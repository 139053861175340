import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore";
import "./Switch.css";
import algoliasearch from 'algoliasearch/lite';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const algoliaClient = algoliasearch('F0LCATOZYQ', '52cf76114641d0f9464ec522f4685d5a');
const algoliaIndex = algoliaClient.initIndex('flowroom');

// Algolia client setup


const client = algoliasearch('F0LCATOZYQ', '52cf76114641d0f9464ec522f4685d5a');
const index = algoliaClient.initIndex('flowroom');


function TruncatedText({ description, showFullDescription }) {
  try {
  const maxLength = 30;
  const [isTruncated, setIsTruncated] = useState(true);
  
  const toggleTruncation = () => {
    // setIsTruncated(!isTruncated);
    showFullDescription()
  };
  
  const displayText = isTruncated ? (description.length > maxLength ? description.slice(0, maxLength) + '...' : description) : description;
  const toggleText = isTruncated ? 'more' : 'less';

  return (
    <div style={{display:'flex'}}>
      <p style={{
        fontSize: 12,
        fontFamily: 'Quicksand',
        color: 'white',
        overflow: 'hidden',
        whiteSpace: isTruncated && description.length > maxLength ? 'nowrap' : 'normal'
      }}>
        {displayText}
      </p>
      {description.length > maxLength && (
        <button onClick={toggleTruncation} style={{ color: 'white', background: 'none', border: 'none', padding: 0, margin: 0 }}>
          {toggleText}
        </button>
      )}
    </div>
  );
      } catch(e){}

}


const ItemComponent = (props) =>{
  const [botName, setBotName] = useState(false);

  return (<div style={{display:'flex', alignItems:'center'}}>
  <p onClick={()=>{
    setBotName(true) 
    let iframe = document.getElementById('undefined_output_frame');
    iframe.contentWindow[props.functionName](props.i, props.botName, props.botToUse);
  }} style={{fontSize:11, marginTop:0, marginRight:10, cursor:'pointer', color:'#DF6B68'}}>{botName === false ? props.botName : 'replaced!'}</p>
  <div onClick={()=>{
          document.getElementById(`detail_${props.i}`).style.display = 'block';
  }} style={{border:'1px solid white', borderRadius:'50%',     height: 10,
width: 10,
display: 'flex',
justifyContent: 'center',
alignItems: 'center', marginRight:15, cursor:'pointer'}}><p style={{fontFamily:'quicksand', color:'white', fontSize:7}}>i</p></div>
</div>)
}
const BotComponent = (props) => {
  return(
    <div style={{display:'flex',
  color: 'white',
  fontSize: 10,
  fontDamily: 'Quicksand', flexDirection:'column'}}>
            <div style={{display:'flex', flexDirection:'column', marginBottom:0}}>
            <div style={{display:'flex', flexDirection:'column'}}>
            
            <p style={{fontSize: 12,
  fontFamily: 'Quicksand',
  color: 'white'}}>{'Bot: ' + props.name}</p>

            </div>
           
</div>
{/* <p style={{fontSize: 12,
  fontFamily: 'Quicksand',
  color: 'white'}}>{props.description}</p> */}
  <TruncatedText description={props.description} showFullDescription={props.showFullDescription} />

{/* 
            <div onClick={()=> {
              let iframe = document.getElementById('undefined_output_frame');
              iframe.contentWindow[props.functionName](props.index, botName)
            }} style={{backgroundColor:'white', borderRadius:5, color:'#1c1c1c', padding:3, marginTop:5, cursor:'pointer'}}><p style={{color:'#1c1c1c', fontFamily:'quicksand', margin:0, fontSize:11, fontWeight:'bold'}}>Replace</p></div> */}
         
         <p style={{fontSize: 11,
  fontFamily: 'Quicksand',
  color: 'white'}}>{'Select bot below to replace with bot above:'}</p>
            <ul style={{display:'flex', marginBottom:10}}>
          
            {props.replace.map((item, i) => (
              <li key={i}>

<ItemComponent i={i} botName={item.botName} description={item.description} functionName={props.functionName} botToUse={props.name}/>

     <div id={`detail_${i}`} style={{backgroundColor:'#323232', height:255, width:'calc(100% - 30px)', position:'absolute', display:'none', top: 92, left: 15, padding:10, overflowY:'scroll'}}>
          <p onClick={()=>{
            document.getElementById(`detail_${i}`).style.display = 'none';
          }} style={{color:'white', fontFamily:'quicksand', fontSize:11, cursor:'pointer', marginBottom:10}}>Back</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word', marginBottom:10}}>{'Bot Name: ' + item.botName}</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, textDecoration:'underline', marginBottom:10}}>Description:</p>
          <div style={{height:155, overflowY:"scroll", padding:0}}>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word'}}>{item.description}</p>
          </div>
        </div>
              
              </li>
            ))}
          </ul>

            </div>)
}



function BotManager(props) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fullDescription, setFullDescription] = useState(false);

  // Example array for featured items
  const featuredItems = [
    { profilePic: 'https://demo.flowroom.com/uploads/Arc4T6.png', description: 'Description for item 1 bnbnbnbbnnbnbbnnbbnnbnbnbnbnbbbnbbnbnbnbnbnnbnbnbbnbbnbnnbbnbnbnbnbnbnbnbnbnttttytytyttytyytttyytyttytyttytyttyytttttytyttytytytytyttyyttytytyttyytytyttyytytytyttttytytytytyyttttytytytyytyttyytytytytttyytytytnb', botName:'chef' },
    { profilePic: 'https://demo.flowroom.com/uploads/Arc4T6.png', description: 'Description for item 1 bnbnbnbbnnbnbbnnbbnnbnbnbnbnbbbnbbnbnbnbnbnnbnbnbbnbbnbnnbbnbnbnbnbnbnbnbnbnttttytytyttytyytttyytyttytyttytyttyytttttytyttytytytytyttyyttytytyttyytytyttyytytytyttttytytytytyyttttytytytyytyttyytytytytttyytytytnb', botName:'boo' },
    { profilePic: 'https://demo.flowroom.com/uploads/Arc4T6.png', description: 'Description for item 2', botName:'boo' },    { profilePic: 'https://demo.flowroom.com/uploads/Arc4T6.png', description: 'Description for item 1 bnbnbnbbnnbnbbnnbbnnbnbnbnbnbbbnbbnbnbnbnbnnbnbnbbnbbnbnnbbnbnbnbnbnbnbnbnbnttttytytyttytyytttyytyttytyttytyttyytttttytyttytytytytyttyyttytytyttyytytyttyytytytyttttytytytytyyttttytytytyytyttyytytytytttyytytytnb', botName:'boo' },    { profilePic: 'https://demo.flowroom.com/uploads/Arc4T6.png', description: 'Description for item 2', botName:'boo' },
  ];

  const searchAlgolia = async (query) => {
    setIsLoading(true);
    try {
      const { hits } = await index.search(query);
      setResults(hits);
    } catch (error) {
      console.error('Error searching Algolia:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (query !== '') {
      searchAlgolia(query);
    }
  }, [query]);


  const handleSearch = async (query) => {
    setIsLoading(true);
    try {
      const { hits } = await index.search(query);
      setResults(hits);
      console.log(hits);
    } catch (error) {
      console.error('Error searching in Algolia:', error);
      // setResults([]);  // Reset results on error
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    try{
    const newQuery = e.target.value;
    setQuery(newQuery);
    if (newQuery.length > 0) {
      handleSearch(newQuery);
    } else {
      // setResults([]);  // Clear results when query is cleared
    }
    }catch(e){}
  };


  return (<div style={{height:300, width:'100%', minWidth:300, marginTop:40, padding:15}}>
    <Tabs>
      <TabList>
        <Tab>Featured</Tab>
        <Tab>Search</Tab>
      </TabList>

      <TabPanel>
       <div style={{height:255, width:'calc(100% - 10px)', background:'rgb(50, 50, 50)', overflowY:'scroll'}}>
        <ul>
          {featuredItems.map((item, i) => (
            <li key={index} style={{padding:10, borderBottom:'1px solid #3E3E3E'}}>
             <BotComponent index={i} name={item.botName} functionName={props.functionName} replace={props.items} description={item.description} showFullDescription={()=>{
                document.getElementById(`detail_${i}`).style.display = 'block';
             }}/>
             <div id={`detail_${i}`} style={{backgroundColor:'#323232', height:255, width:'calc(100% - 30px)', position:'absolute', display:'none', top: 92, left: 15, padding:10, overflowY:'scroll'}}>
          <p onClick={()=>{
            document.getElementById(`detail_${i}`).style.display = 'none';
          }} style={{color:'white', fontFamily:'quicksand', fontSize:11, cursor:'pointer', marginBottom:10}}>Back</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word', marginBottom:10}}>{'Bot Name: ' + item.botName}</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, textDecoration:'underline', marginBottom:10}}>Description:</p>
          <div style={{height:155, overflowY:"scroll", padding:0}}>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word'}}>{item.description}</p>
          </div>
        </div>
        <div id={`detail_replace${i}`} style={{backgroundColor:'#323232', height:255, width:'calc(100% - 30px)', position:'absolute', display:'none', top: 92, left: 15, padding:10, overflowY:'scroll'}}>
          <p onClick={()=>{
            document.getElementById(`detail_replace${i}`).style.display = 'none';
          }} style={{color:'white', fontFamily:'quicksand', fontSize:11, cursor:'pointer', marginBottom:10}}>Back</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word', marginBottom:10}}>{'Bot Name: ' + item.botName}</p>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, textDecoration:'underline', marginBottom:10}}>Description:</p>
          <div style={{height:155, overflowY:"scroll", padding:0}}>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:11, wordBreak:'break-word'}}>{item.description}</p>
          </div>
        </div>
            </li>
          ))}
        </ul>
        </div>
      </TabPanel>

      <TabPanel>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleChange}
      />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div style={{height: 300, width: 'calc(100% - 10px)', marginTop: 18, padding: 0}}>
          <div style={{backgroundColor: '#323232', height: 220, overflowY: 'scroll', padding: 10}}>
            <ul>
              {results.map((item, index) => (
                <li key={index} style={{padding: 10, borderBottom: '1px solid #3E3E3E'}}>
                  <BotComponent index={index} name={item.botName} functionName={props.functionName} replace={props.items} description={item.description} showDescription={() => {}}/>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </TabPanel>
    </Tabs>
    </div>);
}

export default BotManager;
