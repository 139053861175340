// libraries
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ReactModal from "react-modal";
// store
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { TabStoreContext } from "../../store/TabStore.js";

import CommentsMobile from "./RoomComponents/CommentsMobile";

const CommentsModal = observer((props) => {
  const CommentsStore = useContext(CommentsStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const TabStore = useContext(TabStoreContext);

  let isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={500}
      // id="commentsScrollView"
      isOpen={props.open}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          background: "#1c1c1c",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 100000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "90%",
          maxWidth: "760px",
          maxHeight: "750px",
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Comments
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            props.close();
            TabStore.setClosePanel(true);
            if(document.getElementById(
              "vertical-tab-comments-tab"
            ) !== null) {
            document.getElementById(
              "vertical-tab-comments-tab"
            ).style.background = "transparent";
            document.getElementById(
              "vertical-tab-comments-tab"
            ).style.borderRadius = "0px";
            TabStore.setIsCommentsSelected(false);
            }
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>

      <CommentsMobile
        style={{ position: "relative" }}
        close={() => {
          props.close();
        }}
      />
    </ReactModal>
  );
});

export default CommentsModal;
