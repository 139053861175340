import {
  faPenSquare,
  faSave,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as React, useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FaLock, FaUnlock } from "react-icons/fa";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";

const CalendarComponent = (props) => {
  // const [color, setColor] = useState("");
  const WidgetStore = useContext(WidgetStoreContext);
  let widgets = WidgetStore.widgets;
  const FlowStore = useContext(FlowStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [showUnlockWidget, setShowUnlockWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);
  const [isDevAccount, setIsDevAccount] = useState(false);


  return (
    <div
      style={{
        display:"flex",
        justifyContent:'center',
        alignItems:'center'
      }}
    >
            <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: 20
      
        }}
      >
        <div
          style={{
            height: 35,
            width: "100%",
            borderBottom: "1px solid #323232",
            marginBottom: 17,
          }}
        ></div>
        <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
          <DatePicker
            // selected={item.defaultValue}
            onChange={(date) => {
              
              
               let iframe = document.getElementById("undefined_output_frame");
            
                
               iframe.contentWindow[props.functionName](date);
              
            
            
            }}
            style={{ height: 500, width: 280 }}
            selectsRange
            inline
          />
        </div>
    </div>
    </div>
    
  );
};

export default React.memo(CalendarComponent);
