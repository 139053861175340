// src/Components/Home/InviteRequests.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

const InviteRequests = () => {
  const db = firebase.firestore();
  const [inviteRequests, setInviteRequests] = useState([]);
  const [inviteMessage, setInviteMessage] = useState('');
  const [error, setError] = useState('');
  const [processingInviteId, setProcessingInviteId] = useState(null); // To track which invite is being processed

  useEffect(() => {
    console.log("Fetching invite requests...");
    const unsubscribe = db.collection('inviteRequests').onSnapshot(snapshot => {
      const requests = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Fetched invite requests:", requests); // Debugging
      setInviteRequests(requests);
    }, err => {
      console.error("Error fetching invite requests:", err);
      setError('Failed to fetch invite requests.');
    });

    return () => unsubscribe();
  }, [db]);

  const handleAcceptInvite = async (inviteId, inviteEmail) => {
    setInviteMessage('');
    setError('');
    setProcessingInviteId(inviteId);

    try {
      // Fetch the corresponding invite in 'invites' collection using inviteEmail
      const inviteSnapshot = await db.collection('invites')
        .where('inviteEmail', '==', inviteEmail)
        .limit(1)
        .get();

      if (inviteSnapshot.empty) {
        setInviteMessage('Invite not found.');
        setProcessingInviteId(null);
        return;
      }

      const inviteDoc = inviteSnapshot.docs[0];
      await db.collection('invites').doc(inviteDoc.id).update({
        inviteAccepted: true,
        acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Remove the invite request from 'inviteRequests' collection
      await db.collection('inviteRequests').doc(inviteId).delete();

      setInviteMessage(`Invite for ${inviteEmail} has been accepted.`);
    } catch (err) {
      console.error("Error accepting invite:", err);
      setError('Failed to accept invite. Please try again.');
    } finally {
      setProcessingInviteId(null);
    }
  };

  const handleRejectInvite = async (inviteId) => {
    setInviteMessage('');
    setError('');
    setProcessingInviteId(inviteId);

    try {
      // Remove the invite request from 'inviteRequests' collection
      await db.collection('inviteRequests').doc(inviteId).delete();

      setInviteMessage(`Invite request has been rejected and removed.`);
    } catch (err) {
      console.error("Error rejecting invite:", err);
      setError('Failed to reject invite. Please try again.');
    } finally {
      setProcessingInviteId(null);
    }
  };

  const Row = React.memo(({ index, style }) => {
    const invite = inviteRequests[index];
    const isProcessing = processingInviteId === invite.id;

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Email:</strong> {invite.inviteEmail}
          <br />
          <strong>Info:</strong> {invite.inviteInfo}
          <br />
          <strong>Timestamp:</strong> {invite.timestamp ? invite.timestamp.toDate().toLocaleString() : 'N/A'}
        </div>
        <div style={styles.buttonGroup}>
          <button
            onClick={() => handleAcceptInvite(invite.id, invite.inviteEmail)}
            style={{ ...styles.button, ...styles.acceptButton }}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Accept'}
          </button>
          <button
            onClick={() => handleRejectInvite(invite.id)}
            style={{ ...styles.button, ...styles.rejectButton }}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Reject'}
          </button>
        </div>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2>Pending Invite Requests</h2>
      {inviteRequests.length === 0 ? (
        <p>No pending invite requests.</p>
      ) : (
        <List
          height={400}
          itemCount={inviteRequests.length}
          itemSize={100}
          width={'100%'}
        >
          {Row}
        </List>
      )}
      {inviteMessage && <p style={styles.message}>{inviteMessage}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
  acceptButton: {
    backgroundColor: '#28A745',
    color: '#fff',
  },
  rejectButton: {
    backgroundColor: '#DC3545',
    color: '#fff',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default InviteRequests;
