import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore";

const LockRemixWidgetModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [showLockMessage, setShowLockMessage] = useState(false);
  useEffect(() => {
    setShowLockMessage(props.showLockMessage);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.showLockMessage]);
  return (
    <ReactModal
      isOpen={showLockMessage}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",

          padding: 20,
        },
      }}
    >
      <div>
        <div
          onClick={() => {
            props.closeLockModal();
          }}
          style={{
            height: 50,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#F06263"
            size={"4x"}
            style={{
              display: "block",
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
            }}
          />
        </div>
        <p
          style={{
            color: "white",
            fontFamily: "quicksand",
            width: "100%",
            maxWidth: 429,
            margin: "auto",
            fontSize: 17,
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          Are you sure you want to hide this widget?
        </p>
        <p
          style={{
            color: "white",
            fontFamily: "quicksand",
            width: "100%",
            maxWidth: 429,
            margin: "auto",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          Hiding widgets can be useful when you have a special use case for a
          widget and don't want anyone to modify without warning.
        </p>
        <div
          onClick={() => {
            props.lock();
          }}
          style={{
            height: 30,
            width: "100%",
            // maxWidth: 400,
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: 20,
            marginBottom: 10,
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Yes</p>
        </div>
        <div
          onClick={() => {
            // WidgetStore.setShowWidgetMessage(false);
            props.closeLockModal();
          }}
          style={{
            height: 30,
            width: "100%",
            // maxWidth: 400,
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Cancel</p>
        </div>
      </div>
    </ReactModal>
  );
});

export default LockRemixWidgetModal;
