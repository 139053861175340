import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import { default as React, useContext, useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

import {
  faPenSquare,
  faSave,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";

const SliderComponent = (props) => {
  const [slider, setSlider] = useState(0);
  const WidgetStore = useContext(WidgetStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  let widgets = WidgetStore.widgets;

  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();
  const FlowStore = useContext(FlowStoreContext);
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);

  const [lock, setLock] = useState(false);

  const [showUnlockWidget, setShowUnlockWidget] = useState(false);

  const [isDevAccount, setIsDevAccount] = useState(false);
  // const textInput = useRef();

  useEffect(() => {
    setTimeout(() => {
      setIsDevAccount(AuthenticationStore.isDevAccount);
    });
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [AuthenticationStore.isDevAccount]);

  const PrettoSlider = withStyles({
    root: {
      color: "#A962F1",
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);
  const Lock = () => {
    useEffect(() => {
      setLock(props.isLocked);
      return () => {
        // Clean up or cancel any ongoing tasks or subscriptions here
      };
    }, []);
    return (
      <div
        onClick={(e) => {
          // setLock(e.target.value);

          //widgets.map((item) => {
          // alert(item.actualInstance + " " + props.actualInstance++);

          //if (item.actualInstance === props.actualInstance) {
          if (lock == false) {
            // setLock(true);
            setShowLockWidget(true);

            // widgets[props.actualInstance].isLocked = true;
          } else {
            // setLock(false);
            setShowUnlockWidget(true);
            // widgets[props.actualInstance].isLocked = false;
          }

          if (FlowStore.isFlowOwner === true) {
            firebase
              .firestore()
              .collection("flows")
              .doc(lastSegment)
              .update({ widgets: widgets })
              .then(() => {
                firebase
                  .firestore()
                  .collection("flows")
                  .doc(lastSegment)
                  // .limit(1)
                  .get()
                  .then((doc) => {
                    let data = doc.data();
                    console.log("widget data", data.widgets);

                    // WidgetStore.setWidgets(data.widgets);
                  });
              });
          }

          WidgetStore.setWidgets(widgets);
        }}
        style={{ marginTop: 8, marginLeft: 10, zIndex: 1000 }}
      >
        {lock === true ? (
          <FaLock
            size={17}
            color={"#F06263"}
            style={{ marginBottom: 7, cursor: "pointer" }}
          />
        ) : (
          <FaUnlock
            size={17}
            color={"#A962F1"}
            style={{ marginBottom: 7, cursor: "pointer" }}
          />
        )}
      </div>
    );
  };

  const setJump = () => {
    let textCount = 0;
    let imageCount = 0;
    let colorCount = 0;
    let sliderCount = 0;
    let switchCount = 0;
    let selectCount = 0;
    let checkboxCount = 0;
    let calendarCount = 0;

    let optionsJump = [];
    WidgetStore.widgets.map((widget, i) => {
      if (optionsJump.length !== widgets.length) {
        if (
          widget.widget === "ImageUploader" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          imageCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: imageCount,
          });
        } else if (
          widget.widget === "TextInput" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          textCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: textCount,
          });
        } else if (
          widget.widget === "ColorSelect" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          colorCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: colorCount,
          });
        } else if (
          widget.widget === "Slider" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          sliderCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: sliderCount,
          });
        } else if (
          widget.widget === "Switch" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          switchCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: switchCount,
          });
        } else if (
          widget.widget === "Select" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          selectCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: selectCount,
          });
        } else if (
          widget.widget === "Checkbox" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          checkboxCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: checkboxCount,
          });
        } else if (
          widget.widget === "Calendar" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          calendarCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: calendarCount,
          });
        }
      }
    });
    WidgetStore.setJumpTo(optionsJump);
  };

  const editLabel = () => {
    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", true);
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "5px dotted #F06263";
  };
  const saveLabel = () => {
    if (
      document
        .getElementById(`label_${props.actualInstance}`)
        .innerText.toString()
        .trim().length > 14
    ) {
      WidgetStore.setErrorMessage(true);

      WidgetStore.setCurrentErrorMessage(
        "You can only enter a maximum of 14 charaacters for a label name."
      );
      return;
    }
    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", false);

    widgets.map((item) => {
      if (item.actualInstance === props.actualInstance) {
        widgets[props.actualInstance].label = document.getElementById(
          `label_${props.actualInstance}`
        ).innerText;
      }
    });
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "0px";
    //WidgetStore.setWidgets(widgets);
    setJump();
    if (FlowStore.isFlowOwner === true) {
      firebase
        .firestore()
        .collection("flows")
        .doc(lastSegment)
        .update({ widgets: WidgetStore.widgets })
        .then(() => {
          firebase
            .firestore()
            .collection("flows")
            .doc(lastSegment)
            // .limit(1)
            .get()
            .then((doc) => {
              let data = doc.data();
              console.log("widget data", data.widgets);

              WidgetStore.setWidgets(data.widgets);
            });
        });
    }
  };
  return (
    <div
      style={{
        display:
          props.isLocked === true &&
          props.advancedOptions === false &&
          FlowStore.isFlowOwner === false &&
          lastSegment !== "new"
            ? "none"
            : "block",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: 35,
            width: "100%",
            borderBottom: "1px solid #323232",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            id={`label_${props.actualInstance}`}
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "white",
            }}
          >
            {props.label}
          </p>
          {/* <div
            style={{
              position: "absolute",
              right: 17,
              justifyContent: "space-between",
              width: 98,
              display: "flex",
            }}
          >
            <img src="../unlock.svg" style={{ height: 17 }} />
            <img src="../message-edit.svg" style={{ height: 17 }} />
            <img src="../direct-inbox.svg" style={{ height: 17 }} />
            <img src="../group.svg" style={{ height: 17 }} />
          </div> */}
          {isDevAccount == true && props.hideOptions !== true ? (
            <Lock
              updateLock={(bool) => {
                widgets.map((item) => {
                  if (item.actualInstance === props.actualInstance) {
                    widgets[props.actualInstance].isLocked = bool;
                  }
                });

                if (FlowStore.isFlowOwner === true) {
                  firebase
                    .firestore()
                    .collection("flows")
                    .doc(lastSegment)
                    .update({ widgets: WidgetStore.widgets })
                    .then(() => {
                      firebase
                        .firestore()
                        .collection("flows")
                        .doc(lastSegment)
                        // .limit(1)
                        .get()
                        .then((doc) => {
                          let data = doc.data();
                          console.log("widget data", data.widgets);

                          WidgetStore.setWidgets(data.widgets);
                        });
                    });
                }
              }}
            />
          ) : (
            ""
          )}
        </div>

        {isDevAccount == true && props.hideOptions !== true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: 70,
            }}
          >
            <div onClick={editLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faPenSquare}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
            <div onClick={saveLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faSave}
                color="#4FD975"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              onClick={() => {
                setShowDeleteWidget(true);
                // widgets.splice(props.actualInstance, 1);
                // window.widgets.splice(props.actualInstance, 1);
                // // WidgetStore.setWidgets(widgets);
                // // WidgetStore.setDeleteWidgetItem(true);
                // // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                // // WidgetStore.setImageUploaderCount(
                // //   WidgetStore.imageUploaderCount > 0
                // //     ? WidgetStore.imageUploaderCount - 1
                // //     : 0
                // // );

                // firebase
                //   .firestore()
                //   .collection("flows")
                //   .doc(lastSegment)
                //   .update({ widgets: widgets })
                //   .then(() => {
                //     WidgetStore.setWidgets(widgets);
                //     WidgetStore.setDeleteWidgetItem(true);
                //     WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                //     setJump();
                //   });
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faWindowClose}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {props.showInfo !== true ? (
        <div style={{ width:'100%', paddingLeft: 20, paddingRight: 20 }}>
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            defaultValue={slider}
            onChange={(range) => {
              // setSlider(range.target.ariaValueNow);

              // WidgetStore.setReplaceValue(range.target.ariaValueNow);
              // WidgetStore.setWidgetValueLength(range.target.ariaValueNow.length);
              // WidgetStore.setIsReplaceJS(true);
              // WidgetStore.setWidgetActualInstance(props.actualInstance);
              // WidgetStore.setEditorSelected(
              //   WidgetStore.widgets[props.actualInstance].editorSelected
              // );

              if (props.functionName !== "" || props.functionName !== null) {
                // localStorage.setItem("ReplaceValue", range.target.ariaValueNow);
                // localStorage.setItem(
                //   "WidgetActualInstance",
                //   props.actualInstance
                // );
                // WidgetStore.setWidgetValueLength(
                //   range.target.ariaValueNow.length
                // );
                // WidgetStore.setIsReplaceJS(true);
                // WidgetStore.setEditorSelected(
                //   WidgetStore.widgets[props.actualInstance].editorSelected
                // );

                // FlowStore.setRenderIframe(true);
                let iframe = document.getElementById("undefined_output_frame");

                if (iframe !== undefined && iframe !== null) {
                  let textValue = range.target.ariaValueNow;

                  if (
                    document.getElementById("undefined_output_frame") !== null
                  ) {
                    eval(
                      `let iframe = document.getElementById("undefined_output_frame").contentWindow.${props.functionName}(textValue);`
                    );
                  }
                }
              }
            }}
          />
        </div>
      ) : (
        ""
      )}
      <p
        style={{
          color: "white",
          fontSize: 10,
          fontFamily: "quicksand",
          marginTop: 12,
          opacity: 0.8,
        }}
      >
        {props.info}
      </p>
      <DeleteRemixWidgetModal
        deleteWidget={() => {
          widgets = widgets.filter((widget) => widget.label !== props.label);
          window.widgets = widgets.filter(
            (widget) => widget.label !== props.label
          );
          let lastArr =
            JSON.parse(localStorage.getItem("lastRemovedSlider")) === null
              ? []
              : JSON.parse(localStorage.getItem("lastRemovedSlider")).sort();

          lastArr.push(props.instanceOfWidget);
          localStorage.setItem("lastRemovedSlider", JSON.stringify(lastArr));

          // WidgetStore.setImageUploaderCount(
          //   WidgetStore.imageUploaderCount > 0
          //     ? WidgetStore.imageUploaderCount - 1
          //     : 0
          // );
          if (FlowStore.isFlowOwner === true) {
            firebase
              .firestore()
              .collection("flows")
              .doc(lastSegment)
              .update({
                widgets: widgets.filter(
                  (widget) => widget.label !== props.label
                ),
              })
              .then(() => {
                WidgetStore.setWidgets(widgets);
                // WidgetStore.setDeleteWidgetItem(true);
                // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                setJump();
                setShowDeleteWidget(false);
              });
          }
        }}
        showDeleteRemixWidgetModal={showDeleteWidget}
        closeWidget={() => {
          setShowDeleteWidget(false);
        }}
      />
      <UnlockRemixWidgetModal
        unlock={() => {
          // setLock(false);
          widgets[props.actualInstance].isLocked = false;

          setShowUnlockWidget(false);
        }}
        showUnlockMessage={showUnlockWidget}
        closeUnlockModal={() => {
          setShowUnlockWidget(false);
        }}
      />
      <LockRemixWidgetModal
        lock={() => {
          // setLock(true);
          widgets[props.actualInstance].isLocked = true;

          setShowLockWidget(false);
        }}
        showLockMessage={showLockWidget}
        closeLockModal={() => {
          setShowLockWidget(false);
        }}
      />
    </div>
  );
};

export default React.memo(SliderComponent);
