import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { firebase } from "../Components/firebase/firebase";

const username = JSON.parse(localStorage.getItem("flowroom-user"));

class PublishMenuStore {
  constructor() {
    makeAutoObservable(this);
  }

  titie = "";
  description = "";
  tags = [];
  html = "";
  css = "";
  js = "";

  savingScreenVisible = false;
  isRemix = false;
  remixRoomID = "";
  remixUserName = "";
  userName = "";
  dateCreated = "";
  postBtnVisible = false;
  saveVisible = false;
  showRemixMobile = false;
  showPublishMobile = false;
  flowExists = false;
  showCreateMenu = false;
  hasPublished = false;
  visibility = "public";
  isRemixable = true;
  titleLabel = "";
  descriptionLabel = "";
  libraries = [];
  cssLibraries = [];
  AITextPopulated = "";
  collaborators = [];
  remixedBy = [];
  flowRemixer = "";
  flowIdOP = "";
  showShare = false;
  createFlowId = "";
  flowData = {};
  postErrorMessage = "";
  showErrorMessage = false;
  showErrorMessageTitle = false;
  showErrorMessageDescription = false;
  originalTitle = false;
  originalDescription = false;

  savingScreenVisible(boolean) {
    this.savingScreenVisible = boolean;
  }

  isRemix(boolean) {
    this.isRemix = boolean;
  }

  remixRoomID(param) {
    this.remixRoomID = param;
  }

  remixUserNamee(param) {
    this.savingScreenVisible = param;
  }

  userName(param) {
    this.userName = param;
  }

  dateCreated(param) {
    this.dateCreated = param;
  }

  setPostBtnVisible(boolean) {
    this.postBtnVisible = boolean;
  }

  setSaveVisible(boolean) {
    this.saveVisible = boolean;
  }

  setShowRemixMobile(bool) {
    this.showRemixMobile = bool;
  }

  setShowPublishMobile(bool) {
    this.showPublishMobile = bool;
  }

  setHasPublished(bool) {
    this.hasPublished = bool;
  }

  setTags(tag) {
    this.tags = [...this.tags, tag];
  }

  setVisibility(value) {
    this.visibility = value;
  }

  set_fr_remix_list(fr_remix_list) {
    this.fr_remix_list = fr_remix_list;
  }
  setCollaborators(array) {
    this.collaborators = array;
  }

  startCreateRoom = async (roomData = {}, user) => {
    if (user) {
      console.log("flowId: ", roomData.flowId);
      console.log("roomData: ", roomData);
      let roomObject = JSON.parse(JSON.stringify(roomData));
      firebase
        .firestore()
        .collection("flows")
        .doc(roomData.flowId)
        .set(roomObject, { merge: true })
        .then(() => {
          // this.hasPublished = true;
          // this.showShare = true;
          // this.savingScreenVisible = false;
        });
    } else {
      // TODO: prompt sign in
      console.log("[Publish_Menu] not signed in");
    }
  };
  startCreateRoomNoModal = async (roomData = {}, user) => {
    if (user) {
      console.log("flowId: ", roomData.flowId);
      console.log("roomData: ", roomData);
      let roomObject = JSON.parse(JSON.stringify(roomData));
      firebase
        .firestore()
        .collection("flows")
        .doc(roomData.flowId)
        .set(roomObject, { merge: true })
        .then(() => {
          // this.hasPublished = true;
          // this.showShare = true;
          // this.savingScreenVisible = false;
        });
    } else {
      // TODO: prompt sign in
      console.log("[Publish_Menu] not signed in");
    }
  };
  isMenuAlreadySetToFalse = false;
  fr_remix_list = {};
  savingScreen = (bool) => {
    this.savingScreenVisible = true;
  };

  setFlowExists(boolean) {
    this.flowExists = boolean;
  }

  setTitle(title) {
    this.title = title;
  }

  setDescription(description) {
    this.description = description;
  }

  setShowCreateMenu(boolean) {
    this.showcreateMenu = boolean;
  }

  setIsMenuAlreadySetToFalse(boolean) {
    this.isMenuAlreadySetToFalse = true;
  }

  setIsRemixable(bool) {
    this.isRemixable = bool;
  }

  setTitleLabel(text) {
    this.titleError = text;
  }

  setDescriptionError(boolean) {
    this.descriptionError = boolean;
  }

  setTitleLabel(text) {
    this.titleLabel = text;
  }

  setDescriptionLabel(text) {
    this.descriptionLabel = text;
  }
  setLibraries(array) {
    this.libraries = array;
  }
  setCSSLibraries(array) {
    this.cssLibraries = array;
  }
  setIsRemixable(bool) {
    this.isRemixable = bool;
  }
  setAITextPopulated(text) {
    this.AITextPopulated = text;
  }
  setCollaborators(conllaborators) {
    this.collaborators = conllaborators;
  }
  setRemixedBy(array) {
    this.remixedBy = array;
  }
  setFlowRemixer(param) {
    this.flowRemixer = param;
  }
  setFlowIdOP(param) {
    this.flowIdOP = param;
  }
  setShowShare(bool) {
    this.showShare = bool;
  }
  setCreateFlowId(flowId) {
    this.createFlowId = flowId;
  }
  setPostErrorMessage(param) {
    this.postErrorMessage = param;
  }
  setShowErrorMessage(param) {
    this.showErrorMessagee = param;
  }
  setShowErrorMessageTitle(param) {
    this.showErrorMessageTitle = param;
  }
  setShowErrorMessageDescription(param) {
    this.showErrorMessageDescription = param;
  }
  setOriginalTitle(param) {
    this.originalTitle = param;
  }
  setOriginalDescription(param) {
    this.originalDescription = param;
  } 
}

export const PublishMenuStoreContext = createContext(new PublishMenuStore());
