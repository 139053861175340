import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import { default as React, useContext, useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

import {
  faPenSquare,
  faSave,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import FormLabel from "@material-ui/core/FormLabel";
import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";

const radioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "4px solid #2d2a2b",
    width: 30,
    height: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#2d2a2b",
    color: "white",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '$root.Mui-focusVisible &': {
    // outline: '1px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
  },
  checkedIcon: {
    backgroundColor: "#4FD975",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      color: "white",
    },
    "input:hover ~ &": {
      backgroundColor: "#4FD975",
      color: "white",
    },
  },
});
const RadioComponent = (props) => {
  // const [color, setColor] = useState("");
  const WidgetStore = useContext(WidgetStoreContext);
  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();
  const classes = radioStyles();
  let widgets = WidgetStore.widgets;
  const FlowStore = useContext(FlowStoreContext);
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [showUnlockWidget, setShowUnlockWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);

  const [lock, setLock] = useState(false);


  return (
    <div
      style={{
        display:"flex"
      }}
    >

<div
      style={{
        display:"flex",
        height:'100%',
        backgroundColor:'#1c1c1c'
      }}
    >
        <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10, marginTop: 70,
    width: '100%',
    overflowY: 'scroll' }}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{props.radioLabel}</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          // className={classes.group}
          // value={this.state.value}
          onChange={(e) => {
            console.log("radio", e.target.value);
            window.getAlready = false;
            // WidgetStore.setReplaceValue(e.target.value);

            localStorage.setItem("ReplaceValue", e.target.value);
            localStorage.setItem("WidgetActualInstance", props.actualInstance);
            WidgetStore.setWidgetValueLength(e.target.value.length);

            WidgetStore.setIsReplaceJS(true);
            // WidgetStore.setWidgetActualInstance(props.actualInstance);
            localStorage.setItem("WidgetActualInstance", props.actualInstance);
            WidgetStore.setEditorSelected(
              WidgetStore.widgets[props.actualInstance].editorSelected
            );

            try {
              let iframe = document.getElementById("undefined_output_frame");
              if (iframe !== undefined && iframe !== null) {
                let ev = `
                  let iframe = document.getElementById(
                    "undefined_output_frame"
                  );
                  if (iframe !== undefined && iframe !== null) {
                    iframe.contentWindow.${props.function}('${e.target.value}')
                  }`;
                console.log("function called", ev);

                try {
                  eval(ev);
                } catch (e) {}
              }
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {props.options.map((option) => {
            return (
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={option.disabled}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
    </div>
    </div>
  );
};
export default React.memo(RadioComponent);
