import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";

const PaymentModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [confirm, setConfirm] = useState(false);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [cardNumber, setCardNumber] = useState("");

  const handleChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleSubmit = () => {
    // TODO: Implement your processing logic here (e.g., send the card number to your server for processing)

    const creditAmount = 10; // Set the amount you want to increment the credits by
    let currentUserString = localStorage.getItem("flowroom-user");

    // if (currentUserString) {
    //   currentUser = JSON.parse(currentUserString);
    // }
    // if (currentUser && !currentUser.uid && currentUser.userId) {
    //   currentUser.uid = currentUser.userId;
    // }

    firebase
      .firestore()
      .collection("credits")
      .doc(firebase.auth().currentUser.uid)
      .update({
        credits: firebase.firestore.FieldValue.increment(100000),
      })
      .then(() => {
        // Your callback or success handling code here
        alert("purchase complete!");
      })
      .catch((error) => {
        // Your error handling code here
      });

    console.log("Card number submitted:", cardNumber);
  };
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    // alert(isMobile);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={WidgetStore.showPaymentModal}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          height: "70%",
          maxWidth: "650px",
        },
      }}
    >
      <div
        style={{
          background: "#1C1C1C",
          borderRadius: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "58px",
            background: "#1C1C1C",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 111,
            marginLeft: 12,
          }}
        >
          <img
            src="../images/receipt-modal.svg"
            style={{ fontSize: 18, height: 18 }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18,
              color: "white",
            }}
          >
            Subscribe
          </p>
          <img
            onClick={() => {
              WidgetStore.setShowPaymentModal(false);
            }}
            src="../images/close-circle.svg"
            style={{
              fontSize: 18,
              height: 18,
              right: 15,
              position: "absolute",
              cursor: "pointer",
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "#1C1C1C",
            borderTop: "1px solid #3E3E3E",
          }}
        >
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 23,
              color: "#FFFFFF",
              margin: "auto",
              width: "232px",
              marginTop: 70,
            }}
          >
            This is a PRO feature
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(255, 255, 255, 0.6)",
              width: "100%",
              maxWidth: 308,
              margin: "auto",
              textAlign: "center",
              marginBottom: 37,
            }}
          >
            With a PRO membership, you can create even more with Flowroom!
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "auto",
              width: "100%",
              maxWidth: 484,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: 230,
                height: 365,
                top: 427,
                background: "#323232",
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 36,
                  textAlign: "center",
                  color: "#4FD975",
                  marginTop: 15,
                }}
              >
                $00
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Quicksand",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#FFFFFF",
                    marginTop: 20,
                    marginBottom: 12,
                  }}
                >
                  Free Plan
                </p>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 17px",
                    width: "190px",
                    height: "50px",
                    background: "#4FD975",
                    borderRadius: 10,
                    margin: "auto",
                    position: "absolute",
                    bottom: 71,
                    left: 103,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Choose
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                width: 230,
                height: 365,
                top: 427,
                background: "#323232",
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 36,
                  textAlign: "center",
                  color: "#4FD975",
                  marginTop: 15,
                }}
              >
                $00
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Quicksand",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#FFFFFF",
                    marginTop: 20,
                    marginBottom: 12,
                  }}
                >
                  PRO
                </p>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: 95,
                    justifyContent: "space-between",
                    marginBottom: 7,
                  }}
                >
                  <img src="../images/subscribe-check.svg" />
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#FFFFFF",
                      opacity: 0.6,
                    }}
                  >
                    Feature 1
                  </p>
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 17px",
                    width: "190px",
                    height: "50px",
                    background: "#4FD975",
                    borderRadius: 10,
                    margin: "auto",
                    position: "absolute",
                    bottom: 71,
                    right: 103,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Choose
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
});

export default React.memo(PaymentModal);
