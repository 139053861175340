import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/storage"; // for storage

// Initialize Firebase
const config = {
  apiKey: "AIzaSyC-ragZHIGaq2dt0Epqiu1_7mPJPg7MQIs",
  authDomain: "flowroom-fd862.firebaseapp.com",
  databaseURL: "https://flowroom-fd862.firebaseio.com",
  projectId: "flowroom-fd862",
  storageBucket: "flowroom-fd862.appspot.com",
  messagingSenderId: "631544722196",
  appId: "1:631544722196:web:ec2454de6a0f90051d4801",
};

firebase.initializeApp(config);

const db = firebase.firestore(); // Export Firestore database
const auth = firebase.auth(); // Export Authentication service
const storage = firebase.storage(); // Export Storage service

export { firebase, db, auth, storage };
