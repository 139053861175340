import React, { useContext, useEffect, useState, useRef } from "react";
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { DrawerStoreContext } from "../../store/DrawerStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { RemixableStoreContext } from "../../store/RemixableStore";
import { firebase } from "../firebase/firebase";

const FlowIframeRoom = (props) => {
  const iframeRef = useRef(null);
  const EditorStore = useContext(EditorStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const RemixableStore = useContext(RemixableStoreContext);
  const DrawerStore = useContext(DrawerStoreContext);
  const DHTMLStore = useContext(DHTML_StoreContext);
  const [cssStyles, setcssStyles] = useState("");
  const [html, setHTML] = useState("");
  const [css, setCSS] = useState("");
  const [js, setJS] = useState("");
  const [isNormalize, setIsNormalize] = useState();
  const [isBabel, setIsBabel] = useState();

  useEffect(() => {
    const fetchWithRetry = async (url, retries = 7, backoff = 300) => {
      try {
        const response = await fetch(url, { mode: "cors" });
        if (!response.ok) throw new Error(`Fetch failed with status ${response.status}`);
        return await response.text();
      } catch (error) {
        if (retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, backoff));
          return fetchWithRetry(url, retries - 1, backoff * 2);
        } else {
          throw error;
        }
      }
    }
  
    const fetchData = async () => {
      try {
        const fetchPromises = [EditorStore.urlHTML, EditorStore.urlCSS, EditorStore.urlJS].map(url => url ? fetchWithRetry(url) : Promise.resolve(undefined));
        const [htmlContent, cssContent, jsContent] = await Promise.all(fetchPromises);
        if (htmlContent !== undefined) setHTML(htmlContent);
        if (cssContent !== undefined) setCSS(cssContent);
        if (jsContent !== undefined) setJS(jsContent);
      } catch (error) {
        console.error("Failed to fetch resources:", error);
      }
    };
  
    fetchData();
  }, [EditorStore.urlHTML, EditorStore.urlCSS, EditorStore.urlJS]); 

  useEffect(() => {
    if (!iframeRef.current) return;

    const renderIframeHTML = () => {
      let iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      let source = `
        <!doctype html>
        <html>
          <head>
            <meta charset="utf-8">
            <title>Flowroom</title>
            <style>${css}</style>
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">
            ${isNormalize ? '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css">' : ''}
          </head>
          <body>
            ${html}
            <script>${js}</script>
            <script>window.pageLoaded = true;</script>
          </body>
        </html>
      `;

      iframeDoc.open();
      iframeDoc.write(source);
      iframeDoc.close();
    };

    renderIframeHTML();
    return () => {
        // Clean up or cancel any ongoing tasks or subscriptions here
      };
  }, [html, css, js]);

  return <iframe  id="undefined_output_frame" ref={iframeRef}             
  loading="lazy"
  allow="accelerometer; camera; encrypted-media; display-capture; geolocation; gyroscope; microphone; midi; clipboard-read; clipboard-write"
  allowFullScreen={true}
  allowpaymentrequest="true"
  allowtransparency="true"
  sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts"
   style={{ height: "100vh", width: "100%", border: "0px" }}></iframe>;
};

export default FlowIframeRoom;
