// libraries
import { faShare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { collection } from "typesaurus";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import Hashids from "hashids";
import { v4 as uuid } from "uuid";
import ReactModal from "react-modal";
import RateLimit from "./RateLimit.js";
import { RoomContext } from "./RoomContext.js";
import RemixItem from "../Home/RemixItem";

// store
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";

interface IComment {
  comment: string;
  commentId: string;
  date: string;
  flowId: string;
  urlPhoto: string;
  userId: string;
  username: string;
}

let commentsCollection = collection<IComment>("comments");
const firestore = firebase.firestore();

const linkRegex = /(https?:\/\/(?:www\.)?flowroom\.com\/room\/[^\s]+)/gi;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#1C1C1C",
      margin: "4px 8px",
    },
    header: {
      color: "white",
      textAlign: "start",
      background: "#1C1C1C",
    },
  })
);

let currentlySelectedReplyComment: string | undefined;
let globalReplyCount = 0;
let globalReplyResetTimeout: NodeJS.Timeout | null = null;

interface ICommentCardProps {
  comment: string;
  commentId: string;
  date: string;
  flowId: string;
  isSelf: boolean;
  myUserId: string;
  photoURL: string;
  username: string;
  userId: string;
  likesCount: number;
}

interface IReply {
  replyId: string;
  username: string;
  userId: string;
  text: string;
  timestamp: Date;
  likesCount: number;
  currentUserHasLiked: boolean;
}

const CommentCard: React.FC<ICommentCardProps> = ({
  comment,
  commentId,
  date,
  flowId,
  isSelf,
  myUserId,
  photoURL,
  username,
  userId,
  likesCount,
}) => {
  const classes = useStyles();
  const CommentsStore = useContext(CommentsStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const { currentUser } = useContext(AuthContext);
  const room = useContext(RoomContext);
  const { replyId } = useParams<{ replyId: string }>();

  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const [flowUsername, setFlowUsername] = useState<string>("");
  const [flowDate, setFlowDate] = useState<string>("");
  const [flowInCommentId, setFlowInCommentId] = useState<string>("");
  const [flowInCommentProfilePic, setFlowInCommentProfilePic] = useState<string>("");
  const [showReplyBox, setShowReplyBox] = useState<boolean>(false);
  const [showEditBox, setShowEditBox] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>("");
  const [replyUpdateText, setReplyUpdateText] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);
  const [replies, setReplies] = useState<IReply[]>([]);
  const [repliesVisible, setRepliesVisible] = useState<boolean>(false);
  const [editingReplyId, setEditingReplyId] = useState<string | null>(null);
  const [showCommentEditBox, setShowCommentEditBox] = useState<boolean>(false);
  const [likesReply, setLikesReply] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commentEditBoxText, setCommentEditBoxText] = useState<string>("");
  const [deleteCommentModal, setDeleteCommentModal] = useState<boolean>(false);
  const [reportCommentModal, setReportCommentModal] = useState<boolean>(false);
  const [reportReplyCommentModal, setReportReplyCommentModal] = useState<boolean>(false);
  const [replyLikes, setReplyLikes] = useState<number>(0);
  const [commentLikes, setCommentLikes] = useState<number>(likesCount);
  const [deleteReplyCommentModal, setDeleteReplyCommentModal] = useState<boolean>(false);
  const [isUpdatingLike, setIsUpdatingLike] = useState<boolean>(false);
  const [reportTitle, setReportTitle] = useState<string>('');
  const [reportDescription, setReportDescription] = useState<string>('');
  const [reportEmail, setReportEmail] = useState<string>('');
  const [reportTitleReply, setReportTitleReply] = useState<string>('');
  const [reportDescriptionReply, setReportDescriptionReply] = useState<string>('');
  const [reportEmailReply, setReportEmailReply] = useState<string>('');
  const [reportErrorMessage, setReportErrorMessage] = useState<string>("");
  const [commentNotExist, setCommentNotExist] = useState<boolean>(false);
  const [flowInCommentRemixedBy, setFlowInCommentRemixedBy] = useState<string>("");
  const [flowInCommentFlowUsername, setFlowInCommentFlowUsername] = useState<string>("");
  const [flowInCommentsNumbers, setCommentsNumbers] = useState<string>("");
  const [flowShareCount, setShareCount] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const [lastClickTime, setLastClickTime] = useState<number>(0);
  const [clickCount, setClickCount] = useState<number>(0);
  const [isRateLimited, setIsRateLimited] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userPhotos, setUserPhotos] = useState<{ [key: string]: string }>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalRateLimit, setOpenModalRateLimit] = useState(false)

  const formatNumber = (number: number): string => {
    if (number === null || number === undefined) {
      return '0';
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number.toString();
  };

  const fetchUserPhoto = async (username: string): Promise<string> => {
    if (!username) return "/images/Flowroom_Default_Avatar.png";
    try {
      const userSnapshot = await firebase.firestore()
        .collection("users")
        .where("username", "==", username)
        .limit(1)
        .get();

      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        return userData.urlPhoto || "/images/Flowroom_Default_Avatar.png";
      } else {
        return "/images/Flowroom_Default_Avatar.png";
      }
    } catch (error) {
      console.error(`Error fetching user photo for username "${username}":`, error);
      return "/images/Flowroom_Default_Avatar.png";
    }
  };

  useEffect(() => {
    const fetchAllUserPhotos = async () => {
      const commentUsernames = [username];
      const replyUsernames = replies.map(reply => reply.username);
      const uniqueUsernames = Array.from(new Set([...commentUsernames, ...replyUsernames]));
      const usernamesToFetch = uniqueUsernames.filter(username => !(username in userPhotos));

      if (usernamesToFetch.length === 0) return;

      try {
        const photoPromises = usernamesToFetch.map(username => fetchUserPhoto(username));
        const photos = await Promise.all(photoPromises);
        const newUserPhotos: { [key: string]: string } = {};
        usernamesToFetch.forEach((username, index) => {
          newUserPhotos[username] = photos[index];
        });
        setUserPhotos(prev => ({ ...prev, ...newUserPhotos }));
      } catch (error) {
        console.error("Error fetching user photos:", error);
      }
    };

    if (replies.length > 0 || username) {
      fetchAllUserPhotos();
    }
  }, [replies, username, userPhotos]);

  useEffect(() => {
    if (commentId) {
      loadReplies(commentId);
    }
  }, [commentId]);

  useEffect(() => {
    setCommentLikes(isNaN(likesCount) ? 0 : likesCount);
  }, [likesCount]);

  useEffect(() => {
    if (replyId) {
      setRepliesVisible(true);
    }
    setTimeout(() => {
      const targetElementId = replyId ? `${replyId}` : false;
      const replyElement = targetElementId ? document.getElementById(targetElementId) : null;
      if (replyElement) {
        replyElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 1000);
  }, [replyId]);

  useEffect(() => {
    const hasFlowroomUrl = comment.match(linkRegex);
    if (!hasFlowroomUrl) {
      setThumbnailUrl("");
      return;
    }

    const flowIdRegex = /(?:www\.)?flowroom\.com\/room\/(\S+)/;
    const flowIds = hasFlowroomUrl
      .map((url) => {
        const match = url.match(flowIdRegex);
        return match ? match[1] : null;
      })
      .filter((flowId) => flowId);

    if (flowIds.length > 0) {
      const flowId = flowIds[0];
      const flowRef = firestore
        .collection("flows")
        .where("flowId", "==", flowId)
        .limit(1);
      flowRef
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No matching document");
            setThumbnailUrl("");
            return;
          }
          const data = querySnapshot.docs[0].data();
          if (data && data.thumbnail) {
            setThumbnailUrl(data.thumbnail);
            setFlowUsername(data.username);
            setFlowDate(data.date);
            setFlowInCommentId(data.flowId);
            setFlowInCommentProfilePic(data.urlPhoto);
            setFlowInCommentRemixedBy(data.remixedBy);
            setFlowInCommentFlowUsername(data.username);
            setShareCount(data.shareCount);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          setThumbnailUrl("error");
        });
    } else {
      setThumbnailUrl("");
    }
  }, [comment]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteComment = async () => {
    const commentIn = {
      commentId: commentId,
      flowId: flowId,
      userId: userId,
      comment: comment,
    };
    await CommentsStore.setComment(commentIn);
    await CommentsStore.setIsDeleteComment(true);
  };

  const flagComment = async () => {
    const commentIn = {
      commentId: commentId,
      flowId: flowId,
      userId: userId,
      flagUserId: myUserId,
      comment: comment,
    };
    await CommentsStore.setComment(commentIn);
    await CommentsStore.setIsFlagComment(true);
  };

  const handleReplyToggle = () => {
    setShowReplyBox(!showReplyBox);
  };

  const addCommentNotification = async (
    sender: string,
    receiver: string,
    commentContent: string,
    flowId: string
  ) => {
    const newNotification = {
      sender,
      receiver,
      type: "commentLeft",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: {
        commentContent,
        flowId,
      },
    };
    await firebase.firestore().collection("notifications").add(newNotification);
  };

  const addCommentReplyNotification = async (
    sender: string,
    receiver: string,
    commentContent: string,
    photoURL: string,
    flowId: string,
    commentId: string,
    replyId: string
  ) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "commentReplyLeft",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isSeen: false,
      flowId: flowId,
      replyId: replyId,
      commentId: commentId,
      additionalData: {
        commentContent: commentContent,
        photoURL: photoURL,
      },
    };

    await firebase.firestore().collection("notifications").add(newNotification);
  };

  const addMentionCommentReplyNotification = async (
    sender: string,
    receiver: string,
    commentContent: string,
    photoURL: string,
    newCommentId: string,
    replyId: string
  ) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "commentMentionReply",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isSeen: false,
      flowId: flowId,
      commentId: newCommentId,
      replyId: replyId,
      additionalData: {
        commentContent: commentContent,
        photoURL: photoURL,
      },
    };
    await firebase.firestore().collection("notifications").add(newNotification);
  };

  const addLikeCommentNotification = async (
    sender: string,
    receiver: string,
    commentContent: string,
    photoURL: string,
    flowId: string,
    commentId: string
  ) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "likeComment",
      commentId: commentId,
      flowId: flowId,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: {
        commentContent: commentContent,
        photoURL: photoURL,
      },
    };

    await firebase.firestore().collection("notifications").add(newNotification);
  };

  const loadReplies = async (commentId: string) => {
    const repliesSnapshot = await firebase.firestore().collection("commentReplies")
      .where("commentId", "==", commentId)
      .orderBy("timestamp", "desc")
      .get();

    const loadedReplies = await Promise.all(repliesSnapshot.docs.map(async doc => {
      const data = doc.data();
      const likesSnapshot = await firebase.firestore().collection("repliesLikes")
        .where("replyId", "==", doc.id)
        .get();
      const userLikeSnapshot = await firebase.firestore().collection("repliesLikes")
        .doc(`${currentUser?.uid}:${doc.id}`)
        .get();

      return {
        ...data,
        replyId: data.replyId,
        timestamp: data.timestamp.toDate(),
        likesCount: likesSnapshot.size,
        currentUserHasLiked: userLikeSnapshot.exists,
      };
    }));

    setReplies(loadedReplies);
  };

  const submitReply = async () => {
    const maxReplies = 5;
    const timeWindow = 10000;

    if (globalReplyCount >= maxReplies) {
      if (!isRateLimited) {
        setIsRateLimited(true);
        setErrorMessage("You've replied too frequently. Please wait a few seconds before trying again.");
        if (globalReplyResetTimeout) {
          clearTimeout(globalReplyResetTimeout);
        }
        globalReplyResetTimeout = setTimeout(() => {
          setIsRateLimited(false);
          globalReplyCount = 0;
          setErrorMessage('');
        }, timeWindow);
      }
      return;
    }

    globalReplyCount += 1;

    if (!replyText || isRateLimited) {
      return;
    }

    if (currentUser === null || currentUser.displayName === null) {
      CommentsStore.setIsShowModal(false);
      AuthenticationStore.setShowModal(true);
      return;
    }

    let normalizedReplyText = replyText.replace(/@\w+/g, mention => mention.toLowerCase());

    let newReplyId = new Hashids(uuid(), 10).encode(1, 2, 3);

    const newReply = {
      username: currentUser.displayName,
      text: normalizedReplyText,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      flowId: flowId,
      replyId: newReplyId,
      commentId: commentId,
      urlPhoto: currentUser.photoURL
    };

    addCommentReplyNotification(
      currentUser.displayName,
      room !== undefined ? room.username : EditorStore.username,
      "",
      currentUser.photoURL,
      flowId,
      commentId,
      newReplyId
    );

    const docRef = await firebase.firestore().collection("commentReplies").add(newReply);
    const docId = docRef.id;

    const mentions = normalizedReplyText.match(/@\w+/g);

    if (mentions) {
      mentions.forEach(mention => {
        let mentionedUser = mention.substring(1);
        addMentionCommentReplyNotification(currentUser.displayName, mentionedUser, "", currentUser.photoURL, commentId, newReplyId);
      });
    }

    setReplies(prevReplies => [{ ...newReply, timestamp: new Date(), firestoreId: docId }, ...prevReplies]);
    setReplyText("");
    setShowReplyBox(false);
  };

  const updateCommentReply = async (replyId: string, newText: string) => {
    const commentRepliesRef = firebase.firestore().collection("commentReplies");

    try {
      const querySnapshot = await commentRepliesRef.where("replyId", "==", replyId).get();

      if (querySnapshot.empty) {
        console.log("No matching documents found");
        return;
      }

      const batch = firebase.firestore().batch();

      querySnapshot.forEach(doc => {
        batch.update(commentRepliesRef.doc(doc.id), { text: newText });
      });

      await batch.commit();
      setShowEditBox(false);
    } catch (error) {
      console.error("Error updating comment replies: ", error);
    }
  };

  const updateComment = async (commentId: string, newText: string) => {
    const commentsRef = firebase.firestore().collection("comments");
    try {
      const querySnapshot = await commentsRef.where("commentId", "==", commentId).get();

      if (querySnapshot.empty) {
        console.log("No matching documents found");
        return;
      }

      const batch = firebase.firestore().batch();

      querySnapshot.forEach(doc => {
        batch.update(commentsRef.doc(doc.id), { comment: newText });
      });

      await batch.commit();
      setShowEditBox(false);
    } catch (error) {
      console.error("Error updating comment: ", error);
    }
  };

  const incrementLikesComments = async (commentId: string) => {
    if (!currentUser || !currentUser.displayName) {
      console.log("User not authenticated or display name missing.");
      return;
    }

    const currentTime = Date.now();
    if (currentTime - lastClickTime > 3000) {
      setClickCount(0);
    }

    setClickCount(prevCount => prevCount + 1);
    setLastClickTime(currentTime);

    if (clickCount >= 30) {
      console.log("Too many actions. Try again later.");
      WidgetStore.setErrorModalMessage("You clicked too many times. Please wait a few seconds and try again.");
      setOpenModalRateLimit(true);
      return;
    }

    setPending(true);
    const docRef = firebase.firestore().collection("commentsLikes").doc(`${currentUser.uid}:${commentId}`);
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      setCommentLikes(prev => Math.max(0, prev - 1));
      await docRef.delete();
    } else {
      setCommentLikes(prev => prev + 1);
      await docRef.set({
        commentId: commentId,
        likeId: `${currentUser.uid}:${commentId}`,
        userId: currentUser.uid,
        timestamp: new Date().toISOString(),
      });
    }
    setPending(false);
  };

  const incrementLikesReplies = async (replyId: string) => {
    if (isUpdatingLike || !currentUser) {
      console.log("Either updating is in progress or user is not logged in.");
      return;
    }

    const currentTime = Date.now();
    if (currentTime - lastClickTime > 3000) {
      setClickCount(0);
    }

    setClickCount(prevCount => prevCount + 1);
    setLastClickTime(currentTime);

    if (clickCount >= 30) {
      console.log("Too many actions. Try again later.");
      WidgetStore.setErrorModalMessage("You clicked too many times. Please wait a few seconds and try again.");
      setOpenModalRateLimit(true);
      return;
    }

    setIsUpdatingLike(true);

    const docRef = firebase.firestore().collection("repliesLikes").doc(`${currentUser.uid}:${replyId}`);
    const docSnapshot = await docRef.get();

    setReplies(replies => replies.map(reply => {
      if (reply.replyId === replyId) {
        const adjustment = docSnapshot.exists ? -1 : 1;
        return {
          ...reply,
          likesCount: Math.max(isNaN(reply.likesCount) ? 0 : reply.likesCount + adjustment, 0),
          currentUserHasLiked: !docSnapshot.exists
        };
      }
      return reply;
    }));

    if (docSnapshot.exists) {
      await docRef.delete();
    } else {
      await docRef.set({
        replyId: replyId,
        likeId: `${currentUser.uid}:${replyId}`,
        userId: currentUser.uid,
        timestamp: new Date().toISOString(),
      }, { merge: true });
    }

    setIsUpdatingLike(false);
  };

  const deleteReplyByReplyId = async (replyId: string) => {
    setDeleteReplyCommentModal(true);
    currentlySelectedReplyComment = replyId;
  };

  const deleteReplyByReplyIdConfirm = async (replyId: string) => {
    const docRef = firebase.firestore().collection("commentReplies").doc(replyId);

    try {
      await docRef.delete();
      setDeleteReplyCommentModal(false);
      setReplies(currentReplies => currentReplies.filter(reply => reply.replyId !== replyId));
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const handleLinkClick = (url: string, isImage: boolean) => {
    if (!isImage && !url.match(/(?:www\.)?flowroom\.com\/room\/\S+/)) {
      const userConfirm = window.confirm("You are about to leave this page. Continue?");
      if (userConfirm) {
        window.open(url, '_blank').focus();
      }
    } else if (isImage) {
      window.open(url, '_blank').focus();
    }
  };

const CommentTextRenderer: React.FC<{ text: string }> = ({ text }) => {
  const imageRegex = /(\bhttps?:\/\/\S*?\.(?:jpg|jpeg|gif|png|bmp))/gi;
  const linkRegex = /(\bhttps?:\/\/\S+)/gi;
  const flowroomRegex = /(?:www\.)?flowroom\.com\/room\/(\S+)/;

  const [flowData, setFlowData] = useState<any>({});
  const [flowIdFound, setFlowIdFound] = useState<boolean>(false);

  useEffect(() => {
    const match = text.match(flowroomRegex);
    if (match) {
      const flowId = match[1];
      const flowRef = firestore.collection("flows").where("flowId", "==", flowId).limit(1);
      flowRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setFlowData({
            thumbnailUrl: data.thumbnail,
            username: data.username,
            date: data.date,
            shareCount: data.shareCount,
            flowId: data.flowId
          });
          setFlowIdFound(true);
        } else {
          console.log("No matching document");
          setFlowIdFound(false);
        }
      }).catch(error => {
        console.error("Error getting document:", error);
        setFlowIdFound(false);
      });
    } else {
      setFlowIdFound(false);
    }
  }, [text]);

  const renderTextParts = () => {
    return text.split(/(\s+)/).map((segment, index) => {
      if (segment.startsWith('@')) {
        return (
          <Link to={`/${segment.slice(1)}`} key={index}>
            <span style={{ color: '#6671EE' }}>{segment}</span>
          </Link>
        );
      } else if (imageRegex.test(segment)) {
        return (
          <img
            key={index}
            src={segment}
            alt="Loaded content"
            onClick={() => handleLinkClick(segment, true)}
            style={{ maxWidth: '100%', maxHeight: '200px', display: 'inline-block', margin: '0 5px' }}
          />
        );
      } else if (linkRegex.test(segment)) {
        if (segment.match(flowroomRegex)) {
          return null;
        }
        return (
          <span
            key={index}
            onClick={() => handleLinkClick(segment, false)}
            style={{ color: '#0094FF', cursor: 'pointer', textDecoration: 'underline' }}
          >
            {segment}
          </span>
        );
      } else {
        return <span key={index}>{segment}</span>;
      }
    });
  };

  return (
    <>
      {renderTextParts()}
      {flowIdFound && flowData.thumbnailUrl && (
        <RemixItem
          thumbnailUrl={flowData.thumbnailUrl}
          username={flowData.username}
          remixedBy={flowInCommentRemixedBy}
          date={flowData.date}
          shareCount={flowData.shareCount}
          flowId={flowData.flowId}
        />
      )}
    </>
  );
};
  const saveReport = async () => {
    if (!reportTitle.trim() || !reportDescription.trim()) {
      setReportErrorMessage('Please fill in all required fields.');
      return;
    }

    try {
      const reportsRef = firebase.firestore().collection("commentReports");
      const existingReportQuery = await reportsRef
        .where("flowId", "==", flowId)
        .where("currentUser", "==", currentUser?.displayName)
        .get();

      if (!existingReportQuery.empty) {
        setReportErrorMessage('You already made a report for this comment.');
        return;
      }

      await reportsRef.add({
        title: reportTitle.trim(),
        description: reportDescription.trim(),
        email: reportEmail.trim(),
        commentId: commentId,
        flowId: flowId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        currentUser: currentUser?.displayName
      });

      setConfirm(true);
    } catch (error) {
      console.error("Error saving report: ", error);
      setReportErrorMessage('There was an error submitting your report. Please try again.');
    }
  };

  const saveReportReplies = async () => {
    if (!reportTitleReply.trim() || !reportDescriptionReply.trim()) {
      setReportErrorMessage('Please fill in all required fields.');
      return;
    }

    try {
      const reportsRepliesRef = firebase.firestore().collection("commentReportsReplies");
      const existingReportRepliesQuery = await reportsRepliesRef
        .where("flowId", "==", flowId)
        .where("currentUser", "==", currentUser?.displayName)
        .get();

      if (!existingReportRepliesQuery.empty) {
        setReportErrorMessage('You already made a report for this comment.');
        return;
      }

      await reportsRepliesRef.add({
        title: reportTitleReply.trim(),
        description: reportDescriptionReply.trim(),
        email: reportEmailReply.trim(),
        commentId: commentId,
        flowId: flowId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        currentUser: currentUser?.displayName
      });

      setConfirm(true);
    } catch (error) {
      console.error("Error saving report: ", error);
      setReportErrorMessage('There was an error submitting your report. Please try again.');
    }
  };

  return (
    <Card id={`comment-${commentId}`} className={classes.root} style={{ borderTop: '1px solid #3E3E3E', fontFamily: 'quicksand' }}>
      <CardHeader
        className={classes.header}
        avatar={
          <Link
            to={`/${username}`}
            onClick={() => {
              CommentsStore.setIsShowModal(false);
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                overflow: "hidden",
                borderRadius: "50%",
                backgroundImage: `url(${photoURL || "/images/Flowroom_Default_Avatar.png"})`,
                border: "0px solid #4FD975",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Link>
        }
        title={
          (
            <Link
              to={`/${username}`}
              onClick={() => {
                CommentsStore.setIsShowModal(false);
              }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "quicksand",
                }}
              >
                @{username}
              </p>
            </Link>
          ) || "Anonymous"
        }
        subheader={`${moment(date).fromNow()}`}
        action={
          <>
            <Menu
              id="comment-more"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{
                zIndex: 1000000,
                position: "absolute",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  flagComment();
                }}
                style={{
                  display: isSelf ? "none" : "flex",
                  fontSize: 16,
                }}
              >
                Report as inappropriate
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteComment();
                }}
                style={{
                  display: isSelf ? "flex" : "none",
                  fontSize: 16,
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
      />

      <CardContent
        style={{ paddingTop: 0, textAlign: "start", background: "#1C1C1C", fontWeight: 100, marginLeft: 7, paddingLeft: 0 }}
      >
        <div>
          <div style={{ display: 'flex' }}>
            <Typography variant="body2" color="textPrimary" component="p" style={{ display: 'flex', wordBreak: 'break-all', width: '100%', fontFamily: 'quicksand' }}>
              {comment.match(linkRegex) ? (
                <>
                  {comment
                    .replace(linkRegex, " ")
                    .split(/(\s+)/)
                    .map((part, index) => part.startsWith('@') ? (
                      <span key={index} style={{ color: 'blue', whiteSpace: 'pre-wrap' }}>
                        <CommentTextRenderer text={part} />
                      </span>
                    ) : (
                      <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
                        {part}
                      </span>
                    ))
                  }
                </>
              ) : (
                <p id={`comment-${commentId}-text`} style={{ fontFamily: 'quicksand', color: 'white' }}>
                  {comment.split(/(\s+)/).map((part, index) => part.startsWith('@') ? (
                    <Link to={`/${part.slice(1)}`} key={index}>
                      <span style={{ color: '#6671EE', whiteSpace: 'pre-wrap' }}>{part}</span>
                    </Link>
                  ) : (
                    <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
                      <CommentTextRenderer text={part} />
                    </span>
                  ))}
                </p>
              )}
            </Typography>
          </div>
          {thumbnailUrl ? (
            <RemixItem
              thumbnailUrl={thumbnailUrl}
              username={flowUsername}
              remixedBy={flowInCommentRemixedBy}
              date={flowDate}
              shareCount={flowShareCount}
              flowId={flowInCommentId}
            />
          ) : (
            <div>{thumbnailUrl === "error" ? "This flow could not be loaded." : ""}</div>
          )}
          <div style={{ display: 'flex', marginTop: 20 }}>
            <div onClick={() => incrementLikesComments(commentId)} style={{ color: 'white' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ height: '24px', width: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {commentLikes > 0 ? (
                    <img
                      src="/images/heart-icon-menu-selected-red.svg"
                      style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    />
                  ) : (
                    <img
                      src="/images/heart-menu-red.svg"
                      style={{ height: '24px', width: '24px', cursor: 'pointer' }}
                    />
                  )}
                </div>
                <p style={{
                  color: 'rgb(255, 92, 92)',
                  fontFamily: 'quicksand',
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginLeft: 8,
                  marginRight: 8,
                  minWidth: '20px',
                  textAlign: 'center'
                }}>
                  {formatNumber(commentLikes)}
                </p>
              </div>
            </div>

            {isSelf ? (
              <div onClick={() => {
                setShowCommentEditBox(!showCommentEditBox);
                const commentText = document.getElementById(`comment-${commentId}-text`)?.innerText || "";
                setCommentEditBoxText(commentText);
              }} style={{
                height: 25,
                width: 70,
                background: '#323232',
                borderRadius: 4,
                marginLeft: 10,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: 3,
                paddingLeft: 10,
                paddingRight: 10
              }}>
                <p style={{
                  fontFamily: 'quicksand',
                  color: '#F06263',
                  fontWeight: 'bold',
                  fontSize: 10,
                  marginRight: 0,
                  marginLeft: 0
                }}>Edit</p>
              </div>
            ) : ""}

            <div
              onClick={handleReplyToggle}
              style={{
                height: 25,
                width: 55,
                background: '#323232',
                borderRadius: 4,
                marginLeft: 7,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <p style={{
                color: '#F06263',
                fontFamily: 'quicksand',
                fontWeight: 'bold',
                fontSize: 11,
                marginRight: 0,
                marginLeft: 0
              }}>Reply</p>
            </div>

            <div style={{ display: 'flex' }}>
              {isSelf ? (
                <div onClick={() => {
                  setDeleteCommentModal(true);
                }} style={{
                  height: 25,
                  width: 70,
                  background: '#323232',
                  borderRadius: 4,
                  marginLeft: 10,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                  <img src="/images/trash.svg" style={{ height: 14, width: 14, marginLeft: 7 }} />
                  <p style={{
                    fontFamily: 'quicksand',
                    color: '#F06263',
                    fontWeight: 'bold',
                    fontSize: 10,
                    marginRight: 7,
                    marginLeft: 0
                  }}>Delete</p>
                </div>
              ) : (
                <div onClick={() => {
                  setReportCommentModal(true);
                }} style={{
                  height: 25,
                  width: 55,
                  background: '#323232',
                  borderRadius: 4,
                  marginLeft: 10,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                  <p style={{
                    fontFamily: 'quicksand',
                    color: '#F06263',
                    fontWeight: 'bold',
                    fontSize: 11,
                    marginRight: 0,
                    marginLeft: 0
                  }}>Report</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {showReplyBox && (
          <div>
            {isRateLimited && (
              <div style={{ color: '#F06263', textAlign: 'center' }}>
                <p style={{ fontSize: 14, fontFamily: 'quicksand', wordBreak: 'break-all', marginBottom: 10 }}>Too many comment posts! Please wait...</p>
              </div>
            )}
            <textarea
              value={replyText}
              onChange={(e) => {
                if (e.target.value.length <= 300) {
                  setReplyText(e.target.value);
                }
              }}
              placeholder="Write a reply..."
              maxLength="300"
              style={{
                width: '100%',
                height: '70px',
                border: 0,
                marginRight: 20,
                marginBottom: 10,
                paddingLeft: 20,
                color: 'rgb(254, 255, 254)',
                fontSize: 14,
                fontFamily: 'quicksand',
                background: 'rgb(50, 50, 50)',
                borderRadius: 10,
                paddingTop: 20,
                resize: 'none',
                outline: 'none',
                marginTop: 10,
                marginLeft: 5
              }}
            ></textarea>
            <div style={{ color: 'rgb(254, 255, 254)', marginBottom: '10px' }}>
              {replyText.length}/300
            </div>
            <div style={{ display: 'flex' }}>
              <div onClick={() => submitReply()} style={{
                height: 25,
                width: '50px',
                background: 'rgb(50, 50, 50)',
                borderRadius: 4,
                marginLeft: 7,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <p style={{
                  fontFamily: 'quicksand',
                  color: '#F06263',
                  fontWeight: 'bold',
                  fontSize: 11,
                  marginRight: 0,
                  marginLeft: 0
                }}>Post</p>
              </div>
              <div onClick={() => { setShowReplyBox(!showReplyBox) }} style={{
                height: 25,
                width: '50px',
                background: 'rgb(50, 50, 50)',
                borderRadius: 4,
                marginLeft: 7,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <p style={{
                  fontFamily: 'quicksand',
                  color: '#F06263',
                  fontWeight: 'bold',
                  fontSize: 11,
                  marginRight: 0,
                  marginLeft: 0
                }}>Cancel</p>
              </div>
            </div>
          </div>
        )}

        {showCommentEditBox && (
          <div>
            <textarea
              value={commentEditBoxText}
              onChange={(e) => {
                if (e.target.value.length <= 300) {
                  setCommentEditBoxText(e.target.value);
                }
              }}
              placeholder="Edit a comment..."
              maxLength="300"
              style={{
                width: '100%',
                height: '70px',
                border: 0,
                marginRight: 20,
                marginBottom: 10,
                paddingLeft: 20,
                color: 'rgb(254, 255, 254)',
                fontSize: 14,
                fontFamily: 'quicksand',
                background: 'rgb(50, 50, 50)',
                borderRadius: 10,
                paddingTop: 20,
                resize: 'none',
                outline: 'none',
                marginTop: 10,
                marginLeft: 5
              }}
            ></textarea>
            <div style={{ color: 'rgb(254, 255, 254)', marginBottom: '10px' }}>
              {commentEditBoxText.length}/300
            </div>
            <div style={{ display: 'flex' }}>
              <div onClick={() => {
                updateComment(commentId, commentEditBoxText);
                const commentElement = document.getElementById(`comment-${commentId}-text`);
                if (commentElement) {
                  commentElement.innerText = commentEditBoxText;
                }
                setShowCommentEditBox(false);
              }} style={{
                height: 25,
                width: '50px',
                background: 'rgb(50, 50, 50)',
                borderRadius: 4,
                marginLeft: 7,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <p style={{
                  fontFamily: 'quicksand',
                  color: '#F06263',
                  fontWeight: 'bold',
                  fontSize: 11,
                  marginRight: 0,
                  marginLeft: 0
                }}>Update</p>
              </div>
              <div onClick={() => { setShowCommentEditBox(!showCommentEditBox) }} style={{
                height: 25,
                width: '50px',
                background: 'rgb(50, 50, 50)',
                borderRadius: 4,
                marginLeft: 7,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <p style={{
                  fontFamily: 'quicksand',
                  color: '#F06263',
                  fontWeight: 'bold',
                  fontSize: 11,
                  marginRight: 0,
                  marginLeft: 0
                }}>Cancel</p>
              </div>
            </div>
          </div>
        )}

        {replies.length !== 0 ? (
          <div onClick={() => setRepliesVisible(!repliesVisible)} style={{ cursor: 'pointer', color: '#007BFF', display: 'flex', alignItems: 'center', marginLeft: 10, marginTop: 10 }}>
            <span style={{ fontFamily: 'quicksand', color: 'white', fontSize: 12, fontWeight: 'bold' }}>{`View ${replies.length} replies`}</span>
            {repliesVisible ? (
              <span style={{ color: 'white', display: 'flex', alignItems: 'center', marginTop: 3, marginLeft: 4, fontSize: 9 }}>▼</span>
            ) : (
              <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>▲</span>
            )}
          </div>
        ) : ""}

        {repliesVisible && (
          <div style={{ marginLeft: '20px', marginTop: replies.length !== 0 ? 20 : 0 }}>
            {replies.map((reply) => (
              <div id={`reply-${reply.replyId}`} key={reply.replyId} style={{ borderBottom: '1px solid rgb(62, 62, 62)', paddingBottom: 10, marginTop: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{
                    height: 30,
                    width: 30,
                    borderRadius: '50%',
                    marginRight: 10,
                    backgroundImage: `url(${userPhotos[reply.username] || "/images/Flowroom_Default_Avatar.png"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}></div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ color: 'white', fontWeight: 'bold', fontFamily: 'quicksand', fontSize: 12 }}>@{reply.username}</div>
                    <div style={{ color: 'white', fontWeight: 100, fontFamily: 'quicksand', opacity: 0.7 }}>{`${moment(reply.timestamp).fromNow()}`}</div>
                  </div>
                </div>
                <p id={`reply-${reply.replyId}-comment`} style={{ display: 'flex', color: 'white', fontFamily: 'quicksand', fontSize: 12, marginTop: 10, fontWeight: 'bold' }}>
                  <CommentTextRenderer text={reply.text} />
                </p>
                <div style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <div onClick={() => incrementLikesReplies(reply.replyId)} style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          height: '24px',
                          width: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          {reply.likesCount > 0 ? (
                            <img
                              src='/images/heart-icon-menu-selected-red.svg'
                              style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                              alt="Liked"
                            />
                          ) : (
                            <img
                              src='/images/heart-menu-red.svg'
                              style={{ height: '24px', width: '24px', cursor: 'pointer' }}
                              alt="Like"
                            />
                          )}
                        </div>
                        <span style={{
                          color: '#FF5C5C',
                          fontWeight: 'bold',
                          fontFamily: 'quicksand',
                          marginLeft: 7,
                          marginRight: 10,
                          minWidth: '30px',
                          textAlign: 'center',
                          display: 'inline-block'
                        }}>
                          {reply.likesCount <= 0 ? 0 : formatNumber(reply.likesCount)}
                        </span>
                      </div>
                    </div>

                    {reply.username !== currentUser?.displayName ? (
                      <div onClick={() => { setReportReplyCommentModal(true); }} style={{
                        height: 25,
                        width: 50,
                        background: 'rgb(50, 50, 50)',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginRight: 10
                      }}>
                        <p style={{
                          fontFamily: 'quicksand',
                          color: 'rgb(240, 98, 99)',
                          fontWeight: 'bold',
                          fontSize: 11,
                          marginRight: 0,
                          marginLeft: 0
                        }}>
                          Report
                        </p>
                      </div>
                    ) : (
                      <div onClick={() => { deleteReplyByReplyId(reply.replyId) }} style={{
                        height: 25,
                        background: 'rgb(50, 50, 50)',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        cursor: 'pointer',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}>
                        <img src="/images/trash.svg" style={{ height: 14, width: 14, marginRight: 7 }} alt="Delete" />
                        <p style={{
                          fontFamily: 'quicksand',
                          color: 'rgb(240, 98, 99)',
                          fontWeight: 'bold',
                          fontSize: 11,
                          marginRight: 0,
                          marginLeft: 0
                        }}>
                          Delete
                        </p>
                      </div>
                    )}
                  </div>

                  {editingReplyId === reply.replyId && showEditBox && (
                    <div>
                      <textarea
                        value={replyUpdateText}
                        onChange={(e) => {
                          if (e.target.value.length <= 300) {
                            setReplyUpdateText(e.target.value);
                          }
                        }}
                        placeholder="Write a reply..."
                        maxLength="300"
                        style={{
                          width: '100%',
                          height: '70px',
                          border: 0,
                          marginRight: 20,
                          marginBottom: 10,
                          paddingLeft: 20,
                          color: 'rgb(254, 255, 254)',
                          fontSize: 14,
                          fontFamily: 'quicksand',
                          background: 'rgb(50, 50, 50)',
                          borderRadius: 10,
                          paddingTop: 20,
                          resize: 'none',
                          outline: 'none',
                          marginTop: 10,
                          marginLeft: 5
                        }}
                      ></textarea>
                      <div style={{ color: 'rgb(254, 255, 254)', marginBottom: '10px' }}>
                        {replyUpdateText.length}/300
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div onClick={() => {
                          updateCommentReply(reply.replyId, replyUpdateText);
                          const replyElement = document.getElementById(`reply-${reply.replyId}-comment`);
                          if (replyElement) {
                            replyElement.innerText = replyUpdateText;
                          }
                        }} style={{
                          height: 25,
                          width: '50px',
                          background: 'rgb(50, 50, 50)',
                          borderRadius: 4,
                          marginLeft: 7,
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}>
                          <p style={{
                            fontFamily: 'quicksand',
                            color: '#F06263',
                            fontWeight: 'bold',
                            fontSize: 11,
                            marginRight: 0,
                            marginLeft: 0
                          }}>
                            Update
                          </p>
                        </div>
                        <div onClick={() => { setShowEditBox(!showEditBox) }} style={{
                          height: 25,
                          width: '50px',
                          background: 'rgb(50, 50, 50)',
                          borderRadius: 4,
                          marginLeft: 7,
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}>
                          <p style={{
                            fontFamily: 'quicksand',
                            color: '#F06263',
                            fontWeight: 'bold',
                            fontSize: 11,
                            marginRight: 0,
                            marginLeft: 0
                          }}>
                            Cancel
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <ReactModal
          isOpen={deleteCommentModal}
          ariaHideApp={false}
          closeTimeoutMS={200}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              padding: 0,
              zIndex: 1000000,
              height: 270,
              maxWidth: 350,
              borderRadius: 7,
              background: "#1c1c1c",
              border: "0px solid #2D2A2B",
              margin: "auto",
              overflow: "hidden",
            },
          }}
        >
          <div style={{
            height: 52,
            width: "100%",
            background: "#1C1C1C",
            display: "flex",
            alignItems: "center",
            borderRadius: "20px 0px 0px 0px",
            borderBottom: "4px solid #000"
          }}>
            <img
              src="/images/comments-modal-icon.svg"
              style={{
                position: "relative",
                cursor: "pointer",
                marginLeft: 15,
                marginRight: 10,
              }}
            />
            <p style={{
              color: "#FFF",
              fontSize: 18,
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              opacity: 0.7,
              marginBottom: 10,
              marginTop: 10,
              marginLeft: 10,
            }}>
              Delete comment
            </p>
            <img
              onClick={() => { setDeleteCommentModal(false); }}
              src="/images/close-circle.svg"
              style={{ position: "absolute", right: 25, cursor: "pointer" }}
            />
          </div>
          <div style={{ width: '100%', borderTop: '1px solid rgb(62, 62, 62)' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxWidth: 300, margin: 'auto' }}>
              <p style={{ color: '#fff', fontFamily: 'quicksand', marginTop: 20, maxWidth: 320 }}>
                Are you sure you want to permanently delete this comment?
              </p>
              <div onClick={() => { handleClose(); deleteComment(); }} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "11px 17px",
                gap: "10px",
                height: 37,
                background: "#4FD975",
                borderRadius: 10,
                width: "100%",
                margin: "auto",
                border: '1px solid #4FD975',
                marginTop: 20,
                cursor: "pointer",
              }}>
                <p style={{
                  color: "#000",
                  fontFamily: "quicksand",
                  fontSize: 15,
                  cursor: 'pointer'
                }}>
                  Yes, delete this comment
                </p>
              </div>
              <div onClick={() => { setDeleteCommentModal(false); }} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                height: 37,
                background: "rgb(28, 28, 28)",
                borderRadius: 10,
                width: "100%",
                margin: "auto",
                border: '1px solid #4FD975',
                marginTop: 20,
                cursor: "pointer",
                marginBottom: 20
              }}>
                <p style={{
                  color: "#4FD975",
                  fontFamily: "quicksand",
                  fontSize: 15,
                  cursor: 'pointer'
                }}>
                  Cancel
                </p>
              </div>
              <style jsx>{`
        .button-container {
          width: 48%;
          height: 42px;
          border: 1px solid #4FD975;
          border-radius: 10px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        }

        @media (max-width: 600px) {
          .button-container {
            width: 100%;
          }
        }

      .dropdown-container {
        position: relative;
      }
      .dropdown-display {
        background-color: #1c1c1c !important;
        padding: 7px !important;
        border: none;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        border: 1px solid #4fd975;
        color: white;
        font-family: "Quicksand";
        overflow: hidden;
        border-radius: 20px;
      }
      .dropdown-list {
        position: absolute;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #1c1c1c;
        border: 0px solid #ddd;
        border-radius: 5px;
        width: 100%;
        z-index: 100;
        bottom: -59px !important;
        border-left: 1px solid #4fd975 !important;
        border-bottom: 1px solid #4fd975 !important;
        border-right: 1px solid #4fd975 !important;
        color: white;
        font-family: quicksand;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow-y: auto;
        max-height: 200px;

        scrollbar-width: thin;
        scrollbar-color: #4fd975 #1c1c1c;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #1c1c1c;
        }
        &::-webkit-scrollbar-thumb {
          background: #4fd975;
          border-radius: 4px;
        }
      }
      .dropdown-list li {
        padding: 10px;
        cursor: pointer;
      }
      `}</style>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={deleteReplyCommentModal}
          ariaHideApp={false}
          closeTimeoutMS={200}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              padding: 0,
              zIndex: 1000000,
              height: 270,
              maxWidth: 350,
              borderRadius: 7,
              background: "#1c1c1c",
              border: "0px solid #2D2A2B",
              margin: "auto",
              overflow: "hidden",
            },
          }}
        >
          <div style={{
            height: 52,
            width: "100%",
            background: "#1C1C1C",
            display: "flex",
            alignItems: "center",
            borderRadius: "20px 0px 0px 0px",
            borderBottom: "4px solid #000"
          }}>
            <img
              src="/images/comments-modal-icon.svg"
              style={{
                position: "relative",
                cursor: "pointer",
                marginLeft: 15,
                marginRight: 10,
              }}
            />
            <p style={{
              color: "#FFF",
              fontSize: 18,
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              opacity: 0.7,
              marginBottom: 10,
              marginTop: 10,
              marginLeft: 10,
            }}>
              Delete comment
            </p>
            <img
              onClick={() => { setDeleteReplyCommentModal(false); }}
              src="/images/close-circle.svg"
              style={{ position: "absolute", right: 25, cursor: "pointer" }}
            />
          </div>
          <div style={{ width: '100%', borderTop: '1px solid rgb(62, 62, 62)' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxWidth: 300, margin: 'auto' }}>
              <p style={{ color: '#fff', fontFamily: 'quicksand', marginTop: 20, maxWidth: 320 }}>
                Are you sure you want to permanently delete this comment?
              </p>
              <div onClick={() => { handleClose(); deleteReplyByReplyIdConfirm(currentlySelectedReplyComment || ""); }} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "11px 17px",
                gap: "10px",
                height: 37,
                background: "#4FD975",
                borderRadius: 10,
                width: "100%",
                margin: "auto",
                border: '1px solid #4FD975',
                marginTop: 20,
                cursor: "pointer",
              }}>
                <p style={{
                  color: "#000",
                  fontFamily: "quicksand",
                  fontSize: 15,
                  cursor: 'pointer'
                }}>
                  Yes, delete this comment
                </p>
              </div>
              <div onClick={() => { setDeleteReplyCommentModal(false); }} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                height: 37,
                background: "rgb(28, 28, 28)",
                borderRadius: 10,
                width: "100%",
                margin: "auto",
                border: '1px solid #4FD975',
                marginTop: 20,
                cursor: "pointer",
                marginBottom: 20
              }}>
                <p style={{
                  color: "#4FD975",
                  fontFamily: "quicksand",
                  fontSize: 15,
                  cursor: 'pointer'
                }}>
                  Cancel
                </p>
              </div>
              <style jsx>{`
        .button-container {
          width: 48%;
          height: 42px;
          border: 1px solid #4FD975;
          border-radius: 10px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        }

        @media (max-width: 600px) {
          .button-container {
            width: 100%;
          }
        }

      .dropdown-container {
        position: relative;
      }
      .dropdown-display {
        background-color: #1c1c1c !important;
        padding: 7px !important;
        border: none;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        border: 1px solid #4fd975;
        color: white;
        font-family: "Quicksand";
        overflow: hidden;
        border-radius: 20px;
      }
      .dropdown-list {
        position: absolute;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #1c1c1c;
        border: 0px solid #ddd;
        border-radius: 5px;
        width: 100%;
        z-index: 100;
        bottom: -59px !important;
        border-left: 1px solid #4fd975 !important;
        border-bottom: 1px solid #4fd975 !important;
        border-right: 1px solid #4fd975 !important;
        color: white;
        font-family: quicksand;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow-y: auto;
        max-height: 200px;

        scrollbar-width: thin;
        scrollbar-color: #4fd975 #1c1c1c;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #1c1c1c;
        }
        &::-webkit-scrollbar-thumb {
          background: #4fd975;
          border-radius: 4px;
        }
      }
      .dropdown-list li {
        padding: 10px;
        cursor: pointer;
      }
      `}</style>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          ariaHideApp={true}
          isOpen={reportCommentModal}
          closeTimeoutMS={200}
          onAfterClose={() => {
            setReportCommentModal(false);
            setConfirm(false);
            setReportTitle("");
            setReportEmail("");
            setReportDescription("");
            setReportErrorMessage("");
          }}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.8)",
              opacity: 1,
            },
            content: {
              position: "relative",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "0px solid rgb(64, 255, 232)",
              backgroundColor: "#1C1C1C",
              overflow: "hidden",
              borderRadius: "20px",
              outline: "none",
              padding: "0px",
              zIndex: 1000000,
              margin: "auto",
              width: "calc(100% - 50px)",
              maxWidth: "450px",
              borderRadius: 20,
              overflow: "hidden",
            },
          }}
        >
          <div style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            padding: "7px 25px",
            borderBottom: "1px solid #3E3E3E",
            alignItems: "center",
          }}>
            <img
              style={{
                width: 19.92,
                height: 19.76,
              }}
              src="/images/report-modal-icon.svg"
            />
            <div>
              <p style={{
                color: "white",
                fontFamily: "quicksand",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 14,
                opacity: 0.7,
                marginBottom: 10,
                marginTop: 10,
                marginLeft: 10,
              }}>
                Report this comment
              </p>
            </div>
            <div onClick={() => { setReportCommentModal(false); }} style={{ cursor: "pointer" }}>
              <img
                src="/images/close-circle-report.svg"
                style={{ position: "absolute", right: 20, bottom: 17 }}
              />
            </div>
          </div>
          {confirm === false ? (
            <div style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: 55,
              padding: "20px 25px",
              paddingBottom: 30,
            }}>
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
                marginTop: 10,
                marginBottom: 12,
              }}>
                Report title
              </p>
              <input
                onChange={(e) => { setReportTitle(e.target.value); }}
                className="formInputOutline"
                type="text"
                style={{
                  height: 40,
                  width: "100%",
                  marginBottom: 5,
                  borderRadius: 10,
                  paddingLeft: 10,
                  backgroundColor: "#323232",
                  border: "none",
                  fontSize: 15,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                  opacity: 0.36,
                }}
                placeholder={"Report title"}
                value={reportTitle}
              />
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
                marginTop: 12,
              }}>
                Descriptions
              </p>

              <textarea
                className="formInputOutline"
                onChange={(e) => { setReportDescription(e.target.value); }}
                style={{
                  height: 170,
                  width: "100%",
                  borderRadius: 10,
                  background: "#323232",
                  opacity: 0.36,
                  marginTop: 15,
                  marginBottom: 10,
                  resize: "none",
                  border: "none",
                  padding: 10,
                  fontSize: 14,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                }}
                value={reportDescription}
                placeholder="Please give as much detail as possible, such as how to reproduce your bug, what or device"
              ></textarea>
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
              }}>
                Email (Optional)
              </p>

              <input
                onChange={(e) => { setReportEmail(e.target.value); }}
                className="formInputOutline"
                type="text"
                style={{
                  height: 33,
                  width: "100%",
                  marginBottom: 5,
                  borderRadius: 10,
                  paddingLeft: 10,
                  background: "#323232",
                  opacity: 0.36,
                  border: "none",
                  marginTop: 15,
                  fontSize: 15,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                }}
                placeholder={"Email (Optional)"}
                value={reportEmail}
              />
              <p style={{ color: '#FF5C5C', fontSize: 12, fontFamily: 'quicksand', textAlign: 'center', marginTop: 10 }}>{reportErrorMessage}</p>
              <div
                onClick={() => { saveReport(); }}
                style={{
                  backgroundColor: "#4FD975",
                  height: 35,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                  marginTop: 15,
                  cursor: "pointer",
                }}
              >
                <p style={{
                  color: "#222222",
                  fontFamily: "quicksand",
                  fontSize: 15,
                }}>
                  Submit
                </p>
              </div>
            </div>
          ) : (
            <div style={{
              height: "calc(100vh - 400px)",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: 55,
              padding: "20px 25px",
              maxHeight: 500,
              alignContent: 'center',
              justifyContent: 'center'
            }}>
              <p style={{ color: "white", fontFamily: "quicksand", fontSize: 30, textAlign: 'center' }}>
                Thank you!
              </p>
              <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20, textAlign: 'center', fontWeight: 100 }}>
                Your report has been submitted.
              </p>
            </div>
          )}
          <div></div>
        </ReactModal>

        <ReactModal
          ariaHideApp={true}
          isOpen={reportReplyCommentModal}
          closeTimeoutMS={200}
          onAfterClose={() => {
            setReportReplyCommentModal(false);
            setConfirm(false);
            setReportTitleReply("");
            setReportEmailReply("");
            setReportDescriptionReply("");
            setReportErrorMessage("");
          }}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.8)",
              opacity: 1,
            },
            content: {
              position: "relative",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "0px solid rgb(64, 255, 232)",
              backgroundColor: "#1C1C1C",
              overflow: "hidden",
              borderRadius: "20px",
              outline: "none",
              padding: "0px",
              zIndex: 1000000,
              margin: "auto",
              width: "calc(100% - 50px)",
              maxWidth: "450px",
              borderRadius: 20,
              overflow: "hidden",
            },
          }}
        >
          <div style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            padding: "7px 25px",
            borderBottom: "1px solid #3E3E3E",
            alignItems: "center",
          }}>
            <img
              style={{
                width: 19.92,
                height: 19.76,
              }}
              src="/images/report-modal-icon.svg"
            />
            <div>
              <p style={{
                color: "white",
                fontFamily: "quicksand",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 14,
                opacity: 0.7,
                marginBottom: 10,
                marginTop: 10,
                marginLeft: 10,
              }}>
                Report this comment
              </p>
            </div>
            <div onClick={() => { setReportReplyCommentModal(false); }} style={{ cursor: "pointer" }}>
              <img
                src="/images/close-circle-report.svg"
                style={{ position: "absolute", right: 20, bottom: 17 }}
              />
            </div>
          </div>
          {confirm === false ? (
            <div style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: 55,
              padding: "20px 25px",
              paddingBottom: 30,
            }}>
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
                marginTop: 10,
                marginBottom: 12,
              }}>
                Report title
              </p>
              <input
                onChange={(e) => { setReportTitleReply(e.target.value); }}
                className="formInputOutline"
                type="text"
                style={{
                  height: 40,
                  width: "100%",
                  marginBottom: 5,
                  borderRadius: 10,
                  paddingLeft: 10,
                  backgroundColor: "#323232",
                  border: "none",
                  fontSize: 15,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                  opacity: 0.36,
                }}
                placeholder={"Report title"}
                value={reportTitleReply}
              />
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
                marginTop: 12,
              }}>
                Descriptions
              </p>

              <textarea
                className="formInputOutline"
                onChange={(e) => { setReportDescriptionReply(e.target.value); }}
                style={{
                  height: 170,
                  width: "100%",
                  borderRadius: 10,
                  background: "#323232",
                  opacity: 0.36,
                  marginTop: 15,
                  marginBottom: 10,
                  resize: "none",
                  border: "none",
                  padding: 10,
                  fontSize: 14,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                }}
                value={reportDescriptionReply}
                placeholder="Please give as much detail as possible, such as how to reproduce your bug, what or device"
              ></textarea>
              <p style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                opacity: 0.7,
              }}>
                Email (Optional)
              </p>

              <input
                onChange={(e) => { setReportEmailReply(e.target.value); }}
                className="formInputOutline"
                type="text"
                style={{
                  height: 33,
                  width: "100%",
                  marginBottom: 5,
                  borderRadius: 10,
                  paddingLeft: 10,
                  background: "#323232",
                  opacity: 0.36,
                  border: "none",
                  marginTop: 15,
                  fontSize: 15,
                  color: "white",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                }}
                placeholder={"Email (Optional)"}
                value={reportEmailReply}
              />
              <p style={{ color: '#FF5C5C', fontSize: 12, fontFamily: 'quicksand', textAlign: 'center', marginTop: 10 }}>{reportErrorMessage}</p>

              <div
                onClick={() => { saveReportReplies(); }}
                style={{
                  backgroundColor: "#4FD975",
                  height: 35,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                  marginTop: 15,
                  cursor: "pointer",
                }}
              >
                <p style={{
                  color: "#222222",
                  fontFamily: "quicksand",
                  fontSize: 15,
                }}>
                  Submit
                </p>
              </div>
            </div>
          ) : (
            <div style={{
              height: "calc(100vh - 400px)",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: 55,
              padding: "20px 25px",
              maxHeight: 500,
              alignContent: 'center',
              justifyContent: 'center'
            }}>
              <p style={{ color: "white", fontFamily: "quicksand", fontSize: 30, textAlign: 'center' }}>
                Thank you!
              </p>
              <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20, textAlign: 'center', fontWeight: 100 }}>
                Your report has been submitted.
              </p>
            </div>
          )}
          <div></div>
        </ReactModal>

        <RateLimit
          openModalRateLimit={openModalRateLimit}
          closeError={() => { setOpenModalRateLimit(false); }}
        />
      </CardContent>
    </Card>
  );
};

export default CommentCard;
