import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class TabStore {
  constructor() {
    makeAutoObservable(this);
  }
  isRemixSelected = false;
  isCodeSelected = false;
  isPostSelected = false;
  isShareSelected = false;
  isDetailsSelected = false;
  isLikesSelected = false;
  isCommentsSelected = false;
  isMoreSelected = false;
  selectedTab = null;
  isCommentsSelected = false;
  IsPanelOpen = false;
  closePanel = false;

  setIsShowErrorModal(isShow) {
    this.isShowModal = isShow;
  }
  setIsRemixSelected(bool) {
    this.isRemixSelected = bool;
  }
  setIsCodeSelected(bool) {
    this.isCodeSelected = bool;
  }
  setIsPostSelected(bool) {
    this.isPostSelected = bool;
  }
  setIsShareSelected(bool) {
    this.isShareSelected = bool;
  }
  setIsDetailSelected(bool) {
    this.isDetailSelected = bool;
  }
  setIsLikesSelected(bool) {
    this.isLikesSelected = bool;
  }
  setIsCommentsSelected(bool) {
    this.isCommentsSelected = bool;
  }
  setIsMoreSelected(bool) {
    this.isMoreSelected = bool;
  }
  setSelectedTab(param) {
    this.selectedTab = param;
  }
  setIsCommentsSelected(param){
    this.commentsSelected = param;
  }
  setIsPanelOpen(param){
    this.IsPanelOpen = param;
  }
  setClosePanel(param){
    this.closePanel = param;
  }
}

export const TabStoreContext = createContext(new TabStore());
