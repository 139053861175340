import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";

const ErrorModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    // alert(isMobile);
    setOpenClose(props.openModalError);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.openModalError]);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={openClose}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "350px",
        },
      }}
    >
           <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        {/* <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        /> */}

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
            textAlign:'center',
            width:'100%'
          }}
        >
          Block User
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            setOpenClose(false);
            props.closeError();          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: 0,
          padding: "0px 25px 30px",
          borderTop: '1px solid #3E3E3E'
        }}
      >
        {/* begin */}

        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          <img
            src="/images/block-icon.svg"
            style={{
              height: 90,
              width: 90,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {WidgetStore.errorModalMessage === 'You can not block yourself' ? '' : 'User Blocked'}
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            {WidgetStore.errorModalMessage}
          </p>
          <div
            onClick={() => {
              setOpenClose(false);
              props.closeError();
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 40,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              Ok
            </p>
          </div>
        </div>
      </div>

      <div></div>
    </ReactModal>
  );
});

export default React.memo(ErrorModal);
