import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class CommentsStore {
  constructor() {
    makeAutoObservable(this);
  }

  isShowModal = false;
  isLoaded = false;
  isAddComment = false;
  isDeleteComment = false;
  isFlagComment = false;
  commentsCount = 0;
  flowId = "";
  room = {};
  incrementCommentsCount = false;
  decrementCommentsCount = false;
  setIsShowModal(boolean) {
    this.isShowModal = boolean;
  }

  setIsLoaded(boolean) {
    this.isLoaded = boolean;
  }

  setIsAddComment(boolean) {
    this.isAddComment = boolean;
  }

  setIsDeleteComment(boolean) {
    this.isDeleteComment = boolean;
  }

  setIsFlagComment(boolean) {
    this.isFlagComment = boolean;
  }

  setComment(comment) {
    this.comment = comment;
  }

  setCommentsCount(commentsCount) {
    this.commentsCount = commentsCount;
  }

  setIncrementCommentsCount(bool) {
    this.incrementCommentsCount = bool;
  }

  setDecrementCommentsCount(bool) {
    this.decrementCommentsCount = bool;
  }

  setFlowId(flowId) {
    this.flowId = flowId;
    this.commentsCount = 0;
  }

  setRoom(room) {
    this.room = room;
  }
}

export const CommentsStoreContext = createContext(new CommentsStore());
