import React, { useEffect, useState } from "react";

const ProgressBar = ({ duration = 100, maxDuration = 10000 }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((oldValue) => {
        const increment = (100 / maxDuration) * duration;
        const newValue = oldValue + increment;
        return newValue > 100 ? 100 : newValue;
      });
    }, duration);

    return () => clearInterval(interval);
  }, [duration, maxDuration]);

  const progressContainerStyle = {
    width: "100%",
    backgroundColor: "#222222",
    borderRadius: "5px",
    overflow: "hidden",
    maxWidth: 200,
  };

  const progressBarStyle = {
    width: `${value}%`,
    height: "4px",
    backgroundImage: "url('../Progress_Mask.svg')", // Update this path to the correct one
    backgroundSize: "cover", // Ensure the image covers the bar correctly; you might need to adjust this
    borderRadius: "0px",
    transition: `width ${duration}ms linear`,
    backgroundRepeat:'no-repeat',
    transform:'scale(1.1)'
  };

  return (
    <div style={progressContainerStyle}>
      <div style={progressBarStyle}></div>
    </div>
  );
};

export default ProgressBar;
