import {
  faExclamationTriangle,
  faTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@material-ui/core/Radio";
import Slider from "@material-ui/core/Slider";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import "@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css";
import * as S3 from "aws-sdk/clients/s3";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import Select, { components } from "react-select";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
//types: image, text, audio, element
// flowroom.Widget([
//   { class: "", id: "", var: "", url:"", func: function () {}, tag: "", type: "image" },
// ]);
//parent.window.flowroom_Widget = [];
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../store/RemixableStore";
import { WidgetStoreContext } from "../../store/WidgetStore";
import { TabStoreContext } from "../../store/TabStore.js"
import { firebase } from "../firebase/firebase";
import AITrainer from "./AITrainer.js";
import AIWidget from "./AIWidget.js";
import { inspect } from "./db";
import TextComponent from "./TextComponent.js";

let currentUser = firebase.auth().currentUser;
const s3 = new S3();

let widgets = [];
let optionsJump = [];
let colorSelected = [];
let initialCall = false;
let addImage;
let addImageFuncCalls = 0;
let addTextFuncCalls = 0;
let ColorInstance = -1;
let getText;
let searchArray;
let textFromResponse;
let functionToCall;
let imageToReplaceArray;
let searchQueryIndex = 0;
let imageIndexSelect = 0;
let alreadyLoadedModel = false;
let textIndexSelect = 0;
let colorIndexSelect = 0;
let sliderIndexSelect = 0;
let switchIndexSelect = 0;
let selectIndexSelect = 0;
let checkboxIndexSelect = 0;
let calendarIndexSelect = 0;
let rateCounter = 0;
let IndexSelect = 0;
let typingTimer;
let actualInstanceIncrement = 0;
let startingTextIncrement = [];
let startingImageIncrement = [];
let hiddenWidgetsAlreadyLoaded = false;

let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let lastSegment = parts.pop() || parts.pop();

widgets.map((widget, i) => {
  if (widget.widget === "ColorSelect") {
    colorSelected.push({
      color: widgets[i].color.hex,
      instance: widgets[i].instance,
    });
  }
});

const customStyles = {
  indicatorsContainer: (provided, state) => ({
    backgroundColor: "#1C1C1C",
    borderRadius: 4,
    color: "white",
  }),

  control: (_, { selectProps: { width, backgroundColor, height } }) => ({
    height: 32,
    backgroundColor: "#1C1C1C",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 15,
    padding: "0px",
    fontFamily: "quicksand",
    color: "white",
    minHeight: 3,
    //width: "calc(100% - 50px)",

    overflow: "hidden",
    border: "1px solid #4FD975",
  }),

  dropdownIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      backgroundColor: "#1C1C1C",
      // height: 30,
      fontSize: 20,

      borderRadius: 4,
      padding: "0px",
      color: "white",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      fontFamily: "quicksand",
      padding: "0px 10px",
      // width: "calc(100% - 50px)",
    };
  },
  menu: (provided, state) => {
    return {
      // ...provided,
      fontFamily: "quicksand",
      backgroundColor: "#1C1C1C",
      overflow: "hidden",
      fontSize: 14,
      position: "absolute",
      top: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,

      // borderRadius: 15,
      // width: "calc(100% - 50px)",
      // color: state.selectProps.menuColor,
    };
  },
  placeholder: (provided, state) => {
    return {
      fontFamily: "quicksand",
      color: "white",
      alignItems: "auto",
      justifyContent: "space-between",
      display: "flex",
      fontSize: 12,
      backgroundColor: "#1C1C1C",
      // width: "calc(100% - 50px)",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      // marginTop: 5,
      fontSize: "10px",
      height: 90,
      marginRight: 10,
      marginTop: 10,
      // width: "calc(100% - 50px)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      // height: 30,
      fontSize: 14,

      padding: "0px",
      color: "white",
      fontFamily: "quicksand",
      // width: "calc(100% - 50px)",
    };
  },
  option: (base) => ({
    ...base,
    height: "100%",
    width: "calc(100vw - 50px)",
  }),
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    // backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 244,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#222222",
    border: "1px solid #222222",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "red",
    },
  },
}))(InputBase);
const radioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "4px solid #2d2a2b",
    width: 30,
    height: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#2d2a2b",
    color: "white",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '$root.Mui-focusVisible &': {
    // outline: '1px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
  },
  checkedIcon: {
    backgroundColor: "#4FD975",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      color: "white",
    },
    "input:hover ~ &": {
      backgroundColor: "#4FD975",
      color: "white",
    },
  },
});
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const CaretDownIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faTriangle}
      color="#A962F1"
      style={{
        display: "block",
        position: "absolute",
        right: "10px",
        top: "10px",
        transform: "rotate(180deg)",
        fontSize: "15px",
      }}
    />
  );
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="../dropdown-widget-icon.svg" />
    </components.DropdownIndicator>
  );
};
// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
const PrettoSlider = withStyles({
  root: {
    color: "#A962F1",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CssTextField = withStyles({
  typography: {
    fontFamily: "quicksand",
  },
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

function isValid(str) {
  return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

const removeAllButLast = (string, token) => {
  /* Requires STRING not contain TOKEN */
  var parts = string.split(token);
  return parts.slice(0, -1).join("") + token + parts.slice(-1);
};

var current_style;
function showButtons(style) {
  if (style == current_style) {
    return;
  }
}

// function emailButton() {
//   if (recognizing) {
//     recognizing = false;
//     recognition.stop();
//   } else {
//     createEmail();
//   }
//   email_button.style.display = "none";
//   email_info.style.display = "inline-block";
//   // showInfo("");
// }

var two_line = /\n\n/g;
var one_line = /\n/g;
function linebreak(s) {
  return s.replace(two_line, "<p></p>").replace(one_line, "<br>");
}
var first_char = /\S/;
function capitalize(s) {
  return s.replace(first_char, function (m) {
    return m.toUpperCase();
  });
}

const updateFlow = () => {};

let h = 0;
let text = "";
let passDataToIframe = (functionToCall, data) => {
  try {
    let iframe = document.getElementById("undefined_output_frame");
    if (iframe !== undefined && iframe !== null) {
      let ev = `let iframe = document.getElementById(
                    "undefined_output_frame"
                  );
                  if (iframe !== undefined && iframe !== null) {
                    iframe.contentWindow.${functionToCall}('${data}')
                  }`;
      console.log("function called", ev);
      try {
        eval(ev);
      } catch (e) {}
    }
    // setColor(color.hex);
  } catch (e) {
    console.log(e);
  }
};
let imageArr = [];
let textArr = [];
let alreadyAddedWidget = [];
const ControlWidget = observer((props) => {
  const classes = useStyles();
  const FlowStore = useContext(FlowStoreContext);

  const [imageArray, setImageArray] = useState([]);

  // useState(0);

  const RemixableStore = useContext(RemixableStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const DHTMLStore = useContext(DHTML_StoreContext);
  const [widgetType, setWidgetType] = useState(false);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const [value, setValue] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [checked, setChecked] = React.useState(true);

  const [colorSel, setColor] = useState([]);

  const [giphy, setGiphy] = useState([]);
  const [jumpTo, setJumpTo] = useState([]);
  const [val, setVal] = useState([0, 40]);
  const [remixAIText, setRemixAIText] = useState("");
  const [template, setTemplate] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [charLimit, setCharLimit] = useState(0);
  const [btnText, setBtnText] = useState("");
  const [bottom, setBottom] = useState(false);
  const [useModel, setUseModel] = useState(false);
  const [gptConfirmation, setGPTConfirmation] = useState(false);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const [isShowCheckoutForm, setIsShowCheckoutForm] = useState(false);
  const [aiWidgetVsible, setAIWidgetVsible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [bucketInterval, setBucketInterval] = React.useState(5000);
  const [bucketCapacity, setBucketCapacity] = React.useState(10);
  const [key, setKey] = React.useState("My Key");
  const [result, setResult] = React.useState("");
  const [taking, setTaking] = React.useState(false);
  const [tokenState, setTokenState] = React.useState(inspect(key));
  const [showHiddenRemixWidgets, setShowHiddenRemixWidgets] = useState(false);
  const [showHiddenOption, setShowHiddenOption] = useState(false);
  const [dontShow, setDontShow] = useState(false);
  const TabStore = useContext(TabStoreContext);

 

  // React.useEffect(() => {
  //   const interval = setInterval(() => setTokenState(inspect(key)), 100);
  //   return () => clearInterval(interval);
  // }, [key]);
  // const [now, setNow] = React.useState(new Date());
  // React.useEffect(() => {
  //   const interval = setInterval(() => setNow(new Date()), 100);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        console.log("Received message:", event.data);
        // remaining code...
        if (
          event.data.widgetList !== null &&
          event.data.widgetList !== undefined &&
          event.data.widgetList !== []
        ) {
          WidgetStore.setWidgets(event.data.widgetList);
        }
      },
      false
    );
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  let imageIndex = WidgetStore.imageIndexes;

  let textIndex = WidgetStore.textInputIndexes;

  let colorIndex = WidgetStore.colorSelectIndexes;

  let getStartingTextIncrementNum = () => {
    for (let i = 0; i < WidgetStore.widgets.length; i++) {
      if (WidgetStore.widgets[i].widget === "TextInput") {
        textArr.push(WidgetStore.widgets[i].actualInstance - 1);
      }
    }
    return textArr;
  };

  let getStartingImageIncrementNum = () => {
    for (let i = 0; i < WidgetStore.widgets.length; i++) {
      if (WidgetStore.widgets[i].widget === "ImageUploader") {
        imageArr.push(WidgetStore.widgets[i].actualInstance - 1);
      }
    }
    return imageArr;
  };

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const EditorStore = useContext(EditorStoreContext);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  // const [jumpTo, setJumpTo] = useState([]);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  /* The purpose of this new approach is to not have everything seen in a library and be able to control variables attached to window in the iframe (contentWindow)
    and to no longer need the remix JS approach */

  /* this works the same as before. No need to assign values within the editor */
  /* changes images in the flow the same way, just now with urls it'll find and replace or with variables it'll change them */
  /* no need for remix JS */
  const handleSwitchChange = (event, switchName) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    window["flow_widget_type_" + switchName] = event.target.checked;
    //RemixableStore.setRenderHTML(true);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };
  const TitlebarImageList = (props) => {
    const classes = useStyles();
    // if (
    //   props.defaultValue !== undefined &&
    //   props.defaultValue !== null &&
    //   props.defaultValue.trim() !== ""
    // ) {
    //   window["flow_widget_type_" + props.name] = props.defaultValue;
    //   RemixableStore.setRenderHTML(true);
    // }

    console.log("imgValue", props.img);

    return (
      <div
        id={props.flowThumbId}
        style={{
          height: "100px",
          width: "100px",
          backgroundImage: `url(${props.img})`,
          backgroundColor: "#2d2a2b",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 15,
          margin: 10,
          border: "0px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
        onClick={() => {
          WidgetStore.setSwipeIndex(1);
          WidgetStore.setCurrentlySelectedImage(props.name);
        }}
      >
        <p
          style={{
            display: "block",
            fontFamily: "quicksand",
            fontSize: 18,
            color: "#4FD975",
          }}
        >
          Add
        </p>
        <p
          style={{
            display: "block",

            fontFamily: "quicksand",
            fontSize: 18,
            color: "#4FD975",
          }}
        >
          Image
        </p>
        <p style={{ color: "white" }}>{props.imgLabel}</p>
      </div>
    );
  };

  useEffect(() => {
    function myFunction(x) {
      if (x.matches) {
        // if (document.getElementsByClassName("output_frame")[0] !== null) {
        //   document.getElementsByClassName(
        //     "output_frame"
        //   )[0].style.borderRadius = "15px";
        // }
        // If media query matches
        //let isMobile = window.matchMedia("(max-width: 768px)").matches;
        setIsMobile(true);
        // RemixableStore.setRenderHTML(true);
      } else {
        if (document.getElementById("menu-wrap") !== null) {
          document.getElementById("menu-wrap").style.display = "none";
        }

        // if (document.getElementsByClassName("output_frame")[0] !== null) {
        //   document.getElementsByClassName(
        //     "output_frame"
        //   )[0].style.borderRadius = "0px";
        // }
        if (
          document.getElementById("horizontal-tab-remix-tab") !== null &&
          document.getElementById("horizontal-tab-remix-tab") !== undefined
        ) {
          document.getElementById("horizontal-tab-remix-tab").style.background =
            "none";
        }
        setIsMobile(false);
        // RemixableStore.setRenderHTML(true);
        // if (
        //   document.getElementsByClassName("editor-menu")[0] !== null &&
        //   document.getElementsByClassName("editor-menu")[0] !== undefined
        // ) {
        //   document.getElementsByClassName("editor-menu")[0].style.paddingLeft =
        //     "78px";
        // }
        RemixableStore.setOpenWidgetsMobile(false);
        if (
          document.getElementById("horizontal-tab-remix-tab") !== null &&
          document.getElementById("horizontal-tab-remix-tab") !== undefined
        ) {
          document.getElementById("horizontal-tab-remix-tab").style.background =
            "none";
        }
        // if (
        //   document.getElementsByClassName("editor-menu")[0] !== null &&
        //   document.getElementsByClassName("editor-menu")[0] !== undefined
        // ) {
        //   document.getElementsByClassName("editor-menu")[0].style.paddingLeft =
        //     "78px";
        // }
        RemixableStore.setOpenWidgetsMobile(false);
        // if (
        //   document.getElementsByClassName("remix-menu")[0] !== null &&
        //   document.getElementsByClassName("editor-menu")[0] !== undefined
        // ) {
        //   document.getElementsByClassName("remix-menu")[0].style.display =
        //     "none";
        // }
      }
    }

    var x = window.matchMedia("(max-width: 768px)");
    myFunction(x); // Call listener function at run time
    x.addListener(myFunction); // Attach listener function on state changes
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  const doSomething = (name, value, instance) => {
    setColor((values) => ({ [name]: value, instance: instance }));
    console.log("color", colorSel);
  };

  const setJump = () => {
    let textCount = 0;
    let imageCount = 0;
    let colorCount = 0;
    let sliderCount = 0;
    let switchCount = 0;
    let selectCount = 0;
    let checkboxCount = 0;
    let calendar = 0;
    WidgetStore.setJumpTo([]);
    optionsJump = [];

    WidgetStore.widgets.map((widget, i) => {
      if (optionsJump.length !== widgets.length) {
        if (widget.widget === "ImageUploader") {
          imageCount++;
          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: imageCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: imageCount,
            });
          } else {
          }
        } else if (widget.widget === "TextInput") {
          textCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: textCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: textCount,
            });
          } else {
          }
        } else if (widget.widget === "ColorSelect") {
          colorCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: colorCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: colorCount,
            });
          } else {
          }
        } else if (widget.widget === "Slider") {
          sliderCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: sliderCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: sliderCount,
            });
          } else {
          }
        } else if (widget.widget === "Switch") {
          switchCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: switchCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: switchCount,
            });
          } else {
          }
        } else if (widget.widget === "Select") {
          selectCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: selectCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: selectCount,
            });
          } else {
          }
        } else if (widget.widget === "Checkbox") {
          checkboxCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: checkboxCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: checkboxCount,
            });
          } else {
          }
        } else if (widget.widget === "Calendar") {
          calendar++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: calendar,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: calendar,
            });
          } else {
          }
        }
      }
    });
    WidgetStore.setJumpTo(optionsJump);
  };

  useEffect(() => {
    // setTimeout(() => {
    setJump();
    // FlowStore.setIsLoaded(false);
    // }, 5000);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [FlowStore.isLoaded]);

  useEffect(() => {
    // setTimeout(() => {
    setJump();
    FlowStore.setJumpTo(false);
    // }, 5000);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [FlowStore.jumpTo]);

  return (
    <div
      style={{
        backgroundColor: "rgb(28, 28, 28)",
        width: "100%",
        overflowY: "scroll",
        height: "calc(100vh - 77px)",
        overflowX: "hidden",
        cursor: "move",
        position: "relative",
      }}
    >
      <div
        style={{
          backgroundColor: "transparent",
          height: "calc(100% - 120px)",
          width: "294px",
          position: "absolute",
          zIndex: "1000",
          top: "0px",
          display: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(28, 28, 28)",
            height: "calc(100% - 120px)",
            width: "294px",
            position: "absolute",
            zIndex: "1000",
            top: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ backgroundImage: `url(${"./loading.png"})` }}>
            {/* <div
              className="dots"
              style={{
                margin: "auto",
                position: "relative",
                fontSize: "20px",
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        style={{
          background: "rgb(28, 28, 28)",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            backgroundColor: "#3f3f3e",
            alignItems: "center",
            display: "flex",
          }}
        ></div>

        {WidgetStore.widgets.length > 0 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "calc(100% - 40px)",
              marginTop: 20,
              marginBottom: 53,
            }}
          >
            <Select
              defaultValue={1}
              closeMenuOnSelect={true}
              components={{ Placeholder, DropdownIndicator }}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  // ...theme.colors,
                  primary25: "#A962F1",
                  primary: "#6371F6",
                  // color: "white",
                },
              })}
              display={"flex"}
              // width={250}
              height={30}
              placeholder={"Jump to..."}
              options={WidgetStore.jumpTo}
              onChange={(picked) => {
                var elmnt = document.getElementById(picked.value);
                if (elmnt !== null) {
                  elmnt.scrollIntoView(false);
                }
              }}
            />
            <img
              src="../new-widget.svg"
              style={{
                marginTop: 10,
                right: 25,
                position: "absolute",
                zIndex: 100,
                cursor: "pointer",
              }}
            />

            <div
              onClick={() => {
                WidgetStore.setShowAddRemixableWidget(true);
              }}
              style={{
                height: 20,
                width: 100,
                backgroundColor: "red",
                borderRadius: 15,
                display: "none",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 14,
                }}
              >
                Add Widget
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {(AuthenticationStore.isDevAccount == true &&
          WidgetStore.widgets.length !== 0) ||
        aiWidgetVsible == true ? (
          <div>
            <p
              onClick={() => {
                WidgetStore.setCurrentModalHeight("670px");
                let parts = window.location.pathname.split("/");
                // let parts = window.location.pathname.split("/");
                let lastSegment = parts.pop() || parts.pop();

                let dontShow = JSON.parse(
                  localStorage.getItem("dontShowAgainNewWidget")
                );

                if (
                  dontShow == null ||
                  dontShow == undefined ||
                  dontShow.flowId !== lastSegment
                ) {
                  if (
                    lastSegment !== "new" &&
                    FlowStore.isFlowOwner !== true &&
                    WidgetStore.widgets.length !== 0
                  ) {
                    // WidgetStore.setNewWidgetWarningMessage(true);
                    EditorStore.setGoToGuide(true);
                  } else {
                    // WidgetStore.setShowAddRemixableWidget(true);
                    EditorStore.setGoToGuide(true);
                  }
                } else {
                  // WidgetStore.setShowAddRemixableWidget(true);
                  EditorStore.setGoToGuide(true);
                }
              }}
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 20,
                marginTop: 7,
                cursor: "pointer",
                marginLeft: 19,
                display: "block",
              }}
            >
              + New Widget
            </p>
          </div>
        ) : (
          ""
        )}

        {AuthenticationStore.isDevAccount === true ||
        (AuthenticationStore.isDevAccoun == true && lastSegment === "new") ? (
          <div
            onClick={() => {
              document.getElementsByClassName("remix-menu")[0].style.display =
                "none";
     

              if (firebase.auth().currentUser !== null) {
                firebase
                  .firestore()
                  .collection("devAccess")
                  .doc(firebase.auth().currentUser.uid)
                  .get()
                  .then((doc) => {
                    if (!doc.exists) {
           
                      EditorStore.setEditorAccessDenied(true);

                      return;
                    }

                    let data = doc.data();
                    console.log("the user id", data.userId);
                    //EditorStore.setEditorAccessDeniedPassword()
                    if (data.userId === firebase.auth().currentUser.uid) {
                      if (EditorStore.editorLocked == false) {
                        if (EditorStore.showAnimation === false) {
                          EditorStore.showAnimationF();
                        } else {
                          EditorStore.showAnimationF();
                        }
                      } else if (FlowStore.isFlowOwner) {
                        if (EditorStore.showAnimation === false) {
                          EditorStore.showAnimationF();
                        } else {
                          EditorStore.showAnimationF();
                        }
                      } else {
                        EditorStore.setCodeLocked(true);
                      }
                    }
                  });
              }

              if (
                firebase.auth().currentUser == null ||
                firebase.auth().currentUser.displayName == null
              ) {
                AuthenticationStore.setShowModal(true);
                return;
              }
            }}
          >
            <p
              style={{
                color: "#6371F6",
                fontFamily: "quicksand",
                fontSize: 20,
                marginTop: 7,
                cursor: "pointer",
                marginLeft: 19,
                display: "none",
              }}
            >
              Remix Code (Advanced)
            </p>
          </div>
        ) : (
          ""
        )}

        {WidgetStore.widgets.map((widget, i) => {
          if (widget.isLocked === true) {
            if (hiddenWidgetsAlreadyLoaded === false) {
              setTimeout(() => {
                setShowHiddenOption(widget.isLocked);
                hiddenWidgetsAlreadyLoaded = true;
              }, 500);
            }
          }
          if (widget.widget === "AIWidget") {
            return (
              <div
                style={{
                  margin: 20,
                  borderBottom: "3px solid #222222",
                  paddingBottom: 20,
                  display: widget.isLocked === true ? "none" : "block",
                }}
              >
                <AIWidget
                  functionName={widget.functionName}
                  label={widget.label}
                  model={widget.model}
                  // replaceInstance={widget.replaceInstance}
                  // replace={widget.replace}
                  // actualInstance={i}
                  // value={widget.value}
                  // isLocked={widget.isLocked}
                  advancedOptions={WidgetStore.advancedOptions}
                  instanceOfWidget={widget.instanceOfWidget}
                  info={widget.info}
                  showInfo={true}
                  deductCreditFrom={props.deductCreditFrom}
                />
              </div>
            );
          }
          if (
            widget.widget !== "AIWidget" &&
            widget.widget !== "AITrainerWidget"
          ) {
            return (
              <div
                style={{
                  margin: 20,
                  borderBottom: "3px solid #222222",
                  paddingBottom: 20,
                  display: widget.isLocked === true ? "none" : "block",
                }}
              >
                <TextComponent
                  functionName={widget.functionName}
                  label={widget.label}
                  replaceInstance={widget.replaceInstance}
                  replace={widget.replace}
                  actualInstance={i}
                  value={widget.value}
                  isLocked={widget.isLocked}
                  advancedOptions={WidgetStore.advancedOptions}
                  instanceOfWidget={widget.instanceOfWidget}
                  info={widget.info}
                  showInfo={true}
                />
              </div>
            );
          }
          if (widget.widget === "AITrainerWidget") {
            return (
              <div
                style={{
                  margin: 0,
                  borderBottom: "3px solid #222222",
                  paddingBottom: 0,
                  display: widget.isLocked === true ? "none" : "block",
                }}
              >
                <AITrainer
                  functionName={widget.functionName}
                  label={widget.label}
                  replaceInstance={widget.replaceInstance}
                  replace={widget.replace}
                  actualInstance={i}
                  value={widget.value}
                  instanceOfWidget={widget.instanceOfWidget}
                  isLocked={widget.isLocked}
                  advancedOptions={WidgetStore.advancedOptions}
                  info={widget.info}
                  showInfo={true}
                  flowId={widget.flowId}
                />
              </div>
            );
          }
        })}

        {/* <div
            style={{
              height: 30,
              width: 135,
              backgroundColor: "#4FD975",
              borderRadius: 15,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                fontFamily: "quicksand",
                color: "#222222",
                fontSize: 17,
              }}
            >
              + New widget
            </p>
          </div> */}
      </div>
      <div
       onClick={() => {
            TabStore.setClosePanel(true);
            
            // if( document.getElementById(
            //   "vertical-tab-details-tab"
            // ) !== null) {
            // document.getElementById(
            //   "vertical-tab-details-tab"
            // ).style.backgroundColor = "transparent";
            
            // document.getElementById(
            //   "vertical-tab-details-tab"
            // ).style.borderRadius = "0px";
            // }


            // document.getElementsByClassName("iframe-container")[0].style.width =
            //   "calc(100% - 125px)";

            // document.getElementById(
            //   "horizontal-tab-remix-tab"
            // ).style.background = "none";
          }}
        style={{
          position: "relative",
          fontFamily: "Quicksand",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
          <img
          src="/images/close-circle.svg"
         
          style={{
            height: 20,
            width: 20,
            cursor: "pointer",
            position: "absolute",
            right: 20,
            top:30,
            
          }}
        />
        {aiWidgetVsible === false && !WidgetStore.widgets.length > 0 ? (
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: 80,
              position: "relative",
            }}
          >
            <FontAwesomeIcon
              key={Math.random() + "icon" + new Date()}
              icon={faExclamationTriangle}
              color="#FFFFFF"
              style={{
                display: "flex",
                marginBottom: 10,
                fontSize: "70px",
                position: "relative",
                bottom: 0,
              }}
            />
            <p
              style={{
                position: "relative",
                fontFamily: "Quicksand",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "243px",
                fontSize: 15,
                left: 7,
                textAlign: "left",
              }}
            >
              There are no widgets available for this flow.
            </p>
            <p
              onClick={() => {
                WidgetStore.setCurrentModalHeight("700px");
                WidgetStore.setShowCreateGuide(true);

                EditorStore.setGoToGuide("widgets");
              }}
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 15,
                cursor: "pointer",
                marginTop: 20,
              }}
            >
              + New widget
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default ControlWidget;
