// src/Components/Home/GiveCredits.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

const GiveCredits = () => {
  const db = firebase.firestore();

  // State for Credit Requests
  const [creditRequests, setCreditRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [requestMessage, setRequestMessage] = useState('');
  const [requestError, setRequestError] = useState('');

  // State for Users and Credits
  const [users, setUsers] = useState([]);
  const [creditsMap, setCreditsMap] = useState({});
  const [usersWithCredits, setUsersWithCredits] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [userMessage, setUserMessage] = useState('');
  const [userError, setUserError] = useState('');

  // Fetch Credit Requests
  useEffect(() => {
    const unsubscribe = db
      .collection('creditRequest')
      .where('creditRequest', '==', true)
      .onSnapshot(
        async (snapshot) => {
          const requests = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          const enhancedRequests = await Promise.all(
            requests.map(async (request) => {
              // Fetch user by username
              const userSnapshot = await db
                .collection('users')
                .where('username', '==', request.currentUser)
                .limit(1)
                .get();
              if (userSnapshot.empty) {
                return { ...request, userId: null, currentCredits: null, error: 'User not found.' };
              }
              const userDoc = userSnapshot.docs[0];
              const userId = userDoc.data().userId;

              // Fetch current credits directly by document ID (userId)
              let currentCredits = 0;
              const creditDoc = await db.collection('credits').doc(userId).get();
              if (creditDoc.exists) {
                currentCredits = creditDoc.data().credits;
              }

              return { ...request, userId, currentCredits };
            })
          );
          setCreditRequests(enhancedRequests);
          setLoadingRequests(false);
        },
        (err) => {
          console.error('Error fetching credit requests:', err);
          setRequestError('Failed to fetch credit requests.');
          setLoadingRequests(false);
        }
      );

    return () => unsubscribe();
  }, [db]);

  // Fetch Users and Credits
  useEffect(() => {
    // Listener for Users
    const unsubscribeUsers = db.collection('users').onSnapshot(
      (snapshot) => {
        const fetchedUsers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(fetchedUsers);
        setLoadingUsers(false);
      },
      (err) => {
        console.error('Error fetching users:', err);
        setUserError('Failed to fetch users.');
        setLoadingUsers(false);
      }
    );

    // Listener for Credits
    const unsubscribeCredits = db.collection('credits').onSnapshot(
      (snapshot) => {
        const fetchedCredits = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          fetchedCredits[doc.id] = data.credits; // doc.id is userId
        });
        setCreditsMap(fetchedCredits);
      },
      (err) => {
        console.error('Error fetching credits:', err);
        setUserError('Failed to fetch credits.');
      }
    );

    return () => {
      unsubscribeUsers();
      unsubscribeCredits();
    };
  }, [db]);

  // Combine Users and Credits
  useEffect(() => {
    const combined = users.map((user) => ({
      ...user,
      credits: creditsMap[user.userId] || 0,
    }));
    setUsersWithCredits(combined);
  }, [users, creditsMap]);

  // Handler to Update Credits from Credit Requests
  const handleUpdateCredits = async (requestId, userId, newCredits) => {
    setRequestMessage('');
    setRequestError('');

    if (newCredits === '') {
      setRequestError('Please enter a credit amount.');
      return;
    }

    const creditsNum = parseInt(newCredits, 10);
    if (isNaN(creditsNum) || creditsNum < 0) {
      setRequestError('Credit amount must be a non-negative number.');
      return;
    }

    try {
      const creditRef = db.collection('credits').doc(userId);
      const creditDoc = await creditRef.get();

      if (creditDoc.exists) {
        // Update existing credits
        await creditRef.update({
          credits: creditsNum,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        // Create a new credits document with userId as document ID
        await creditRef.set({
          userId: userId, // Ensure userId field matches document ID
          credits: creditsNum,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      // Remove the credit request
      await db.collection('creditRequest').doc(requestId).delete();

      setRequestMessage(`Credits updated to ${creditsNum} for user.`);
      // The onSnapshot listeners will automatically update the states
    } catch (err) {
      console.error('Error updating credits:', err);
      setRequestError('Failed to update credits. Please try again.');
    }
  };

  // Handler to Manually Update Credits for Any User
  const handleManualUpdateCredits = async (userId, newCredits) => {
    setUserMessage('');
    setUserError('');

    if (newCredits === '') {
      setUserError('Please enter a credit amount.');
      return;
    }

    const creditsNum = parseInt(newCredits, 10);
    if (isNaN(creditsNum) || creditsNum < 0) {
      setUserError('Credit amount must be a non-negative number.');
      return;
    }

    try {
      const creditRef = db.collection('credits').doc(userId);
      const creditDoc = await creditRef.get();

      if (creditDoc.exists) {
        // Update existing credits
        await creditRef.update({
          credits: creditsNum,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        // Create a new credits document with userId as document ID
        await creditRef.set({
          userId: userId, // Ensure userId field matches document ID
          credits: creditsNum,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      setUserMessage(`Credits updated to ${creditsNum} for user.`);
      // The onSnapshot listeners will automatically update the states
    } catch (err) {
      console.error('Error updating credits:', err);
      setUserError('Failed to update credits. Please try again.');
    }
  };

  // Row component for Credit Requests
  const CreditRequestRow = React.memo(({ index, style }) => {
    const request = creditRequests[index];
    const [newCredits, setNewCredits] = useState('');

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Username:</strong> {request.currentUser}
          <br />
          <strong>Current Credits:</strong>{' '}
          {request.currentCredits !== null ? request.currentCredits : 'N/A'}
          {request.error && <p style={{ color: 'red' }}>{request.error}</p>}
        </div>
        {request.userId && (
          <div style={styles.inputGroup}>
            <input
              type="number"
              placeholder="New Credits"
              value={newCredits}
              onChange={(e) => setNewCredits(e.target.value)}
              style={styles.input}
              min="0"
            />
            <button
              onClick={() => handleUpdateCredits(request.id, request.userId, newCredits)}
              style={styles.button}
            >
              Update
            </button>
          </div>
        )}
      </div>
    );
  });

  // Row component for Users with Credits
  const UserCreditsRow = React.memo(({ index, style }) => {
    const user = usersWithCredits[index];
    const [newCredits, setNewCredits] = useState('');

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Username:</strong> {user.username}
          <br />
          <strong>Current Credits:</strong> {user.credits}
        </div>
        <div style={styles.inputGroup}>
          <input
            type="number"
            placeholder="New Credits"
            value={newCredits}
            onChange={(e) => setNewCredits(e.target.value)}
            style={styles.input}
            min="0"
          />
          <button
            onClick={() => handleManualUpdateCredits(user.userId, newCredits)}
            style={styles.button}
          >
            Update
          </button>
        </div>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2>Credit Requests</h2>
      {loadingRequests ? (
        <p>Loading credit requests...</p>
      ) : creditRequests.length === 0 ? (
        <p>No pending credit requests.</p>
      ) : (
        <List height={400} itemCount={creditRequests.length} itemSize={120} width={'100%'}>
          {CreditRequestRow}
        </List>
      )}
      {requestMessage && <p style={styles.message}>{requestMessage}</p>}
      {requestError && <p style={{ ...styles.message, color: 'red' }}>{requestError}</p>}

      <hr style={styles.divider} />

      <h2>All Users with Credits</h2>
      {loadingUsers ? (
        <p>Loading users with credits...</p>
      ) : usersWithCredits.length === 0 ? (
        <p>No users found.</p>
      ) : (
        <List height={400} itemCount={usersWithCredits.length} itemSize={80} width={'100%'}>
          {UserCreditsRow}
        </List>
      )}
      {userMessage && <p style={styles.message}>{userMessage}</p>}
      {userError && <p style={{ ...styles.message, color: 'red' }}>{userError}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '10px',
  },
  input: {
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '120px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28A745',
    color: '#fff',
    cursor: 'pointer',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  divider: {
    margin: '40px 0',
    border: 'none',
    borderTop: '1px solid #ccc',
  },
};

export default GiveCredits;
