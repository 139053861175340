import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";

import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import { RoomContext } from "./RoomContext.js";
import { useNavigate, useParams } from "react-router-dom";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { Modal, Button, Form } from 'react-bootstrap';

const CollaboratorList = observer(({ flowId, userId }) => {
  const [collaborators, setCollaborators] = useState([]);
  const [collaborator, setCollaborator] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [yourRole, setYourRole] = useState("");
  const [yourRoleError, setYourRoleError] = useState("");
  const [collaboratorRoleError, setCollaboratorRoleError] = useState("");
  const [collaboratorRole, setCollaboratorRole] = useState("");
  const [isOwnerRemovable, setIsOwnerRemovable] = useState(false);

  const FlowStore = useContext(FlowStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);

  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isPromoOpen, setIsPromoOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const firestore = firebase.firestore();
  const { roomId, commentId, replyId } = useParams();

  const room = useContext(RoomContext);




      // Function to handle opening the modal
      const handleOpenModal = (username) => {
        setSelectedUsername(username);
        setShowModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setNewRole('');
        setSelectedUsername('');
    };

    /**
     * Function to update the collaborator role
     * @param {string} username - The username of the collaborator
     */
    const updateCollaboratorRole = async (username) => {
        // Open the modal to collect new role
        handleOpenModal(username);
    };

    /**
     * Function to handle the form submission in the modal
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedUsername) {
            console.error("No username selected.");
            handleCloseModal();
            return;
        }

        const flowId = EditorStore.flowId; // Ensure EditorStore.flowId is accessible

        if (!flowId) {
            console.error("Invalid flowId. Ensure EditorStore.flowId is set.");
            handleCloseModal();
            return;
        }

        try {
          const db = firebase.firestore();

            const notificationsRef = db.collection('notifications');

            // Query the notifications collection with the specified conditions
            const querySnapshot = await notificationsRef
                .where('type', '==', 'promoRequest')
                .where('receiver', '==', selectedUsername)
                .where('flowId', '==', flowId)
                .where('isAccepted', '==', true)
                .limit(1)
                .get();

            if (querySnapshot.empty) {
                console.log("No matching notification found. No update performed.");
            } else {
                // Assuming only one document since we used limit(1)
                const doc = querySnapshot.docs[0];
                await notificationsRef.doc(doc.id).update({
                    collaboratorRole: newRole
                });
                console.log(`Collaborator role updated to "${newRole}" for user "${selectedUsername}".`);
            }
        } catch (error) {
            console.error("Error updating collaborator role:", error);
        } finally {
            // Close the modal and reset state
            handleCloseModal();
        }
    };

  useEffect(() => {
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [collaborators]);

  useEffect(() => {
    if (!flowId) return;

    setIsLoading(true);

    const db = firebase.firestore();
    const unsubscribe = db
      .collection("notifications")
      .where("flowId", "==", flowId)
      .where("type", "==", "promoRequest")
      .onSnapshot(
        (snapshot) => {
          const fetchedCollaborators = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              docId: doc.id,
              receiver: data.receiver,
              status: data.isAccepted === false ? "pending" : "",
              collaboratorRole: data.collaboratorRole,
            };
          });

          setCollaborators(fetchedCollaborators);
          console.log("Fetched Collaborators:", fetchedCollaborators);
          setIsLoading(false);
        },
        (error) => {
          console.error("Error fetching collaborators:", error);
          setErrorMessage("Error fetching collaborators.");
          setIsLoading(false);
        }
      );

    return () => unsubscribe();
  }, [flowId]);

  const addLikeNotification = async (
    sender,
    receiver,
    photoURL,
    collaboratorRole,
    yourRole
  ) => {
    const newNotification = {
      sender: sender,
      receiver: receiver,
      type: "promoRequest",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: {
        photoURL: photoURL,
      },
      flowId: EditorStore.flowId,
      isSeen: false,
      isAccepted: false,
      collaboratorRole,
      yourRole,
    };

    setMessage("");
    setIsLoading(true);

    try {
      const existingRequestQuery = await firebase
        .firestore()
        .collection("notifications")
        .where("type", "==", "promoRequest")
        .where("flowId", "==", EditorStore.flowId)
        .where("receiver", "==", receiver)
        .get();

      if (!existingRequestQuery.empty) {
        setMessage(`A collaboration request has already been sent to ${receiver}.`);
        setIsLoading(false);
        return;
      }

      await firebase.firestore().collection("notifications").add(newNotification);
      setCollaborators([
        {
          receiver: receiver,
          status: "pending",
          collaboratorRole: collaboratorRole,
        },
        ...collaborators,
      ]);

      setMessage(`Collaboration request successfully sent to ${receiver}.`);
    } catch (error) {
      console.error("Error sending collaboration request:", error);
      setMessage("Failed to send collaboration request. Please try again.");
    } finally {
      setIsLoading(false);
      setIsPromoOpen(false);
    }
  };

  const fetchCollaborators = async (flowId) => {
    const db = firebase.firestore();
    try {
      const querySnapshot = await db
        .collection("notifications")
        .where("flowId", "==", flowId)
        .where("type", "==", "promoRequest")
        .get();

      const fetchedCollaborators = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          receiver: data.receiver,
          status: data.isAccepted === false ? "pending" : "",
          collaboratorRole: data.collaboratorRole,
        };
      });

      console.log("fetched Collaborators", fetchedCollaborators);

      setCollaborators([...fetchedCollaborators]);
    } catch (error) {
      console.error("Error fetching collaborators: ", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCollaborators(EditorStore.flowId);
    };
    fetchData();
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [EditorStore.flowId]);

  const uniqueCollaborators = collaborators
    .filter((collab) => collab.receiver )
    .reduce(
      (acc, collab) => {
        if (!acc.ids.has(collab.receiver)) {
          acc.ids.add(collab.receiver);
          acc.result.push(collab);
        }
        return acc;
      },
      { ids: new Set(), result: [] }
    ).result;

  const addCollaborator = async (collabId, collaboratorRole, yourRole) => {
    const userSnapshot = await firestore
      .collection("users")
      .where("username", "==", collabId)
      .get();

    if (collaborator.trim() === "") {
      setErrorMessage("You must enter a username");
      return;
    }

    if (userSnapshot.empty) {
      setErrorMessage("This user doesn't exist.");
      return;
    }

    await addLikeNotification(
      currentUser.displayName,
      collaborator,
      AuthenticationStore.photoURL,
      collaboratorRole,
      yourRole
    );
    await fetchCollaborators(EditorStore.flowId);
  };

  const removeCollaborator = async (username) => {
    const db = firebase.firestore();
    setErrorMessage("");

    try {
      const batch = db.batch();

      // Delete from 'notifications' collection where type:"promoRequest" and receiver matches
      const notificationsQuery = await db
        .collection("notifications")
        .where("type", "==", "promoRequest")
        .where("receiver", "==", username)
        .get();

      if (!notificationsQuery.empty) {
        notificationsQuery.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        console.log(
          `Added ${notificationsQuery.size} notification deletions to the batch.`
        );
      } else {
        console.log("No matching notification documents found to delete.");
      }

      if (!notificationsQuery.empty) {
        await batch.commit();
        console.log(
          "All matching documents successfully deleted from 'notifications' collection."
        );
      } else {
        console.log(
          "No documents to delete in 'notifications' collection."
        );
      }
      await fetchCollaborators(EditorStore.flowId)

      // No need to manually update collaborators; onSnapshot will handle it
    } catch (error) {
      console.error("Error removing collaborator documents: ", error);
      setErrorMessage("Failed to delete collaborator from the database.");
    }
  };



  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "#4FD975",
              marginLeft: 0,
              marginTop: 12,
            }}
          >
            Collaborator Settings
          </p>
          <img
            src="../images/collaborator-down-arrow.svg"
            style={{ marginLeft: 10, marginBottom: 0, marginTop: 12 }}
          />
        </div>
        {isOpen && (
          <>
            <p
              style={{
                fontFamily: "quicksand",
                fontSize: 12,
                color: "white",
                marginLeft: 0,
                marginTop: 12,
                fontWeight: "bolder",
                maxWidth: 455,
                width: "100%",
              }}
            >
              Add additional collaborators
            </p>
            <p style={{fontFamily: "quicksand", color:'rgb(240, 98, 99)', fontSize:11, marginTop:10}}>IMPORTANT: be careful, collaborators will have access to all code (including anything sensitive)</p>

            {/* {isOwnerRemovable == false ? (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <input
                  maxLength="10"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (inputValue && inputValue.trim() === "") {
                      setYourRoleError("Entry cannot be just spaces!");
                    } else if (inputValue.length >= e.target.maxLength) {
                      setYourRoleError("Maximum character limit reached!");
                    } else {
                      setYourRoleError("");
                    }

                    setYourRole(inputValue);
                    EditorStore.setYourRole(inputValue);
                  }}
                  id="your-role"
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 15,
                    color: "white",
                    outline: "none",
                    fontFamily: "quicksand",
                    fontSize: 11,
                    marginTop: 10,
                    marginRight: 10,
                  }}
                  placeholder="Type your role here"
                  value={yourRole}
                />

                <div
                  onClick={() => {
                    setYourRole("");
                    setErrorMessage("");
                  }}
                  style={{
                    height: 37,
                    width: 82,
                    background: "rgb(79, 217, 117)",
                    fontFamily: "Quicksand",
                    fontSize: 10,
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                    }}
                  >
                    Clear
                  </p>
                </div>
              </div>
            ) : (
              ""
            )} */}
            <p
              style={{
                color: "#FF5C5C",
                fontSize: 14,
                marginTop: 10,
                fontFamily: "quicksand",
              }}
            >
              {yourRoleError}
            </p>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                marginTop: 12,
              }}
            >
              <div style={{ display: "flex", width: "calc(100% - 170px)" }}>
                <input
                  maxLength="15"
                  id="collaborator"
                  onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase();
                    const regex = /^[a-z0-9 ]*$/;
                    setErrorMessage("");

                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    if (!regex.test(inputValue)) {
                      setCollaboratorRoleError(
                        "Special characters like #$@ are not allowed or uppercase letters!"
                      );
                      return;
                    }

                    setCollaborator(inputValue);

                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for collaborator!"
                      );
                    }
                  }}
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    marginRight: 20,
                    fontSize: 12,
                    textTransform: "lowercase",
                  }}
                  placeholder="Username"
                  value={collaborator}
                />

                <input
                  id="collaboratorRole"
                  maxLength="10"
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setYourRoleError("");

                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    setCollaboratorRole(inputValue);

                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for role!"
                      );
                    }
                  }}
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    fontSize: 12,
                  }}
                  placeholder="Role"
                  value={collaboratorRole}
                />
              </div>
              <div
                id="add-collaborator-btn"
                style={{
                  backgroundImage: `url(../images/add-collaborator.svg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "154px",
                  height: 38,
                  width: 155,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setCollaboratorRoleError("");
                  if (!collaborator || collaborator.trim() === "") {
                    setErrorMessage("You must enter a username");
                    return;
                  }

                  if (!collaboratorRole || collaboratorRole.trim() === "") {
                    setCollaboratorRoleError(
                      "Role cannot be empty or just spaces!"
                    );
                    return;
                  }

                  if (uniqueCollaborators.length > 50) {
                    setCollaboratorRoleError(
                      "You can not have more than 50 collaborators"
                    );
                    return;
                  }

                  await addCollaborator(
                    collaborator.trim(),
                    collaboratorRole.trim(),
                    yourRole.trim()
                  );
                  setCollaboratorRole("");
                  setCollaborator("");
                }}
              ></div>
            </div>
            <p
              style={{
                color: "#FF5C5C",
                fontSize: 14,
                fontFamily: "quicksand",
                marginTop: 10,
              }}
            >
              {collaboratorRoleError}
            </p>

            <p
              id="error-message"
              style={{
                color: "#FF5C5C",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {errorMessage}
            </p>
            <p
              style={{
                color: message.includes("Failed") ? "rgb(240, 98, 99)" : "rgb(240, 98, 99)",
                fontFamily: "quicksand",
                fontSize: 12,
                marginTop: 10,
              }}
            >
              {message}
            </p>
            <ul
              style={{
                height: 70,
                width: "100%",
                overflowY: "scroll",
                backgroundColor: "rgb(44, 44, 44)",
                borderRadius: 10,
                padding: 10,
                color: "white",
                listStyle: "none",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {collaborators.length === 0 ? (
                <p style={{ fontFamily: "quicksand", fontSize: 12 }}>
                  No added collaborators
                </p>
              ) : (
                uniqueCollaborators.map((collab) => (
                  <li
                    key={collab.docId}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom:10
                    }}
                  >
                         
                         <button
                      onClick={() => removeCollaborator(collab.receiver)}
                      style={{
                        height: 18,
                        width: 47,
                        background: "rgb(79, 217, 117)",
                        fontFamily: "Quicksand",
                        fontSize: 10,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "none",
                        fontWeight: "bold",
                        marginRight:5
                      }}
                    >
                      Delete
                    </button>
                      {collab.ownerRole && collab.receiver === EditorStore.username ? (
                      <span
                        style={{
                          color: "white",
                          fontFamily: "quicksand",
                          fontSize: 12,
                        }}
                      >
                        Owner
                      </span>
                    ) : (
                      collab.status === "pending" && (
                        <span style={{whiteSpace:'nowrap', fontFamily:'quicksand', fontSize:11, marginRight:5}}> - pending</span>
                      )
                    )}
                    <span style={{whiteSpace:'nowrap', fontFamily:'quicksand', fontSize:11, marginRight:5}}>role: {collab.collaboratorRole}</span>
                    {/* <button
                      onClick={() => updateCollaboratorRole(collab.receiver)}
                      style={{
                        height: 18,
                        width: 47,
                        background: "rgb(79, 217, 117)",
                        fontFamily: "Quicksand",
                        fontSize: 10,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "none",
                        fontWeight: "bold",
                        marginRight:5
                      }}
                    >
                      Update
                    </button> */}
                    <span style={{whiteSpace:'nowrap', fontFamily:'quicksand', fontSize:11}}>user: {collab.receiver}</span>
                  </li>
                ))
              )}
            </ul>
          </>
        )}
        {isPromoOpen && (
          <>
            <p
              style={{
                fontFamily: "quicksand",
                fontSize: 12,
                color: "white",
                marginLeft: 0,
                marginTop: 5,
                fontWeight: "bolder",
                maxWidth: 455,
                width: "100%",
              }}
            >
              If you are adding a collaborator, you must initially type your role i.e. "developer", "javascript", "idea person" or however you want to describe your role, then type the username of a collaborator and their role then select "Add Collaborator". You only have to add your role initially, not each time you add a new collaborator. You do not need to enter your username, it will be added automatically.
            </p>
            {isOwnerRemovable == false ? (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <input
                  maxLength="10"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (inputValue && inputValue.trim() === "") {
                      setYourRoleError("Entry cannot be just spaces!");
                    } else if (inputValue.length >= e.target.maxLength) {
                      setYourRoleError("Maximum character limit reached!");
                    } else {
                      setYourRoleError("");
                    }

                    setYourRole(inputValue);
                    EditorStore.setYourRole(inputValue);
                  }}
                  id="your-role"
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 15,
                    color: "white",
                    outline: "none",
                    fontFamily: "quicksand",
                    fontSize: 11,
                    marginTop: 10,
                    marginRight: 10,
                  }}
                  placeholder="Type your role here"
                  value={yourRole}
                />

                <div
                  onClick={() => {
                    setYourRole("");
                    setErrorMessage("");
                  }}
                  style={{
                    height: 37,
                    width: 82,
                    background: "rgb(79, 217, 117)",
                    fontFamily: "Quicksand",
                    fontSize: 10,
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                    }}
                  >
                    Clear
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            <p
              style={{
                color: "#FF5C5C",
                fontSize: 14,
                marginTop: 10,
                fontFamily: "quicksand",
              }}
            >
              {yourRoleError}
            </p>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                marginTop: 12,
              }}
            >
              <div style={{ display: "flex", width: "calc(100% - 170px)" }}>
                <input
                  maxLength="15"
                  id="collaborator"
                  onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase();
                    const regex = /^[a-z0-9 ]*$/;
                    setErrorMessage("");

                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    if (!regex.test(inputValue)) {
                      setCollaboratorRoleError(
                        "Special characters like #$@ are not allowed or uppercase letters!"
                      );
                      return;
                    }

                    setCollaborator(inputValue);

                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for collaborator!"
                      );
                    }
                  }}
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    marginRight: 20,
                    fontSize: 12,
                    textTransform: "lowercase",
                  }}
                  placeholder="Username"
                  value={collaborator}
                />

                <input
                  id="collaboratorRole"
                  maxLength="10"
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setYourRoleError("");

                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    setCollaboratorRole(inputValue);

                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for role!"
                      );
                    }
                  }}
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    fontSize: 12,
                  }}
                  placeholder="Role"
                  value={collaboratorRole}
                />
              </div>
              <div
                id="add-collaborator-btn"
                style={{
                  backgroundImage: `url(../images/add-collaborator.svg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "154px",
                  height: 38,
                  width: 155,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setCollaboratorRoleError("");

                  if (!collaborator || collaborator.trim() === "") {
                    setErrorMessage("You must enter a username");
                    return;
                  }

                  if (!collaboratorRole || collaboratorRole.trim() === "") {
                    setCollaboratorRoleError(
                      "Role cannot be empty or just spaces!"
                    );
                    return;
                  }

                  if (uniqueCollaborators.length > 50) {
                    setCollaboratorRoleError(
                      "You can not have more than 50 collaborators"
                    );
                    return;
                  }

                  await addCollaborator(
                    collaborator.trim(),
                    collaboratorRole.trim(),
                    yourRole.trim()
                  );
                }}
              ></div>
            </div>
            <p
              style={{
                color: "rgb(240, 98, 99)",
                fontSize: 14,
                fontFamily: "quicksand",
                marginTop: 10,
              }}
            >
              {collaboratorRoleError}
            </p>

            <p
              id="error-message"
              style={{
                color: "rgb(240, 98, 99)",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {errorMessage}
            </p>
            <p
              style={{
                color: message.includes("Failed") ? "rgb(240, 98, 99)" : "rgb(240, 98, 99)",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {message}
            </p>
            <ul
              style={{
                height: 70,
                width: "100%",
                overflowY: "scroll",
                backgroundColor: "rgb(44, 44, 44)",
                borderRadius: 10,
                padding: 10,
                color: "white",
                listStyle: "none",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {collaborators.length === 0 ? (
                <p style={{ fontFamily: "quicksand", fontSize: 12 }}>
                  No added collaborators
                </p>
              ) : (
                uniqueCollaborators.map((collab) => (
                  <li
                    key={collab.docId}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{collab.receiver}</span>
                    {collab.ownerRole && collab.receiver === EditorStore.username ? (
                      <span
                        style={{
                          color: "white",
                          fontFamily: "quicksand",
                          fontSize: 12,
                        }}
                      >
                        Owner
                      </span>
                    ) : (
                      collab.status === "pending" && <span> - Pending</span>
                    )}
                    <span>{collab.collaboratorRole}</span>
                    
                    <button
                      onClick={() => removeCollaborator(collab.receiver)}
                      style={{
                        height: 20,
                        width: 55,
                        background: "rgb(79, 217, 117)",
                        fontFamily: "Quicksand",
                        fontSize: 10,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "none",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      Delete
                    </button>
                  </li>
                ))
              )}
            </ul>
          </>
        )}
      </div>

    </div>
  );
});

export default CollaboratorList;
