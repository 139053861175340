import { default as React } from "react";
import ReactImageFallback from "react-image-fallback";
import { Link } from "react-router-dom";

const NameAndDate = (props) => {
 return( <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="fourth-step"
  >
    <div
      style={{
        // width: 40,

        width: "35px",
        height: "35px",
        borderRadius: "50%",
        backgroundColor: "black",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        marginRight: 12,
        cursor: "pointer",
        position: "relative",
        top: 0,
        left: 1,
        border: "2px solid white",
        overflow: "hidden",
        marginLeft: 23,
        marginBottom: 0,
        marginTop: 0,
      }}
    >
      <ReactImageFallback
        src={props.urlPhoto ? props.urlPhoto : "/images/Flowroom_Default_Avatar.png"}
        fallbackImage="/images/Flowroom_Default_Avatar.png"
        initialImage="/images/Flowroom_Default_Avatar.png"
        style={{ height: 30, width: 30 }}
      />
    </div>
    <div
      className="fifth-step"
      style={{
        textOverflow: "ellipsis",
        textAlign: "left",
        fontSize: "16px",
        color: "white",
        paddingTop: 0,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 5,
      }}
   
    >
      <Link
        to={`/${props.username}`}
        key={Math.random() + "username" + new Date()}
      >
        <p
          style={{
            fontFamily: "quicksand",
            fontSize: "14.8px",
            fontWeight: "bolder",
            color: "white",
            marginLeft: "-3px",
            marginTop: 12,
          }}
        >{`@${props.username}`}</p>
      </Link>
      <p
        style={{
          fontFamily: "quicksand",
          fontSize: "12px",
          fontWeight: "bolder",
          position: "relative",
          left: -4,
          bottom: 2,
          opacity: 0.7,
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.7)",
        }}
      >
        {props.date}
      </p>
    </div>
  </div>)
};

export default React.memo(NameAndDate);
