import { makeAutoObservable } from "mobx";
import { createContext } from "react";
class EditorStore {
  constructor() {
    makeAutoObservable(this);
  }
  showAnimation = false;
  isMobileHeightForResize = 0;
  resizeableHeight = 0;
  rotateTriangle = false;
  resizeableCurrentHeight = 0;
  codeBoxesWidth = 0;
  htmlGear = false;
  cssGear = false;
  jsGear = false;
  codeHTMLWizard = false;
  codeCSSWizard = false;
  codeJSWizard = false;

  gptConfirmation = false;
  instructions = false;
  gptCall = false;
  gptCallSpeech = false;
  remixWizard = false;
  array = [];
  editorAccessDenied = false;
  editorLocked = false;
  editorLockedPassword = false;
  editorLockedSelected = false;
  editIdeas = false;
  disableComments = false;
  isAR = false;
  isVR = false;
  isLive = false;
  isAI = false;
  setupOptions = false;
  isAllDevice = true;
  deviceMobile = true;
  deviceTablet = true;
  deviceDesktop = true;
  addCollaborator = false;
  disableAdvancedRemixability = false;
  disableRemixability = false;
  postAsReply = false;
  paymentOptions = false;
  showShare = false;
  showCardPreview = false;
  paymentOneTimeFee = false;
  paymentSubscription = false;
  paymentFreeTrial = false;
  specialShare = false;
  screencap = false;
  gifRecap = false;
  gifRecapTime = 0;
  gifRecapFunction = "gifrecapfunc";
  gifRecapAI = false;
  gifRecapAIFunctionBeforeRemixModal = "test";
  gifRecapAITimeBeforeRemixModal = 0;
  gifRecapAIFunctionAfterRemixModal = "test2";
  gifRecapAITimeAfterRemixModal = 0;
  isFeatured = false;
  firebaseUIShow = false;
  userRole = false;
  displayOnProfile = false;
  developerRole = false;
  pronouns = false;
  isRemixed = false;
  isNSFW = false;
  enableGifRecap = false;
  enableAIGifRecap = false;
  enableSpecialShare = false;
  browserTags = ["chrome", "firefox", "safari", "opera", "edge"];
  paymentPlan = "";
  loadRemixes = false;
  isSafari = true;
  isChrome = true;
  isFirefox = true;
  isOpera = true;
  isVivaldi = true;
  isBrave = true;
  compatibleWithAllBrowswers = true;
  loadInFeeed = true;
  saveAsAReplay = false;
  isPinned = false;
  currentFlowCommentsNumber = 0;
  currentFlowLikesNumber = 0;
  currentFlowDescription = "";
  currentFlowDetails = "";
  editorLocked = false;
  remixedLocked = false;
  codeLocked = false;
  currentFlowData = false;
  loadInFeed = false;
  remixes = [];
  title = "";
  urlPhoto = "";
  date = "";
  username = "";
  collaborators = [];
  showDescriptionMobile = false;
  flowId = "";
  deductCreditFrom = false;
  uid = false;
  isAReShare = false;
  currentFlowId = false;
  aiTrainerModel = "";
  isAResult = false;
  css = "";
  flowData = {};
  hasAudio = false;
  dhtmlEditor = false;
  dhtml_HTML = "";
  dhtml_CSS = "";
  dhtml_JS = "";
  goToGuide = "";
  showShareModal = false;
  showPostModal = false;
  showCreateAI = false;
  description = "";
  codeModification = false;
  currentHTML = "";
  currentJS = "";
  currentCSS = "";
  turnOffRemix = false;
  duplicateCheckOff = false;
  isSaving = false;
  warning = false;
  warningMessage = "";
  flowIsMonetized = false;
  flowIsMonetizedInitial = false;
  flowPrice = 0;
  flowPriceInitial = 0;
  flowIsApproved = false;
  flowMonthly = false;
  flowIsApprovedInitial = false;
  flowFreeTrial = false;
  flowFreeTrialInitial = false;
  paidRemix = false;
  paidRemixInitial = false;
  flowPreview = false;
  flowPreviewInitial = false;
  flowThumbnail = false;
  flowThumbnailInitial = false;
  flowPerMonth = false;
  flowPerMonthInitial = false;
  flowPerYear = false;
  flowPerYearInitial = false;
  monetized = false;
  monetizedInitial = false;
  flowDays = 0;
  flowDaysInitial = 0;
  flowNumberOfUses = 0;
  flowNumberOfUsesInitial = 0;
  flowPreviewId = "";
  flowPreviewIdInitial = "";
  loadThumbnail = false;
  loadThumbnailInitial = false;
  isRemixable = false;
  isRemixableInitial = false;
  loadThumbnailPaid = false;
  loadThumbnailPaidInitial = false;
  isRemixableByEveryone = true;
  isRemixableByEveryoneInitial = true;
  isRemixableByDevOnly = false;
  isRemixableByDevOnlyInitial = false;
  isRemixableByMeOnly = false;
  isRemixableByMeOnlyInitial = false;
  aiModifyRemix = true;
  aiModifyRemixInitial = false;
  editorRemix = true;
  editorRemixInitial = true;
  specificRemixing = true;
  specificRemixingInitial = true;
  remixPrice = false;
  remixPriceInitial = false;
  isProAccount = false;
  flowAnnually = false;
  flowAnnuallyInitial = false;
  yourRole = "";
  convoArray = [];
  currentTokensAICreateAvailable = 2048;
  currentModelAICreate = "gpt-4o";
  showTooltip = true;
  showPostOptions = false;
  showApprovalChat = false;
  flowThumbnailImage = "";
  showRemixWithWidgetButton = false;
  showMoreModal = false;
  showAdvancedModal = false;
  advancedCodeMode = false;
  alreadySetAdvancedMode = false;
  visibleIcons = true;
  adminIsLoggedIn = false;
  likes = 0;
  urlHTML = "";
  urlCSS = "";
  urlJS = "";
  otherProfilePic = "";
  IsPanelOpen = false;
  deniedCodeEditor = false;
  comments = 0;
  selectedTab = null;
  closePanel = false;
  userId = "";
  shareCount = 0;
  isBlocked = false;
  totalCreditsNeededForFlow = 0;
  allowMessages = true;
  allowMessagesBlocked = false;
  reloadProfile = false;
  remixedCount = 0;
  searchFilter = false;
  showSaveButton = false;
  selectedBookmark = false;
  flowIdOP = "";
  
  showAnimationF() {
    this.showAnimation === false
      ? (this.showAnimation = true)
      : (this.showAnimation = false);
    let element = document.getElementsByClassName("editor-menu")[0];
    if (element !== null && element !== undefined) {
      let style = window.getComputedStyle(element);

      let paddingLeft = 380; //style.getPropertyValue("paddingLeft");
      if (paddingLeft === 380) {
        if (
          document.getElementsByClassName("editor-parent")[0].style
            .visibility !== "hidden"
        ) {
          document.getElementsByClassName("editor-parent")[0].style.visibility =
            "visible";
        } else {
          document.getElementsByClassName("editor-parent")[0].style.visibility =
            "hidden";
        }
        this.codeBoxesWidth =
          document.getElementById("resizable-box").clientWidth / 2;
      } else {
        this.codeBoxesWidth =
          document.getElementById("resizable-box").clientWidth / 3;
      }
    }

    // this.isMobileHeightForResize = 0
    // this.resizeableHeight = 0
    // this.rotateTriangle = false
    // this.resizeableCurrentHeight = 0
  }
  setCodeBoxesWidth(width) {
    this.codeBoxesWidth = width;
  }
  setHTMLGear(bool) {
    this.htmlGear = bool;
  }
  setCSSGear(bool) {
    this.cssGear = bool;
  }
  setJSGear(bool) {
    this.jsGear = bool;
  }
  setCodeWizard(bool) {
    this.codeWizard = bool;
  }
  setGPTConfirmation(bool) {
    this.gptConfirmation = bool;
  }
  setInstructions(bool) {
    this.instructions = bool;
  }
  setGPTCall(bool) {
    this.gptCall = bool;
  }
  setGPTCallSpeech(bool) {
    this.gptCallSpeech = bool;
  }
  setCodeHTMLWizard(bool) {
    this.codeHTMLWizard = bool;
  }
  setCodeCSSWizard(bool) {
    this.codeCSSWizard = bool;
  }
  setCodeJSWizard(bool) {
    this.codeJSWizard = bool;
  }
  setRemixWizard(bool) {
    this.remixWizard = bool;
  }
  setWidgets(array) {
    this.array = array;
  }
  setEditorAccessDenied(bool) {
    this.editorAccessDenied = bool;
  }
  setEditorLocked(bool) {
    this.editorLocked = bool;
  }
  setEditorLockedPassword(bool) {
    this.editorLockedPassword = bool;
  }
  setEditIdeas(bool) {
    this.editIdeas = bool;
  }
  setDisableComments(bool) {
    this.disableComments = bool;
  }
  setIsAR(bool) {
    this.isAR = bool;
  }
  setIsVR(bool) {
    this.isVR = bool;
  }
  setAI(bool) {
    this.isAI = bool;
  }
  setIsLive(bool) {
    this.isLive = bool;
  }
  setEditorLockedSelected(bool) {
    this.editorLockedSelected = bool;
  }
  setAddCollaborator(bool) {
    this.addCollaborator = bool;
  }
  setShowAnimation(bool) {
    this.showAnimation = bool;
  }
  setDeviceMobile(bool) {
    this.deviceMobile = bool;
  }
  setDeviceTablet(bool) {
    this.deviceTablet = bool;
  }
  setDeviceDesktop(bool) {
    this.deviceDesktop = bool;
  }
  setIsNSFW(bool) {
    this.isNSFW = bool;
  }
  setSetupOptions(bool) {
    this.setupOptions = bool;
  }
  setIsAllDevice(bool) {
    this.isAllDevice = bool;
  }
  setDisableAdvancedRemixability(bool) {
    this.disableAdvancedRemixability = bool;
  }
  setDisableRemixability(bool) {
    this.disableRemixability = bool;
  }
  setPostAsReply(bool) {
    this.postAsReply = bool;
  }
  setPaymentOptions(bool) {
    this.paymentOptions = bool;
  }
  setShowShare(bool) {
    this.showShare = bool;
  }
  setShowCardPreview(bool) {
    this.showCardPreview = bool;
  }
  setPaymentOneTimeFee(bool) {
    this.paymentOneTimeFee = bool;
  }
  setPaymentSubscription(bool) {
    this.paymentSubscription = bool;
  }
  setPaymentFreeTrial(bool) {
    this.paymentFreeTrial = bool;
  }
  setSpecialShare(bool) {
    this.specialShare = bool;
  }
  setScreencap(bool) {
    this.screencap = bool;
  }
  setGifRecapAI(bool) {
    this.gifRecapAI = bool;
  }
  setGifRecap(bool) {
    this.gifRecap = bool;
  }
  setGifRecapFunction(param) {
    this.gifRecapFunction = param;
  }
  setGifRecapTime(param) {
    this.gifRecapTime = param;
  }
  setGifRecapAIFunctionBeforeRemixModal(param) {
    this.gifRecapAIFunctionBeforeRemixModal = param;
  }
  setGifRecapAITimeBeforeRemixModal(param) {
    this.gifRecapAITimeBeforeRemixModal = param;
  }
  setGifRecapAIFunctionAfterRemixModal(param) {
    this.gifRecapAIFunctionAfterRemixModal = param;
  }
  setGifRecapAITimeAfterRemixModal(param) {
    this.gifRecapAITimeAfterRemixModal = param;
  }
  setIsFeatured(bool) {
    this.isFeatured = bool;
  }
  setFirebaseUIShow(bool) {
    this.firebaseUIShow = bool;
  }
  setUserRole(bool) {
    this.userRole = bool;
  }
  setDisplayOnProfile(bool) {
    this.displayOnProfile = bool;
  }
  setDeveloperRole(bool) {
    this.developerRole = bool;
  }
  setPronouns(pronoun) {
    this.pronouns = pronoun;
  }
  setIsRemixed(bool) {
    this.isRemixed = bool;
  }
  setEnableGifRecap(bool) {
    this.enableGifRecap = bool;
  }
  setEnableAIGifRecap(bool) {
    this.enableAIGifRecap = bool;
  }
  setEnableSpecialShare(bool) {
    this.enableSpecialShare = bool;
  }
  setBrowserTags(arr) {
    this.browserTags = arr;
  }
  setPaymentPlan(plan) {
    this.paymentPlan = plan;
  }
  setLoadRemixes(bool) {
    this.loadRemixes = bool;
  }
  setIsSafari(bool) {
    this.isSafari = bool;
  }
  setIsChrome(bool) {
    this.isChrome = bool;
  }
  setIsFirefox(bool) {
    this.isFirefox = bool;
  }
  setIsOpera(bool) {
    this.isOpera = bool;
  }
  setIsVivaldi(bool) {
    this.isVivaldi = bool;
  }
  setIsBrave(bool) {
    this.isBrave = bool;
  }
  setCompatibleWithAllBrowswers(bool) {
    this.compatibleWithAllBrowswers = bool;
  }
  setLoadInFeed(bool) {
    this.loadInFeed = bool;
  }
  setSaveAsAReplay(bool) {
    this.saveAsAReplay = bool;
  }
  setIsPinned(bool) {
    this.isPinned = bool;
  }
  setCurrentFlowCommentsNumber(param) {
    this.currentFlowCommentsNumber = param;
  }
  setCurrentFlowLikesNumber(param) {
    this.currentFlowLikesNumber = param;
  }
  setCurrentFlowDescription(param) {
    this.currentFlowDescription = param;
  }
  setCurrentFlowDetails(param) {
    this.currentFlowDetails = param;
  }
  setEditorLocked(bool) {
    this.editorLocked = bool;
  }
  setRemixedLocked(bool) {
    this.remixedLocked = bool;
  }
  setCodeLocked(bool) {
    this.codeLocked = bool;
  }
  setCurrentFlowData(bool) {
    this.currentFlowData = bool;
  }
  setLoadInFeed(bool) {
    this.loadInFeed = bool;
  }

  setRemixes(remixes) {
    this.remixes = remixes;
  }
  setTitle(title) {
    this.title = title;
  }
  setUrlPhoto(urlPhoto) {
    this.urlPhoto = urlPhoto;
  }
  setDate(date) {
    this.date = date;
  }
  setUserName(username) {
    this.username = username;
  }
  setCollaborators(collaborators) {
    this.collaborators = collaborators;
  }
  showDescriptionMobile(param) {
    this.showDescriptionMobile = param;
  }
  setFlowId(flowId) {
    this.flowId = flowId;
  }
  setDeductCreditFrom(bool) {
    this.deductCreditFrom = bool;
  }
  setUID(bool) {
    this.uid = bool;
  }
  setIsAReShare(bool) {
    this.isAReShare = bool;
  }
  setCurrentFlowId(bool) {
    this.currentFlowId = bool;
  }
  setAITrainerModel(param) {
    this.aiTrainerModel = param;
  }
  setIsAResult(bool) {
    this.isAResult = bool;
  }
  setCSS(param) {
    this.css = param;
  }
  setFlowData(param) {
    this.flowData = param;
  }
  setHasAudio(param) {
    this.hasAudio = param;
  }
  setDHTMLEditor(param) {
    this.dhtmlEditor = param;
  }
  setDHTML_HTML(html) {
    this.dhtml_HTML = html;
  }
  setDHTML_CSS(css) {
    this.dhtml_CSS = css;
  }
  setDHTML_JS(js) {
    this.dhtml_JS = js;
  }
  setGoToGuide(param) {
    this.goToGuide = param;
  }
  setShowShareModal(param) {
    this.showShareModal = param;
  }
  setShowPostModal(param) {
    this.showPostModal = param;
  }
  setShowCreateAI(param) {
    this.showCreateAI = param;
  }
  setDescription(text) {
    this.description = text;
  }
  setCodeModification(param) {
    this.codeModification = param;
  }
  setCurrentHTML(html) {
    this.currentHTML = html;
  }
  setCurrentJS(js) {
    this.currentJS = js;
  }
  setCurrentCSS(css) {
    this.currentCSS = css;
  }
  setTurnOffRemix(bool) {
    this.turnOffRemix = bool;
  }
  setDuplicateCheckOff(bool) {
    this.duplicateCheckOff = bool;
  }
  setIsSaving(bool) {
    this.isSaving = bool;
  }
  setWarning(bool) {
    this.warning = bool;
  }
  setWarningMessage(message) {
    this.warningMessage = message;
  }
  setIsProAccount(bool) {
    this.isProAccount = bool;
  }
  setRemixPrice(param) {
    this.remixPrice = param;
  }
  setRemixPriceInitial(param) {
    this.remixPriceInitial = param;
  }
  setFlowIsMonetized(param) {
    this.flowIsMonetized = param;
  }
  setFlowIsMonetizedInitial(param) {
    this.flowIsMonetizedInitial = param;
  }
  setFlowPrice(param) {
    this.flowPrice = param;
  }
  setFlowPriceInitial(param) {
    this.flowPriceInitial = param;
  }
  setFlowIsApproved(param) {
    this.flowIsApproved = param;
  }
  setFlowIsApprovedInitial(bool) {
    this.flowIsApprovedInitial = bool;
  }
  setPaidRemix(bool) {
    this.paidRemix = bool;
  }
  setPaidRemixInitial(bool) {
    this.paidRemixInitial = bool;
  }
  setFlowPreview(bool) {
    this.flowPreview = bool;
  }
  setFlowPreviewInitial(bool) {
    this.flowPreviewInitial = bool;
  }
  setFlowThumbnail(bool) {
    this.flowThumbnail = bool;
  }
  setFlowThumbnailInitial(bool) {
    this.flowThumbnailInitial = bool;
  }
  setFlowPerMonth(bool) {
    this.flowPerMonth = bool;
  }
  setFlowPerMonthInitial(bool) {
    this.flowPerMonthInitial = bool;
  }
  setFlowFreeTrial(bool) {
    this.flowFreeTrial = bool;
  }
  setFlowFreeTrialInitial(bool) {
    this.flowFreeTrialInitial = bool;
  }
  setFlowPerYear(param) {
    this.flowPerYear = param;
  }
  setFlowPerYearInitial(param) {
    this.flowPerYearInitial = param;
  }
  setMonetized(param) {
    this.monetized = param;
  }
  setMonetizedInitial(param) {
    this.monetizedInitial = param;
  }
  setFlowDays(param) {
    this.flowDays = param;
  }
  setFlowDaysInitial(param) {
    this.flowDaysInitial = param;
  }
  setFlowNumberOfUses(param) {
    this.flowNumberOfUses = param;
  }
  setFlowNumberOfUsesInitial(param) {
    this.flowNumberOfUsesInitial = param;
  }
  setFlowPreviewId(param) {
    this.flowPreviewId = param;
  }
  setFlowPreviewIdInitial(param) {
    this.flowPreviewIdInitial = param;
  }
  setLoadThumbnail(param) {
    this.loadThumbnail = param;
  }
  setLoadThumbnailInitial(param) {
    this.loadThumbnailInitial = param;
  }
  setIsRemixable(param) {
    this.isRemixable = param;
  }
  setIsRemixableInitial(param) {
    this.isRemixableInitial = param;
  }
  setLoadThumbnailPaid(param) {
    this.loadThumbnailPaid = param;
  }
  setIsRemixableByEveryone(param) {
    this.isRemixableByEveryone = param;
  }
  setIsRemixableByEveryoneInitial(param) {
    this.isRemixableByEveryoneInitial = param;
  }
  setIsRemixableByDevOnly(param) {
    this.isRemixableByDevOnly = param;
  }
  setIsRemixableByDevOnlyInitial(param) {
    this.isRemixableByDevOnlyInitial = param;
  }
  setIsRemixableByMeOnly(param) {
    this.isRemixableByMeOnly = param;
  }
  setIsRemixableByMeOnlyInitial(param) {
    this.isRemixableByMeOnlyInitial = param;
  }
  setAIModifyRemix(param) {
    this.aiModifyRemix = param;
  }
  setAIModifyRemixInitial(param) {
    this.aiModifyRemixInitial = param;
  }
  setEditorRemix(param) {
    this.editorRemix = param;
  }
  setEditorRemixInitial(param) {
    this.editorRemixInitial = param;
  }
  setSpecificRemixing(param) {
    this.specificRemixing = param;
  }
  setSpecificRemixingInitial(param) {
    this.specificRemixingInitial = param;
  }
  setLoadThumbnailPaidInitial(param) {
    this.loadThumbnailPaidInitial = param;
  }
  setFlowMonthly(param) {
    this.flowMonthly = param;
  }
  setFlowAnnually(param) {
    this.flowAnnually = param;
  }
  setFlowAnnuallyInitial(param) {
    this.flowAnnuallyInitial = param;
  }
  setYourRole(param) {
    this.yourRole = param;
  }
  setConvoArray(newArray) {
    this.convoArray = newArray;
  }
  setCurrentTokensAICreateAvailable(number) {
    this.currentTokensAICreateAvailable = number;
  }
  setCurrentModelAICreate(param) {
    this.currentModelAICreate = param;
  }
  setShowTooltip(param) {
    this.showTooltip = param;
  }
  setShowPostOptions(param) {
    this.showPostOptions = param;
  }
  setShowApprovalChat(param) {
    this.showApprovalChat = param;
  }
  setFlowThumbnailImage(image) {
    this.flowThumbnailImage = image;
  }
  setShowRemixWithWidgetButton(bool) {
    this.showRemixWithWidgetButton = bool;
  }
  setShowMoreModal(bool) {
    this.showMoreModal = bool;
  }
  setShowAdvancedModal(bool) {
    this.showAdvancedModal = bool;
  }
  setAdvanceCodeMode(bool) {
    this.advancedCodeMode = bool;
  }
  setAlreadySetAdvancedMode(bool){
    this.alreadySetAdvancedMode = bool;
  }
  setVisibleIcons(bool) {
    this.visibleIcons = bool;
  }
  setAdminIsLoggedIn(bool) {
    this.adminIsLoggedIn = bool;
  }
  setLikes(bool) {
    this.likes = bool;
  }
  setURLHTML(urlHTML) {
    this.urlHTML = urlHTML;
  }
  setURLCSS(urlCSS) {
    this.urlCSS = urlCSS;
  }
  setURLJS(urlJS) {
    this.urlJS = urlJS;
  }
  setOtherProfilePic(string) {
    this.otherProfilePic = string;
  }
  setIsPanelOpen(param) {
    this.IsPanelOpen = param;
  }
  setDeniedCodeEditor(param) {
    this.deniedCodeEditor = param;
  }
  setComments(param) {
    this.comments = param;
  }
  setSelectedTab(param) {
    this.selectedTab = param;
  }
  setClosePanel(param){
    this.closePanel = param;
  }
  setUserId(param){
    this.userId = param;
  }
  setShareCount(num) {
    this.shareCount = num;
  }
  setIsBlocked(param) {
    this.isBlocked = param;
  }
  setTotalCreditsNeededForFlow(total) {
    this.totalCreditsNeededForFlow = total;
  }
setAllowMessages(param) {
  this.allowMessages = param;
}

setAllowMessagesBlocked(param) {
  this.allowMessagesBlocked = param;
}
setReloadProfile(param) {
  this.reloadProfile = param;
}
setRemixedCount(param) {
  this.remixedCount = param;
}
setSearchFilter(param) {
  this.searchFilter = param;
}
setShowSaveButton(param) {
  this.showSaveButton = param;
}
setSelectedBookmark(param) {
  this.selectedBookmark = param;
}
setFlowIdOP(param) {
  this.flowIdOP = param;
}

}

export const EditorStoreContext = createContext(new EditorStore());
