import {
  faCode,
  faDownload,
  faEnvelope,
  faInfoCircle,
  faLink,
  faPaperPlane,
  faShare,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FaFacebook,
  FaLinkedin,
  FaReddit,
  FaRss,
  FaSnapchat,
  FaTiktok,
  FaTumblr,
  FaTwitter,
} from "react-icons/fa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { Config } from "../../../config/Config";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { ShareStoreContext } from "../../store/ShareStore.js";

import { EditorStoreContext } from "../../store/EditorStore.js";
import { TabStoreContext } from "../../store/TabStore.js";

import { FlowStoreContext } from "../../store/FlowStore.js";

import Hashids from "hashids";
import Checkbox from "react-custom-checkbox";
import ReactModal from "react-modal";
import { v4 as uuid } from "uuid";
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";

import LoginM from "./LoginM.js";

// Import the libraries
const html2canvas = require("html2canvas");
const gifshot = require("gifshot");
const dataUrls = [];
const promises = [];

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);

const Html = () => {
  return (
    <div
      id="recording"
      style={{ height: 300, width: 300, zIndex: 1000, background: "red" }}
    ></div>
  );
};

const Share = observer((props) => {
  const FlowStore = useContext(FlowStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const TabStore = useContext(TabStoreContext);

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const ShareStore = useContext(ShareStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const DHTML_Store = useContext(DHTML_StoreContext);
  const [copiedClipboardShare, setCopiedClipboardShare] = useState();
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState();
  const [shareWithOwnCredits, setShareWithOwnCredits] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareInfo, setShowShareInfo] = useState(false);
  const [notEnough, setNotEnough] = useState(false);
  const [flowIdCopy, setFlowIdCopy] = useState("");
  const [showScreenshot, setShowScreenshot] = useState(false);
  const [showShareInfoCopyLinkResult, setShowShareInfoCopyLinkResult] =
    useState(false);
  const { currentUser } = useContext(AuthContext);

  let isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    let hashids = new Hashids(uuid(), 6);
    let flowId = hashids.encode(1, 2, 3);
    setFlowIdCopy(flowId);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  const shareTest = async (flowId) => {
    let firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let flowIdGenerated = flowId;
    const snapshotFlows = await firestore
      .collection("flows")
      .where("flowId", "==", props.flowId)
      .limit(1)
      .get();

    // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

    snapshotFlows.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] like:", data);

      let RoomData = {
        canvasWidth: data.canvasWidth,
        canvasHeight: data.canvasHeight,
        date: new Date(),
        description: data.description,
        flowId: flowIdGenerated, //generate new
        thumbnail: data.thumbnail,
        animation: data.animation,
        title: data.title,
        urlHTML: data.urlHTML,
        urlCSS: data.urlCSS,
        urlJS: data.urlJS,
        urlPhoto: data.urlPhoto,
        userId: userId,
        username: data.username,
        visibility: "hidden",
        modelId: data.modelId,
        libraries: data.libraries,
        cssLibraries: data.cssLibraries,
        isRemixable: data.isRemixable,
        AITextPopulated: data.AITextPopulated,
        Contributors: data.Contributors,
        widgets: data.widgets,
        imageIndexes: data.imageIndexes,
        textInputIndexes: data.textInputIndexes,
        colorSelectIndexes: data.colorSelectIndexes,
        sliderIndexSelect: data.sliderIndexes,
        switchIndexSelect: data.switchIndexes,
        selectIndexSelect: data.selectIndexes,
        checkboxIndexSelect: data.checkboxIndexes,
        calendarIndexSelect: data.calendarIndexes,
        imageUploaderCount: data.imageUploaderCount,
        editorLocked: data.editorLocked,
        editorLockedPassword: data.editorLockedPassword,
        editorLockedSelected: data.editorLockedSelected,
        disableComments: data.disableComments,
        isAR: data.isAR,
        isVR: data.isVR,
        isLive: data.isLive,
        isAI: data.isAI,
        hashtags: data.hashtags,
        hasRemixableAI: data.hasRemixableAI,
        followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
        flowRemixer: data.flowRemixer,
        flowIdOP: data.flowIdOP,
        collaborators: data.collaborators,
        isAllDevice: data.isAllDevice,
        deviceMobile: data.deviceMobile,
        deviceTablet: data.deviceTablet,
        deviceDesktop: data.deviceDesktop,
        paymentOneTimeFee: data.paymentOneTimeFee,
        paymentSubscription: data.paymentSubscription,
        paymentFreeTrial: data.paymentFreeTrial,
        isFeatured: false,
        isAReShare: false,
        isPinned: false,
        isPaidPromotion: false,
        isReplyInComments: false,
        isPinnedBy: firebase.auth().currentUser.displayName,
        // remixedCount: data.remixedCount,
        isRemixed: data.isRemixed,
        paymentPlan: data.paymentPlan,
        isRemixable: data.isRemixable,
        enableGifRecap: data.enableGifRecap,
        enableAIGifRecap: data.enableAIGifRecap,
        screencap: data.screencap,
        enableSpecialShare: data.enableSpecialShare,
        deductCreditFrom: {
          uid: firebase.auth().currentUser.uid,
          numberOfTimes: 0,
        },
        uid: firebase.auth().currentUser.uid,
        resultCode: "",
      };
      document.getElementById(`copy-link-${props.flowId}`).innerText =
        "Copied to clipboard!";

      setTimeout(() => {
        document.getElementById(`copy-link-${props.flowId}`).innerText =
          "Copy link";
      }, 1000);
      createShare(RoomData);
    });
  };

  const copyLinkResultWithCredits = async (flowId) => {
    let firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let flowIdGenerated = flowId;
    const snapshotFlows = await firestore
      .collection("flows")
      .where("flowId", "==", props.flowId)
      .limit(1)
      .get();
    let iframeCodeCSS = EditorStore.css;
    let iframeCodeBody = document.getElementById("undefined_output_frame")
      .contentDocument.body.innerHTML;

    let iframeCode =
      "<style>" +
      iframeCodeCSS +
      "</style>" +
      "<body>" +
      iframeCodeBody +
      "</body>";
    console.log(iframeCode);

    // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

    snapshotFlows.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] like:", data);

      let RoomData = {
        canvasWidth: data.canvasWidth,
        canvasHeight: data.canvasHeight,
        date: new Date(),
        description: data.description,
        flowId: flowIdGenerated, //generate new
        thumbnail: data.thumbnail,
        animation: data.animation,
        title: data.title,
        urlHTML: "",
        urlCSS: "",
        urlJS: "",
        urlPhoto: data.urlPhoto,
        userId: userId,
        username: data.username,
        visibility: "hidden",
        modelId: data.modelId,
        libraries: data.libraries,
        cssLibraries: data.cssLibraries,
        isRemixable: data.isRemixable,
        AITextPopulated: data.AITextPopulated,
        Contributors: data.Contributors,
        widgets: data.widgets,
        imageIndexes: data.imageIndexes,
        textInputIndexes: data.textInputIndexes,
        colorSelectIndexes: data.colorSelectIndexes,
        sliderIndexSelect: data.sliderIndexes,
        switchIndexSelect: data.switchIndexes,
        selectIndexSelect: data.selectIndexes,
        checkboxIndexSelect: data.checkboxIndexes,
        calendarIndexSelect: data.calendarIndexes,
        imageUploaderCount: data.imageUploaderCount,
        editorLocked: data.editorLocked,
        editorLockedPassword: data.editorLockedPassword,
        editorLockedSelected: data.editorLockedSelected,
        disableComments: data.disableComments,
        isAR: data.isAR,
        isVR: data.isVR,
        isLive: data.isLive,
        isAI: data.isAI,
        hashtags: data.hashtags,
        hasRemixableAI: data.hasRemixableAI,
        followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
        flowRemixer: data.flowRemixer,
        flowIdOP: data.flowIdOP,
        collaborators: data.collaborators,
        isAllDevice: data.isAllDevice,
        deviceMobile: data.deviceMobile,
        deviceTablet: data.deviceTablet,
        deviceDesktop: data.deviceDesktop,
        paymentOneTimeFee: data.paymentOneTimeFee,
        paymentSubscription: data.paymentSubscription,
        paymentFreeTrial: data.paymentFreeTrial,
        isFeatured: false,
        isAReShare: false,
        isPinned: false,
        isPaidPromotion: false,
        isReplyInComments: false,
        isAResult: true,
        isPinnedBy: firebase.auth().currentUser.displayName,
        // remixedCount: data.remixedCount,
        isRemixed: data.isRemixed,
        paymentPlan: data.paymentPlan,
        isRemixable: data.isRemixable,
        enableGifRecap: data.enableGifRecap,
        enableAIGifRecap: data.enableAIGifRecap,
        screencap: data.screencap,
        enableSpecialShare: data.enableSpecialShare,
        deductCreditFrom: {
          uid: firebase.auth().currentUser.uid,
          numberOfTimes: 0,
        },
        uid: firebase.auth().currentUser.uid,
        resultCode: iframeCode,
      };
      document.getElementById(
        `copy-link-with-result-${props.flowId}`
      ).innerText = "Copied to clipboard!";

      setTimeout(() => {
        document.getElementById(
          `copy-link-with-result-${props.flowId}`
        ).innerText = "Copy link with result";
      }, 1000);
      createShare(RoomData);
    });
  };

  const reshareWithResult = async (flowId) => {
    // WidgetStore.setGetCodeFromIframe(true);
    let firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let flowIdGenerated = flowId;
    const snapshotFlows = await firestore
      .collection("flows")
      .where("flowId", "==", props.flowId)
      .limit(1)
      .get();
    // Get the iframe element
    // var iframe = document.getElementById("undefined_output_frame");

    // Send a message to the iframe to extract its HTML
    // In the parent window

    let html = localStorage.getItem("widgetHTML");
    let css = localStorage.getItem("widgetCSS");
    let js = localStorage.getItem("widgetJS");

    let iframe_code =
      html + "<style>" + css + "</style>" + "<script>" + js + "</script>";

    console.log("html:", html);
    console.log("css:", css);
    console.log("js:", js);

    snapshotFlows.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] like:", data);

      let RoomData = {
        canvasWidth: data.canvasWidth,
        canvasHeight: data.canvasHeight,
        date: new Date(),
        description: data.description,
        flowId: flowIdGenerated, //generate new
        thumbnail: data.thumbnail,
        animation: data.animation,
        title: data.title,
        urlHTML: "",
        urlCSS: "",
        urlJS: "",
        urlPhoto: data.urlPhoto,
        userId: userId,
        username: data.username,
        visibility: "public",
        modelId: data.modelId,
        libraries: data.libraries,
        cssLibraries: data.cssLibraries,
        isRemixable: data.isRemixable,
        AITextPopulated: data.AITextPopulated,
        Contributors: data.Contributors,
        widgets: data.widgets,
        imageIndexes: data.imageIndexes,
        textInputIndexes: data.textInputIndexes,
        colorSelectIndexes: data.colorSelectIndexes,
        sliderIndexSelect: data.sliderIndexes,
        switchIndexSelect: data.switchIndexes,
        selectIndexSelect: data.selectIndexes,
        checkboxIndexSelect: data.checkboxIndexes,
        calendarIndexSelect: data.calendarIndexes,
        imageUploaderCount: data.imageUploaderCount,
        editorLocked: data.editorLocked,
        editorLockedPassword: data.editorLockedPassword,
        editorLockedSelected: data.editorLockedSelected,
        disableComments: data.disableComments,
        isAR: data.isAR,
        isVR: data.isVR,
        isLive: data.isLive,
        isAI: data.isAI,
        hashtags: data.hashtags,
        hasRemixableAI: data.hasRemixableAI,
        followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
        flowRemixer: data.flowRemixer,
        flowIdOP: data.flowIdOP,
        collaborators: data.collaborators,
        isAllDevice: data.isAllDevice,
        deviceMobile: data.deviceMobile,
        deviceTablet: data.deviceTablet,
        deviceDesktop: data.deviceDesktop,
        paymentOneTimeFee: data.paymentOneTimeFee,
        paymentSubscription: data.paymentSubscription,
        paymentFreeTrial: data.paymentFreeTrial,
        isFeatured: false,
        isAReShare: true,
        isPinned: false,
        isPaidPromotion: false,
        isReplyInComments: false,
        isAResult: true,
        isPinnedBy: firebase.auth().currentUser.displayName,
        // remixedCount: data.remixedCount,
        isRemixed: data.isRemixed,
        paymentPlan: data.paymentPlan,
        isRemixable: data.isRemixable,
        enableGifRecap: data.enableGifRecap,
        enableAIGifRecap: data.enableAIGifRecap,
        screencap: data.screencap,
        enableSpecialShare: data.enableSpecialShare,
        deductCreditFrom: {},
        resharedBy: firebase.auth().currentUser.displayName,
        resultCode: iframe_code,
      };

      createReShare(RoomData);
    });
  };

  const copyLinkResultWithoutCredits = async (flowId) => {
    let firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let flowIdGenerated = flowId;
    const snapshotFlows = await firestore
      .collection("flows")
      .where("flowId", "==", props.flowId)
      .limit(1)
      .get();
    let iframeCode = document
      .getElementById("undefined_output_frame")
      .contentWindow.document.getElementsByTagName("html")[0].innerHTML;

    // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

    snapshotFlows.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] like:", data);

      let RoomData = {
        canvasWidth: data.canvasWidth,
        canvasHeight: data.canvasHeight,
        date: new Date(),
        description: data.description,
        flowId: flowIdGenerated, //generate new
        thumbnail: data.thumbnail,
        animation: data.animation,
        title: data.title,
        urlHTML: "",
        urlCSS: "",
        urlJS: "",
        urlPhoto: data.urlPhoto,
        userId: userId,
        username: data.username,
        visibility: "hidden",
        modelId: data.modelId,
        libraries: data.libraries,
        cssLibraries: data.cssLibraries,
        isRemixable: data.isRemixable,
        AITextPopulated: data.AITextPopulated,
        Contributors: data.Contributors,
        widgets: data.widgets,
        imageIndexes: data.imageIndexes,
        textInputIndexes: data.textInputIndexes,
        colorSelectIndexes: data.colorSelectIndexes,
        sliderIndexSelect: data.sliderIndexes,
        switchIndexSelect: data.switchIndexes,
        selectIndexSelect: data.selectIndexes,
        checkboxIndexSelect: data.checkboxIndexes,
        calendarIndexSelect: data.calendarIndexes,
        imageUploaderCount: data.imageUploaderCount,
        editorLocked: data.editorLocked,
        editorLockedPassword: data.editorLockedPassword,
        editorLockedSelected: data.editorLockedSelected,
        disableComments: data.disableComments,
        isAR: data.isAR,
        isVR: data.isVR,
        isLive: data.isLive,
        isAI: data.isAI,
        hashtags: data.hashtags,
        hasRemixableAI: data.hasRemixableAI,
        followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
        flowRemixer: data.flowRemixer,
        flowIdOP: data.flowIdOP,
        collaborators: data.collaborators,
        isAllDevice: data.isAllDevice,
        deviceMobile: data.deviceMobile,
        deviceTablet: data.deviceTablet,
        deviceDesktop: data.deviceDesktop,
        paymentOneTimeFee: data.paymentOneTimeFee,
        paymentSubscription: data.paymentSubscription,
        paymentFreeTrial: data.paymentFreeTrial,
        isFeatured: false,
        isAReShare: false,
        isPinned: false,
        isPaidPromotion: false,
        isReplyInComments: false,
        isAResult: true,

        isPinnedBy: firebase.auth().currentUser.displayName,
        // remixedCount: data.remixedCount,
        isRemixed: data.isRemixed,
        paymentPlan: data.paymentPlan,
        isRemixable: data.isRemixable,
        enableGifRecap: data.enableGifRecap,
        enableAIGifRecap: data.enableAIGifRecap,
        screencap: data.screencap,
        enableSpecialShare: data.enableSpecialShare,
        deductCreditFrom: {},
        uid: firebase.auth().currentUser.uid,
        resultCode: iframeCode,
      };
      document.getElementById(`copy-link-${props.flowId}`).innerText =
        "Copied to clipboard!";

      setTimeout(() => {
        document.getElementById(`copy-link-${props.flowId}`).innerText =
          "Copy link";
      }, 1000);
      createShare(RoomData);
    });
  };

  const shareTestReShare = async (flowId) => {
    let firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let flowIdGenerated = flowId;
    const snapshotFlows = await firestore
      .collection("flows")
      .where("flowId", "==", props.flowId)
      .limit(1)
      .get();

    // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

    snapshotFlows.forEach((doc) => {
      let data = doc.data();
      //console.log("[RoomPosts] like:", data);

      let RoomData = {
        canvasWidth: data.canvasWidth,
        canvasHeight: data.canvasHeight,
        date: new Date(),
        description: data.description,
        flowId: flowIdGenerated, //generate new
        thumbnail: data.thumbnail,
        animation: data.animation,
        title: data.title,
        urlHTML: data.urlHTML,
        urlCSS: data.urlCSS,
        urlJS: data.urlJS,
        urlPhoto: data.urlPhoto,
        userId: userId,
        username: data.username,
        visibility: "public",
        modelId: data.modelId,
        libraries: data.libraries,
        cssLibraries: data.cssLibraries,
        isRemixable: data.isRemixable,
        AITextPopulated: data.AITextPopulated,
        Contributors: data.Contributors,
        widgets: data.widgets,
        imageIndexes: data.imageIndexes,
        textInputIndexes: data.textInputIndexes,
        colorSelectIndexes: data.colorSelectIndexes,
        sliderIndexSelect: data.sliderIndexes,
        switchIndexSelect: data.switchIndexes,
        selectIndexSelect: data.selectIndexes,
        checkboxIndexSelect: data.checkboxIndexes,
        calendarIndexSelect: data.calendarIndexes,
        imageUploaderCount: data.imageUploaderCount,
        editorLocked: data.editorLocked,
        editorLockedPassword: data.editorLockedPassword,
        editorLockedSelected: data.editorLockedSelected,
        disableComments: data.disableComments,
        isAR: data.isAR,
        isVR: data.isVR,
        isLive: data.isLive,
        isAI: data.isAI,
        hashtags: data.hashtags,
        hasRemixableAI: data.hasRemixableAI,
        followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
        flowRemixer: data.flowRemixer,
        flowIdOP: data.flowIdOP,
        collaborators: data.collaborators,
        isAllDevice: data.isAllDevice,
        deviceMobile: data.deviceMobile,
        deviceTablet: data.deviceTablet,
        deviceDesktop: data.deviceDesktop,
        paymentOneTimeFee: data.paymentOneTimeFee,
        paymentSubscription: data.paymentSubscription,
        paymentFreeTrial: data.paymentFreeTrial,
        isFeatured: false,
        isAReShare: true,
        isPinned: false,
        isPaidPromotion: false,
        isReplyInComments: false,
        isPinnedBy: firebase.auth().currentUser.displayName,
        // remixedCount: data.remixedCount,
        isRemixed: data.isRemixed,
        paymentPlan: data.paymentPlan,
        isRemixable: data.isRemixable,
        enableGifRecap: data.enableGifRecap,
        enableAIGifRecap: data.enableAIGifRecap,
        screencap: data.screencap,
        enableSpecialShare: data.enableSpecialShare,
        deductCreditFrom: {
          uid: firebase.auth().currentUser.uid,
          numberOfTimes: 0,
        },
        uid: firebase.auth().currentUser.uid,
      };
      createReShare(RoomData);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    });
  };

  const createRoom = (roomData) => {
    //Important to get your list of followers and share with them in their feed and your profile

    //post

    PublishMenuStore.startCreateRoom(roomData);
    // FlowStore.setFlowId(flowId);
    // FlowStore.setRoom(roomData);
    // FlowStore.setIsFlowOwner(true);
  };
  const createShare = (roomData) => {
    //Important to get your list of followers and share with them in their feed and your profile

    //post

    PublishMenuStore.startCreateRoomNoModal(roomData);
    // FlowStore.setFlowId(flowId);
    // FlowStore.setRoom(roomData);
    // FlowStore.setIsFlowOwner(true);
  };

  const createReShare = (roomData) => {
    //Important to get your list of followers and share with them in their feed and your profile

    //post

    PublishMenuStore.startCreateRoom(roomData);

    // FlowStore.setFlowId(flowId);
    // FlowStore.setRoom(roomData);
    // FlowStore.setIsFlowOwner(true);
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        // backgroundColor: "#222222",
        width: "100%",
      }}
    >
      {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 300,
                    background: "black",
                    padding: 10,
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: 16,
                      marginLeft: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Share
                  </p>
                  <i
                    onClick={() => {
                      document.getElementsByClassName(
                        "editor-menu"
                      )[0].style.paddingLeft = "78px";
                    }}
                    className="fas fa-times"
                    style={{ color: "white", fontSize: 14, cursor: "pointer" }}
                  ></i>
                </div> */}
      <div
        style={{
          justifyContent: "center",
          width: 300,
          backgroundColor: "rgb(63, 63, 62);",
          padding: 20,
          alignItems: "center",
          display: "flex",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
            fontFamily: "quicksand",
          }}
        >
          Share
        </p>

        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#f06263"
          size={"4x"}
          style={{
            display: "block",
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            document.getElementsByClassName(
              "editor-menu"
            )[0].style.paddingLeft = "78px";
            // document.getElementById(
            //   "horizontal-tab-remix-tab"
            // ).style.background = "none";

            document.getElementsByClassName("remix-menu")[0].style.display =
              "none";
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "rgb(63, 63, 62);",
          // width:'calc(100% - 50px)',
          overflowY: "scroll",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            // width: isMobile === true ? 277 : "240px",
            height: "calc(100vh - 100px)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              onClick={() => {
                if (currentUser == null) {
                  AuthenticationStore.setShowModal(true);
                  return;
                }
                if (!FlowStore.credit > 0) {
                  setNotEnough(true);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              {/* <Checkbox
            checked={EditorStore.postAsReply}
            icon={
              <div
                style={{
                  backgroundColor: "#4FD975",
                  // padding: 5,
                  height: 17,
                  width: 17,
                }}
              />
            }
            onChange={(e) => {
              EditorStore.setPostAsReply(e);
            }}
            // inputProps={{ "aria-label": "primary checkbox" }}
            style={{
              height: 20,
              backgroundColor: "white",
            }}
            borderColor={"#2d2a2b"}
            borderRadius={0}
            size={20}
          /> */}

              <Checkbox
                disabled={currentUser !== null}
                checked={shareWithOwnCredits}
                icon={
                  <div
                    style={{
                      backgroundColor: "#4FD975",
                      // padding: 5,
                      height: 25,
                      width: 25,
                      borderRadius: 5,
                    }}
                  />
                }
                onChange={(e) => {
                  if (e === true) {
                    setShareWithOwnCredits(true);
                  } else {
                    setShareWithOwnCredits(false);
                  }
                }}
                // inputProps={{ "aria-label": "primary checkbox" }}
                style={{
                  height: 20,
                  backgroundColor: "#2D2A2B",
                }}
                borderColor={"#2d2a2b"}
                borderRadius={10}
                size={35}
              />
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  marginLeft: 20,
                  marginRight: 10,
                  width: 170,
                }}
              >
                Share with your own credits
              </p>
              <FontAwesomeIcon
                onClick={() => {
                  setShowShareInfo(true);
                }}
                icon={faInfoCircle}
                color="#FEFFFE"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                  zIndex: 10000,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",

                marginBottom: 10,
                // background: "#222222",
                height: "100%",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {/* <CopyToClipboard
                style={{
                  fontFamily: "quicksand",
                  color: "white",
                  display: "flex",
                  cursor: "pointer",
                  marginBottom: 2,
                }}
                // text={`https://flowroom.com/room/${props.flowId}`}
                options={{ format: "text/plain" }}
              >
                <div
                  onClick={() => {
                    // setCopiedClipboardShare(true);
                    document.getElementById("copy-link").innerText =
                      "Copied to clipboard!";
                    setTimeout(() => {
                      document.getElementById("copy-link").innerText =
                        "Copy link";
                    }, 1000);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faLink}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <p
                    id="copy-link"
                    style={{
                      marginLeft: 10,
                      fontFamily: "quicksand",
                      color: "#949DF2",
                      fontSize: 19,
                      position: "relative",
                      bottom: 4,
                    }}
                  >
                    Copy link
                  </p>
                </div>
              </CopyToClipboard> */}
              {shareWithOwnCredits == true ? (
                <CopyToClipboard
                  style={{
                    fontFamily: "quicksand",
                    color: "white",
                    display: "flex",
                    cursor: "pointer",
                    marginBottom: 2,
                    zIndex: 1000,
                  }}
                  text={`https://flowroom.com/room/${flowIdCopy}`}
                  options={{ format: "text/plain" }}
                >
                  <div
                    onClick={() => {
                      shareTest(flowIdCopy);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faLink}
                      color="#6371F6"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      id={`copy-link-${props.flowId}`}
                      style={{
                        marginLeft: 10,
                        fontFamily: "quicksand",
                        color: "#949DF2",
                        fontSize: 19,
                        position: "relative",
                        bottom: 4,
                      }}
                    >
                      Copy link
                    </p>
                  </div>
                </CopyToClipboard>
              ) : (
                <CopyToClipboard
                  style={{
                    fontFamily: "quicksand",
                    color: "white",
                    display: "flex",
                    cursor: "pointer",
                    marginBottom: 2,
                    zIndex: 1000,
                  }}
                  text={`https://flowroom.com/room/${props.flowId}`}
                  options={{ format: "text/plain" }}
                >
                  <div
                    onClick={() => {
                      document.getElementById(
                        `copy-link-${props.flowId}`
                      ).innerText = "Copied to clipboard!";

                      setTimeout(() => {
                        document.getElementById(
                          `copy-link-${props.flowId}`
                        ).innerText = "Copy link";
                      }, 1000);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faLink}
                      color="#6371F6"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      id={`copy-link-${props.flowId}`}
                      style={{
                        marginLeft: 10,
                        fontFamily: "quicksand",
                        color: "#949DF2",
                        fontSize: 19,
                        position: "relative",
                        bottom: 4,
                      }}
                    >
                      Copy link
                    </p>
                  </div>
                </CopyToClipboard>
              )}
              <div style={{ display: "flex" }}>
                {shareWithOwnCredits == true ? (
                  <CopyToClipboard
                    style={{
                      fontFamily: "quicksand",
                      color: "white",
                      display: "flex",
                      cursor: "pointer",
                      marginBottom: 2,
                      zIndex: 1000,
                    }}
                    text={`https://flowroom.com/room/${flowIdCopy}`}
                    options={{ format: "text/plain" }}
                  >
                    {/* <div
                      onClick={() => {
                        copyLinkResultWithCredits(flowIdCopy);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        key={Math.random() + "icon" + new Date()}
                        icon={faLink}
                        color="#6371F6"
                        style={{ display: "flex", fontSize: 25 }}
                      />
                      <p
                        id={`copy-link-with-result-${props.flowId}`}
                        style={{
                          marginLeft: 10,
                          fontFamily: "quicksand",
                          color: "#949DF2",
                          fontSize: 19,
                          position: "relative",
                          bottom: 4,
                        }}
                      >
                        Copy link with result
                      </p>
                    </div> */}
                  </CopyToClipboard>
                ) : (
                  <CopyToClipboard
                    style={{
                      fontFamily: "quicksand",
                      color: "white",
                      display: "flex",
                      cursor: "pointer",
                      marginBottom: 2,
                      zIndex: 1000,
                    }}
                    text={`https://flowroom.com/room/${flowIdCopy}`}
                    options={{ format: "text/plain" }}
                  >
                    {/* <div
                      onClick={() => {
                        copyLinkResultWithCredits(flowIdCopy);

                        document.getElementById(
                          `copy-link-with-result-${props.flowId}`
                        ).innerText = "Copied to clipboard!";

                        setTimeout(() => {
                          document.getElementById(
                            `copy-link-with-result-${props.flowId}`
                          ).innerText = "Copy link with result";
                        }, 1000);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        key={Math.random() + "icon" + new Date()}
                        icon={faLink}
                        color="#6371F6"
                        style={{ display: "flex", fontSize: 25 }}
                      />
                      <p
                        id={`copy-link-with-result-${props.flowId}`}
                        style={{
                          marginLeft: 10,
                          fontFamily: "quicksand",
                          color: "#949DF2",
                          fontSize: 19,
                          position: "relative",
                          bottom: 4,
                        }}
                      >
                        Copy link with result
                      </p>
                    </div> */}
                  </CopyToClipboard>
                )}

                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfoCopyLinkResult(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 10,
                  }}
                />
              </div>
              <div
                onClick={() => {
                  document.getElementById("copy-embed-result").innerText =
                    "Copied!";

                  setTimeout(() => {
                    document.getElementById("copy-embed-result").innerText =
                      "Copy embed result";
                  }, 1000);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: 235,
                  marginBottom: 4,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faCode}
                  color="#6371F6"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  id="copy-embed-result"
                  style={{
                    fontFamily: "quicksand",
                    color: "#949DF2",
                    fontSize: 19,
                    width: 174,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                >
                  Copy embed result
                </p>

                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 0,
                    marginBottom: 5,
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={() => {
                    document.getElementById("copy-embed").innerText = "Copied!";

                    setTimeout(() => {
                      document.getElementById("copy-embed").innerText =
                        "Copy embed";
                    }, 1000);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 152,
                    marginBottom: 4,
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faCode}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <CopyToClipboard
                    style={{
                      fontFamily: "quicksand",
                      color: "rgb(148, 157, 242)",
                      fontSize: 19,
                      position: "relative",
                      display: "flex",
                      cursor: "pointer",
                      marginBottom: 2,
                      zIndex: 1000,
                      width: 117,
                    }}
                    text={`<div id="flow_${FlowStore.flowId}"></div>
<script>
  var script = document.createElement('script');
  script.onload = function() {
    flowInit({ divId: "flow_${FlowStore.flowId}", flowId: "${FlowStore.flowId}", isCenter: true, width: 468, height: 468 });
  };
  script.src = 'https://s3.amazonaws.com/demo1.flowroom.com/js/flow.js?_=1';
  document.head.appendChild(script);
</script>`}
                    options={{ format: "text/plain" }}
                  >
                    <p
                      id="copy-embed"
                      style={{
                        fontFamily: "quicksand",
                        color: "rgb(148, 157, 242)",
                        fontSize: 19,
                        position: "relative",
                        width: 117,
                      }}
                    >
                      Copy embed
                    </p>
                  </CopyToClipboard>
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                />
              </div>{" "}
              {/* <div
                onClick={() => {
                  if (
                    firebase.auth().currentUser == null ||
                    firebase.auth().currentUser.displayName == null
                  ) {
                    AuthenticationStore.setShowModal(true);
                    return;
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 210,
                  justifyContent: "space-between",
                  marginBottom: 4,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faShare}
                  color="#A962F1"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#CAA6EF",
                    fontSize: 19,
                  }}
                >
                  Reply with flow
                </p>
                <FontAwesomeIcon
                  onClick={() => {
                    // setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 5,
                    marginBottom: 5,
                  }}
                />
              </div> */}
              {/* <div
                style={{ display: "flex", width: 138, alignItems: "center" }}
              >
                {shareWithOwnCredits == true ? (
                  <div
                    onClick={async () => {
                      if (!props.isAReShare) {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        shareTestReShare(flowIdCopy);
                      } else {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        shareTestReShare(flowIdCopy);
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 177,
                      justifyContent: "space-between",
                      marginBottom: 4,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faShare}
                      color="#A962F1"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      style={{
                        fontFamily: "quicksand",
                        color: "#CAA6EF",
                        fontSize: 19,
                      }}
                    >
                      Reshare
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={async () => {
                      if (!props.isAReShare) {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        let userId = firebase.auth().currentUser.uid;
                        let hashids = new Hashids(uuid(), 6);
                        let flowId = hashids.encode(1, 2, 3);
                        let firestore = firebase.firestore();

                        const snapshotFlows = await firestore
                          .collection("flows")
                          .where("flowId", "==", props.flowId)
                          .limit(1)
                          .get();

                        // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

                        snapshotFlows.forEach((doc) => {
                          let data = doc.data();
                          //console.log("[RoomPosts] like:", data);

                          let RoomData = {
                            canvasWidth: data.canvasWidth,
                            canvasHeight: data.canvasHeight,
                            date: new Date(),
                            description: data.description,
                            flowId: flowId, //generate new
                            thumbnail: data.thumbnail,
                            animation: data.animation,
                            title: data.title,
                            urlHTML: data.urlHTML,
                            urlCSS: data.urlCSS,
                            urlJS: data.urlJS,
                            urlPhoto: data.urlPhoto,
                            userId: userId,
                            username: data.username,
                            visibility: data.visibility,
                            modelId: data.modelId,
                            libraries: data.libraries,
                            cssLibraries: data.cssLibraries,
                            isRemixable: data.isRemixable,
                            AITextPopulated: data.AITextPopulated,
                            Contributors: data.Contributors,
                            widgets: data.widgets,
                            imageIndexes: data.imageIndexes,
                            textInputIndexes: data.textInputIndexes,
                            colorSelectIndexes: data.colorSelectIndexes,
                            sliderIndexSelect: data.sliderIndexes,
                            switchIndexSelect: data.switchIndexes,
                            selectIndexSelect: data.selectIndexes,
                            checkboxIndexSelect: data.checkboxIndexes,
                            calendarIndexSelect: data.calendarIndexes,
                            imageUploaderCount: data.imageUploaderCount,
                            editorLocked: data.editorLocked,
                            editorLockedPassword: data.editorLockedPassword,
                            editorLockedSelected: data.editorLockedSelected,
                            disableComments: data.disableComments,
                            isAR: data.isAR,
                            isVR: data.isVR,
                            isLive: data.isLive,
                            isAI: data.isAI,
                            hashtags: data.hashtags,
                            hasRemixableAI: data.hasRemixableAI,
                            followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
                            flowRemixer: data.flowRemixer,
                            flowIdOP: data.flowIdOP,
                            collaborators: data.collaborators,
                            isAllDevice: data.isAllDevice,
                            deviceMobile: data.deviceMobile,
                            deviceTablet: data.deviceTablet,
                            deviceDesktop: data.deviceDesktop,
                            paymentOneTimeFee: data.paymentOneTimeFee,
                            paymentSubscription: data.paymentSubscription,
                            paymentFreeTrial: data.paymentFreeTrial,
                            isFeatured: false,
                            isAReShare: true,
                            isPinned: false,
                            isPaidPromotion: false,
                            isReplyInComments: false,
                            isPinnedBy: firebase.auth().currentUser.displayName,
                            // remixedCount: data.remixedCount,
                            isRemixed: data.isRemixed,
                            paymentPlan: data.paymentPlan,
                            isRemixable: data.isRemixable,
                            enableGifRecap: data.enableGifRecap,
                            enableAIGifRecap: data.enableAIGifRecap,
                            screencap: data.screencap,
                            enableSpecialShare: data.enableSpecialShare,
                            AITrainerModel: EditorStore.aiTrainerModel,
                            resharedBy: firebase.auth().currentUser.displayName,
                          };

                          createRoom(RoomData);
                        });
                      } else {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }

                        let userId = firebase.auth().currentUser.uid;
                        let hashids = new Hashids(uuid(), 6);
                        let flowId = hashids.encode(1, 2, 3);
                        let firestore = firebase.firestore();

                        const snapshotFlows = await firestore
                          .collection("flows")
                          .where("flowId", "==", props.flowId)
                          .limit(1)
                          .get();

                        // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

                        snapshotFlows.forEach((doc) => {
                          let data = doc.data();
                          //console.log("[RoomPosts] like:", data);

                          let RoomData = {
                            canvasWidth: data.canvasWidth,
                            canvasHeight: data.canvasHeight,
                            date: new Date(),
                            description: data.description,
                            flowId: flowId, //generate new
                            thumbnail: data.thumbnail,
                            animation: data.animation,
                            title: data.title,
                            urlHTML: data.urlHTML,
                            urlCSS: data.urlCSS,
                            urlJS: data.urlJS,
                            urlPhoto: data.urlPhoto,
                            userId: userId,
                            username: data.username,
                            visibility: data.visibility,
                            modelId: data.modelId,
                            libraries: data.libraries,
                            cssLibraries: data.cssLibraries,
                            isRemixable: data.isRemixable,
                            AITextPopulated: data.AITextPopulated,
                            Contributors: data.Contributors,
                            widgets: data.widgets,
                            imageIndexes: data.imageIndexes,
                            textInputIndexes: data.textInputIndexes,
                            colorSelectIndexes: data.colorSelectIndexes,
                            sliderIndexSelect: data.sliderIndexes,
                            switchIndexSelect: data.switchIndexes,
                            selectIndexSelect: data.selectIndexes,
                            checkboxIndexSelect: data.checkboxIndexes,
                            calendarIndexSelect: data.calendarIndexes,
                            imageUploaderCount: data.imageUploaderCount,
                            editorLocked: data.editorLocked,
                            editorLockedPassword: data.editorLockedPassword,
                            editorLockedSelected: data.editorLockedSelected,
                            disableComments: data.disableComments,
                            isAR: data.isAR,
                            isVR: data.isVR,
                            isLive: data.isLive,
                            isAI: data.isAI,
                            hashtags: data.hashtags,
                            hasRemixableAI: data.hasRemixableAI,
                            followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
                            flowRemixer: data.flowRemixer,
                            flowIdOP: data.flowIdOP,
                            collaborators: data.collaborators,
                            isAllDevice: data.isAllDevice,
                            deviceMobile: data.deviceMobile,
                            deviceTablet: data.deviceTablet,
                            deviceDesktop: data.deviceDesktop,
                            paymentOneTimeFee: data.paymentOneTimeFee,
                            paymentSubscription: data.paymentSubscription,
                            paymentFreeTrial: data.paymentFreeTrial,
                            isFeatured: false,
                            isAReShare: true,
                            isPinned: false,
                            isPaidPromotion: false,
                            isReplyInComments: false,
                            isPinnedBy: firebase.auth().currentUser.displayName,
                            // remixedCount: data.remixedCount,
                            isRemixed: data.isRemixed,
                            paymentPlan: data.paymentPlan,
                            isRemixable: data.isRemixable,
                            enableGifRecap: data.enableGifRecap,
                            enableAIGifRecap: data.enableAIGifRecap,
                            screencap: data.screencap,
                            enableSpecialShare: data.enableSpecialShare,
                            AITrainerModel: EditorStore.aiTrainerModel,
                            resharedBy: firebase.auth().currentUser.displayName,
                          };

                          createReShare(RoomData);
                        });
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 131,
                      justifyContent: "space-between",
                      marginBottom: 4,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faShare}
                      color="#A962F1"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      style={{
                        fontFamily: "quicksand",
                        color: "#CAA6EF",
                        fontSize: 19,
                      }}
                    >
                      Reshare
                    </p>
                  </div>
                )}
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 7,
                  }}
                />
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "212.8px",
                }}
              >
                <div
                  onClick={() => {
                    document.getElementById("copy-embed-result").innerText =
                      "Copied!";

                    setTimeout(() => {
                      document.getElementById("copy-embed-result").innerText =
                        "Copy embed result";
                    }, 1000);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 234,
                    marginBottom: 4,
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faCode}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <p
                    id="copy-embed-result"
                    style={{
                      fontFamily: "quicksand",
                      color: "#949DF2",
                      fontSize: 19,
                      width: 174,
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                  >
                    Copy embed result
                  </p>
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 0,
                    marginBottom: 5,
                  }}
                />
              </div>

              <div
                style={{
                  display:
                    EditorStore.enableGifRecap === true ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: 213,
                  marginBottom: 5,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faRepeat}
                  color="#6371F6"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#949DF2",
                    fontSize: 19,
                  }}
                >
                  Copy GIF recap link
                </p>
              </div>

              <div
                style={{
                  display:
                    EditorStore.enableAIGifRecap === true ? "flex" : "none",
                  alignItems: "center",
                  width: 241,
                  justifyContent: "space-between",
                  marginBottom: 5,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faRobot}
                  color="#6371F6"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#949DF2",
                    fontSize: 19,
                  }}
                >
                  Copy AI GIF Recap link
                </p>
              </div>
              <div
                onClick={() => {
                  if (
                    firebase.auth().currentUser == null ||
                    firebase.auth().currentUser.displayName == null
                  ) {
                    AuthenticationStore.setShowModal(true);
                    return;
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: 177,
                  justifyContent: "space-between",
                  marginBottom: 4,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faShare}
                  color="#A962F1"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#CAA6EF",
                    fontSize: 19,
                  }}
                >
                  Repost/Reflow
                </p>
              </div>
              <div
                style={{ display: "flex", width: 138, alignItems: "center" }}
              >
                {shareWithOwnCredits == true ? (
                  <div
                    onClick={async () => {
                      if (!props.isAReShare) {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        shareTestReShare(flowIdCopy);
                      } else {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        shareTestReShare(flowIdCopy);
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 177,
                      justifyContent: "space-between",
                      marginBottom: 4,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faShare}
                      color="#A962F1"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      style={{
                        fontFamily: "quicksand",
                        color: "#CAA6EF",
                        fontSize: 19,
                      }}
                    >
                      Reshare
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={async () => {
                      if (!props.isAReShare) {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        let userId = firebase.auth().currentUser.uid;
                        let hashids = new Hashids(uuid(), 6);
                        let flowId = hashids.encode(1, 2, 3);
                        let firestore = firebase.firestore();

                        const snapshotFlows = await firestore
                          .collection("flows")
                          .where("flowId", "==", props.flowId)
                          .limit(1)
                          .get();

                        // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

                        snapshotFlows.forEach((doc) => {
                          let data = doc.data();
                          //console.log("[RoomPosts] like:", data);

                          let RoomData = {
                            canvasWidth: data.canvasWidth,
                            canvasHeight: data.canvasHeight,
                            date: new Date(),
                            description: data.description,
                            flowId: flowId, //generate new
                            thumbnail: data.thumbnail,
                            animation: data.animation,
                            title: data.title,
                            urlHTML: data.urlHTML,
                            urlCSS: data.urlCSS,
                            urlJS: data.urlJS,
                            urlPhoto: data.urlPhoto,
                            userId: userId,
                            username: data.username,
                            visibility: data.visibility,
                            modelId: data.modelId,
                            libraries: data.libraries,
                            cssLibraries: data.cssLibraries,
                            isRemixable: data.isRemixable,
                            AITextPopulated: data.AITextPopulated,
                            Contributors: data.Contributors,
                            widgets: data.widgets,
                            imageIndexes: data.imageIndexes,
                            textInputIndexes: data.textInputIndexes,
                            colorSelectIndexes: data.colorSelectIndexes,
                            sliderIndexSelect: data.sliderIndexes,
                            switchIndexSelect: data.switchIndexes,
                            selectIndexSelect: data.selectIndexes,
                            checkboxIndexSelect: data.checkboxIndexes,
                            calendarIndexSelect: data.calendarIndexes,
                            imageUploaderCount: data.imageUploaderCount,
                            editorLocked: data.editorLocked,
                            editorLockedPassword: data.editorLockedPassword,
                            editorLockedSelected: data.editorLockedSelected,
                            disableComments: data.disableComments,
                            isAR: data.isAR,
                            isVR: data.isVR,
                            isLive: data.isLive,
                            isAI: data.isAI,
                            hashtags: data.hashtags,
                            hasRemixableAI: data.hasRemixableAI,
                            followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
                            flowRemixer: data.flowRemixer,
                            flowIdOP: data.flowIdOP,
                            collaborators: data.collaborators,
                            isAllDevice: data.isAllDevice,
                            deviceMobile: data.deviceMobile,
                            deviceTablet: data.deviceTablet,
                            deviceDesktop: data.deviceDesktop,
                            paymentOneTimeFee: data.paymentOneTimeFee,
                            paymentSubscription: data.paymentSubscription,
                            paymentFreeTrial: data.paymentFreeTrial,
                            isFeatured: false,
                            isAReShare: true,
                            isPinned: false,
                            isPaidPromotion: false,
                            isReplyInComments: false,
                            isPinnedBy: firebase.auth().currentUser.displayName,
                            // remixedCount: data.remixedCount,
                            isRemixed: data.isRemixed,
                            paymentPlan: data.paymentPlan,
                            isRemixable: data.isRemixable,
                            enableGifRecap: data.enableGifRecap,
                            enableAIGifRecap: data.enableAIGifRecap,
                            screencap: data.screencap,
                            enableSpecialShare: data.enableSpecialShare,
                            AITrainerModel: EditorStore.aiTrainerModel,
                            resharedBy: firebase.auth().currentUser.displayName,
                          };

                          createRoom(RoomData);
                        });
                      } else {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }

                        let userId = firebase.auth().currentUser.uid;
                        let hashids = new Hashids(uuid(), 6);
                        let flowId = hashids.encode(1, 2, 3);
                        let firestore = firebase.firestore();

                        const snapshotFlows = await firestore
                          .collection("flows")
                          .where("flowId", "==", props.flowId)
                          .limit(1)
                          .get();

                        // console.log("[RoomPosts] # snapshotLikes:", snapshotLikes.docs.length);

                        snapshotFlows.forEach((doc) => {
                          let data = doc.data();
                          //console.log("[RoomPosts] like:", data);

                          let RoomData = {
                            canvasWidth: data.canvasWidth,
                            canvasHeight: data.canvasHeight,
                            date: new Date(),
                            description: data.description,
                            flowId: flowId, //generate new
                            thumbnail: data.thumbnail,
                            animation: data.animation,
                            title: data.title,
                            urlHTML: data.urlHTML,
                            urlCSS: data.urlCSS,
                            urlJS: data.urlJS,
                            urlPhoto: data.urlPhoto,
                            userId: userId,
                            username: data.username,
                            visibility: data.visibility,
                            modelId: data.modelId,
                            libraries: data.libraries,
                            cssLibraries: data.cssLibraries,
                            isRemixable: data.isRemixable,
                            AITextPopulated: data.AITextPopulated,
                            Contributors: data.Contributors,
                            widgets: data.widgets,
                            imageIndexes: data.imageIndexes,
                            textInputIndexes: data.textInputIndexes,
                            colorSelectIndexes: data.colorSelectIndexes,
                            sliderIndexSelect: data.sliderIndexes,
                            switchIndexSelect: data.switchIndexes,
                            selectIndexSelect: data.selectIndexes,
                            checkboxIndexSelect: data.checkboxIndexes,
                            calendarIndexSelect: data.calendarIndexes,
                            imageUploaderCount: data.imageUploaderCount,
                            editorLocked: data.editorLocked,
                            editorLockedPassword: data.editorLockedPassword,
                            editorLockedSelected: data.editorLockedSelected,
                            disableComments: data.disableComments,
                            isAR: data.isAR,
                            isVR: data.isVR,
                            isLive: data.isLive,
                            isAI: data.isAI,
                            hashtags: data.hashtags,
                            hasRemixableAI: data.hasRemixableAI,
                            followers: data.myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
                            flowRemixer: data.flowRemixer,
                            flowIdOP: data.flowIdOP,
                            collaborators: data.collaborators,
                            isAllDevice: data.isAllDevice,
                            deviceMobile: data.deviceMobile,
                            deviceTablet: data.deviceTablet,
                            deviceDesktop: data.deviceDesktop,
                            paymentOneTimeFee: data.paymentOneTimeFee,
                            paymentSubscription: data.paymentSubscription,
                            paymentFreeTrial: data.paymentFreeTrial,
                            isFeatured: false,
                            isAReShare: true,
                            isPinned: false,
                            isPaidPromotion: false,
                            isReplyInComments: false,
                            isPinnedBy: firebase.auth().currentUser.displayName,
                            // remixedCount: data.remixedCount,
                            isRemixed: data.isRemixed,
                            paymentPlan: data.paymentPlan,
                            isRemixable: data.isRemixable,
                            enableGifRecap: data.enableGifRecap,
                            enableAIGifRecap: data.enableAIGifRecap,
                            screencap: data.screencap,
                            enableSpecialShare: data.enableSpecialShare,
                            AITrainerModel: EditorStore.aiTrainerModel,
                            resharedBy: firebase.auth().currentUser.displayName,
                          };

                          createReShare(RoomData);
                        });
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 131,
                      justifyContent: "space-between",
                      marginBottom: 4,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      key={Math.random() + "icon" + new Date()}
                      icon={faShare}
                      color="#A962F1"
                      style={{ display: "flex", fontSize: 25 }}
                    />
                    <p
                      style={{
                        fontFamily: "quicksand",
                        color: "#CAA6EF",
                        fontSize: 19,
                      }}
                    >
                      Reshare
                    </p>
                  </div>
                )}
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 7,
                  }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={() => {
                    reshareWithResult(flowIdCopy);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 211,
                    justifyContent: "space-between",

                    cursor: "pointer",
                    marginBottom: 4,
                  }}
                >
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faShare}
                    color="#A962F1"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <p
                    style={{
                      fontFamily: "quicksand",
                      color: "#CAA6EF",
                      fontSize: 19,
                    }}
                  >
                    Reshare with result
                  </p>
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 7,
                  }}
                />
              </div>

              {/* <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    // setShowScreenshot(true);
                    let iframe = document.getElementById(
                      "undefined_output_frame"
                    );

                    html2canvas(
                      iframe.contentWindow.document.getElementsByTagName(
                        "html"
                      )[0],
                      {
                        useCORS: true,
                        allowTaint: false,
                      }
                    ).then((canvas) => {
                      console.log(canvas);
                      setShowScreenshot(true);

                      setTimeout(() => {
                        if (
                          document.getElementById("screenshot-image") !== null
                        ) {
                          document.getElementById("screenshot-image").src =
                            canvas.toDataURL();
                          document.getElementById(
                            "screenshot-image"
                          ).style.backgroundSize = "contain";
                          document.getElementById(
                            "screenshot-image"
                          ).style.backgroundRepeat = "no-repeat";
                          document.getElementById(
                            "screenshot-image"
                          ).style.height = "500px";
                          document.getElementById(
                            "screenshot-image"
                          ).style.width = "100%";
                          document.getElementById(
                            "screenshot-image"
                          ).style.maxWidth = "500px";

                          var link = document.getElementById("link");
                          link.setAttribute("download", "screenshot.png");
                          link.setAttribute(
                            "href",
                            canvas
                              .toDataURL("image/png")
                              .replace("image/png", "image/octet-stream")
                          );
                        }
                      }, 5000);
                    });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faLink}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <p
                    id={`copy-link-${props.flowId}`}
                    style={{
                      marginLeft: 10,
                      fontFamily: "quicksand",
                      color: "#949DF2",
                      fontSize: 19,
                      position: "relative",
                      bottom: 4,
                    }}
                  >
                    Screenshot
                  </p>
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowShareInfo(true);
                  }}
                  icon={faInfoCircle}
                  color="#FEFFFE"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                    zIndex: 10000,
                    marginLeft: 15,
                  }}
                />
              </div> */}
              <div
                style={{
                  display:
                    EditorStore.enableSpecialShare === true ? "flex" : "none",
                  alignItems: "center",
                  width: 162,
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  key={Math.random() + "icon" + new Date()}
                  icon={faShare}
                  color="#A962F1"
                  style={{ display: "flex", fontSize: 25 }}
                />
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#CAA6EF",
                    fontSize: 19,
                  }}
                >
                  Special Share
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "white",
                    fontSize: 24,
                    marginTop: 35,
                    marginBottom: 20,
                  }}
                >
                  Share via...
                </p>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    stlye={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 0,
                        marginBottom: 6,
                        marginLeft: 21,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        key={Math.random() + "icon" + new Date()}
                        icon={faEnvelope}
                        color="#6371F6"
                        style={{ display: "flex", fontSize: 32 }}
                      />
                      <p
                        style={{
                          fontSize: 19.5,
                          color: "#949DF2",
                          fontFamily: "quicksand",
                          marginTop: 3,
                        }}
                      >
                        Email
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 4,
                        marginBottom: 7,
                        position: "relative",
                        left: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        key={Math.random() + "icon" + new Date()}
                        icon={faPaperPlane}
                        color="rgb(79, 217, 117)"
                        style={{ display: "flex", fontSize: 31 }}
                      />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#75DB91",
                          fontFamily: "quicksand",
                          marginTop: 4,
                        }}
                      >
                        Message
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 9,
                        marginBottom: 7,
                        left: 10,
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <FaTwitter color={"#F06263"} size={32} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#EC9F9F",
                          fontFamily: "quicksand",
                          marginTop: 2,
                        }}
                      >
                        Twitter
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 7,
                        marginBottom: 7,
                        position: "relative",
                        left: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FaLinkedin size={32} color={"#A962F1"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#CAA6EF",
                          fontFamily: "quicksand",
                          marginTop: 2,
                        }}
                      >
                        LinkedIn
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 11,
                        marginBottom: 7,
                        position: "relative",
                        left: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FaSnapchat size={31} color={"#6371F6"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#949DF2",
                          fontFamily: "quicksand",
                        }}
                      >
                        Snapchat
                      </p>
                    </div>
                  </div>

                  {/* <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faRSS}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  /> */}

                  <div
                    stlye={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 5,
                        marginBottom: 7,

                        position: "relative",
                        left: 63,
                        bottom: 5,
                        cursor: "pointer",
                      }}
                    >
                      <FaRss size={32} color={"rgb(79, 217, 117)"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#75DB91",
                          fontFamily: "quicksand",
                          marginTop: 3,
                        }}
                      >
                        RSS
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 0,
                        marginBottom: 8,
                        position: "relative",
                        left: 64,
                        bottom: 2,
                        cursor: "pointer",
                      }}
                    >
                      <FaFacebook size={32} color={"#F06263"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#EC9F9F",
                          fontFamily: "quicksand",
                        }}
                      >
                        Facebook
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 4,
                        marginBottom: 7,
                        position: "relative",
                        left: 63,
                        cursor: "pointer",
                      }}
                    >
                      <FaReddit size={32} color={"#A962F1"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#CAA6EF",
                          fontFamily: "quicksand",
                          marginTop: 1,
                        }}
                      >
                        Reddit
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 8,
                        marginBottom: 7,
                        position: "relative",
                        left: 63,
                        cursor: "pointer",
                      }}
                    >
                      <FaTiktok size={32} color={"#6371F6"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#949DF2",
                          fontFamily: "quicksand",
                          marginTop: 2,
                        }}
                      >
                        TikTok
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 9,
                        marginBottom: 7,
                        position: "relative",
                        left: 63,
                        cursor: "pointer",
                      }}
                    >
                      <FaTumblr size={32} color={"rgb(79, 217, 117)"} />
                      <p
                        style={{
                          fontSize: 19,
                          color: "#75DB91",
                          fontFamily: "quicksand",
                          marginTop: 1,
                        }}
                      >
                        Tumblr
                      </p>
                    </div>
                  </div>

                  {/* <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faFacebook}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />{" "} */}
                  {/* <FontAwesomeIcon icon="fa-brands fa-rss" /> */}
                  {/* <FontAwesomeIcon icon={brands("facebook")} /> */}
                  {/* <i className="fa-brands fa-message"></i>
                  <FontAwesomeIcon icon="fa-brands fa-message" />
                  <FontAwesomeIcon icon="fa-brands fa-facebook" />
                  <FontAwesomeIcon icon="fa-brands fa-twitter" />
                  <FontAwesomeIcon icon="fa-brands fa-reddit" />
                  <FontAwesomeIcon icon="fa-brands fa-linkedIn" />
                  <FontAwesomeIcon icon="fa-brands fa-tiktok" />
                  <FontAwesomeIcon icon="fa-brands fa-snapchat" />
                  <FontAwesomeIcon icon="fa-brands fa-tumblr" /> */}
                  {/* <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faTwitter}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faReddit}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faLinkIn}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faTikTok}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faSnapChat}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  />
                  <FontAwesomeIcon
                    key={Math.random() + "icon" + new Date()}
                    icon={faTumblr}
                    color="#6371F6"
                    style={{ display: "flex", fontSize: 25 }}
                  /> */}
                </div>
              </div>
            </div>

            {/* <EmailShareButton
              url={`https://${Config.HOST_CLOUDFRONT}/room/${FlowStore.flowId}`}
              subject={`Flowroom: ${FlowStore.room.title}`}
              body={`Check out this flow:\n${FlowStore.room.title}\n\nhttps://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
            >
              <EmailIcon
                size={isMobile === true ? 50 : 70}
                round={false}
                borderRadius={10}
              />
            </EmailShareButton>
            <p
              style={{ color: "white", fontSize: isMobile === true ? 17 : 20 }}
            >
              Email
            </p> */}
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "8px 15px",
            }}
          >
            <FacebookShareButton
              url={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
            >
              <FacebookIcon
                size={isMobile === true ? 50 : 70}
                round={false}
                borderRadius={10}
              />
            </FacebookShareButton>
            <p
              style={{ color: "white", fontSize: isMobile === true ? 17 : 20 }}
            >
              Facebook
            </p>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "8px 15px",
            }}
          >
            <TwitterShareButton
              url={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
            >
              <TwitterIcon
                size={isMobile === true ? 50 : 70}
                round={false}
                borderRadius={10}
              />
            </TwitterShareButton>
            <p
              style={{ color: "white", fontSize: isMobile === true ? 17 : 20 }}
            >
              {" "}
              Twitter
            </p>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "8px 15px",
            }}
          >
            <LinkedinShareButton
              url={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
            >
              <LinkedinIcon
                size={isMobile === true ? 50 : 70}
                round={false}
                borderRadius={10}
              />
            </LinkedinShareButton>
            <p
              style={{ color: "white", fontSize: isMobile === true ? 17 : 20 }}
            >
              LinkedIn
            </p>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "8px 15px",
            }}
          >
            <RedditShareButton
              url={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
            >
              <RedditIcon
                size={isMobile === true ? 50 : 70}
                round={false}
                borderRadius={10}
              />
            </RedditShareButton>
            <p
              style={{ color: "white", fontSize: isMobile === true ? 17 : 20 }}
            >
              Reddit
            </p>
          </div> */}
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 270,
            margin: isMobile === true ? "10px 10px" : "40px 10px",
          }}
        > */}
        {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FontAwesomeIcon
              icon={faClipboard}
              color="#4FD975"
              size={"2x"}
              style={{
                display: "block",
                position: "relative",
              }}
              onClick={() => {
                document.getElementsByClassName(
                  "editor-menu"
                )[0].style.paddingLeft = "78px";
              }}
            />
            <div
              style={{
                color: "white",
                left: 0,
                fontSize: 20,
                marginBottom: 5,
                fontFamily: "quicksand",
              }}
            >
              {" "}
              Share Link
            </div>
            <CopyToClipboard
              text={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
              options={{ format: "text/plain" }}
            >
              <div
                onClick={() => {
                  setCopiedClipboardShare(true);
                }}
                style={{
                  color: "white",
                  fontSize: 20,
                  marginBottom: 5,
                  cursor: "pointer",
                  fontFamily: "quicksand",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: 12,
                    fontFamily: "quicksand",
                  }}
                >
                  {!copiedClipboardShare ? "COPY" : "COPIED!"}
                </p>
              </div>
            </CopyToClipboard>
          </div> */}

        {/* <input
            readOnly
            style={{
              height: "33px",
              paddingLeft: "10px",
              background: "#545454",
              color: "white",
              border: "none",
            }}
            value={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
          /> */}
        {/* <CssTextField
            readOnly
            style={{
              backgroundColor: "#3f3f3e",
              width: "100%",
            }}
            type="text"
            minrows={13}
            multiline={false}
            variant="outlined"
            placeholder=""
            onChange={(e) => {}}
            onFocus={() => {}}
            //   value={item.TextValue}
            onKeyUp={() => {}}
            value={`https://${Config.HOST_SHARE}/room/${FlowStore.flowId}`}
          /> */}
        {/* </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 270,
          }}
        >
          {/* <Credits /> */}
        </div>
      </div>
      {/* <ReactModal
        isOpen={AuthenticationStore.showModal}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
            opacity: 0.7,
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: 570,
            // maxHeight: WidgetStore.currentModalHeight,
          },
        }}
      >
        <div
          onClick={() => {
            AuthenticationStore.setShowModal(false);
          }}
          style={{
            position: "absolute",
            right: 0,
            margin: 10,
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#F06263"
            size={"4x"}
            style={{
              display: "block",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          />
        </div>
        <LoginM />
      </ReactModal> */}
      <ReactModal
        isOpen={showShareInfo}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "auto",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: "560px",
            paddingBottom: 20,
            // maxHeight: "370px",
          },
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          <img
            src="/images/block-icon.svg"
            style={{
              height: 120,
              width: 120,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            You can share tokens you purcahsed for Mini Apps that have AI or
            other features that use them, with other anyone you share a mini app
            with without the need for them to be logged in or have tokens. This
            makes it easier for someone to try what you're sharing.
          </p>
          <div
            onClick={() => {
              setShowShareInfo(false);
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 50,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Close
            </p>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showScreenshot}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "auto",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: "560px",
            paddingBottom: 20,
            // maxHeight: "370px",
          },
        }}
      >
        <div style={{ padding: 20 }}>
          <div
            id="screenshot-image-wrap"
            style={{
              position: "relative",
              maxWidth: 500,
              margin: "auto",
              marginTop: 20,
            }}
          >
            <p
              style={{ color: "white", fontFamily: "quicksand", fontSize: 15 }}
            >
              NOTE: This may not work as well at all times.
            </p>
            <p
              style={{
                color: "white",
                fontFamily: "quicksand",
                fontSize: 15,
                marginBottom: 20,
              }}
            >
              You may have to use your devices way of taking a screenshot
            </p>
            <img
              id="screenshot-image"
              style={{
                height: 500,
                maxWidth: 500,
                width: "100%",
                background: "transparent",
                margin: "auto",
                position: "absolute",
                zIndex: 1,
                pointerEvents: "none",
                marginBottom: 20,
              }}
            />
            <div
              style={{
                height: 500,
                maxWidth: 500,
                width: "100%",
                background: "#3F3F3E",
                margin: "auto",
              }}
            >
              {" "}
              <div
                id="loading-image"
                style={{
                  height: "100%",
                  width: "265px",
                  background: "#3F3F3E",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div className="loader">
                  <div className="outer"></div>
                  <div className="middle"></div>
                  <div className="inner"></div>
                </div>
                <p
                  style={{
                    color: "white",
                    fontFamily: "quicksand",
                    fontSize: 20,
                  }}
                >
                  Please Wait...
                </p>
              </div>
            </div>
          </div>
          <a
            id="link"
            style={{
              cursor: "pointer",
              fontFamily: "quicksand",
              color: "white",
              display: "flex",

              width: "170px",
              margin: "auto",
              marginTop: 47,
              marginBottom: 20,
              fontSize: 18,
            }}
          >
            <FontAwesomeIcon
              key={Math.random() + "icon" + new Date()}
              icon={faDownload}
              color="#FFF"
              style={{ display: "flex", fontSize: 20, marginRight: 12 }}
            />{" "}
            Download here
          </a>
          <div
            onClick={() => {
              setShowScreenshot(false);
            }}
            style={{
              backgroundColor: "#A962F1",
              height: 35,
              width: "calc(100% - 50px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              marginTop: "50px",
              cursor: "pointer",
              margin: "auto",
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Close
            </p>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showShareInfoCopyLinkResult}
        ariaHideApp={false}
        closeTimeoutMS={500}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            position: "relative",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "auto",
            borderRadius: "15px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% / 1.1)",
            maxWidth: "350px",
            paddingBottom: 20,
            // maxHeight: "370px",
          },
        }}
      >

        
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
            position:'relative'
          }}
        >
                          <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Info
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            setShowShareInfoCopyLinkResult(false);
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>

          <img
            src="/images/block-icon.svg"
            style={{
              height: 80,
              width: 80,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            You can reshare a flow you like with your followers or the public. 
          </p>
          <div
            onClick={() => {
              setShowShareInfoCopyLinkResult(false);
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 40,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 12,
              }}
            >
              Close
            </p>
          </div>
        </div>
      </ReactModal>
      {/* <Html /> */}
    </div>
  );
});

export default Share;
