import firebase from "firebase";
import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setTimeout(() => {
        setCurrentUser(user);
        setLoading(false);
      }, 1000); // Adjust the delay as needed (1000 milliseconds = 1 second)
    });
  
    // Cleanup function to unsubscribe
    return () => {
      unsubscribe?.();
    };
  }, []);
  

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
