import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class DHTML_Store {
  constructor() {
    makeAutoObservable(this);
  }

  html = "";
  css = "";
  js = "";
  html_ = "";
  css_ = "";
  js_ = "";

  setDHTML(html, css, js) {
    this.html = html;
    this.css = css;
    this.js = js;
  }
  setHTML(html) {
    this.html_ = html;
  }
  setCSS(css) {
    this.css_ = css;
  }
  setJS(js) {
    this.js_ = js;
  }
}

export const DHTML_StoreContext = createContext(new DHTML_Store());
