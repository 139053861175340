// src/Components/Home/SendNotification.js

import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const SendNotification = () => {
  const db = firebase.firestore();
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState('');
  const [error, setError] = useState('');
  
  const MAX_CHAR_LIMIT = 350; // Define the maximum character limit

  // Function to add a notification to Firestore
  const addInviteCodeNotification = async (sender, receiver, message, flowId) => {
    const newNotification = {
      sender,
      receiver,
      type: "flowroom",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: { message, flowId, directMessage: true },
      isSeen: false
    };
    await db.collection("notifications").add(newNotification);
  };

  const handleSubmitNotification = async () => {
    setNotificationStatus('');
    setError('');

    // Input Validation
    if (!username.trim()) {
      setError('Please provide a username.');
      return;
    }

    if (!message.trim()) {
      setError('Please provide a message.');
      return;
    }

    if (message.length > MAX_CHAR_LIMIT) {
      setError(`Message exceeds the maximum limit of ${MAX_CHAR_LIMIT} characters.`);
      return;
    }

    try {
      // Optional: Validate if the username exists in the users collection
      // const userSnapshot = await db.collection('users').where('username', '==', username.toLowerCase().trim()).get();
      // if (userSnapshot.empty) {
      //   setError('Username does not exist.');
      //   return;
      // }

      // Add the notification
      await addInviteCodeNotification("flowroom", username.toLowerCase().trim(), message.trim(), "noid");

      setNotificationStatus('Notification has been sent successfully.');
      setUsername('');
      setMessage('');
    } catch (err) {
      console.error("Error sending notification:", err);
      setError('Failed to send notification. Please try again.');
    }
  };

  // Handler to manage message input with character limit
  const handleMessageChange = (e) => {
    const inputMessage = e.target.value;
    if (inputMessage.length <= MAX_CHAR_LIMIT) {
      setMessage(inputMessage);
    }
    // Optionally, you can handle cases where pasted text exceeds the limit
    else {
      setMessage(inputMessage.slice(0, MAX_CHAR_LIMIT));
    }
  };

  return (
    <section style={styles.section}>
      <h2>Send Notification to User</h2>
      <div style={styles.inputGroup}>
        <input
          type="text"
          placeholder="Enter username..."
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
        />
        <textarea
          placeholder="Write your message here..."
          value={message}
          onChange={handleMessageChange}
          style={styles.textarea}
          maxLength={MAX_CHAR_LIMIT} // Enforce maximum character limit
        ></textarea>
        <div style={styles.charCountContainer}>
          <span style={styles.charCount}>
            {message.length} / {MAX_CHAR_LIMIT}
          </span>
        </div>
        <button onClick={handleSubmitNotification} style={styles.button}>
          Send Notification
        </button>
      </div>
      {notificationStatus && <p style={styles.successMessage}>{notificationStatus}</p>}
      {error && <p style={styles.errorMessage}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#e9ecef', // Slightly different background for distinction
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '100px',
    resize: 'vertical',
    fontSize: '16px',
  },
  charCountContainer: {
    alignSelf: 'flex-end',
    marginTop: '-10px',
    marginBottom: '10px',
  },
  charCount: {
    fontSize: '12px',
    color: '#666',
  },
  button: {
    alignSelf: 'flex-start',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28A745', // Green color for action button
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  },
  successMessage: {
    marginTop: '10px',
    color: 'green',
    fontWeight: 'bold',
  },
  errorMessage: {
    marginTop: '10px',
    color: 'red',
    fontWeight: 'bold',
  },
};

export default SendNotification;
