import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons"; // Import the spinner icon for the loading state
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { firebase } from "../firebase/firebase";
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";


const SignUpHeader = ()=>{
  const AuthenticationStore = useContext(AuthenticationStoreContext);

  return(<div style={{height:50, backgroundColor:'#1c1c1c', display:'flex', alignItems:'center', justifyContent:'center'}}><div style={{    backgroundImage: 'url(/images/logo/logo.svg)',
  backgroundSize: 'contain',
  width: '147px',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
  height: '32.1px',
  position: 'relative',
  left: '-1px'}}></div>
  
  {/* <div onClick={() => {
                          firebase
                            .auth()
                            .signOut()
                            .then(function () {
                              // Sign-out successful.
                              localStorage.clear();
                              localStorage.setItem(
                                "flowroom-user",
                                JSON.stringify({
                                  isLoggedIn: false,
                                })
                              );
                              AuthenticationStore.setIsLoggedIn(false);

                              window.location.replace("/");
                            })
                            .catch(function (error) {
                              // An error happened.
                            });
                        }} style={{position:'absolute', right:20, cursor:'pointer'}}><p style={{fontFamily:'quicksand', fontSize:14, color:'white'}}>Sign Out</p></div> */}
  
  </div>)
}

const EmailVerification = () => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [verificationError, setVerificationError] = useState(""); // State to hold potential error messages
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = sessionStorage.getItem('sessionId') || uuid();
    sessionStorage.setItem('sessionId', sessionId);
    const originalSessionId = localStorage.getItem('originalSessionId');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const actionCode = urlParams.get('oobCode');
    const mode = urlParams.get('mode');
    const email = urlParams.get('email');

    if (mode === 'verifyEmail' && actionCode) {
      firebase.auth().applyActionCode(actionCode).then(() => {
        console.log("Email verification successful");
        setIsLoading(false); // Set loading to false once the verification is successful
        setEmailVerified(true);

        firebase
        .firestore()
        .collection("emails")
        .doc(email)
        .set({ verified: true, email:email }, { merge: true })
        .then(() => {
          console.log('session ids' + sessionId + ' ' + originalSessionId)
          if (sessionId === originalSessionId) {
            navigate('/ProfileSetup')
          }
        })
      }).catch(error => {
        console.error("Error verifying email:", error);
        setIsLoading(false); // Set loading to false also in case of an error
        setVerificationError("Verification Link Invalid or Expired. Please request a new verification link or contact support."); // Set the error message
      });
    } else {
      setIsLoading(false);
      setVerificationError("No verification code found in the link. Please check the link and try again.");
    }
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        {/* <FontAwesomeIcon icon={faSpinner} spin size="2x" /> */}
        {/* <p>Loading...</p> */}
      </div>
    );
  }

  if (verificationError) {
    return (
      <div style={{width:'100%'}}>
            <div style={{width:'100%', height:50, backgroundColor:'#323232'}}><SignUpHeader/></div>

    <div className="error-screen" style={{height:'calc(100vh - 70px)', backgroundColor:'#323232', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:"column", width:'100%'}}>
     <img src="../blocked-icon.svg" style={{height:75, marginBottom:20}} />

  <p style={{    fontSize: 18,
    fontFamily: 'Quicksand',
    marginLeft: 20,
    marginRight: 20,
    textAlign:'center', color:'#F06263'}}>{verificationError}</p>
      </div>
      </div>
    );
  }

  return (<div style={{width:'100%'}}>
    <div style={{width:'100%', height:50, backgroundColor:'#323232'}}><SignUpHeader/></div>

    <div className="" style={{ flex: "1", display: "flex", background:'#323232', width:'100%' }}>

      <div
        style={{
          height: "calc(100vh - 70px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 50,
        }}
      >
        {emailVerified && (
          <>
            <div
              style={{
                height: 250,
                width: "100%",
                maxHeight: 300,
                maxWidth: 400,
                background: "#1C1C1C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: 15,
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                color="#FEFFFE"
                style={{ display: "flex", marginBottom: 10, fontSize: 80 }}
              />
              <p
                style={{
                  color: "#4FD975",
                  fontFamily: "quicksand",
                  fontSize: 20,
                }}
              >
                Email Verified!
              </p>
            </div>

            <p style={{ fontSize: 18, fontFamily: 'quicksand', width: '100%', maxWidth: 500, marginTop: 55, color: '#F06263', textAlign:'center'}}>
              Your email has been verified. Please close this page and return to the original tab/window to continue.
            </p>

          </>
        )}
      </div>
    </div>
    </div>
  );
}

export default React.memo(EmailVerification);
