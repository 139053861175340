import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";

const FeatureRequestModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={WidgetStore.showFeatureRequestModal}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "450px",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          width: "100%",
          justifyContent: "space-between",
          padding: "20px 25px",
        }}
      >
        <div>
          <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20 }}>
            Feature Request
          </p>
        </div>
        <div
          onClick={() => {
            WidgetStore.setShowFeatureRequestModal(false);
          }}
        >
          {/* <svg height="30" fill="white" viewBox="0 0 30 30" width="30">
          <g transform="translate(-270 -800)">
            <path d="m285 828c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0-25c6.617 0 12 5.383 12 12s-5.383 12-12 12-12-5.383-12-12 5.383-12 12-12z" />
            <path d="m280.404 820.303 4.596-4.596 4.596 4.596.708-.707-4.597-4.596 4.597-4.596-.708-.707-4.596 4.596-4.596-4.596-.708.707 4.597 4.596-4.597 4.596z" />
          </g>
        </svg> */}
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#F06263"
            style={{
              display: "block",

              cursor: "pointer",
              fontSize: 35,
            }}
          />
        </div>
      </div>
      {confirm === false ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
            paddingBottom: 30,
          }}
        >
          <input
            id={"promotion-username"}
            className="formInputOutline"
            type="text"
            style={{
              height: 33,
              width: "100%",
              marginBottom: 5,
              borderRadius: 15,
              paddingLeft: 10,
              backgroundColor: "#2D2A2B",
              border: "none",
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder={"Report title"}
          />
          <textarea
            className="formInputOutline"
            style={{
              height: 170,
              width: "100%",
              borderRadius: 15,
              backgroundColor: "#2D2A2B",
              marginTop: 15,
              marginBottom: 10,
              resize: "none",
              border: "none",
              padding: 10,
              fontSize: 14,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder="Please give as much detail as possible, such as how to reproduce your bug, what or device"
          ></textarea>
          <input
            id={"promotion-username"}
            className="formInputOutline"
            type="text"
            style={{
              height: 33,
              width: "100%",
              marginBottom: 5,
              borderRadius: 15,
              paddingLeft: 10,
              backgroundColor: "#2D2A2B",
              border: "none",
              marginTop: 15,
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder={"Email (Optional)"}
          />
          <div
            onClick={() => {
              setConfirm(true);
            }}
            style={{
              backgroundColor: "#4FD975",
              height: 35,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              marginTop: 15,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Submit
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
          }}
        >
          <p style={{ color: "white", fontFamily: "quicksand", fontSize: 30 }}>
            Thank you!
          </p>
          <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20 }}>
            Your report has been submitted.
          </p>
        </div>
      )}

      <div></div>
    </ReactModal>
  );
});

export default React.memo(FeatureRequestModal);
