let Config = {
  HOST_S3_DEV: "test.flowroom.com",
  HOST_S3_PROD: "prod.flowroom.com",

  HOST_CLOUDFRONT_DEV: "demo.flowroom.com",
  HOST_CLOUDFRONT_PROD: "flowroom.com",

  HOST_SHARE_DEV: "web.flowroom.com",
  HOST_SHARE_PROD: "share.flowroom.com",

  TIMEOUT_FEED_SCROOL: 300,
  LIMIT_FEED_FLOWS: 12,
  URL_PROFILE: "/images/white_profile_icon.svg",
  URL_PHOTO_DEFAULT: "",
  URL_THUMBNAIL_PROCESSING: "",

  INTERVAL_THUMBNAIL: 2000,
};

const isConfigDev = true;

Config.HOST_S3 = isConfigDev ? Config.HOST_S3_DEV : Config.HOST_S3_PROD;
Config.HOST_CLOUDFRONT = isConfigDev
  ? Config.HOST_CLOUDFRONT_DEV
  : Config.HOST_CLOUDFRONT_PROD;
Config.HOST_SHARE = isConfigDev
  ? Config.HOST_SHARE_DEV
  : Config.HOST_SHARE_PROD;

export { Config };
