import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore";
import Exclamation from "../Home/Icons/Exclamation.js";

const DeleteRemixWidgetModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [showDeleteWidgetMessage, setShowDeleteWidgetMessage] = useState(false);
  useEffect(() => {
    setShowDeleteWidgetMessage(props.showDeleteRemixWidgetModal);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.showDeleteRemixWidgetModal]);
  return (
    <ReactModal
      isOpen={showDeleteWidgetMessage}
      ariaHideApp={false}
      closeTimeoutMS={200}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          padding: 20,
          width: "100%",
          maxWidth: 500,
        },
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <p
              style={{
                color: "white",
                fontFamily: "quicksand",
                fontSize: 20,
                marginBottom: 20,
                marginRight: 20,
              }}
            >
              Are you sure you want to delete this widget?
            </p>
          </div>
          <div
            onClick={() => {
              props.deleteWidget();
              props.closeWidget();
            }}
          >
            {/* <svg height="30" fill="white" viewBox="0 0 30 30" width="30">
          <g transform="translate(-270 -800)">
            <path d="m285 828c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0-25c6.617 0 12 5.383 12 12s-5.383 12-12 12-12-5.383-12-12 5.383-12 12-12z" />
            <path d="m280.404 820.303 4.596-4.596 4.596 4.596.708-.707-4.597-4.596 4.597-4.596-.708-.707-4.596 4.596-4.596-4.596-.708.707 4.597 4.596-4.597 4.596z" />
          </g>
        </svg> */}
            <FontAwesomeIcon
              icon={faTimesCircle}
              color="#F06263"
              style={{
                display: "block",

                cursor: "pointer",
                fontSize: 35,
              }}
            />
          </div>
        </div>

        <Exclamation />
        <p
          style={{
            color: "white",
            fontFamily: "quicksand",
            width: "100%",
            maxWidth: 429,
            margin: "auto",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          If you are not the original creator of this flow or an advanced user,
          deleting this widget may cause the flow to not work properly.
        </p>
        <div
          onClick={() => {
            props.deleteWidget();
            props.closeWidget();
            // setShowDeleteWidgetMessage(false);
          }}
          style={{
            height: 30,
            width: "100%",
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: 20,
            marginBottom: 10,
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Yes</p>
        </div>
        <div
          onClick={() => {
            // setShowDeleteWidgetMessage(false);
            props.deleteWidget();

            props.closeWidget();
          }}
          style={{
            height: 30,
            width: "100%",
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Cancel</p>
        </div>
      </div>
      {/*start*/}

       <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          <img
            src="../images/block-icon.svg"
            style={{
              height: 120,
              width: 120,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            User blocked
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            This user is now blocked and will not see any posts you make in any
            feed and also will not be able to see your profile.
          </p>
          <div
            onClick={() => {
              props.closeModal();
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 50,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Ok
            </p>
          </div>
        </div>
      
    </ReactModal>
  );
});

export default DeleteRemixWidgetModal;
