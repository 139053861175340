import { faEnvelope, faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import "cropperjs/dist/cropper.css";
import { useFormik } from "formik";
import Hashids from "hashids";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cropper from "react-cropper";
import PasswordChecklist from "react-password-checklist";
import { components } from "react-select";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";
import "../styles/firebaseui-styling.global.css"; // Import globally. Not with CSS modules.
import { Config } from "./config/Config";
import { AuthContext } from "./AuthContext";
import ReCAPTCHA from "react-google-recaptcha";

let profileLoaded = false;
let email;

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});
const s3 = new S3();

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 64,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
const CaretDownIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faTriangle}
      color="#A962F1"
      style={{
        display: "block",
        position: "absolute",
        right: "10px",
        top: "10px",
        transform: "rotate(180deg)",
        fontSize: "15px",
      }}
    />
  );
};
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const customStyles = {
  indicatorsContainer: (provided, state) => ({
    backgroundColor: "#2d2a2b",
    borderRadius: 4,
    color: "white",
  }),

  control: (_, { selectProps: { width, backgroundColor, height } }) => ({
    height: 35,
    backgroundColor: "#2d2a2b",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 30,
    padding: "0px",
    fontFamily: "quicksand",
    color: "white",
    minHeight: 3,
    //width: "calc(100% - 50px)",

    // overflow: "hidden",
  }),

  dropdownIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      backgroundColor: "#2d2a2b",
      // height: 30,
      fontSize: 20,

      borderRadius: 30,
      padding: "0px",
      color: "white",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      fontFamily: "quicksand",
      padding: "0px 10px",
      // width: "calc(100% - 50px)",
    };
  },
  menu: (provided, state) => {
    return {
      // ...provided,
      fontFamily: "quicksand",
      backgroundColor: "#2d2a2b",
      overflow: "hidden",
      fontSize: 20,
      // position: "absolute",
      top: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      // width: "calc(100% - 50px)",
      // color: state.selectProps.menuColor,
    };
  },
  placeholder: (provided, state) => {
    return {
      fontFamily: "quicksand",
      color: "white",
      alignItems: "auto",
      justifyContent: "space-between",
      display: "flex",
      fontSize: 20,
      // width: "calc(100% - 50px)",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      // marginTop: 5,
      fontSize: "10px",
      height: 90,
      // width: "calc(100% - 50px)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      // height: 30,
      fontSize: 20,

      padding: "0px",
      color: "white",
      fontFamily: "quicksand",
      // width: "calc(100% - 50px)",
    };
  },
  option: (base) => ({
    ...base,
    height: "100%",
    width: "calc(100vw - 50px)",
  }),
};
const bioStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 169,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));

const signInStyles = makeStyles((theme) => ({
  root: {
    border: "0px solid #545454",
    overflow: "hidden",
    borderRadius: 15,
    backgroundColor: "#2D2A2B",
    color: "white",
    height: 37,
    width: "100%",
    paddingLeft: 10,
  },
  focused: {},
}));

function NameTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function SignInText(props) {
  const classes = signInStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function UserTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function BioTextField(props) {
  const classes = bioStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}
let fullnameg;

const SigninM = ({ closeSignUp, emailAddress }) => {
  const classes = useStyles();

  const [file, setFiles] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");
  const [cropResult, setCropResults] = useState("");
  const [avatar, setAvatar] = useState("");
  const [isUploading, setIsUploading] = useState("");
  const [progress, setProgress] = useState(0);
  const [avatarURL, setAvatarURL] = useState("");
  const [postedPicURL, setPostedPicURL] = useState("");
  const [fullname, setFullname] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [nameExists, setNameExists] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState("");
  const [showFullName, setShowFullName] = useState(true);
  const [fullNameHidden, setFullNameHidden] = useState("");
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [login, setLogin] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [modalHeight, setModalHeight] = useState(false);
  const WidgetStore = useContext(WidgetStoreContext);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [loginValid, setLoginValid] = useState(false);
  const [firebaseComponentShow, setFirebaseComponentShare] = useState(false);
  const EditorStore = useContext(EditorStoreContext);
  const [sendVerifyButton, setSendVerifyButton] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isVerified, setIsVerified] = useState(false);
  const [emailDefault, setEmailDefault] = useState("");

  useEffect(()=>{
    setEmailDefault(emailAddress);
  },[])

  const handleRecaptcha = (value) => {
    // If value is null, recaptcha expired
    if(value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const handleSubmit = (e) => {
    if(isVerified) {
    email = document
    .getElementById("email")
    .value.toLowerCase()
    .trim();
  let password = document
    .getElementById("password")
    .value.trim();

  localStorage.setItem("emailUsing", email.trim());

  if (loginValid === true) {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        email,
        password
      )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // firebase
        //   .auth()
        //   .currentUser.sendEmailVerification();
      })
      .catch((error) => {
        // document.querySelector(
        //   ".error.reg_error"
        // ).innerHTML = error.message
        if (
          error.code === "auth/email-already-in-use"
        ) {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then(function (providers) {
              console.log("providers", providers);

              if (providers[0] === "google.com") {
                setErrorMessage(
                  "You already have an account. Please sign into your account with Google."
                );
              } else if (
                providers[0] === "github.com"
              ) {
                setErrorMessage(
                  "You already have an account. Please sign into your account with Github."
                );
              } else if (
                providers[0] === "password"
              ) {
                setErrorMessage(
                  "You already have an account. Please sign in with your email and password"
                );
              }
              // The returned 'providers' is a list of the available providers
              // linked to the email address. Please refer to the guide for a more
              // complete explanation on how to recover from this error.
            });
        } else if (
          error.code === "auth/invalid-email"
        ) {
          setErrorMessage(
            "The email you entered was badly formatted"
          );
        }
      });
  }

} else {
  alert("Please verify that you are a human!");

}




  };


  const cropper = useRef("cropper");
  // Configure FirebaseUI.
  const localData = JSON.parse(localStorage.getItem("ProfilePhoto"));
  //alert(localData.profilePhoto);
  let fullname_ = fullnameg;

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,

      // Avoid redirects after sign-in.
    },

    fullLabel: "",
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      bio: "",
      fullname: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username has to be 3 characters or longer!")
        .max(15, "Username can not be longer than 15 characters!")
        .matches(
          /^[a-zA-Z0-9]{3,15}$/g,
          "Username can not have any spaces or special characters!"
        )
        .required("Username is required!"),
      fullname: Yup.string().required("Full name is required!"),
    }),
    onSubmit: (values) => {
      // console.log("[UserModal] onSubmit", JSON.stringify(values, null, 2));
      let username = formik.values.username;
      username = username.toLowerCase();
      username = username.replace(/\s/g, "");
      let fullname =
        showFullName === false ? fullNameHidden : formik.values.fullname;
      let bio = formik.values.bio;
      let userData = {};
      if (username !== "") {
        userData.username = username.trim();
      }
      if (fullname !== "") {
        userData.fullname = fullname;
      }
      if (bio !== "") {
        userData.bio = bio;
      }
      if (urlPhoto && urlPhoto !== "") {
        userData.urlPhoto = urlPhoto;
      }
      if (username === "") {
        document.getElementById("username").focus();
      } else {
        firebase
          .firestore()
          .collection("users")
          .where("username", "==", username)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              let data = snapshot.docs[0].data();
              if (
                data.username &&
                data.username == username &&
                data.userId !== currentUser.uid
              ) {
                setNameExists(true);
                return;
              }
            }
            firebase
              .firestore()
              .collection("users")
              .doc(currentUser.uid)
              .set(userData, { merge: true })
              .then(() => {
                firebase
                  .auth()
                  .currentUser.updateProfile({
                    displayName: username.trim(),
                    photoURL: urlPhoto,
                    fullname: fullname,
                    isDevAccount: false,
                  })
                  .then(() => {
                    localStorage.setItem(
                      "user",
                      JSON.stringify({
                        displayName: username.trim(),
                        photoURL: urlPhoto,
                        fullname: fullname,
                        isDevAccount: false,
                      })
                    );
                    AuthenticationStore.setShowModal(false);
                    // AuthenticationStore.setIsLoggedIn((prev) => !prev);

                    if (username !== "") {
                      AuthenticationStore.setDisplayName(username);
                    }

                    if (fullname !== "") {
                      AuthenticationStore.setFullname(fullname);
                    }

                    if (bio !== "") {
                      AuthenticationStore.setBio(bio);
                    }

                    if (urlPhoto !== "") {
                      AuthenticationStore.setPhotoURL(urlPhoto);
                    }
                    AuthenticationStore.setShowUserModal(false);
                    AuthenticationStore.setIsProfileLoaded(false);
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                //console.log(error);
              });
          });
      }
    },
  });

  const _crop = () => {
    // image in dataUrl
    let imageURL = cropper.getCroppedCanvas().toDataURL();
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const handleUploadSuccess = (filename) => {
    let usernamef = document.getElementById("username").value;
    setAvatar(filename);
    setProgress(100);
    setIsUploading(false);
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        setPostedPicURL(url);
        // props.userStore({ username: usernamef, pic: url });
        AuthenticationStore.setPhotoURL(url);
      });
  };

  const removePhoto = () => {
    setPostedPicURL("");
    // this.props.userStore({ pic: '' });
  };

  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const uploadFile = (file) => {
    let hashids = new Hashids(uuid(), 6);
    let fileName = hashids.encode(1, 2, 3) + ".png";

    let params = {
      Bucket: Config.HOST_S3,
      Key: "uploads/" + fileName,
      ContentType: file.type,
      Body: file,
    };

    s3.putObject(params, function (err, data) {
      if (err) {
        //console.log("error :", err);
      } else {
        //console.log("data :", data);
        setUrlPhoto(`https://${Config.HOST_CLOUDFRONT}/uploads/${fileName}`);
      }
    });
  };

  const showPhotoPreview = (file) => {
    setUrlPhoto(window.URL.createObjectURL(file));
  };
  const keyDownHandler = useCallback((KeyboardEvent) => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 9 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);
  useEffect(() => {
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
    // let actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be in the authorized domains list in the Firebase Console.
    //   url: "https://www.flowroom.com/finishSignUp",
    //   // This must be true.
    // };
    // firebase
    //   .auth()
    //   .sendSignInLinkToEmail("shadowforcerap@gmail.com", actionCodeSettings)
    //   .then(() => {
    //     alert("link sent for sign in");
    //     // The link was successfully sent. Inform the user.
    //     // Save the email locally so you don't need to ask the user for it again
    //     // if they open the link on the same device.
    //     window.localStorage.setItem(
    //       "emailForSignIn",
    //       "shadowforcerap@gmail.com"
    //     );
    //     // ...
    //   })
    //   .catch((error) => {
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // ...
    //   });
  }, []);

  // useEffect(() => {
  //   let stopCheck = setInterval(() => {
  //     let signInBtns = document.getElementsByClassName("firebaseui-idp-button");

  //     for (let i = 0; i < signInBtns.length; i++) {
  //       signInBtns[i].addEventListener("click", function () {
  //         document.getElementById("signInLbl").style.display = "none";
  //         //clearInterval(stopCheck);
  //       });
  //     }
  //   }, 500);

  //   let stopCheckCancel = setInterval(() => {
  //     let signInBtns = document.getElementsByClassName(
  //       "firebaseui-id-secondary-link"
  //     );

  //     for (let i = 0; i < signInBtns.length; i++) {
  //       signInBtns[i].addEventListener("click", function () {
  //         document.getElementById("signInLbl").style.display = "flex";
  //         //clearInterval(stopCheckCancel);
  //       });
  //     }
  //   }, 500);
  // }, []);

  useEffect(() => {
    setLogin(AuthenticationStore.signInUpBtnState);
    // window.onkeypress = function (e) {
    //   if (e.which == 13) {
    //     e.preventDefault();
    //     var inputs = document.getElementsByClassName("input");
    //     for (var i = 0; i < inputs.length; i++) {
    //       if (
    //         document.activeElement.id == inputs[i].id &&
    //         i + 1 < inputs.length
    //       ) {
    //         inputs[i + 1].focus();
    //         break;
    //       }
    //     }
    //   }
    // };
    // document.addEventListener("keydown", function (event) {
    //   if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
    //     var form = event.target.form;
    //     var index = Array.prototype.indexOf.call(form, event.target);
    //     form.elements[index + 1].focus();
    //     event.preventDefault();
    //   }
    // });
    firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        let displayName = user.displayName;
        AuthenticationStore.setIsLoggedIn(true);

        setIsLoggedIn(true);

        const isLoggedInWithEmail =
          user.providerData[0].providerId === "password";

        if (!isLoggedInWithEmail) {
          if (displayName !== null) {
            if (!displayName.match(" ") === true) {
              /*hack needs fixing*/

              AuthenticationStore.setShowModal(false);
            } else {
              setIsLoggedIn(true);
            }
          }
        } else {
          if (user.displayName === null) {
            setIsLoggedIn(true);

            // console.log("[LoginM] Login user", user);
          }
        }

        setUrlPhoto(currentUser !== null && currentUser.photoURL);

        localStorage.setItem(
          "ProfilePhoto",
          JSON.stringify({
            profilePhoto: user.photoURL,
          })
        );

        // setShowFullName(isLoggedInWithEmail ? true : false);
        // setFullNameHidden(user.providerData[0].displayName);
        // localStorage.setItem(
        //   "fullname",
        //   JSON.stringify({ fullname: user.providerData[0].displayName })
        // );
        // fullnameg = user.providerData[0].displayName;
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let data = doc.data();
              let username = data.username;
              if (username != null && username != "") {
                setUsername(username);
                AuthenticationStore.setShowModal(false);
              }
              return;
            }

            firebase
              .firestore()
              .collection("users")
              .doc(user.uid)
              .set({ userId: user.uid });

            firebase.auth().currentUser.updateProfile({
              displayName: "",
            });

            //setIsLoggedIn(true);
            setUsername("");
          });
        if (user.emailVerified !== true) {
          setEmailVerification(true);
          // firebase.auth().currentUser.sendEmailVerification();
        } else {
          setEmailVerification(false);
        }
      }
    });
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [AuthenticationStore]);

  // if (emailVerification === true) {
  //   return (
  //     <div
  //       style={{
  //         height: "100%",
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         flexDirection: "column",
  //         padding: 50,
  //         background:'#1C1C1C'
  //       }}
  //     >
  //       <FontAwesomeIcon
  //         icon={faEnvelope}
  //         color="#FEFFFE"
  //         size={"7x"}
  //         style={{ display: "flex", cursor: "pointer", marginBottom: 10 }}
  //       />
  //       <p style={{ color: "#A962F1", fontSize: 20 }}>
  //         Email verification needed!
  //       </p>

  //       <p
  //         style={{
  //           color: "white",
  //           fontFamily: "quicksand",
  //           fontSize: 15,
  //           maxWidth: 430,
  //           textAlign: "center",
  //           margin: 20,
  //         }}
  //       >
  //         In order to continue using Flowroom, you must verify your email
  //         address via a verification link we'll send you.
  //       </p>
  //       <div
  //         onClick={() => {
  //           let email = localStorage.getItem("emailUsing").trim();

  //           firebase
  //             .firestore()
  //             .collection("emails")
  //             .doc(email)
  //             .set({ verified: false }, { merge: true })
  //             .then(() => {
  //               // firebase
  //               //   .firestore()
  //               //   .collection("emails")
  //               //   .doc("email")
  //               //   .onSnapshot((doc) => {
  //               //     console.log("Current data: ", doc.data());
  //               //   });
  //               setSendVerifyButton(true);
  //               firebase
  //                 .firestore()
  //                 .collection("emails")
  //                 .doc(email)
  //                 .onSnapshot((doc) => {
  //                   if (doc.data().verified === true) {
  //                     setEmailVerification(false);
  //                   }
  //                 });
  //             });

  //           firebase.auth().currentUser.sendEmailVerification();
  //         }}
  //         style={{
  //           height: 28,
  //           width: "100%",
  //           maxWidth: 300,
  //           backgroundColor: sendVerifyButton === false ? "#A962F1" : "#6371F6",
  //           borderRadius: 20,
  //           fontFamily: "quicksand",
  //           color: "white",
  //           textAlign: "center",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           cursor: "pointer",
  //         }}
  //       >
  //         <p
  //           style={{ color: "#222222", fontFamily: "quicksand", fontSize: 15 }}
  //         >
  //           {sendVerifyButton === false
  //             ? "Verify email address"
  //             : "Resend verification email"}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (!isLoggedIn) {
    return (
      <div style={{ flex: "1", display: "flex", height: "100%" }}>
        <div style={{ flex: "1", display: "flex" }}>
          <div
            className="main-section-wrap-signup-screen"
            style={{
              width: "100%",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: 20,
                height: 37,
                borderBottom:'4px solid #000'
              }}
            >
                   <img src="../images/sign-in-icon.svg" style={{    fontSize: 10,
    height: 20,
    margin: 2,
    marginLeft: 15,
    marginRight: 10
              }}/>
              <p
                style={{
                  color: "white",
                  fontSize: 17,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                Sign Up with Flowroom
              </p>
              {/* <p
                className="signIn"
                style={{
                  color: "white",
                  fontSize: 24,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "to" : "with"} Flowroom
              </p> */}
            </div>
            <div className="main-section-signup-box" style={{borderTop:'1px solid #3E3E3E'}}>
              <div className="login-screen-wrap">
                <div className="main-section-wrap-signup-screen">
                  <div></div>

                  <div
                    className="main-section-login-box"
                    style={{
                      backgroundColor: "#202020",
                      border: "none",
                    }}
                  >
                    <div
                      className="login-section-fields"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          position: "relative",
                          maxWidth: "100%",
                          width: "100%",
                          margin: "auto",
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% - 55px)",
                            paddingTop: 25,

                            margin: "auto",
                          }}
                        >
                          <input
                            id="email"
                            type="text"
                            className="formInputOutline"
                            style={{
                              border: "0px solid #545454",
                              overflow: "hidden",
                              borderRadius: 10,
                              backgroundColor: "#323232",
                              color: "white",
                              height: 37,
                              width: "100%",
                              paddingLeft: 10,
                              fontFamily: "quicksand",
                              textTransform: "lowercase"
                            }}
                            onChange={(e)=>{
                              setEmailDefault(e.target.value);
                            }}
                            value={emailDefault}
                            placeHolder="Email"
                          />
                          <p
                            style={{
                              color: "#F06263",
                              fontSize: 14,
                              fontFamily: "quicksand",
                              marginTop: 5,
                            }}
                          >
                            {errorMessage}
                          </p>
                          <div
                            style={{
                              maxWidth: 800,
                              width: "100%",
                              display: "flex",
                              marginTop: 17,
                            }}
                          >
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              id="password"
                              type="password"
                              className="formInputOutline"
                              style={{
                                border: "0px solid #545454",
                                overflow: "hidden",
                                borderRadius: 10,
                                backgroundColor: "#323232",
                                color: "white",
                                height: 37,
                                width: "100%",
                                paddingLeft: 10,
                                marginRight: 23,
                              }}
                              placeHolder="Password"
                            />

                            <input
                              onChange={(e) => setPasswordAgain(e.target.value)}
                              type="password"
                              className="formInputOutline"
                              style={{
                                border: "0px solid #545454",
                                overflow: "hidden",
                                borderRadius: 10,
                                backgroundColor: "#323232",
                                color: "white",
                                height: 37,
                                width: "100%",
                                paddingLeft: 10,
                              }}
                              placeHolder="Re-enter Password"
                            />
                          </div>

                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={5}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => {
                              setLoginValid(isValid);
                            }}
                            style={{
                              color: "#FEFFFE",
                              fontFamily: "quicksand",
                              marginTop: 15,
                              marginBottom:20
                            }}
                          />

                        <ReCAPTCHA
                          sitekey="6Lf2Gk8UAAAAAM0PYHAgWAi_zW3EKWGD0ZkTGjDC"
                          onChange={handleRecaptcha}
                          theme="dark"
                          size="compact"
                        />
                      
                          <div
                            onClick={() => {
                              handleSubmit();
                            }}
                            style={{
                              background: "#4FD975",
                              height: 35,
                              width: "100%",
                              marginTop: 20,
                              marginBottom: 20,
                              borderRadius: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents: "all",
                              cursor: "pointer",
                            }}
                          >
                            <p
                              style={{
                                color: "#222222",
                                fontSize: 15,
                                fontFamily: "quicksand",
                                pointerEvents: "none",
                              }}
                            >
                              Continue with Email
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              closeSignUp();
                            }}
                            style={{
                              background: "#4FD975",
                              height: 35,
                              width: "100%",
                              marginTop: 20,
                              marginBottom: 20,
                              borderRadius: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents: "all",
                              cursor: "pointer",
                            }}
                          >
                            <p
                              style={{
                                color: "#222222",
                                fontSize: 15,
                                fontFamily: "quicksand",
                                pointerEvents: "none",
                              }}
                            >
                              Cancel
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          {/* <FirebaseAuth
                            uiCallback={(ui) => ui.disableAutoSignIn()}
                            uiConfig={uiConfig}
                            firebaseAuth={firebase.auth()}
                          /> */}
                          <div
                            style={{
                              paddingLeft: 30,
                              paddingRight: 30,
                              marginBottom: 40,
                              width: "100%",
                            }}
                          >
          
                          </div>
                       
                        </div>

                        {/* <div
                          style={{
                            position: "absolute",
                            color: "white",
                            left: 80,
                            top: 57,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            WITH FACEBOOK
                          </p>
                        </div> */}
                        {/* <div
                          style={{
                            position: "absolute",
                            color: "#757575",
                            left: 80,
                            top: 127,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p style={{ fontWeight: "bold" }}>WITH GOOGLE</p>
                        </div> */}
                        {/* <div
                          style={{
                            position: "absolute",
                            color: "white",
                            left: 80,
                            top: 197,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              cursor: "pointer",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p style={{ fontWeight: "bold" }}>WITH EMAIL</p>
                        </div> */}
                        {/* <div
                          id="signInLbl"
                          style={{
                            display: "none",
                            margin: "auto",
                            bottom: 15,
                            position: "relative",
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginRight: 5,
                              color: "white",
                              fontFamily: "quicksand",
                            }}
                          >
                            {login
                              ? "Already have an account ?"
                              : "Don't have an account ?"}
                          </p>
                          <p
                            onClick={() => {
                              if (login) {
                                setLogin(false);
                              } else {
                                setLogin(true);
                              }
                            }}
                            style={{ color: "white", cursor: "pointer" }}
                          >
                            {login ? "Sign In" : "Sign Up"}
                          </p>
                        </div> */}
                      </div>
                      {/* 
                      <div
                        style={{
                          display: !login ? "none" : "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            maxWidth: 230,
                            fontFamily: "quicksand",
                          }}
                        >
                          By signing up, you agree to our{" "}
                          <a
                            href="https://about.flowroom.com/terms/"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            Terms
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://about.flowroom.com/privacy-policy/"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(SigninM);
