import React, { useState } from "react";
import { Route } from "react-router-dom";
import { firebase } from "../../src/Components/firebase/firebase.js";

let db = firebase.firestore();

export const FullRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [doesExist, setDoesExist] = useState(null);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const { match } = routeProps;
        const id = match.params.id;

        if (doesExist === null) {
          // Only run this once when doesExist is still null
          const checkExistence = async () => {
            if (!id || id === "new") {
              setDoesExist(true);
              setLoading(false);
              return;
            }

            const docRef = db.collection("flows").doc(id);
            const doc = await docRef.get();
            setDoesExist(doc.exists);
            setLoading(false);
          };

          checkExistence();
        }

        if (loading) {
          return <div></div>;
        }

        if (doesExist) {
          return <Component {...routeProps} />;
        } else {
          return <div>404: Not Found</div>;
        }
      }}
    />
  );
};

export default FullRoute;
