import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";
let typingTimer;

// let removedWidgetNumber = [];

const TextComponent = (props) => {
  const [text, setText] = useState("");
  const WidgetStore = useContext(WidgetStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [lock, setLock] = useState(false);
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [showUnlockWidget, setShowUnlockWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);
  const [isDevAccount, setIsDevAccount] = useState(false);
  const textInput = useRef();

  useEffect(() => {
    setTimeout(() => {
      setIsDevAccount(AuthenticationStore.isDevAccount);
    });
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [AuthenticationStore.isDevAccount]);
  // setTimeout(() => {
  //   widgets[props.actualInstance].value = "";
  // }, 5000);
  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();
  let widgets = WidgetStore.widgets;
  let textComponentLoaded = false;

  // const Lock = () => {
  //   useEffect(() => {
  //     setLock(props.isLocked);
  //   }, []);
  //   return (
  //     <div
  //       onClick={(e) => {
  //         // setLock(e.target.value);

  //         //widgets.map((item) => {
  //         // alert(item.actualInstance + " " + props.actualInstance++);

  //         //if (item.actualInstance === props.actualInstance) {
  //         if (lock == false) {
  //           // setLock(true);
  //           setShowLockWidget(true);

  //           // widgets[props.actualInstance].isLocked = true;
  //         } else {
  //           // setLock(false);
  //           setShowUnlockWidget(true);
  //           // widgets[props.actualInstance].isLocked = false;
  //         }

  //         if (FlowStore.isFlowOwner === true) {
  //           firebase
  //             .firestore()
  //             .collection("flows")
  //             .doc(lastSegment)
  //             .update({ widgets: widgets })
  //             .then(() => {
  //               firebase
  //                 .firestore()
  //                 .collection("flows")
  //                 .doc(lastSegment)
  //                 // .limit(1)
  //                 .get()
  //                 .then((doc) => {
  //                   let data = doc.data();
  //                   console.log("widget data", data.widgets);

  //                   // WidgetStore.setWidgets(data.widgets);
  //                 });
  //             });
  //         }

  //         WidgetStore.setWidgets(widgets);
  //       }}
  //       style={{ marginTop: 8, marginLeft: 10, zIndex: 1000 }}
  //     >
  //       {lock === true ? (
  //         <FaLock
  //           size={17}
  //           color={"#F06263"}
  //           style={{ marginBottom: 7, cursor: "pointer" }}
  //         />
  //       ) : (
  //         <FaUnlock
  //           size={17}
  //           color={"#A962F1"}
  //           style={{ marginBottom: 7, cursor: "pointer" }}
  //         />
  //       )}
  //     </div>
  //   );
  // };
  useEffect(() => {
    if (
      props.value !== undefined &&
      props.value !== null &&
      textInput.current !== undefined &&
      textInput.current !== null
    )
      textInput.current.value = props.value;
      return () => {
        // Clean up or cancel any ongoing tasks or subscriptions here
      };
  }, []);
  const setJump = () => {
    let textCount = 0;
    let imageCount = 0;
    let colorCount = 0;
    let sliderCount = 0;
    let switchCount = 0;
    let selectCount = 0;
    let checkboxCount = 0;
    let calendarCount = 0;

    let optionsJump = [];
    WidgetStore.widgets.map((widget, i) => {
      if (optionsJump.length !== widgets.length) {
        if (
          widget.widget === "ImageUploader" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          imageCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: imageCount,
          });
        } else if (
          widget.widget === "TextInput" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          textCount++;
          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: textCount,
          });
        } else if (
          widget.widget === "ColorSelect" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          colorCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: colorCount,
          });
        } else if (
          widget.widget === "Slider" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          sliderCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: sliderCount,
          });
        } else if (
          widget.widget === "Switch" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          switchCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: switchCount,
          });
        } else if (
          widget.widget === "Select" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          selectCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: selectCount,
          });
        } else if (
          widget.widget === "Checkbox" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          checkboxCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: checkboxCount,
          });
        } else if (
          widget.widget === "Calendar" &&
          widget.isLocked === true &&
          WidgetStore.advancedOptions === false
        ) {
          calendarCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: calendarCount,
          });
        }
      }
    });
    WidgetStore.setJumpTo(optionsJump);
  };
  const editLabel = () => {
    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", true);
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "5px dotted #F06263";
  };
  const saveLabel = () => {
    if (
      document
        .getElementById(`label_${props.actualInstance}`)
        .innerText.toString()
        .trim().length > 14
    ) {
      WidgetStore.setErrorMessage(true);

      WidgetStore.setCurrentErrorMessage(
        "You can only enter a maximum of 14 charaacters for a label name."
      );
      return;
    }

    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", false);

    widgets.map((item) => {
      if (item.actualInstance === props.actualInstance) {
        widgets[props.actualInstance].label = document.getElementById(
          `label_${props.actualInstance}`
        ).innerText;
      }
    });
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "0px";
    // WidgetStore.setWidgets(widgets);
    setJump();

    if (FlowStore.isFlowOwner === true) {
      firebase
        .firestore()
        .collection("flows")
        .doc(lastSegment)
        .update({ widgets: WidgetStore.widgets })
        .then(() => {
          firebase
            .firestore()
            .collection("flows")
            .doc(lastSegment)
            // .limit(1)
            .get()
            .then((doc) => {
              let data = doc.data();
              console.log("widget data", data.widgets);

              WidgetStore.setWidgets(data.widgets);
            });
        });
    }
  };
  // setTimeout(() => {
  //   setText(
  //     widgets[props.actualInstance].value !== undefined ||
  //       widgets[props.actualInstance].value !== null
  //       ? widgets[props.actualInstance].value
  //       : ""
  //   );
  // }, 1000);

  return (
    <div
      style={{
        width: "100%",
        display: "block",
      }}
    >
      <div
        style={{
          marginBottom: 10,
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 35,
            width: "100%",
            borderBottom: "1px solid #323232",
            marginBottom: 17,
          }}
        ></div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p
            id={`label_${props.actualInstance}`}
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "white",
            }}
          >
            {props.label}
          </p>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <img src="../unlock.svg" style={{ height: 17 }} />
              <img src="../message-edit.svg" style={{ height: 17 }} />
              <img src="../direct-inbox.svg" style={{ height: 17 }} />
              <img src="../group.svg" style={{ height: 17 }} />
            </div>
          </div> */}
          {/* {isDevAccount == true && props.hideOptions !== true ? (
            <Lock style={{ cursor: "pointer" }} />
          ) : (
            ""
          )} */}
        </div>
        {/* {isDevAccount == true && props.hideOptions !== true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: 70,
            }}
          >
            <div onClick={editLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faPenSquare}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
            <div onClick={saveLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faSave}
                color="#4FD975"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              onClick={() => {
                setShowDeleteWidget(true);
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faWindowClose}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>

      <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
        {props.showInfo !== true ? (
          <input
            className="formInputOutline"
            ref={textInput}
            style={{
              backgroundColor: "#212F25",
              width: "100%",
              fontWeight: "bolder",
              fontFamily: "quicksand",
              color: "white",
              border: "none",
              height: 40,
              paddingLeft: 10,
              fontSize: 20,
              border: "1px solid #4FD975",
              outline: "none",
              borderRadius: 5,
            }}
            type="text"
            minrows={1}
            maxRows={1}
            multiline={false}
            // value={text === "" ? props.value : text}
            variant="outlined"
            placeholder=""
            onClick={() => {
              window.getAlready = false;
            }}
            // onFocus={() => {}}
            onChange={(e) => {
              //setText(e.target.value);
              // alert(e.target.key);
                // WidgetStore.setReplaceValue(e.target.value);
                // WidgetStore.setWidgetValueLength(e.target.value.length);
                // WidgetStore.setIsReplaceJS(true);
                // WidgetStore.setWidgetActualInstance(props.actualInstance);
                // WidgetStore.setEditorSelected(
                //   WidgetStore.widgets[props.actualInstance].editorSelected
                // );
                try {
                  let iframe = document.getElementById("undefined_output_frame");
                  if (iframe !== undefined && iframe !== null) {
                    // let ev = `
                    //   let iframe = document.getElementById(
                    //     "undefined_output_frame"
                    //   );
                    //   if (iframe !== undefined && iframe !== null) {
                    //     iframe.contentWindow.${props.function}('${e.target.value}')
                    //   }`;
                    iframe.contentWindow[props.functionName](e.target.value);
                    
                    // try {
                    //   eval(ev);
                    // } catch (e) {}
                  }
                  setText(e.target.value);
                } catch (e) {
                  console.log(e);
                }
            }}
            onKeyUp={(e) => {
              clearTimeout(typingTimer);
              typingTimer = setTimeout(() => {
                // if (props.functionName == "" || props.functionName == null) {
                //   localStorage.setItem("ReplaceValue", e.target.value);
                //   localStorage.setItem(
                //     "WidgetActualInstance",
                //     props.actualInstance
                //   );
                //   WidgetStore.setWidgetValueLength(e.target.value.length);
                //   WidgetStore.setIsReplaceJS(true);
                //   WidgetStore.setEditorSelected(
                //     WidgetStore.widgets[props.actualInstance].editorSelected
                //   );

                //   FlowStore.setRenderIframe(true);
                // } else {
                let textValue = e.target.value;

                if (
                  document.getElementById("undefined_output_frame") !== null
                ) {
                  eval(
                    `let iframe = document.getElementById("undefined_output_frame").contentWindow.${props.functionName}(textValue);`
                  );
                }

                // WidgetStore.setGetCodeFromIframe(true);

                // setTimeout(() => {
                //   WidgetStore.setIsUpdateFromWidget(true);
                // }, 1000);
              }, 500);
            }}
            onKeyDown={(event) => {
              if (event.keyCode == 9) {
                // event.preventDefault();
                window.getAlready = false;
              }
            }}
          />
        ) : (
          ""
        )}
        <p
          style={{
            color: "white",
            fontFamily: "quicksand",
            fontSize: 10,
            marginTop: 12,
            opacity: 0.7,
          }}
        >
          {props.info}
        </p>
        {AuthenticationStore.isDevAccount == true ? (
          <DeleteRemixWidgetModal
            deleteWidget={() => {
              widgets = widgets.filter(
                (widget) => widget.label !== props.label
              );
              window.widgets = widgets.filter(
                (widget) => widget.label !== props.label
              );
              let lastArr =
                JSON.parse(localStorage.getItem("lastRemoved")) === null
                  ? []
                  : JSON.parse(localStorage.getItem("lastRemoved")).sort();

              lastArr.push(props.instanceOfWidget);
              localStorage.setItem("lastRemoved", JSON.stringify(lastArr));

              // WidgetStore.setImageUploaderCount(
              //   WidgetStore.imageUploaderCount > 0
              //     ? WidgetStore.imageUploaderCount - 1
              //     : 0
              // );
              if (FlowStore.isFlowOwner === true) {
                firebase
                  .firestore()
                  .collection("flows")
                  .doc(lastSegment)
                  .update({
                    widgets: widgets.filter(
                      (widget) => widget.label !== props.label
                    ),
                  })
                  .then(() => {
                    WidgetStore.setWidgets(widgets);

                    // WidgetStore.setDeleteWidgetItem(true);
                    // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                    setJump();
                    setShowDeleteWidget(false);
                  });
              }
            }}
            showDeleteRemixWidgetModal={showDeleteWidget}
            closeWidget={() => {
              setShowDeleteWidget(false);
            }}
          />
        ) : (
          ""
        )}
        {AuthenticationStore.isDevAccount == true ? (
          <UnlockRemixWidgetModal
            unlock={() => {
              // setLock(false);
              widgets[props.actualInstance].isLocked = false;

              setShowUnlockWidget(false);
            }}
            showUnlockMessage={showUnlockWidget}
            closeUnlockModal={() => {
              setShowUnlockWidget(false);
            }}
          />
        ) : (
          ""
        )}
        {AuthenticationStore.isDevAccount == true ? (
          <LockRemixWidgetModal
            lock={() => {
              // setLock(true);
              widgets[props.actualInstance].isLocked = true;

              setShowLockWidget(false);
            }}
            showLockMessage={showLockWidget}
            closeLockModal={() => {
              setShowLockWidget(false);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default React.memo(TextComponent);
