import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { ShareStoreContext } from "../../store/ShareStore.js";
import Share from "./Share.js";
// import { TabStoreContext } from "@/src/store/TabStore.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoomContext } from "./RoomContext.js"; // Make sure to import the correct context
import { AuthContext } from "./AuthContext.js"; // Make sure to import the correct context

const ShareModal = observer((props) => {
  const ShareStore = useContext(ShareStoreContext);
  // const TabStore =  useContext(TabStoreContext);
  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const room = useContext(RoomContext);
  const { currentUser } = useContext(AuthContext);

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    // alert(isMobile);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  },[]);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={ShareStore.isShowModal}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.2)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "90%",
          maxWidth: "560px",
          maxHeight: "750px",
          overflow: "hidden",
          paddingBottom: 50,

        },
      }}
    >
      <div
        style={{
          justifyContent: "center",
          width: 300,
          backgroundColor: "rgb(63, 63, 62);",
          padding: 20,
          alignItems: "center",
          display: "flex",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: 0,
          zIndex: 100000,
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
            fontFamily: "quicksand",
          }}
        >
          Share
        </p>

        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#f06263"
          size={"4x"}
          style={{
            display: "block",
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            ShareStore.setIsShowModal(false);
          }}
        />
      </div>
      <Share />
    </ReactModal>
  );
});

export default ShareModal;
