import {
  faExclamationTriangle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "@material-ui/core/Switch";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, TabProvider, Tabs } from "react-web-tabs";
import { createContainer } from "unstated-next";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { CommentsStoreContext } from "../../store/CommentsStore";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { Flowroom } from "../../store/Flowroom.ts";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixMenu } from "../../store/RemixMenu.ts";
import { TabStoreContext } from "../../store/TabStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore";
import Share from "../Home/Editor/EditorModals/share-modal.js";
import { firebase } from "../firebase/firebase";
import CommentsModal from "./CommentsModal";
import DetailsMenu from "./DetailsMenu";
import Menu from "./Menu.js";
import { useParams } from 'react-router-dom';
import ReactModal from "react-modal";

let placeHolder;
let templateString;
let isMobile = window.matchMedia("(max-width: 768px)").matches;
let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let lastSegment = parts.pop() || parts.pop();

function useImageUploader() {
  let [_type, setType] = useState("");
  let [_classOrId, setClassOrId] = useState("");

  return { _type, setType, _classOrId, setClassOrId };
}

export let ImageUploader = createContainer(useImageUploader);

// let LazyImageUploader = React.lazy(() => import("../ImageUploader/index"));

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    height: 20,
    width: 50,
    color: "white",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 45,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 0,
    border: "2px solid #222222",
    marginTop: 3,
    // left: "-5px",
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3F3F3E",
        // opacity: 1,
        border: "2px solid #222222",
      },
    },
    "&$focusVisible $thumb": {
      color: "white",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20.7,
    height: 20.7,
    backgroundColor: "rgb(79, 217, 117)",
  },
  track: {
    height: 30,
    borderRadius: 20.5,
    border: `2px solid #222222`,
    backgroundColor: "#3F3F3E",
    opacity: 1,
    // transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const IOSSwitchAI = withStyles((theme) => ({
  root: {
    width: 45,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
    backgroundColor: "transparent",
  },
  switchBase: {
    padding: 0,
    border: "2px solid #222222",
    marginTop: 3,
    color: "#A962F1", // Set the color here to change the thumb color
    backgroundColor: "#3F3F3E",

    "&$checked": {
      transform: "translateX(20px)",
      color: "#A962F1", // Add this line to change the thumb color when checked
      "& + $track": {
        backgroundColor: "#3F3F3E",
        border: "2px solid #222222",
      },
    },
    "&$focusVisible $thumb": {
      border: "6px solid #fff",
      backgroundColor: "white",
    },
  },
  thumb: {
    width: 20.7,
    height: 20.7,
  },
  track: {
    height: 30,
    borderRadius: 20.5,
    border: `2px solid #222222`,
    backgroundColor: "#3F3F3E",
    opacity: 1,
  },
  checked: {
    backgroundColor: "#A962F1",
    color: "#A962F1",
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

// for legacy browsers
const Remix_Menu_Desktop = observer((props) => {
  const EditorStore = useContext(EditorStoreContext);
  const TabStore = useContext(TabStoreContext);
  const [isRemixSelected, setIsRemixSelected] = useState(false);
  const [isCodeSelected, setIsCodeSelected] = useState(false);
  const [isPostSelected, setIsPostSelected] = useState(false);
  const [isShareSelected, setIsShareSelected] = useState(false);
  const [isDetailsSelected, setIsDetailsSelected] = useState(false);
  const [isLikesSelected, setIsLikesSelected] = useState(false);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const WidgetStore = useContext(WidgetStoreContext);
  const CommentsStore = useContext(CommentsStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const [openComments, setOpenComments] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isUsingAI, setIsUsingAI] = useState(false);
  const [remix, setRemix] = useState(false);
  const [isCommentsSelected, setIsCommentsSelected] = useState(false);
  const [deniedCodeEditor, setDeniedCodeEditor] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    const remixDesktop = document.getElementById("remix-desktop");
    const verticalTabDetails = document.getElementById("vertical-tab-details-tab");
    const iframeContainer = document.getElementsByClassName("iframe-container")[0];
  
    if (remixDesktop) {
      remixDesktop.style.width = "378px";
    }
  
    if (verticalTabDetails) {
      verticalTabDetails.style.backgroundColor = "#323232";
      verticalTabDetails.style.borderRadius = "5px";
    }
  
    // if (iframeContainer) {
    //   iframeContainer.style.width = "calc(100% - 288px)";
    // }
  
    TabStore.setIsDetailSelected(true);
  
    // Cleanup function
    return () => {
      if (remixDesktop) {
        remixDesktop.style.width = ""; // Reset to default
      }
  
      if (verticalTabDetails) {
        verticalTabDetails.style.backgroundColor = ""; // Reset to default
        verticalTabDetails.style.borderRadius = ""; // Reset to default
      }
  
      if (iframeContainer) {
        iframeContainer.style.width = ""; // Reset to default
      }
  
      // Handle any additional state or side effects that need to be reset
    };
  }, []);
  
  // const incrementLikes = async () => {
  //   if (currentUser == null || currentUser.displayName == null) {
  //     AuthenticationStore.setShowModal(true);
  //     return;
  //   }

  //   setPending(true);
  //   let docLike = await firebase
  //     .firestore()
  //     .collection("likes")
  //     .doc(currentUser.uid + ":" + props.flowId)
  //     .get();

  //   if (docLike.exists) {
  //     await setIsLiked(false);
  //     await setLikes(likes - 1);

  //     await firebase
  //       .firestore()
  //       .collection("likes")
  //       .doc(currentUser.uid + ":" + props.flowId)
  //       .delete();

  //     await firebase
  //       .firestore()
  //       .collection("flows")
  //       .doc(props.flowId)
  //       .set(
  //         { likes: firebase.firestore.FieldValue.increment(-1) },
  //         { merge: true }
  //       );

  //     await firebase
  //       .firestore()
  //       .collection("users")
  //       .doc(props.userId)
  //       .set(
  //         { likes: firebase.firestore.FieldValue.increment(-1) },
  //         { merge: true }
  //       );

  //     await setPending(false);
  //   } else {
  //     setIsLiked(true);
  //     setLikes(likes + 1);

  //     firebase
  //       .firestore()
  //       .collection("flows")
  //       .doc(props.flowId)
  //       .set(
  //         { likes: firebase.firestore.FieldValue.increment(1) },
  //         { merge: true }
  //       )
  //       .then(() => setPending(false));

  //     firebase
  //       .firestore()
  //       .collection("notifications")
  //       // .doc(myId)
  //       .add({
  //         type: "liked",
  //         text: "",
  //         myUserId: props.myUserId,
  //         otherUserId: "",
  //         otherUserName: props.username,
  //         seen: false,
  //         date: `${new Date()}`,
  //         photoURL: props.photoURL,
  //         flowId: props.flowId,
  //         created: new Date().toISOString(),
  //         timestamp: firebase.firestore.Timestamp.fromDate(new Date()).toDate(),
  //       })
  //       .then(() => {
  //         firebase
  //           .firestore()
  //           .collection("likes")
  //           .doc(currentUser.uid + ":" + props.flowId)
  //           .set(
  //             {
  //               flowId: props.flowId,
  //               likeId: currentUser.uid + ":" + props.flowId,
  //               userId: currentUser.uid,
  //               timestamp: new Date().toISOString(),
  //             },
  //             { merge: true }
  //           );

  //         firebase
  //           .firestore()
  //           .collection("users")
  //           .doc(props.userId)
  //           .set(
  //             { likes: firebase.firestore.FieldValue.increment(1) },
  //             { merge: true }
  //           );

  //         window.analytics.track("Flow Liked", {
  //           flowId: props.flowId,
  //           title: props.title,
  //           userId: props.userId,
  //           username: props.username,
  //           actionUserId: currentUser ? currentUser.uid : null,
  //           actionUsername: currentUser ? currentUser.displayName : null,
  //         });
  //       });

  //     // console.log("[RoomPost] incrementLikes props:", props);
  //   }
  // };
  useEffect(() => {
    setIsMenuLoading(true);
  
    const timeoutId = setTimeout(() => {
      setIsMenuLoading(false);
      const skeletonLoading = document.getElementsByClassName("skeleton-loading")[0];
      if (skeletonLoading) {
        skeletonLoading.style.display = "none";
      }
    }, 2000);
  
    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
  
      // If necessary, reset any styles or states that the timeout would have changed.
      // For example, if you want to reset the display of 'skeleton-loading':
      const skeletonLoading = document.getElementsByClassName("skeleton-loading")[0];
      if (skeletonLoading) {
        skeletonLoading.style.display = ""; // Reset to the default style
      }
    };
  }, []);
  

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedB === false) {
      //createVoiceAI();
    }
  };
  let flowRoom = Flowroom.useContainer();
  let imageUploader = ImageUploader.useContainer();
  let remixMenu = RemixMenu.useContainer();

  // showInfo("info_start");

  const selected = (selected) => {
    switch (selected) {
      case "remix":
  
        WidgetStore.setDraggableWidgetVisible(true);

        TabStore.setIsRemixSelected(true);
        TabStore.setIsCodeSelected(false);
        //setIsAddFlowSelected(false);
        TabStore.setIsDetailSelected(false);

        TabStore.setIsPostSelected(false);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(false);
        if(document.getElementsByClassName('iframe-container') == null ) {
          return;
        }
        
    
      

          document.getElementById('vertical-tab-remix-tab').style.backgroundColor = 'rgb(50, 50, 50)';
          document.getElementById('vertical-tab-remix-tab').style.borderRadius = '5px';

        // document.getElementsByClassName("iframe-container")[0].style.width =
        //   "calc(100% - 125px)";
        // document.getElementsByClassName("iframe-container")[0].style.width =
        //   "calc(100% - 412px)";
          document.getElementById("vertical-tab-code-tab").style.backgroundColor =
          "transparent";
          if(document.getElementById("vertical-tab-likes-tab") !== null) {
          document.getElementById("vertical-tab-likes-tab").style.backgroundColor =
          "transparent";
          }
          if(document.getElementById("vertical-tab-details-tab") !== null) {
          document.getElementById("vertical-tab-details-tab").style.backgroundColor =
          "transparent";
          }

        break;
      case "details":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(false);
        //setIsAddFlowSelected(false);
        TabStore.setIsPostSelected(false);
        TabStore.setIsDetailSelected(true);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(false);
        break;
      case "likes":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(false);
        //setIsAddFlowSelected(false);
        TabStore.setIsPostSelected(false);
        TabStore.setIsDetailSelected(false);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(true);
        TabStore.setIsCommentsSelected(false);

        break;
      case "comments":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(false);
        //setIsAddFlowSelected(false);
        TabStore.setIsPostSelected(false);
        TabStore.setIsDetailSelected(false);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(true);
       
    
        break;
      case "code":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(true);
        //setIsAddFlowSelected(false);
        TabStore.setIsPostSelected(false);
        TabStore.setIsDetailSelected(false);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(false);
     
       
        break;
      case "post":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(false);
        //setIsAddFlowSelected(false);
        TabStore.setIsDetailSelected(false);

        TabStore.setIsPostSelected(true);
        TabStore.setIsShareSelected(false);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(false);
        break;
      case "share":
        TabStore.setIsRemixSelected(false);
        TabStore.setIsCodeSelected(false);
        // setIsAddFlowSelected(false);
        TabStore.setIsDetailSelected(false);

        TabStore.setIsPostSelected(false);
        TabStore.setIsShareSelected(true);
        TabStore.setIsLikesSelected(false);
        TabStore.setIsCommentsSelected(false);
      case "more":
        break;
    }
  };

  if (remixMenu.currentlyDisplayedPane === "RemixableStore") {
    return (
      <div
        style={{
          position: "relative",
          left: 0,
          height: "100vh",
          backgroundColor: "#1C1C1C",
          backgroundColor: "rgb(28, 28, 28)",
          zIndex: 1000,
          borderTop: "1px solid rgb(50, 50, 50)",
        }}
      >
        <TabProvider veritcal defaultTab="vertical-tab-remix">
          <Tabs vertical defaultTab={activeTab} className="vertical-tabs">
            <TabList
              style={{
                paddingTop: 0,
                width: 74,
                alignItems: "center",
                paddingTop: 30,
                width: 75,
                alignItems: "center",
                paddingRight: 7,
              }}
            >
              {id !== "new" ? (
                <Tab
                  tabFor="vertical-tab-details"
                  onClick={() => {
                    // document.getElementById("remix-desktop").style.width =
                    //   "300px";
                    document.getElementById(
                      "vertical-tab-details-tab"
                    ).style.backgroundColor = "#323232";
                    document.getElementById(
                      "vertical-tab-details-tab"
                    ).style.borderRadius = "5px";
                    TabStore.setIsDetailSelected(true);
                    // if (
                    //   document.getElementsByClassName("remix-menu")[0].style
                    //     .display == "block"
                    // ) {
                    //   document.getElementsByClassName(
                    //     "top-box-wrap"
                    //   )[0].style.marginLeft = "295px";
                    // }
                    // // if (
                    //   firebase.auth().currentUser == null ||
                    //   firebase.auth().currentUser.displayName == null
                    // ) {

                    // document.getElementsByClassName(
                    //   "remix-menu"
                    // )[0].style.display = "none";
                    // if (document.getElementsByClassName("editor-menu") !== null) {
                    //   document.getElementsByClassName(
                    //     "editor-menu"
                    //   )[0].style.paddingLeft = "78px";
                    // }
                    //   AuthenticationStore.setShowModal(true);
                    //   return;
                    // }
                    // WidgetStore.setGetCodeFromIframe(true);
                    // document.getElementsByClassName(
                    //   "remix-menu"
                    // )[0].style.display = "block";
                    selected("details");
                    EditorStore.setCodeBoxesWidth(
                      document.getElementById("resizable-box").clientWidth / 3
                    );
                  }}
                  style={{
                    height: 36,
                    width: 36,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <DetailsIcon selected={isDetailsSelected} /> */}
                    {TabStore.isDetailSelected ? (
                      <img src="/images/info-circle.svg" />
                    ) : (
                      <img src="/images/info-menu.svg" />
                    )}
                  </div>
                </Tab>
              ) : (
                ""
              )}
              {id !== "new" ? (
                <Tab
                  tabFor="vertical-tab-likes"
                  onClick={() => {
                   
                    document.getElementById(
                      "vertical-tab-details-tab"
                    ).style.backgroundColor = "#323232";
                    document.getElementById(
                      "vertical-tab-details-tab"
                    ).style.borderRadius = "5px";
           
                    if (
                      firebase.auth().currentUser == null ||
                      firebase.auth().currentUser.displayName == null
                    ) {
                      WidgetStore.setGetCodeFromIframe(true);

                      document.getElementsByClassName(
                        "remix-menu"
                      )[0].style.display = "none";
                      AuthenticationStore.setShowModal(true);
                      return;
                    }
                
                    document.getElementsByClassName(
                      "remix-menu"
                    )[0].style.display = "none";

                    // document.getElementsByClassName(
                    //   "remix-menu"
                    // )[0].style.display = "block";
                    selected("likes");

                    // incrementLikes();
                    EditorStore.setCurrentFlowLikesNumber(
                      EditorStore.currentFlowLikesNumber + 1
                    );
                    EditorStore.setCodeBoxesWidth(
                      document.getElementById("resizable-box").clientWidth / 3
                    );
                  }}
                  style={{
                    height: 36,
                    width: 36,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!TabStore.isLikesSelected ? (
                      <img src="../images/heart-menu.svg" />
                    ) : (
                      <img src="../images/heart-icon-menu-selected.svg" />
                    )}
                  </div>
                </Tab>
              ) : (
                ""
              )}
              {id !== "new" ? (
                <Tab
                  tabFor="vertical-tab-comments"
                  onClick={() => {
                    document.getElementById("remix-desktop").style.width =
                      "70px";
                    if (
                      firebase.auth().currentUser == null ||
                      firebase.auth().currentUser.displayName == null
                    ) {
                      // document.getElementsByClassName(
                      //   "editor-menu"
                      // )[0].style.paddingLeft = "78px";
                      document.getElementsByClassName(
                        "remix-menu"
                      )[0].style.display = "none";
                      AuthenticationStore.setShowModal(true);
                      return;
                    }
                    CommentsStore.setFlowId(FlowStore.flowId);
                    CommentsStore.setRoom(props);
                    CommentsStore.setIsLoaded(false);
                    CommentsStore.setIsShowModal(true);

                    CommentsStore.setIsShowModal(true);
                    // WidgetStore.setGetCodeFromIframe(true);

                    // if (
                    //   firebase.auth().currentUser == null ||
                    //   firebase.auth().currentUser.displayName == null
                    // ) {
                    // document.getElementsByClassName(
                    //   "editor-menu"
                    // )[0].style.paddingLeft = "78px";
                    //   document.getElementsByClassName(
                    //     "remix-menu"
                    //   )[0].style.display = "none";
                    //   AuthenticationStore.setShowModal(true);
                    //   return;
                    // }

                    document.getElementsByClassName(
                      "remix-menu"
                    )[0].style.display = "none";
                    selected("comments");
                    EditorStore.setCodeBoxesWidth(
                      document.getElementById("resizable-box").clientWidth / 3
                    );


                    document.getElementsByClassName(
                      "top-box-wrap"
                    )[0].style.marginLeft = 0;
             
                    
                  }}
                  style={{
                    height: 36,
                    width: 36,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <DetailsIcon selected={isDetailsSelected} /> */}

                    {!TabStore.isCommentsSelected ? (
                      <img src="../images/comments-menu.svg" />
                    ) : (
                      <img src="../images/comments-selected.svg" />
                    )}

                    {/* <i
                      className="fas fa-comment"
                      style={{ color: "#6371F6", fontSize: "40px" }}
                    ></i> */}
                    {/* <p
                      style={{
                        color: "#949DF2",
                        fontFamily: "quicksand",
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {isNaN(EditorStore.currentFlowCommentsNumber)
                        ? 0
                        : EditorStore.currentFlowCommentsNumber}
                    </p> */}
                  </div>
                </Tab>
              ) : (
                ""
              )}
              <Tab
                id="remix-tab-button"
                tabFor="vertical-tab-remix"
                onClick={() => {
                  // document.getElementById("remix-desktop").style.width = "70px";

                  document.getElementsByClassName(
                    "ai-create"
                  )[0].style.display = "none";

                  selected("remix");
                  EditorStore.setCodeBoxesWidth(
                    document.getElementById("resizable-box").clientWidth / 3
                  );
                  // if (
                  //   document.getElementsByClassName("top-box-wrap")[0].style
                  //     .display == "block"
                  // ) {
                  //   document.getElementsByClassName(
                  //     "top-box-wrap"
                  //   )[0].style.marginLeft = "0px";
                  // }

                  // let isFlowLoaded = setInterval(() => {
                  //   if (WidgetStore.customRemixFunction !== null) {
                  //     if (
                  //       document.getElementById("undefined_output_frame") !==
                  //       null
                  //     ) {
                  //       eval(
                  //         `let iframe = document.getElementById("undefined_output_frame").contentWindow.${WidgetStore.customRemixFunction}();`
                  //       );
                  //       clearInterval(isFlowLoaded);
                  //     }
                  //   }
                  // });
                }}
                style={{
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  {/* <RemixIcon selected={isRemixSelected} /> */}
                  {!TabStore.isRemixSelected ? (
                    <img src="../images/widget-menu.svg" />
                  ) : (
                    <img src="../images/widget-menu-selected.svg" />
                  )}
                </div>
              </Tab>

             <Tab
                tabFor="vertical-tab-code"
                onClick={() => {
                  if(FlowStore.isFlowOwner == true || id == "new") {
                  document.getElementById("remix-desktop").style.width = "70px";
                  document.getElementById('vertical-tab-code-tab').style.backgroundColor = 'rgb(50, 50, 50)';
                  document.getElementById('vertical-tab-code-tab').style.borderRadius = '5px';
      
                  document.getElementById('vertical-tab-remix-tab').style.backgroundColor = 'transparent';
                  document.getElementById('vertical-tab-remix-tab').style.borderRadius = '0px';


                  if (TabStore.isCodeSelected == true) {
                    document.getElementsByClassName(
                      "top-boxes"
                    )[0].style.display = "block";

                    EditorStore.showAnimationF();
                  } else {
                    document.getElementsByClassName(
                      "top-boxes"
                    )[0].style.display = "block";

                    EditorStore.showAnimationF();
                    selected("code");
                  }

                } else {
                  setDeniedCodeEditor(true);

                  document.getElementsByClassName(
                    "remix-menu"
                  )[0].style.display = "none";

                }
                  
                }}
                style={{
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  {/* <CodeIcon selected={isCodeSelected} /> */}
                  {!TabStore.isCodeSelected ? (
                    <img src="../images/code-editor-menu.svg" />
                  ) : (
                    <img src="../images/code-editor-menu-selected.svg" />
                  )}
                </div>
              </Tab>

              <Tab
                tabFor="vertical-tab-share"
                onClick={() => {
                  // document.getElementById("remix-desktop").style.width = "70px";
                  // document.getElementsByClassName(
                  //   "remix-menu"
                  // )[0].style.display = "none";
                  // document.getElementsByClassName("remix-menu")[0].style.width =
                  //   "0px";
                  selected("share");
                  EditorStore.setShowShareModal(true);
                  // document.getElementsByClassName(
                  //   "editor-menu"
                  // )[0].style.paddingLeft = "78px";
                  document.getElementsByClassName(
                    "top-box-wrap"
                  )[0].style.marginLeft = 0;
                }}
                style={{
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  display:
                    (id !== "new" &&
                      PublishMenuStore.showShare === true) ||
                    PublishMenuStore.showShare === true
                      ? "flex"
                      : "none",
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "block",
                  }}
                >
                  {/* <ShareIcon selected={isShareSelected} /> */}
                  {!TabStore.isShareSelected ? (
                    <img src="../images/share-flow.svg" />
                  ) : (
                    <img src="../images/share-selected.svg" />
                  )}
                </div>
              </Tab>
              <Tab
                tabFor="vertical-tab-more"
                onClick={() => {
                  EditorStore.setShowMoreModal(true);
                  document.getElementsByClassName(
                    "top-box-wrap"
                  )[0].style.marginLeft = 0;
                
 
                  // if (
                  //   document.getElementsByClassName("top-box-wrap")[0].style
                  //     .display == "block"
                  // ) {
                  //   document.getElementsByClassName(
                  //     "top-box-wrap"
                  //   )[0].style.marginLeft = "295px";
                  // } else {
                  //   document.getElementsByClassName(
                  //     "top-box-wrap"
                  //   )[0].style.marginLeft = "0px";
                  // }
                  
                  // document.getElementById("remix-desktop").style.width =
                  //   "378px";
             
                  // if (
                  //   document.getElementsByClassName("remix-menu")[0].style
                  //     .display == "block"
                  // ) {
                  //   document.getElementsByClassName(
                  //     "top-box-wrap"
                  //   )[0].style.marginLeft = "295px";
                  // }
                  // // if (
                  //   firebase.auth().currentUser == null ||
                  //   firebase.auth().currentUser.displayName == null
                  // ) {

                  // document.getElementsByClassName(
                  //   "remix-menu"
                  // )[0].style.display = "none";
                  // if (document.getElementsByClassName("editor-menu") !== null) {
                  //   document.getElementsByClassName(
                  //     "editor-menu"
                  //   )[0].style.paddingLeft = "78px";
                  // }
                  //   AuthenticationStore.setShowModal(true);
                  //   return;
                  // }
                  // WidgetStore.setGetCodeFromIframe(true);
                  // document.getElementsByClassName(
                  //   "remix-menu"
                  // )[0].style.display = "block";
                  // selected("more");
                
                }}
                style={{
                  height: 36,
                  width: 36,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  display:"flex",
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "block",
                  }}
                >
                  {/* <ShareIcon selected={isShareSelected} /> */}
                  {!TabStore.isShareSelected ? (
                    <img src="../images/more-tabbar-mobile.svg" />
                  ) : (
                    <img src="../images/more-tabbar-mobile.svg" />
                  )}
                  <p style={{position:'relative', color:'white', fontSize:12}}>More</p>
                </div>
              </Tab>
              <div
                style={{
                  height: 150,
                  background: "transparent",
                  width: 70,
                  bottom: 70,
                  position: "fixed",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {PublishMenuStore.isRemixable == true &&
                EditorStore.isRemixableByDevOnly == false ? (
                  <div style={{}}>
                    {/* <p
                      style={{
                        fontFamily: "quicksand",
                        color: "#4FD975",
                        marginBottom: 7,
                        fontSize: 14,
                      }}
                    >
                      Remix
                    </p> */}
                    {/* <RemixSwitch /> */}
                    {/* <IOSSwitch
                      onChange={(e) => {
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        let windowSize = window.innerWidth;
                        if (e.target.checked == true) {
                          eval(`
                       try {
                         iframe.contentWindow.${WidgetStore.startRemixing}();
                        } catch (e) {
                          if(windowSize >= 700) {
                            // parent.window.alert('not remixable');
                                 if(!AuthenticationStore.isDevAccount) {
                                      WidgetStore.setNoRemixWidgetModal(true)
                                 }                      

                          }

                        }  
                      `);
                          WidgetStore.setIsCurrentlyRemixing(true);
                          function myFunction(x) {
                            if (x.matches) {
                              if (WidgetStore.isCurrentlyRemixing === true) {
                                document.getElementsByClassName(
                                  "feed-save"
                                )[0].style.display = "none";
                              }
                            } else {
                              if (WidgetStore.isCurrentlyRemixing === true) {
                                // document.getElementsByClassName('feed-save')[0].style.display = "flex";
                              }
                            }
                          }
                          var x = window.matchMedia("(min-width: 700px)");
                          myFunction(x); // Call listener function at run time
                          x.addListener(myFunction);
                        }
                        if (e.target.checked == false) {
                          WidgetStore.setIsCurrentlyRemixing(false);
                          eval(
                            `try { iframe.contentWindow.${WidgetStore.endRemixing}() } catch(e) { }`
                          );
                          document.getElementsByClassName(
                            "feed-save"
                          )[0].style.display = "none";
                        }
                      }}
                      checked={WidgetStore.isCurrentlyRemixing}
                    /> */}
                  </div>
                ) : (
                  ""
                )}

                {/* <IOSSwitchAI
                      onChange={(e) => {
                        setIsUsingAI(e.target.checked);
                        if (e.target.checked) {
                          setActiveTab("vertical-tab-ai-create");
                          document.getElementById(
                            "vertical-tab-ai-create"
                          ).style.display = "block";

                          document.getElementsByClassName(
                            "ai-create"
                          )[0].style.display = "block";

                          document.getElementsByClassName(
                            "remix-menu"
                          )[0].style.display = "block";

                          document.getElementsByClassName(
                            "editor-menu"
                          )[0].style.paddingLeft = "380px";
                          document.getElementById(
                            "vertical-tab-post"
                          ).style.display = "none";
                        } else {
                          setActiveTab("vertical-tab-ai-create");
                          document.getElementById(
                            "vertical-tab-ai-create"
                          ).style.display = "none";

                          document.getElementsByClassName(
                            "remix-menu"
                          )[0].style.display = "none";

                          document.getElementsByClassName(
                            "editor-menu"
                          )[0].style.paddingLeft = "78px";
                        }
                      }}
                      checked={isUsingAI}
                    /> */}

                {PublishMenuStore.isRemixable == true &&
                EditorStore.isRemixableByDevOnly == true &&
                AuthenticationStore.isDevAccount == true ? (
                  <div
                    style={{
                      height: 30,
                      width: 50,
                      margin: "auto",
                      top: "77px",
                      position: "relative",
                    }}
                  >
                    {/* <p
                      style={{
                        fontFamily: "quicksand",
                        color: "white",
                        marginBottom: 5,
                        fontWeight: "normal",
                        width: 50,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      Remix
                    </p> */}
                    {/* <img
                      onClick={() => {
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        let windowSize = window.innerWidth;
                        WidgetStore.setIsCurrentlyRemixing(true); 

                        //if (true == true) {
                          //     eval(`
                          //  try {
                          //    iframe.contentWindow.${WidgetStore.startRemixing}();
                          //   } catch (e) {
                          //     if(windowSize >= 700) {
                          //       // parent.window.alert('not remixable');
                          //            if(!AuthenticationStore.isDevAccount) {
                          //                                   WidgetStore.setNoRemixWidgetModal(true)
                          //            }
                          //     }
                          //   }
                          // `);
                          // function myFunction(x) {
                          //   if (x.matches) {
                          //     if (WidgetStore.isCurrentlyRemixing === true) {
                          //       document.getElementsByClassName(
                          //         "feed-save"
                          //       )[0].style.display = "none";
                          //     }
                          //   } else {
                          //     if (WidgetStore.isCurrentlyRemixing === true) {
                          //       // document.getElementsByClassName('feed-save')[0].style.display = "flex";
                          //     }
                          //   }
                          // }
                          // var x = window.matchMedia("(min-width: 700px)");
                          // myFunction(x); // Call listener function at run time
                          // x.addListener(myFunction);
                        //}
                        //if (false == false) {
                          // WidgetStore.setIsCurrentlyRemixing(false);
                          // eval(
                          //   `try { iframe.contentWindow.${WidgetStore.endRemixing}() } catch(e) { }`
                          // );
                          // document.getElementsByClassName(
                          //   "feed-save"
                          // )[0].style.display = "none";
                       // }
                    //   }}
                    //   src="../images/remix-switch-off.svg"
                    //   style={{ height: 20, width: 50, margin: "auto" }}
                    // />

                    {/* <IOSSwitch
                      onChange={(e) => {
                        let iframe = document.getElementById(
                          "undefined_output_frame"
                        );
                        let windowSize = window.innerWidth;
                        if (e.target.checked == true) {
                          eval(`
                       try {
                         iframe.contentWindow.${WidgetStore.startRemixing}();
                        } catch (e) {
                          if(windowSize >= 700) {
                            // parent.window.alert('not remixable');
                                 if(!AuthenticationStore.isDevAccount) {
                                                        WidgetStore.setNoRemixWidgetModal(true)
                                 }                      

                          }

                        }  
                      `);
                          WidgetStore.setIsCurrentlyRemixing(true);
                          function myFunction(x) {
                            if (x.matches) {
                              if (WidgetStore.isCurrentlyRemixing === true) {
                                document.getElementsByClassName(
                                  "feed-save"
                                )[0].style.display = "none";
                              }
                            } else {
                              if (WidgetStore.isCurrentlyRemixing === true) {
                                // document.getElementsByClassName('feed-save')[0].style.display = "flex";
                              }
                            }
                          }
                          var x = window.matchMedia("(min-width: 700px)");
                          myFunction(x); // Call listener function at run time
                          x.addListener(myFunction);
                        }
                        if (e.target.checked == false) {
                          WidgetStore.setIsCurrentlyRemixing(false);
                          eval(
                            `try { iframe.contentWindow.${WidgetStore.endRemixing}() } catch(e) { }`
                          );
                          document.getElementsByClassName(
                            "feed-save"
                          )[0].style.display = "none";
                        }
                      }}
                      checked={WidgetStore.isCurrentlyRemixing}
                    /> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TabList>

            <div
              className="remix-menu"
              style={{
                background: "#1C1C1C",
                height: PublishMenuStore.showBottom ? "100%" : "100%",
                width: "302px",
                overflowY: "scroll",
                borderLeft: "1px solid #323232",
                borderTop: "0px solid rgb(50, 50, 50)",
                overflowX: "hidden",
              }}
            >
              <TabPanel tabId="vertical-tab-remix">
                <div
                  style={{
                    backgroundColor: "#3F3F3E",
                    height: "calc(100vh - 77px)",
                    overflowY: "scroll",
                    width: 302,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgb(28, 28, 28)",
                      position: "absolute",
                      zIndex: 1000,
                      maxWidth: "302px",
                      display: isMenuLoading ? "flex" : "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="loader">
                      <div className="outer"></div>
                      <div className="middle"></div>
                      <div className="inner"></div>
                    </div>
                  </div>
                  {isMobile === true ? "" : <Menu />}
                </div>
              </TabPanel>

              <TabPanel tabId="vertical-tab-details">
                {/* <div
                  className="main-menu"
                  style={{
                    borderRight: "1px solid rgb(24, 24, 24)",
                    background: "#222222",
                    left: "0px; flex-direction: column",
                    transform: "translateX(0px) translateZ(-378px)",
                    display: "block",
                    position: "relative",
                    overflowY: "scroll",
                    padding: "7px 14px 2px 10px",
                    height: "100vh",
                  }}
                ></div> */}
                {/* <Details props={props} /> */}

                {EditorStore.username !== undefined ? (
                  <div>
                    <DetailsMenu
            
                    />
                  </div>
                ) : (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // backgroundColor: "#222222",
                      width: "100%",
                      height: "100vh",
                    }}
                  >
                    <div
                      style={{
                        width: 300,
                        backgroundColor: "rgb(63, 63, 62);",
                        padding: 20,
                        display: "flex",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100vh",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontFamily: "Quicksand",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: 18,
                        }}
                      >
                        Info
                      </p>

                      <div onClick={()=>{
                                 
                                  // document.getElementById(
                                  //   "horizontal-tab-remix-tab"
                                  // ).style.background = "none";
                        
                                  // document.getElementsByClassName("iframe-container")[0].style.width =
                                  // "calc(100% - 125px)";

                                  document.getElementsByClassName('top-box-wrap')[0].style.marginLeft = '0px';
                                
                    }} style={{ 
                          display: "block",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          cursor: "pointer",
                          backgroundImage:'url(/images/close-circle.svg)', backgroundSize:'contain', height:20, width:20, marginTop:15, marginRight:10}}></div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        position: "absolute",
                        flexDirection: "column",
                        top: "200px",
                        marginLeft: 30,
                      }}
                    >
                      <FontAwesomeIcon
                        key={Math.random() + "icon" + new Date()}
                        icon={faExclamationTriangle}
                        color="#FFFFFF"
                        style={{
                          display: "flex",
                          marginBottom: 10,
                          fontSize: "70px",
                          position: "relative",
                          bottom: 20,
                        }}
                      />
                      <p
                        style={{
                          position: "relative",
                          fontFamily: "Quicksand",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "243px",
                          fontSize: 16,
                          left: 0,
                          textAlign: "center",
                        }}
                      >
                        There are no details available for this flow. If you
                        feel this is an error. Refresh and try again.
                      </p>
                    </div>
                  </div>
                )}
              </TabPanel>

              {/* <TabPanel tabId="vertical-tab-code">
                <div
                  className="main-menu"
                  style={{
                    borderRight: "1px solid rgb(24, 24, 24)",
                    background: "#222222",
                    left: "0px; flex-direction: column",
                    transform: "translateX(0px) translateZ(-378px)",
                    display: "block",
                    position: "relative",
                    overflowY: "scroll",
                    padding: "7px 14px 2px 10px",
                    height: "100vh",
                  }}
                ></div>
              </TabPanel> */}

              <TabPanel tabId="vertical-tab-post">
                {/* <PublishMenu props={props} /> */}
              </TabPanel>
              <div
                className="ai-create"
                style={{
                  background: "#3f3f3e",
                  height: "100vh",
                  width: "302px",
                  overflowY: "scroll",
                  position: "absolute",
                  top: 0,
                  display: "none",
                }}
              >
                <TabPanel tabId="vertical-tab-ai-create"></TabPanel>
              </div>

              <TabPanel
                tabId="vertical-tab-share"
                style={{
                  height: "100vh",
                  backgroundColor: "rgb(63, 63, 62);",
                }}
              >
                <Share
                  flowId={EditorStore.currentFlowId}
                  isAReShare={EditorStore.isAReShare}
                />
              </TabPanel>
        
            </div>
          </Tabs>
        </TabProvider>
        {/* <CommentsModal
          open={openComments}
          close={() => {
            setOpenComments(false);
          }}
        /> */}
          <ReactModal
      isOpen={deniedCodeEditor}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.2)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "400px",
          maxWidth: "557px",
          padding: 20,
          overflowX:'hidden'
        },
      }}
    >
      <div style={{ display: "flex", justifyContent:'center', alignItems:'center', flexDirection:'column' }}>
      <div onClick={()=>{
         setDeniedCodeEditor(false);
                                  
                   }} style={{ 
                         display: "block",
                         position: "absolute",
                         right: "10px",
                         top: "10px",
                         cursor: "pointer",
                         backgroundImage:'url(../images/close-circle.svg)', backgroundSize:'contain', height:20, width:20, marginTop:15, marginRight:10}}></div>
      <img src="../images/warning.svg" style={{marginTop:40}}/>
      <p style={{color:'white', fontFamily:'quicksand', fontSize:18,
    marginTop: 30,
    textAlign: "center",
    maxWidth: 450, marginBottom:20}}>You can not get access to code unless you created it or are made a collaborator by the owner.</p>
<div style={{height:50, width:100, backgroundColor:'#4FD975',     height: 57,
    width: 200,
    backgroundColor: 'rgb(79, 217, 117)',
    borderRadius: 10, cursor:'pointer', marginTop:15, justifyContent:'center', alignItems:'center', display:'flex'}} onClick={()=>{
      setDeniedCodeEditor(false);

    }}><p style={{color:'#000'}}>OK</p></div>
  <style jsx>{`
    .button-container {
      width: 48%; /* default width */
      height: 42px;
      border: 1px solid #4FD975;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      /* other styles */
    }

    @media (max-width: 600px) { /* Adjust this breakpoint as needed */
      .button-container {
        width: 100%; /* full width on small screens */
      }
    }

  .dropdown-container {
    position: relative;
  }
  .dropdown-display {
    background-color: #1c1c1c !important;
    padding: 7px !important;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #4fd975;
    color: white;
    font-family: "Quicksand";
    overflow: hidden;
    border-radius: 20px;
  }
  .dropdown-list {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #1c1c1c;
    border: 0px solid #ddd;
    border-radius: 5px;
    width: 100%;
    z-index: 100;
    bottom: -59px !important;
    border-left: 1px solid #4fd975 !important;
    border-bottom: 1px solid #4fd975 !important;
    border-right: 1px solid #4fd975 !important;
    color: white;
    font-family: quicksand;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    max-height: 200px; /* Adjust based on your requirement */

    /* Custom scroll bar styles */
    scrollbar-width: thin;
    scrollbar-color: #4fd975 #1c1c1c; /* thumb and track color */

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #1c1c1c;
    }
    &::-webkit-scrollbar-thumb {
      background: #4fd975;
      border-radius: 4px;
    }
  }
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }
  `}</style>
      </div>
    </ReactModal>
      </div>
    );
  }
});

function WrappedRemixMenuDesktop() {
  return (
    <RemixMenu.Provider>
      <ImageUploader.Provider>
        <Flowroom.Provider>
          <Remix_Menu_Desktop />
        </Flowroom.Provider>
      </ImageUploader.Provider>
    </RemixMenu.Provider>
  );
}

export default React.memo(WrappedRemixMenuDesktop);
