// libraries
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useTransitionHistory } from "react-route-transition";
// stores
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { RouteStoreContext } from "../../store/RouteStore.js";
import { ShareStoreContext } from "../../store/ShareStore.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
// modals
// content
import { faCheck, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ReactModal from "react-modal";
import LoginM from "./LoginM.js";
import SigninM from "./SigninM.js";
import Header from "./Header.js";

// import Header from "./Header";
// import MoreModal, { MoreModalContainer } from "../Home/MoreModal.js";
// import RemixModalFeed from "./RemixModalFeed.js";
// import ShareModal from "./ShareModal.js";

// const MoreModal = React.lazy(() => import("./MoreModal"));
const ReactWindowPagination = React.lazy(() =>
  import("./ReactWindowPagination.js")
);
// const LandingPage = React.lazy(() => import('LandingPageApp/App'));


// const ReactVirtualPage = React.lazy(() => import("./ReactVirtualPage.js"));
// const RemixModalFeed = React.lazy(() => import("./RemixModalFeed.js"));
const ShareModal = React.lazy(() => import("./ShareModal.js"));

let alreadyClose = false;
function ContentComponent(props) {
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep].content;
  return <div></div>;
}

const Main = observer((props) => {
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const RouteStore = useContext(RouteStoreContext);
  const ShareStore = useContext(ShareStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isTourClose, setisTourClose] = useState(false);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // useEffect(() => {
  //   document.body.classList.add("bg-main");

  //   return () => {
  //     document.body.classList.remove("bg-main");
  //   };
  // }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [steps, setSteps] = useState([
    {
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 18,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Welcome to flowroom
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            If this is your first time, you may want to take this Walkthrough
            tour! If at any point you don't want to continue this tour, select
            "Disable walkthroughs". You will be able to turn it back on via
            settings.
          </p>
          <div
            onClick={() => {
              setStepIndex(1);
              document.getElementsByTagName("html")[0].style.overflow =
                "hidden";
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 20,
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 10,
                fontSize: 17,
              }}
            />
            <p style={{ color: "#222", fontFamily: "quicksand", fontSize: 14 }}>
              Got it
            </p>
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
                marginRight: 5.5,
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      spotlightClicks: true,
      placement: "center",
      target: "#flow-container",
      continuous: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".create-button-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(2);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".your-profile-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(3);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".messages-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(4);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".notifications-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(5);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".help-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(6);
              document.getElementsByTagName("html")[0].style.overflowY =
                "hidden";
              document.getElementsByTagName("body")[0].style.overflowY =
                "hidden";
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
    {
      target: ".nav-wrap-step",
      content: (
        <div
          style={{
            height: "calc(100vh / 5.2)",
            width: "100%",
            maxWidth: 278,
            maxHeight: "300px",
          }}
        >
          <p
            style={{
              color: "#222",
              fontFamily: "quicksand",
              fontSize: 20,
              position: "absolute",
              left: 18,
              top: 7,
            }}
          >
            Walkthrough title
          </p>
          <br />
          <p
            style={{
              color: "#222",
              textAlign: "left",
              fontSize: 12,
              fontFamily: "quicksand",
              fontWeight: "bold",
              maxWidth: 250,
              width: "100%",
              left: 20,
              position: "absolute",
            }}
          >
            Lorem ipsum dolor sit amet, consecteur adispiscing elit. Fusce
            rutrum convballis fringilla aliquet viverra. Odio gravida sit velit
            vel sed.
          </p>
          <div
            onClick={() => {
              setStepIndex(7);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginRight: 3,
              }}
            >
              Next
            </p>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#222"
              size={"4x"}
              style={{
                display: "block",
                position: "relative",
                right: 0,
                fontSize: 14,
              }}
            />
          </div>
          <div
            onClick={() => {
              WidgetStore.setDisableWalkThrough(true);
            }}
            style={{
              display: "flex",
              top: 90,
              alignItems: "center",
              position: "relative",
              left: 10,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              X
            </p>
            <p
              style={{
                color: "#222",
                fontFamily: "quicksand",
                fontSize: 14,
                marginLeft: 5.5,
              }}
            >
              Disable walkthroughs
            </p>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      disableScrollParentFix: true,
      scrollOffset: 1,
      styles: {
        options: {
          zIndex: 100000,
          padding: 0,
          borderRadius: 20,
        },
      },
    },
  ]);

  const history = useTransitionHistory();

  // The following tells react-router-transition that whenever the user
  // navigates to '/', call the first function (start the first animation),
  // and whenever the user leaves '/' call the second function *before* pushing
  // the new path to the react-router history.
  //

  const [finishStatus, setfinishStatus] = useState(false);
  let handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      stepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  useEffect(() => {
    // window.history.pushState(null, null, window.location.pathname);
    // window.addEventListener("popstate", onBackButtonEvent);
    // return () => {
    //   window.removeEventListener("popstate", onBackButtonEvent);
    // };
    RouteStore.setLastPage(window.location.pathname);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  return (
      <React.Fragment>
        <div data-home-main key={"data-home-main_" + Math.random()}>
        {/* <Header/> */}

          <div
            key={"div" + Math.random()}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              background: "transparent",
              position: "relative",
              margin: "auto",
            }}
            className="main-page-padding"
          >

            <div id="feed-space"></div>
            <ReactWindowPagination key={Math.random()} />

         
            {/* <ReactModal
              ariaHideApp={false}
              closeTimeoutMS={500}
              isOpen={AuthenticationStore.showModal}
              style={{
                overlay: {
                  padding: 0,
                  zIndex: 1000000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(0, 0, 0, 0.8)",
                },
                content: {
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  border: "0px solid rgb(64, 255, 232)",
                  backgroundColor: "#3F3F3E",
                  overflow: "hidden",
                  borderRadius: "15px",
                  outline: "none",
                  padding: "0px",
                  zIndex: 1000000,
                  margin: "auto",
                  width: "calc(100% / 1.1)",
                  maxWidth: 570,
                  // maxHeight: WidgetStore.currentModalHeight,
                },
              }}
            >
              <div
                onClick={() => {
                  AuthenticationStore.setShowModal(false);
                  RouteStore.setCurrent("");
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: 10,
                  cursor: "pointer",
                  zIndex: 1,
                }}
              >
       
                    <img src="../images/close-circle.svg"   style={{
                  display: "block",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}/>
              </div>

             <LoginM /> 
            </ReactModal> */}
            <ShareModal />
         
          </div>
          <style jsx>
        {`
        #profile-wrap {
          height:35px;
          width:35px;
        }
        .main-page-padding {
          padding-left:10px;
          padding-right:10px;
        }

        /* New media query for mobile resolution */
        @media (max-width: 768px) {
          .main-page-padding {
            padding-left:0px !important;
            padding-right:0px !important;
          }

          #profile-wrap {
            height:24px;
            width:24px;
          }
      
        
        }
        
        `}
      </style>
        </div>
      </React.Fragment>
  );
});

export default React.memo(Main);
