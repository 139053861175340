// src/Components/Home/AddAdmin.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

const AddAdmin = () => {
  const db = firebase.firestore();

  // State Variables
  const [usernameInput, setUsernameInput] = useState('');
  const [admins, setAdmins] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [processingAdminId, setProcessingAdminId] = useState(null); // To track which admin is being processed

  // Fetch Admins
  useEffect(() => {
    console.log("Fetching admins...");
    const unsubscribe = db.collection('admin').onSnapshot(snapshot => {
      const fetchedAdmins = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Fetched admins:", fetchedAdmins); // Debugging
      setAdmins(fetchedAdmins);
    }, err => {
      console.error("Error fetching admins:", err);
      setError('Failed to fetch admins.');
    });

    return () => unsubscribe();
  }, [db]);

  // Handler to Add Admin
  const handleAddAdmin = async () => {
    setMessage('');
    setError('');

    // Trim and lowercase the input
    const trimmedUsername = usernameInput.trim().toLowerCase();

    // Validation: No spaces or symbols, only alphanumeric
    const usernameRegex = /^[a-z0-9]+$/;
    if (!usernameRegex.test(trimmedUsername)) {
      setError('Username must be lowercase and contain no spaces or symbols.');
      return;
    }

    if (trimmedUsername === '') {
      setError('Username cannot be empty.');
      return;
    }

    try {
      // Check if user exists in 'users' collection
      const userSnapshot = await db.collection('users')
        .where('username', '==', trimmedUsername)
        .limit(1)
        .get();

      if (userSnapshot.empty) {
        setError('This user does not exist.');
        return;
      }

      // Check if user is already an admin
      const adminDoc = await db.collection('admin').doc(trimmedUsername).get();
      if (adminDoc.exists) {
        setError('This user is already an admin.');
        return;
      }

      // Add user to 'admin' collection
      await db.collection('admin').doc(trimmedUsername).set({
        isAdminLoggedIn: true,
        username: trimmedUsername,
      });

      setMessage(`User "${trimmedUsername}" has been added as an admin.`);
      setUsernameInput('');
    } catch (err) {
      console.error("Error adding admin:", err);
      setError('Failed to add admin. Please try again.');
    }
  };

  // Handler to Remove Admin
  const handleRemoveAdmin = async (adminId) => {
    setMessage('');
    setError('');
    setProcessingAdminId(adminId);

    try {
      await db.collection('admin').doc(adminId).delete();
      setMessage(`Admin "${adminId}" has been removed.`);
    } catch (err) {
      console.error("Error removing admin:", err);
      setError('Failed to remove admin. Please try again.');
    } finally {
      setProcessingAdminId(null);
    }
  };

  // Row Component for Admin List
  const Row = React.memo(({ index, style }) => {
    const admin = admins[index];
    const isProcessing = processingAdminId === admin.id;

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <span style={styles.adminName}>{admin.username}</span>
        <button
          onClick={() => handleRemoveAdmin(admin.id)}
          style={{ ...styles.button, ...styles.removeButton }}
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Remove'}
        </button>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2>Manage Admins</h2>
      
      {/* Add Admin Form */}
      <div style={styles.form}>
        <input
          type="text"
          placeholder="Enter username"
          value={usernameInput}
          onChange={(e) => setUsernameInput(e.target.value)}
          style={styles.input}
        />
        <button
          onClick={handleAddAdmin}
          style={styles.addButton}
          disabled={usernameInput.trim() === ''}
        >
          Add Admin
        </button>
      </div>

      {/* Messages */}
      {message && <p style={styles.message}>{message}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}

      {/* Admins List */}
      <h3>Current Admins</h3>
      {admins.length === 0 ? (
        <p>No admins found.</p>
      ) : (
        <List
          height={300}
          itemCount={admins.length}
          itemSize={50}
          width={'100%'}
        >
          {Row}
        </List>
      )}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    gap: '10px',
  },
  input: {
    flex: '1',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  addButton: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28A745',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '5px',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
  },
  adminName: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
  removeButton: {
    backgroundColor: '#DC3545',
    color: '#fff',
  },
};

export default AddAdmin;
