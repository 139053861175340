import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../../store/EditorStore";

let ResponsiveTooltip = observer(() => {
  const [showTooltip, setShowTooltip] = useState(false);
  const EditorStore = useContext(EditorStoreContext);

  useEffect(() => {
    // Check localStorage when component mounts
    const tooltipShown = localStorage.getItem('tooltipShown');
    if (!tooltipShown) {
      setShowTooltip(true); // Show tooltip if flag not set
    }
  }, []);

  const hideTooltip = () => {
    localStorage.setItem('tooltipShown', 'true'); // Set flag in localStorage
    setShowTooltip(false); // Hide tooltip
    EditorStore.setShowTooltip(false);
  };

  const tooltipStyle = {
    width: "100%",
    paddingTop: "0%",
    backgroundImage: "url(../tool-tip.png)",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "62px",
    maxWidth: 190,
    right: 35,
    height: 127,
    zIndex:1000000
  };

  const contentStyle = {
    position: "absolute",
    top: "10%",
    left: "10%",
    right: "10%",
    bottom: "10%",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
  };

  // Only render the tooltip if showTooltip is true
  return showTooltip ? (
    <div style={tooltipStyle}>
      <div style={contentStyle}>
        <p style={{ fontSize: 12, color: "#4FD975", fontWeight: "bold", fontFamily: "quicksand", marginTop: 5 }}>
          You're not signed in
        </p>
        <p style={{ color: "white", fontSize: 9, fontFamily: "quicksand", marginTop: 4 }}>
          Sign in or sign up to Flowroom to like, comment, remix and more!
        </p>
        <div onClick={hideTooltip} style={{ backgroundColor: "#4FD975", height: 27, width: 57, borderRadius: 20, marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
          <p style={{ fontSize: 10, color: "#000", display: "flex", justifyContent: "center", alignItems: "center" }}>
            Got it
          </p>
        </div>
      </div>
    </div>
  ) : null;
});

export default ResponsiveTooltip;
