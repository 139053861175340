import React from "react";

const SquareCheckbox = ({
  onToggle,
  defaultValue,
  width = "20px",
  height = "20px",
  isChecked, // We're expecting this prop now
}) => {
  return (
    <img
      style={{
        display: "block",
        cursor: "pointer",
        zIndex: 1000,
        width: width,
        height: height,
        marginRight: 10,
      }}
      onClick={onToggle}
      src={
        isChecked // Use the prop directly
          ? "/images/check-select.svg"
          : "/images/check-unselect.svg"
      }
      alt={isChecked ? "Checked" : "Unchecked"}
    />
  );
};

export default SquareCheckbox;
