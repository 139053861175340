import React, { useState, useEffect } from 'react';

const EventModal = ({ isOpen, onClose, onSave, onDelete, event, currentUser }) => {
    const [title, setTitle] = useState('');
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());    
    const [error, setError] = useState('');
    const [flowId, setFlowId] = useState('');
    
    useEffect(() => {
        if (event) {
            setTitle(event.title || '');
            setStart(new Date(event.start) || new Date());
            setEnd(new Date(event.end) || new Date());
        } else {
            // Reset form for new events
            setTitle('');
            setStart(new Date());
            setEnd(new Date());
        }
    }, [event]);

    // Determine whether the user can edit the event
    const canEdit = !event || !event.createdBy || event.createdBy === currentUser.uid;

    const handleSubmit = () => {
        if (!title || !start || !end) {
            setError('All fields are required.');
            return;
        }
        onSave({ title, start: new Date(start), end: new Date(end), id: event ? event.id : null, flowId: flowId });
    };

    const handleDelete = () => {
        if (canEdit && event && event.id) {
            onDelete(event.id);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal" style={{position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#1c1c1c',
            zIndex: 1000, flexDirection:'column'}}>
            {canEdit ? (<div className="modal-content" style={{display: 'flex',
    flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                <h2 style={{fontFamily:'quicksand', color:'white'}}>{event ? 'Edit Event' : 'Create New Event'}</h2>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <input
                    type="text"
                    placeholder="Event Title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    disabled={!canEdit}
                    style={{    background: '#2C2C2C',
                        border: 0,
                        height: 40,
                        width: '100%',
                        color: 'white',
                        paddingLeft: 10,
                        margin: 10,borderRadius: 5,
                        outline: 'none', marginBottom:0}}
                />
                  <input
                    type="text"
                    placeholder="flow link"
                    value={flowId}
                    onChange={e => setFlowId(e.target.value)}
                    disabled={!canEdit}
                    style={{    background: '#2C2C2C',
                        border: 0,
                        height: 40,
                        width: '100%',
                        color: 'white',
                        paddingLeft: 10,
                        margin: 10,borderRadius: 5,
                        outline: 'none', marginBottom:10}}
                />
                <input
                    type="datetime-local"
                    value={start.toISOString().slice(0, 16)}
                    onChange={e => setStart(new Date(e.target.value))}
                    disabled={!canEdit}
                    style={{fontFamily: 'Quicksand',
                    height: 50,
                    width: '100%',
                    outline: 'none',
                    padding: '10px',
                    fontWeight: 'bold', marginBottom:10, borderRadius:5}}
                />
                <input
                    type="datetime-local"
                    value={end.toISOString().slice(0, 16)}
                    onChange={e => setEnd(new Date(e.target.value))}
                    disabled={!canEdit}
                    style={{fontFamily: 'Quicksand',
                    height: 50,
                    width: '100%',
                    outline: 'none',
                    padding: '10px',
                    fontWeight: 'bold', borderRadius:5}}
                />
                <div onClick={handleSubmit} disabled={!canEdit} style={{ height:40, width:100, backgroundColor:'#37D262',  display:'flex', alignItems:'center', justifyContent:'center', width:'100%', borderRadius:5, marginTop: 10,
    marginBottom:10, cursor:'pointer' }}>
                    <p style={{fontSize:14, fontFamily:'quicksand'}}>{event ? 'Update Event' : 'Create Event'}</p>
                </div>
                {event && canEdit && (
                    <div onClick={handleDelete} style={{ height:40, width:100, backgroundColor:'#37D262', borderRadius:5, display:'flex', alignItems:'center', justifyContent:'center', width:'100%', marginTop: 0,
                    marginBottom: 0, cursor:'pointer' }}>
                    <p style={{fontSize:14, fontFamily:'quicksand'}}>Delete Event</p>
                    </div>
                )}
                 <div className="close" onClick={onClose} style={{height:40, width:100, backgroundColor:'#37D262', borderRadius:5, display:'flex', alignItems:'center', justifyContent:'center', width:'100%', marginTop: 10,
    marginBottom: 10, cursor:'pointer'}}>
                    <p style={{fontSize:14, fontFamily:'quicksand'}}>Go back</p>
            </div>
            </div>):(<div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}><img src="../images/warning.svg" style={{height:70, width:70, marginBottom:20}}/><p style={{fontFamily:'quicksand', color:'white', textAlign:'center', marginBottom:20}}>The date, time, and or frequency is not available.</p> <div onClick={onClose} style={{ height:40, maxWidth:'50%', width:100, backgroundColor:'#37D262', borderRadius:5, display:'flex', alignItems:'center', justifyContent:'center', width:'100%', marginTop: 0,
                    marginBottom: 0, cursor:'pointer' }}>
                    <p style={{fontSize:14, fontFamily:'quicksand'}}>go back</p>
                    </div></div>)}
           
        </div>
    );
};

export default EventModal;
