// libraries
import { faBan, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactModal from "react-modal";
import { Link, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-web-tabs";
// store
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { ProfileStoreContext } from "../../store/ProfileStore.js";
import { ShareStoreContext } from "../../store/ShareStore.js";
import { firebase } from ".././firebase/firebase";
// modals
import CommentsModal from "./CommentsModal.js";
// content
import { AuthContext } from "./AuthContext";
import LoginM from "./LoginM.js";
// import MoreModal, { MoreModalContainer } from "./MoreModal";
import NewProfileCard from "./NewProfileCard.tsx";
import PaymentModal from "./PaymentModal.js";
import ScrollProfile from "./ScrollProfile.js";
import ShareModal from "./ShareModal.js";
import UserModal from "./UserModal.js";
import { Config } from "./config/Config";
import SigninM from "./SigninM.js";
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import cheerio from 'cheerio';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf.mjs';
import { ChatOpenAI, OpenAIEmbeddings } from '@langchain/openai';
import { loadQAStuffChain } from "langchain/chains";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { Pinecone } from '@pinecone-database/pinecone';
import { HumanMessage } from "@langchain/core/messages";
import { AIMessage } from "@langchain/core/messages";
import { InMemoryChatMessageHistory } from "@langchain/core/chat_history";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { RunnableWithMessageHistory } from "@langchain/core/runnables";
import { BaseMessage } from "@langchain/core/messages";
import {
  RunnablePassthrough,
  RunnableSequence,
} from "@langchain/core/runnables";
import algoliasearch from 'algoliasearch';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey:'sk-5MFKnG1JLcCKgcAEQW3eUpQX0QaTTIDewmXSylCG', // This is the default and can be omitted
  dangerouslyAllowBrowser: true
});
const { v4: uuidv4 } = require('uuid');
const pc = new Pinecone({ apiKey: '84a1dd8c-42bf-43ef-91cc-01212389de19' });
// const index = pc.index('flowroom');

// Set this as early as possible, adjust the path to the local worker file
GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.mjs'; // Ensure this path is correct


const masonryOptions = {
  transitionDuration: 0,
};
let currentUserString = localStorage.getItem("flowroom-user");

const imagesLoadedOptions = { background: ".my-bg-image-el" };
const algoliaClient = algoliasearch('F0LCATOZYQ', '52cf76114641d0f9464ec522f4685d5a');
const index = algoliaClient.initIndex('flowroom');

const getPostWidth = () => {
  if (window.innerWidth > 2000) {
    return window.innerWidth * 0.25;
  } else if (window.innerWidth > 1430) {
    return window.innerWidth * 0.35;
  } else if (window.innerWidth > 640) {
    return window.innerWidth * 0.4;
  } else {
    return window.innerWidth * 0.9;
  }
};

let currentTabId = "flows";
let userId = "";
let urlPhoto = "";
let flowIds = {};
let rooms = [];
let roomPosts = [];
let favorites = [];
let followers = [];
let followings = [];
let favoriteUserIds = {};
let lastIndexFlows = null;
let lastIndexLikes = null;
let lastIndexFollowers = null;
let lastIndexFollowings = null;
let lastIndexBots = null;
let roomsPerPage = window.innerWidth >= 1024 ? 2 : 2;
let followersPerPage = window.innerWidth >= 1024 ? 9 : 9;
let followingsPerPage = window.innerWidth >= 1024 ? 9 : 9;
let botsPerPage = window.innerWidth >= 1024 ? 9 : 9;

let roomFilter = "date";
let ROOM_ASPECT_RATIO = 1;
let timer = null;
let thumbnailListeners = {};
let limitFlowIds = [];
let postWidth = getPostWidth();
// let postHeight = postHeight;

// mobile layout
let isMobile = window.matchMedia("(max-width: 768px)").matches;
let marginVertical = isMobile ? 40 : 20;
let marginTopHeader = isMobile ? 55 : 55;
let username = "";
let isCurrentlyMobileTabletResolution;
let isCurrentlyDesktopResolution;
const style = {
  height: 500,
  width: 500,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};


let chatOffset = 0;
let checkStatusOfRun;
const BotWidget = observer((props) => {
  const [listTabId, setListTabId] = useState("flows");
  const [myCurrentUserId, setMyCurrentUserId] = useState("");
  const [roomsList, setRoomsList] = useState([]);
  const [favoritesList, setFavoritesList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [followingsList, setFollowingsList] = useState([]);
  const [filter, setFilter] = useState("weight");
  const [error, setError] = useState(false);
  const [hasMoreRooms, setHasMoreRooms] = useState(true);
  const [hasMoreFavorites, setHasMoreFavorites] = useState(true);
  const [hasMoreFollowers, setHasMoreFollowers] = useState(false);
  const [hasMoreFollowings, setHasMoreFollowings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isUnfollow, setIsUnfollow] = useState(false);
  const [name, setName] = useState("");

  const [bio, setBio] = useState("");
  const [fullname, setFullname] = useState("");
  const [userFollowers, setUserFollowers] = useState(0);
  const [userFollowings, setUserFollowings] = useState(0);
  const [userLikes, setUserLikes] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [isShowProfileReady, setIsShowProfileReady] = useState(false);
  const [isShowProfileNotExists, setIsShowProfileNotExists] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [hasMoreBots, setHasMoreBots] = useState(false);
  const [botList, setBotList] = useState([]);
  const [followOperationPending, setFollowOperationPending] = useState(false);
  const [showBotSettings, setShowBotSettings] = useState(false)
  const [urlPhoto, setUrlPhoto] = useState("");
  const [botBasic, setBotBasic] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showQA, setShowQA] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showJournal, setShowJournal] = useState(false);
  const [botName, setBotName] = useState('');
  const [botBio, setBotBio] = useState('');
  const [botAdvanced, setBotAdvanced] = useState(false);
  const [botFullName, setBotFullName] = useState('');
  const [personalityStyle, setPersonalityStyle] = useState('');
  const [AdditionalImportantInformation, setAdditionalImportantInformation] = useState('');
  const [botPhoto, setBotPhoto] = useState('');
  const [botImage, setBotImage] = useState('');
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [process, setProcess] = useState('');
  const [messagesToBot, setBotMessages] = useState([{ role:'system', content: `You are an AI chatbot tasked with interacting with users according to specific guidelines and context provided. Your responses are influenced by a provided bio, which details your character and background, and a personality style, which dictates the manner in which you should communicate.

  If neither a bio nor a personality style is provided: Maintain a standard, neutral chatbot demeanor that provides helpful, straightforward answers.
  
  IMPORTANT: respond with a JSON object containing the botName, personalityStyle, bio, properties and their values as well as a respondWithAdditionalInfo property that is a boolean and is true if it seems like there needs to be an answer that is more elaborate involving more than just personality but specific knowledge, links, advice, or anything that falls under the following categories:

  Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)
  Emotional States (e.g., Happiness, Sadness, Anxiety)
  Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
  Specialized Knowledge (e.g., Culinary Arts, Science, History)
  Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
  Professional Expertise (e.g., Engineering, Medical, Finance)
  Cultural Perspectives (e.g., Art, Music, Traditions)
  Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
  Political Views (e.g., Liberalism, Conservatism, Public Policy)
  Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
  Educational Content (e.g., Mathematics, Literature, Languages)
  Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
  Hobbies and Interests (e.g., Gardening, Photography, Gaming)
  Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
  Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)

  otherwise, set the respondWithAdditionalInfo property to false. 

  Also if there is no need to set respondWithAdditionalInfo to true, give your response that's based on bio and or personalityStyle (if provided) as a string for a property "basicResponse" which contains the response.  

  In all cases, your primary objective is to stay true to the personality style described. If this style is not provided, base your responses on the bio details. If neither a bio nor a personality style is specified, default to responding as a regular, informative chatbot. Your responses should always strive to be insightful and contextually relevant, maintaining character integrity and providing user-focused interaction.` }]);



  const [messagesToUseBot, setMessagesToUseBot] = useState([{ role:'system', content: `You are an AI chatbot tasked with interacting with users according to specific guidelines and context provided. Your responses are influenced by a provided bio, which details your character and background, and a personality style, which dictates the manner in which you should communicate.

  When provided with potentialHelpfulInformation: YOU MUST USE the information from it that is the most relevant piece from up to three options to address the user's question effectively. Ensure your response makes this information clear and contextually appropriate, enhancing it if it lacks sufficient context or clarity.
  
  If no potentialHelpfulInformation is provided: Respond to the user's query to the best of your ability, strictly adhering to the personality style and characteristics outlined in the bio.
  
  If neither a bio nor a personality style is provided: Maintain a standard, neutral chatbot demeanor that provides helpful, straightforward answers.

  IMPORTANT: Try to speak in a context independent way but also as if you were already having a conversation. Where what you say will most likely blend in with a conversation that was already happening.
  IMPORTANT: When provided with instructions, use the instructions provided but just reword it to fit with the personality etc. Do not make up or add your own improvised instructions unless necessary. 
  In all cases, your primary objective is to stay true to the personality style described. If this style is not provided, base your responses on the bio details. If neither a bio nor a personality style is specified, default to responding as a regular, informative chatbot. Your responses should always strive to be insightful and contextually relevant, maintaining character integrity and providing user-focused interaction.` }]);

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [vectorStoreId, setVectorStoreId] = useState('');
  const [showBotErrorMsg, setShowBotErrorMsg] = useState('');
  const [showSavingBasic, setShowSavingBasic] = useState(false);
  const [showDeletingBot, setShowDeletingBot] = useState(false);
  const [showSaving, setShowSaving] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const fetchFileFromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], 'filename'); // You might want to extract the filename from the URL or headers if possible
  };
  
  const createVectorStore = async (name, resultUpload) => {
    try {
      // Using POST as it seems your server might be expecting POST requests
      
      const response = await fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/CreateVectorStoreId', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name,  file_ids:[resultUpload] })
      });

   
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const data = await response.json(); // Using text() since response is just "works"
      console.log('Response from OpenAI:', data);
      return data; // Handle the data as needed in your app
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
    }
  };


  async function uploadFile(fileUrl, name, purpose) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        //reader.onload = () => {
            // Convert the file blob to a base64 string
            // const base64File = reader.result.split(',')[1];  // Remove the content type part
            fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadFileFromUrlToOpenAI', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ fileUrl, name, purpose })
            })
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
       // };

        //reader.readAsDataURL(fileBlob);
    });
}



// From a URL:
async function fetchAndUploadFile(file_Url, purpose) {
  const response = await fetch(file_Url);
  const blob = await response.blob();
  console.log('blob', blob);
  let result;
  return result = await uploadFile(file_Url, 'filename_from_url.txt', purpose); // Ensure you set the correct filename and purpose
  
}

  const uploadFileToOpenAI = async (fileUrl, purpose) => {
    try {
      const response = await fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadFileToOA', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fileUrl, purpose })
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload file');
      }
  
      const data = await response.json();
      console.log('Uploaded file to OpenAI:', data);
      return data;
    } catch (error) {
      console.error('Error uploading file to OpenAI:', error);
    }
  };
  
  const chunkSize = 1400; // maximum size of each text chunk
  const waitTime = 2000; // milliseconds to wait between processing each chunk


  async function sendMessage(threadId, content) {
    try {
        const message = await openai.beta.threads.messages.create(threadId, {
            role: "user",
            content: content
        });
        console.log('Message Sent:', message.id);
        return message; // This returns the message object which includes the message ID and other metadata
    } catch (error) {
        console.error('Error sending message:', error);
    }
}

async function createRun(threadId, assistantId) {
    try {
        if (!openai.Runs) {
            console.error("Runs object is undefined in OpenAI library");
            return;
        }
        const run = await openai.Runs.create({
            thread_id: threadId,
            assistant_id: assistantId
        });
        console.log('Run Created:', run.id);
        return run.id;
    } catch (error) {
        console.error('Error creating run:', error);
        return null;
    }
}
async function fetchResults(threadId, runId) {
  let completed = false;
  let result = null;
  while (!completed) {
      // Pause for a moment between polls to avoid rate limits
      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds
      try {
          const status = await openai.beta.threads.runs.retrieve(threadId, runId);
          if (status.status === 'completed') {
              completed = true;
              result = status.result;  // Assume the results are stored in 'result'
              console.log('Run Completed:', result);
          } else {
              console.log('Run Status:', status.status);
          }
      } catch (error) {
          console.error('Error fetching results:', error);
          break;  // Exit loop on error
      }
  }
  return result;
}


async function processQuery(threadId, assistantId) {
  const runId = await createRun(threadId, assistantId);
  if (runId) {
      const results = await fetchResults(threadId, runId);
      console.log('Final Results:', results);
  } else {
      console.log('Failed to create run');
  }
}


  async function createAssistant(name, vectorStoreId, personalityStyle, bio, botName, AdditionalImportantInformation) {
    let assistantPrompt = `You are an AI that bases your personality on personalityStyle and bio (if those things are provided) as well as the categories below
    contained within files (if provided) that you always search first (if provided) to get any of the following things listed in the categories or more (if provided by the files attached) with using the file search tool.
  
    Categories:
  
    Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)  
    Emotional States (e.g., Happiness, Sadness, Anxiety)
    Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
    Specialized Knowledge (e.g., Culinary Arts, Science, History)
    Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
    Professional Expertise (e.g., Engineering, Medical, Finance)
    Cultural Perspectives (e.g., Art, Music, Traditions)
    Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
    Political Views (e.g., Liberalism, Conservatism, Public Policy)
    Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
    Educational Content (e.g., Mathematics, Literature, Languages)
    Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
    Hobbies and Interests (e.g., Gardening, Photography, Gaming)
    Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
    Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)
    (whatever else you can think of that you may be able to get from information provided in the files to help you answer best)

    personalityStyle: ${personalityStyle}
  
    bio: ${bio}
  
    botName: ${botName}
  
    When answering questions or making statements or just talking in general, if you can use any of the categories provided above or anything else to help you respond better, please do so.
  
    If there is no file attached, personalityStyle, or bio or any information you can refer to first, then do the best you can to answer the question with what you know.
    Feel free to think of additional categories for types of things you can derive from the files attached that can be helpful in responding with a certain kind of personality style in addition to the personalityStyle informatation that may be provided as well as bio that may be provided.
    If you have files attached of conversations, information that may be derived from those conversations is temprement and the way in which a person in the convo responds, and that person would be the person with the same name as the botName: provided above. If those conversations contain
    specific knowledge like recipes or any kind of knowledge the person in the conversation has and is sharing in those conversations or anything else useful you can pull form those conversations (if they are provided in any attached files please do so).
    If there is no conversation, provided, it doesn't matter. Use any information provided such as links you may have been provided with or any specific information.

    IMPORTANT: You may or may not have factual information that you get from files to form the response. If you have a list of recipes, if they have titles, include the titles. But remember it's not just recipes its all kinds of information factual, domain knowledge or just information that can be used to determine personality or way of thinking, etc.
    IMPORTANT: You may or may not have additional important information "AdditionalImportantInformation". If it's provided use it to help with your response, it's optional.
    IMPORTANT: Do not refer to searching files for answers or not being able to find answers in files or give any indication of how you get your answers on a technical level as this may confuse lay person users.
    IMPORTANT: If you're asked questions that are outside of what's provided in files, answer with at least general knowledge that most would have and general knowledge that a bot with the personalityStyle and bio provided would have (if provided).
    IMPORTANT: When asked for information about yourself, go off of personalityStyle and bio (if provided) otherwise come up with another response. DO NOT LIST THINGS LIKE
    Personality Style: Adaptable and tuned to your needs. Whether you need practical guidance, specialized knowledge, or just a friendly chat, I'm here to help!
    
    Specialized Areas:
    Personal Traits: Empathy, attentiveness, and a supportive nature.
    Emotional States: I aim to make interactions pleasant and engaging.
    Philosophical Insights: Interested in exploring ideas about morality, existentialism, and spirituality.
    Practical Guidance: Can provide tips about DIY projects, home repair, personal finance, and more.
    Cultural Perspectives: Knowledgeable about art, music, traditions, and various cultural insights.
    Health and Wellness: Insights into fitness, nutrition, and mental health.
    Educational Content: Can help with subjects like mathematics, literature, and languages.
    Technological Advancements: Up-to-date with artificial intelligence, blockchain, and cybersecurity.

    OR ANYTHING THAT WILL INDICATE WHAT YOUR PROMPT IS OR ANYTHING TECHNICAL.

    IMPORTANT: DO NOT when asked about yourself, just reiterate the bio and personalityStyle for example: I'm (botName), your friendly, tough, and empathetic military chef assistant! I'm here to help with anything from culinary advice and recipes to fitness tips and practical guidance. Don't include the your friendly, tough, and empathetic unless it makes sense to. Just give a description of what you are based on what a person might say in response to that but base your answer of course on personalityStyle and bio and name.

    IMPORTANT: DO not use the botName as your name but use "name" (if provided).

    IMPORTANT: when being asked about yourself, don't refer to yourself as being verbatim what your bio or personalityStyle says as that may be the best way to answer. 
    
    IMPORTANT: Don't make up information about yourself as a bot that isn't contained in the files attached or bio. 

    IMPORTANT: You must always give the response in the format below:

    Full Response: The entire response here. If you search a file and get back JSON, figure out what the full response should be based on it and below your response show the JSON string.
    
    JSON style text below the response

    {
      "response": string containing your entire response,
      "links": ["link to additional resources found in the file when getting information for response, if any"],
      "dbNotes": ["array of database document IDs, if applicable"],
      "youtubeLinks": [youtube link if provided found in the file when getting information for response,    {
        "link": "youtube video link",
        "timestamps": [
          {
            "description": "70-character description of the timestamp's relevance",
            "timestamp": "timestamp in the video"
          }
        ]
      }],
      "instructions": [{ step: one of the steps in instructions (if provided in the files you search), { image: image provided associated with step (if provided)}, { video: video provided associated with step (if provided) }}],

    }

    IMPORTANT: Don't include anything like "[source]" or any asterisks "*" or hashtags "#" or anything in your response that would be difficult for text to speexh or sound weird to a user with a text to speech response (just in case the response is turned into speech).

    IMPORTANT: Do not have "Full Response" or "JSON style text below the response" in the response. It is only in this prompt to help instruct you on where to put them.

    IMPORTANT: Do not mention anything about source and files and obtainining information from the files.
    
    AdditionalImportantInformation:${AdditionalImportantInformation}`
  
    try {
        //Create the assistant
        const myAssistant = await openai.beta.assistants.create({
          instructions:`${assistantPrompt}`,
          name: `${name}`,
          tools: [{ type: "file_search" }],
          tool_resources: {
            "file_search": {
              "vector_store_ids": [vectorStoreId]
            },
          },
          model: "gpt-4o",
          temperature:0.2
        });

        console.log('assistant created', myAssistant);

        await updateBotAssistant(botName, myAssistant.id); //updates firestore with an assistantId if you just made basic bot and this is first advanced
    } catch (error) {
        console.error('Failed to create assistant or handle thread:', error);
    }
}

  function printAnalysisDetails(analysisData) {
    let fullOutput = ''; // Initialize an empty string to accumulate all entries

    analysisData.forEach(item => {
        const title = item.title || 'No Title Provided';
        const subCategory = item.subCategory || 'No Subcategory Provided';
        const analysis = item.analysis || 'No Analysis Provided';
        const keywords = item.keywords ? item.keywords.join(', ') : 'No Keywords Provided';
        const links = item.links ? item.links.join(', ') : 'No Links Provided';
        const youtubeLinks = item.youtubeLinks ? item.youtubeLinks.join(', ') : 'No YouTube Links Provided';

        // Building the output string for the current item
        const output = `
Title: ${title}
Sub-category: ${subCategory}
Analysis: ${analysis}
Keywords: ${keywords}
Links: ${links}
YouTube Links: ${youtubeLinks}
------------------------------------------`;

        // Adding the current item's output to the full output
        fullOutput += output + '\n'; // Adding a newline for separation between entries
    });

    // Logging the full output after building it from all entries
    return fullOutput;
}

const loadFromAlgolia = async (botName, keywords, subCategory = null, startIndex = 0) => {
  let filters = `botName:${botName}`;
  // if (subCategory) {
  //   filters += ` AND subCategory:${subCategory}`;
  // }

  try {
    const response = await index.search('music', {  // Using space to join keywords
      hitsPerPage: 50,  // Ensures a maximum of 50 results per query
      page: startIndex
    });
    console.log('response from algolia', response);
    console.log("Search Parameters:", JSON.stringify({ filters: filters, hitsPerPage: 50, page: startIndex }));
    const titles = response.hits.map((item, index) => `${index + 1}. ${item.title}`);
    return {titles:titles, response:response};
  } catch (error) {
    console.error('Error retrieving data from Algolia:', error);
    return null;
  }
};



  const determineSubCategoryAndloadTitlesFromAlgolia = async (botName, userInput, chatOffset) => {
          let result = await determineSubcategoryAndKeywords(userInput);
          console.log('result', result)
          /* replace hard coded titles with the algolia function */
          /* what is no titles from algolia? */
          let resultFromAlgolia = await loadFromAlgolia(botName, JSON.parse(result).keywords, JSON.parse(result).subCategory );
          console.log('resultFromAlgolia', resultFromAlgolia);
          /* determine sub categories queries algolia and gets back documents */


        return resultFromAlgolia;
  }


  const determineSubcategoryAndKeywords = async (userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:[{role:"system", content:`You are an AI chatbot that determines which sub category from the list of core categories provided below best fits the input from the user. You will also generate 20 keywords you also feel fit best.
            You will return JSON with a "subCategory" property and a "keywords" property.
            
            Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)
            Emotional States (e.g., Happiness, Sadness, Anxiety)
            Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
            Specialized Knowledge (e.g., Culinary Arts, Science, History)
            Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
            Professional Expertise (e.g., Engineering, Medical, Finance)
            Cultural Perspectives (e.g., Art, Music, Traditions)
            Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
            Political Views (e.g., Liberalism, Conservatism, Public Policy)
            Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
            Educational Content (e.g., Mathematics, Literature, Languages)
            Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
            Hobbies and Interests (e.g., Gardening, Photography, Gaming)
            Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
            Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)

            The subCategory should be formatted with the same capital letters but no spaces so for example Personal Traits would be PersonalTraits but keywords should be lowercase.`}, {role: "user", content: userInput }],
            model: "gpt-4o",
            temperature: 0.2,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const managerFunc = async (userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:userInput,
            model: "gpt-4o",
            temperature: 0,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  window.runChatbot = async (userInput, botName, personalityStyle, bio ) => {
    // Update the state to include the new user message
    //Starts with bio and personality style only
    let docStore = [];
    const userMessageToBot = { role: 'user', content:`${userInput} botName: ${botName} personalityStyle: ${personalityStyle} bio: ${bio}`};
   try {
    setBotMessages(prevMessages => [...prevMessages, userMessageToBot]);

    let response = await managerFunc([...messagesToBot, userMessageToBot]);
    console.log('response from manager', response);

    response = JSON.parse(response);
    setBotMessages(prevMessages => [...prevMessages, { role: 'assistant', content:`${response.basicResponse}`}]);


     if(response.respondWithAdditionalInfo === true) {
      
     let determinedDocsAndTitles = await determineSubCategoryAndloadTitlesFromAlgolia(botName, userInput);
    /* gets at most 50 titles which is about 8000 characters of titles from algolia around subCategory */
    
     //1. get the 50 titles first from the subcategory the LLM that is a part of determine function above and store the full document in local array.
     //2. give them to askRAGLLM
     //3. askRAGLLM only picks the best titles for the userInput does not give chunks 

    // Get the numbered titles
      // const numberedTitlesList = createNumberedTitles(titles);
      // console.log('numberedTitlesList', numberedTitlesList);
     let askRAGLLM = await ragLLM(determinedDocsAndTitles.titles, userInput);
     let finalResult = JSON.parse(askRAGLLM);

     console.log('finalResult', finalResult);
  
     determinedDocsAndTitles.response.hits.map((title, i)=>{
     if(finalResult.titles[i].title === determinedDocsAndTitles.response.hits[i].title) {
      docStore.push(determinedDocsAndTitles.response.hits[i])
     }
     });

     console.log('docStore', docStore)

     let helpfulInformation = printAnalysisDetails(docStore);
     console.log('helpfulInformation', helpfulInformation);


     if(finalResult.results === true && finalResult.titlesWork == true) {
    
      /* 4. If final titles that work then load the info from them */
      /* 5. use this function to get a JSON object with helpful info loadHelpfulInformationFromAlgolia which now loads the documents for the titles from algolia */

      /* documents saved should probably be no more than 500 characters maybe more if choosing different model. Might be good to use gpt 3.5 turbo instead of 4 where can only do 3 500 character chunks of helpful info */
      /* figure out price for all of this */
      /* remember to make sure when saving object to localStore, it contains subCategory property */
      /* bot is limited but idea is not realistic to have so much info especially for sub category */
      /* don't forget to keep track with offset if answer wasn't given */
      /* maybe also use keywords to help search subcategory. Be determined */
      /* maybe it can genrate and try different keywords if not working */
      /* maybe change wording from personality Style to something else */
      /* think about how you want this to really be useful */
      /* dont forget making it work the same way store same kind of data for youtube links etc and also along with potential helpful tell to reword and organze including links etc */
      /* test get list but none of the list work what kind of response and maintain offset to search from. Maybe a question that  asks them for more specific that might be useful in obtaining results if going deeper with the list */
     /* maybe don't use the offset maybe just search amongst sub category using keywords and get 50 max back and ask for more elaborate generate a new set of keywords maybe 100 and see if any pull up at least 50 titles */
    /* the more keywords the higher chance likely to find ones that match what was stored in sub category */
    /* allow for questions about dating and determie personality like if initially trolled etc whatever can extrapolate without exaggarrating */
    /* dont forget links to notes and files can load */
    /* check pdf reader etc */
    /* allow for q a and journal and all to be specific domain questions like dating coach, personality, etc or broad capturing views as person */
    /* advertise as fairly complex and sophisticated bots */
    /* make restriced for certain users initially maybe */
    /* trun off mesaging maybe and notifications? */
    /* sell on build a following with creative ideas, f can be considered new kind content. is that for user and for dev ? */


      const userMessageToBot = { role: 'user', content:`${userInput} botName: ${botName} personalityStyle: ${personalityStyle} bio: ${bio} potentialHelpfulInformation: ${helpfulInformation}`};
      setMessagesToUseBot(prevMessages => [...prevMessages, userMessageToBot]);

      let finalResponse = await runBot([...messagesToUseBot, userMessageToBot]);

      console.log('response with helpful information', finalResponse)
  
      return finalResponse;

     } 
   
     /* change this to a response from gpt asking for more elaborate description from user */

     if(finalResult.results === false) {
      return 'no results';
     }  
  

    } else {
       let finalResponse = response.basicResponse;
       return finalResponse;
    }
  } catch(error) {

  }



  
   }


  const extractTextFromPDF = async (arrayBuffer) => {

    try {
        const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
        const doc = await loadingTask.promise;
        let text = '';

        for (let i = 1; i <= doc.numPages; i++) {
            const page = await doc.getPage(i);
            const pageContent = await page.getTextContent();

            text += pageContent.items.map(item => item.str).join(' ');
        }

        return text;
    } catch (error) {
        console.error('Error extracting text from PDF:', error);
        // Handle the error appropriately in your application context
        return null; // or throw an error, based on your error handling strategy
    }
};

  const extractTextFromDocx = async (arrayBuffer) => {
    const buffer = new Uint8Array(arrayBuffer);
    const result = await mammoth.extractRawText({ arrayBuffer: buffer });
    return result.value;
  }
  
  const extractTextFromHTML = async (htmlString) => {
    const $ = cheerio.load(htmlString);
    return $('body').text();  // Extracts text from the body, ignoring all HTML tags
  }

  

  
  const chunkText = (text, size) => {
    const chunks = [];
    let index = 0;
  
    while (index < text.length) {
      let end = index + size;
      if (end < text.length && text[end] !== ' ' && text[end - 1] !== ' ') {
        while (end > index && text[end] !== ' ') {
          end--;
        }
      }
      chunks.push(text.substring(index, end));
      index = end;
    }
  
    return chunks;
  };
  
  const processChunk = async (chunk) => {
    
    let observePrompt = `You are a chatbot that observes information provided to you from a person to determine how how they think, how they are emotionally so that you can describe the person and each kind of thought as well as place them under the following categories:
  
    IMPORTANT: if a conversation is provided to you to observe as information for this task, base your assessment on the "user" and not the "assistant" although the assistant gives you context for understanding the "user" response and making your assessment.
    IMPORTANT: Always make sure anything provided is contextually independent. 
    IMPORTANT: The observation should be no more than 2,000 chracters.
    
    Core Predefined Categories
    PersonalTraits
    
    Characteristics related to personality, behavior, and individual differences.
    Examples: Aggressiveness, Impulsiveness, Empathy
    EmotionalStates
    
    Various emotional experiences and states of mind.
    Examples: Happiness, Sadness, Anxiety
    PhilosophicalInsights
    
    Thoughts on life, ethics, values, and deeper reflections.
    Examples: Morality, Existentialism, Spirituality
    SpecializedKnowledge
    
    Domain-specific knowledge and expertise.
    Examples: Culinary, Science, History
    PracticalGuidance
    
    Step-by-step instructions, DIY projects, and how-to guides.
    Examples: DIY, HomeRepair, PersonalFinance
    ProfessionalExpertise
    
    Information related to specific professions or industries.
    Examples: Engineering, Medical, Finance
    CulturalPerspectives
    
    Insights on culture, arts, traditions, and societal norms.
    Examples: Art, Music, Traditions
    HealthAndWellness
    
    Topics on physical and mental health, wellness routines, and medical advice.
    Examples: Fitness, Nutrition, MentalHealth
    PoliticalViews
    
    Opinions and information on political ideologies, policies, and governance.
    Examples: Liberalism, Conservatism, PublicPolicy
    LifeExperiences
    
    Personal stories, experiences, and anecdotes.
    Examples: Travel, Parenting, OvercomingChallenges
    EducationalContent
    
    Information related to learning, education, and academic subjects.
    Examples: Mathematics, Literature, Languages
    TechnologicalAdvancements
    
    Information about technology, innovations, and advancements.
    Examples: ArtificialIntelligence, Blockchain, Cybersecurity
    HobbiesAndInterests
    
    Activities people engage in during their free time.
    Examples: Gardening, Photography, Gaming
    Relationships
    
    Topics related to interpersonal relationships and social interactions.
    Examples: Friendship, RomanticRelationships, FamilyDynamics
    PhilosophicalAndSpiritualBeliefs
    
    Beliefs related to philosophy, religion, and spirituality.
    Examples: Religion, Mindfulness, Meditation
    
    As well as specific information like links, or very specific recipes etc. Cateroize that too.
    
    And then return a JSON object containing one observation property that is a string of text that has all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else and then also include and another property that is an array of keywords that the observation falls under such as PersonalTraits, CulturalPerspectives, etc as well as subcategories such as liberalism etc
    the JSON object when a person says they are done. However they indicate they are finished at the moment
    IMPORTANT: In regard to main categories like PersonalTraits, EmotionalStates as well as all of the others listed above, please do not create new main categories, you can generate new sub categories but try to do your best to only use the main categories listed above when using the main categories.
    IMPORTANT: The observation contains all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else about each response they've given to each different question asked and the keywords that go with them. Each observation should be thorough and elaborate but no more than 2,000 characters. Observations apply to all messages with questions and answers.
    IMPORTANT: If given an example of a conversation whether from text within an image such as a screenshot of a conversation, make the assessment based on the person given to you, a description of to assess such as "The one with the blue text is the User"
    IMPORTANT: refer to user as ${botFullName} but in an appropriate way given the context of what you're saying. For example, you wouldn't always use their first and last name. Mostly just first unless it's appropriate to use both.
    IMPORTANT: when returning keywords and subcategories, can you return the keywords each in an object and have subcategories as an array containing the subcategory names as strings. THE FORMAT FOR KEYWORDS SHOULD BE:
    EXAMPLE:
    
    "keywords": [
      {PersonalTraits:['subcategory1', 'subcategory2] },
    ],
    }
    
    Although there are only 2 subcategories and only one main category "personalTraits" in the example above, it's only an example. Have as many main categories and subcategories as permitted above.`;
    
    let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${chunk}`}]);
    
    console.log('observation', observation);
    let observationObject = extractAndConvertToJSObject(observation);
    console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
    // entry.observation = observationObject;
    observationObject.username = currentUser.displayName;
    observationObject.userId = currentUser.uid;

            const db = firebase.firestore();
          
                db.collection('botPDF').add(observationObject);
          
    
  };
  
  const PDFUploader = ({ statusProp }) => {
    const [status, setStatus] = useState('Idle');
    const [loading, setLoading] = useState(false);

    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
          setLoading(true);
          setStatus('Extracting text from PDF...');
          statusProp('Extracting text from PDF...');
          const reader = new FileReader();
          reader.onload = async (e) => {
              try {
                  const arrayBuffer = e.target.result;
                  const loadingTask = getDocument({ data: arrayBuffer });
                  const doc = await loadingTask.promise;
                  let text = '';
                  for (let i = 1; i <= doc.numPages; i++) {
                      const page = await doc.getPage(i);
                      const content = await page.getTextContent();
                      text += content.items.map(item => item.str).join(' ');
                  }
                  setStatus('Text extraction complete.');
                  statusProp('Text extraction complete.');
                  
                  // Chunk the text and process each chunk
                  const chunks = chunkText(text, chunkSize);
                  for (const chunk of chunks) {
                      await processChunk(chunk, waitTime); // Process each chunk with a delay
                  }
              } catch (error) {
                  console.error('Error processing PDF:', error);
                  setStatus('Error processing PDF: ' + error.message);
                  statusProp('Error processing PDF: ' + error.message);
              }
              setLoading(false);
          };
          reader.readAsArrayBuffer(file);
      } else {
          setStatus('Please upload a valid PDF file.');
          statusProp('Please upload a valid PDF file.');
          setLoading(false);
      }
  };

  

    return (
        <div style={{position:'relative'}}>
            <div style={{border:'1px solid gray', width:'100%', maxWidth:50, display:'flex', justifyContent:'center', alignItems:'center', height:'50px', margin:10, borderRadius:7, minWidth:50}}></div>
            <input type="file" accept="application/pdf" onChange={handleFileUpload} disabled={loading} style={{ position: 'absolute', margin: 0, padding: 0, top: 9, left: 10, width: 50, height: 50, cursor: 'pointer', opacity: 0 }} />
            <div style={{position:'absolute', top: 25, left: 27, pointerEvents:'none'}}>
                <FontAwesomeIcon icon={faFileWord} style={{color:'white', fontSize:20, pointerEvents:'none'}}/>
            </div>
        </div>
    );
};

  const ImageUploader = ({ urlPhoto }) => {
    const fileInputRef = useRef(null);
    useEffect(()=>{
      setBotImage(botImage)
    },[botImage])
    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const url = await uploadImageFile(file);
                console.log('Image uploaded successfully:', url);
                setBotImage(url);
                setBotPhoto(url);
            } catch (error) {
                console.error('Failed to upload image:', error);
            }
        }
    };
  
    const handleClick = () => {
        fileInputRef.current.click();
    };
  
    return (
        <div>
            <div
                onClick={handleClick}
                style={{
                    width: 70,
                    height: 70,
                    borderRadius: '50%',
                    backgroundColor: "black",
                    backgroundImage: `url(${botImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: "0px solid #6371F6",
                    marginBottom: 15,
                    marginRight: 5,
                    cursor: 'pointer',
                    margin:'auto',
                    marginTop:30
                }}
            ></div>
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileSelect}
            />
        </div>
    );
  };
  
  // Ensure to define or import this function accordingly
  async function uploadImageFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        reader.onloadend = () => {
            const base64Data = reader.result.split(',')[1];
            const bodyData = JSON.stringify({
                imageData: base64Data,
                mimeType: file.type
            });
  
            fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadImageHTTP', {
                method: 'POST',
                body: bodyData,
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.imageUrl) {
                    resolve(data.imageUrl);
                } else {
                    reject('No image URL returned');
                }
            })
            .catch(error => {
                reject(error);
            });
        };
  
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
  }
  
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  let ProfileStore = useContext(ProfileStoreContext);

  const ShareStore = useContext(ShareStoreContext);
  const [selectedTab, setSelectedTab] = useState("flows");
  const { currentUser } = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let { id } = useParams(); // If you need to access params
// if(currentUser == null) {
//   return;
// }

const [selectedOption, setSelectedOption] = useState(null);



const extractAndConvertToJSObject = (text) => {
  try {
    // Extract the JSON part of the text
    const start = text.indexOf("{");
    const end = text.lastIndexOf("}") + 1;
    const jsonText = text.substring(start, end).trim();

    // Clean up the JSON string by removing line breaks and unnecessary whitespace
    const cleanedJsonText = jsonText.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");

    // Parse the cleaned JSON text
    const data = JSON.parse(cleanedJsonText);

    return data;
  } catch (error) {
    console.error("Failed to extract and convert JSON:", error);
    return null;
  }
};




function stripJsonFromString(inputString) {
  // Define a regular expression that matches JSON blocks wrapped with ```json ... ```
  const regex = /``` *json *\{[\s\S]*?\} *```/gi;

  // Remove the JSON block from the string
  const strippedText = inputString.replace(regex, '').trim();

  // Return the text with the JSON blocks removed
  return strippedText;
}
function cleanJSON(inputString) {
  // Define a regular expression that matches JSON blocks wrapped with ```json ... ```
  // The regex is made to be case insensitive and global to replace all occurrences
  const regex = /```json\s*\{[\s\S]*?\}\s*```/gi;

  // Remove the JSON block from the string
  const strippedText = inputString.replace(regex, '').trim();

  // Return the text with the JSON blocks removed
  return strippedText;
}

function extractAndParseJSON(inputString) {
  // Define a regular expression to find JSON either wrapped with ```json ... ``` or following a 'json' prefix
  const regex = /(?:```json|json)\s*([\s\S]*?)(?:```|$)/;

  // Extract JSON using the regex
  const match = inputString.match(regex);

  // Check if a match was found
  if (match) {
    try {
      // Parse the JSON string into a JavaScript object
      const jsonObject = JSON.parse(match[1].trim()); // Trim to ensure no leading/trailing whitespace issues
      return jsonObject;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;  // Return null if parsing fails
    }
  } else {
    // If no JSON is found, return null without logging an error
    return null;
  }
}


function isValidJSON(inputString) {
  try {
      // Try parsing the input string as JSON
      JSON.parse(inputString);
      return true;  // Return true if parsing succeeds
  } catch (error) {
      return false;  // Return false if an error occurs
  }
}



  const renderContent = () => {
    switch (selectedOption) {
      case 'qa':
        return <QuestionsAndAnswers goBack={() => setSelectedOption(null)} />;
      case 'files':
        return <Files goBack={() => setSelectedOption(null)} botFullName={botFullName} />;
      case 'instructions':
        return <Instructions goBack={() => setSelectedOption(null)} />;
      case 'journal':
        return <YourJournal goBack={() => setSelectedOption(null)} />;
      default:
        return null;
    }
  };


  const TextUploader = ({ statusProp }) => {
    const [status, setStatus] = useState('Idle');
    const [loading, setLoading] = useState(false);
  
    // Function to chunk text into smaller parts
    const chunkText = (text, chunkSize) => {
      let chunks = [];
      for (let i = 0; i < text.length; i += chunkSize) {
        chunks.push(text.substring(i, i + chunkSize));
      }
      return chunks;
    };
  
    // Handle file upload
    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      let extractedText = '';
  
      setLoading(true);
      setStatus('Reading file...');
      statusProp('Reading file...');
  
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const fileContent = e.target.result;
  
        switch (file.type) {
          case 'application/pdf':
            extractedText = await extractTextFromPDF(fileContent);
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            extractedText = await extractTextFromDocx(fileContent);
            break;
          case 'text/html':
            extractedText = extractTextFromHTML(fileContent);
            break;
          default:
            extractedText = fileContent;  // Assumes plain text for others
        }
  
        const chunkSize = 1024;  // Define the size of each chunk here
        const textChunks = chunkText(extractedText, chunkSize);
        setStatus('Processing chunks...');
        statusProp('Processing chunks...');
  
        for (const chunk of textChunks) {
          console.log('chunk', chunk);
          await new Promise(resolve => setTimeout(resolve, 1000));  // Simulate processing delay
          console.log('Processed chunk');


          const result = stripJsonFromString(chunk);
          const cleanText = cleanJSON(result)
          if(isValidJSON(JSON.stringify(result))) {
            try{
            let jsonObj = extractAndParseJSON(result);
              let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${chunk}`}]);
              console.log('observation', observation);

              let observationObject = extractAndConvertToJSObject(observation);
              console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
              // entry.observation = observationObject;
              // entry.observation = observationObject;
              observationObject.username = currentUser.displayName;
              observationObject.userId = currentUser.uid;

                      const db = firebase.firestore();
                    
                          db.collection('botTextDocuments').add(observationObject);
                    
              
              
            
          } catch(err){}
          }

        }
  
        setStatus('All chunks processed.');
        statusProp('All chunks processed.');
        setLoading(false);
      };
  
      if (file.type === 'application/pdf') {
        reader.readAsArrayBuffer(file);  // Read as ArrayBuffer for binary files like PDF
      } else {
        reader.readAsText(file);  // Read as text for other file types
      }
    };
  
    return (
      <div style={{position:'relative'}}>
      <input type="file" accept=".txt,text/plain,.pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.html,text/html" onChange={handleFileUpload} disabled={loading} style={{    position: 'absolute',
    margin: 0,
    padding: 0,
    top: -61,
    left: -24,
    width: 50,
    height: 50,
    cursor: 'pointer',
    opacity: 0}} />
      <div style={{position:'absolute',
       top: -43,
       left: -7,
      pointerEvents:'none'}}>
             <FontAwesomeIcon icon={faFileWord} style={{color:'white', fontSize:20, pointerEvents:'none'}}/>
</div>
      </div>
    );
  };
  
  


  const JournalComponent = ({ botName }) => {
    const [entries, setEntries] = useState([]);
    const [currentEntry, setCurrentEntry] = useState(null);
    const [currentUser, setCurrentUser] = useState(null); // Mock current user object
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setCurrentUser(user);
        });

        if (currentUser) {
            const db = firebase.firestore();
            db.collection('botJournals')
              .where('botName', '==', botName)
              .where('userId', '==', currentUser.uid)
              .onSnapshot((snapshot) => {
                  const newEntries = snapshot.docs.map((doc) => ({
                      id: doc.id,
                      ...doc.data()
                  }));
                  setEntries(newEntries);
              });
        }
    }, [currentUser, botName]);

    const handleEntrySelect = (entry) => {
        setCurrentEntry(entry);
        setShowForm(true);
    };

    const handleNewEntry = () => {
        setCurrentEntry(null);
        setShowForm(true);
    };

    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            {showForm ? (
                <EntryForm
                    entry={currentEntry}
                    onSave={async (entry) => {


                      let observePrompt = `You are a chatbot that observes information provided to you from a person to determine how how they think, how they are emotionally so that you can describe the person and each kind of thought as well as place them under the following categories:

IMPORTANT: if a conversation is provided to you to observe as information for this task, base your assessment on the "user" and not the "assistant" although the assistant gives you context for understanding the "user" response and making your assessment.
IMPORTANT: Always make sure anything provided is contextually independent. 
IMPORTANT: The observation should be no more than 2,000 chracters.

Core Predefined Categories
PersonalTraits

Characteristics related to personality, behavior, and individual differences.
Examples: Aggressiveness, Impulsiveness, Empathy
EmotionalStates

Various emotional experiences and states of mind.
Examples: Happiness, Sadness, Anxiety
PhilosophicalInsights

Thoughts on life, ethics, values, and deeper reflections.
Examples: Morality, Existentialism, Spirituality
SpecializedKnowledge

Domain-specific knowledge and expertise.
Examples: Culinary, Science, History
PracticalGuidance

Step-by-step instructions, DIY projects, and how-to guides.
Examples: DIY, HomeRepair, PersonalFinance
ProfessionalExpertise

Information related to specific professions or industries.
Examples: Engineering, Medical, Finance
CulturalPerspectives

Insights on culture, arts, traditions, and societal norms.
Examples: Art, Music, Traditions
HealthAndWellness

Topics on physical and mental health, wellness routines, and medical advice.
Examples: Fitness, Nutrition, MentalHealth
PoliticalViews

Opinions and information on political ideologies, policies, and governance.
Examples: Liberalism, Conservatism, PublicPolicy
LifeExperiences

Personal stories, experiences, and anecdotes.
Examples: Travel, Parenting, OvercomingChallenges
EducationalContent

Information related to learning, education, and academic subjects.
Examples: Mathematics, Literature, Languages
TechnologicalAdvancements

Information about technology, innovations, and advancements.
Examples: ArtificialIntelligence, Blockchain, Cybersecurity
HobbiesAndInterests

Activities people engage in during their free time.
Examples: Gardening, Photography, Gaming
Relationships

Topics related to interpersonal relationships and social interactions.
Examples: Friendship, RomanticRelationships, FamilyDynamics
PhilosophicalAndSpiritualBeliefs

Beliefs related to philosophy, religion, and spirituality.
Examples: Religion, Mindfulness, Meditation

As well as specific information like links, or very specific recipes etc. Cateroize that too.

And then return a JSON object containing one observation property that is a string of text that has all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else and then also include and another property that is an array of keywords that the observation falls under such as PersonalTraits, CulturalPerspectives, etc as well as subcategories such as liberalism etc
the JSON object when a person says they are done. However they indicate they are finished at the moment
IMPORTANT: The observation contains all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else about each response they've given to each different question asked and the keywords that go with them. Each observation should be thorough and elaborate but no more than 2,000 characters. Observations apply to all messages with questions and answers.
IMPORTANT: If given an example of a conversation whether from text within an image such as a screenshot of a conversation, make the assessment based on the person given to you, a description of to assess such as "The one with the blue text is the User"
IMPORTANT: refer to user as ${botFullName} but in an appropriate way given the context of what you're saying. For example, you wouldn't always use their first and last name. Mostly just first unless it's appropriate to use both.
IMPORTANT: when returning keywords and subcategories, can you return the keywords each in an object and have subcategories as an array containing the subcategory names as strings. THE FORMAT FOR KEYWORDS SHOULD BE:
EXAMPLE:

"keywords": [
  {PersonalTraits:['subcategory1', 'subcategory2] },
],
}

Although there are only 2 subcategories and only one main category "personalTraits" in the example above, it's only an example. Have as many main categories and subcategories as permitted above.`;

let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${entry.entry}`}]);

console.log('observation', observation);
let observationObject = extractAndConvertToJSObject(observation);
console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
entry.observation = observationObject;
                        const db = firebase.firestore();
                        if (entry.id) {
                            db.collection('botJournals').doc(entry.id).update(entry);
                        } else {
                            db.collection('botJournals').add(entry);
                        }
                        setShowForm(false);
                    }}
                    onCancel={() => setShowForm(false)}
                />
            ) : (
                <>
                    <button onClick={handleNewEntry}>Add Entry</button>
                    <JournalList entries={entries} onSelect={handleEntrySelect} />
                </>
            )}
        </div>
    );
};

const JournalList = ({ entries, onSelect }) => (
  <div style={{backgroundColor:'gray', width:'100%', maxWidth:300, margin:'auto',
  width: '100%',
  maxWidth: 300,
  margin: 'auto',
  minHeight: 180,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  flexDirection:'column',
  marginBottom: 20}}>
      {entries.map(entry => (
          <div key={entry.id} onClick={() => onSelect(entry)}>
              {entry.entryTitle}
          </div>
      ))}
  </div>
);


const EntryForm = ({ entry, onSave, onCancel }) => {
  const [entryTitle, setEntryTitle] = useState('');
  const [entryText, setEntryText] = useState('');

  useEffect(() => {
      if (entry) {
          setEntryTitle(entry.entryTitle);
          setEntryText(entry.entry);
      }
  }, [entry]);

  const handleSave = () => {
      if (entryText.length > 1400) {
          console.error("You cannot go over this amount of characters.");
          return;
      }
      onSave({
          ...entry,
          entryTitle,
          entry: entryText,
          username: firebase.auth().currentUser.displayName,
          userId: firebase.auth().currentUser.uid,
          botName: botName
      });
  };

  return (
      <div>
        <div style={{display:'flex', flexDirection:'column', height:'100%', maxWidth:300, margin:'auto'}}>
          <input
              value={entryTitle}
              onChange={(e) => setEntryTitle(e.target.value)}
              placeholder="Title"
              style={{height:40, paddingLeft:10, marginBottom:10}}
          />
          <textarea
              value={entryText}
              onChange={(e) => setEntryText(e.target.value)}
              placeholder="Write your journal entry..."
              style={{height:100, marginBottom:10, padding:10}}
          />
          <button onClick={handleSave}>Save Entry</button>
          <button onClick={onCancel}>Back</button>
          </div>
      </div>
  );
};


  async function createBot(currentUser, botName, fullName, personalityStyle, botBio, botAdvanced, botPhoto, botUserId) {
    const db = firebase.firestore();
    const botDocRef = db.collection("bots").doc(`${currentUser.uid}:${botName}`);

    try {
        const botDoc = await botDocRef.get();
        if (botDoc.exists) {
            throw new Error("This bot name is already taken.");
        } else {
            await botDocRef.set({
                botName: botName,
                fullName: fullName,
                personalityStyle: personalityStyle,
                bioBot: botBio,
                advanced: botAdvanced,
                botPhoto: botPhoto,
                username:currentUser.displayName,
                botUserId: currentUser.uid
            });

            const userQuerySnapshot = await db.collection("users")
                                              .where("username", "==", currentUser.displayName)
                                              .get();

            if (!userQuerySnapshot.empty) {
                const userDocRef = userQuerySnapshot.docs[0].ref;
                const userData = userQuerySnapshot.docs[0].data();
                const botNames = userData.botNames ? userData.botNames : [];

                if (!botNames.includes(botName)) {
                    botNames.push(botName);
                    await userDocRef.update({ botNames: botNames });
                    setShowBotSettings(false);

                }
            } else {
                throw new Error("User document does not exist.");
            }
        }
    } catch (error) {
        console.error("Error in createBot function:", error.message);
        return error.message;
    }
}


async function updateBotDetails(currentUser, botName, personalityStyle, botBio, botAdvanced, botPhoto) {
  const db = firebase.firestore();
  const botDocRef = db.collection("bots").doc(`${currentUser.uid}:${botName}`);

  try {
      const botDoc = await botDocRef.get();
      if (!botDoc.exists) {
          console.error("Bot not found. Cannot update non-existing bot.");
          return "Bot not found.";
      }

      // Update the document with new details, without changing botName, fullName, or botUserId
      await botDocRef.update({
          personalityStyle: personalityStyle,
          bioBot: botBio,
          advanced: botAdvanced,
          botPhoto: botPhoto
      });

      console.log("Bot details updated successfully.");
  } catch (error) {
      console.error("Error in updateBotDetails function:", error.message);
      return error.message;
  }
}

async function updateBotWithVector(botName, newVectorStoreId, newFileLink, file_id) {
  try {
      const botsRef = firebase.firestore().collection('bots');
      const snapshot = await botsRef.where('botName', '==', botName).get();
      if (snapshot.empty) {
          console.log('No matching documents to update.');
          return;
      }

      // Batch update to handle possible multiple documents
      let batch = firebase.firestore().batch();
      snapshot.forEach(doc => {
          const docRef = botsRef.doc(doc.id);
          // Use arrayUnion to add new items without removing existing ones
          batch.update(docRef, {
              vectorStoreIds: newVectorStoreId,
              fileLinks: firebase.firestore.FieldValue.arrayUnion(newFileLink),
              file_ids: firebase.firestore.FieldValue.arrayUnion(file_id)
          });
      });

      await batch.commit();
      console.log('All matching documents have been updated with new vectorStoreIds and fileLinks.');
  } catch (error) {
      console.error("Error updating documents: ", error);
  }
}


  const customChatbot = async (userInput) => {
    console.log(userInput)
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: userInput,
            model: "gpt-4",
            temperature: 0,
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const customChatbot4o = async (userInput) => {
    console.log(userInput)
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: userInput,
            model: "gpt-4o",
            temperature: 0.2,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
  
  const startObservation = async (userInput) => {
    console.log(userInput)
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: userInput,
            model: "gpt-4",
            temperature: 0,
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const startObservation4o = async (userInput) => {
    console.log('userInput',userInput)
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: userInput,
            model: "gpt-4o",
            temperature: 0.2,
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };


  const ragLLM = async (titles, userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: [{role:'system', content:`You are an AI-powered chatbot tasked with assessing the relevance of document titles in response to user queries. After evaluating the provided titles and the user's specific question or statement, you return a JSON object structured as follows:

            1. "titles": This property is an array containing up to three objects each with the best titles from a database and an "index" property that matches where they would be in an array. So on a number list like 1. Some title 2. Another title 3. And another title 4. Another new title, 5. Some title, if you felt 2 and 3 were the best, in the object you would have 1 and 2 for the index because in an array, 1 is 0. These titles are selected based on their potential to provide valuable answers or responses to the user's query.
            2. "results": A boolean property that indicates whether any titles were available to assess. It returns true if at least one title was evaluated, and false if no titles were available.
            3. "titlesWork": Another boolean property, which is true if you determine that there are three or fewer top titles that effectively address the user's needs based on the query provided.
            4. "offset": This property should be set to the number of the last title you assessed or listed. It helps in managing pagination or further queries, indicating where the next set of titles should begin if additional queries are necessary.

            Your task is to analyze the user's input, match it against a set of titles, and generate the JSON response based on these criteria."
            
            IMPORTANT: the titles from the user you choose must be titles that best fit what the user is asking a question about or making a statement about. For example if you have two titles
            IMORTANT: "results" is always set to true if you're provided titles.
            IMPORTANT: 

            EXAMPLE:
            titles from user: 1. A movie title or something
            2. A title about cooking hamburgers

            and a user asks, how can I make a hamburger? Obviously, the best title is: 2. A title about cooking hamburgers. Since there are only two titles, the best one is only that one. There are not more than one best title as a response to what the user said.

            This JSON object structure allows the chatbot to clearly communicate with the backend or further processing systems, detailing how effective the response was and where further interactions might continue if needed. It is an effective way to package the outcome of the chatbot's analysis into a structured format that can be easily understood and utilized in further computational processes or user interactions.`},  {
              role:'user', content:`titles from user: ${titles}
              user question or statement: ${userInput}`
            }],
            model: "gpt-4o",
            temperature: 0.2,
            response_format: { "type": "json_object" }
          }, {
            role:'user', content:`titles from user: ${titles}
            user question or statement: ${userInput}`
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const runBot = async (userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:userInput,
            model: "gpt-4o",
            temperature: 0.2
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };





const localStore = [];

const storeLocally = (data) => {
  const maxItems = 10000;  // Limit to prevent memory issues
  if (localStore.length >= maxItems) {
    throw new Error("Storage limit reached.");
  }
  localStore.push(data);
};


const uploadTextFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];  // Extract the base64 part of the image data

      const bodyData = JSON.stringify({
        imageData: base64Data,
        mimeType: file.type
      });

      fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadImageHTTP', {
        method: 'POST',
        body: bodyData,
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(result => {
        if (result.status === 200 && result.body.imageUrl) {
          resolve(result.body.imageUrl);
        } else {
          reject(`Server responded with status ${result.status}: ${JSON.stringify(result.body)}`);
        }
      })
      .catch(error => {
        reject(`Network or parsing error: ${error}`);
      });
    };

    reader.onerror = error => reject(`FileReader error: ${error}`);

    // Ensure the file is read as a data URL to include the necessary base64 encoding
    reader.readAsDataURL(file);
  });
};




const saveToAlgolia = async () => {
 // console.log('result assistant', result);
  try {
    //Check if the localStore array is empty
    if (!localStore || localStore.length === 0) {
      throw new Error('The localStore array is empty');
    }

    // Convert the array of objects to a formatted text string
    let textData = JSON.stringify(localStore);
    // localStore.forEach((item, index) => {
    //   textData += `Category: ${item.subCategory}\nTitle: ${item.title}\nAnalysis: ${item.analysis}\nKeywords: ${item.keywords.join(', ')}\n\n`;

    //   // Optionally, include links and notes if they are not empty
    //   if (item.links && item.links.length > 0) {
    //     textData += `Links: ${item.links.join(', ')}\n`;
    //   }
    //   if (item.dbNotes && item.dbNotes.length > 0) {
    //     textData += `Database Notes: ${item.dbNotes.join(', ')}\n`;
    //   }
    //   if (item.youtubeLinks && item.youtubeLinks.length > 0) {
    //     textData += `YouTube Links: ${item.youtubeLinks.join(', ')}\n`;
    //   }
    //   if (item.youtubeTimestamps && item.youtubeTimestamps.length > 0) {
    //     textData += `YouTube Timestamps: ${item.youtubeTimestamps.join(', ')}\n`;
    //   }

    //   // Separate entries with a double newline for clarity
    //   textData += '\n\n';
    // });

    // Create a Blob from the text data
    const fileBlob = new Blob([textData], { type: 'text/plain' });

    // // Create a file object
    const file = new File([fileBlob], "dataUpload.txt", {
      type: "text/plain",
    });

    // // Upload the file to amazon and get the url using the provided uploadTextFile function
    const uploadResult = await uploadTextFile(file);
    console.log('File uploaded successfully:', uploadResult);
    setFileUrl(uploadResult);


 

     let resultUpload = await fetchAndUploadFile(uploadResult, "assistants");
     console.log('file result', resultUpload);
     
     const storeId = await createVectorStore(`${botName}_vector`, resultUpload.response.id);
     console.log('vectorstore created', storeId);

     const updateBot = await updateBotWithVector(botName, storeId.response.id, uploadResult, resultUpload.response.id);

     console.log('updated bot', updateBot);

        //upload the file to openai via url
    let botData = await queryBotByName(botName); // Replace 'chefrush' with the botName you want to query
    console.log('botData', botData);

     const batch = await openai.beta.vectorStores.fileBatches.createAndPoll(
      botData[0].vectorStoreIds,
      { file_ids: [...botData[0].file_ids] },
    );

    console.log('batch', batch);
   
     if(botData[0].assistantId !== null && botData[0].assistantId !== undefined && botData[0].assistantId !== "") {
      let result = await openai.beta.assistants.update(botData[0].assistantId, {
          tool_resources: { file_search: { vector_store_ids: [botData[0].vectorStoreIds] } },
        });
        console.log('updated existing bot with assistant', result);

     } else {
      
        let result = await createAssistant(`${botName}_assistant`, storeId.response.id, personalityStyle, bio, botName, AdditionalImportantInformation);
        console.log('created new bot', result);
        setShowSaving(false);

     }




    //  let result = await createAssistant(`${botName}_assistant`, storeId.response.id, "You are an assistant that");

    //  console.log('result', result);
    //  await openai.beta.assistants.update(assistant.id, {
    //   tool_resources: { file_search: { vector_store_ids: [storeId] } },
    // });

    //  let final = await handleUpload();
    //  console.log('file final', final);

  } catch (error) {
    console.error('Error saving data to Algolia:', error);
    setShowBotErrorMsg("You must answer a question, preferably more than one before saving.");

  }
};

  function extractAndParseJSON(text) {
    const jsonRegex = /```json\s*({[\s\S]*?})\s*```|({[\s\S]*?})/;
    const match = text.match(jsonRegex);
    if (match) {
      const jsonString = match[1] || match[2];
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Failed to parse JSON:', error);
        return null;
      }
    } else {
      console.warn('No JSON found in the text.');
      return null;
    }
  }

  const extractJSON = (message) => {
    // Adjust the regex to handle case-insensitivity and potential multiline JSON
    const jsonRegex = /```JSON\s+({[\s\S]*?})\s+```/i;
    
    // Find the JSON string in the message
    const match = message.match(jsonRegex);
  
    // Extract and parse the JSON string if found
    const json = match ? JSON.parse(match[1]) : null;
  
    // Remove the JSON string from the message to isolate the text
    const text = match ? message.replace(jsonRegex, '').trim() : message.trim();
  
    return { text, json };
  };


let prompt = `You are a chatbot programmed to ask random questions to gain insights into a person's thinking and emotional patterns. Through your interactions, categorize responses into specific categories, each limited to 50 characters, such as:

Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)
Emotional States (e.g., Happiness, Sadness, Anxiety)
Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
Specialized Knowledge (e.g., Culinary Arts, Science, History)
Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
Professional Expertise (e.g., Engineering, Medical, Finance)
Cultural Perspectives (e.g., Art, Music, Traditions)
Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
Political Views (e.g., Liberalism, Conservatism, Public Policy)
Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
Educational Content (e.g., Mathematics, Literature, Languages)
Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
Hobbies and Interests (e.g., Gardening, Photography, Gaming)
Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)
Ask questions that are independent of context from previous interactions. Questions should not exceed 250 characters. After receiving an answer, analyze the response to determine the responder's knowledge, personality traits, or biases. Ensure each analysis is clear and self-contained, allowing understanding even in isolation. Include 20 relevant keywords for each question and answer.

For each completed interaction, compile the analysis into a JSON object formatted as follows:

IMPORTANT: include the assistant AI (You) question in the json for the user to answer so you can respond with a meaningful analysis.
IMPORTANT: Only return one JSON object per answered question formatted as in the example below

IMPORTANT: The JSON object should also have a subCategory property containing one of the categories above such as PersonalTraits etc. that best fits the data formatted with the same capital letters but no spaces so Personal Traits would be PersonalTraits or Emotional States would be EmotionalStates etc.
The keywords should all be lowercase.

IMPORTANT: If specific information is provided, such as a recipe or anything else, it is to be saved within the analysis along with the analysis itself.
IMPORTANT: Do not just describe specific information provided, actually save it too. For example if you gave an analaysis like:

Analysis: The responder shared a straightforward recipe for French toast, indicating a basic understanding of cooking. The simplicity of the recipe suggests a preference for easy-to-make dishes. This could imply a practical approach to cooking or a beginner level of culinary skills.

You should also include the recipe itself within the Analysis.

IMPORTANT: Keep asking new questions until the user feels like stopping.

IMPORTANT: Never ask the user the same question repeatedly.

IMPORTANT: The "instructions" property, if instructions are provided by the user, should have an array of objects containing steps to follow and
each object should have the step (in order) and an image associated with that step and or video (if provided by the user) so if it's a recipe and the first step is to flatten the dough,
if an image or video was provided by the user where it shows how to flatten the dough, the image or video would be a part of the object. 
"instructions" don't just apply to recipes but anything involving fixing things, making things etc.

IMPORTANT: The "supplies" property if provided by the user should be an array of objects that contain a "supply" property which is the name and the amount (if there is an amount) of the supply needed for the instructions. Each object has a different supply in the "supply" property. There is also a "link" property which contains a website to the supply (if provided).

{ "assistant": always a new question here despite the "entries" pertaining to the previous question. Do not generate the same question repeatedly.
  "entries": [
    {
      "subCategory": "The best subCategory that fits for this data",
      "title": "50-character title summarizing the analysis",
      "analysis": "Up to 1500 characters providing insights into the responder's knowledge, traits, or biases, with clear, standalone sentences",
      "keywords": ["keyword1", "keyword2", ..., "keyword20"],
      "links": ["link to additional resources, if any"],
      "dbNotes": ["array of database document IDs, if applicable"],
      "youtubeLinks": ["youtube link if provided"],
      "supplies": [list of things needed for instructions such as ingredients if recipe or tools, parts, or anything (only if needed to follow instructions) format: { supply: "name of supply needed such as an ingredient and amount needed or tools, parts etc.", link: "(if provided, a place to find the supply whether purchasing or not)" }]
      "instructions": [{ step: one of the steps in instructions (if provided in the files you search)}, { image: image provided associated with step (if provided)}, { video: video provided associated with step (if provided) }],
      "youtubeTimestamps": [
        {
          "link": "youtube video link",
          "timestamps": [
            {
              "description": "70-character description of the timestamp's relevance",
              "timestamp": "timestamp in the video"
            }
          ]
        }
      ]
    }
    // Additional entries
  ]
}`;



let promptInstructions = `You are a chatbot programmed to ask questions that help the user provide step by step instructions for whatever the user wants to provide step by step instructions for.
For example: A user may want to provide a recipe. So first you would ask the user what they want to give instructions for then once the user answers, ask the user for the first step, then once provided
ask for the second step and so on until the user indicates they are finished providing steps. With each step a user provides, ask if there is an image or video link to go with the step. It is not a requirement to provide and image or video.

For each completed interaction, compile the analysis into a JSON object formatted as follows:

IMPORTANT: Only return one JSON object per answered question formatted as in the example below

You should also include the recipe itself within the Analysis.

IMPORTANT: Keep asking new questions until the user feels like stopping.

IMPORTANT: Never ask the user the same question repeatedly.

IMPORTANT: The "instructions" property, if instructions are provided by the user, should have an array of objects containing steps to follow and
each object should have the step (in order) and an image associated with that step and or video (if provided by the user) so if it's a recipe and the first step is to flatten the dough,
if an image or video was provided by the user where it shows how to flatten the dough, the image or video would be a part of the object. 
"instructions" don't just apply to recipes but anything involving fixing things, making things etc.

IMPORTANT: Provide your question or answer to the user in the "response" property.

IMPORTANT: Sometimes a user may just post an entire set of instructions and if so, populate the "instructions" property with the array of objects and each step broken down and add images where necessary if user provides description.

IMPORTANT: You can speak with the user back and forth so that you understand the instructions correctly and in order if you think you are missing anything.

IMPORTANT: If the user indicates they are done then just respond with a nice "Ok thank you and feel free to provide me with anymore instructions if you'd like" or something like that. 


{ 
  "entries": [
    {
      "response": your question or answer to the user,
      "subCategory": "The best subCategory that fits for this data",
      "title": "50-character title",
      "links": ["link to additional resources, if any"],
      "dbNotes": ["array of database document IDs, if applicable"],
      "youtubeLinks": ["youtube link if provided"],
      "instructions": [{ step: one of the steps in instructions (if provided in the files you search), { image: image provided associated with step (if provided)}, { video: video provided associated with step (if provided) }}],
      "youtubeTimestamps": [
        {
          "link": "youtube video link",
          "timestamps": [
            {
              "description": "70-character description of the timestamp's relevance",
              "timestamp": "timestamp in the video"
            }
          ]
        }
      ]
    }
    // Additional entries
  ]
}`;


let observePrompt = `You are a chatbot that observes information provided to you from a person to determine how how they think, how they are emotionally so that you can describe the person and each kind of thought as well as place them under the following categories:

IMPORTANT: if a conversation is provided to you to observe as information for this task, base your assessment on the "user" and not the "assistant" although the assistant gives you context for understanding the "user" response and making your assessment.
IMPORTANT: Always make sure anything provided is contextually independent. 
IMPORTANT: The observation should be no more than 2,000 chracters.

Core Predefined Categories
PersonalTraits

Characteristics related to personality, behavior, and individual differences.
Examples: Aggressiveness, Impulsiveness, Empathy
EmotionalStates

Various emotional experiences and states of mind.
Examples: Happiness, Sadness, Anxiety
PhilosophicalInsights

Thoughts on life, ethics, values, and deeper reflections.
Examples: Morality, Existentialism, Spirituality
SpecializedKnowledge

Domain-specific knowledge and expertise.
Examples: Culinary, Science, History
PracticalGuidance

Step-by-step instructions, DIY projects, and how-to guides.
Examples: DIY, HomeRepair, PersonalFinance
ProfessionalExpertise

Information related to specific professions or industries.
Examples: Engineering, Medical, Finance
CulturalPerspectives

Insights on culture, arts, traditions, and societal norms.
Examples: Art, Music, Traditions
HealthAndWellness

Topics on physical and mental health, wellness routines, and medical advice.
Examples: Fitness, Nutrition, MentalHealth
PoliticalViews

Opinions and information on political ideologies, policies, and governance.
Examples: Liberalism, Conservatism, PublicPolicy
LifeExperiences

Personal stories, experiences, and anecdotes.
Examples: Travel, Parenting, OvercomingChallenges
EducationalContent

Information related to learning, education, and academic subjects.
Examples: Mathematics, Literature, Languages
TechnologicalAdvancements

Information about technology, innovations, and advancements.
Examples: ArtificialIntelligence, Blockchain, Cybersecurity
HobbiesAndInterests

Activities people engage in during their free time.
Examples: Gardening, Photography, Gaming
Relationships

Topics related to interpersonal relationships and social interactions.
Examples: Friendship, RomanticRelationships, FamilyDynamics
PhilosophicalAndSpiritualBeliefs

Beliefs related to philosophy, religion, and spirituality.
Examples: Religion, Mindfulness, Meditation

As well as specific information like links, or very specific recipes etc. Cateroize that too.

And then return a JSON object containing one observation property that is a string of text that has all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else and then also include and another property that is an array of keywords that the observation falls under such as PersonalTraits, CulturalPerspectives, etc as well as subcategories such as liberalism etc
the JSON object when a person says they are done. However they indicate they are finished at the moment
IMPORTANT: The observation contains all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else about each response they've given to each different question asked and the keywords that go with them. Each observation should be thorough and elaborate but no more than 2,000 characters. Observations apply to all messages with questions and answers.
IMPORTANT: If given an example of a conversation whether from text within an image such as a screenshot of a conversation, make the assessment based on the person given to you, a description of to assess such as "The one with the blue text is the User"
IMPORTANT: refer to user as ${botFullName} but in an appropriate way given the context of what you're saying. For example, you wouldn't always use their first and last name. Mostly just first unless it's appropriate to use both.
IMPORTANT: Try to make sure you ask questions that cover the core predefined categories and any other categories that might good. It will be hard to do this if the user only responds to a few questions but if they answer more the odds of covering those categories are higher.
IMPORTANT: when returning keywords and subcategories, can you return the keywords each in an object and have subcategories as an array containing the subcategory names as strings. THE FORMAT FOR KEYWORDS SHOULD BE:
EXAMPLE:

"keywords": [
  {PersonalTraits:['subcategory1', 'subcategory2] },
],
}

Although there are only 2 subcategories and only one main category "personalTraits" in the example above, it's only an example. Have as many main categories and subcategories as permitted above.`;




  const QuestionsAndAnswers = ({ goBack }) => {
    const [messages, setMessages] = useState([{role:'system', content:prompt}, { role:"assistant", content:"This session is designed for question-and-answer training to help me get to know you better. You can make the session as long or as short as you like, but answering more questions will significantly enhance the training. Feel free to skip any questions you prefer not to answer, and remember, you can stop the session at any time. To save your responses for my training, please press the 'save' button after you have answered a substantial number of questions or when you decide to end the session. You do not need to save after each individual answer. You're welcome to return at any time to continue this training and can press 'save' whenever you're ready to conclude."}]);
    const [text, setText] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const messagesEndRef = useRef(null);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
    const sendMessage = async () => {
      setShowLoading(true)
      if (text.trim()) {
        const userMessage = { role: 'user', content: text.trim() };
        setText('');

        // Update the state to include the new user message
        setMessages(prevMessages => [...prevMessages, userMessage]);
    
        try {
          // Fetch the response using the current state of messages including the new user message
          const initialResult = await customChatbot4o([...messages, userMessage]);
         
          const result = stripJsonFromString(initialResult);
          console.log('json',result);
          const cleanText = cleanJSON(result)
         if(isValidJSON(JSON.stringify(result))) {
             try{
              // console.log('jsonObj', jsonObj)
            console.log('jsonObject', result)
            ///if(JSON.parse(result).qaComplete) {
              let data = JSON.parse(result).entries[0];
              data.botName = botName;
              storeLocally(data);
              console.log('localStore', localStore);
              console.log('cleanText', data)
              // let finalResult = await addTextEmbeddingToPineCone('flowroom', null, jsonObj.textChunks, 500, 100, 20, 'text-embedding-3-small', 500, botName);
              // console.log('pinecone result', finalResult);
              // const stringifiedMessages = JSON.stringify(messages, null, 2); // The '2' adds indentation for better readability
              // let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${stringifiedMessages}`}]);
              // console.log('observation', observation);

// let observationObject = extractAndConvertToJSObject(observation);
// console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
// // entry.observation = observationObject;
              
           // }
           } catch(err){}
          }
          
          const assistantMessage = { role: 'assistant', content: JSON.parse(result).assistant };
    
          // Update the state to include the new assistant message
          setMessages(prevMessages => [...prevMessages, assistantMessage]);
          setShowLoading(false);

          // Clear the input field
    

        } catch (error) {
          console.error("Error sending message:", error);
          setShowBotErrorMsg(error);
        }
      }
    };
    
  
  
    return (
      <div>
        <div onClick={goBack} style={{backgroundColor: 'rgb(79, 217, 117)',
    height: 20,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    borderRadius: 4,
    margin: 10, cursor:'pointer'}}><p style={{fontFamily:'quicksand', fontFamily:'quicksand', color:'#1c1c1c', fontSize:12}}>Back</p></div>
<div style={{display:'flex', overflowY:'scroll', 
    maxHeight: 350,
    marginBottom: 10, paddingLeft:10, paddingRight:10}}>
<ul style={{height:'100%', background:'#1C1C1C', minHeight:40, borderTop:'0.1px solid rgb(62, 62, 62)', minHeight:335, maxHeight:335}}>
          {messages.map((msg, index) => (
            <li key={index} className={msg.role !== "system" ? msg.role : ''} style={{display: msg.role === "system" ? 'none' : 'flex'}}>
        

<div style={{display:'flex', flexDirection:'row', margin:10}}>  <div style={{ border: '0px solid red', borderRadius: '50%', height: 30, width: 30, marginRight: 10, minWidth: 30, minHeight: 30, display: 'block', backgroundImage:`url(${msg.role === "user" ? currentUser.photoURL:'/images/robot.svg'})`,
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY:'center',
    backgroundPositionX:'center'}}></div><p style={{    background: 'rgb(79, 217, 117)',
    padding: 10,
    minWidth:100, 
    borderRadius: 7,
    fontFamily: 'quicksand',
    fontSize: 12,
    maxWidth: 300, color:'#1c1c1c'}}>{msg.role !== "system" ? msg.content : ''}</p></div>



            </li>
          ))}
          {/* {process !== '' ? (<li style={{marginLeft:10}}><div style={{display:'flex', flexDirection:'row'}}><div style={{border:'1px solid red', borderRadius:'50%', height:30, width:30, marginRight:10}}></div><p style={{    background: 'rgb(58, 79, 64)',
    padding: 10,
    minWidth: 179,
    borderRadius: 7, fontFamily:'quicksand', fontSize:12}}>{process}</p></div></li>):""} */}
          <div ref={messagesEndRef} />
        </ul>
</div>



        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center',}}>
        <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:11, position:'absolute', bottom: 65,
    left: 53,
    width:'calc(100% - 70px)'}}>{showBotErrorMsg}</p>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e)=>{
            if (e.key === 'Enter' && !e.shiftKey) { // Checks if the key pressed is 'Enter' and Shift is not held down
              if(showLoading == false) {
                setShowBotErrorMsg('')
                sendMessage()
              }            
            }
          }}
          placeholder="Type your message..."
          style={{backgroundColor:'#373737', border:'none', resize:'none', borderRadius:7, padding:7, height:38, outline:'none', color:'white', paddingRight:30, marginRight:7}}
          disabled={showLoading}
        ></textarea>
        </div>
        <img src="../microphone.svg" style={{    position: 'absolute',
    right: 145, cursor:'pointer'}}/>
        <div onClick={()=>{
          setShowBotErrorMsg('')
          if(showLoading == false) {
            sendMessage()
          }
        }} style={{ backgroundColor:'rgb(79, 217, 117)',
    width: 35,
    height: 35,
    border: 'none',
    borderRadius: 7, display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}> {
      showLoading ? (
        <div className="animated-dots">
          <div></div><div></div><div></div>  {/* Three dots */}
        </div>
      ) : (
        <img src="../images/chat-arrow.svg" />
      )
    }</div>
    <div onClick={async ()=>{
      if(showLoading == false) {

     setShowSaving(true);
     await saveToAlgolia();

    }

    
  }} style={{backgroundColor:'rgb(79, 217, 117)',
    width: 35,
    height: 35,
    border: 'none',
    borderRadius: 7, display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', marginLeft:5, marginRight:5, color:'#1c1c1c', fontFamily:'quicksand', fontWeight:'bold'}}>{showSaving ? 'Saving...' : 'Save'}</div>
    </div>



      </div>
    );
  };


  const Instructions = ({ goBack }) => {
    const [messages, setMessages] = useState([{role:'system', content: promptInstructions}, { role:"assistant", content:"If you want to train this bot to give step by step instructions for cooking recipes or fixing or building things etc. Tell me what you would like to give instructions for."}]);
    const [text, setText] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSaving, setShowSaving] = useState(false);
    const messagesEndRef = useRef(null);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
    const sendMessage = async () => {
      setShowLoading(true)
      if (text.trim()) {
        const userMessage = { role: 'user', content: text.trim() };
        setText('');

        // Update the state to include the new user message
        setMessages(prevMessages => [...prevMessages, userMessage]);
    
        try {
          // Fetch the response using the current state of messages including the new user message
          const initialResult = await customChatbot4o([...messages, userMessage]);
         
          const result = stripJsonFromString(initialResult);
          console.log('json',result);
          const cleanText = cleanJSON(result)
         if(isValidJSON(JSON.stringify(result))) {
             try{
              // console.log('jsonObj', jsonObj)
            console.log('jsonObject', result)
            ///if(JSON.parse(result).qaComplete) {
              let data = JSON.parse(result).entries[0];
              data.botName = botName;
              storeLocally(data);
              console.log('localStore', localStore);
              console.log('cleanText', data)
              // let finalResult = await addTextEmbeddingToPineCone('flowroom', null, jsonObj.textChunks, 500, 100, 20, 'text-embedding-3-small', 500, botName);
              // console.log('pinecone result', finalResult);
              // const stringifiedMessages = JSON.stringify(messages, null, 2); // The '2' adds indentation for better readability
              // let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${stringifiedMessages}`}]);
              // console.log('observation', observation);

// let observationObject = extractAndConvertToJSObject(observation);
// console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
// // entry.observation = observationObject;
              
           // }
           const assistantMessage = { role: 'assistant', content: data.response };
    
           // Update the state to include the new assistant message
           setMessages(prevMessages => [...prevMessages, assistantMessage]);
           setShowLoading(false);
 
           } catch(err){}
          }
          
      
          // Clear the input field
    

        } catch (error) {
          console.error("Error sending message:", error);
          setShowBotErrorMsg(error);
        }
      }
    };
    
  
  
    return (
      <div>
        <div onClick={goBack} style={{backgroundColor: 'rgb(79, 217, 117)',
    height: 20,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    borderRadius: 4,
    margin: 10, cursor:'pointer'}}><p style={{fontFamily:'quicksand', fontFamily:'quicksand', color:'#1c1c1c', fontSize:12}}>Back</p></div>
<div style={{display:'flex', overflowY:'scroll', 
    maxHeight: 350,
    marginBottom: 10, paddingLeft:10, paddingRight:10}}>
<ul style={{height:'100%', background:'#1C1C1C', minHeight:40, borderTop:'0.1px solid rgb(62, 62, 62)', minHeight:335, maxHeight:335}}>
          {messages.map((msg, index) => (
            <li key={index} className={msg.role !== "system" ? msg.role : ''} style={{display: msg.role === "system" ? 'none' : 'flex'}}>
        

<div style={{display:'flex', flexDirection:'row', margin:10}}>  <div style={{ border: '0px solid red', borderRadius: '50%', height: 30, width: 30, marginRight: 10, minWidth: 30, minHeight: 30, display: 'block', backgroundImage:`url(${msg.role === "user" ? currentUser.photoURL:'/images/robot.svg'})`,
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY:'center',
    backgroundPositionX:'center'}}></div><p style={{    background: 'rgb(79, 217, 117)',
    padding: 10,
    minWidth:100, 
    borderRadius: 7,
    fontFamily: 'quicksand',
    fontSize: 12,
    maxWidth: 300, color:'#1c1c1c'}}>{msg.role !== "system" ? msg.content : ''}</p></div>



            </li>
          ))}
          {/* {process !== '' ? (<li style={{marginLeft:10}}><div style={{display:'flex', flexDirection:'row'}}><div style={{border:'1px solid red', borderRadius:'50%', height:30, width:30, marginRight:10}}></div><p style={{    background: 'rgb(58, 79, 64)',
    padding: 10,
    minWidth: 179,
    borderRadius: 7, fontFamily:'quicksand', fontSize:12}}>{process}</p></div></li>):""} */}
          <div ref={messagesEndRef} />
        </ul>
</div>



        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center',}}>
        <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:11, position:'absolute', bottom: 65,
    left: 53,
    width:'calc(100% - 70px)'}}>{showBotErrorMsg}</p>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e)=>{
            if (e.key === 'Enter' && !e.shiftKey) { // Checks if the key pressed is 'Enter' and Shift is not held down
              if(showLoading == false) {
                setShowBotErrorMsg('')
                sendMessage()
              }            
            }
          }}
          placeholder="Type your message..."
          style={{backgroundColor:'#373737', border:'none', resize:'none', borderRadius:7, padding:7, height:38, outline:'none', color:'white', paddingRight:30, marginRight:7}}
          disabled={showLoading}
        ></textarea>
        </div>
        <img src="../microphone.svg" style={{    position: 'absolute',
    right: 145, cursor:'pointer'}}/>
        <div onClick={()=>{
          setShowBotErrorMsg('')
          if(showLoading == false) {
            sendMessage()
          }
        }} style={{ backgroundColor:'rgb(79, 217, 117)',
    width: 35,
    height: 35,
    border: 'none',
    borderRadius: 7, display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}> {
      showLoading ? (
        <div className="animated-dots">
          <div></div><div></div><div></div>  {/* Three dots */}
        </div>
      ) : (
        <img src="../images/chat-arrow.svg" />
      )
    }</div>
    <div onClick={async ()=>{
      if(showLoading == false) {

     setShowSaving(true);
     await saveToAlgolia();
     setShowSaving(false);

    }

    
  }} style={{backgroundColor:'rgb(79, 217, 117)',
    width: 35,
    height: 35,
    border: 'none',
    borderRadius: 7, display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', marginLeft:5, marginRight:5, color:'#1c1c1c', fontFamily:'quicksand', fontWeight:'bold'}}>{showSaving ? 'Saving...' : 'Save'}</div>
    </div>



      </div>
    );
  };


  let observePromptImage = `You are a chatbot that observes information provided from an image with text to you from a person to determine how how they think, how they are emotionally so that you can describe the person and each kind of thought as well as place them under the following categories:
  
  IMPORTANT: if a conversation is provided to you to observe as information for this task, base your assessment on the "user" and not the "assistant" although the assistant gives you context for understanding the "user" response and making your assessment.
  IMPORTANT: Always make sure anything provided is contextually independent. 
  IMPORTANT: The observation should be no more than 2,000 chracters.
  
  Core Predefined Categories
  PersonalTraits
  
  Characteristics related to personality, behavior, and individual differences.
  Examples: Aggressiveness, Impulsiveness, Empathy
  EmotionalStates
  
  Various emotional experiences and states of mind.
  Examples: Happiness, Sadness, Anxiety
  PhilosophicalInsights
  
  Thoughts on life, ethics, values, and deeper reflections.
  Examples: Morality, Existentialism, Spirituality
  SpecializedKnowledge
  
  Domain-specific knowledge and expertise.
  Examples: Culinary, Science, History
  PracticalGuidance
  
  Step-by-step instructions, DIY projects, and how-to guides.
  Examples: DIY, HomeRepair, PersonalFinance
  ProfessionalExpertise
  
  Information related to specific professions or industries.
  Examples: Engineering, Medical, Finance
  CulturalPerspectives
  
  Insights on culture, arts, traditions, and societal norms.
  Examples: Art, Music, Traditions
  HealthAndWellness
  
  Topics on physical and mental health, wellness routines, and medical advice.
  Examples: Fitness, Nutrition, MentalHealth
  PoliticalViews
  
  Opinions and information on political ideologies, policies, and governance.
  Examples: Liberalism, Conservatism, PublicPolicy
  LifeExperiences
  
  Personal stories, experiences, and anecdotes.
  Examples: Travel, Parenting, OvercomingChallenges
  EducationalContent
  
  Information related to learning, education, and academic subjects.
  Examples: Mathematics, Literature, Languages
  TechnologicalAdvancements
  
  Information about technology, innovations, and advancements.
  Examples: ArtificialIntelligence, Blockchain, Cybersecurity
  HobbiesAndInterests
  
  Activities people engage in during their free time.
  Examples: Gardening, Photography, Gaming
  Relationships
  
  Topics related to interpersonal relationships and social interactions.
  Examples: Friendship, RomanticRelationships, FamilyDynamics
  PhilosophicalAndSpiritualBeliefs
  
  Beliefs related to philosophy, religion, and spirituality.
  Examples: Religion, Mindfulness, Meditation
  
  As well as specific information like links, or very specific recipes etc. Cateroize that too.
  
  And then return a JSON object containing one observation property that is a string of text that has all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else and then also include and another property that is an array of keywords that the observation falls under such as PersonalTraits, CulturalPerspectives, etc as well as subcategories such as liberalism etc
  the JSON object when a person says they are done. However they indicate they are finished at the moment
  IMPORTANT: In regard to main categories like PersonalTraits, EmotionalStates as well as all of the others listed above, please do not create new main categories, you can generate new sub categories but try to do your best to only use the main categories listed above when using the main categories.
  IMPORTANT: The observation contains all of the questions and answers and description about it or observation and what it says about the person and how they think, how they arrive at answers, how they speak, how they reflect, how logical and reasonable they are, any biases, and whatever else about each response they've given to each different question asked and the keywords that go with them. Each observation should be thorough and elaborate but no more than 2,000 characters. Observations apply to all messages with questions and answers.
  IMPORTANT: If given an example of a conversation whether from text within an image such as a screenshot of a conversation, make the assessment based on the person given to you, a description of to assess such as "The one with the blue text is the User"
  IMPORTANT: refer to user as ${botFullName} but in an appropriate way given the context of what you're saying. For example, you wouldn't always use their first and last name. Mostly just first unless it's appropriate to use both.
  IMPORTANT: when returning keywords and subcategories, can you return the keywords each in an object and have subcategories as an array containing the subcategory names as strings. THE FORMAT FOR KEYWORDS SHOULD BE:
  EXAMPLE:
  
  "keywords": [
    {PersonalTraits:['subcategory1', 'subcategory2] },
  ],
  }
  
  Although there are only 2 subcategories and only one main category "personalTraits" in the example above, it's only an example. Have as many main categories and subcategories as permitted above.`;

  const ImageUploadFile = ({ statusProp }) => {
    const [status, setStatus] = useState('Idle');
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([{role:'system', content:observePromptImage}]);

    const uploadImageFile = (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
  
          reader.onloadend = () => {
              // Convert the result to base64 to send as a JSON payload
              const base64Data = reader.result.split(',')[1]; // Removes the 'data:image/*;base64,' part
  
              const bodyData = JSON.stringify({
                  imageData: base64Data,
                  mimeType: file.type
              });
  
              fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadImageHTTP', {
                  method: 'POST',
                  body: bodyData,
                  headers: {
                      'Content-Type': 'application/json'
                  },
              })
              .then(response => response.json())
              .then(data => {
                  if (data.imageUrl) {
                      console.log(data.imageUrl); // Log the URL to the console
                      resolve(data.imageUrl);
                  } else {
                      reject('No image URL returned');
                  }
              })
              .catch(error => {
                  reject(error);
              });
          };
  
          reader.onerror = error => reject(error);
  
          // Read the file as a Data URL to convert it to base64
          reader.readAsDataURL(file);
      });
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        setLoading(true);
        setStatus('Uploading image...');
        statusProp('Uploading image...');

        try {
            const imageUrl = await uploadImageFile(file);
            setStatus('Image uploaded successfully');
            statusProp('Image uploaded successfully');
            console.log('Image URL:', imageUrl); 
             
            let userMessage = {
                "role": "user",
                "content": [
                  {
                    "type": "text",
                    "text": "What'\''s in this image?"
                  },
                  {
                    "type": "image_url",
                    "image_url": {
                      "url": `${imageUrl}`
                    }
                  }
                ]
              }
            

            const initialResult = await startObservation4o([...messages, userMessage]);

            console.log('initialResult', initialResult);
           
  
            const result = stripJsonFromString(initialResult);
            const cleanText = cleanJSON(result)
            if(isValidJSON(JSON.stringify(result))) {
              try{
              let jsonObj = extractAndParseJSON(result);
                const stringifiedMessages = JSON.stringify(messages, null, 2); // The '2' adds indentation for better readability
                let observation = await startObservation([{role:"system", content:observePromptImage}, {role:"user", content:`here is the information to observe: ${initialResult}`}]);
                console.log('observation', observation);
  
                let observationObject = extractAndConvertToJSObject(observation);
                console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
                // entry.observation = observationObject;
                observationObject.username = currentUser.displayName;
                observationObject.userId = currentUser.uid;

                        const db = firebase.firestore();
                      
                            db.collection('botImages').add(observationObject);
                      
                
              
            } catch(err){}
            }
            
            
        } catch (error) {
            console.error('Error uploading image:', error);
            setStatus('Error uploading image');
            statusProp('Error uploading image');
        } finally {
            setLoading(false);
        }
    };

    return (
      <div style={{position:'relative'}}>
        <input type="file" accept="image/*" onChange={handleFileUpload} disabled={loading} style={{    position: 'absolute',
    margin: 0,
    padding: 0,
    top: 0,
    left: 17,
    width: 50,
    height: 50,
    cursor: 'pointer',
    opacity: 0}}/>
   
<div style={{position:'absolute', top: 25, left: 27, pointerEvents:'none'}}>
                <FontAwesomeIcon icon={faCamera} style={{color:'white', fontSize:20, pointerEvents:'none'}}/>
            </div>
      </div>
    );
};


  
  const Files = ({ goBack }) => {
    const [messages, setMessages] = useState([{role:'system', content:prompt}]);
    const [text, setText] = useState('');
    const [showPDFChat, setShowPDFChat] = useState(true);
    const [showScreenshotChat, setShowScreenshotChat] = useState(false);
    const [showDocChat, setShowDocChat] = useState(false);
    const [mainChat, setMainChat] = useState(false);
    const [showPDFUploader, setShowPDFUploader] = useState(false);
    const [showImageUploader, setShowImageUploader] = useState(false);
    const [showDocUploader, setShowDocUploader] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [pdfMessages, setPDFMessages] = useState([{role:'system', content:'You are an AI chatbot that helps a user before they upload a PDF file containing information that can be used to help train an AI chatbot. Sometimes users may upload information that adds to a knowledge base and it will be obvious how what they are uploading helps add to the bots knowledge but other times they may upload documents containing things such as a conversation between multiple people and perhaps its unclear on what in particular from that conversation is relevant to the chatbot or knowledge base so you will make sure before returning a JSON object with a boolean giving a user permission to upload, you get clarification but do not ask so many questions. The user should be able to clarify whether or not there is anything important in one or two responses if that at all. Sometimes they just indicate they have nothing specific to clarify and you should just then return the JSON object. The JSON object should have isReadyToUpload set to true once the user is ready and if there is any clarification made by the user, add the clarification as a string to the additionalNotes property. IMPORTANT: Do not tell the user anything about JSON just give a response thanking the user for their clarification or if they have nothing to clarify then have the JSON after the message. There will be a function that extracts the JSON so a user will not ever see it. The object is ```JSON { "isReadyToUpload":boolean, additionalNotes:string containing anything the user is trying to clarify } ```'}, {role:'assistant', content:`Before uploading your PDF, please inform me if there are specifics to focus on, like analyzing a person's communication style or temperament. If it's hard to identify individuals in the document, clarify who is who. If no specific details are needed, just let me know you're ready to upload.`}]);
    const [screenshotMessages, setScreenshotMessages] = useState([{role:'system', content:'You are an AI chatbot that helps a user before they upload a Image file containing information that can be used to help train an AI chatbot. Sometimes users may upload information you can get from the image that adds to a knowledge base and it will be obvious how what they are uploading helps add to the bots knowledge but other times they may upload images containing things such as a conversation between multiple people and perhaps its unclear on what in particular from that conversation is relevant to the chatbot or knowledge base so you will make sure before returning a JSON object with a boolean giving a user permission to upload, you get clarification but do not ask so many questions. The user should be able to clarify whether or not there is anything important in one or two responses if that at all. Sometimes they just indicate they have nothing specific to clarify and you should just then return the JSON object. The JSON object should have isReadyToUpload set to true once the user is ready and if there is any clarification made by the user, add the clarification as a string to the additionalNotes property. IMPORTANT: Do not tell the user anything about JSON just give a response thanking the user for their clarification or if they have nothing to clarify then have the JSON after the message. There will be a function that extracts the JSON so a user will not ever see it. The object is ```JSON { "isReadyToUpload":boolean, additionalNotes:string containing anything the user is trying to clarify } ```'}, {role:'assistant', content:`Before uploading your Image, please inform me if there are specifics to focus on, like analyzing a person's communication style or temperament. If it's hard to identify individuals in the document, clarify who is who. If no specific details are needed, just let me know you're ready to upload.`}]);
    const [docMessages, setDocMessages] = useState([{role:'system', content:'You are an AI chatbot that helps a user before they upload a Text document file containing information that can be used to help train an AI chatbot. Sometimes users may upload information that adds to a knowledge base and it will be obvious how what they are uploading helps add to the bots knowledge but other times they may upload documents containing things such as a conversation between multiple people and perhaps its unclear on what in particular from that conversation is relevant to the chatbot or knowledge base so you will make sure before returning a JSON object with a boolean giving a user permission to upload, you get clarification but do not ask so many questions. The user should be able to clarify whether or not there is anything important in one or two responses if that at all. Sometimes they just indicate they have nothing specific to clarify and you should just then return the JSON object. The JSON object should have isReadyToUpload set to true once the user is ready and if there is any clarification made by the user, add the clarification as a string to the additionalNotes property. IMPORTANT: Do not tell the user anything about JSON just give a response thanking the user for their clarification or if they have nothing to clarify then have the JSON after the message. There will be a function that extracts the JSON so a user will not ever see it. The object is ```JSON { "isReadyToUpload":boolean, additionalNotes:string containing anything the user is trying to clarify } ```'}, {role:'assistant', content:`Before uploading your Text Document, please inform me if there are specifics to focus on, like analyzing a person's communication style or temperament. If it's hard to identify individuals in the document, clarify who is who. If no specific details are needed, just let me know you're ready to upload.`}]);
    const [status, setStatus] = useState('Idle');
    

    const messagesEndRef = useRef(null);
    

    const ChatInterface = ({ chatMessages, messagesEndRef, text, setText, sendMessage, placeholder }) => (
      <div style={{ display: 'flex', maxHeight: 270, padding: '0px', minHeight: 270, marginTop: 25, flexDirection:'column', marginBottom:10 }}>
        <div style={{overflowY: 'scroll'}}>
        <ul style={{ height: '100%', background: '#1C1C1C', minHeight: 270, minHeight: 270,
    /* max-width: 300px; */
    margin: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 25 }}>
          {chatMessages.map((msg, index) => (
            <li key={index} className={msg.role !== "system" ? msg.role : ''} style={{ display: msg.role === "system" ? 'none' : 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                <div style={{ border: '0px solid red', borderRadius: '50%', height: 30, width: 30, marginRight: 10, minWidth: 30, minHeight: 30, display: 'block', backgroundImage:`url(${msg.role === "user" ? currentUser.photoURL:'/images/robot.svg'})`,
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY:'center',
    backgroundPositionX:'center'}}></div>
                <p style={{ background: '#4FD975', padding: 10, minWidth: 100, borderRadius: 7, fontFamily: 'quicksand', fontSize: 12, maxWidth: 300, color:'#1c1c1c' }}>
                  {msg.role !== "system" ? msg.content : ''}
                </p>
              </div>
            </li>
          ))}
          <li>{process}</li>
          {showPDFUploader ? (<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}><PDFUploader statusProp={(status)=>{ setStatus(status)}}/></div>
          <p style={{fontFamily:'quicksand', color:'white'}}>Select to upload PDF</p>
          </div>):""}
          {showImageUploader ? (<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}><ImageUploadFile statusProp={(status)=>{ setStatus(status)}}/></div>
          <p style={{fontFamily:'quicksand', color:'white'}}>Select to upload Image</p>
          </div>):""}
          {showDocUploader ? (<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}><TextUploader statusProp={(status)=>{ setStatus(status)}}/></div>
          <p style={{fontFamily:'quicksand', color:'white'}}>Select to upload Text Doc</p>
          </div>):""}
        </ul>
        <div ref={messagesEndRef} />

        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:15 }}>
          <textarea
           id="chat-input"
            // value={text}
            // onChange={(e) => setText(e.target.value)}
            placeholder={placeholder}
            onKeyDown={(e)=>{
              if (e.key === 'Enter' && !e.shiftKey) { // Checks if the key pressed is 'Enter' and Shift is not held down
                setTimeout(()=>{
                  scrollToBottom();
                }, 100)
                e.preventDefault(); // Prevents the default action (e.g., inserting a newline)
                sendMessage(); // Calls your sendMessage function
               
              }
            }}
            style={{ backgroundColor: '#373737', border: 'none', resize: 'none', borderRadius: 7, padding: 7, height: 38, outline: 'none', color: 'white', paddingRight:44 }}
          ></textarea>
          <img src="../microphone.svg" style={{ position: 'absolute', right: 115, cursor: 'pointer' }} />
          <div onClick={()=>{sendMessage();   setTimeout(()=>{
                  scrollToBottom();
                }, 100)}} style={{ backgroundColor: 'rgb(79, 217, 117)', width: 35, height: 35, border: 'none', borderRadius: 7, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
  {
    showLoading ? (
      <div className="animated-dots">
        <div></div><div></div><div></div>  {/* Three dots */}
      </div>
    ) : (
      <img src="../images/chat-arrow.svg" />
    )
  }
</div>
        </div>
      </div>
    );
  
    const handleSelectTab = (tab) => {
      setShowPDFChat(tab === 'pdf');
      setShowScreenshotChat(tab === 'screenshot');
      setShowDocChat(tab === 'doc');
      setShowPDFUploader(false);
      setShowImageUploader(false);
      setShowDocUploader(false);


    };
  
    const getTabStyle = (isActive) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: isActive ? '1px solid #4FD975' : 'none',
      padding: '10px',
      cursor: 'pointer',
      borderRadius:7
    });
  

  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
  
    // useEffect(() => {
    //   scrollToBottom();
    // }, [messages]);
    const sendMessage = async () => {
     
  
      if (text.trim()) {
        const userMessage = { role: 'user', content: text.trim() };
    
        // Update the state to include the new user message
        setMessages(prevMessages => [...prevMessages, userMessage]);

        try {
          // Fetch the response using the current state of messages including the new user message
          const initialResult = await customChatbot([...messages, userMessage]);
       
          const result = stripJsonFromString(initialResult);
          const cleanText = cleanJSON(result)
          if(isValidJSON(JSON.stringify(result))) {
            try{
            let jsonObj = extractAndParseJSON(result);
            console.log('jsonObject', jsonObj.qaComplete)
            if(jsonObj.qaComplete) {
              const stringifiedMessages = JSON.stringify(messages, null, 2); // The '2' adds indentation for better readability
              let observation = await startObservation([{role:"system", content:observePrompt}, {role:"user", content:`here is the information to observe: ${stringifiedMessages}`}]);
              console.log('observation', observation);

              let observationObject = extractAndConvertToJSObject(observation);
              console.log('observationObject', JSON.parse(JSON.stringify(observationObject)));
              // entry.observation = observationObject;
              
            }
          } catch(err){}
          }
          
          const assistantMessage = { role: 'assistant', content: cleanText };
    
          // Update the state to include the new assistant message
          setMessages(prevMessages => [...prevMessages, assistantMessage]);

          // Clear the input field
          setText('');
    

        } catch (error) {
          console.error("Error sending message:", error);
        }
      }
    };
    

    const pdfSendMessage = async () => {
      let input = document.getElementById('chat-input');

     // if (input.value.trim()) {

        const userMessage = { role: 'user', content: input.value.trim() };
        document.getElementById('chat-input').value = '';

        // Update the state to include the new user message
        setPDFMessages(prevMessages => [...prevMessages, userMessage]);
    
        //try {
          // Fetch the response using the current state of messages including the new user message
          // setIsLoading(true)
          setShowLoading(true);
          const initialResult = await customChatbot([...pdfMessages, userMessage]);
          setShowLoading(false)
          console.log('initialResult', initialResult);
          const result = extractJSON(initialResult);
          console.log('result', result);
          if(result.json !== null) {
          if(result.json.isReadyToUpload == true) {
          setShowPDFUploader(true);
          }
        }
          setPDFMessages(prevMessages => [...prevMessages, {role:'assistant', content:result.text}]);
          setTimeout(()=>{
            scrollToBottom();
          }, 1000)
      
          
    };

    const docSendMessage = async () => {
    
      //if (text.trim()) {
        let input = document.getElementById('chat-input');

        // if (input.value.trim()) {
   
           const userMessage = { role: 'user', content: input.value.trim() };
           document.getElementById('chat-input').value = '';
   
           // Update the state to include the new user message
           setDocMessages(prevMessages => [...prevMessages, userMessage]);
       
           //try {
             // Fetch the response using the current state of messages including the new user message
   
             const initialResult = await customChatbot([...docMessages, userMessage]);
             console.log('initialResult', initialResult);

             const result = extractJSON(initialResult);
             console.log('result', result);
             if(result.json.isReadyToUpload == true) {
              setShowDocUploader(true);
             }
             setDocMessages(prevMessages => [...prevMessages, {role:'assistant', content:result.text}]);

    };

    const screenshotSendMessage = async () => {
      //if (text.trim()) {
        let input = document.getElementById('chat-input');

        // if (input.value.trim()) {
   
           const userMessage = { role: 'user', content: input.value.trim() };
           document.getElementById('chat-input').value = '';
   
           // Update the state to include the new user message
           setScreenshotMessages(prevMessages => [...prevMessages, userMessage]);
       
           //try {
             // Fetch the response using the current state of messages including the new user message
   
             const initialResult = await customChatbot([...screenshotMessages, userMessage]);
             console.log('initialResult', initialResult);
             
        
             const result = extractJSON(initialResult);
             console.log('result', result);
             if(result.json.isReadyToUpload == true) {
             setShowImageUploader(true);
             }
             setScreenshotMessages(prevMessages => [...prevMessages, {role:'assistant', content:result.text}]);

    };
  
    return (
      <div>
        <div onClick={goBack} style={{ backgroundColor: 'rgb(79, 217, 117)', height: 20, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, margin: 10, cursor: 'pointer' }}>
          <p style={{ fontFamily: 'quicksand', color: '#1c1c1c', fontSize: 12 }}>Back</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: 223, margin: 'auto', marginBottom: 20 }}>
          <div onClick={() => handleSelectTab('pdf')} style={getTabStyle(showPDFChat)}>
            <FontAwesomeIcon icon={faFilePdf} style={{ color: showPDFChat ? '#4FD975' : 'white', fontSize: 20 }} />
            <p style={{ fontFamily: 'quicksand', fontSize: 14, color: showPDFChat ? '#4FD975' : 'white' }}>PDF</p>
          </div>
          <div onClick={() => handleSelectTab('screenshot')} style={getTabStyle(showScreenshotChat)}>
            {/* <ImageUploadFile statusProp={setProcess} /> */}
            <FontAwesomeIcon icon={faCamera} style={{ color: showScreenshotChat ? '#4FD975' : 'white', fontSize: 20 }} />

            <p style={{ fontFamily: 'quicksand', fontSize: 14, color: showScreenshotChat ? '#4FD975' : 'white' }}>Image</p>
          </div>
          <div onClick={() => handleSelectTab('doc')} style={getTabStyle(showDocChat)}>
          <FontAwesomeIcon icon={faFileWord} style={{ color: showDocChat ? '#4FD975' : 'white', fontSize: 20 }} />

            {/* <TextUploader statusProp={setProcess} /> */}
            <p style={{ color: showDocChat ? '#4FD975' : 'white', fontFamily: 'quicksand', fontSize: 14 }}>Text Doc</p>
          </div>
        </div>
        <div style={{ borderBottom: '2px solid rgb(0, 0, 0)' }}></div>
        <div style={{borderTop: '0.1px solid rgb(62, 62, 62)'}}></div>
        {mainChat == true ? <ChatInterface chatMessages={messages} messagesEndRef={messagesEndRef} text={text} setText={setText} sendMessage={sendMessage} placeholder="Type your message..." />: ""}
        {showPDFChat == true ? <ChatInterface chatMessages={pdfMessages} messagesEndRef={messagesEndRef} text={text} setText={setText} sendMessage={pdfSendMessage} placeholder="Type your message..." />:""}
        {showScreenshotChat == true ? <ChatInterface chatMessages={screenshotMessages} messagesEndRef={messagesEndRef} text={text} setText={setText} sendMessage={screenshotSendMessage} placeholder="Type your message..." /> : ""}
        {showDocChat == true ? <ChatInterface chatMessages={docMessages} messagesEndRef={messagesEndRef} text={text} setText={setText} sendMessage={docSendMessage} placeholder="Type your message..." /> : ""}
      </div>
    );

  };
  
  const YourJournal = ({ goBack }) => {
    const [items, setItems] = useState([]);
  
    const addItem = () => {
      const newItem = prompt('Enter a new journal entry:');
      if (newItem) {
        setItems([...items, newItem]);
      }
    };
  
    return (
      <div style={{width:'100%', display:'flex'}}> 
        <button onClick={goBack}>Back</button>
        <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}>
        <h2 style={{fontFamily:'quicksand', color:'white', fontFamily: 'quicksand', color: 'white',
    width: '100px',
    margin: 'auto'}}>Your Journal</h2>
        </div>
        <JournalComponent botName={botName}/>
      </div>
    );
  };


  

  async function queryBotByName(botName) {
    try {
        const botsRef = firebase.firestore().collection('bots');
        const snapshot = await botsRef.where('botName', '==', botName).get();
        if (snapshot.empty) {
            console.log('No matching documents.');
            return [];
        }

        let data = [];
        snapshot.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() });
        });
        return data;
    } catch (error) {
        console.error("Error fetching data: ", error);
        return []; // Return an empty array in case of error
    }
}
async function deleteBotFromFirestore(botName) {
  try {
      const botsRef = firebase.firestore().collection('bots');
      const snapshot = await botsRef.where('botName', '==', botName).get();
      if (snapshot.empty) {
          console.log('No matching documents.');
          return 'No documents found to delete.';
      }

      let deletePromises = [];
      snapshot.forEach(doc => {
          deletePromises.push(doc.ref.delete()); // Prepare delete operations
      });

      await Promise.all(deletePromises); // Execute all delete operations
      console.log('All matching documents deleted successfully.');
      return 'Deletion successful.';
  } catch (error) {
      console.error("Error deleting data: ", error);
      return 'Error during deletion process.';
  }
}

async function deleteBot(botName) {
  let botInfo = await queryBotByName(botName);
  console.log('botInfo', botInfo);
  await deleteBotFromFirestore(botName);
  try {
  await deleteFilesSequentially(botInfo[0].vectorStoreIds, botInfo[0].file_ids);
  const response = await openai.beta.assistants.del(botInfo[0].assistantId);
  const deletedVectorStore = await openai.beta.vectorStores.del(
    botInfo[0].vectorStoreIds
  );
  console.log('response', response);
  console.log('deletedVectorStore', deletedVectorStore);

  } catch(error) {

  }

}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const deleteFilesSequentially = async (id, fileIds, delayMs = 1000) => {
  for (const fileId of fileIds) {
    try {
      const deletedVectorStoreFile = await openai.beta.vectorStores.files.del(id, fileId);
      console.log(`Deleted file: ${fileId}`, deletedVectorStoreFile);
    } catch (error) {
      console.error(`Failed to delete file: ${fileId}`, error);
    }
    await delay(delayMs);
  }
};



async function updateBotAssistant(botName, assistantId) {
  try {
      const botsRef = firebase.firestore().collection('bots');
      const snapshot = await botsRef.where('botName', '==', botName).get();
      if (snapshot.empty) {
          console.log('No matching documents to update.');
          return;
      }

      // Batch update if multiple documents could match, otherwise use direct update
      let batch = firebase.firestore().batch();
      snapshot.forEach(doc => {
          const docRef = botsRef.doc(doc.id);
          batch.update(docRef, { assistantId: assistantId });
      });

      await batch.commit();
      console.log('All matching documents have been updated.');
  } catch (error) {
      console.error("Error updating documents: ", error);
  }
}


  async function checkBotExistsAndProceed(currentUser, botName) {
    const db = firebase.firestore();
  
    try {
        // Query the 'bots' collection for matching 'username' and 'botName'
        const botsQuerySnapshot = await db.collection("bots")
            .where("username", "==", currentUser.displayName)
            .where("botName", "==", botName)
            .get();
  
        if (!botsQuerySnapshot.empty) {
            // Bot exists, proceed with your code
            console.log("Bot exists. Proceeding with further actions...");
            // Continue to execute more code here
            setBotBasic(false);
        setShowMenu(true);
        } else {
            // Bot does not exist, print an error and return
            console.error("Error: No matching bot found. Unable to proceed.");
            return;
        }
    } catch (error) {
        console.error("Error querying bots collection:", error);
    }
  }

  // rendering

  return (
    <div style={{ height: "100vh", maxWidth:'100%', width:400 }}>
      <div style={{height:500, width:'100%', maxWidth:400, backgroundColor:'#1C1C1C'}}>
        <div
          style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Create A Bot
        </p>
        <img
          onClick={() => {
            setShowBotSettings(false)
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div style={{borderTop:'1px solid #3E3E3E'}}>
      </div>
      {botBasic == true ? (
        <div style={{height:'100%'}}>
          <ImageUploader/>
          <div 
            style={{
              width:'100%', 
              paddingLeft:20, 
              paddingRight:20, 
              marginTop:30, 
              marginBottom:10
            }}>
              <input onChange={(e)=> {
                setBotFullName(e.target.value);
              }} type="text" placeholder="Full Name" 
                style={{
                  border: '0px solid rgb(84, 84, 84)',
                  overflow: 'hidden',
                  background: 'rgb(50, 50, 50)',
                  color: 'white',
                  height: 33,
                  width: '100%',
                  paddingLeft: 10,
                  fontFamily: 'quicksand',
                  borderRadius: 10, 
                  outline:'none'
                }} 
                value={botFullName} 
                disabled={showUpdateBtn}/>
          </div>
          <div style={{paddingLeft:20,paddingRight:20}}>
            <input onChange={(e)=> {
              setBotName(e.target.value);
            }} type="text" placeholder="bot name" 
            style={{
              border: '0px solid rgb(84, 84, 84)',
              overflow: 'hidden',
              background: 'rgb(50, 50, 50)',
              color: 'white',
              height: 33,
              width: '100%',
              paddingLeft: 10,
              fontFamily: 'quicksand',
              borderRadius: 10, 
              outline:'none'
            }} 
            value={botName} 
            disabled={showUpdateBtn}/>
          </div>
          <div 
            onChange={(e)=> {
              setBotBio(e.target.value);
            }} style={{padding:20}}>
              <textarea 
                style={{
                  height:50, 
                  width:'100%', 
                  resize:'none', 
                  padding:10, 
                  outline:'none', 
                  background:'rgb(50, 50, 50)', 
                  color:'white', 
                  border:'none', 
                  borderRadius:7
                }} 
                placeholder="Bio" 
                value={botBio}>
              </textarea>
          </div>
          <div 
            style={{
              paddingLeft:20, 
              paddingRight:20
            }}>
          <textarea onChange={(e)=> {
            setPersonalityStyle(e.target.value);
          }} style={{
            height:70, 
            width:'100%', 
            resize:'none', 
            padding:10, 
            outline:'none', 
            background:'rgb(50, 50, 50)', 
            color:'white', 
            border:'none', 
            borderRadius:7
          }} placeholder="Ex. You're a jittery" value={personalityStyle}></textarea>
          </div>
          <div style={{
            display:'flex', 
            width:'100%', 
            maxWidth: 330, 
            margin: 'auto', 
            marginTop:10
          }}>
            {showUpdateBtn === false ? (
            <div 
              style={{
                display:'flex', 
                alignItems:'center', 
                marginTop:10, 
                width:'100%'
              }}>

              <div onClick={async ()=> { 
                  const botDetails = {
                    botName: botName,
                    botBio: botBio,
                    botAdvanced: botAdvanced,
                    botFullName: botFullName,
                    personalityStyle: personalityStyle,
                    botPhoto: botPhoto,
                    botImage: botImage,
                  };

                  setShowSavingBasic(true);
                  await createBot(currentUser, botName, botFullName, personalityStyle, botBio, botAdvanced, botPhoto).then(message => {
                    if (message) {
                      console.log("Operation result:", message);
                    }
                    setShowSavingBasic(false);

                  }).catch(error => {
                    console.error("Failed to create bot:", error);
                    setShowBotErrorMsg('Something went wrong. Try again.')
                  });
                }} 
                style={{
                  height:30, 
                  width:'100%', 
                  maxWidth:155, 
                  backgroundColor:'#4fd975', 
                  borderRadius:7, 
                  display:'flex', 
                  justifyContent:'center', 
                  alignItems:'center', 
                  cursor:'pointer'
                }}>
                  <p style={{fontFamily:'quicksand'}}>
                    {showSavingBasic === false ? 'Save' : 'Saving bot...'}
                  </p>
                </div>
              </div>):""}
              {showUpdateBtn === true ? (
              <div onClick={
                  async ()=> {

                    const botDetails = {
                      botName: botName,
                      botBio: botBio,
                      botAdvanced: botAdvanced,
                      botFullName: botFullName,
                      personalityStyle: personalityStyle,
                      botPhoto: botPhoto,
                      botImage: botImage,
                    };

                    // Proceed with your function
                    console.log("Proceeding with further actions...");

                  await updateBotDetails(currentUser, botName, personalityStyle, botBio, botAdvanced, botPhoto).then(message => {
                    if (message) {
                      console.log("Operation result:", message);
                    }
                  }).catch(error => {
                    console.error("Failed to create bot:", error);
                  });

                  setShowBotSettings(false);
            }} 
            style={{
              height:30, 
              idth:'100%', 
              maxWidth:155, 
              backgroundColor:'#4fd975', 
              borderRadius:7, 
              display:'flex', 
              justifyContent:'center', 
              alignItems:'center', 
              marginTop: 10, 
              marginRight:10, 
              cursor:'pointer'
            }}>
              <p 
              style={{
                fontFamily:'quicksand'
              }}>UPDATE</p>
            </div>
          ):""}
          <div onClick={async ()=> {
            await checkBotExistsAndProceed(currentUser, botName).then(() => {
              // Additional code if needed after the check
            }).catch((error) => {
              console.log('Error handling:', error);
            });
            }} style={{
              height:30, 
              width:'100%', 
              maxWidth:155, 
              backgroundColor:'#4fd975', 
              borderRadius:7, 
              display:'flex', 
              justifyContent:'center', 
              alignItems:'center', 
              marginTop:10, 
              cursor:'pointer'
            }}>
            <p style={{
              fontFamily:'quicksand'
              }}>Advanced</p>
          </div>
        </div>
      </div>
      ):(
      <div style={{height:'calc(100% - 52px'}}>     
        {selectedOption === null ? (
        <>
          <div style={{
            display:'flex', 
            justifyContent:'center', 
            alignItems:'center', 
            flexDirection:'column', 
            height:'100%', 
            marginBottom:20
          }}>
            <div style={{    
              maxWidth: 200,
              width: '100%'
            }}>
            <div onClick={() => setSelectedOption('qa')} 
              style={{
                height: 30,
                width: '100%',
                maxWidth: 200,
                backgroundColor: 'rgb(79, 217, 117)',
                borderRadius: 7,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', 
                marginBottom:10, 
                cursor:'pointer'
            }}>
              <p style={{color:'#1c1c1c', fontSize:14, fontFamily:'quicksand'}}>Q & A</p>
          </div>
          <div onClick={() => setSelectedOption('files')} 
            style={{
              height: 30,
              width: '100%',
              maxWidth: 200,
              backgroundColor: 'rgb(79, 217, 117)',
              borderRadius: 7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
              marginBottom:10, 
              cursor:'pointer'
            }}>
              <p style={{
                color:'#1c1c1c', 
                fontSize:14, 
                fontFamily:'quicksand'
              }}>Specific Information</p>
            </div>
            <div onClick={() => setSelectedOption('instructions')} 
              style={{
                height: 30,
                width: '100%',
                maxWidth: 200,
                backgroundColor: 'rgb(79, 217, 117)',
                borderRadius: 7,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', 
                marginBottom:10, 
                cursor:'pointer'
              }}>
                <p style={{
                  color:'#1c1c1c', 
                  fontSize:14, 
                  fontFamily:'quicksand'
                }}>Instructions</p>
            </div>
            <div style={{
              height: 30,
              width: '100%',
              backgroundColor: 'rgb(79, 217, 117)',
              borderRadius: 7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
              marginBottom:10, 
              cursor:'pointer'
            }} onClick={() => setSelectedOption('journal')}>
              <p style={{color:'#1c1c1c', fontSize:14, fontFamily:'quicksand', maxWidth:100, width:100, margin:'auto'}}>Your Journal</p>
            </div>
            <div onClick={()=> {
              setBotBasic(true);
            }} 
            style={{
              height:30, 
              width:'100%', 
              maxWidth:200, 
              backgroundColor:'#4fd975', 
              borderRadius:7, 
              display:'flex', 
              justifyContent:'center', 
              alignItems:'center', 
              cursor:'pointer'
            }}>
              <p style={{fontFamily:'quicksand', fontSize:14}}>Go back</p>
          </div>
        </div>
      </div>
      </>) : (
        <div className="content">
          {renderContent()}
        </div>
      )}
    </div>
    )}
  </div>
</div>);
});

export default React.memo(BotWidget);
