import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { firebase } from "../Components/firebase/firebase";

class AuthenticationStore {
  constructor() {
    makeAutoObservable(this);
  }

  loggedIn = false;
  mailchimp = true;
  logout = () => {
    this.loggedIn = false;
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  showModal = false;
  userId = "";
  fullname = "";
  displayName = "";
  bio = "";
  email = "";
  photoURL = "/images/Flowroom_Default_Avatar.png";
  isFlowOwner = false;
  showUserModal = false;
  isProfileLoaded = false;
  hideModal = true;
  signInUpBtnState = false;
  notificationNumber = 0;
  showSignIn = false;
  showSignUp = false;
  passwordRecovery = false;
  gptConfirmation = false;
  showMessageNotification = false;
  isDevAccount = false;
  roomShowLoginModal = false;
  showLoginModalMainPage = false;
  setIsLoggedIn(bool) {
    this.loggedIn = bool;
  }

  setShowModal(bool) {
    this.showModal = bool;
  }
  setHideModal(bool) {
    this.hideModal = bool;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setFullname(fullname) {
    this.fullname = fullname;
  }

  setDisplayName(displayname) {
    this.displayName = displayname;
  }

  setBio(bio) {
    this.bio = bio;
  }

  setEmail(email) {
    this.email = email;
  }

  setPhotoURL(url) {
    this.photoURL = url;
  }

  setIsFlowOwner(bool) {
    this.isFlowOwner = bool;
  }

  setIsProfileLoaded(isProfileLoaded) {
    this.isProfileLoaded = isProfileLoaded;
  }

  setShowUserModal(bool) {
    this.showUserModal = bool;
  }

  setSignInUpBtnState(bool) {
    this.signInUpBtnState = bool;
  }

  isFlowOwnerByUsername(username) {
    return firebase.auth().currentUser &&
      firebase.auth().currentUser.displayName
      ? firebase.auth().currentUser.displayName === username
      : false;
  }
  isMailChimp(username) {
    this.mailchimp = username;
  }
  setNotificationNumber(number) {
    this.notificationNumber = number;
  }
  setShowSignIn(bool) {
    this.showSignIn = bool;
  }
  setShowSignUp(bool) {
    this.showSignUp = bool;
  }
  showPasswordRecovery(bool) {
    this.passwordRecovery = bool;
  }
  showGptConfirmation(bool) {
    this.gptConfirmation = bool;
  }
  setShowMessageNotification(bool) {
    this.showMessageNotification = bool;
  }
  setIsDevAccount(bool) {
    this.isDevAccount = bool;
  }
  setRoomShowLoginModal(bool) {
    this.roomShowLoginModal = bool;
  }
  setShowLoginModalMainPage(bool) {
    this.showLoginModalMainPage = bool;
  }
}

export const AuthenticationStoreContext = createContext(
  new AuthenticationStore()
);
