import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class WidgetStore {
  constructor() {
    makeAutoObservable(this);
  }

  isStartWidgetPolling = false;
  currentlySelectedImage = "";
  images = [];
  textInputs = [];
  isReplaceJS = false;
  ReplacedJS = "";
  widgetInstanceChange = {};
  widgetArray = {};
  currentWidgetCopy = [];
  updateMenu = false;
  widgetImageArray = [];
  widgetDefaultArray = [];
  isSpecialReplaceImage = false;
  isSpecialReplaceImageValue = "";
  windowObject = {};
  currentlySelectedFunction = "";
  swipeIndex = 0;
  jumpTo = [];
  widgets = [];
  replaceValue = "";
  replaceWith = "";
  replaceLine = "";
  getSelectedRemix = false;
  editorSelected = "";
  widgetObject = {};
  widgetReplaceSelection = {};
  widgetActualInstance = 0;
  getNewEndColumn = false;
  widgetValueLength = 0;
  widgetOnOff = false;
  deleteWidgetItem = false;
  widgetDeleteInstance = 0;
  imageIndexes = [];
  textInputIndexes = [];
  colorSelectIndexes = [];
  currentlySelectedValue = "";
  colorIndexes = [];
  sliderIndexes = [];
  switchIndexes = [];
  selectIndexes = [];
  checkboxIndexes = [];
  calendarIndexes = [];
  radioIndexes = [];
  resetImageSelect = false;
  imageUploaderCount = 0;
  textInputCount = 0;
  noNewValue = true;
  hasRemixableAI = false;
  modulePrompt = "";
  aiModuleInstructions = "";
  aiModuleAbout = "";
  aiModulePlaceholder = "";
  aiModuleDescription = "";
  aiModuleName = "";
  currentlySelectedFilter = "Featured";
  initialScreen = false;
  showRemixModal = false;
  showPostModal = false;
  showDetailsModal = false;
  colorInputCount = 0;
  sliderInputCount = 0;
  switchInputCount = 0;
  selectInputCount = 0;
  checkboxInputCount = 0;
  calendarInputCount = 0;
  currentlySelectedEditorValue = "";
  isLoading = false;
  dropImageValue = "";
  textValue = "";
  errorMessage = false;
  advanced = false;
  currentErrorMessage = "";
  loadingOneMoment = "";
  postModalFeed = false;
  currentSignUpPassword = "";
  currentModalHeight = 444;
  showReportModal = false;
  showBlockModal = false;
  roomsLoaded = false;
  rooms = [];
  showErrorModal = false;
  showPaymentModal = false;
  showAddRemixableWidget = false;
  showWidgetsMenu = false;
  widgetMessage = false;
  advancedOptions = false;
  deleteWidgetMessage = false;
  unlockWidgetMessage = false;
  deleteRemixWidget = false;
  showAddWidgetErrorMessage = false;
  newWidgetWarningMessage = false;
  codeWarningMessage = false;
  showRemixes = false;
  showDetailsDescription = false;
  showRemixModalFeed = false;
  flowForFeed = {};
  urlHTML = "";

  urlCSS = "";

  urlJS = "";
  loadFlowCode = false;
  closeOpenRemixSession = false;
  disableWalkThrough = false;
  callAIWalkthrough = false;
  scrollCounter = 0;
  walkthroughSwitch = false;
  showAngleUpWalkthrough = false;
  isReshareWalkthrough = false;
  isPinnedWalkthrough = false;
  isRemixWalkthrough = false;
  imageWalkthrough = "https://demo.flowroom.com/uploads/XyIWiw.png";
  onOffWalkthrough = "OFF";
  text1Walkthrough = "Example text 1";
  text2Walkthrough = "Example text 2";
  dateWalkthrough = "Date";
  renderHTMLWalkthrough = false;
  showBlock = false;
  htmlWalkthrough = `<div style="width:100%; max-width:500px;display:flex;justify-content:center;align-items:center;margin:auto;
display:flex;
flex-direction:column;
">
<p>Example text 1</p>
<img src="https://demo.flowroom.com/uploads/XyIWiw.png" height="100px" style="border-radius:15px"/>
<p>Example text 2</p>
<p>OFF</p>
<p>0</p>
<p>Date</p>
</div>`;
  cssWalkthrough = "";
  showWalkthrough = false;
  currentlyBlockingUser = "";
  currentlySelectedUser = "";
  requestCustomAI = false;
  provideFeedback = false;
  improveAI = false;
  customRemixFunction = null;
  functionName = "";
  showRemixModal = false;
  showRemixWidgetModal = false;
  widgetLabel = "";
  showWidgetByLabel = "";
  aiWidgetCount = 0;
  imageWidget = false;
  textWidget = false;
  colorWidget = false;
  sliderWidget = false;
  switchWidget = false;
  dropDownWidget = false;
  calendarWidget = false;
  aiWidget = false;
  selectWidget = false;
  showNext = false;
  disabeImageWidgetMenu = false;
  widgetInfo = false;
  pendingAI = false;
  showRemixOrDescription = "description";
  aiTrainerWidget = false;
  advancedWarning = false;
  isUpdateFromWidget = false;

  getCodeFromIframe = false;
  widgetCSS = "";
  widgetHTML = "";
  widgetJS = "";
  tryAgain = false;
  blockModal = false;
  showSaveRemixModal = false;
  startRemixing = false;
  endRemixing = false;
  isCurrentlyRemixing = false;
  showFeatureRequestModal = false;
  currentlySlectedFlowMessage = "";
  showFlowModal = false;
  noRemixWidgetModal = false;
  showCreateGuide = false;
  draggableWidgetVisible = false;
  remixId = "";
  clear() {
    this.isStartWidgetPolling = false;
    this.currentlySelectedImage = "";
    this.images = [];
    this.textInputs = [];
    this.isReplaceJS = false;
    this.ReplacedJS = "";
    this.widgetInstanceChange = {};
    this.widgetArray = {};
    this.currentWidgetCopy = [];
    this.updateMenu = false;
    this.widgetImageArray = [];
    this.setwidgetDefaultArray = [];
    this.isSpecialReplaceImage = false;
    this.isSpecialReplaceImageValue = "";
    this.windowObject = {};
    this.currentlySelectedFunction = "";
    this.swipeIndex = 0;
    this.jumpTo = [];
    this.widgets = [];
    this.replaceValue = "";
    this.replaceWith = "";
    this.replaceLine = "";
    this.getSelectedRemix = false;
    this.editorSelected = "";
    this.widgetObject = {};
    this.widgetReplaceSelection = {};
    this.widgetActualInstance = 0;
    this.getNewEndColumn = false;
    this.widgetValueLength = 0;
    this.widgetOnOff = false;
    this.deleteWidgetItem = false;
    this.widgetDeleteInstance = 0;
    this.imageIndexes = [];
    this.textInputIndexes = [];
    this.colorSelectIndexes = [];
    this.sliderIndexes = [];
    this.switchIndexes = [];
    this.selectIndexes = [];
    this.checkboxIndexes = [];
    this.calendarIndexes = [];
    this.CurrentlySelectedValue = "";
    this.radioIndexes = [];
    this.resetImageSelect = true;
    this.imageUploaderCount = 0;
    this.textInputCount = 0;
    this.noNewValue = true;
    this.hasRemixableAI = false;
    this.modulePrompt = "";
    this.aiModuleInstructions = "";
    this.aiModuleAbout = "";
    this.aiModulePlaceholder = "";
    this.aiModuleDescription = "";
    this.aiModuleName = "";
    this.currentlySelectedFilter = "Featured";
    this.initialScreen = false;
    this.showRemixModal = false;
    this.showPostModal = false;
    this.showDetailsModal = false;
    this.colorInputCount = 0;
    this.sliderInputCount = 0;
    this.switchInputCount = 0;
    this.selectInputCount = 0;
    this.checkboxInputCount = 0;
    this.calendarInputCount = 0;
    this.aiWidgetCount = 0;
    this.currentlySelectedEditorValue = "";
    this.isLoading = false;
    this.dropImageValue = "";
    this.textValue = "";
    this.errorMessage = false;
    this.advanced = false;
    this.currentErrorMessage = "";
    this.loadingOneMoment = "";
    this.currentlySelectedFeedFlow = "";
    this.loadFlowCode = false;
    this.postModalFeed = false;
    this.currentSignUpPassword = "";
    this.currentModalHeight = 444;
    this.showReportModal = false;
    this.showBlockModal = false;
    this.roomsLoaded = false;
    this.rooms = [];
    this.showErrorModal = false;
    this.showPaymentModal = false;
    this.showAddRemixableWidget = false;
    this.showWidgetsMenu = false;
    this.widgetMessage = false;
    this.advancedOptions = false;
    this.deleteWidgetMessage = false;
    this.unlockWidgetMessage = false;
    this.deleteRemixWidget = false;
    this.showAddWidgetErrorMessage = false;
    this.newWidgetWarningMessage = false;
    this.codeWarningMessage = false;
    this.showRemixes = false;
    this.showDetailsDescription = false;
    this.showRemixModalFeed = false;
    this.flowForFeed = {};

    this.urlHTML = "";

    this.urlCSS = "";

    this.urlJS = "";
    this.loadFlowCode = false;
    this.closeOpenRemixSession = false;
    this.disableWalkThrough = false;
    this.callAIWalkthrough = false;
    this.scrollCounter = 0;
    this.walkthroughSwitch = false;
    this.showAngleUpWalkthrough = false;
    this.isReshareWalkthrough = false;
    this.isPinnedWalkthrough = false;
    this.isRemixWalkthrough = false;
    this.imageWalkthrough = "https://demo.flowroom.com/uploads/XyIWiw.png";
    this.onOffWalkthrough = "OFF";
    this.text1Walkthrough = "Example text 1";
    this.text2Walkthrough = "Example text 2";
    this.dateWalkthrough = "Date";
    this.renderHTMLWalkthrough = false;
    this.htmlWalkthrough = `<div style="width:100%; max-width:500px;display:flex;justify-content:center;align-items:center;margin:auto;
display:flex;
flex-direction:column;
">
<p>Example text 1</p>
<img src="https://demo.flowroom.com/uploads/XyIWiw.png" height="100px" style="border-radius:15px"/>
<p>Example text 2</p>
<p>OFF</p>
<p>0</p>
<p>Date</p>
</div>`;
    this.cssWalkthrough = "";
    this.showWalkthrough = false;
    this.currentlyBlockingUser = "";
    this.currentlySelectedUsr = "";
    this.showBlock = false;
    this.errorModalMessage = "";
    this.requestCustomAI = false;
    this.provideFeedback = false;
    this.improveAI = false;
    this.customRemixFunction = null;
    this.functionName = "";
    this.showRemixModal = false;
    this.showRemixWidgetModal = false;
    this.widgetLabel = "";
    this.showWidgetByLabel = "";
    this.imageWidget = false;

    this.textWidget = false;

    this.colorWidget = false;

    this.sliderWidget = false;

    this.switchWidget = false;

    this.dropDownWidget = false;

    this.calendarWidget = false;

    this.aiWidget = false;
    this.selectWidget = false;
    this.showNext = false;
    this.disabeImageWidgetMenu = false;
    this.widgetInfo = false;
    this.pendingAI = false;
    this.showRemixOrDescription = "description";
    this.aiTrainerWidget = false;
    this.advancedWarning = false;
    this.isUpdateFromWidget = false;
    this.getCodeFromIframe = false;
    this.widgetCSS = "";
    this.widgetHTML = "";
    this.widgetJS = "";
    this.tryAgain = false;
    this.blockModal = false;
    this.showSaveRemixModal = false;
    this.startRemixing = false;
    this.endRemixing = false;
    this.isCurrentlyRemixing = false;
    this.showFeatureRequestModal = false;
    this.currentlySlectedFlowMessage = "";
    this.showFlowModal = false;
    this.noRemixWidgetModal = false;
    this.showCreateGuide = false;
    this.draggableWidgetVisible = false;
    this.remixId = "";
  }

  setStartWidgetPolling(boolean) {
    this.isStartWidgetPolling = boolean;
  }
  setCurrentlySelectedImage(imageName) {
    this.currentlySelectedImage = imageName;
  }
  setCurrentlySelectedFunction(func) {
    this.currentlySelectedFunction = func;
  }
  setImages(images) {
    this.images = images;
  }
  setTextInputs(inputs) {
    this.textInputs = inputs;
  }
  setIsReplaceJS(boolean) {
    this.isReplaceJS = boolean;
  }
  setReplacedJS(javascript) {
    this.ReplacedJS = javascript;
  }
  setWidgetInstanceChange(obj) {
    this.widgetInstanceChange = obj;
  }
  setWidgetArray(obj) {
    this.widgetArray = obj;
  }
  setWidgetImageArray(obj) {
    this.widgetImageArray = obj;
  }
  setCurrentWidgetCopy(arr) {
    this.currentWidgetCopy = arr;
  }
  setUpdateMenu(boolean) {
    this.updateMenu = boolean;
  }
  setwidgetDefaultArray(arr) {
    this.widgetDefaultArray = arr;
  }
  setIsSpecialReplaceImage(boolean) {
    this.isSpecialReplaceImage = boolean;
  }
  setIsSpecialReplaceImageValue(value) {
    this.isSpecialReplaceImageValue = value;
  }
  setWindowObject(obj) {
    this.obj = obj;
  }
  setSwipeIndex(index) {
    this.swipeIndex = index;
  }
  setJumpTo(array) {
    this.jumpTo = array;
  }
  setWidgets(array) {
    this.widgets = array;
  }
  setImageIndexes(array) {
    this.imageIndexes = array;
  }
  setTextInputIndexes(array) {
    this.textInputIndexes = array;
  }
  setColorSelectIndexes(array) {
    this.colorSelectIndexes = array;
  }
  setSliderIndexes(array) {
    this.sliderIndexes = array;
  }
  setSwitchIndexes(array) {
    this.switchIndexes = array;
  }
  setSelectIndexes(array) {
    this.selectIndexes = array;
  }

  setCalendarIndexes(array) {
    this.calendarIndexes = array;
  }
  setRadioIndexes(array) {
    this.radioIndexes = array;
  }
  setCheckboxIndexes(param) {
    this.checkboxIndexes = param;
  }
  setResetImageSelect(bool) {
    this.resetImageSelect = bool;
  }
  setAddJumpto(boolean) {
    this.addJumpTo = boolean;
  }
  setReplaceValue(value) {
    this.replaceValue = value;
  }
  setReplaceWith(value) {
    this.replaceWith = value;
  }
  setReplaceLine(value) {
    this.replaceLine = value;
  }
  setGetSelectedRemix(value) {
    this.getSelectedRemix = value;
  }
  setEditorSelected(editor) {
    this.editorSelected = editor;
  }
  setWidgetObject(obj) {
    this.widgetObject = obj;
  }
  setWidgetReplaceSelection(obj) {
    this.widgetReplaceSelection = obj;
  }
  setGetNewEndColumn(boolean) {
    this.getNewEndColumn = boolean;
  }
  setWidgetActualInstance(number) {
    this.widgetActualInstance = number;
  }
  setWidgetValueLength(value) {
    this.widgetValueLength = value;
  }
  setWidgetOnOff(boolean) {
    this.widgetOnOff = boolean;
  }
  setDeleteWidgetItem(boolean) {
    this.deleteWidgetItem = boolean;
  }
  setWidgetDeleteInstance(instance) {
    this.widgetDeleteInstance = instance;
  }
  setCurrentlySelectedValue(value) {
    this.currentlySelectedValue = value;
  }
  setImageUploaderCount(value) {
    this.imageUploaderCount = value;
  }
  setTextInputCount(value) {
    this.textInputCount = value;
  }
  setColorInputCount(value) {
    this.colorInputCount = value;
  }
  setSliderInputCount(value) {
    this.sliderInputCount = value;
  }
  setAIWidgetCount(value) {
    this.aiWidgetCount = value;
  }
  setSwitchInputCount(value) {
    this.switchInputCount = value;
  }
  setSelectInputCount(value) {
    this.selectInputCount = value;
  }
  setCheckboxInputCount(value) {
    this.checkboxInputCount = value;
  }
  setRadioInputCount(value) {
    this.radioInputCount = value;
  }
  setCalendarInputCount(value) {
    this.calendarInputCount = value;
  }
  setNoNewValue(bool) {
    this.noNewValue = bool;
  }
  setHasRemixableAI(bool) {
    this.hasRemixableAI = bool;
  }
  setModulePrompt(prompt) {
    this.modulePrompt = prompt;
  }
  setAIModuleInstructions(prompt) {
    this.aiModuleInstructions = prompt;
  }
  setAIModuleAbout(prompt) {
    this.aiModuleAbout = prompt;
  }
  setAIModulePlaceholder(prompt) {
    this.aiModulePlaceholder = prompt;
  }
  setAIModuleDescription(prompt) {
    this.aiModuleDescription = prompt;
  }
  setAIModuleName(prompt) {
    this.aiModuleName = prompt;
  }
  setCurrentlySelectedFilter(selected) {
    this.currentlySelectedFilter = selected;
  }
  setInitialScreen(selected) {
    this.initialScreen = selected;
  }
  setShowRemixModal(bool) {
    this.showRemixModal = bool;
  }
  setShowPostModal(bool) {
    this.showPostModal = bool;
  }
  setShowDetailsModal(bool) {
    this.showDetailsModal = bool;
  }
  setCalendarInput(number) {
    this.calendarInputCount = number;
  }
  setCurrentlySelectedEditorValue(value) {
    this.currentlySelectedEditorValue = value;
  }
  setIsLoading(bool) {
    this.isLoading = bool;
  }
  setDropImageValue(src) {
    this.dropImageValue = src;
  }
  setTextValue(text) {
    this.textValue = text;
  }
  setErrorMessage(message) {
    this.errorMessage = message;
  }
  setAdvanced(bool) {
    this.advanced = bool;
  }
  setCurrentErrorMessage(message) {
    this.currentErrorMessage = message;
  }
  setLoadingOneMoment(bool) {
    this.loadingOneMoment = bool;
  }
  setCurrentlySelectedFeedFlow(flow) {
    this.currentlySelectedFeedFlow = flow;
  }
  setLoadFlowCode(bool) {
    this.loadFlowCode = bool;
  }
  setShowPostModalFeed(bool) {
    this.postModalFeed = bool;
  }
  setCurrentSignUpPassword(param) {
    this.currentSignUpPassword = param;
  }
  setCurrentModalHeight(param) {
    this.currentModalHeight = param;
  }
  setShowReportModal(bool) {
    this.showReportModal = bool;
  }
  setShowBlockModal(bool) {
    this.showBlockModal = bool;
  }
  setRoomsLoaded(bool) {
    this.roomsLoaded = bool;
  }
  setRooms(arr) {
    this.rooms = arr;
  }
  setShowErrorModal(bool) {
    this.showErrorModal = bool;
  }
  setShowPaymentModal(bool) {
    this.showPaymentModal = bool;
  }
  setShowAddRemixableWidget(bool) {
    this.showAddRemixableWidget = bool;
  }
  setShowWidgetsMenu(bool) {
    this.showWidgetsMenu = bool;
  }
  setShowWidgetMessage(bool) {
    this.widgetMessage = bool;
  }
  setShowAdavancedOptions(bool) {
    this.advancedOptions = bool;
  }
  setShowDeleteWidgetMessage(bool) {
    this.deleteWidgetMessage = bool;
  }
  setShowUnlockWidgetMessage(bool) {
    this.unlockWidgetMessage = bool;
  }
  setDeleteRemixWidget(bool) {
    this.deleteRemixWidget = bool;
  }
  setDeleteRemixWidget(bool) {
    this.deleteRemixWidget = bool;
  }
  setShowAddWidgetErrorMessage(bool) {
    this.showAddWidgetErrorMessage = bool;
  }
  setNewWidgetWarningMessage(bool) {
    this.newWidgetWarningMessage = bool;
  }
  setCodeWarningMessage(bool) {
    this.codeWarningMessage = bool;
  }
  setShowRemixes(bool) {
    this.showRemixes = bool;
  }
  setShowDetailsDescription(bool) {
    this.showDetailsDescription = bool;
  }
  setShowRemixModalFeed(bool) {
    this.showRemixModalFeed = bool;
  }
  setUrlHTML(html) {
    this.urlHTML = html;
  }
  setUrlCSS(css) {
    this.urlCSS = css;
  }
  setUrlJS(js) {
    this.urlJS = js;
  }
  setLoadFlowCode(bool) {
    this.loadFlowCode = bool;
  }
  setCloseOpenRemixSession(bool) {
    this.closeOpenRemixSession = bool;
  }
  setDisableWalkThrough(bool) {
    this.disableWalkThrough = bool;
  }
  setCallAIWalkthrough(bool) {
    this.callAIWalkthrough = bool;
  }
  setScrollCounter(num) {
    this.scrollCounter = num;
  }
  setWalkthroughSwitch(bool) {
    this.walkthroughSwitch = bool;
  }
  setShowAngleUpWalkthrough(bool) {
    this.showAngleUpWalkthrough = bool;
  }
  setIsReshareWalkthrough(bool) {
    this.isReshareWalkthrough = bool;
  }
  setIsPinnedWalkthrough(bool) {
    this.isPinnedWalkthrough = bool;
  }
  setIsRemixWalkthrough(bool) {
    this.isRemixWalkthrough = bool;
  }
  setImageWalkthrough(img) {
    this.imageWalkthrough = img;
  }
  setText1Walkthrough(text) {
    this.text1Walkthrough = text;
  }
  setText2Walkthrough(text) {
    this.text2Walkthrough = text;
  }
  setText3Walkthrough(text) {
    this.text3Walkthrough = text;
  }
  setDateWalkthrough(text) {
    this.dateWalkthrough = text;
  }
  setOnOffWalkthrough(bool) {
    this.onOffWalkthrough = bool;
  }
  setNumWalkthrough(num) {
    this.numWalkthrough = num;
  }
  setRenderHTMLWalkthrough(bool) {
    this.renderHTMLWalkthrough = bool;
  }
  setHTMLWalkthrough(html) {
    this.htmlWalkthrough = html;
  }
  setCSSWalkthrough(css) {
    this.cssWalkthrough = css;
  }
  setShowWalkthrough(bool) {
    this.showWalkthrough = bool;
  }
  setCurrentlyBlockingUser(user) {
    this.currentlyBlockingUser = user;
  }
  setCurrentlySelectedUser(user) {
    this.currentlySelectedUser = user;
  }
  setShowBlock(bool) {
    this.showBlock = bool;
  }
  setErrorModalMessage(message) {
    this.errorModalMessage = message;
  }
  setRequestCustomAI(bool) {
    this.requestCustomAI = bool;
  }
  setProvideFeedback(bool) {
    this.provideFeedback = bool;
  }
  setImproveAI(bool) {
    this.improveAI = bool;
  }
  setCustomRemixFunction(func) {
    this.customRemixFunction = func;
  }
  setFunctionName(func) {
    this.functionName = func;
  }
  setShowRemixWidgetModal(bool) {
    this.showRemixWidgetModal = bool;
  }
  setWidgetLabel(label) {
    this.widgetLabel = label;
  }
  setShowWidgetByLabel(label) {
    this.showWidgetByLabel = label;
  }
  setImageWidget(bool) {
    this.imageWidget = bool;
  }
  setTextWidget(bool) {
    this.textWidget = bool;
  }
  setColorWidget(bool) {
    this.colorWidget = bool;
  }
  setSliderWidget(bool) {
    this.sliderWidget = bool;
  }
  setSwitchWidget(bool) {
    this.switchWidget = bool;
  }
  setDropdownWidget(bool) {
    this.dropDownWidget = bool;
  }
  setCalendarWidget(bool) {
    this.calendarWidget = bool;
  }
  setSelectWidget(bool) {
    this.selectWidget = bool;
  }
  setAIWidget(bool) {
    this.aiWidget = bool;
  }
  setShowNext(bool) {
    this.showNext = bool;
  }
  setDisabeImageWidgetMenu(bool) {
    this.disabeImageWidgetMenu = bool;
  }
  setWidgetInfo(bool) {
    this.widgetInfo = bool;
  }
  setPendingAI(bool) {
    this.pendingAI = bool;
  }
  setShowRemixOrDescription(param) {
    this.showRemixOrDescription = param;
  }
  setAITrainerWidget(bool) {
    this.aiTrainerWidget = bool;
  }
  setAdvancedWarning(bool) {
    this.advancedWarning = bool;
  }
  setIsUpdateFromWidget(bool) {
    this.isUpdateFromWidget = bool;
  }
  setGetCodeFromIframe(bool) {
    this.getCodeFromIframe = bool;
  }
  setWidgetHTML(param) {
    this.widgetHTML = param;
  }
  setWidgetCSS(param) {
    this.widgetCSS = param;
  }
  setWidgetJS(param) {
    this.widgetJS = param;
  }
  setTryAgain(param) {
    this.tryAgain = param;
  }
  setBlockModal(param) {
    this.blockModal = param;
  }
  setShowSaveRemixModal(param) {
    this.showSaveRemixModal = param;
  }
  setStartRemixing(param) {
    this.startRemixing = param;
  }
  setEndRemixing(param) {
    this.endRemixing = param;
  }
  setIsCurrentlyRemixing(param) {
    this.isCurrentlyRemixing = param;
  }
  setShowFeatureRequestModal(param) {
    this.showFeatureRequestModal = param;
  }
  setCurrentlySlectedFlowMessage(param) {
    this.currentlySlectedFlowMessage = param;
  }
  setShowFlowModal(param) {
    this.showFlowModal = param;
  }
  setNoRemixWidgetModal(param) {
    this.noRemixWidgetModal = param;
  }
  setShowCreateGuide(param) {
    this.showCreateGuide = param;
  }
  setDraggableWidgetVisible(param) {
    this.draggableWidgetVisible = param;
  }
  setRemixId(param) {
    this.remixId = param;
  }
  
}

export const WidgetStoreContext = createContext(new WidgetStore());
