import React, { useEffect, useState } from "react";
import { firebase } from "../firebase/firebase";

const CollaboratorsDetails = ({ flowId }) => {
  const [collaborators, setCollaborators] = useState([]);

  const fetchCollaborators = async (flowId) => {
    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection("notifications")
        .where("flowId", "==", flowId)
        .where("type", "==", "collabRequest")
        .where("isAccepted", "==", true)
        .get();

      const collaboratorsData = [];

      for (const doc of querySnapshot.docs) {
        const data = doc.data();

        // Fetch user photo URL from the users collection
        let urlPhoto = ""; // Default photo URL if not found
        const userSnapshot = await db.collection("users").where("username", "==", data.username).get();
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          urlPhoto = userData.photoURL || ""; // Assuming 'photoURL' is the field for the user's photo
        }

        collaboratorsData.push({
          id: doc.id,
          username: data.username,
          status: data.isAccepted ? "" : "pending",
          collaboratorRole: data.collaboratorRole,
          urlPhoto: urlPhoto, // Set the photo URL from users collection
        });
      }

      console.log('Fetched Collaborators:', collaboratorsData);
      // setCollaborators(collaboratorsData);
    } catch (error) {
      console.error("Error fetching collaborators: ", error);
    }
  };

  useEffect(() => {
    // fetchCollaborators(flowId);
  }, [flowId]);

  return (
    <div
      id={`credit_${flowId}-details`}
      style={{
        position: "absolute",
        top: "0px",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        zIndex: 1001,
        flexDirection: "column",
        background: "rgb(28, 28, 28)",
        pointerEvents: "all",
        paddingLeft: 25,
        paddingTop: 10,
        display: 'none'
      }}
    >
      <ul style={{ listStyle: 'none' }}>
        {collaborators.map(collab => (
          <li key={collab.id} style={{ margin: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                width: 25,
                height: 25,
                borderRadius: '50%',
                backgroundColor: 'black',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                cursor: 'pointer',
                position: 'relative',
                top: 0,
                left: 1,
                border: '2px solid white',
                overflow: 'hidden'
              }}>
                <img src={collab.urlPhoto} alt={`${collab.username} profile`} style={{ width: 40, height: 40 }} />
              </div>
              <p style={{ color: 'white', fontFamily: 'quicksand', fontSize: 14, marginLeft: 10 }}>{collab.username} - {collab.collaboratorRole}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(CollaboratorsDetails);

