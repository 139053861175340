import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";

import ErrorModal from "./ErrorModal.js";

// store
import Hashids from "hashids";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuid } from "uuid";

import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext.js";
let currentUser = firebase.auth().currentUser;
let firestore = firebase.firestore();
let blockedUsersArr = [];
const BlockModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const EditorStore = useContext(EditorStoreContext);

  const [confirm, setConfirm] = useState(false);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [openBlockError, setOpenBlockError] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [showDetails, setShowDetails] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [pending, setPending] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const [lastClickTime, setLastClickTime] = useState(false);

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
    if(currentUser == null) {
      return;
    } 
    (async () => {
      const querySnapshot = await firebase
        .firestore()
        .collection("blocked")
        .where("userId", "==", currentUser.uid)
        .where("blockedUser", "==", props.username)
        .get();
  
      // Check if the query returned any documents
      if (!querySnapshot.empty && isMounted) {
        setIsBlocked(true);
      }
    })();
  
    // Cleanup function to handle component unmount
    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []); // Dependency array is empty, meaning this effect runs only once on component mount
  

  const toggleBlockUser = async (blockedUser) => {
    if (!currentUser || !currentUser.displayName) {
      AuthenticationStore.setShowModal(true);
      return;
    }
  
    if (currentUser.displayName === blockedUser) {
      console.log("You cannot block yourself.");
      return;
    }
  
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 1000) {
      console.log("Too many actions. Try again later.");
      return;
    }
    setLastClickTime(currentTime);
    setPending(true);
  
    // Query the users collection to find a user with the username that matches `blockedUser`
    const querySnapshot = await firebase.firestore()
      .collection("users")
      .where("username", "==", blockedUser.trim())
      .get();
  
    if (querySnapshot.empty) {
      console.log("This user does not exist.");
      setPending(false);
      return;
    }
  
    const userToBlock = querySnapshot.docs[0];
    const userBlockDocId = currentUser.uid + ":" + userToBlock.id;
  
    let docLike = await firebase.firestore().collection("blocked").doc(userBlockDocId).get();
  
    if (docLike.exists) {
      // Unblock logic
      await firebase.firestore().collection("blocked").doc(userBlockDocId).delete();
      // setBlockedUsers(prev => prev.filter(user => user.blockedUserId !== userToBlock.id));
      setIsBlocked(false);
      setBlocked(blocked - 1);
      props.isUnBlocked();
      WidgetStore.setBlockModal(false);
      setOpenClose(false);
      await setPending(false);

    } else {
      // Block logic
      const blockedUserProfilePhoto = userToBlock.data().photoURL || 'default-profile-photo-url';
      const newBlockedUser = {
        userId: currentUser.uid,
        blockedUserId: userToBlock.id,
        blockedUserProfilePhoto,
        timestamp: new Date().toISOString(),
        blockedUser: userToBlock.data().username
      };
      // setBlockedUsers(prev => [...prev, newBlockedUser]);
      setIsBlocked(true);
      props.isBlocked();
      setBlocked(blocked + 1);
      await firebase.firestore().collection("blocked").doc(userBlockDocId).set(newBlockedUser, { merge: true });
      WidgetStore.setBlockModal(false);
      setOpenClose(false);
    }
  };
  


  // mobile layout


  
  useEffect(() => {
    // alert(isMobile);
    setOpenClose(props.openModal);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.openModal]);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={openClose}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.5)",
          opacity: 1,
        },
        content: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "350px",
          height:400
        },
      }}
    >
   
      <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        {/* <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        /> */}

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
            textAlign:'center',
            width:'100%'
          }}
        >
          Block User
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            props.closeModal();
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div style={{borderTop: '1px solid rgb(62, 62, 62)'}}></div>
      {confirm === false ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
            alignItems:'center'
          }}
        >
          <img
            src="/images/block-icon.svg"
            style={{ height: 80, width: 80, marginTop:5, marginBottom:15 }}
          />
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
        {!isBlocked ? 'Block this user' : 'Unblock this user?'}
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "left",
              color: "#FFFFFF",
              opacity: 0.6,

            }}
          >
            {!isBlocked ? 'Are you sure you want to block this user?' : 'Are you sure you want to unblock this user?'}
          </p>
          <div
            onClick={() => {
              toggleBlockUser(WidgetStore.currentlyBlockingUser);
              // let roomData = {
              //   blocked: WidgetStore.currentlyBlockingUser,
              //   user: firebase.auth().currentUser.displayName,
              // };
              // let myUserId = firebase.auth().currentUser.uid;

              // let roomObject = JSON.parse(JSON.stringify(roomData));

              // let hashids = new Hashids(uuid(), 6);
              // let generatedId = hashids.encode(1, 2, 3); //simply used as an ID
              // if (
              //   firebase.auth().currentUser.displayName !==
              //   WidgetStore.currentlyBlockingUser
              // ) {
              //   firebase
              //     .firestore()
              //     .collection("blocked")
              //     .where("blocked", "==", WidgetStore.currentlyBlockingUser)
              //     .where("user", "==", firebase.auth().currentUser.displayName)
              //     .limit(1)
              //     .get()
              //     .then((data) => {
              //       if (data.docs.length <= 0) {
              //         firebase
              //           .firestore()
              //           .collection("blocked")
              //           .doc(generatedId)
              //           .set(roomObject, { merge: true })
              //           .then(() => {
              //             if (firebase.auth().currentUser === null) {
              //               return;
              //             }
              //             firebase
              //               .firestore()
              //               .collection("followers")
              //               .doc(
              //                 WidgetStore.currentlyBlockingUser + ":" + myUserId
              //               )
              //               .delete()
              //               .then(() => {
              //                 setConfirm(true);
              //               });
              //           });
              //       } else {
              //         // alert("You already blocked this user");
              //         WidgetStore.setErrorModalMessage(
              //           "You already blocked this person."
              //         );
              //         setOpenBlockError(true);
              //       }
              //     });
              // }
            }}
            style={{
              backgroundColor: "#4FD975",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 15,
              cursor: "pointer",
              maxWidth: 526,
              height: 35,
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Yes
            </p>
          </div>

          <div
            onClick={() => {
              props.closeModal();
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 15,
              cursor: "pointer",
              maxWidth: 526,
              height: 35,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          
          <img
            src="/images/block-icon.svg"
            style={{
              height: 100,
              width: 100,
              margin: "auto",
              marginBottom: 10,
              marginTop: 35,
            }}
          />
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            User block
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            This user is now blocked and will not see any posts you make in any
            feed and also will not be able to see your profile.
          </p>
          <div
            onClick={() => {
              props.closeModal();
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 50,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Ok
            </p>
          </div>
        </div>
      )}

      <div>
        <ErrorModal
          openModalError={openBlockError}
          closeError={() => {
            setOpenBlockError(false);
          }}
        />
        ;
      </div>
    </ReactModal>
  );
});

export default React.memo(BlockModal);
