import {
  faPenSquare,
  faSave,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { FaLock, FaUnlock } from "react-icons/fa";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";
import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";

const ColorComponent = observer((props) => {
  const [color, setColor] = useState("");
  const [label, setLabel] = useState("");
  const WidgetStore = useContext(WidgetStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);

  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [showUnlockWidget, setShowUnlockWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);
  const [lock, setLock] = useState(false);
  const [isDevAccount, setIsDevAccount] = useState(false);
  useEffect(() => {
    setLock(props.isLocked);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsDevAccount(AuthenticationStore.isDevAccount);
    });
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [AuthenticationStore.isDevAccount]);
  const Lock = () => {
    return (
      <div
        onClick={(e) => {
          // setLock(e.target.value);

          //widgets.map((item) => {
          // alert(item.actualInstance + " " + props.actualInstance++);

          //if (item.actualInstance === props.actualInstance) {
          if (lock == false) {
            // setLock(true);
            setShowLockWidget(true);

            // widgets[props.actualInstance].isLocked = true;
          } else {
            // setLock(false);
            setShowUnlockWidget(true);
            // widgets[props.actualInstance].isLocked = false;
          }

          if (FlowStore.isFlowOwner === true) {
            firebase
              .firestore()
              .collection("flows")
              .doc(lastSegment)
              .update({ widgets: widgets })
              .then(() => {
                firebase
                  .firestore()
                  .collection("flows")
                  .doc(lastSegment)
                  // .limit(1)
                  .get()
                  .then((doc) => {
                    let data = doc.data();
                    console.log("widget data", data.widgets);

                    // WidgetStore.setWidgets(data.widgets);
                  });
              });
          }

          WidgetStore.setWidgets(widgets);
        }}
        style={{ marginTop: 0, marginLeft: 10, zIndex: 1000 }}
      >
        {lock === true ? (
          <FaLock
            size={17}
            color={"#F06263"}
            style={{ marginBottom: 7, cursor: "pointer" }}
          />
        ) : (
          <FaUnlock
            size={17}
            color={"#A962F1"}
            style={{ marginBottom: 7, cursor: "pointer" }}
          />
        )}
      </div>
    );
  };

  let widgets = WidgetStore.widgets;

  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();

  const setJump = () => {
    let textCount = 0;
    let imageCount = 0;
    let colorCount = 0;
    let sliderCount = 0;
    let switchCount = 0;
    let selectCount = 0;
    let checkboxCount = 0;
    let calendarCount = 0;

    let optionsJump = [];
    WidgetStore.widgets.map((widget, i) => {
      if (optionsJump.length !== widgets.length) {
        if (widget.widget === "ImageUploader") {
          imageCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: imageCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: imageCount,
            });
          } else {
          }
        } else if (widget.widget === "TextInput") {
          textCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: textCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: textCount,
            });
          } else {
          }
        } else if (widget.widget === "ColorSelect") {
          colorCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: colorCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: colorCount,
            });
          } else {
          }
        } else if (widget.widget === "Slider") {
          sliderCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: sliderCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: sliderCount,
            });
          } else {
          }
        } else if (widget.widget === "Switch") {
          switchCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: switchCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: switchCount,
            });
          } else {
          }
        } else if (widget.widget === "Select") {
          selectCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: selectCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: selectCount,
            });
          } else {
          }
        } else if (widget.widget === "Checkbox") {
          checkboxCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: checkboxCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: checkboxCount,
            });
          } else {
          }
        } else if (widget.widget === "Calendar") {
          calendarCount++;

          if (widget.isLocked === true && WidgetStore.advancedOptions == true) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: calendarCount,
            });
          } else if (
            widget.isLocked === false &&
            widget.advancedOptions == false
          ) {
            optionsJump.push({
              value: `label_${i}`,
              label: widget.label,
              widget: widget.widget,
              index: i,
              widgetIndex: calendarCount,
            });
          } else {
          }
        }
      }
    });
    WidgetStore.setJumpTo(optionsJump);
  };

  const editLabel = () => {
    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", true);
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "5px dotted #F06263";
  };

  const saveLabel = () => {
    if (
      document
        .getElementById(`label_${props.actualInstance}`)
        .innerText.toString()
        .trim().length > 14
    ) {
      WidgetStore.setErrorMessage(true);

      WidgetStore.setCurrentErrorMessage(
        "You can only enter a maximum of 14 charaacters for a label name."
      );

      return;
    }
    document
      .getElementById(`label_${props.actualInstance}`)
      .setAttribute("contentEditable", false);

    widgets.map((item) => {
      if (item.actualInstance === props.actualInstance) {
        widgets[props.actualInstance].label = document.getElementById(
          `label_${props.actualInstance}`
        ).innerText;
      }
    });
    document.getElementById(`label_${props.actualInstance}`).style.border =
      "0px";
    // WidgetStore.setWidgets(widgets);
    setJump();
    FlowStore.setJumpTo(true);

    if (FlowStore.isFlowOwner === true) {
      firebase
        .firestore()
        .collection("flows")
        .doc(lastSegment)
        .update({ widgets: WidgetStore.widgets })
        .then(() => {
          firebase
            .firestore()
            .collection("flows")
            .doc(lastSegment)
            // .limit(1)
            .get()
            .then((doc) => {
              let data = doc.data();
              console.log("widget data", data.widgets);

              WidgetStore.setWidgets(data.widgets);
              FlowStore.setJumpTo(true);
            });
        });
    }
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display:
          props.isLocked === true &&
          props.advancedOptions === false &&
          FlowStore.isFlowOwner === false &&
          lastSegment !== "new"
            ? "none"
            : "block",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          // marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: 35,
            width: "100%",
            borderBottom: "1px solid #323232",
            marginBottom: 17,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            id={`label_${props.actualInstance}`}
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "white",
              marginBottom: 10,
            }}
          >
            {props.label}
          </p>
          {/* <div
            style={{
              position: "absolute",
              justifyContent: "space-between",
              width: 98,
              display: "flex",
            }}
          >
            <img src="../unlock.svg" style={{ height: 17 }} />
            <img src="../message-edit.svg" style={{ height: 17 }} />
            <img src="../direct-inbox.svg" style={{ height: 17 }} />
            <img src="../group.svg" style={{ height: 17 }} />
          </div> */}
          {isDevAccount === true && !props.hideOptions == true ? (
            <Lock style={{ zIndex: 1000000 }} updateLock={(bool) => {}} />
          ) : (
            ""
          )}
        </div>
        {props.hideOptions !== true && isDevAccount === true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: 70,
            }}
          >
            <div onClick={editLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faPenSquare}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>

            <div onClick={saveLabel}>
              {" "}
              <FontAwesomeIcon
                icon={faSave}
                color="#4FD975"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              onClick={() => {
                setShowDeleteWidget(true);
                // widgets.splice(props.actualInstance, 1);
                // window.widgets.splice(props.actualInstance, 1);
                // firebase
                //   .firestore()
                //   .collection("flows")
                //   .doc(lastSegment)
                //   .update({ widgets: widgets })
                //   .then(() => {
                //     WidgetStore.setWidgets(widgets);
                //     WidgetStore.setDeleteWidgetItem(true);
                //     WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                //   });
                // WidgetStore.setWidgets(widgets);
                // WidgetStore.setDeleteWidgetItem(true);
                // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                // WidgetStore.setImageUploaderCount(
                //   WidgetStore.imageUploaderCount > 0
                //     ? WidgetStore.imageUploaderCount - 1
                //     : 0
                // );
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faWindowClose}
                color="#F06263"
                style={{
                  display: "flex",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {props.showInfo !== true ? (
        <div>
          <ChromePicker
            style={{
              background: "#1C1C1C",
              borderRadius: 2,
              /* box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px; */
              boxSizing: "initial",
              fontFamily: "quicksand",
            }}
            color={color}
            onChange={(color) => {
            
                setColor(color.hex);
                  try{
                  let iframe = document.getElementById("undefined_output_frame");
                  if (iframe !== undefined && iframe !== null) {
                    // let ev = `
                    //   let iframe = document.getElementById(
                    //     "undefined_output_frame"
                    //   );
                    //   if (iframe !== undefined && iframe !== null) {
                    //     iframe.contentWindow.${props.function}('${e.target.value}')
                    //   }`;
                    iframe.contentWindow[props.functionName](color);
                    
                    // try {
                    //   eval(ev);
                    // } catch (e) {}
                  }
                }catch(e){}
                
              
            }}
          />
        </div>
      ) : (
        ""
      )}
      <p
        style={{
          color: "white",
          fontFamily: "quicksand",
          fontSize: 10,
          marginTop: 12,
          opacity: 0.8,
        }}
      >
        {props.info}
      </p>

      {props.isDevAccount == true ? (
        <DeleteRemixWidgetModal
          deleteWidget={() => {
            widgets = widgets.filter((widget) => widget.label !== props.label);
            window.widgets = widgets.filter(
              (widget) => widget.label !== props.label
            );
            let lastArr =
              JSON.parse(localStorage.getItem("lastRemovedColor")) === null
                ? []
                : JSON.parse(localStorage.getItem("lastRemovedColor")).sort();

            lastArr.push(props.instanceOfWidget);
            localStorage.setItem("lastRemovedColor", JSON.stringify(lastArr));

            // WidgetStore.setImageUploaderCount(
            //   WidgetStore.imageUploaderCount > 0
            //     ? WidgetStore.imageUploaderCount - 1
            //     : 0
            // );
            if (FlowStore.isFlowOwner === true) {
              firebase
                .firestore()
                .collection("flows")
                .doc(lastSegment)
                .update({
                  widgets: widgets.filter(
                    (widget) => widget.label !== props.label
                  ),
                })
                .then(() => {
                  WidgetStore.setWidgets(widgets);
                  // WidgetStore.setDeleteWidgetItem(true);
                  // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                  setJump();
                  setShowDeleteWidget(false);
                });
            }
          }}
          showDeleteRemixWidgetModal={showDeleteWidget}
          closeWidget={() => {
            setShowDeleteWidget(false);
          }}
        />
      ) : (
        ""
      )}
      {AuthenticationStore.isDevAccount == true ? (
        <UnlockRemixWidgetModal
          unlock={() => {
            // setLock(false);
            widgets[props.actualInstance].isLocked = false;

            setShowUnlockWidget(false);
          }}
          showUnlockMessage={showUnlockWidget}
          closeUnlockModal={() => {
            setShowUnlockWidget(false);
          }}
        />
      ) : (
        ""
      )}
      {AuthenticationStore.isDevAccount == true ? (
        <LockRemixWidgetModal
          lock={() => {
            // setLock(true);
            widgets[props.actualInstance].isLocked = true;

            setShowLockWidget(false);
          }}
          showLockMessage={showLockWidget}
          closeLockModal={() => {
            setShowLockWidget(false);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
});

export default React.memo(ColorComponent);
