import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import "cropperjs/dist/cropper.css";
import { useFormik } from "formik";
import Hashids from "hashids";
import React, { useContext, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { firebase } from "../firebase/firebase";
import "../styles/firebaseui-styling.global.css"; // Import globally. Not with CSS modules.
import { AuthContext } from "./AuthContext";
import { Config } from "./config/Config";
import { alpha } from '@material-ui/core/styles';

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});
const s3 = new S3();

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 64,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));

const bioStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 169,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));

function NameTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function UserTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function BioTextField(props) {
  const classes = bioStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

const UserModal = (props) => {
  const classes = useStyles();

  const [file, setFiles] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");
  const [cropResult, setCropResults] = useState("");
  const [avatar, setAvatar] = useState("");
  const [isUploading, setIsUploading] = useState("");
  const [progress, setProgress] = useState(0);
  const [avatarURL, setAvatarURL] = useState("");
  const [urlPhoto, setUrlPhoto] = useState("/images/Flowroom_Default_Avatar.png");
  const [fullname, setFullName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUserName] = useState("");
  const [nameExists, setNameExists] = useState(false);
  const [bio, setBio] = useState("");
  const [pic, setPic] = useState("");
  const profileRef = React.useRef([]);
  const [pictures, setPictures] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setPictures(pictures.concat(pictureFiles));
  };

  profileRef.current = [0, 0, 0, 0].map(
    (ref, index) => (profileRef.current[index] = React.createRef())
  );

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const cropper = useRef("cropper");

  const formik = useFormik({
    initialValues: {
      fullname: AuthenticationStore.fullname,
      username: AuthenticationStore.displayName,
      bio: AuthenticationStore.bio,
    },

    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username has to be 3 characters or longer!")
        .max(15, "Username can not be longer than 15 characters!")
        .matches(
          /^[a-zA-Z0-9]{3,15}$/g,
          "Username can not have any spaces or special characters!"
        )
        .required("Username is required!"),
      fullname: Yup.string().required("Full name is required!"),
    }),

    onSubmit: (values) => {
      if(uploading == false) {
      console.log("[UserModal] onSubmit", JSON.stringify(values, null, 2));
      let username = formik.values.username;
      username = username.toLowerCase();
      let fullname = formik.values.fullname;
      let bio = formik.values.bio;
      let userData = {};
      if (username !== "") {
        userData.username = username.trim();
      }
      if (fullname !== "") {
        userData.fullname = fullname;
      }
      if (bio !== "") {
        userData.bio = bio;
      }
      // if (urlPhoto && urlPhoto !== "") {
      //   userData.urlPhoto = urlPhoto;
      // }
      if (username === "") {
        document.getElementById("username").focus();
      } else {
        firebase
          .firestore()
          .collection("users")
          .where("username", "==", username)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              let data = snapshot.docs[0].data();
              if (
                data.username &&
                data.username == username &&
                data.userId !== currentUser.uid
              ) {
                setNameExists(true);
                return;
              }
            }
            firebase
              .firestore()
              .collection("users")
              .doc(currentUser.uid)
              .set(userData, { merge: true })
              .then(() => {
                // firebase
                //   .auth()
                //   .currentUser.updateProfile({
                //     fullname: fullname,
                //   })
                //   .then(() => {
                    // localStorage.setItem(
                    //   "user",
                    //   JSON.stringify({
                    //     displayName: username.trim(),
                    //     photoURL: urlPhoto,
                    //     fullname: fullname,
                    //   })
                    // );

                    if (username !== "") {
                      AuthenticationStore.setDisplayName(username.trim());
                    }

                    if (fullname !== "") {
                      AuthenticationStore.setFullname(fullname);
                    }

                    if (bio !== "") {
                      AuthenticationStore.setBio(bio);
                    }

                    // if (urlPhoto !== "") {
                    //   AuthenticationStore.setPhotoURL(urlPhoto);
                    // }
                    AuthenticationStore.setShowUserModal(false);
                    AuthenticationStore.setIsProfileLoaded(false);
                    window.location.replace(`/${username}`)
                  // })
                  // .catch((error) => {});
              })
              .catch((error) => {});
          });
        
      }
    }
    },
  });

  const _crop = () => {
    // image in dataUrl
    let imageURL = cropper.getCroppedCanvas().toDataURL();
  };

  const handleChangeUsername = (event) => {
    setUserName(event.target.value);
  };

  let getMimeType = (type) => {
    let mimeType = "";
    switch (type) {
      case "html":
        mimeType = "text/html";
        break;
      case "css":
        mimeType = "text/css";
        break;
      case "js":
        mimeType = "text/javascript";
        break;
    }

    return mimeType;
  };

  // const uploadFile = (file) => {
  //   let hashids = new Hashids(uuid(), 6);
  //   let fileName = hashids.encode(1, 2, 3) + ".png";

  //   let params = {
  //     Bucket: Config.HOST_S3,
  //     Key: "uploads/" + fileName,
  //     ContentType: file.type,
  //     Body: file,
  //   };

  //   s3.putObject(params, function (err, data) {
  //     if (err) {
  //       console.log("error :", err);
  //     } else {
  //       console.log("data :", data);
  //       setUrlPhoto(`https://${Config.HOST_CLOUDFRONT}/uploads/${fileName}`);
  //     }
  //   });
  // };


  const uploadFile = (file) => {
    setUploading(true);
    let hashids = new Hashids(uuid(), 6);
    let fileName = hashids.encode(1, 2, 3) + ".png";
  
    let params = {
      Bucket: Config.HOST_S3,
      Key: "uploads/" + fileName,
      ContentType: file.type,
      Body: file,
    };
  
    s3.putObject(params, function (err, data) {
      if (err) {
        console.log("error :", err);
      } else {
        console.log("Successfully uploaded file to S3:", data);
        let urlPhoto = `https://demo.flowroom.com/uploads/${fileName}`;
  
        // Update Firestore
        firebase.firestore().collection("users").doc(currentUser.uid)
          .update({
            urlPhoto: urlPhoto // Update the 'urlPhoto' field in Firestore
          })
          .then(() => {
            console.log("Firestore updated with new urlPhoto:", urlPhoto);
            // Update Firebase Auth user profile
            currentUser.updateProfile({
              photoURL: urlPhoto
            }).then(() => {
              console.log("Firebase Auth profile updated with new photoURL.");
              // If managing state or UI, update it here as needed
              setUploading(false);
              


            }).catch((error) => {
              console.error("Error updating Firebase Auth profile:", error);
            });
  
          })
          .catch((error) => {
            console.error("Error updating user in Firestore:", error);
          });
      }
    });
  };
  

  const showPhotoPreview = (file) => {
    setUrlPhoto(window.URL.createObjectURL(file));
  };

  useEffect(() => {
    let localUser = currentUser;
    
    console.log("localUser", localUser);
    //profileRef.current[0].value = "Brandon";

    if (localUser != null) {
      if (localUser.displayName != null && localUser.displayName != "") {
        let username = localUser.username;

        if (username != null && username != "") {
          setUserName(username);
          AuthenticationStore.setShowModal(false);
        }
      } else {
        localStorage.setItem("user", JSON.stringify({ displayName: "" }));
        setIsLoggedIn(true);
        //setUserName("");
        AuthenticationStore.setShowModal(true);
      }
    } else {
      setIsLoggedIn(false);
      AuthenticationStore.setShowModal(true);
    }

    if (AuthenticationStore.isProfileLoaded === false) {
 
      AuthenticationStore.setIsProfileLoaded(true);
   }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);


  useEffect(()=>{
        // firebase.auth().onAuthStateChanged((user) => {
        //if (user !== null) {
    
        firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid)
            .get()
            .then((doc) => {
              if (!doc.exists) {
                setIsLoggedIn(true);
                // setUserName("");
                // AuthenticationStore.setShowModal(true);
                return;
              }
              let data = doc.data();

              let urlPhoto = data.urlPhoto
                ? data.urlPhoto
                : "/images/Flowroom_Default_Avatar.png";
              let fullname = data.fullname ? data.fullname : "";
              let username = data.username ? data.username : "";
              let bio = data.bio ? data.bio : "";
              formik.setFieldValue("fullname", fullname);
              formik.setFieldValue("username", username);
              formik.setFieldValue("bio", bio);
              if (data.urlPhoto !== "") {
                setUrlPhoto(urlPhoto);
              }
              // if (username != null && username != "") {
              //   setUserName(username);
              //   AuthenticationStore.setShowModal(false);
              // }
              // AuthenticationStore.setShowModal(false);
            });
        //}
      //});
  },[AuthenticationStore.showUserModal])
  
  return (
    <div style={{ flex: "1", display: "flex", height: "100%" }}>
      <div
        id="cropModal"
        style={{
          position: "absolute",
          display: "none",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          bottomm: 0,
        }}
      >
        <div
          onClick={() => {
            document.getElementById("cropModal").style.display = "none";
          }}
          style={{
            height: "100%",
            width: "100%",
            background: "black",
            opacity: 0.5,
          }}
        ></div>
        <div
          style={{
            height: "80%",
            display: "flex",
            width: "80%",
            backgroundColor: "white",
            position: "absolute",
          }}
        >
          <Cropper
            ref={cropper}
            src={file}
            style={{ flex: 1, width: "100%" }}
            // Cropper.js options
            aspectRatio={16 / 9}
            guides={true}
            crop={() => {
              _crop.bind();
            }}
            scalable={true}
            cropBoxMovable={true}
            cropBoxResizable={true}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundImage: `url(${cropResult})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 50px",
            }}
          >
          <button className="community-create-community-button" style={{ marginTop: 20 }}>
  {uploading ? (
    <div className="dot-animation">Uploading</div>
  ) : 'Save'}
</button>
          </div>
        </div>
      </div>
      <div className="main-section-wrap-signup-screen">
        <div
          className="main-section-signup-box"
          style={{
            display: "flex",

            margin: "auto",
          }}
        >
          <div
            style={{
              height: 58,
              background: "#1C1C1C",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              borderBottom: "4px solid rgb(0, 0, 0)"
            }}
          >
            <img
              src="./images/edit-profile-icon.svg"
              style={{ marginLeft: 10, marginRight: 10 }}
            />
            <p
              style={{
                fontFamily: "Quicksand",
                color: "white",
                position: "relative",
                fontSize: 20,
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18,
                color: "#FFFFFF",
              }}
            >
              {"Edit profile"}
            </p>
            <img
              onClick={() => {
                AuthenticationStore.setShowUserModal(false);
                AuthenticationStore.setIsProfileLoaded(false);
              }}
              src="./images/close-circle-report.svg"
              style={{
                marginLeft: 10,
                marginRight: 10,
                position: "absolute",
                right: 10,
                cursor: "pointer",
              }}
            />
          </div>

          <div style={{ width: "100%",               borderTop: "1px solid #323232",
 }}>
            <div style={{ width: "100%", padding: "0px 22px" }}>
              <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  {isUploading && <p>Progress: {progress}</p>}

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      top: 25,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: 100,
                        position: "relative",
                        height: 100,
                        marginBottom: 55,
                      }}
                    >
                      <Avatar
                        style={{
                          width: "100%",

                          height: "100%",
                          display: "flex",
                          overflow: "hidden",
                          position: "relative",
                          alignItems: "center",
                          borderRadius: "50%",
                          justifyContent: "center",
                          background:
                            "linear-gradient(0deg, #000000, #000000), url(.png)",
                          border: "2px solid #4FD975",
                          maxWidth: 100,
                          maxHeight: 100,
                        }}
                        src={
                          AuthenticationStore.urlPhoto === null
                            ? "/images/Flowroom_Default_Avatar.png"
                            : urlPhoto
                        }
                        onClick={() => {}}
                      />
                      <input
                        name="photo"
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          if(uploading == false){
                          showPhotoPreview(event.target.files[0]);
                          uploadFile(event.target.files[0]);
                          }
                        }}
                        style={{
                          background:
                            "linear-gradient(0deg, #000000, #000000), url(.png)",
                          border: "2px solid #4FD975",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          bottom: "0px",
                          opacity: 0.7,
                          zIndex: 1000,
                          height: "100%",
                          width: "100%",
                          borderRadius: "50%",
                          pointerEvents: "none",
                          background:'black'
                        }}
                      ></div>
                      {!uploading ? (<p
                        style={{
                          zIndex: 100000,
                          color: "white",
                          fontFamily: "quicksand",
                          fontSize: 11,
                          position: "absolute",
                          top: 45,
                          margin: "auto",
                          width: 108,
                          left: 10,
                          cursor: "pointer",
                          pointerEvents: "none",
                        }}
                      >
                        Change Avatar
                      </p>):
                      (<div className="dot-animation" style={{position: "absolute",
                      top: 45,
                      zIndex: 1000,
                      left: 21, fontFamily:'quicksand', color:'white'}}>Uploading</div>)}

                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <input
                        disabled={currentUser !== null && currentUser.displayName !== null}
                        className="formInputOutline"
                        type="text"
                        id="username"
                        autocomplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                        variant="filled"
                        label="Username"
                        style={{
                          border: "0px solid #545454",
                          overflow: "hidden",
                          backgroundColor: "#2D2A2B",
                          color: "white",
                          height: 33,
                          width: "100%",
                          paddingLeft: 10,
                          fontFamily: "quicksand",
                          background: "#323232",
                          opacity: 0.36,
                          borderRadius: 10,
                        }}
                        placeHolder="Username"
                      />
                      <p
                        style={{
                          color: "#F06263",
                          fontFamily: "quicksand",
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        {formik.errors.username}
                        {nameExists ? (
                          <p
                            style={{
                              color: "#F06263",
                              fontFamily: "quicksand",
                            }}
                          >
                            Username already exists. Please choose another one.
                          </p>
                        ) : (
                          ""
                        )}
                      </p>
                      <p
                        style={{
                          color: "#6371F6",
                          fontFamily: "quicksand",
                          marginTop: 2,
                        }}
                      >
                        {`flowroom.com/${
                          formik.values.username === null ||
                          formik.values.username === undefined ||
                          formik.values.username === ""
                            ? "[username]"
                            : formik.values.username
                        }`}
                      </p>
                      <div style={{ margin: 5 }}></div>
                      {/* <UserTextField
                        id="username"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                        variant="filled"
                        label="Username"
                      /> */}

                      <input
                        type="text"
                        autocomplete="off"
                        id="fullname"
                        className="formInputOutline"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullname}
                        variant="filled"
                        label="First + Last Name"
                        style={{
                          border: "0px solid #545454",
                          overflow: "hidden",
                          backgroundColor: "#2D2A2B",
                          color: "white",
                          height: 33,
                          width: "100%",
                          paddingLeft: 10,
                          fontFamily: "quicksand",
                          background: "#323232",
                          borderRadius: 10,
                        }}
                        placeHolder="Name"
                      />
                      <p
                        style={{
                          color: "#F06263",
                          fontFamily: "quicksand",
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        {formik.errors.fullname}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        color: "white",
                        position: "relative",
                        top: 12,
                        left: 0,
                        fontSize: 18,
                        marginTop: 55,
                        marginBottom: 5,
                      }}
                    >
                      Role
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "quicksand",
                        color: "#FEFFFE",
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                    >
                      This is optional.
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={EditorStore.developerRole}
                          icon={
                            <div
                              style={{
                                backgroundColor: "#4FD975",
                                // padding: 5,
                                height: 28,
                                width: 40,
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                            />
                          }
                          onChange={(e) => {
                            EditorStore.setDeveloperRole(e);
                          }}
                          // inputProps={{ "aria-label": "primary checkbox" }}
                          style={{
                            height: 20,
                            backgroundColor: "#2D2A2B",
                          }}
                          borderColor={"#2D2A2B"}
                          borderRadius={"50%"}
                          size={35}
                        />
                        <p
                          style={{
                            color: "#FEFFFE",
                            fontFamily: "quicksand",
                            marginRight: 50,
                            marginLeft: 20,
                            cursor: "pointer",
                          }}
                        >
                          Developer
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={EditorStore.userRole}
                          icon={
                            <div
                              style={{
                                backgroundColor: "#4FD975",
                                // padding: 5,
                                height: 28,
                                width: 40,
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                            />
                          }
                          onChange={(e) => {
                            EditorStore.setUserRole(e);
                          }}
                          // inputProps={{ "aria-label": "primary checkbox" }}
                          style={{
                            height: 20,
                            backgroundColor: "#2D2A2B",
                            cursor: "pointer",
                          }}
                          borderColor={"#2D2A2B"}
                          borderRadius={"50%"}
                          size={35}
                        />
                        <p
                          style={{
                            color: "#FEFFFE",
                            fontFamily: "quicksand",
                            marginLeft: 20,
                          }}
                        >
                          User
                        </p>
                      </div>
                    </div>
                  </div> */}
                {/* <p
                    style={{
                      fontFamily: "Quicksand",
                      color: "white",
                      position: "relative",
                      top: 12,
                      left: 0,
                      fontSize: 20,
                      marginTop: 5,
                    }}
                  >
                    Gender
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontFamily: "quicksand",
                      color: "#FEFFFE",
                      fontSize: 14,
                      marginTop: 5,
                    }}
                  >
                    This is optional.
                  </p> */}
                {/* <div
                    style={{
                      position: "relative",
                      top: 20,
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 300 }}>
                      <Select
                        defaultValue={1}
                        closeMenuOnSelect={true}
                        components={{ Placeholder, DropdownIndicator }}
                        styles={customStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            // ...theme.colors,
                            primary25: "#A962F1",
                            primary: "#6371F6",
                            // color: "white",
                          },
                        })}
                        display={"flex"}
                        width={100}
                        height={20}
                        placeholder={"Pronouns"}
                        options={[
                          { value: "Public", label: "Public" },
                          { value: "Private", label: "Private" },
                          { value: "Followers", label: "Followers Only" },
                        ]}
                        onChange={(picked) => {
                          var elmnt = document.getElementById(picked.value);
                          EditorStore.setPronouns(picked.value);
                          if (elmnt !== null) {
                            elmnt.scrollIntoView(false);
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 20,
                      }}
                    >
                      <Checkbox
                        checked={EditorStore.displayOnProfile}
                        icon={
                          <div
                            style={{
                              backgroundColor: "#4FD975",
                              // padding: 5,
                              height: 28,
                              width: 40,
                              borderRadius: "50%",
                            }}
                          />
                        }
                        onChange={(e) => {
                          EditorStore.setDisplayOnProfile(e);
                        }}
                        // inputProps={{ "aria-label": "primary checkbox" }}
                        style={{
                          height: 20,
                          backgroundColor: "#2D2A2B",
                        }}
                        borderColor={"#2D2A2B"}
                        borderRadius={"50%"}
                        size={35}
                      />
                      <p
                        style={{
                          color: "white",
                          fontFamily: "quicksand",
                          fontSize: 14,
                          marginLeft: 10,
                        }}
                      >
                        Display on profile
                      </p>
                    </div>
                  </div> */}
                <p
                  style={{
                    fontFamily: "Quicksand",
                    color: "white",
                    position: "relative",
                    top: 12,
                    left: 0,
                    fontSize: 20,
                    marginTop: 55,
                  }}
                >
                  Bio
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      width: "100%",
                    }}
                  >
                    {/* <NameTextField
                        id="fullname"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullname}
                        variant="filled"
                        label="First + Last Name"
                      /> */}

                    <div style={{ margin: 10 }}></div>
                    {/* <BioTextField
                        id="bio"
                        type="text"
                        multiline
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bio}
                        variant="filled"
                        label="Bio"
                      /> */}
                    <textarea
                      type="text"
                      id="bio"
                      className="formInputOutline"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bio}
                      variant="filled"
                      label="Bio"
                      style={{
                        border: "0px solid #545454",
                        overflow: "hidden",
                        borderRadius: 20,
                        color: "white",
                        height: 80,
                        width: "100%",
                        paddingLeft: 10,
                        fontFamily: "quicksand",
                        resize: "none",

                        paddingLeft: 17,
                        fontFamily: "quicksand",
                        resize: "none",
                        paddingTop: 12,
                        background: "#323232",
                        borderRadius: 10,
                      }}
                      placeHolder="Bio"
                      maxLength={125}
                    ></textarea>
                    <p style={{ color: "#F06263", fontFamily: "quicksand" }}>
                      {formik.errors.bio}
                    </p>
                  </div>
                </div>
                <div style={{ margin: 35 }}></div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "quicksand",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 30,
                  }}
                >
                  {/* <button
                    id="SaveAndContinue"
                    type="submit"
                    style={{ backgroundColor: "#4FD975" }}
                  >
                    <p style={{ fontFamily: "Quicksand", fontWeight: "bold" }}>
                      Save
                    </p>
                  </button> */}

                  <button id="SaveAndContinue" disabled={uploading}
                    type="submit" className="community-create-community-button" style={{ marginTop: 20 }}>
  {uploading ? (
    <div className="dot-animation">Uploading</div>
  ) : 'Save'}
</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserModal);
