import {
  faPenSquare,
  faSave,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as React, useContext, useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
const ImageComponent = (props) => {
  // const [giphy, setGiphy] = useState("");
  // const [swipeIndex, setSwipeIndex] = useState(0);
  const WidgetStore = useContext(WidgetStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);

  let widgets = WidgetStore.widgets;
  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();
  const FlowStore = useContext(FlowStoreContext);
  let imageComponentLoaded = false;
  const [lock, setLock] = useState(false);
  const [showUnlockWidget, setShowUnlockWidget] = useState(false);
  const [showLockWidget, setShowLockWidget] = useState(false);

  const [isDevAccount, setIsDevAccount] = useState(false);
  const [image, setImage] = useState("");

  // useEffect(()=>{
  //   if(props.imgSrc === '') {
  //     setImage(props.imgSrc)
  //   } else {
  //     setImage(props.imgSrc);
  //   }
  // },[props.imgSrc])

  return (
    <div
      style={{
        width: "100%",
        display:"flex",
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 35,
            width: "100%",
            borderBottom: "1px solid #323232",
          }}
        ><p style={{fontSize:15, color:'white', fontFamily:'quicksand', margin:7}}>{props.label == null ? 'Image Uploader':props.label}</p></div>
  

      </div>
        <div
          onClick={() => {
            WidgetStore.setDropImageValue(null);

            // WidgetStore.setCurrentlySelectedFunction(props.function);
            // WidgetStore.setWidgetActualInstance(props.actualInstance);
            // localStorage.setItem("WidgetActualInstance", props.actualInstance);
            // WidgetStore.setEditorSelected(
            //   WidgetStore.widgets[props.actualInstance].editorSelected
            // );
            WidgetStore.setSwipeIndex(1);
          }}
          id={props.imageId}
          style={{
            background: "#212F25",

            height: 85,
            width: 125,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            margin: 27,
          }}
        >
          <p
            id={`add_${props.actualInstance}`}
            style={{
              color: "#4FD975",
              fontFamily: "quicksand",
              fontSize: 14,
              visibility: "hidden",
            }}
          >
            Add
          </p>
          <p
            id={`add_image_${props.actualInstance}`}
            style={{
              color: "#4FD975",
              fontFamily: "quicksand",
              fontSize: 14,
              visibility: "hidden",
            }}
          >
            Image
          </p>
        </div>
     
      
    </div>
  );
};

export default React.memo(ImageComponent);
