// src/Components/Home/Announcements.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

// Function to add a notification when an announcement is made
const addInviteCodeNotification = async (sender, receiver, message, flowId) => {
  const newNotification = {
    sender,
    receiver,
    type: "flowroom",
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    additionalData: { message, flowId },
    isSeen: false
  };
  await firebase.firestore().collection("notifications").add(newNotification);
};

const Announcements = () => {
  const db = firebase.firestore();
  const [announcement, setAnnouncement] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [error, setError] = useState('');

  // Define the maximum character limit
  const MAX_CHAR_LIMIT = 350;

  useEffect(() => {
    console.log("Fetching site announcements...");
    const unsubscribe = db.collection('siteMessage')
      .orderBy('createdAt', 'desc') // Optional: Order announcements by date
      .onSnapshot(snapshot => {
        const msgs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAnnouncements(msgs);
      }, err => {
        console.error("Error fetching announcements:", err);
        setError('Failed to fetch announcements.');
      });

    return () => unsubscribe();
  }, [db]);

  const handleSubmitAnnouncement = async () => {
    setAnnouncementMessage('');
    setError('');

    // Input Validation
    if (!announcement.trim()) {
      setAnnouncementMessage('Please provide a message.');
      return;
    }

    if (announcement.length > MAX_CHAR_LIMIT) {
      setError(`Announcement exceeds the maximum limit of ${MAX_CHAR_LIMIT} characters.`);
      return;
    }

    try {
      await db.collection('siteMessage').add({
        message: announcement.trim(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setAnnouncementMessage('Announcement has been submitted.');
      await addInviteCodeNotification("flowroom", "all_flowroo_musers_XYRGT-TEXTX", announcement.trim(), "noid");

      setAnnouncement('');
    } catch (err) {
      console.error("Error submitting announcement:", err);
      setAnnouncementMessage('Failed to submit announcement. Please try again.');
    }
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    setAnnouncementMessage('');
    setError('');

    try {
      await db.collection('siteMessage').doc(announcementId).delete();
      setAnnouncementMessage('Announcement has been deleted.');
    } catch (err) {
      console.error("Error deleting announcement:", err);
      setAnnouncementMessage('Failed to delete announcement. Please try again.');
    }
  };

  // Handler to manage announcement input with character limit
  const handleAnnouncementChange = (e) => {
    const inputAnnouncement = e.target.value;
    if (inputAnnouncement.length <= MAX_CHAR_LIMIT) {
      setAnnouncement(inputAnnouncement);
    } else {
      // Optionally, you can notify the user that the limit has been reached
      setAnnouncement(inputAnnouncement.slice(0, MAX_CHAR_LIMIT));
    }
  };

  // Modified Row component to include the date
  const Row = React.memo(({ index, style }) => {
    const msg = announcements[index];
    const { message, createdAt } = msg;

    // Convert Firestore Timestamp to JavaScript Date
    const date = createdAt ? createdAt.toDate() : new Date();

    // Format the date to a readable string
    const formattedDate = date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div style={styles.messageContainer}>
          <span style={styles.messageText}>{message}</span>
          <span style={styles.dateText}>{formattedDate}</span>
        </div>
        <button
          onClick={() => handleDeleteAnnouncement(msg.id)}
          style={styles.deleteButton}
        >
          Delete
        </button>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2>Site-wide Announcements</h2>
      <div style={styles.inputGroup}>
        <textarea
          placeholder="Write your announcement here..."
          value={announcement}
          onChange={handleAnnouncementChange} // Updated to use the new handler
          style={styles.textarea}
          maxLength={MAX_CHAR_LIMIT} // Enforce maximum character limit
        ></textarea>
        {/* Character Count Display */}
        <div style={styles.charCountContainer}>
          <span style={styles.charCount}>
            {announcement.length} / {MAX_CHAR_LIMIT}
          </span>
        </div>
        <button onClick={handleSubmitAnnouncement} style={styles.button}>
          Submit Announcement
        </button>
      </div>
      {announcementMessage && <p style={styles.message}>{announcementMessage}</p>}
      <List
        height={400}
        itemCount={announcements.length}
        itemSize={80} // Increased height to accommodate date
        width={'100%'}
      >
        {Row}
      </List>
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    position: 'relative',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '80px',
    resize: 'vertical',
    fontSize: '16px',
  },
  charCountContainer: {
    alignSelf: 'flex-end',
    marginTop: '-10px',
    marginBottom: '10px',
  },
  charCount: {
    fontSize: '12px',
    color: '#666',
  },
  button: {
    alignSelf: 'flex-start',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ddd', // Optional: Add border for better separation
  },
  deleteButton: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#DC3545',
    color: '#fff',
    cursor: 'pointer',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  dateText: {
    fontSize: '12px',
    color: '#666',
    marginTop: '4px',
  },
};

export default Announcements;
