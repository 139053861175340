import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore";
import "./Switch.css";

function PromptUI({ defaultChecked = false, onToggle }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const EditorStore = useContext(EditorStoreContext);

  return (
    <div style={{height:'100%', minHeight:300, backgroundColor:'rgb(28, 28, 28)', maxWidth:400, width:'100%', minWidth:300, paddingBottom:20, paddingLeft:10, paddingRight:10}}>
      <textarea style={{height:'100%', minHeight:250, marginTop:50, width:'100%', resize:'none', outline:'none', padding:10, fontFamily:'quicksand', backgroundColor:'#E9E9E91A', border:'none', color:'white', fontSize:15}}></textarea>
    </div>
  );
}

export default PromptUI;
