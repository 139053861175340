import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class CreditStore {
  constructor() {
    makeAutoObservable(this);
  }

  credits = 0;

  setCredits(credits) {
    this.credits = credits;
  }
}

export const CreditStoreContext = createContext(new CreditStore());
