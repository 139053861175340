import { default as React } from "react";

const FlowNotFound = () => (
  <div className="" style={{ flex: "1", display: "flex", justifyContent:'center', alignItems:'center', height:'height: calc(100vh - 70px)' }}>
    

    <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 70px)",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: 70,
                    width: 500,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 35,
                    padding: "10px 55px",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: "url(/blocked-icon.svg)",
                      backgroundSize: "contain",
                      height: "70px",
                      backgroundPosition: "center",
                      width: "100%",
                      maxWidth: "500px",
                      backgroundRepeat: "no-repeat",
                      /* margin-top: 137px; */
                      position: "relative",
                    }}
                  ></div>
                </div>
                <div style={{paddingLeft:10, paddingRight:10}}>
                <p
                  style={{
                    color: "white",
                    fontFamily: "quicksand",
                    marginBottom: 40,
                    cursor: "pointer",
                    textAlign:'center',
                    maxWidth:300
                  }}
                >
                  This owner of this flow has blocked you which means you will not be able to interact with or use this flow unless you are unblocked.
                </p>
                </div>
                {/* <Link to="/" style={{ maxWidth: 200, width: "100%" }}> */}
                <a href="/" style={{maxWidth:200, width:'100%'}}>
                  <div
                    style={{
                      height: 30,
                      width: "100%",
                      maxWidth: 200,
                      backgroundColor: "#4FD975",
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "#222222",
                        fontFamily: "quicksand",
                        fontSize: 17,
                      }}
                    >
                      Return Home
                    </p>
                  </div>
                  </a>
</div>


  </div>
);

export default FlowNotFound;
