import DropNCrop from "@synapsestudios/react-drop-n-crop";
import "@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore";
import { RemixableStoreContext } from "../../store/RemixableStore";
import { WidgetStoreContext } from "../../store/WidgetStore";

const DragDropNCrop = observer((props) => {
  const RemixableStore = useContext(RemixableStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);

  const DHTMLStore = useContext(DHTML_StoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const [isLoading, setIsLoading] = useState(false);


  let imageIndex = WidgetStore.imageIndexes;
  const [value, setValue] = useState({
    result: null,
    filename: null,
    filetype: null,
    src:
      window["flow_widget_type_" + props.selectedImageName] === undefined &&
      window["flow_widget_type_" + props.selectedImageName] === null &&
      window["flow_widget_type_" + props.selectedImageName].trim() === ""
        ? null
        : window["flow_widget_type_" + props.selectedImageName],
    error: null,
  });

  let onChange = async (value) => {
    setValue(value)

    WidgetStore.setDropImageValue(value.result);
    try {
      localStorage.setItem(`${props.imageId}`, value.result);
      } catch(err) {}
  };

  return (

   <DropNCrop
      onChange={onChange}
      value={value}
      height={"300px"}
      width={"300px"}
    />
 
  );
});

export default DragDropNCrop;
