import {
  faMicrophone,
  faQuestionCircle,
  faTimesCircle,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import Checkbox from "react-custom-checkbox";
import ReactModal from "react-modal";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AuthenticationStoreContext } from "../../store/AuthenticationStore";
import { DHTML_StoreContext } from "../../store/DHTML_Store";
import { EditorStoreContext } from "../../store/EditorStore";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../store/RemixableStore";
import { WidgetStoreContext } from "../../store/WidgetStore";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal";
import { inspect } from "./db";

let initialCall = false;
let addImage;
let addImageFuncCalls = 0;
let addTextFuncCalls = 0;
let ColorInstance = -1;
let getText;
let searchArray;
let textFromResponse;
let functionToCall;
let imageToReplaceArray;
let searchQueryIndex = 0;
let imageIndexSelect = 0;
let alreadyLoadedModel = false;
let textIndexSelect = 0;
let colorIndexSelect = 0;
let sliderIndexSelect = 0;
let switchIndexSelect = 0;
let selectIndexSelect = 0;
let checkboxIndexSelect = 0;
let calendarIndexSelect = 0;
let rateCounter = 0;
let IndexSelect = 0;
let typingTimer;
let actualInstanceIncrement = 0;
let startingTextIncrement = [];
let startingImageIncrement = [];
let hiddenWidgetsAlreadyLoaded = false;
var two_line = /\n\n/g;
var one_line = /\n/g;
let final_transcript;
function linebreak(s) {
  return s.replace(two_line, "<p></p>").replace(one_line, "<br>");
}
var first_char = /\S/;
function capitalize(s) {
  return s.replace(first_char, function (m) {
    return m.toUpperCase();
  });
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    // backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 244,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
let h = 0;
let text = "";
let passDataToIframe = (functionToCall, data) => {
  try {
    let iframe = document.getElementById("undefined_output_frame");
    if (iframe !== undefined && iframe !== null) {
      let ev = `let iframe = document.getElementById(
                    "undefined_output_frame"
                  );
                  if (iframe !== undefined && iframe !== null) {
                    iframe.contentWindow.${functionToCall}('${data}')
                  }`;
      console.log("function called", ev);
      try {
        eval(ev);
      } catch (e) {}
    }
    // setColor(color.hex);
  } catch (e) {
    console.log(e);
  }
};
let imageArr = [];
let textArr = [];

const AIWidget = observer((props) => {
  const [gptConfirmation, setGPTConfirmation] = useState(false);
  const [remixAIText, setRemixAIText] = useState("");
  const [textAreaCount, setTextAreaCount] = useState(0);

  const FlowStore = useContext(FlowStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const PublishStore = useContext(PublishMenuStoreContext);

  const [taking, setTaking] = React.useState(false);
  const [btnText, setBtnText] = useState("");

  const classes = useStyles();

  const [imageArray, setImageArray] = useState([]);

  // useState(0);

  const RemixableStore = useContext(RemixableStoreContext);
  const DHTMLStore = useContext(DHTML_StoreContext);
  const [widgetType, setWidgetType] = useState(false);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const [value, setValue] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [checked, setChecked] = React.useState(true);

  const [colorSel, setColor] = useState([]);

  const [giphy, setGiphy] = useState([]);
  const [jumpTo, setJumpTo] = useState([]);
  const [val, setVal] = useState([0, 40]);
  const [template, setTemplate] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [charLimit, setCharLimit] = useState(0);
  const [bottom, setBottom] = useState(false);
  const [useModel, setUseModel] = useState(false);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const [isShowCheckoutForm, setIsShowCheckoutForm] = useState(false);
  const [aiWidgetVsible, setAIWidgetVsible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const EditorStore = useContext(EditorStoreContext);

  const [bucketInterval, setBucketInterval] = React.useState(5000);
  const [bucketCapacity, setBucketCapacity] = React.useState(10);
  const [key, setKey] = React.useState("My Key");
  const [result, setResult] = React.useState("");
  const [tokenState, setTokenState] = React.useState(inspect(key));
  const [showHiddenRemixWidgets, setShowHiddenRemixWidgets] = useState(false);
  const [showHiddenOption, setShowHiddenOption] = useState(false);
  const [dontShow, setDontShow] = useState(false);
  const [model, setModel] = useState("");
  const [showDeleteWidget, setShowDeleteWidget] = useState(false);
  const [notEnough, setNotEnough] = useState(false);
  const { currentUser } = useContext(AuthContext);

  let widgets = WidgetStore.widgets;
  let parts = window.location.pathname.split("/");

  let lastSegment = parts.pop() || parts.pop();

  const deductCredit = (creditAmount) => {
    if (firebase.auth().currentUser !== null) {
      let currentUser = firebase.auth().currentUser;
      firebase
        .firestore()
        .collection("credits")
        .doc(currentUser.uid)
        .update({
          credits: FlowStore.credit - creditAmount,
        })
        .then(() => {
          FlowStore.setCredits(FlowStore.credit - creditAmount);
        });
    }
  };
  let getStartingTextIncrementNum = () => {
    for (let i = 0; i < WidgetStore.widgets.length; i++) {
      if (WidgetStore.widgets[i].widget === "TextInput") {
        textArr.push(WidgetStore.widgets[i].actualInstance - 1);
      }
    }
    return textArr;
  };

  let getStartingImageIncrementNum = () => {
    for (let i = 0; i < WidgetStore.widgets.length; i++) {
      if (WidgetStore.widgets[i].widget === "ImageUploader") {
        imageArr.push(WidgetStore.widgets[i].actualInstance - 1);
      }
    }
    return imageArr;
  };

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  useEffect(() => {
    let isMounted = true; // flag to track mount status

    if (props.model !== undefined && props.model !== "") {
      // PublishMenuStore.setAITextPopulated(final_transcript);
      // setRemixAIText(PublishMenuStore.AITextPopulated);

      firebase
        .firestore()
        .collection("models")
        .doc(props.model)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
          setAIWidgetVsible(true);

          let data = doc.data();
          setCharLimit(data.charLimit);
          setBtnText(data.btnText);
          WidgetStore.setAIModulePlaceholder(data.placeholder);
          console.log("data", data);
          alreadyLoadedModel = true;
        });
    }
    return () => {
      isMounted = false; // set flag to false on unmount
    };
  }, [props.model]);

  function startButton(lang) {
    if (recognizing) {
      recognition.stop();
      return;
    }
    final_transcript = "";
    recognition.lang = "English";
    recognition.start();
    ignore_onend = false;
  }
  function stopButton() {
    if (recognizing) {
      recognizing = false;
      recognition.stop();
    }
  }

  var create_email = false;
  var final_transcript = "";
  var recognizing = false;
  var ignore_onend;
  var start_timestamp;
  if (!("webkitSpeechRecognition" in window)) {
    //upgrade();
  } else {
    var recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = true;
    recognition.onstart = function () {
      recognizing = true;
      //   showInfo("info_speak_now");
    };
    recognition.onerror = function (event) {
      if (event.error == "no-speech") {
        ignore_onend = true;
      }
      if (event.error == "audio-capture") {
        // start_img.src =
        //   //   "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22%2F%3E%3Cpath%20d%3D%22M12%2015c1.66%200%202.99-1.34%202.99-3L15%206c0-1.66-1.34-3-3-3S9%204.34%209%206v6c0%201.66%201.34%203%203%203zm-1.2-9.1c0-.66.54-1.2%201.2-1.2s1.2.54%201.2%201.2l-.01%206.2c0%20.66-.53%201.2-1.19%201.2s-1.2-.54-1.2-1.2V5.9zm6.5%206.1c0%203-2.54%205.1-5.3%205.1S6.7%2015%206.7%2012H5c0%203.41%202.72%206.23%206%206.72V22h2v-3.28c3.28-.48%206-3.3%206-6.72h-1.7z%22%2F%3E%3C%2Fsvg%3E";
        //   ignore_onend = true;
      }
      if (event.error == "not-allowed") {
        if (event.timeStamp - start_timestamp < 100) {
          //      showInfo("info_blocked");
        } else {
          //     showInfo("info_denied");
        }
        ignore_onend = true;
      }
    };
    recognition.onend = function () {
      recognizing = false;
      if (ignore_onend) {
        return;
      }
      if (!final_transcript) {
        //showInfo("info_start");
        return;
      }
      if (final_transcript !== "") {
        setRemixAIText(final_transcript);
        if (final_transcript.length !== charLimit) {
          GPT3REMIX(final_transcript);
          setTextAreaCount(final_transcript.length);
          setIsMicOn(false);
        }
      }
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
        var range = document.createRange();
      }
      if (create_email) {
        create_email = false;
        //createEmail();
      }
    };
    recognition.onresult = function (event) {
      var interim_transcript = "";
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          final_transcript += event.results[i][0].transcript;
        } else {
          interim_transcript += event.results[i][0].transcript;
        }
      }
      final_transcript = capitalize(final_transcript);
      // final_span.innerHTML = linebreak(final_transcript);
      //interim_span.innerHTML = linebreak(interim_transcript);

      // setRemixAIText(final_transcript);

      //setTextAreaCount(final_transcript.length);
      if (final_transcript || interim_transcript) {
        //showButtons("inline-block");
      }
    };
  }
  const startFlowChange = () => {
    RemixableStore.setRemixThis(true);
    document.getElementById("undefined_output_frame").style.visibility =
      "hidden";
  };

  const endFlowChange = () => {
    RemixableStore.setRemixThis(false);
    document.getElementById("undefined_output_frame").style.visibility =
      "visible";
  };
  const contentFilterModule = (options, prompt) => {
    return fetch(
      `https://us-central1-flowroom-fd862.cloudfunctions.net/contentFilter`,
      {
        method: `POST`,
        body: JSON.stringify({ options: options, prompt: prompt }),
      },
      {
        mode: "cors",
      }
    );
  };

  const remixGPTImageSearchUpdate = (
    term = null,
    increment,
    text = null,
    usedFor,
    removeBG,
    gif,
    gif_remove_bg
  ) => {
    let widgets = WidgetStore.widgets;
    if (gif !== true) {
      fetch(
        `https://us-central1-flowroom-fd862.cloudfunctions.net/imageSearch?term=${term}`,
        { mode: "cors" }
      )
        .then((response) => response.json())
        .then((imageObj) => {
          console.log("image search", imageObj);

          WidgetStore.setReplaceValue(imageObj.image[0].url);
          WidgetStore.setWidgetValueLength(imageObj.image[0].url.length);
          WidgetStore.setWidgetActualInstance(increment);
          WidgetStore.setEditorSelected(widgets[increment].editorSelected);
          WidgetStore.setIsReplaceJS(true);
          // try {
          // } catch (error) {
          //   console.log("error remi", error);
          //   // WidgetStore.setCurrentErrorMessage(
          //   //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
          //   // );
          //   // WidgetStore.setErrorMessage(true);
          //   // RemixableStore.setRemixThis(false);

          //   endFlowChange();
          // }

          //}
        })
        .catch((error) => {
          // alert(error);
          // WidgetStore.setCurrentErrorMessage(
          //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
          // );
          WidgetStore.setErrorMessage(true);
          RemixableStore.setRemixThis(false);
          endFlowChange();
        });
    }
  };
  const remixGPTTextUpdate = (text = null, widgetIndex) => {
    try {
      let widgets = WidgetStore.widgets;
      // console.log("WidgetStore.widgets[20].actualInstance");
      // if (widgets[widgetIndex] !== undefined) {
      //   widgets[widgetIndex].value = text;
      //   WidgetStore.setWidgets([]);
      //   setTimeout(() => {
      //     WidgetStore.setWidgets(widgets);
      //     FlowStore.setRenderIframe(true);
      //     WidgetStore.setIsReplaceJS(true);
      //     WidgetStore.setEditorSelected(
      //       WidgetStore.widgets[widgetIndex].editorSelected
      //     );
      //     FlowStore.setRenderIframe(true);
      //   });
      // }

      if (
        widgets[widgetIndex].actualInstance !== null &&
        widgets[widgetIndex].actualInstance !== undefined
      ) {
        localStorage.setItem("ReplaceValue", text);
        localStorage.setItem("WidgetActualInstance", widgetIndex);
        WidgetStore.setWidgetValueLength(text.length);
        WidgetStore.setIsReplaceJS(true);
        // WidgetStore.setWidgetActualInstance(props.actualInstance);
        WidgetStore.setEditorSelected(
          WidgetStore.widgets[props.actualInstance].editorSelected
        );

        // WidgetStore.setEditorSelected(widgets[widgetIndex].editorSelected);
        // WidgetStore.setReplaceValue(text);
        // WidgetStore.setWidgetValueLength(text.length);
        // WidgetStore.setIsReplaceJS(true);
        // WidgetStore.setWidgetActualInstance(widgetIndex);
        // FlowStore.setRenderIframe(true);

        endFlowChange();
      } else {
        // alert("reselect");
        endFlowChange();
      }
      // WidgetStore.setEditorSelected(
      //   WidgetStore.widgets[WidgetStore.widgets[widgetIndex - 1].actualInstance]
      //     .editorSelected
      // );
    } catch (e) {
      // alert("reselect");
      endFlowChange();
    }
  };
  // addImage = () => {
  //   try {
  //     if (searchArray[searchQueryIndex] !== undefined) {
  //       if (searchQueryIndex < searchArray.length) {
  //         remixGPTImageSearchUpdate(
  //           searchArray[searchQueryIndex],
  //           startingImageIncrement[searchQueryIndex]
  //         );
  //         imageIndexSelect++;
  //         searchQueryIndex++;
  //         addImageFuncCalls++;
  //         window.getAlready = false;
  //       }
  //     }
  //     if (searchQueryIndex === startingImageIncrement.length - 1) {
  //       setTimeout(() => {
  //         endFlowChange();
  //       }, 5000);
  //     }
  //   } catch (e) {}
  // };

  // getText = () => {
  //   if (textFromResponse[h] !== undefined) {
  //     if (h < textFromResponse.length) {
  //       remixGPTTextUpdate(textFromResponse[h], startingTextIncrement[h]);

  //       h++;
  //       textIndexSelect++;
  //       addTextFuncCalls++;
  //       window.getAlready = false;
  //     }
  //     if (h === startingTextIncrement.length - 1) {
  //       setTimeout(() => {
  //         endFlowChange();
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    if (RemixableStore.useModel === true) {
      if (RemixableStore.wait === false) {
        if (
          startingImageIncrement.length !== 0 &&
          addImageFuncCalls < startingImageIncrement.length
        ) {
          if (searchArray.length !== 0) {
            // alert("call" + " " + addImageFuncCalls);
            addImage();

            // getText();
          }
        } else if (
          startingTextIncrement.length !== 0 &&
          addTextFuncCalls < startingTextIncrement.length
        ) {
          if (textFromResponse.length !== 0) {
            // alert("call text");
            console.log(textFromResponse);
            getText();
          }
        }
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [RemixableStore.wait]);

  const setJump = () => {
    let textCount = 0;
    let imageCount = 0;
    let colorCount = 0;
    let sliderCount = 0;
    let switchCount = 0;
    let selectCount = 0;
    let checkboxCount = 0;
    let calendarCount = 0;

    let optionsJump = [];
    WidgetStore.widgets.map((widget, i) => {
      if (optionsJump.length !== widgets.length) {
        if (
          widget.widget === "ImageUploader" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          imageCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: imageCount,
          });
        } else if (
          widget.widget === "TextInput" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          textCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: textCount,
          });
        } else if (
          widget.widget === "ColorSelect" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          colorCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: colorCount,
          });
        } else if (
          widget.widget === "Slider" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          sliderCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: sliderCount,
          });
        } else if (
          widget.widget === "Switch" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          switchCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: switchCount,
          });
        } else if (
          widget.widget === "Select" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          selectCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: selectCount,
          });
        } else if (
          widget.widget === "Checkbox" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          checkboxCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: checkboxCount,
          });
        } else if (
          widget.widget === "Calendar" &&
          widget.isLocked === true &&
          widget.advancedOptions === false
        ) {
          calendarCount++;

          optionsJump.push({
            value: `label_${i}`,
            label: widget.label,
            widget: widget.widget,
            index: i,
            widgetIndex: calendarCount,
          });
        }
      }
    });
    WidgetStore.setJumpTo(optionsJump);
  };

  let GPT3REMIX = (promptText) => {
    EditorStore.setGPTCall(false);

    const GPTClient = (options, prompt) => {
      return fetch(
        `https://us-central1-flowroom-fd862.cloudfunctions.net/GPT3`,
        {
          method: `POST`,
          body: JSON.stringify({ options: options, prompt: prompt }),
        },
        {
          mode: "cors",
        }
      );
    };

    const GPTClientImagesAndText = (options, prompt) => {
      return fetch(
        `https://us-central1-flowroom-fd862.cloudfunctions.net/GPT3NLP`,
        {
          method: `POST`,
          body: JSON.stringify({ options: options, prompt: prompt }),
        },
        {
          mode: "cors",
        }
      );
    };
    //  let htmlString = htmlEditor.current.getValue();
    //  let position = htmlEditor.current.getPosition();
    //  let text = htmlEditor.current.getValue(position);
    //  let splitedText = text.split("\n");
    //  let line = splitedText[position.lineNumber - 1];
    // let templateObj = {
    //   options: {
    //     engine: "davinci",
    //     maxTokens: 307,
    //     temperature: 0.4,
    //     topP: 1,
    //     presencePenalty: 0,
    //     frequencyPenalty: 0,
    //     bestOf: 1,
    //     n: 1,
    //     stream: false,
    //     stop: ["###"],
    //   },
    //   prompt: `The following is a list of songs and the categories they fall into

    // C:make the first song ludachris southern hospitality and the second jay z new york then beyonce single ladies|
    // R:S:['Ludachris','Jay Z','Beyonce']|
    // T:['Ludachris','Southern Hospitality',Jay Z','New York','Beyonce','Single Ladies']|
    // IM:['cover1','cover2','cover3']|
    // TN:['artist1','songTitle1','artist2','songTitle2','artist3','songTitle3']|
    // ###
    // C:change the first song to justin bieber baby, 2pac all eyez on me, big pun you came up, the game westside story, beyonce formation|
    // R:S:['Justin Bieber','2pac','Big Pun','The Game','Beyonce']|
    // T:['Justin Bieber','Baby','2pac','All Eyez On Me','Big Pun','You Came Up','The Game','Westside Story','Beyonce','Formation']|
    // IM:['cover1','cover2','cover3','cover4']|
    // TN:['artist1','songTitle1','artist2','songTitle2','artist3','songTitle3','artist4','songTitle4']|
    // ###
    // C:eminem guilty conscience, effiel 65 blue, da baby suge, snoop dogg west, tony braxton unbreak my heart|
    // R:S:['Eminem','Effiel 65','Da Baby','Snoop Dogg','Tony Braxton']|
    // T:['Eminem','Guilty Conscience','Effiel 65','Blue','Da Baby','Suge','Snoop Dogg','West','Tony Braxton','Unbreak My Heart']|
    // IM:['cover1','cover2','cover3','cover4','cover5']|
    // TN:['artist1','songTitle1','artist2','songTitle2','artist3','songTitle3','artist4','songTitle4','artist5','songTitle5']|
    // ###
    // C:Jennifer Lopez jenny from the block, big pun not a playa|
    // R:S:['Jennifer Lopez','Big Pun','Not A Playa']|
    // T:['Jennifer Lopez','Jenny From The Block','Big Pun','Not A Playa']|
    // IM:['cover1','cover2']|
    // TN:['artist1','songTitle1','artist2','songTitle2']|
    // ###
    // C:Olivia Rodrigo "Drivers License", Silk Sonic "Leave the Door Open", The Weeknd & Ariana Grande "Save Your Tears" (Remix), Dua Lipa feat. DaBaby "Levitating", Kali Uchis "Telepatía", Pooh Shiesty feat. Lil Durk "Back in Blood", SZA "Good Days", Justin Bieber feat. Daniel Caesar & Giveon "Peaches", Lil Nas X "Montero (Call Me By Your Name)"|
    // R:S:['Olivia Rodrigo','Silk Sonic','The Weeknd & Ariana Grande','Dua Lipa feat. DaBaby','Kali Uchis','Pooh Shiesty feat. Lil Durk','SZA','Justin Bieber feat. Daniel Caesar & Giveon','Peaches','Lil Nas X','Montero',Call Me By Your Name']|
    // T:['Olivia Rodrigo','Drivers License','Silk Sonic','Leave The Door Open','The Weeknd & Ariana Grande','Save Your Tears (Remix)','Dua Lipa feat. DaBaby','Levitating','Kali Uchis','Telepatía','Pooh Shiesty feat. Lil Durk','Back in Blood','SZA','Good Days','Justin Bieber feat. Daniel Caesar & Giveon','Peaches','Lil Nas X','Montero','Call Me By Your Name']|
    // IM:['cover1','cover2','cover3','cover4','cover5','cover6','cover7','cover8','cover9']|
    // ###
    // C:Show me the top 5 songs for kids|
    // R:S:['Muppets','The Ramones','Pink Fong','Bobby Darin','Paul Simon']|
    // T:['Muppets','Bohemian Rhapsody','The Ramones','Spider-Man','Pink Fong','Baby Shark','Bobby Darin','Splish Splash','Paul Simon','Sesame Street']|
    // IM:['cover1','cover2','cover3','cover4','cover5']|
    // TN:['artist1','songTitle1','artist2','songTitle2','artist3','songTitle3','artist4','songTitle4','artist5','songTitle5']|
    // ###
    // C:2 best songs for love|
    // R:S:['Beyonce','Justin Bieber feat. Ed Sheeran']|
    // T:['Beyonce','Love On Top','Justin Bieber feat. Ed Sheeran','love yourself']|
    // IM:['cover1','cover2']|
    // TN:['artist1','songTitle1','artist2','songTitle2']|
    // ###
    // C:${promptText}|
    // R:`,
    // };
    imageIndexSelect = 0;
    textIndexSelect = 0;
    colorIndexSelect = 0;
    sliderIndexSelect = 0;
    switchIndexSelect = 0;
    selectIndexSelect = 0;
    checkboxIndexSelect = 0;
    calendarIndexSelect = 0;
    searchQueryIndex = 0;
    addImageFuncCalls = 0;
    addTextFuncCalls = 0;
    searchArray = [];
    textFromResponse = [];
    h = 0;
    actualInstanceIncrement = 0;
    startingImageIncrement = [];
    startingTextIncrement = [];
    imageArr = [];
    textArr = [];
    RemixableStore.setWait(true);

    startFlowChange();

    firebase
      .firestore()
      .collection("models")
      .doc(props.model)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // WidgetStore.setCurrentErrorMessage(
          //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
          // );
          // WidgetStore.setErrorMessage(true);
          return;
        }
        let data = doc.data();
        let targetsArr = doc.data().targetsArr;
        ///doc.data().targetsArr;

        let prompt = data.prompt.replace("promptText", promptText);
        let templateObj = {
          options: {
            engine: data.engine,
            maxTokens: data.maxTokens,
            temperature: data.temperature,
            topP: data.topP,
            presencePenalty: data.presencePenalty,
            frequencyPenalty: data.frequencyPenalty,
            bestOf: data.bestOf,
            n: data.n,
            stream: false,
            stop: ["###"],
          },
          prompt: prompt,
        };

        // let templateObj = {
        //   options: {
        //     engine: "davinci",
        //     maxTokens: 230,
        //     temperature: 0,
        //     topP: 1,
        //     presencePenalty: 0.1,
        //     frequencyPenalty: 0,
        //     bestOf: 1,
        //     n: 1,
        //     stream: false,
        //     stop: ["###"],
        //   },
        //   prompt: `The following are rap lyrics and rating whether based on wordplay, metaphors, similes, punchlines, multisyllabic rhymes and near rhymes

        // Lyrics:Triggers from the Grilltown Illtown
        // Some ask how it feels now
        // The deal is that we're real, so we're still 'round
        // Don't lamp with a freestyle phantom
        // Ain't tryin' to be handsome
        // Shrinkin' what you're thinkin', 'cause I'm vampin'|
        // R:T:This is a classic hip hop song. There are a lot of multisyllabic rhymes such as  rhyming "Grilltown" with "Illtown" and "feels now" as well as "still around". There aren't any metaphors, similes, wordplay, punchlines. There are a few near rhymes.T otalScore:5 out of 10|
        // TN:lyrics|
        // ###
        // Lyrics:I been packing a black MAC with it wrapped in a backpack
        // With a stack and I'm Mad Max in the back of the black cab
        // With a gat and a snapback, put a cap in yo' back fact
        // Murder you and your wack little Cat in the Hat raps|
        // R:T:There are a lot of multisyllabic rhymes such as  rhyming "packing a black Mac" with "wrapped in a backpack" and "stack and I'm Mad Max" and "in the back of the black cab" and " with a gat and a snapback" and "put a cap in yo' back fact" and "wack little cat" and "hat raps". There is also a metaphor "I'm Mad Max" which isn't profound but decent in this context. TotalScore:10 out of 10|
        // TN:lyrics|
        // ###
        // Lyrics:Rocking Balmains and they all on my jeans (ooh)
        // I'm off the xans and I pour me some lean (Lil Pump)
        // I sell your mama some crack, she a fiend (huh?)
        // I crash the Porsche and I just left the scene (brrr)|
        // R:T:The rhymes are very basic such as rhyming "jeans" with "lean" and "fiend" and "scene". There is no deep message. There are no near rhymes, metaphors, similes, wordplay, or punchlines TotalScore:1 out of 10|
        // TN:lyrics|
        // ###
        // Lyrics:Whippin, whippin The foreign just so vicious Listen, listen These hoes just want your mention Trippin', trippin' On all the stupid issues Tissues, tissues I swear that I don't miss you Guns don't shine when you're not by The only friend I have is time The only friend I had just died|
        // R:T:There are no multisyllabic rhymes, similes, punchlines, wordplay or metaphors. The only thing that saves this song is the near rhymes. The lyrics are very basic and not profound at all. TotalScore:1 out of 10|
        // TN:lyrics|
        // ###
        // Lyrics:${promptText}|
        // R:`,
        // };
        //alert(line.trim());

        GPTClient(templateObj.options, templateObj.prompt)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            let response = data.data.choices[0].text.trim();
            console.log("gptdata", response);

            startingImageIncrement = getStartingImageIncrementNum();
            startingTextIncrement = getStartingTextIncrementNum();
            console.log("startingImageIncrement", startingImageIncrement);
            console.log("startingTextIncrement", startingTextIncrement);
            //try {
            if (response.includes("S:")) {
              // get terms to search for
              var searchSubString = response.split("S:").pop().split("|")[0];
              searchArray = searchSubString.trim();
              //searchArray = searchArray.split(","); //it becomes an actual array once using split.
              // console.log("searchArray", eval(searchArray));

              // //name of images in flow to replace with images found with search term
              // var imageNameSubString = response
              //   .split("IM:")
              //   .pop()
              //   .split("|")[0];
              // imageToReplaceArray = imageNameSubString.replace(/ /g, "");
              // imageToReplaceArray = imageToReplaceArray.trim();
              console.log("imageToReplaceArray", eval(imageToReplaceArray));
              console.log("searchArray", searchArray);

              if (response.includes("[")) {
                searchArray = eval(searchArray);
                // imageToReplaceArray = eval(imageToReplaceArray);
              }
            }

            if (response.includes("T:")) {
              // alert(props.deductCredit);

              deductCredit(FlowStore.currentlySelectedFlowInFeedTokenAmount);

              var mySubString = response.split("T:").pop().split("|")[0];
              textFromResponse = mySubString.trim();
              console.log("textFromResponse", textFromResponse);

              textFromResponse = mySubString.replace(/ /g, "");
              textFromResponse = mySubString.replace(/(\S)/g, "$1");

              if (!textFromResponse.includes(",")) {
                if (targetsArr[0].target === "class") {
                  if (targetsArr[0].propertyType === "innerText") {
                    document
                      .getElementById("undefined_output_frame")
                      .contentWindow.document.getElementsByClassName(
                        targetsArr[0].className
                      )[0].innerText = textFromResponse;
                    RemixableStore.setRemixThis(false);
                    endFlowChange();
                  } else {
                    document
                      .getElementById("undefined_output_frame")
                      .contentWindow.document.getElementsByClassName(
                        targetsArr[0].className
                      )[0].innerHTML = textFromResponse;
                    RemixableStore.setRemixThis(false);
                    endFlowChange();
                  }
                } else {
                  if (targetsArr[0].propertyType === "innerText") {
                    document
                      .getElementById("undefined_output_frame")
                      .contentWindow.document.getElementById(
                        targetsArr[0].id
                      ).innerText = textFromResponse;
                    RemixableStore.setRemixThis(false);
                    endFlowChange();
                  } else {
                    document
                      .getElementById("undefined_output_frame")
                      .contentWindow.document.getElementById(
                        targetsArr[0].id
                      ).innerHTML = textFromResponse;
                    RemixableStore.setRemixThis(false);
                    endFlowChange();
                  }
                }
              } else {
                let textResponseArray = textFromResponse.split();
                for (let i = 0; textResponseArray.length; i++) {
                  if (targetsArr[i].target === "class") {
                    if (targetsArr[i].propertyType === "innerText") {
                      document
                        .getElementById("undefined_output_frame")
                        .contentWindow.document.getElementsByClassName(
                          targetsArr[i].className
                        )[i].innerText = textFromResponse;
                      RemixableStore.setRemixThis(false);
                      endFlowChange();
                    } else {
                      document
                        .getElementById("undefined_output_frame")
                        .contentWindow.document.getElementsByClassName(
                          targetsArr[i].className
                        )[i].innerHTML = textFromResponse;
                      RemixableStore.setRemixThis(false);
                      endFlowChange();
                    }
                  } else {
                    if (targetsArr[i].propertyType === "innerText") {
                      document
                        .getElementById("undefined_output_frame")
                        .contentWindow.document.getElementById(
                          targetsArr[i].id
                        ).innerText = textFromResponse;
                      RemixableStore.setRemixThis(false);
                      endFlowChange();
                    } else {
                      document
                        .getElementById("undefined_output_frame")
                        .contentWindow.document.getElementById(
                          targetsArr[i].id
                        ).innerHTML = textFromResponse;
                      RemixableStore.setRemixThis(false);
                      endFlowChange();
                    }
                  }
                }
              }

              // if (response.includes("[")) {
              //try {
              // textFromResponse = eval(textFromResponse);
              // console.log("77", textFromResponse);

              //          localStorage.setItem("ReplaceValue", textFromResponse);
              // localStorage.setItem(
              //   "WidgetActualInstance",
              //   1
              // );
              // WidgetStore.setWidgetValueLength(textFromResponse);
              // WidgetStore.setIsReplaceJS(true);
              // // WidgetStore.setWidgetActualInstance(props.actualInstance);
              // WidgetStore.setEditorSelected(
              //   "html"
              // );

              // FlowStore.setRenderIframe(true);
              // WidgetStore.setErrorMessage(true);
              // RemixableStore.setRemixThis(false);
              // endFlowChange();
              // } catch (e) {
              // WidgetStore.setCurrentErrorMessage(
              //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this. No text response"
              // );
              // console.log("text error", e.message);

              //}
              //}
            }

            if (response.includes("L:")) {
              let object = {};
              var labelString = response.split("L:").pop().split("|")[0];
              let lFromResponse = labelString.trim();

              lFromResponse = labelString.replace(/ /g, "");
              lFromResponse = labelString.replace(/(\S)/g, "$1");
              //if (response.includes("[")) {
              lFromResponse = eval(lFromResponse);
              console.log("lFromResponse", lFromResponse);
              for (let i = 0; i < searchArray.length; i++) {
                object[lFromResponse[i]] = searchArray[i];
              }
              for (let j = 0; j < textFromResponse.length; j++) {
                object[lFromResponse[j]] = textFromResponse[j];
              }
              console.log("object", object);
              let iframe = document.getElementById("undefined_output_frame");
              if (iframe !== undefined && iframe !== null) {
                iframe.contentWindow.getData(object);
              }
              //}
            }
            //} catch (e) {
            // WidgetStore.setCurrentErrorMessage(
            //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this." +
            //     e.message +
            //     "first"
            // );
            // WidgetStore.setErrorMessage(true);
            // RemixableStore.setRemixThis(false);
            // endFlowChange();
            // }
            // if (searchArray == [] || textFromResponse == []) {
            //   alert("sorry try again");
            // }

            if (searchArray !== [] || textFromResponse !== []) {
              deductCredit(FlowStore.currentlySelectedFlowInFeedTokenAmount);
            }

            // if (
            //   (searchArray.length !== 0 || textFromResponse.length !== 0) &&
            //   (startingImageIncrement.length !== 0 ||
            //     startingTextIncrement.length !== 0)
            // ) {
            //   RemixableStore.setUseModel(true); //calls the function addImage
            // } else {
            //   RemixableStore.setUseModel(false); //calls the function addImage
            // }

            RemixableStore.setWait(false); //calls the function addImage
          })
          .catch((e) => {
            // WidgetStore.setCurrentErrorMessage(
            //   "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
            // );
            WidgetStore.setErrorMessage(true);
            RemixableStore.setRemixThis(false);
            endFlowChange();
          });
      });
  };

  const contentFilter = (value) => {
    let arr = [];

    WidgetStore.widgets.map((widget) => {
      arr.push(widget.label);
      arr.push(widget.value);
    });
    let check = value;
    let prompt = check;
    let templateObj = {
      options: {
        engine: "content-filter-alpha",
        maxTokens: 250,
        temperature: 0.5,
        topP: 1,
        presencePenalty: 0,
        frequencyPenalty: 0,
        logprobs: 10,
      },
      prompt: prompt,
    };
    console.log("check", check);
    // WidgetStore.setLoadingOneMoment(true);
    GPT3REMIX(value);
    // contentFilterModule(templateObj.options, templateObj.prompt)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("check content", data);
    //     if (data.data.choices[0].text === "0") {
    //       WidgetStore.setShowPostModal(false);
    //       document.getElementById("horizontal-tab-post-tab").style.background =
    //         "transparent";
    //       GPT3REMIX(value);

    //       WidgetStore.setLoadingOneMoment(false);
    //     } else {
    //       WidgetStore.setLoadingOneMoment(false);

    //       document.getElementById("horizontal-tab-post-tab").style.background =
    //         "transparent";

    //       WidgetStore.setShowPostModal(false);

    //       WidgetStore.setCurrentErrorMessage(
    //         "Inappropriate content detected. Check your widget labels, title, or description for offensive language."
    //       );
    //       WidgetStore.setErrorMessage(true);
    //       RemixableStore.setRemixThis(false);
    //     }
    //   })
    //   .catch((e) => {
    //     WidgetStore.setCurrentErrorMessage(
    //       "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
    //     );
    //     WidgetStore.setErrorMessage(true);
    //   });
  };

  return (
    <div className="" style={{ flex: "1", display: "flex" }}>
      <div
        style={{
          padding: "5px",
          width: "100%",
          display: "block",
        }}
      >
        <div
          style={{
            // borderTop: "3px solid #222222",
            // borderBottom: "3px solid #222222",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontFamily: "quicksand",
                fontSize: 20,
                color: "white",
                display: "block",
              }}
            >
              {props.label}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: 70,
              }}
            >
              <div
                onClick={() => {
                  setShowDeleteWidget(true);
                  // widgets.splice(props.actualInstance, 1);
                  // window.widgets.splice(props.actualInstance, 1);
                  // firebase
                  //   .firestore()
                  //   .collection("flows")
                  //   .doc(lastSegment)
                  //   .update({ widgets: widgets })
                  //   .then(() => {
                  //     WidgetStore.setWidgets(widgets);
                  //     WidgetStore.setDeleteWidgetItem(true);
                  //     WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                  //   });
                  // WidgetStore.setWidgets(widgets);
                  // WidgetStore.setDeleteWidgetItem(true);
                  // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                  // WidgetStore.setImageUploaderCount(
                  //   WidgetStore.imageUploaderCount > 0
                  //     ? WidgetStore.imageUploaderCount - 1
                  //     : 0
                  // );
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faWindowClose}
                  color="#F06263"
                  style={{
                    display: "flex",
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>

          {props.showInfo == false ? (
            <textarea
              className="RemixAITextArea formInputOutline"
              style={{
                backgroundColor: "#222222",
                width: "100%",
                borderRadius: 15,
                height: 100,
                resize: "none",
                border: "none",
                padding: 10,
                color: "white",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
              type="text"
              rows={5}
              maxRows={5}
              multiline={true}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
              placeholder={WidgetStore.aiModulePlaceholder}
              onChange={(e) => {
                if (e.target.value.length - 1 !== charLimit) {
                  let string = e.target.value;
                  string = string.replace(/\s+/g, " ");
                  setRemixAIText(string);
                  setTextAreaCount(e.target.value.length);
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(() => {
                    // WidgetStore.setReplaceValue(e.target.value);
                    // WidgetStore.setWidgetValueLength(e.target.value.length);
                    // WidgetStore.setIsReplaceJS(true);
                    // WidgetStore.setWidgetActualInstance(props.actualInstance);
                    // WidgetStore.setEditorSelected(
                    //   WidgetStore.widgets[props.actualInstance].editorSelected
                    // );
                    // FlowStore.setRenderIframe(true);
                  });
                  // PublishMenuStore.setAITextPopulated(e.target.value);
                }
                if (e.target.value.length === 0) {
                  setTextAreaCount(0);
                }
              }}
              // onFocus={() => {}}
              // value={remixAIText}
              onKeyUp={() => {}}
            />
          ) : (
            ""
          )}
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 14,
              marginTop: 12,
            }}
          >
            {props.info}
          </p>
          <DeleteRemixWidgetModal
            deleteWidget={() => {
              widgets = widgets.filter(
                (widget) => widget.label !== props.label
              );
              window.widgets = widgets.filter(
                (widget) => widget.label !== props.label
              );
              let lastArr =
                JSON.parse(localStorage.getItem("lastRemovedAIWidget")) === null
                  ? []
                  : JSON.parse(
                      localStorage.getItem("lastRemovedAIWidget")
                    ).sort();

              lastArr.push(props.instanceOfWidget);
              localStorage.setItem(
                "lastRemovedAIWidget",
                JSON.stringify(lastArr)
              );

              // WidgetStore.setImageUploaderCount(
              //   WidgetStore.imageUploaderCount > 0
              //     ? WidgetStore.imageUploaderCount - 1
              //     : 0
              // );
              if (FlowStore.isFlowOwner === true) {
                firebase
                  .firestore()
                  .collection("flows")
                  .doc(lastSegment)
                  .update({
                    widgets: widgets.filter(
                      (widget) => widget.label !== props.label
                    ),
                  })
                  .then(() => {
                    WidgetStore.setWidgets(widgets);
                    // WidgetStore.setDeleteWidgetItem(true);
                    // WidgetStore.setWidgetDeleteInstance(props.actualInstance);
                    setJump();
                    setShowDeleteWidget(false);
                  });
              }
            }}
            showDeleteRemixWidgetModal={showDeleteWidget}
            closeWidget={() => {
              setShowDeleteWidget(false);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            {props.showInfo === false ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    color: "white",
                    fontFamily: "quicksand",
                    marginRight: 10,
                  }}
                >
                  {`${textAreaCount}`} / {charLimit}
                </p>
                <div data-tip data-for="charLimitTip">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color="#6371F6"
                    style={{
                      display: "block",
                      right: "0px",
                      fontSize: 15,
                    }}
                  />
                </div>
                <div style={{ right: "50px" }}>
                  <ReactTooltip
                    id="charLimitTip"
                    place="bottom"
                    effect="solid"
                    multiline={true}
                  >
                    <p style={{ fontFamily: "quicksand" }}>
                      Character limit for this flow
                    </p>
                  </ReactTooltip>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10000,
              }}
            >
              {/* <p
                      style={{
                        color: "#6371F6",
                        fontFamily: "quicksand",
                        fontWeight: "bold",
                        cursor: "pointer",
                        marginRight: 10,
                      }}
                      onClick={() => {
                        if (
                          firebase.auth().currentUser == null ||
                          firebase.auth().currentUser.displayName == null
                        ) {
                          AuthenticationStore.setShowModal(true);
                          return;
                        }
                        EditorStore.setInstructions(true);
                      }}
                    >
                      Instructions
                    </p> */}
              {/* <div data-tip data-for="instructionTip">
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        color="#6371F6"
                        style={{
                          display: "block",
                          right: "0px",
                          fontSize: 15,
                        }}
                      />
                    </div> */}
              <div style={{ right: "50px" }}>
                <ReactTooltip
                  id="instructionTip"
                  place="bottom"
                  effect="solid"
                  multiline={true}
                >
                  <p style={{ fontFamily: "quicksand" }}>
                    Good ideas for things you can say.
                  </p>
                  <p style={{ fontFamily: "quicksand" }}>
                    Although you can say whatever you want.
                  </p>

                  <p style={{ fontFamily: "quicksand" }}>
                    These are ideas that might work best
                  </p>
                </ReactTooltip>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {props.showInfo == false ? (
              <div
                onClick={() => {
                  if (
                    currentUser == null ||
                    currentUser.currentUser.displayName == null
                  ) {
                    AuthenticationStore.setShowModal(true);
                    return;
                  }
                  setGPTConfirmation(true);

                  // if (isMicOn === false) {
                  //   setIsMicOn(true);
                  //   setGPTConfirmation(true);
                  // } else {
                  //   setIsMicOn(false);
                  //   stopButton();
                  // }
                }}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faMicrophone}
                  color="#6371F6"
                  size={"4x"}
                  style={{
                    display: "flex",
                    marginLeft: 5,
                    marginRight: 20,
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {props.showInfo == false ? (
              <Button
                disabled={taking}
                style={{
                  height: 30,
                  backgroundColor: "rgb(79, 217, 117)",
                  borderRadius: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                  padding: "0px 15px",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  if (currentUser == null) {
                    AuthenticationStore.setShowModal(true);
                    return;
                  }
                  firebase
                    .firestore()
                    .collection("flows")
                    .doc(FlowStore.flowId)
                    .get()
                    .then((doc) => {
                      if (!doc.exists) {
                        return;
                      }
                      let data = doc.data();

                      firebase
                        .firestore()
                        .collection("models")
                        .doc(data.modelId)
                        .get()
                        .then((doc) => {
                          if (!doc.exists) {
                            return;
                          }
                          //prevfilter prompt lenth plus text you counted
                          //amount flowroom fee is charging to use this flow
                          //content filter
                          let modelData = doc.data();

                          let promptTextLength = modelData.prompt.length;
                          let textTyped = textAreaCount;
                          let tokenAmount = promptTextLength + textTyped;
                          let enoughCredit = Math.sign(
                            FlowStore.credit - tokenAmount
                          );
                          FlowStore.setCurrentlySelectedFlowInFeedTokenAmount(
                            tokenAmount
                          );
                          if (
                            currentUser == null ||
                            currentUser.currentUser.displayName == null
                          ) {
                            AuthenticationStore.setShowModal(true);
                            return;
                          }

                          if (enoughCredit == 1) {
                            if (
                              currentUser == null ||
                              currentUser.currentUser.displayName == null
                            ) {
                              AuthenticationStore.setShowModal(true);
                              return;
                            }
                            console.log("called");

                            setGPTConfirmation(true);
                            if (isMicOn === true) {
                              setIsMicOn(false);
                            }
                            stopButton();
                          } else {
                            //modal claiming
                            setNotEnough(true);
                          }
                        });
                    });
                }}
              >
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "#222",
                    fontWeight: "bold",
                  }}
                >
                  {btnText}
                </p>
              </Button>
            ) : (
              ""
            )}
          </div>

          {props.hideOptions !== true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", marginTop: 10 }}>
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "white",
                    marginRight: 5,
                    fontSize: 14,
                  }}
                >
                  Credits remaining:
                </p>
                <p
                  style={{
                    fontFamily: "quicksand",
                    color: "rgb(202, 166, 239)",
                    fontSize: 14,
                  }}
                >
                  {nFormatter(10537)}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontFamily: "quicksand",
                      color: "white",
                      fontSize: 12,
                      marginRight: 7,
                    }}
                  >
                    IMPROVE THIS AI!
                  </p>
                </div>
                <p
                  onClick={() => {
                    if (props.model !== "") {
                      WidgetStore.setImproveAI(true);
                    } else {
                      WidgetStore.setPendingAI(true);
                    }
                  }}
                  style={{
                    fontFamily: "quicksand",
                    color: "#F06263",
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                >
                  Provide Feedback
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              {result}
            </p>
          </div>
          <div
            style={{
              position: "relative",
              top: 20,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <a
                      href={`https://us-central1-flowroom-fd862.cloudfunctions.net/${
                        process.env.REACT_APP_IS_STRIPE_TEST === "true"
                          ? "stripe_checkout_test"
                          : "stripe_checkout"
                      }?userId=${AuthenticationStore.userId}`}
                      target="flowroom_checkout"
                    >
                      <p style={{ color: "white", fontFamily: "quicksand" }}>
                        Add Credit +
                      </p>
                    </a>
                  </div> */}
            {/* <p style={{ fontFamily: "quicksand", color: "white" }}>
                    Credits: {nFormatter(10537)}
                  </p> */}
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={gptConfirmation}
        ariaHideApp={false}
        closeTimeoutMS={200}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            padding: 0,
            zIndex: 1000000,
            height: 400,
            maxWidth: 700,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 7,
            background: "#3F3F3E",
            border: "5px solid #2D2A2B",
            margin: "auto",
            overflow: "hidden",
          },
        }}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#f06263"
          size={"4x"}
          style={{
            display: "block",
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setGPTConfirmation(false);
          }}
        />
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "537px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: 22,
                  fontFamily: "quicksand",
                  marginBottom: "20px",
                  width: "calc(100% / 1.5)",
                }}
              >
                {`${FlowStore.currentlySelectedFlowInFeedTokenAmount} credit(s) will be
                deducted once you press ok. Please remember A.I. will ocasionally
                make mistakes.`}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 20,
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontFamily: "quicksand",
                    fontSize: 17,
                    marginRight: 10,
                  }}
                >
                  Don't show this again
                </p>
                <Checkbox
                  checked={dontShow}
                  icon={
                    <div
                      style={{
                        backgroundColor: "#4FD975",
                        // padding: 5,
                        height: 25,
                        width: 25,
                        borderRadius: 5,
                      }}
                    />
                  }
                  onChange={(e) => {
                    setDontShow(e);
                  }}
                  // inputProps={{ "aria-label": "primary checkbox" }}
                  style={{
                    height: 20,
                    backgroundColor: "#2D2A2B",
                  }}
                  borderColor={"#2d2a2b"}
                  borderRadius={10}
                  size={35}
                />
              </div>
            </div>
            <div
              onClick={() => {
                setGPTConfirmation(false);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 100px)",
                maxWidth: "300px",
                border: "1px solid rgb(79, 217, 117)",
                borderRadius: 5,
                marginBottom: 20,

                backgroundColor: "transparent",
                borderRadius: "40px",
                cursor: "pointer",
                padding: "0px 15px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: 18,
                  fontFamily: "quicksand",
                }}
              >
                Cancel.
              </p>
            </div>
            <div
              onClick={() => {
                // EditorStore.setGPTCall(true);
                // EditorStore.setGPTCallSpeech(true);
                setGPTConfirmation(false);
                if (isMicOn === true) {
                  startButton();
                } else {
                  contentFilter(remixAIText);
                }
              }}
              style={{
                display: "flex",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                width: "calc(100% - 100px)",
                maxWidth: "300px",
                border: "0px solid #40ffe8",
                borderRadius: 5,
                marginBottom: 20,

                backgroundColor: "rgb(79, 217, 117)",
                borderRadius: "40px",
                cursor: "pointer",
                padding: "0px 15px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: 18,
                  fontFamily: "quicksand",
                }}
              >
                Yes, continue
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "100%",
            width: "100%",
            background: "rgb(24,24,24)",
          }}
        ></div>
      </ReactModal>
      <ReactModal
        ariaHideApp={true}
        isOpen={notEnough}
        closeTimeoutMS={200}
        // className={{
        //   base: "remix-base",
        //   beforeClose: "remix-before",
        //   afterOpen: "remix-open",
        // }}
        style={{
          overlay: {
            padding: 0,
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.8)",
            opacity: 1,
          },
          content: {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            border: "0px solid rgb(64, 255, 232)",
            backgroundColor: "#3F3F3E",
            overflow: "hidden",
            borderRadius: "20px",
            outline: "none",
            padding: "0px",
            zIndex: 1000000,
            margin: "auto",
            width: "calc(100% - 50px)",
            height: "45%",
            maxWidth: "500px",
            maxHeight: "300px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            justifyContent: "space-between",
            padding: "20px 25px",
          }}
        >
          <div>
            <p
              style={{
                color: "white",
                fontFamily: "quicksand",
                fontSize: 20,
              }}
            >
              Not enough credits!
            </p>
          </div>
          <div>
            {/* <svg height="30" fill="white" viewBox="0 0 30 30" width="30">
          <g transform="translate(-270 -800)">
            <path d="m285 828c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0-25c6.617 0 12 5.383 12 12s-5.383 12-12 12-12-5.383-12-12 5.383-12 12-12z" />
            <path d="m280.404 820.303 4.596-4.596 4.596 4.596.708-.707-4.597-4.596 4.597-4.596-.708-.707-4.596 4.596-4.596-4.596-.708.707 4.597 4.596-4.597 4.596z" />
          </g>
        </svg> */}
            <FontAwesomeIcon
              onClick={() => {
                setNotEnough(false);
              }}
              icon={faTimesCircle}
              color="#F06263"
              style={{
                display: "block",

                cursor: "pointer",
                fontSize: 35,
              }}
            />
          </div>
        </div>

        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
          }}
        >
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            You do not have enough credits to use this!
          </p>
        </div>
      </ReactModal>
    </div>
  );
});

export default React.memo(AIWidget);
