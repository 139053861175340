import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import ReactModal from "react-modal";
import { EditorStoreContext } from "../../../../store/EditorStore.js";
import { TabStoreContext } from "../../../../store/TabStore.js";
import { WidgetStoreContext } from "../../../../store/WidgetStore.js";
import { FlowStoreContext } from "../../../../store/FlowStore.js";
import { RoomContext } from "../../RoomContext.js"; // Make sure to import the correct context
import { AuthContext } from "../../AuthContext.js"; // Make sure to import the correct context
import { firebase } from "../../../../Components/firebase/firebase";

const NoCodeAccess = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const FlowStore = useContext(FlowStoreContext);

  const TabStore = useContext(TabStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [freeCreditValue, setFreeCreditValue] = useState("1");
  const [confirmCredits, setConfirmCredits] = useState(false);
  const [creditsNeededForSharing, setCreditsNeededForSharing] = useState(0);
  const [freeCreditMultiplier, setFreeCreditMultiplier] = useState(1); // State for the input multiplier
  const [copyLinkWithCreditsLbl, setCopyLinkWithCreditsLbl] = useState("Copy Link with credits");
  const [copyLinkLbl, setCopyLinkLbl] = useState("Copy Link");
  const [errorMessage, setErrorMessage] = useState("");

  const room = useContext(RoomContext);
  const { currentUser } = useContext(AuthContext);

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  // Effect to update creditsNeededForSharing based on the multiplier
  const calculateTotalCredits = (operations) => {
    const total = operations.reduce((sum, operation) => sum + operation.creditsNeeded, 0);
    EditorStore.setTotalCreditsNeededForFlow(total);
  };
  useEffect(() => {
    const updatedSharingCredits = EditorStore.totalCreditsNeededForFlow * freeCreditMultiplier;
    setCreditsNeededForSharing(updatedSharingCredits);
  }, [EditorStore.totalCreditsNeededForFlow, freeCreditMultiplier]); // Depend on both totalCredits and multiplier


  // Handle input change to prevent values less than 1
  const handleMultiplierChange = (e) => {
    const newValue = Math.max(1, Number(e.target.value));
    setFreeCreditMultiplier(newValue); // This should trigger useEffect to re-calculate
  };
  function updateOrCreateFreeCredits() {
    const freeCreditsRef = firebase.firestore().collection("freeCredits")
                            .doc(`${EditorStore.username}_${EditorStore.flowId}`);
  
    freeCreditsRef.get().then(doc => {
      if (doc.exists) {
        // Document exists, update it
        freeCreditsRef.update({
          credits: firebase.firestore.FieldValue.increment(EditorStore.totalCreditsNeededForFlow)
        }).then(() => {
          console.log("Free credits updated successfully.");
        }).catch(error => console.error("Error updating free credits:", error));
      } else {
        // Document does not exist, create a new one
        freeCreditsRef.set({
          flowId: EditorStore.flowId,
          username: EditorStore.username,
          credits: EditorStore.totalCreditsNeededForFlow
        }).then(() => {
          console.log("New free credits document created successfully.");
        }).catch(error => console.error("Error creating free credits document:", error));
      }
    }).catch(error => {
      console.error("Error accessing free credits document:", error);
    });
  }
  return (
    <ReactModal
      isOpen={EditorStore.showShareModal}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          maxWidth: "288px",
          padding: 0,
          overflowX:'hidden'
        },
      }}
    >
      <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000",
        }}
      >
               <img src="/images/share-icon-modal.svg"  style={{marginRight:10, marginLeft:20}}/>

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Share
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            // setDeleteBookmark(false);
            // setDeleteFlow(false);

            EditorStore.setShowShareModal(false);
            TabStore.setClosePanel(true);
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer",  }}
        />
      </div>
      <div
        style={{
          borderTop:'1px solid #323232',
          display: "flex",
          flexWrap: "wrap",
          width: '100%',
          justifyContent: "space-between",
          margin: "auto",
          paddingLeft:20,
          paddingRight:20
        }}
      >
     <div
     onClick={()=> {

      navigator.clipboard.writeText(`https://flowroom.com/room/${EditorStore.flowId}`)
      .then(() => { setTimeout(()=> { setCopyLinkLbl('Copy Link'); }, 1000); setCopyLinkLbl('Copied!')})
      .catch(err => console.error("Failed to copy link to clipboard:", err));

     }}
    className="button-container" // Add this class to each button container
    style={{
      height: "30px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent:"center",
      marginTop:30,
      cursor:'pointer'
    }}
  >
          <img
            src="/images/copy-icon-modal.svg"
            style={{ marginRight: 10, height:15 }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 12,
              color: "white",
            }}
          >
            {copyLinkLbl}
          </p>
        </div>

        {EditorStore.totalCreditsNeededForFlow !== 0 ? (<div>
        <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "#FFF",
              marginBottom: 15,
              marginTop: 12,
            }}
          >
            Share with credits
          </p>
          <p style={{fontFamily:'quicksand', fontSize:11, color:'#F06263'}}>You can share this with some of your own credits so friends can try for free.</p>
          <p style={{fontFamily:'quicksand', fontSize:11, color:'#F06263', marginTop:10}}>Whoever has the link will be able to use the number of credits you provided so be careful.</p>
<div style={{display:'flex', alignItems:'center'}}>    <input
        type="number"
        value={freeCreditMultiplier}
        style={{
          backgroundColor: '#323232',
          height: 30,
          width: 60,
          resize: 'none',
          borderRadius: 5,
          outline: 'none',
          color: '#DF6B68',
          paddingLeft: 10,
          border: 'none',
          marginTop: 4
        }}
        onChange={handleMultiplierChange}
        min="1"  // Ensure the input does not go below 1
      /><p style={{fontFamily:'quicksand', fontSize:11, color:'white', marginLeft:5}}>number of times can be used</p>
</div>
<p style={{color:'white', fontFamily:'quicksand', fontSize:11, marginTop:5}}>Credits needed: {creditsNeededForSharing}</p>
          {confirmCredits === false ? (<div
          onClick={()=> {
            setConfirmCredits(true);
          }}
    className="button-container" // Add this class to each button container
    style={{
      height: "30px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
      justifyContent:"center",
      marginTop:10,
      cursor:'pointer'
    }}
  >
          <img
            src="/images/copy-icon-modal.svg"
            style={{ marginRight: 10, height:15}}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 12,
              color: "white",
            }}
          >
            {copyLinkWithCreditsLbl}
          </p>
        </div>):(<div style={{display:'flex', flexDirection:'column'}}>
          <p style={{fontFamily:'quicksand', color:'white', fontSize:12, marginTop:5}}>Are you sure you want to share some of your credits for someone else to try this for free?</p>
          <div style={{display:'flex'}}>
          <div onClick={() => {
      const documentRef = firebase.firestore().collection("credits").doc(currentUser.uid);

      documentRef.get().then((doc) => {
        const creditsToDeduct = creditsNeededForSharing;
        const availableCredits = FlowStore.credit;
        if (availableCredits < creditsToDeduct) {
          console.error("Not enough credits available.");
          setErrorMessage("You do not have enough credits available!")
          return; // Exit the function early
        }
        setErrorMessage("")

        if (doc.exists) {
          // Document exists, proceed with update
          documentRef.update({
            credits: availableCredits - creditsToDeduct,
          })
          .then(() => {
            FlowStore.setCredits(availableCredits - creditsToDeduct);
            setConfirmCredits(false);
            // Copy link to clipboard after updating
            navigator.clipboard.writeText(`https://flowroom.com/room/${EditorStore.flowId}/?freeCredits=true&flowId=${EditorStore.flowId}&username=${EditorStore.username}`)
              .then(() => {
                setTimeout(() => { setCopyLinkWithCreditsLbl('Copy link with credits'); }, 1000);
                setCopyLinkWithCreditsLbl('Copied!');
              })
              .catch(err => console.error("Failed to copy link to clipboard:", err));
            updateOrCreateFreeCredits(); // Handle freeCredits document after credits document update
          })
          .catch(error => console.error("Error updating document:", error));
        } else {
          // Document does not exist, create it
          documentRef.set({
            credits: availableCredits - creditsToDeduct,
          })
          .then(() => {
            console.log("Document successfully created!");
            FlowStore.setCredits(availableCredits - creditsToDeduct);
            setConfirmCredits(false);
            // Copy link to clipboard after creating
            navigator.clipboard.writeText(`https://flowroom.com/room/${EditorStore.flowId}/?freeCredits=true&username=${EditorStore.username}`)
              .then(() => console.log("Link copied to clipboard!"))
              .catch(err => console.error("Failed to copy link to clipboard:", err));
            updateOrCreateFreeCredits(); // Handle freeCredits document after credits document creation
          })
          .catch(error => console.error("Error creating document:", error));
        }
      }).catch(error => {
        console.error("Error getting document:", error);
      });
          
          }} style={{ height: 25,
    border:'1px solid rgb(79, 217, 117)',
    borderRadius: 10,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    marginTop:10,
    cursor: 'pointer', 
    width:'100%',
    maxWidth: 100,
    marginRight:10
}}><p style={{fontFamily:'quicksand', color:'white', fontSize:12}}>Yes</p></div>
              <div onClick={()=>{
                            setConfirmCredits(false);
                            setErrorMessage("");
              }} style={{ height: 25,
    border:'1px solid rgb(79, 217, 117)',
    borderRadius: 10,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    marginTop:10,
    cursor: 'pointer',     
    width:'100%',
    maxWidth: 100, fontSize:12}}><p style={{fontFamily:'quicksand', color:'white', fontSize:12}}>No</p></div>
    </div>
        </div>)}
        </div>):""}
<p style={{color:'#DF6B68', fontFamily:'quicksand', fontSize:11, marginTop:10}}>{errorMessage}</p>

        {/* <div
    className="button-container" // Add this class to each button container
    style={{
      height: "42px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
    }}
  >
          <img
            src="../images/code-icon-share-modal.svg"
            style={{ marginLeft: 10, marginRight: 20 }}
          />

          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
          >
            Copy Embed
          </p>
        </div> */}
        {/* <div
    className="button-container" // Add this class to each button container
    style={{
      height: "42px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
    }}
  >
          <img
            src="../images/copy-icon-modal.svg"
            style={{ marginLeft: 10, marginRight: 20 }}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
          >
            Copy Gif Recap Link
          </p>
        </div> */}
        {/* <div
    className="button-container" // Add this class to each button container
    style={{
      height: "42px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
    }}
  >
          <img
            src="../images/robot-icon-modal.svg"
            style={{ marginLeft: 10, marginRight: 20 }}
          />

          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
          >
            Copy AI Gif Recap Link
          </p>
        </div> */}
        {/* <div
    className="button-container" // Add this class to each button container
    style={{
      height: "42px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
    }}
  >
          <img
            src="../images/repost-icon-modal.svg"
            style={{ marginLeft: 10, marginRight: 20 }}
          />

          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
          >
            Repost / Reflow
          </p>
        </div> */}
        {/* <div
    className="button-container" // Add this class to each button container
    style={{
      height: "42px",
      border: "1px solid #4FD975",
      borderRadius: 10,
      marginBottom: 20,
      display: "flex",
      alignItems: "center",
    }}
  >
          <img
            src="../images/special-share-icon-modal.svg"
            style={{ marginLeft: 10, marginRight: 20 }}
          />

          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "white",
            }}
          >
            Special Share
          </p>
        </div> */}
        <div style={{marginBottom:20}}>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 16,
              color: "#FFF",
              marginBottom: 20,
              marginTop: 12,
            }}
          >
            Share Via
          </p>
          <div style={{ display: "flex", flexWrap:'wrap', justifyContent:'center' }}>
            <div></div>
            <img
              src="/images/share-message-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-send-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-facebook-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-twitter-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-linkedin-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-reddit-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-snapchat-icon.svg"
              style={{ marginLeft: 10, marginRight: 20,  margin:10}}
            />
            <img
              src="/images/share-rss-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-tiktok-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
            <img
              src="/images/share-tumblr-icon.svg"
              style={{ marginLeft: 10, marginRight: 20, margin:10 }}
            />
          </div>
        </div>

  <style jsx>{`
    .button-container {
      width: 100%; /* default width */
      height: 42px;
      border: 1px solid #4FD975;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      /* other styles */
    }

    @media (max-width: 600px) { /* Adjust this breakpoint as needed */
      .button-container {
        width: 100%; /* full width on small screens */
      }
    }

  .dropdown-container {
    position: relative;
  }
  .dropdown-display {
    background-color: #1c1c1c !important;
    padding: 7px !important;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #4fd975;
    color: white;
    font-family: "Quicksand";
    overflow: hidden;
    border-radius: 20px;
  }
  .dropdown-list {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #1c1c1c;
    border: 0px solid #ddd;
    border-radius: 5px;
    width: 100%;
    z-index: 100;
    bottom: -59px !important;
    border-left: 1px solid #4fd975 !important;
    border-bottom: 1px solid #4fd975 !important;
    border-right: 1px solid #4fd975 !important;
    color: white;
    font-family: quicksand;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    max-height: 200px; /* Adjust based on your requirement */

    /* Custom scroll bar styles */
    scrollbar-width: thin;
    scrollbar-color: #4fd975 #1c1c1c; /* thumb and track color */

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #1c1c1c;
    }
    &::-webkit-scrollbar-thumb {
      background: #4fd975;
      border-radius: 4px;
    }
  }
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }
  `}</style>
      </div>

    </ReactModal>
  );
});

export default React.memo(NoCodeAccess);
