import React, { useEffect, useState } from 'react';
import moment from 'moment'; // Ensure moment.js is installed
import { firebase } from "../firebase/firebase.js";
import { Link, useHistory, useParams } from "react-router-dom";

const RemixItem = ({ flowId, thumbnailUrl, username, remixedBy, date, shares, thumbnailImage, shareCount }) => {
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [flowIdFlow, setFlowIdFlow] = useState("");

  useEffect(()=> {
    setFlowIdFlow(flowId);
  },[flowId])

  useEffect(() => {
    const likesCollectionRef = firebase.firestore().collection('likes');
    const queryLikes = likesCollectionRef.where("flowId", "==", flowId);

    const unsubscribeLikes = queryLikes.onSnapshot(querySnapshot => {
      setLikes(querySnapshot.size);
    }, error => {
      console.error("Error fetching likes: ", error);
    });

    return () => unsubscribeLikes();
  }, [flowId]);

  useEffect(() => {
    const commentsCollectionRef = firebase.firestore().collection('comments');
    const queryComments = commentsCollectionRef.where("flowId", "==", flowId);

    const unsubscribeComments = queryComments.onSnapshot(querySnapshot => {
      setComments(querySnapshot.size);
    }, error => {
      console.error("Error fetching comments: ", error);
    });

    return () => unsubscribeComments();
  }, [flowId]);

  return (
    <Link to={`/room/${flowId}`} style={{width:'100%'}}>

    <div style={{marginTop:10, marginBottom:10}}>
      <div className="remix-item" style={{
        marginBottom: '10px',
        padding: '0px',
        backgroundColor: '#1C1C1C',
        borderRadius: 20,
        maxWidth: 244,
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.5)',
        marginLeft: 2,
        width:'100%',
        minWidth:244
      }}>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', paddingTop: 12, paddingLeft: 8, paddingBottom: 4 }}>
            <div style={{
              height: 25, width: 25, backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center', borderRadius: '50%', marginLeft: 1, position: 'relative', bottom: 2, marginRight:10
            }}></div>
            <div style={{ position: 'relative', bottom: 7 }}>
              <p style={{ color: 'white', fontFamily: 'quicksand', fontSize: 10, position: 'relative', top: 5 }}>{username}</p>
             {remixedBy !== null && remixedBy !== undefined ? (<div style={{ display: 'flex' }}>
                <img src="/images/remixed-by-green.svg" style={{ height: 14, width: 10, marginRight: 5, position: 'relative', top: 2, right: 3 }} />
                <p style={{ color: '#4FD975', fontFamily: 'quicksand', fontSize: 12, marginTop: 0, fontWeight: 100, lineHeight: '15px' }}>{remixedBy}</p>
              </div>):""}
            </div>
            <div style={{ display: 'flex', position: 'absolute', right: 7 }}>
              <p style={{ color: 'white', fontSize: 8, fontWeight: 100, marginRight: 3, opacity: 0.7, top: 3, position: 'relative' }}>{moment(date).fromNow()}</p>
              <img src="/images/ellipsis.svg" style={{ height: 15, top: 3, left: 3, position: 'relative', marginRight: 10 }} />
            </div>
          </div>
          {thumbnailUrl !== null && thumbnailUrl !== undefined && thumbnailUrl !== "" && thumbnailUrl !== "" ? (<div style={{ height: '100%', minHeight: 170, backgroundColor: 'red', width: '100%', backgroundImage: `url(${thumbnailImage})`, backgroundSize: 'cover' }}></div>):(<div style={{height: '100%', minHeight: 170, backgroundColor: '#323232', width: '100%', justifyContent:'center', alignItems:'center', display:'flex'}}><p style={{fontFamily:'quicksand', fontSize:14}}>No Screenshot</p></div>)}
          <div style={{
            width: '100%', maxWidth: 111, height: 45, display: 'flex', justifyContent: 'space-between', marginLeft: 'auto', marginRight: 'auto', marginTop: 0
          }}>
            <Interaction icon="/images/heart.svg" color="#FF5C5C" count={likes} />
            <Interaction icon="/images/comments.svg" color="#0094FF" count={comments} />
            <Interaction icon="/images/shares.svg" color="#DF5FFF" count={shareCount} />
          </div>
        </div>
      </div>
    </div>
    </Link>
  );
};

const Interaction = ({ icon, color, count }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={icon} style={{ height: 15, width: 15 }} />
      <p style={{ fontFamily: 'quicksand', color, fontSize: 8 }}>{count}</p>
    </div>
  );
};

export default React.memo(RemixItem);
