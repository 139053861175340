import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import firebase from "firebase";
import Hashids from "hashids";
import moment from "moment";
import { collection, Doc } from "typesaurus";
import { v4 as uuid } from "uuid";
// store
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";

import { CommentsStoreContext } from "../../store/CommentsStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import takeToken from "./bucket";
import { inspect } from "./db";
import { AuthContext } from "./AuthContext";
import { RoomContext } from "../Home/RoomContext.js"; // Make sure to import the correct context

const ReportModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [reportTitle, setReportTitle] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [reportEmail, setReportEmail] = useState('');
  const [reportCommentModal, setReportCommentModal] = useState(false);
  const [reportErrorMessage, setReportErrorMessage] = useState('');
  const { currentUser } = useContext(AuthContext);
 let flowId = WidgetStore.currentlySelectedFeedFlow == undefined ? EditorStore.flowId: WidgetStore.currentlySelectedFeedFlow;
 
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  const saveReport = async () => {
    if (!reportTitle.trim() || !reportDescription.trim()) {
      setReportErrorMessage('Please fill in all required fields.')
      return;
    }
    try {
      // Check if a report for the same flowId by the current user already exists
      const reportsRef = firebase.firestore().collection("flowReports");
      const existingReportQuery = await reportsRef
        .where("flowId", "==", flowId)
        .where("currentUser", "==", currentUser.displayName)
        .get();
  
      if (!existingReportQuery.empty) {
        setReportErrorMessage('You already made a report for this flow.')
        return;
      }
      // If no existing report is found, proceed to add a new one
      await reportsRef.add({
        title: reportTitle.trim(),
        description: reportDescription.trim(),
        email: reportEmail.trim(), // Optional, consider handling cases where this might be empty
        flowId: flowId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        username: currentUser.displayName
      });
  
      setConfirm(true);
    } catch (error) {
      console.error("Error saving report: ", error);
      setReportErrorMessage('There was an error submitting your report. Please try again.');
    }
  };

  return (
<ReactModal
      ariaHideApp={true}
      isOpen={WidgetStore.showReportModal}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      onAfterClose={()=> {
        setConfirm(false);
        setReportTitle("");
        setReportEmail("");
        setReportDescription("");
        setReportErrorMessage("");
        WidgetStore.setShowReportModal(false);
      }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "450px",
          borderRadius: 20,
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          width: "100%",
          padding: "7px 25px",
          borderBottom: "1px solid #3E3E3E",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: 19.92,
            height: 19.76,
          }}
          src="/images/report-modal-icon.svg"
        />

        <div>
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 14,
              opacity: 0.7,
              marginBottom: 10,
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            Report this flow
          </p>
        </div>
        <div
          onClick={() => {

            WidgetStore.setShowReportModal(false);


          }}
          style={{ cursor: "pointer" }}
        >
          {/* <svg height="30" fill="white" viewBox="0 0 30 30" width="30">
          <g transform="translate(-270 -800)">
            <path d="m285 828c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0-25c6.617 0 12 5.383 12 12s-5.383 12-12 12-12-5.383-12-12 5.383-12 12-12z" />
            <path d="m280.404 820.303 4.596-4.596 4.596 4.596.708-.707-4.597-4.596 4.597-4.596-.708-.707-4.596 4.596-4.596-4.596-.708.707 4.597 4.596-4.597 4.596z" />
          </g>
        </svg> */}
          {/* <FontAwesomeIcon
            icon={faTimesCircle}
            color="#F06263"
            style={{
              display: "block",

              cursor: "pointer",
              fontSize: 35,
            }}
          /> */}
          <img
            src="/images/close-circle-report.svg"
            style={{ position: "absolute", right: 20, bottom: 17 }}
          />
        </div>
      </div>
      {confirm === false ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
            paddingBottom: 30,
          }}
        >
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              opacity: 0.7,
              marginTop: 10,
              marginBottom: 12,
            }}
          >
            Report title
          </p>
          <input
            className="formInputOutline"
            type="text"
            onChange={(e)=>{
              setReportTitle(e.target.value);
            }}
            style={{
              height: 40,
              width: "100%",
              marginBottom: 5,
              borderRadius: 10,
              paddingLeft: 10,
              backgroundColor: "#2D2A2B",
              border: "none",
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
              background: "#323232",
            }}
            placeholder={"Report title"}
            value={reportTitle}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              marginTop: 12,
            }}
          >
            Descriptions
          </p>

          <textarea
            className="formInputOutline"
            onChange={(e)=>{
              setReportDescription(e.target.value)
            }}
            style={{
              height: 170,
              width: "100%",
              borderRadius: 10,
              background: "#323232",
              marginTop: 15,
              marginBottom: 10,
              resize: "none",
              border: "none",
              padding: 10,
              fontSize: 14,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            value={reportDescription}
            placeholder="Please give as much detail as possible, such as how to reproduce your bug, what or device"
          ></textarea>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              opacity: 0.7,
            }}
          >
            Email (Optional)
          </p>

          <input
            className="formInputOutline"
            type="text"
            onChange={(e)=>{
                setReportEmail(e.target.value)
            }}
            style={{
              height: 33,
              width: "100%",
              marginBottom: 5,
              borderRadius: 10,
              paddingLeft: 10,
              background: "#323232",
              border: "none",
              marginTop: 15,
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder={"Email (Optional)"}
            value={reportEmail}
          />
          <p style={{color:'#FF5C5C', fontSize:12, fontFamily:'quicksand', textAlign:'center'}}>{reportErrorMessage}</p>
          <div
            onClick={() => {
              saveReport();
            }}
            style={{
              backgroundColor: "#4FD975",
              height: 35,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 15,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              Submit
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 400px)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
            maxHeight: 500,
  alignContent: 'center',
  justifyContent: 'center'

          }}
        >
          <p style={{ color: "white", fontFamily: "quicksand", fontSize: 30, textAlign:'center' }}>
            Thank you!
          </p>
          <p style={{ color: "white", fontFamily: "quicksand", fontSize: 20, textAlign:'center', marginBottom: 50,
  fontWeight: 100 }}>
            Your report has been submitted.
          </p>
        </div>
      )}

      <div></div>
    </ReactModal>
  );
});

export default React.memo(ReportModal);
