import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore";
import "./Switch.css";

function Switch({ defaultChecked = false, onToggle }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const EditorStore = useContext(EditorStoreContext);

  useEffect(() => {
    setIsChecked(EditorStore.flowIsMonetized);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [EditorStore.flowIsMonetized]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (onToggle) {
      onToggle(!isChecked);
    }
  };

  return (
    <div className="switch">
      <input
        type="checkbox"
        id="switchInput"
        checked={isChecked}
        onChange={handleToggle}
      />
      <label htmlFor="switchInput" className="slider"></label>
    </div>
  );
}

export default Switch;
