import { makeAutoObservable } from "mobx";
import { createContext } from "react";
class RemixableStore {
  constructor() {
    makeAutoObservable(this);
  }

  FR_REMIX_IMAGE_LIST = [];
  FR_REMIX_TEXT_LIST = [];
  FR_REMIX_BACKGROUND_COLOR = [];
  FR_REMIX_NLP_TEMPLATE_OPTIONS = [];
  FR_REMIX_AI_VOICE = [];
  FR_REMIX_IMAGE_LIST_NUMBER = [];
  remixThis = false;
  renderHTML = false;
  hasNLPTemplateOptions = false;
  reRenderFlow = false;
  changingImages = false;
  changingText = false;
  removingBackground = false;
  reRenderCalled = false;
  loadingFlow = false;
  hasRemixableVoiceAI = false;
  result = 0;
  openWidgetsMobile = false;
  wait = true;
  model = false;
  openWidgetsMobileDetails = false;

  clear() {
    this.FR_REMIX_IMAGE_LIST = [];
    this.FR_REMIX_TEXT_LIST = [];
    this.FR_REMIX_BACKGROUND_COLOR = [];
    this.FR_REMIX_NLP_TEMPLATE_OPTIONS = [];
    this.FR_REMIX_AI_VOICE = [];
    this.FR_REMIX_IMAGE_LIST_NUMBER = [];
    this.HasRemixableImages = false;
    this.HasRemixableText = false;
    this.HasRemixableColor = false;
    this.hasNLPTemplateOptions = false;
    this.remixThis = false;
    this.renderHTML = false;
    this.reRenderFlow = false;
    this.changingImages = false;
    this.changingText = false;
    this.removingBackground = false;
    this.reRenderedCalled = false;
    this.loadingFlow = false;
    this.hasRemixableVoiceAI = false;
    this.result = 0;
    this.openWidgetsMobile = false;
    this.openWidgetsMobileDetails = false;
    this.wait = true;
    this.useModel = false;
  }

  setFR_REMIX_IMAGE_LIST(FR_REMIX_IMAGE_LIST) {
    this.FR_REMIX_IMAGE_LIST = FR_REMIX_IMAGE_LIST;
  }

  setFR_REMIX_TEXT_LIST(FR_REMIX_TEXT_LIST) {
    this.FR_REMIX_TEXT_LIST = FR_REMIX_TEXT_LIST;
  }

  setFR_REMIX_BACKGROUND_COLOR(FR_REMIX_BACKGROUND_COLOR) {
    this.FR_REMIX_BACKGROUND_COLOR = FR_REMIX_BACKGROUND_COLOR;
  }

  setFR_REMIX_NLP_TEMPLATE_OPTIONS(FR_REMIX_NLP_TEMPLATE_OPTIONS) {
    this.FR_REMIX_NLP_TEMPLATE_OPTIONS = FR_REMIX_NLP_TEMPLATE_OPTIONS;
  }

  setHasRemixableImages(boolean) {
    this.HasRemixableImages = boolean;
  }

  setHasRemixableText(boolean) {
    this.HasRemixableText = boolean;
  }
  setHasRemixableColor(boolean) {
    this.HasRemixableColor = boolean;
  }
  setRemixThis(boolean) {
    this.remixThis = boolean;
  }
  setLoadingFlow(boolean) {
    this.loadingFlow = boolean;
  }
  setReRenderFlow(boolean) {
    this.reRenderFlow = boolean;
  }
  setRenderHTML(boolean) {
    this.renderHTML = boolean;
  }
  setHasNLPTemplateOptions(boolean) {
    this.hasNLPTemplateOptions = boolean;
  }
  setChangingImages(boolean) {
    this.changingImages = boolean;
  }
  setChangingText(boolean) {
    this.changingText = boolean;
  }
  setRemovingBackground(boolean) {
    this.removingBackground = boolean;
  }
  setReRenderedCalled(boolean) {
    this.reRenderedCalled = boolean;
  }
  setFR_REMIX_AI_VOICE(FR_REMIX_AI_VOICE) {
    this.FR_REMIX_AI_VOICE = FR_REMIX_AI_VOICE;
  }
  setHasRemixableVoiceAI(boolean) {
    this.hasRemixableVoiceAI = boolean;
  }
  setFR_REMIX_IMAGE_LIST_NUMBER(num) {
    this.FR_REMIX_IMAGE_LIST_NUMBER = num;
  }
  setResult(num) {
    this.result = num;
  }
  setOpenWidgetsMobile(bool) {
    this.openWidgetsMobile = bool;
  }

  setOpenWidgetsMobileDetails(bool) {
    this.openWidgetsMobileDetails = bool;
  }

  setWait(boolean) {
    this.wait = boolean;
  }
  setUseModel(boolean) {
    this.useModel = boolean;
  }
}

export const RemixableStoreContext = createContext(new RemixableStore());
