import { default as React, useContext, useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";
import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import SquareCheckboxWidget from "./SqaureCheckBoxWidget";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";
const CheckboxComponent = (props) => {
  const [checked, setChecked] = useState("");
  const WidgetStore = useContext(WidgetStoreContext);
  let widgets = WidgetStore.widgets;
 

  return (
    <div
      style={{
        display:"flex"
      }}
    >

      <div
        style={{
          height: 35,
          width: "100%",
          borderBottom: "1px solid #323232",
          marginBottom: 17,
        }}
      ></div>
      <p
        style={{
          color: "white",
          fontFamily: "quicksand",
          marginTop: 12,
          fontSize: 10,
          opacity: 0.7,
        }}
      >
        {props.info}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <SquareCheckboxWidget functionName={props.functionName}/>
      </div>


    </div>
  );
};

export default React.memo(CheckboxComponent);
