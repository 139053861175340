import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom'; // Assuming you're using react-router-dom for routing
import EmailConfirmation from './EmailVerification'; // Import your EmailConfirmation component
import PasswordConfirmation from './PasswordReset'; // Import your PasswordConfirmation component

const Auth = () => {
  const location = useLocation(); // Hook to access the current location
  const [componentToRender, setComponentToRender] = useState(null);
  
  useEffect(() => {
    // Function to parse the query string
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');
    const apiKey = queryParams.get('apiKey');
    
    // Check if both mode and oobCode exist
    if (mode && oobCode) {
      switch(mode) {
        case 'verifyEmail':
          setComponentToRender(<EmailConfirmation actionCode={oobCode} apiKey={apiKey} />);
          break;
        case 'resetPassword': // Adjust the mode value as per your requirement
          setComponentToRender(<PasswordConfirmation oobCode={oobCode} apiKey={apiKey} />);
          break;
        default:
          // Redirect to not-found for any other mode or missing parameters
          setComponentToRender(<Navigate to="/not-found" />);
          break;
      }
    } else {
      // Redirect to not-found if either mode or oobCode is missing
      setComponentToRender(<Navigate to="/not-found" />);
    }
  }, [location]);
  
  return (
    <div style={{ flex: "1", display: "flex" }}>
      {componentToRender}
    </div>
  );
};

export default React.memo(Auth);
