import React, { useEffect, useState } from "react";

const SquareCheckboxWidget = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   if (defaultValue) {
  //     setIsChecked(true);
  //   } else {
  //     setIsChecked(false);
  //   }
  //   return () => {
  //     // Clean up or cancel any ongoing tasks or subscriptions here
  //   };
  // }, [defaultValue]); // Added defaultValue as a dependency

  const toggleCheck = () => {
    let iframe = document.getElementById('undefined_output_frame');
    
    const updatedCheckedStatus = !isChecked;
    setIsChecked(updatedCheckedStatus);
    //if (onToggle) {
      // ensure the onToggle prop was provided
      iframe.contentWindow[props.functionName](isChecked);
     // onToggle(updatedCheckedStatus);
   // }
  };

  return (
    <img
      style={{
        display: "block",
        cursor: "pointer",
        zIndex: 1000,
      }}
      onClick={toggleCheck}
      src={
        isChecked
          ? "../images/square-checked.svg"
          : "../images/square-unchecked.svg"
      }
      alt={isChecked ? "Checked" : "Unchecked"}
    />
  );
};

export default SquareCheckboxWidget;
