import { createContext } from "react";
class RemixMenuDesktopStore {
  remixList = [];
  setShowMenuComponents = "remix-menu";
  deleteModal = false;
  setRemixList(list) {
    this.remixList.push(list);
  }
  setShowMenuComponents(component) {
    this.setShowMenuComponents = component;
  }
  setDeleteModal(boolean) {
    this.deleteModal = boolean;
  }
}

export const RemixMenuDesktopStoreContext = createContext(
  new RemixMenuDesktopStore()
);
