import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class FlowStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading = false;
  isLoaded = false;
  flowId = "";
  room = {};
  RemixableStore = [];
  isFlowOwner = false;
  modelId = "";
  source = "";
  previousSource = "";
  username = "";
  credit = 0;
  renderIframe = false;
  loadCollaborators = false;
  currentlySelectedFlowInFeed = "";
  currentlySelectedFlowInFeedIndex = "";
  currentlySelectedFlowInFeedTokenAmount = 0;
  flowIdOP = "none";
  currentlySelectedOptionInFeed = "";
  jumpTo = false;
  shareWithOwnCredits = false;
  numberOfTimes = 1;
  isRemixSelected = false;
  currentRoute = "";
  isSaving = false;

  setIsLoading(bool) {
    this.isLoading = bool;
  }

  setIsLoaded(bool) {
    this.isLoaded = bool;
  }

  setFlowId(flowId) {
    this.flowId = flowId;
  }

  setRoom(room) {
    this.room = room;
  }

  setRemixableStore(remix) {
    this.RemixableStore = remix;
  }

  setIsFlowOwner(isFlowOwner) {
    this.isFlowOwner = isFlowOwner;
  }

  setModelId(modelId) {
    this.modelId = modelId;
  }
  setSource(source) {
    this.source = source;
  }
  setPreviousSource(source) {
    this.source = source;
  }
  setUserName(user) {
    this.username = user;
  }
  setCredits(param) {
    this.credit = param;
  }
  setRenderIframe(bool) {
    this.renderIframe = bool;
  }
  setLoadCollaborators(bool) {
    this.loadCollaborators = bool;
  }
  setCurrentlySelectedFlowInFeed(flow) {
    this.currentlySelectedFlowInFeed = flow;
  }
  setCurrentlySelectedFlowInFeedIndex(flow) {
    this.currentlySelectedFlowInFeedIndex = flow;
  }
  setCurrentlySelectedFlowInFeedTokenAmount(flow) {
    this.currentlySelectedFlowInFeedTokenAmount = flow;
  }
  setFlowIdOP(flow) {
    this.flowIdOP = flow;
  }
  setCurrentlySelectedOptionInFeed(option) {
    this.currentlySelectedOptionInFeed = option;
  }
  setJumpTo(bool) {
    this.jumpTo = bool;
  }
  setShareWithOwnCredits(bool) {
    this.shareWithOwnCredits = bool;
  }
  setNumberOfTimes(num) {
    this.numberOfTimes = num;
  }
  setIsRemixSelected(bool) {
    this.isRemixSelected = bool;
  }
  setCurrentRoute(route) {
    this.currentRoute = route;
  }
  setIsSaving(bool) {
    this.isSaving = bool;
  }
}

export const FlowStoreContext = createContext(new FlowStore());
