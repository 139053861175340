import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { firebase } from "../firebase/firebase.js";
import EventModal from './EventModal'; // Ensure this import points to where your EventModal component is saved
import { useParams } from 'react-router-dom';
import { AuthContext } from "../Home/AuthContext.js";


const db = firebase.firestore();

const localizer = momentLocalizer(moment);

const ScheduleCalendar = ({ userId }) => {
    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [start, setStart] = useState(new Date());
const [end, setEnd] = useState(new Date());

    const { id } = useParams();  // This is the user ID for whom the event is scheduled
    const { currentUser } = useContext(AuthContext);
    // const userId = currentUser.displayName; // Assuming displayName uniquely identifies a user
    const addLikeNotification = async (
        sender,
        receiver,
        commentContent,
        photoURL,
        flowId
      ) => {
        const newNotification = {
          sender: currentUser.displayName,
          receiver: id,
          type: "promoRequest",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          additionalData: {
            commentContent: commentContent,
            photoURL: photoURL,
          },
          isSeen:false,
          flowId:flowId
        };
    
        await firebase.firestore().collection("notifications").add(newNotification);
      };



    useEffect(() => {
        const unsubscribe = db.collection('events').onSnapshot(snapshot => {
            const eventsFromDb = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate()
            }));
            setEvents(eventsFromDb);
        });
    
        return () => unsubscribe();
    }, []); // This should be enough to keep your calendar updated
    
    
    useEffect(() => {
        fetchEvents();
    }, [userId]);






    const fetchEvents = async () => {
        const eventData = await db.collection('events').get();
        setEvents(eventData.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate()
        })));
    };
    ;
    

    const handleSelectSlot = ({ start, end }) => {
        setSelectedEvent({ start, end });
        setModalOpen(true);
    };

    const handleTouchEnd = (event) => {
        // Custom logic to determine if this should trigger selection
        if (event.touches.length === 1) { // Simple example
            const slotInfo = { start: event.touches[0].start, end: event.touches[0].end };
            handleSelectSlot(slotInfo);
        }
    };
    

    const onSelectEvent = (event) => {
        setSelectedEvent(event);
        setModalOpen(true);
    };

    const saveEvent = async ({ title, start, end, id, flowId }) => {
        console.log("Attempting to save event:", { title, start, end, id });
        const eventData = {
            title,
            start: firebase.firestore.Timestamp.fromDate(start),
            end: firebase.firestore.Timestamp.fromDate(end),
            createdBy: currentUser.uid,
            flowId:flowId
        };
    
        if (!id || await isSlotAvailable(start, end, id)) {
            const eventRef = id ? db.collection('events').doc(id) : db.collection('events').doc();
            await eventRef.set(eventData);
            console.log("Event saved successfully:", eventData);

            addLikeNotification( 
                currentUser.displayName,
                id,
                eventData,
                currentUser.photoURL, flowId);
            setModalOpen(false);
        } else {
            console.error('Conflict detected, slot not available:', {start, end});
            alert('This slot is not available due to a conflict.');
        }
    };
    
    
    
    

    const deleteEvent = async (id) => {
        await db.collection('events').doc(id).delete();
        fetchEvents();
        setModalOpen(false);
    };

    const isSlotAvailable = async (start, end, eventId) => {
        const allEvents = await db.collection('events').get();
        return !allEvents.docs.some(doc => {
            const event = doc.data();
            return doc.id !== eventId &&
                   new Date(start).getTime() < event.end.toDate().getTime() &&
                   new Date(end).getTime() > event.start.toDate().getTime();
        });
    };
    
    return (
        <div>
       <Calendar
    onSelectEvent={onSelectEvent}
    onSelectSlot={handleSelectSlot}
    onTouchEnd={handleTouchEnd}
    localizer={localizer}
    events={events}
    startAccessor="start"
    endAccessor="end"
    style={{ height: 500, paddingLeft: 15, paddingRight: 15 }}
    selectable
/>
        {modalOpen && <EventModal
            isOpen={modalOpen}
            onClose={() => {
                setModalOpen(false);
                setSelectedEvent(null);
            }}
            onSave={saveEvent}
            onDelete={deleteEvent}
            event={selectedEvent}
            currentUser={currentUser}  // Pass the current logged-in user
        />}
    </div>
    );
};

export default ScheduleCalendar;
