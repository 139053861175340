import { makeAutoObservable, reaction } from "mobx";
import { createContainer } from "unstated-next";

const Panes = {
  RemixableStore: "RemixableStore",
  UploadItems: "uploaditems",
  EditItems: "edititems",
  ViewItems: "viewitems",
} as const;

type Pane = typeof Panes[keyof typeof Panes];

class RemixMenuUI {
  isRemixMenuOpen = false;

  currentlyDisplayedPane: Pane = "RemixableStore";

  droppedFiles = false;

  constructor() {
    makeAutoObservable(this);
    this.currentlyDisplayedPane = "RemixableStore";
    this.logCurrentPane();
  }

  updateCurrentPane(opts: { currentlyDisplayedPane: Pane }) {
    this.currentlyDisplayedPane = opts.currentlyDisplayedPane;
  }

  updateDroppedFiles(opts: { droppedFiles: boolean }) {
    this.droppedFiles = opts.droppedFiles;
  }

  private logCurrentPane() {
    reaction(
      () => this.currentlyDisplayedPane,
      () =>
        console.log("CURRENTLY DISPLAYED PANE: ", this.currentlyDisplayedPane)
    );
  }
}

export let RemixMenu = createContainer(() => new RemixMenuUI());
