import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore";
import Exclamation from "../Home/Icons/Exclamation.js";

const UnlockRemixWidgetModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [showUnlockMessage, setShowUnlockMessage] = useState(false);
  useEffect(() => {
    setShowUnlockMessage(props.showUnlockMessage);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.showUnlockMessage]);
  return (
    <ReactModal
      isOpen={showUnlockMessage}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "100%",
          maxWidth: "530px",

          padding: 20,
        },
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <p
              style={{
                color: "white",
                fontFamily: "quicksand",
                fontSize: 20,
                marginBottom: 20,
                marginRight: 20,
              }}
            >
              Are you sure you want to reveal this widget?
            </p>
          </div>
          <div
            onClick={() => {
              props.closeUnlockModal();
            }}
          >
            {/* <svg height="30" fill="white" viewBox="0 0 30 30" width="30">
          <g transform="translate(-270 -800)">
            <path d="m285 828c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0-25c6.617 0 12 5.383 12 12s-5.383 12-12 12-12-5.383-12-12 5.383-12 12-12z" />
            <path d="m280.404 820.303 4.596-4.596 4.596 4.596.708-.707-4.597-4.596 4.597-4.596-.708-.707-4.596 4.596-4.596-4.596-.708.707 4.597 4.596-4.597 4.596z" />
          </g>
        </svg> */}
            <FontAwesomeIcon
              icon={faTimesCircle}
              color="#F06263"
              style={{
                display: "block",

                cursor: "pointer",
                fontSize: 35,
              }}
            />
          </div>
        </div>

        <Exclamation />
        <p
          style={{
            color: "white",
            fontFamily: "quicksand",
            width: "100%",
            maxWidth: 429,
            margin: "auto",
            fontSize: 15,
            textAlign: "center",
          }}
        >
          If you are not the original creator of this flow, you may be changing
          something the original creator wanted to be changed with caution. Be
          careful if you are not an advanced user such as a dev!
        </p>
        <div
          onClick={() => {
            props.unlock();
          }}
          style={{
            height: 30,
            width: "100%",
            // maxWidth: 400,
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: 20,
            marginBottom: 10,
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Yes</p>
        </div>
        <div
          onClick={() => {
            // WidgetStore.setShowWidgetMessage(false);
            props.closeUnlockModal();
          }}
          style={{
            height: 30,
            width: "100%",
            // maxWidth: 400,
            backgroundColor: "rgb(79, 217, 117)",
            borderRadius: 20,
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#222", fontFamily: "quicksand" }}>Cancel</p>
        </div>
      </div>
    </ReactModal>
  );
});

export default UnlockRemixWidgetModal;
