import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState } from "react";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { CreditStoreContext } from "../../store/CreditStore.js";
import { DrawerStoreContext } from "../../store/DrawerStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../store/RemixableStore.js";
import { RemixMenuDesktopStoreContext } from "../../store/Remix_Menu_Desktop.js";
import { RouteStoreContext } from "../../store/RouteStore.js";
import ControlWidget from "./ControlWidget.js";
import PublishMenu from "./publish_menu";
const Menu = observer((props) => {
  const EditorStore = useContext(EditorStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const RemixableStore = useContext(RemixableStoreContext);
  const [isRemixSelected, setIsRemixSelected] = useState(true);
  const [isCodeSelected, setIsCodeSelected] = useState(false);
  const [isPostSelected, setIsPostSelected] = useState(false);
  const [isShareSelected, setIsShareSelected] = useState(false);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const RemixMenuDesktop = useContext(RemixMenuDesktopStoreContext);
  const [enabled, setEnabled] = useState(true);
  const [showMenuComponents, setShowMenuComponents] = useState("remix-menu");
  const [ImageI, setImageI] = useState({});
  const [url, setUrl] = useState("");
  const [imgName, setimgName] = useState("");
  const [selectedTab, setSelectedTab] = useState(false);
  const [AddFlowSelected, setAddFlowSelected] = useState(false);
  const [hasImageRemixables, setHasImageRemixables] = useState(false);
  const [hasTextRemixables, setHasTextRemixables] = useState(false);
  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [isAddFlowSelected, setIsAddFlowSelected] = useState(false);
  const CreditStore = useContext(CreditStoreContext);
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [textArea, setTextArea] = useState("");
  const [remixDisabled, setRemixDisabled] = useState(true);
  const RouteStore = useContext(RouteStoreContext);
  const DrawerStore = useContext(DrawerStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [currentTextForCloudinary, setCurrentTextForCloudinary] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isMicOn, setIsMicOn] = useState(false);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const [widgetMenu, setWidgetMenu] = useState(true);
  const [publishMenu, setPublishMenu] = useState(false);

  useEffect(() => {
    //setWidgetMenu(true);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  if (widgetMenu === true) {
    return <ControlWidget />;
  } else if (publishMenu === true) {
    return <PublishMenu />;
  }
});

export default Menu;
