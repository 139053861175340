import { CardHeader, List } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { CommentsStoreContext } from "../../store/CommentsStore.js";
import CommentCard from "./NewCommentCard.tsx";
import NewCommentForm from "./NewCommentForm.tsx";

import { AuthContext } from "./AuthContext";
import { firebase } from "../firebase/firebase"; // Ensure firebase is correctly imported

const Comment = ({
  comments,
  flowId,
  myUserId,
  profilePic
}: {
  comments: any[];
  flowId: string;
  myUserId: string;
  profilePic: string;
}) => {
  // const classes = useStyles();

  const messagesEndRef = useRef<any>(null);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const CommentsStore = useContext(CommentsStoreContext);
  const { currentUser } = useContext(AuthContext);

  // State to hold mapping from username to urlPhoto
  const [userPhotos, setUserPhotos] = useState<{ [key: string]: string }>({});

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Optionally scroll to bottom when component mounts or updates
    // scrollToBottom();
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  // Function to fetch user photo from "users" collection
  const fetchUserPhoto = async (username: string): Promise<string> => {
    if (!username) return "/images/Flowroom_Default_Avatar.png";
    try {
      const userSnapshot = await firebase.firestore()
        .collection("users")
        .where("username", "==", username)
        .limit(1)
        .get();

      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        return userData.urlPhoto || "/images/Flowroom_Default_Avatar.png";
      } else {
        return "/images/Flowroom_Default_Avatar.png"; // Fallback image
      }
    } catch (error) {
      console.error(`Error fetching user photo for username "${username}":`, error);
      return "/images/Flowroom_Default_Avatar.png"; // Fallback image on error
    }
  };

  // useEffect to fetch all unique user photos when comments change
  useEffect(() => {
    const fetchAllUserPhotos = async () => {
      // Extract unique usernames from comments
      const uniqueUsernames = Array.from(new Set(comments.map(comment => comment.username)));

      // Filter out usernames that are already fetched
      const usernamesToFetch = uniqueUsernames.filter(username => !(username in userPhotos));

      if (usernamesToFetch.length === 0) return; // No new usernames to fetch

      try {
        // Fetch photos in parallel
        const photoPromises = usernamesToFetch.map(username => fetchUserPhoto(username));
        const photos = await Promise.all(photoPromises);

        // Create a mapping from username to urlPhoto
        const newUserPhotos: { [key: string]: string } = {};
        usernamesToFetch.forEach((username, index) => {
          newUserPhotos[username] = photos[index];
        });

        // Update the userPhotos state
        setUserPhotos(prev => ({ ...prev, ...newUserPhotos }));
      } catch (error) {
        console.error("Error fetching user photos:", error);
        // Optionally handle error, e.g., set fallback images
      }
    };

    if (comments.length > 0) {
      fetchAllUserPhotos();
    }
  }, [comments, userPhotos]);

  return (
    <>
      <div
        style={{
          height: "300px",
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: '0px solid #3E3E3E'
        }}
      >
        <NewCommentForm scrollToBottom={scrollToBottom} flowId={flowId} />
      </div>
      <List
        // id="commentsScrollView"
        // className={`${classes.root} hidden-scrollbars`}
        key={flowId + "_list_" + Math.random()}
      >
        {comments.length > 0 ? (
          comments.map((comment) => {
            console.log("comment card", comment);
            return (
              <CommentCard
                key={comment.commentId}
                comment={comment.comment}
                commentId={comment.commentId}
                date={comment.date}
                flowId={comment.flowId}
                myUserId={myUserId}
                photoURL={userPhotos[comment.username] || "/images/Flowroom_Default_Avatar.png"} // Use fetched photo or fallback
                isSelf={
                  comment.userId && comment.userId === currentUser.uid ? true : false
                }
                username={comment.username}
                userId={comment.userId}
                likesCount={comment.likesCount}
              />
            );
          })
        ) : (
          <CardHeader
            // className={classes.header}
            avatar={
              <div
                style={{
                  display: "flex",
                  backgroundImage: "url(./images/Flowroom_Default_Avatar.png)",
                  height: "55px",
                  width: "55px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                }}
              ></div>
            }
            title="No Comments"
            subheader="Be the first to comment"
          />
        )}
        <div ref={messagesEndRef} />
      </List>
    </>
  );
};

export default Comment;
