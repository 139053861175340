// src/Components/Home/GrantDevAccess.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const GrantDevAccess = () => {
  const db = firebase.firestore();
  const [devUsername, setDevUsername] = useState('');
  const [devMessage, setDevMessage] = useState('');
  const [inviteRequests, setInviteRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState({}); // To track loading state for each invite request

  useEffect(() => {
    // Fetch inviteCodeRequests in real-time
    const unsubscribe = db.collection('inviteCodeRequests').orderBy('createdAt', 'desc').onSnapshot(
      (snapshot) => {
        const requests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInviteRequests(requests);
      },
      (error) => {
        console.error('Error fetching invite code requests:', error);
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [db]);

  const handleGrantDevAccess = async () => {
    setDevMessage('');
    
    if (!devUsername.trim()) {
      setDevMessage('Please provide a username.');
      return;
    }

    setLoading(true);

    try {
      // Fetch user by username
      const userSnapshot = await db
        .collection('users')
        .where('username', '==', devUsername.trim())
        .limit(1)
        .get();

      if (userSnapshot.empty) {
        setDevMessage('User not found.');
        return;
      }

      const userDoc = userSnapshot.docs[0];
      const userId = userDoc.data().userId;

      // Check if developer access already exists
      const devSnapshot = await db
        .collection('devAccess')
        .where('userId', '==', userId)
        .limit(1)
        .get();

      if (!devSnapshot.empty) {
        setDevMessage('This user already has developer access.');
        return;
      }

      // Grant developer access
      await db.collection('devAccess').add({
        userId: userId,
        username: devUsername.trim(),
        grantedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setDevMessage(`Developer access granted to ${devUsername.trim()}.`);
      setDevUsername('');
    } catch (err) {
      console.error('Error granting developer access:', err);
      setDevMessage('Failed to grant developer access. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handler to accept an invite
  const handleAcceptInvite = async (inviteId, username) => {
    setActionLoading((prev) => ({ ...prev, [inviteId]: true }));
    try {
      await db.collection('inviteCodeRequests').doc(inviteId).update({
        inviteAccepted: true,
      });

      const addInviteCodeNotification = async (sender, receiver, message, flowId) => {
        const newNotification = {
          sender,
          receiver,
          type: "flowroom",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          additionalData: { message, flowId },
        };
        await firebase.firestore().collection("notifications").add(newNotification);
      };
      addInviteCodeNotification("flowroom", username, "You have been granted developer access. You can now access the code editor and flowroom guide.", "noid")


      // Optionally, you can add a success message or notification here
    } catch (error) {
      console.error('Error accepting invite:', error);
      alert('Failed to accept the invite. Please try again.');
    } finally {
      setActionLoading((prev) => ({ ...prev, [inviteId]: false }));
    }
  };

  // Handler to remove an invite
  const handleRemoveInvite = async (inviteId) => {
    const confirmDelete = window.confirm('Are you sure you want to remove this invite request?');
    if (!confirmDelete) return;

    setActionLoading((prev) => ({ ...prev, [inviteId]: true }));
    try {
      await db.collection('inviteCodeRequests').doc(inviteId).delete();
      // Optionally, you can add a success message or notification here
    } catch (error) {
      console.error('Error removing invite:', error);
      alert('Failed to remove the invite. Please try again.');
    } finally {
      setActionLoading((prev) => ({ ...prev, [inviteId]: false }));
    }
  };

  return (
    <section style={styles.section}>
      <h2>Grant Developer Access</h2>
      <div style={styles.inputGroup}>
        <input
          type="text"
          placeholder="Username"
          value={devUsername}
          onChange={(e) => setDevUsername(e.target.value)}
          style={styles.input}
        />
        <button onClick={handleGrantDevAccess} style={styles.button} disabled={loading}>
          {loading ? 'Granting...' : 'Grant Access'}
        </button>
      </div>
      {devMessage && <p style={styles.message}>{devMessage}</p>}

      <hr style={styles.divider} />

      <h2>Invite Code Requests</h2>
      {inviteRequests.length === 0 ? (
        <p>No invite code requests found.</p>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Username</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Invite Info</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {inviteRequests.map((invite) => (
              <tr key={invite.id} style={styles.tr}>
                <td style={styles.td}>{invite.username}</td>
                <td style={styles.td}>{invite.inviteEmail}</td>
                <td style={styles.td}>{invite.inviteInfo}</td>
                <td style={styles.td}>
                  {invite.inviteAccepted ? (
                    <span style={styles.accepted}>Accepted</span>
                  ) : (
                    <span style={styles.pending}>Pending</span>
                  )}
                </td>
                <td style={styles.td}>
                  {!invite.inviteAccepted ? (
                    <button
                      onClick={() => handleAcceptInvite(invite.id, invite.username)}
                      style={styles.acceptButton}
                      disabled={actionLoading[invite.id]}
                    >
                      {actionLoading[invite.id] ? 'Accepting...' : 'Accept'}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleRemoveInvite(invite.id)}
                      style={styles.removeButton}
                      disabled={actionLoading[invite.id]}
                    >
                      {actionLoading[invite.id] ? 'Removing...' : 'Remove'}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    flex: '1 1 200px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    flex: '0 0 auto',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  divider: {
    margin: '20px 0',
    borderTop: '1px solid #ccc',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  },
  th: {
    border: '1px solid #ddd',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    textAlign: 'left',
  },
  tr: {
    borderBottom: '1px solid #ddd',
  },
  td: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
  },
  accepted: {
    color: 'green',
    fontWeight: 'bold',
  },
  pending: {
    color: 'orange',
    fontWeight: 'bold',
  },
  acceptButton: {
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28a745',
    color: '#fff',
    cursor: 'pointer',
  },
  removeButton: {
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#dc3545',
    color: '#fff',
    cursor: 'pointer',
  },
};

export default GrantDevAccess;
