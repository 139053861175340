import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js"
import { faTimesCircle, faBookmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorModal from "./ErrorModal.js";
import SquareCheckbox from "./SquareCheckBox.js";
// store
import Hashids from "hashids";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuid } from "uuid";

import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext.js";
let currentUser = firebase.auth().currentUser;
let firestore = firebase.firestore();
let blockedUsersArr = [];
const UseLaterModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);

  const [confirm, setConfirm] = useState(false);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [openBlockError, setOpenBlockError] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [showDetails, setShowDetails] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [pending, setPending] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(false);

  const [lastClickTime, setLastClickTime] = useState(false);
  const [checked, setChecked] = useState(false);


  useEffect(() => {
    const showModal = localStorage.getItem('flowroom_showModal')
    setOpenClose(showModal);
  }, []);


  const handleClose = () => {
    const showModal = localStorage.getItem('flowroom_showModal')

    setOpenClose(showModal);
    props.closeModal();
    if (selectedCheckbox) {
      localStorage.setItem('flow_room_showModal', false);
    }
  };

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted
  
   
  
    // Cleanup function
    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []); // Dependency array is empty, so this effect runs only once on mount
  

  const handleCheckboxToggle = (checkboxName) => {
    console.log("Checkbox clicked:", checkboxName);
    setSelectedCheckbox(checkboxName);
    PublishMenuStore.setVisibility(checkboxName);
    if (selectedCheckbox) {
      localStorage.setItem('flow_room_showModal', false);
    } else {
      localStorage.setItem('flow_room_showModal', true);

    }
  };

  const blockUser = async () => {
    if(currentUser == null) {
      return;
    }
    // addLikeNotification(
    //   currentUser.displayName,
    //   room.username,
    //   "",
    //   currentUser.photoURL
    // );
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 1000) {
      console.log("Too many likes. Try again later.");
      setTryAgain(true);
      return;
    }
    if (currentUser == null || currentUser.displayName == null) {
      AuthenticationStore.setShowModal(true);
      return;
    }
    setLastClickTime(currentTime);

    setPending(true);
    let docLike = await firebase
      .firestore()
      .collection("blocked")
      .doc(AuthenticationStore.userId + ":" + EditorStore.userId)
      .get();

    if (docLike.exists) {
      setIsBlocked(false);
      setBlocked(blocked - 1);

      await firebase
        .firestore()
        .collection("blocked")
        .doc(AuthenticationStore.userId + ":" + EditorStore.userId)
        .delete();
        WidgetStore.setBlockModal(false);
        setOpenClose(false)

      // await firebase
      //   .firestore()
      //   .collection("flows")
      //   .doc(room.flowId)
      //   .set(
      //     { likes: firebase.firestore.FieldValue.increment(-1) },
      //     { merge: true }
      //   );

      // await firebase
      //   .firestore()
      //   .collection("users")
      //   .doc(room.userId)
      //   .set(
      //     { likes: firebase.firestore.FieldValue.increment(-1) },
      //     { merge: true }
      //   );

      await setPending(false);
    } else {
      setIsBlocked(true);
      setBlocked(blocked + 1);

             firebase
            .firestore()
            .collection("blocked")
            .doc(AuthenticationStore.userId + ":" + EditorStore.userId)
            .set(
              {
                blocked: AuthenticationStore.userId + ":" + EditorStore.userId,
                timestamp: new Date().toISOString(),
              },
              { merge: true }
            );
            WidgetStore.setBlockModal(false);
            setOpenClose(false)

      // firebase
      //   .firestore()
      //   .collection("flows")
      //   .doc(room.flowId)
      //   .set(
      //     { likes: firebase.firestore.FieldValue.increment(1) },
      //     { merge: true }
      //   )
      //   .then(() => setPending(false));
      // firebase
      //   .firestore()
      //   .collection("notifications")
      //   // .doc(myId)
      //   .add({
      //     type: "liked",
      //     text: "",
      //     myUserId: currentUser.uid,
      //     otherUserId: "",
      //     otherUserName: room.username,
      //     seen: false,
      //     date: new Date().toISOString(),
      //     photoURL: room.photoURL,
      //     flowId: room.flowId,
      //     created: new Date().toISOString(),
      //     timestamp: firebase.firestore.Timestamp.fromDate(new Date()).toDate(),
      //   })
      //   .then(() => {
      //     firebase
      //       .firestore()
      //       .collection("likes")
      //       .doc(currentUser.uid + ":" + room.flowId)
      //       .set(
      //         {
      //           flowId: room.flowId,
      //           likeId: currentUser.uid + ":" + room.flowId,
      //           userId: currentUser.uid,
      //           timestamp: new Date().toISOString(),
      //         },
      //         { merge: true }
      //       );

      //     // firebase
      //     //   .firestore()
      //     //   .collection("users")
      //     //   .doc(props.userId)
      //     //   .set(
      //     //     { likes: firebase.firestore.FieldValue.increment(1) },
      //     //     { merge: true }
      //     //   );

      //     window.analytics.track("Flow Liked", {
      //       flowId: room.flowId,
      //       title: room.title,
      //       userId: room.userId,
      //       username: room.username,
      //       actionUserId: currentUser ? currentUser.uid : null,
      //       actionUsername: currentUser ? currentUser.displayName : null,
      //     });
      //   });

      // console.log("[RoomPost] incrementLikes props:", props);
    }
  };


  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  const getBlockedUsers = async () => {
    try{
    console.log('currentUser B', currentUser)

    if (myCurrentUserName === undefined) {
      return;
    }
    let myCurrentUserName = JSON.parse(
      localStorage.getItem("flowroom-user")
    ).displayName;
    setBlockedUsers([]);

    let snapshot = await firebase
      .firestore()
      .collection("blocked")
      .where("user", "==", myCurrentUserName)
      .limit(10)
      .get();
    snapshot.forEach((doc) => {
      let data = doc.data();
      let blockedUserObj = {
        blocked: data.blocked,
        user: data.user,
        profilePic: data.profilePic,
      };
      blockedUsersArr.push(blockedUserObj);
    });
    setBlockedUsers(blockedUsersArr);
  } catch(e) {

  }
  };

  useEffect(() => {
    blockedUsersArr = [];
    setBlockedUsers([]);
    getBlockedUsers();
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);
  useEffect(() => {
    // alert(isMobile);
    setOpenClose(props.openModal);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.openModal]);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={openClose}
      closeTimeoutMS={200}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.5)",
          opacity: 1,
          position:'fixed'
        },
        content: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          height: "370px",
          maxWidth: "300px",
        },
      }}
    >
           <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        {/* <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        /> */}

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
            textAlign:'center',
            width:'100%'
          }}
        >
          Bookmark
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
      
      </div>
   
      <div style={{borderTop: '1px solid rgb(62, 62, 62)'}}></div>

      {confirm === false ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 0px",
          }}
        >
          <div style={{display:'flex', justifyContent:'center', alignItems:'center',paddingLeft:20, paddingRight:20, flexDirection:'column'}}>
          <img
            onClick={handleClose}
            src="/images/close-circle-report.svg"
            style={{ height: 20, width: 20, margin: "auto", position:'absolute', right:15, top:15, cursor:'pointer' }}
          />
             <FontAwesomeIcon
          icon={faBookmark}
          color="rgb(79, 217, 117)"
          size={"5x"}
          style={{
            display: "block",
            position: "relative",
            cursor: "pointer",
            marginTop:20,
            marginBottom:30
          }}
        />
          {/* <img
            src="../images/reshare-icon.svg"
            style={{ height: 70, width: 70, margin: "auto", marginBottom: 10, marginTop: 33 }}
          /> */}
          {/* <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom:20
            }}
          >
            Bookmark
          </p> */}
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 14,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom:20
            }}
          >This flow is now saved in your profile under Saves,' where you can remove it, and it's only visible to you          </p>
          <div style={{display:'flex', marginBottom:10, width:'100%'}}>
            <SquareCheckbox onToggle={()=> {
              if(checked == false) {
                setChecked(true);
                handleCheckboxToggle(true);
              } else {
                setChecked(false);
                handleCheckboxToggle(false);

              }
            }} isChecked={checked}/>
            <p style={{fontFamily:'quicksand', color:'white', fontSize:12}}>Don't show this again.</p>
          </div>  
          <div
            onClick={handleClose}
            style={{
              backgroundColor: "#4FD975",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 15,
              cursor: "pointer",
              maxWidth: 526,
              height: 35,
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Ok
            </p>
          </div>





          </div>

        </div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 25px",
          }}
        >
          <img
            src="/images/block-icon.svg"
            style={{ height: 100, width: 100, margin: "auto", marginBottom: 10, marginTop: 35 }}
          />
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            User block
          </p>
          <p
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
            }}
          >
            This user is now blocked and will not see any posts you make in any
            feed and also will not be able to see your profile.
          </p>
          <div
            onClick={handleClose}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 50,
              cursor: "pointer",
              maxWidth: 526,
              height: 50,
              border: "1px solid #4FD975",
            }}
          >
            <p
              style={{
                color: "rgb(79, 217, 117)",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Ok
            </p>
          </div>
        </div>
      )}

      <ErrorModal
        openModalError={openBlockError}
        closeError={() => setOpenBlockError(false)}
      />
    </ReactModal>
  );
});

export default React.memo(UseLaterModal);
