import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import CalendarComponent from "../Home/CalendarComponent.js"
import ScheduleCalendar from "../Home/ScheduleCalendar.js"
const PromoAgreement = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    // alert(isMobile);
    setOpenClose(props.openPromoAgreement);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.openPromoAgreement]);
  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={openClose}
      closeTimeoutMS={200}
      // className={{
      //   base: "remix-base",
      //   beforeClose: "remix-before",
      //   afterOpen: "remix-open",
      // }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "450px",
        },
      }}
    >
  <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Promo Request
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            props.closePromoAgreement()    
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div style={{borderTop: '1px solid rgb(62, 62, 62)'}}></div>
      <div style={{height:'100%', minHeight:500, width:'100%', maxWidth:500}}>
        <ScheduleCalendar/>
      </div>
    </ReactModal>
  );
});

export default React.memo(PromoAgreement);
