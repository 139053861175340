import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import SquareCheckbox from "./SquareCheckBox.js";
import Switch from "./Switch";
const firestore = firebase.firestore();

const CollaboratorList = observer(({ flowId, userId }) => {
  const [collaborators, setCollaborators] = useState([]);
  const [collaborator, setCollaborator] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [yourRole, setYourRole] = useState("");
  const [yourRoleError, setYourRoleError] = useState("");
  const [collaboratorRoleError, setCollaboratorRoleError] = useState("");
  const [collaboratorRole, setCollaboratorRole] = useState("");

  const FlowStore = useContext(FlowStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);

  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false); // New state for accordion
  const [selectedCheckbox, setSelectedCheckbox] = useState(false);
  const [price, setPrice] = useState(0);
  const [flowFreeTrial, setFlowFreeTrial] = useState(false);
  const [flowPerMonth, setFlowPerMonth] = useState(false);
  const [flowPerYear, setFlowPerYear] = useState(false);
  const [flowThumbnail, setFlowThumbnail] = useState(false);
  const [flowPreview, setFlowPreview] = useState(false);
  const [paidRemix, setPaidRemix] = useState(false);
  const [flowDays, setFlowDays] = useState(0);
  const [flowNumberOfUses, setFlowNumberOfUses] = useState(0);
  const [flowPreviewId, setFlowPreviewId] = useState("");

  useEffect(() => {
    setPrice(EditorStore.price);
    setFlowFreeTrial(EditorStore.flowFreeTrial);
    setFlowPerMonth(EditorStore.flowPerMonth);
    setFlowPerYear(EditorStore.flowPerYear);
    setFlowThumbnail(EditorStore.flowThumbnail);
    setFlowPreview(EditorStore.flowPreview);
    setPaidRemix(EditorStore.paidRemix);
    setPrice(EditorStore.flowPrice);
    setFlowDays(EditorStore.flowDays);
    setFlowNumberOfUses(EditorStore.flowNumberOfUses);
    setFlowPreviewId(EditorStore.flowPreviewId);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        // Fetch users who accepted the collaboration request
        const acceptedSnapshot = await firestore
          .collection("notifications")
          .where("additionalData.flowId", "==", flowId)
          .where("type", "==", "collabAccepted")
          .where("receiver", "==", userId)
          .get();
        console.log("acceptedSnapshot", acceptedSnapshot);

        const acceptedCollaborators = acceptedSnapshot.docs.map((doc) => ({
          id: doc.data().sender,
          status: "accepted",
          docId: doc.id,
        }));
        console.log("acceptedCollaborators", acceptedCollaborators);

        // Fetch users who have been sent a collaboration request but haven't accepted yet
        const pendingSnapshot = await firestore
          .collection("notifications")
          .where("additionalData.flowId", "==", flowId)
          .where("type", "==", "collabRequestSent")
          .where("sender", "==", userId)
          .get();

        const pendingCollaborators = pendingSnapshot.docs.map((doc) => ({
          id: doc.data().receiver,
          status: "pending",
          docId: doc.id,
        }));

        // Merge both lists and update state
        const combinedCollaborators = [
          ...pendingCollaborators,
          ...acceptedCollaborators,
        ];

        console.log(acceptedSnapshot.docs);
        console.log(pendingSnapshot.docs);
        setCollaborators(combinedCollaborators);
      } catch (error) {
        console.error("Error fetching collaborators:", error);
      }
    };

    // fetchCollaborators();
    setYourRole("Creator");
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [userId, flowId]);

  const uniqueCollaborators = collaborators
    .filter((collab) => collab.id !== userId)
    .reduce(
      (acc, collab) => {
        if (!acc.ids.has(collab.id)) {
          acc.ids.add(collab.id);
          acc.result.push(collab);
        }
        return acc;
      },
      { ids: new Set(), result: [] }
    ).result;

  const addCollaborator = async (collabId) => {
    if (
      collabId === userId ||
      collaborators.some((collab) => collab.id === collabId)
    ) {
      setErrorMessage(
        "You can't collaborate with yourself or with someone you've already collaborated or sent a request to!"
      );
      return;
    }

    // Check if the username exists in the users collection
    const userSnapshot = await firestore
      .collection("users")
      .where("username", "==", collabId)
      .get();

    if (collaborator.trim() == "") {
      setErrorMessage("You must enter a username");
      return;
    }

    // if (userSnapshot.empty) {
    //   setErrorMessage("This user doesn't exist.");
    //   return;
    // }

    const collabData = { id: collabId, status: "pending" };
    setCollaborators((prevCollabs) => [...prevCollabs, collabData]);

    const notificationForSender = {
      sender: userId,
      receiver: collabId,
      type: "collabRequestSent",
      message: `You sent ${collabId} a collaboration request for this flow ${flowId}.`,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: {
        flowId: flowId,
        collaboratorId: collabId,
      },
    };

    const notificationForReceiver = {
      sender: userId,
      receiver: collabId,
      type: "collabRequestReceived",
      message: `${userId} sent you a collaboration request for this flow ${flowId}.`,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      additionalData: {
        flowId: flowId,
        collaboratorId: userId,
      },
    };

    firestore.collection("notifications").add(notificationForSender);
    firestore.collection("notifications").add(notificationForReceiver);
    setCollaborator("");
    setCollaboratorRole("");
  };

  const removeCollaborator = async (collabId) => {
    setCollaboratorRoleError("");
    const collabToRemove = collaborators.find(
      (collab) => collab.id === collabId
    );
    if (collabToRemove && collabToRemove.docId) {
      await firestore
        .collection("notifications")
        .doc(collabToRemove.docId)
        .delete();
    }
    const updatedCollaborators = collaborators.filter(
      (collab) => collab.id !== collabId
    );
    setCollaborators(updatedCollaborators);
  };

  return (
    <div style={{ marginTop: 10, marginBottom: 15 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p
            style={{
              fontFamily: "quicksand",
              fontSize: 17,
              color: "#4FD975",
              marginLeft: 15,
              marginRight: 10,
            }}
          >
            Monetize
          </p>
          {/* <button style={{ marginLeft: 10 }}>▼</button> */}
          <img src="../images/monetize-down-arrow.svg" style={{ height: 15 }} />
        </div>
        {isOpen && (
          <>
            <div
              style={{
                display: "flex",
                marginLeft: 15,
                flexDirection: "column",
              }}
            >
              <Switch
                onToggle={(e) => {
                  EditorStore.setFlowIsMonetized(e);
                  EditorStore.setMonetized(e);
                  if (e === false) {
                    EditorStore.setFlowPrice(0);
                    EditorStore.setFlowIsMonetized(false);
                    EditorStore.setFlowFreeTrial(false);
                    EditorStore.setPaidRemix(false);
                    EditorStore.setFlowPreview(false);
                    EditorStore.setFlowThumbnail(false);
                    EditorStore.setFlowPerMonth(false);
                    EditorStore.setFlowPerYear(false);
                    EditorStore.setMonetized(false);
                    EditorStore.setFlowDays(0);
                    EditorStore.setFlowNumberOfUses(0);
                    EditorStore.setFlowPreviewId("");

                    setPrice(0);
                    setFlowFreeTrial(false);
                    setFlowPerMonth(false);
                    setFlowPerYear(false);
                    setFlowThumbnail(false);
                    setFlowPreview(false);
                    setPaidRemix(false);
                    setPrice(0);
                    setFlowDays(0);
                    setFlowNumberOfUses(0);
                    setFlowPreviewId("");
                  }
                }}
                defaultChecked={EditorStore.flowIsMonetized}
              />

              <div
                style={{
                  display: "flex",
                  marginRight: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <p style={{ color: "white", fontFamily: "quicksand" }}>
                  Price:
                </p>
                <input
                  type="text"
                  onChange={(e) => {
                    setPrice(e.target.value);
                    EditorStore.setFlowPrice(e.target.value);
                    EditorStore.setFlowIsMonetized(true);
                  }}
                  style={{
                    fontSize: 14,
                    color: "white",
                    fontFamily: "quicksand",
                    height: "30px",
                    width: "100%",
                    background: "rgb(44, 44, 44)",
                    borderRadius: 10,
                    border: "none",
                    outline: "none",
                    paddingLeft: 10,
                    fontWeight: "normal",
                    maxWidth: 57,
                    marginLeft: 15,
                  }}
                  value={price}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: 200,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginRight: 10,
                      borderRight: "1px solid white",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        maxWidth: 180,
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          marginRight: 10,
                          fontFamily: "quicksand",
                        }}
                      >
                        Free Trial
                      </p>
                      <SquareCheckbox
                        onToggle={() => {
                          if (flowFreeTrial == false) {
                            EditorStore.setFlowIsMonetized(true);

                            setFlowFreeTrial(true);
                            setFlowPerMonth(false);
                            setFlowPerYear(false);

                            EditorStore.setFlowFreeTrial(true);
                            EditorStore.setFlowPerMonth(false);
                            EditorStore.setFlowPerYear(false);
                          } else {
                            setFlowFreeTrial(false);
                            EditorStore.setFlowFreeTrial(false);
                          }
                        }}
                        isChecked={flowFreeTrial}
                      />
                      ;
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        flexDirection: "column",
                      }}
                    >
                      <input
                        onChange={(e) => {
                          setFlowDays(e.target.value);
                          EditorStore.setFlowDays(e.target.value);
                          EditorStore.setFlowIsMonetized(true);
                        }}
                        type="text"
                        style={{
                          height: 30,
                          width: 30,
                          fontSize: 14,
                          color: "white",
                          fontFamily: "quicksand",
                          height: 30,
                          width: "100%",
                          background: "rgb(44, 44, 44)",
                          borderRadius: 10,
                          border: "none",
                          outline: "none",
                          paddingLeft: 10,
                          fontWeight: "normal",
                          maxWidth: 57,
                          marginRight: 15,
                        }}
                        value={flowDays}
                      />
                      <p
                        style={{
                          color: "white",
                          marginRight: 10,
                          fontFamily: "quicksand",
                        }}
                      >
                        days
                      </p>
                    </div>
                    <div>
                      <input
                        type="text"
                        onChange={(e) => {
                          setFlowNumberOfUses(e.target.value);
                          EditorStore.setFlowNumberOfUses(e.target.value);
                          EditorStore.setFlowIsMonetized(true);
                        }}
                        style={{
                          height: 30,
                          width: 30,
                          height: 30,
                          width: 30,
                          fontSize: 14,
                          color: "white",
                          fontFamily: "quicksand",
                          height: 30,
                          width: "100%",
                          background: "rgb(44, 44, 44)",
                          borderRadius: 10,
                          border: "none",
                          outline: "none",
                          paddingLeft: 10,
                          fontWeight: "normal",
                          maxWidth: 57,
                          marginRight: 15,
                        }}
                        value={flowNumberOfUses}
                      />
                      <p
                        style={{
                          color: "white",
                          marginRight: 10,
                          fontFamily: "quicksand",
                        }}
                      >
                        free number of uses
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginRight: 10,
                    borderRight: "1px solid white",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      marginRight: 10,
                      fontFamily: "quicksand",
                    }}
                  >
                    {price} per month
                  </p>
                  <SquareCheckbox
                    onToggle={() => {
                      if (flowPerMonth == false) {
                        EditorStore.setFlowIsMonetized(true);

                        setFlowFreeTrial(false);
                        setFlowPerMonth(true);
                        setFlowPerYear(false);
                        EditorStore.setFlowPerMonth(true);
                        EditorStore.setFlowFreeTrial(false);
                        EditorStore.setFlowPerYear(false);
                      } else {
                        setFlowPerMonth(false);
                        EditorStore.setFlowPerMonth(false);
                      }
                    }}
                    isChecked={flowPerMonth}
                  />
                  ;
                </div>
                <div
                  style={{
                    display: "flex",
                    marginRight: 10,
                    borderRight: "1px solid white",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      marginRight: 10,
                      fontFamily: "quicksand",
                    }}
                  >
                    {price * 12} per year
                  </p>
                  <SquareCheckbox
                    onToggle={() => {
                      if (flowPerYear == false) {
                        EditorStore.setFlowIsMonetized(true);

                        setFlowFreeTrial(false);
                        setFlowPerMonth(false);
                        setFlowPerYear(true);
                        EditorStore.setFlowPerYear(true);
                        EditorStore.setFlowPerMonth(false);
                        EditorStore.setFlowFreeTrial(false);
                      } else {
                        setFlowPerYear(false);
                        EditorStore.setFlowPerYear(false);
                      }
                    }}
                    isChecked={flowPerYear}
                  />
                  ;
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid white",
                  display: "flex",
                  paddingTop: 20,
                }}
              >
                <div style={{ display: "flex" }}>
                  {" "}
                  <p
                    style={{
                      fontFamily: "quicksand",
                      color: "white",
                      marginRight: 10,
                    }}
                  >
                    Thumbnail
                  </p>
                  <SquareCheckbox
                    onToggle={() => {
                      EditorStore.setFlowIsMonetized(true);

                      PublishMenuStore.setPostErrorMessage("");
                      setFlowPreview(false);

                      if (flowThumbnail == false) {
                        setFlowThumbnail(true);
                        EditorStore.setFlowPreview(false);
                        EditorStore.setLoadThumbnailPaid(true);
                        EditorStore.setLoadThumbnail(true);
                        EditorStore.setFlowThumbnail(true);
                      } else {
                        setFlowThumbnail(false);
                        EditorStore.setLoadThumbnailPaid(false);
                        EditorStore.setLoadThumbnail(false);
                      }
                    }}
                    isChecked={flowThumbnail}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {" "}
                  <p
                    style={{
                      fontFamily: "quicksand",
                      color: "white",
                      marginRight: 10,
                    }}
                  >
                    Preview
                  </p>
                  <SquareCheckbox
                    onToggle={() => {
                      setFlowThumbnail(false);

                      if (flowPreview == false) {
                        EditorStore.setFlowIsMonetized(true);

                        setFlowPreview(true);
                        EditorStore.setFlowPreview(true);
                        EditorStore.setFlowThumbnail(false);
                        EditorStore.setLoadThumbnailPaid(false);
                        EditorStore.setLoadThumbnail(false);
                        EditorStore.setFlowThumbnail(false);
                      } else {
                        setFlowPreview(false);
                        EditorStore.setFlowPreview(false);
                      }
                    }}
                    isChecked={flowPreview}
                  />
                  <input
                    onChange={(e) => {
                      EditorStore.setFlowPreviewId(e.target.value);
                      setFlowPreviewId(e.target.value);
                      EditorStore.setFlowIsMonetized(true);
                    }}
                    type="text"
                    style={{
                      height: 30,
                      width: 30,
                      height: 30,
                      width: 30,
                      fontSize: 14,
                      color: "white",
                      fontFamily: "quicksand",
                      height: 30,
                      width: "100%",
                      background: "rgb(44, 44, 44)",
                      borderRadius: 10,
                      border: "none",
                      outline: "none",
                      paddingLeft: 10,
                      fontWeight: "normal",
                      maxWidth: 57,
                      marginRight: 15,
                    }}
                    value={flowPreviewId}
                  />
                  <p style={{ fontFamily: "quicksand", color: "white" }}>i</p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid white",
                  display: "flex",
                  paddingTop: 20,
                  borderBottom: "1px solid white",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <p
                      style={{
                        fontFamily: "quicksand",
                        color: "white",
                        marginRight: 10,
                      }}
                    >
                      Users pay to remix this flow.
                    </p>
                    <SquareCheckbox
                      onToggle={() => {
                        EditorStore.setIsRemixable(true);
                        PublishMenuStore.setPostErrorMessage("");
                        EditorStore.setFlowIsMonetized(true);

                        if (paidRemix == false) {
                          setPaidRemix(true);
                          EditorStore.setPaidRemix(true);
                          EditorStore.setIsRemixable(true);
                        } else {
                          setPaidRemix(false);
                          EditorStore.setPaidRemix(false);
                        }
                      }}
                      isChecked={paidRemix}
                    />
                    <p style={{ fontFamily: "quicksand", color: "white" }}>i</p>
                  </div>
                  <input
                    type="text"
                    placeholder="price"
                    style={{
                      height: 30,
                      width: "100%",
                      fontSize: 14,
                      color: "white",
                      fontFamily: "quicksand",
                      background: "rgb(44, 44, 44)",
                      borderRadius: 10,
                      border: "none",
                      outline: "none",
                      paddingLeft: 10,
                      fontWeight: "normal",
                      maxWidth: 57,
                      marginTop: 10,
                      marginBottom: 15,
                    }}
                    onChange={(e) => {
                      EditorStore.setRemixPrice(e.target.value);
                      EditorStore.setFlowIsMonetized(true);
                    }}
                    value={EditorStore.remixPrice}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default CollaboratorList;
