import { default as React, useContext, useState, useEffect } from "react";
import { RoomContext } from "../RoomContext.js";
import { WidgetStoreContext } from "../../../store/WidgetStore.js";
import { firebase } from "../../firebase/firebase";
import ReactModal from "react-modal";
import { AuthContext } from "../AuthContext";

import { useParams } from 'react-router-dom';
const FlowCardContainer = React.lazy(() => import("./FlowCardContainer"));
const Collaborators = React.lazy(() => import("./Collaborators"));
const FlowCardExpandButton = React.lazy(() => import("./FlowCardExpandButton"));
const FlowCardNameDateAndEllipsisFeed = React.lazy(() =>
  import("./FlowCardNameDateAndEllipsisFeed")
);


const FlowCardShareOverlay = React.lazy(() => import("./FlowCardShareOverlay"));

const FlowCardTitleAndStats = React.lazy(() =>
  import("./FlowCardTitleAndStats")
);

const RoomPlaceholder = ({index}) => {
  const [thumbnail, setThumbnail] = useState('');

  // useEffect(()=>{
  //   let getLoadingCovers = document.getElementsByClassName('skeleton-card');
   
  //   for(let i = 0; i < getLoadingCovers.length; i++) {
  //     document.getElementsByClassName('skeleton-card')[i].style.display = 'block';
  //   }
  //   setTimeout(()=>{


  //     for(let i = 0; i < getLoadingCovers.length; i++) {
  //       document.getElementsByClassName('skeleton-card')[i].style.display = 'none';
  //     }
  //   },2000)
  // },[])
  return (
    <div className="room-placeholder" style={{borderRadius:20, overflow:'hidden'}}>
      {/* Your placeholder content, e.g., gray boxes, shimmer effects, etc. */}
      <div
        style={{
          height: '100%',
          width: "100%",
          background: "rgb(28, 28, 28)",
          boxShadow: "rgb(0, 0, 0) 0px 0px 17px -7px",
          borderRadius: 20,
          overflow: "hidden",
          maxWidth: 503,
          margin: "auto",
          zIndex:10000, 
          position:'absolute',
          top:0
        }}
        className="skeleton-card"
      ></div>
      <style>
        {`
    .skeleton-card {
      overflow: hidden;
      position: relative;
      background: rgb(28, 28, 28);
      box-shadow: rgb(0, 0, 0) 0px 0px 17px -7px;
      border-radius: 20px;
      height: 100%;
      width: 100%;
  }
  
  .skeleton-card::before {
    content: "";
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 20%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: shimmer 1.2s infinite;
    border-radius: 20px;

  }
  
 
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  `}
      </style>
    </div>
  );
};
const FlowCard = ({index}) => {
  const [showReshareModal, setShowReshareModal] = useState(false);
  const WidgetStore = useContext(WidgetStoreContext);
  const [showOptions, setShowOptions] = useState(false);
  const [showPlaceHolder, setShowPlaceholder] = useState('');
  const room = useContext(RoomContext);

    const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setThumbnail(null)
    setTimeout(()=>{
      setThumbnail(room.thumbnail);
    },900)
           

    // const unsubscribe = firebase.firestore()
    //   .collection("flows")
    //   .doc(room.flowId) // Assuming flowId is correctly set to identify the unique document
    //   .onSnapshot(doc => {
    //     if (doc.exists) {
    //       const data = doc.data();
    //       if (data.thumbnail && data.thumbnail !== "") {
    //         setTimeout(()=>{
    //         setThumbnail(data.thumbnail);
    //         },2000)
    //       } else {
    //         setThumbnail(''); // Ensure thumbnail is set to '' if no thumbnail is present
    //       }
    //     } else {
    //       setError('Flow not found.');
    //     }
    //   }, err => {
    //     console.error("Error fetching document: ", err);
    //     setError('Error fetching flow.');
    //   });

    // Clean up the listener when the component unmounts
    return () => {}
  }, [room.flowId]);

  return (
    <>
      <div data-home-main style={{ width: "100%", position: "relative", overflow:'hidden', borderRadius:20 }}>
      {thumbnail == null ? <RoomPlaceholder index={index}/> : ''}
    
        <div
          style={{
            zIndex: 1000,
            backgroundColor: "#1c1c1c",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "relative",
            overflow:'hidden'
          }}
        >
          <FlowCardNameDateAndEllipsisFeed showOptions={()=>{
            setShowOptions(true);
          }} />
        </div>
        <FlowCardShareOverlay flowId={room.flowId} openReshareModal={()=>{setShowReshareModal(true);}}/>
        <Collaborators />
        {/*Flow Card Conatiner contains the main flow card componenents*/}
        <FlowCardContainer showOptions={showOptions} flowImage={room.thumbnail} />
        <FlowCardTitleAndStats />
        <FlowCardExpandButton />
      </div>
    </>
  );
};

export default React.memo(FlowCard);
