import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import ReactModal from "react-modal";
import { EditorStoreContext } from "../../../../store/EditorStore.js";
import { AuthenticationStoreContext} from "../../../../store/AuthenticationStore.js";

import { TabStoreContext } from "../../../../store/TabStore.js";
import { WidgetStoreContext } from "../../../../store/WidgetStore.js";
import { useNavigate, useParams } from "react-router-dom"; // Updated import

const SetAdvancedModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const TabStore = useContext(TabStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isNotDev, setIsNotDev] = useState(false);
  const [showAdvancedModal, setShowAdvancedModal] = useState(false);
  const navigate = useNavigate(); // Call useNavigate at the top level

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <ReactModal
      isOpen={EditorStore.showAdvancedModal}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#1C1C1C",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          minHeight:400,
          maxWidth: "646px",
          padding: 0,
        },
      }}
    >
            <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          More Options
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            // setDeleteBookmark(false);
            // setDeleteFlow(false);

            // EditorStore.setShowMoreModal(false);
            // TabStore.setClosePanel(true);
            EditorStore.setShowAdvancedModal(false)
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>
      <div style={{padding:10, borderTop:'1px solid rgb(62, 62, 62)'}}></div>
      {isNotDev == false ? (<div style={{ display: "flex", width:'100%', justifyContent:'space-between' }}>
      <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width:'100%',
            padding:20
          }}
        >
         
          <img
            src="../images/lightbulb.svg"
            style={{ marginTop: 55, marginBottom: 15, width:90 }}
          />
  
          <p
            style={{
              color: "white",
              fontSize: 14,
              wudth: "100%",
              textAlign: "center",
              fontFamily: "Quicksand",

              margin: "23px 10px 20px",
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: 16,
              textAlign: "left",
              color: "#FFFFFF",
              marginTop: 15,
              marginBottom: 20,
            }}
          >
            You will now get access to creation tools that allow you to create mini apps with just AI and your words, with or without code just your words with widgets apis tools can easily integrate.
          </p>
          <div
           
           onClick={() => {
            EditorStore.setShowAdvancedModal(false);

          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "11px 17px",
            gap: "10px",
            height: 40,
            background: "#4FD975",
            borderRadius: 10,
            width: "calc(100% - 21px)",
            margin: "auto",
            border:'1px solid #4FD975',
            marginTop:20,
            cursor:'pointer'
          }} 
          >
            <p
              style={{
                color: "#000",
                fontFamily: "quicksand",
                fontSize: 15,
                cursor:'pointer'
                
              }}
            >
              Start Creating
            </p>
          </div>
          <div
            onClick={() => {
              EditorStore.setShowAdvancedModal(false);
            }}
            style={{
              height: 40,
              width: "calc(100% - 22px)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              cursor: "pointer",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              cursor: "pointer",
              margin: "auto",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "11px 17px",
              gap: "10px",
              /* position: absolute; */
              /* width: 526px; */
              /* height: 42px; */
              /* left: 457px; */
              /* top: 677px; */
              border: "1px solid #4FD975",
              borderRadius: 10,
              marginTop: 15,
            }}
          >
            <p
              style={{
                color: "#4FD975",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Close
            </p>
          </div>
        </div>
  <style jsx>{`
    .button-container {
      width: 48%; /* default width */
      height: 42px;
      border: 1px solid #4FD975;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      /* other styles */
    }

    @media (max-width: 600px) { /* Adjust this breakpoint as needed */
      .button-container {
        width: 100%; /* full width on small screens */
      }
    }

  .dropdown-container {
    position: relative;
  }
  .dropdown-display {
    background-color: #1c1c1c !important;
    padding: 7px !important;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #4fd975;
    color: white;
    font-family: "Quicksand";
    overflow: hidden;
    border-radius: 20px;
  }
  .dropdown-list {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #1c1c1c;
    border: 0px solid #ddd;
    border-radius: 5px;
    width: 100%;
    z-index: 100;
    bottom: -59px !important;
    border-left: 1px solid #4fd975 !important;
    border-bottom: 1px solid #4fd975 !important;
    border-right: 1px solid #4fd975 !important;
    color: white;
    font-family: quicksand;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    max-height: 200px; /* Adjust based on your requirement */

    /* Custom scroll bar styles */
    scrollbar-width: thin;
    scrollbar-color: #4fd975 #1c1c1c; /* thumb and track color */

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #1c1c1c;
    }
    &::-webkit-scrollbar-thumb {
      background: #4fd975;
      border-radius: 4px;
    }
  }
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
  }
  `}</style>
      </div>):(<div>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <FontAwesomeIcon
            key={Math.random() + "icon" + new Date()}
            icon={faExclamation}
            color="white"
            style={{
              display: "flex",
              fontSize: 77,
              margin: "auto",
              marginBottom: 15,
              marginTop: 30,
            }}
          />{" "} */}
          <img
            src="../images/lightbulb.svg"
            style={{ marginTop: 55, marginBottom: 37, width:80 }}
          />
          <p
            style={{
              color: "#A962F1",
              fontFamily: "quicksand",
              fontSize: 20,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              width: "calc(100% - 50px)",
              marginBottom: 0,
              paddingBottom: 0,
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Dev mode not enabled!
          </p>
          <p
            style={{
              color: "white",
              fontSize: 15,
              wudth: "100%",
              maxWidth: 348,
              textAlign: "center",
              fontFamily: "Quicksand",

              margin: "auto auto 20px",
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 0.6,
              marginTop: 23,
              marginBottom: 34,
            }}
          >
            To create a flow from scratch, you must have "Developer mode"
            enabled in your settings!
          </p>
          <div
            onClick={() => {
              navigate("/settings");

            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "11px 17px",
              gap: "10px",
              height: 50,
              background: "#4FD975",
              borderRadius: 10,
              width: "calc(100% - 50px)",
              margin: "auto",
              cursor:'pointer'
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Go to settings
            </p>
          </div>
          <div
            onClick={() => {
              setIsNotDev(false);
            }}
            style={{
              height: 50,
              width: "calc(100% - 50px)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              cursor: "pointer",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              cursor: "pointer",
              margin: "auto",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "11px 17px",
              gap: "10px",
              /* position: absolute; */
              /* width: 526px; */
              /* height: 42px; */
              /* left: 457px; */
              /* top: 677px; */
              border: "1px solid #4FD975",
              borderRadius: 10,
              marginTop: 15,
            }}
          >
            <p
              style={{
                color: "#4FD975",
                fontFamily: "quicksand",
                fontSize: 19,
              }}
            >
              Back
            </p>
          </div>
        </div>
      </div>)}
    </ReactModal>
  );
});

export default React.memo(SetAdvancedModal);
