import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore";
import "./Switch.css";
import { propTypes } from "velocity-react/src/velocity-component";

function PasswordProtect(props) {
const [password, setPassword] = useState("");
  return (
    <div style={{height:'100%', backgroundColor:'rgb(28, 28, 28)', maxWidth:400, width:'100%', minWidth:300, paddingBottom:20, paddingLeft:10, paddingRight:10, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:15, top:40, position:'relative'}}>Please enter the password to access</p>
      <input onChange={(e)=> {
        setPassword(e.target.value);
      }} style={{ marginTop:50, width:'100%', resize:'none', outline:'none', padding:10, fontFamily:'quicksand', backgroundColor:'#E9E9E91A', border:'none', color:'white', fontSize:15}} type={'password'} value={password}/>
      <div onClick={()=>{
                let iframe = document.getElementById('undefined_output_frame');
                iframe.contentWindow[props.functionName](password);
      }} style={{height:30, width:'100%', maxWidth:100, backgroundColor:'#4FD975', borderRadius:7, cursor:'pointer', display:'flex', justifyContent:'center', alignItems:'center', marginTop:20}}><p style={{fontFamily:'quicksand', color:'#1c1c1c', fontSize:12, }}>Submit</p></div>
    </div>
  );
}

export default PasswordProtect;
