import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class ShareStore {
  constructor() {
    makeAutoObservable(this);
  }

  isShowModal = false;
  flowId = "";
  room = {};

  setIsShowModal(isShow) {
    this.isShowModal = isShow;
  }

  setFlowId(flowId) {
    this.flowId = flowId;
  }

  setRoom(room) {
    this.room = room;
  }
}

export const ShareStoreContext = createContext(new ShareStore());
