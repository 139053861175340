
import { faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@material-ui/core/Radio";
import Slider from "@material-ui/core/Slider";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-responsive-select/dist/react-responsive-select.css";
import { components } from "react-select";
// stores
import ReactModal from "react-modal";

import Hashids from "hashids";

import { Button } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';

import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import { createBrowserHistory } from "history";
import { v4 as uuid } from "uuid";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { TabStoreContext } from "../../store/TabStore.js";

import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixMenuDesktopStoreContext } from "../../store/Remix_Menu_Desktop.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import Checkbox from "./Checkbox.js";
import CollaboratorList from "./CollaborateList.js";
import { Config } from "./config/Config";
import MonetizeComponent from "./MonetizeComponent.js";
import Publish_Save from "./Publish_Save.js";
// import Sharing from "./Sharing.js";
import SquareCheckbox from "./SquareCheckBox.js";
import { useNavigate, useLocation, useHistory, useParams } from 'react-router-dom';
import 'simplebar/dist/simplebar.css';
import SimpleBar from 'simplebar-react';



let currentUserString = localStorage.getItem("flowroom-user");


let followList = [];
let followListCount = 0;
let called = false;
let lastIndexFlow = null;
let followers = [];
let firestore = firebase.firestore();
let incNum = 0;

let options = ["Post", "Update"];
let alreadyDHTML = false;
let arrayLikeObj = {};
let arr = [];
let remixedByArr = [];
let isUpdate = false;
let isRemix = false;

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});

const s3 = new S3();
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 100 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    zIndex: 10000000,
  };
}

const remove_duplicates_es = (arr) => {
  let s = new Set(arr);
  let it = s.values();
  return Array.from(it);
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1000000,
  },
}));

const history = createBrowserHistory();

let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let lastSegment = parts.pop() || parts.pop();

let isLoadedMenu = false;
const KeyCodes = {
  comma: 188,
  enter: 13,
};
let collaboratorsItem = [];
let alreadyAdded = [];
let alreadyLoadedName = false;

const delimiters1 = [KeyCodes.comma, KeyCodes.enter];
const delimiters2 = [KeyCodes.comma, KeyCodes.enter];
const delimiters3 = [KeyCodes.comma, KeyCodes.enter];
const delimiters4 = [KeyCodes.comma, KeyCodes.enter];
const delimiters5 = [KeyCodes.comma, KeyCodes.enter];
const customStyles = {
  indicatorsContainer: (provided, state) => ({
    backgroundColor: "#2d2a2b",
    borderRadius: 15,
    color: "white",
  }),

  control: (_, { selectProps: { width, backgroundColor, height } }) => ({
    height: 42,
    backgroundColor: "#2C2C2C",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: "0px",
    fontFamily: "quicksand",
    color: "white",
    minHeight: 3,
    //width: "calc(100% - 50px)",

    // overflow: "hidden",
  }),

  dropdownIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#2d2a2b",
  }),
  clearIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#2d2a2b",
  }),
  valueContainer: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      backgroundColor: "#2d2a2b",
      // height: 30,
      fontSize: 20,

      borderRadius: 15,
      padding: "0px",
      color: "white",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      fontFamily: "quicksand",
      padding: "0px 10px",
      // width: "calc(100% - 50px)",
    };
  },
  menu: (provided, state) => {
    return {
      // ...provided,
      fontFamily: "quicksand",
      backgroundColor: "#2C2C2C",
      overflow: "hidden",
      fontSize: 20,
      position: "absolute",
      top: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
      zIndex: 1,
      // width: "calc(100% - 50px)",
      // color: state.selectProps.menuColor,
    };
  },
  placeholder: (provided, state) => {
    return {
      fontFamily: "quicksand",
      color: "white",
      alignItems: "auto",
      justifyContent: "space-between",
      display: "flex",
      fontSize: 20,
      backgroundColor: "#2d2a2b",

      // width: "calc(100% - 50px)",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      // marginTop: 5,
      backgroundColor: "#2C2C2C",

      fontSize: "10px",
      height: 10,
      // width: "calc(100% - 50px)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      // height: 30,
      fontSize: 20,
      backgroundColor: "#2C2C2C",

      padding: "0px",
      color: "white",
      fontFamily: "quicksand",
      // width: "calc(100% - 50px)",
    };
  },
  option: (base) => ({
    ...base,
    height: "100%",
    width: "250px",
    backgroundColor: "#2C2C2C",
    zIndex: 1000,
  }),
};


const radioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "4px solid #2d2a2b",
    width: 30,
    height: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#2d2a2b",
    color: "white",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '$root.Mui-focusVisible &': {
    // outline: '1px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
  },
  checkedIcon: {
    backgroundColor: "#4FD975",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      color: "white",
    },
    "input:hover ~ &": {
      backgroundColor: "#4FD975",
      color: "white",
    },
  },
});
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const CaretDownIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faTriangle}
      color="#A962F1"
      size={50}
      style={{
        display: "block",
        position: "absolute",
        right: "10px",
        top: "10px",
        transform: "rotate(180deg)",
        fontSize: 15,
      }}
    />
  );
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src="/images/down-arrow.svg"
        style={{ position: "absolute", top: 17, right: 20, cursor: "pointer" }}
      />
    </components.DropdownIndicator>
  );
};
// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
const PrettoSlider = withStyles({
  root: {
    color: "#A962F1",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#40ffe8",
      "& + $track": {
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#40ffe8",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const objInArrayHasProperty = (obj, prop) => {
  return obj.some((item) => item.Name === "Magenic");
};

const username = JSON.parse(localStorage.getItem("flowroom-user"));
const PublishMenu = observer((props) => {
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState();
  const [isvalidationComplete, setIsValidationComplete] = useState(false);
  const [visibility, setVisibility] = useState("public");
  const [titleHelperText, setTitleHelperText] = useState();
  const [TitleError, setTitleError] = useState(false);
  const [descriptionHelperText, setDescriptionHelperText] = useState();
  const [descriptionError, setDescriptionError] = useState(false);
  const [isChecked, setIsChecked] = useState({ checkedA: false });
  const [collaborators, setCollaborators] = useState([]);
  const [normalizeChecked, setNormalizeChecked] = useState(false);
  const [collaborator, setCollaborator] = useState("");
  const [notEnough, setNotEnough] = useState(false);
  const [hasEnoughCredit, setHasEnoughCredit] = useState(false);
  const [saveAsReply, setSaveAsReply] = useState(false);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const [displayName, setDisplayName] = useState(null);
  const [titleErrorOutline, setTitleErrorOutline] = useState(false);
  const [descriptionErrorOutline, setDescriptionErrorOutline] = useState(false);
  const FlowStore = useContext(FlowStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const { currentUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [DescriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState("public");
  const [saving, setSaving] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [conversation, setConversation] = useState([
    { role: "system", content: "You are an AI bot that answers questions" },
  ]);
  const inputRef = useRef();

  const DHTML_Store = useContext(DHTML_StoreContext);
  const Remix_Menu_Desktop = useContext(RemixMenuDesktopStoreContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [postSelectedIndex, setPostSelectedIndex] = React.useState(0);
  const [isPostOptionsLoaded, setIsPostOptionsLoaded] = React.useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emptyDHTML, setEmptyDHTML] = useState(false);
  const [myFollowerList, setMyFollowerList] = useState([]);
  const classes = useStyles();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [disableSend, setDisableSend] = useState(false);
  const [openT, setState] = useState(false);
  const [IsReviewing, setIsReviewing] = useState(false);

  const TabStore = useContext(TabStoreContext);
  const Publish_Menu_Store = useContext(PublishMenuStoreContext);
  const [beginOrQuestionConversation, setBeginOrQuestionConversation] =
    useState([
      {
        role: "system",
        content: `You are an advanced AI system specifically designed to review and manage digital content. Your capabilities include analyzing text, images, and videos to ensure they meet quality standards and comply with guidelines. Users interact with you to initiate reviews, make updates, or post new content.

Your process is straightforward and user-friendly. When a user wants to begin a review, they can simply type 'begin review' or ask a question about the review process. Your first step is to confirm the ownership of the content. Users indicate their ownership by including 'Ownership: true' in their message.

Once ownership is confirmed, you provide them with two choices:

Update Existing Content: If the user wishes to update their existing content, they should respond with 'update existing'. You then guide them through the process of making changes to their current content.
Post New Content: If the user prefers to post new content, they should reply with 'post new'. You assist them in uploading and reviewing their new content.
Based on the user's choice, you process their request and respond with a JSON object. For updates, the response is { "update": true, "postNew": false }, and for new posts, it’s { "update": false, "postNew": true }.

As an AI, your goal is to ensure a seamless, efficient, and accurate review process, helping users manage their digital content effectively.`, // Initial system message
      },
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [flowId_, setFlowId_] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [showReviseFields, setShowReviseFields] = useState(false);
    const [revisedTitle, setRevisedTitle] = useState("");
    const [showReviseTitleField, setShowReviseTitleField] = useState(false);
    const [showReviseDescriptionField, setShowReviseDescriptionField] = useState(false);

    const [revisedDescription, setRevisedDescription] = useState("");
    const [alreadySelectedTitle, setAlreadySelectedTitle] = useState(false);
    const [alreadySelectedDescription, setAlreadySelectedDescription] = useState(false);
    const [originalTitle, setOriginalTitle] = useState(null);
    const [originalDescription, setOriginalDescription] = useState(null);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [hideUpdateBtn, setHideUpdateBtn] = useState(false);
    const [hideRemixBtn, setHideRemixBtn] = useState(false);
    const [useMyOwnTitle, setUseMyOwnTitle] = useState(false);
    const [useMyOwnDescription, setUseMyOwnDescription] = useState(false);
  
    const { id } = useParams();

    useEffect(() => {
      let parts = window.location.pathname.split("/");
      // let parts = window.location.pathname.split("/");
      let lastSegment = parts.pop() || parts.pop();
      
      // Only query Firestore if id is not "new"
      if (lastSegment !== "new") {
        const fetchData = async (id) => {
          try {
            const querySnapshot = await firebase
              .firestore()
              .collection("flows")
              .where("flowId", "==", lastSegment)
              .get();
  
            if (!querySnapshot.empty) {
              const doc = querySnapshot.docs[0];
              const data = doc.data();

              let titleFiltered;
              let descriptionFiltered;
              if(data.isRemixed) {
                isRemix = true;
              }
              // if (data.title) titleFiltered = removeLinesWithLinks(data.title);
              // if (data.description) descriptionFiltered = removeLinesWithLinks(data.description);
                setTitle(removeLinesWithLinks(data.title));
                setDescription(removeLinesWithLinks(data.description));
                setOriginalTitle(removeLinesWithLinks(data.title));
                setOriginalDescription(removeLinesWithLinks(data.description));
                PublishMenuStore.setTitle(removeLinesWithLinks(data.title));
                PublishMenuStore.setDescription(removeLinesWithLinks(data.description));

                PublishMenuStore.setOriginalTitle(removeLinesWithLinks(data.title));
                PublishMenuStore.setOriginalDescription(removeLinesWithLinks(data.description));

            } else {
              console.warn("No matching document found for flowId:", id);
            }
          } catch (error) {
            console.error("Error fetching data from Firestore:", error);
          }
        };
  
        fetchData(lastSegment);
      }
      
      if(id === "new"){
        PublishMenuStore.setTitle("");
        PublishMenuStore.setDescription("");
        setTitle("");
        setDescription("");
      }
    }, [id]); // Re-run the effect if id changes

    const removeLinesWithLinks = (text) => {
   // Step 1: Remove 'Remix of <a href="...">this</a>' or 'Remix of this' with optional trailing punctuation
   const remixAndLinkRegex = /(?:^|\s)Remix\s+of\s+(?:<a\s+href="[^"]*">)?this(?:<\/a>)?[.,]?\s*/gi;
   let result = text.replace(remixAndLinkRegex, '');

   // Step 2: Remove any remaining <a> tags but keep their inner text
   const linkRegex = /<a\s+href="[^"]*">([^<]*)<\/a>/gi;
   result = result.replace(linkRegex, '$1');

   // Step 3: Remove any remaining <a> or </a> tags that might be left
   const strayATagRegex = /<\/?a[^>]*>/gi;
   result = result.replace(strayATagRegex, '');

   // Step 4: Trim leading and trailing whitespace
   return result.trim();
};
    
    
    // **Example Usage:**


    const addMentionDescription = async (username) => {
      const newNotification = {
          sender: currentUser.displayName,
          receiver: username,
          type: "mentionedDescription",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: currentUser.uid,
          flowId: FlowStore.flowId,
          isSeen: false,
          additionalData: {
              messageNotification: "messageNotification",
              photoURL: currentUser.photoURL,
          },
      };
  
      try {
          await firebase.firestore().collection("notifications").add(newNotification);
          console.log("Notification added for:", username);
      } catch (error) {
          console.error("Error adding notification:", error);
      }
  };
  
  // Processes descriptions to find and handle @mentions
  function processMentions(description) {
      const mentionRegex = /@(\w+)/g;
      let match;
      while ((match = mentionRegex.exec(description)) !== null) {
          const username = match[1].toLowerCase();
          addMentionDescription(username);  // Asynchronous, not awaited here
      }
  }
  function extractAndParseJson(inputString) {
    // Regular expression to match JSON within ```json ``` delimiters
    const regex = /```json\n([\s\S]*?)\n```/;
    const match = inputString.match(regex);
  
    if (match && match[1]) {
      // Try to parse the JSON string
      try {
        const parsedJson = JSON.parse(match[1]);
        return parsedJson;
      } catch (e) {
        console.error("Failed to parse JSON:", e);
        return null;
      }
    } else {
      console.error("No JSON found in the string.");
      return null;
    }
  }
  
  const getAssistantResponse = (userMessage) => {

    // Placeholder for an actual function that generates a response
    return reviewInfoGPT(userMessage);
  };

  async function reviewInfoGPT(
    messages,
    model,
    temperature,
    max_tokens,
    clearConvo = false,
    specialArgument = ""
  ) {
    const response = await fetch(
      "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: [{role:"system", content:`You are a highly intelligent AI assistant specialized in content optimization. Your primary task is to review, revise, and enhance titles and descriptions provided to you, ensuring they are engaging, clear, and SEO-friendly. Additionally, you are equipped to suggest relevant hashtags that could increase the visibility of the content.

          Instructions:
          Review the Title: Analyze the given title for clarity, engagement, and relevance. Suggest a revised title if necessary.
          Enhance the Description: Examine the provided description. Revise it to improve clarity, engagement, and succinctness, making sure it is informative and aligns with the intent of the title.
          Hashtag Suggestions: Based on the content of the title and description, suggest up to five hashtags that are relevant and could help in reaching a broader audience.
          Compare with Pre-Existing: Compare both the preExistingTitle and preExistingDescription with the Original Title and Original Description to determine if they match.
          Output Format:
          Provide your response in JSON format, including the revised title, description, and hashtags. Include the hashtags in the description as well. Also, indicate whether the preExistingTitle and preExistingDescription match the Original Title and Original Description.
          
          Example:
          Input:
          Original Title: "Cooking Fast: Quick Meal Ideas"
          Original Description: "This blog post provides several quick meal ideas for people who don't have much time to cook."
          title: "Cooking Fast: Quick Meal Ideas"
          description: "This blog post provides several quick meal ideas for people who don't have much time to cook."

          IMPORTANT: Below is the title and description provided by the user submitting.

          Provide your revisions and hashtag suggestions in JSON format.
          
          IMPORTANT: The example JSON response below is only an example. Do not use the RevisedTitle verbatim or RevisedDescription verbatim. Instead, try to take what the user originally wrote for a title or description and make it more understandable, more descriptive if necessary. If the user provided something vague, don't make assumptions. If they only typed a few words, 
          then use "reject" boolean and set it to true and for the "rejectReason", give it a string that tells the user why the title or description was rejected such as: "Your title was not descriptive enough" or "Your description needs to be more elaborate" if pertaining to the description, however you want to convey to the user their title or description is not suitable for submission

          If it's a game or social experience use hashtags like #Social or #Games.
          Sometimes certain things might fall under a new category such as #InteractiveMemes

          An example of something submitted that should get a #Social hashtag would some kind of app or interactive experience social related like posting photos for friends or an app that contains text for a text post for people to read etc. 

          Here's an example of a title: Quick & Easy Meal Ideas for Busy Cooks

          Here's an example of a description: Explore our top picks for quick and easy meal ideas perfect for busy individuals looking to whip up delicious dishes in no time. These recipes are ideal for those who need fast, yet tasty solutions for their daily meals. #QuickMeals #EasyRecipes #BusyCooks #FastCooking #HealthyEating

          IMPORTANT: Their title and or description may be fine. It may be simple but if it is good enough to convey to users what the experience is in a relatively meaningful way, not like "a title" or "hello" or super simplistic not really explaining what the experience is, you won't need a "RevisedTitle" if the title is good emough or a "RevisedDescription"

          VERY IMPORTANT: If you provide a "RevisedTitle" or a "RevisedDescription", do not make it start with Discover or Explore each time. It just needs to elaborate more not be an entirely new title or description. Also, these are interactive experiences and mini apps to give you context for the title or description but you don't have to mention that either.

          IMPORTANT: If useMyOwnTitle: true, provided in this prompt above, then make "RevisedTitle" null and if useMyOwnDescription: true then make "RevisedDescription" null and "reject" false and "noRevisionNeeded" true
          
          IMPORTANT: If you are checking for a title or description, check for the title and description provided: 

          IMPORTANT: If there is any NSFW title or description or anything racist or extremely offensive then set "reject": true and "rejectReason" explain why it was rejected.

          Input for Task:
                    
          useMyOwnTitle: ${useMyOwnTitle}

          useMyOwnDescription: ${useMyOwnDescription}

          isRemix: ${isRemix}
          isUpdate: ${isUpdate}

          JSON Response:
          ${'`' + '`' + '`' + 'json'}
          {
            "RevisedTitle": String containing a revised title if necessary,
            "RevisedDescription": String containing a revised description if necessary,
            "Hashtags": ["#QuickMeals", "#EasyRecipes", "#BusyCooks", "#FastCooking", "#HealthyEating"],
            "showSuggestionFields": boolean,
            "originalTitle":${PublishMenuStore.originalTitle},
            "originalDescription":${PublishMenuStore.originalDescription},
            "title":${PublishMenuStore.title},
            "description":${PublishMenuStore.description},
            "showSuggestedTitle": boolean,
            "showSuggestedDescription": boolean,
            "isMatchPreExistingAndOriginal": boolean,
            "rejectReason": String describing why the title or description is not acceptable.
            "reject":  boolean based on whether a title or description should be rejected,
            "noRevisionNeeded": boolean based on whether a title or description is needed,
            "isUpdate":${isUpdate}
          }
          
          Important Notes:

          Only show suggestions when the title or description really doesn't help describe the content. Try to keep descriptions under 50 characters if possible, but longer descriptions are acceptable if they are descriptive.
          The Original Title or Original Description should not be nonsensical or too short (e.g., "bbnbnb" or "kjkjkkj"). If it doesn't make sense or is very short, provide a suggestion.
          It's okay if the Original Title and preExistingTitle or Original Description and preExistingDescription are similar, as long as they are not identical. If they are identical, isMatchPreExistingAndOriginal should be true. If they are similar but not identical, isMatchPreExistingAndOriginal should be false.`}],
          model: "gpt-4o",
          temperature: 0,
        }),
      }
    );
  
    if (response.ok) {
      const result = await response.json();
      return result.choices[0].message.content;
    
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  }
  

  async function reviewFlowInfo() {
    setIsLoading(true);

    console.log('title ' + title);
    console.log('description ' + description);
  
    console.log('preExistingTitle ' + originalTitle);
    console.log('preExistingDescription ' + originalDescription);
    if(isUpdate == true) {
      
      setIsLoadingUpdate(true);
      setHideUpdateBtn(false);
      setHideRemixBtn(true);
 

    } else {
      setIsLoading(true);
      setHideUpdateBtn(true);
      setHideRemixBtn(false);
    }
    PublishMenuStore.setShowErrorMessage(false);
    PublishMenuStore.setShowErrorMessageTitle(false);
    PublishMenuStore.setShowErrorMessageDescription(false);
    PublishMenuStore.setPostErrorMessage(
      ""
    );

    // if (title == "" || description == "") {
    //   PublishMenuStore.setShowErrorMessage(true);
    //   PublishMenuStore.setShowErrorMessageTitle(true);
    //   PublishMenuStore.setShowErrorMessageDescription(true);
    //   PublishMenuStore.setPostErrorMessage(
    //     "You are missing a title and/or description"
    //   );

    //   setTimeout(()=>{
    //   setIsLoading(false);
    //   setIsLoadingUpdate(false);
    //   },500)
    //   return;
    // }


    // if (title.length < 15) {
    //   PublishMenuStore.setShowErrorMessage(true);
    //   PublishMenuStore.setShowErrorMessageTitle(true);
    //   PublishMenuStore.setShowErrorMessageDescription(true);
    //   PublishMenuStore.setPostErrorMessage(
    //     "The title must be 20 characters or more."
    //   );

    //   setTimeout(()=>{
    //   setIsLoading(false);
    //   setIsLoadingUpdate(false);
    //   },500)
    //   return;
    // }


    // if (description.length < 150) {
    //   PublishMenuStore.setShowErrorMessage(true);
    //   PublishMenuStore.setShowErrorMessageTitle(true);
    //   PublishMenuStore.setShowErrorMessageDescription(true);
    //   PublishMenuStore.setPostErrorMessage(
    //     "The description can not be less than 150 characters"
    //   );

    //   setTimeout(()=>{
    //   setIsLoading(false);
    //   setIsLoadingUpdate(false);
    //   },500)
    //   return;
    // }
   
   
    const assistantResponse = await getAssistantResponse(); // Wait for the assistant's response
    const extractedJson = extractAndParseJson(assistantResponse);
    console.log('assistantResponse', extractedJson);

    if(extractedJson.reject === true) {
      PublishMenuStore.setShowErrorMessage(true);
      
      PublishMenuStore.setPostErrorMessage(
        extractedJson.rejectReason
      );
      setIsLoading(false);
      setIsLoadingUpdate(false);
      return;
    }

    
    if(isUpdate == false) {
    if(extractedJson.showSuggestedTitle === true) {
      setShowReviseTitleField(true);
      setRevisedTitle(extractedJson.RevisedTitle);
    }

    if(extractedJson.showSuggestedDescription === true) {
      setShowReviseDescriptionField(true);    
      setRevisedDescription(extractedJson.RevisedDescription);
    }
    // console.log('is match', extractedJson.isMatchPreExistingAndOriginal)

    // if(extractedJson.isMatchPreExistingAndOriginal === true) {

    //   PublishMenuStore.setShowErrorMessage(true);
    //   PublishMenuStore.setShowErrorMessageTitle(true);
    //   PublishMenuStore.setShowErrorMessageDescription(true);
    //   PublishMenuStore.setPostErrorMessage(
    //     "No duplicate posts allowed!"
    //   );

    //   setTimeout(()=>{
    //   setIsLoading(false);
    //   setIsLoadingUpdate(false);
    //   },500)
    //   return;
    // }
  

 

    if(extractedJson.showSuggestedTitle || extractedJson.showSuggestedDescription) {
      setIsLoading(false);
      setIsLoadingUpdate(false);
      setShowReviseTitleField(true);

      return;
    }

    // handleClick();

  


  }

  
    handleClick();
 

  }




    const addPostNotification = async (
      sender,
      receiver,
      messageNotification,
      photoURL
    ) => {
      const newNotification = {
        sender: currentUser.displayName,
        receiver: "followers",
        type: isUpdate ? "updatedFlow":"postedFlow",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userId:currentUser.uid,
        flowId:  FlowStore.flowId,
        isSeen:false,
        additionalData: {
          messageNotification: messageNotification,
          photoURL: photoURL,
        },

      };
  
      await firebase.firestore().collection("notifications").add(newNotification);
    };
  

    const addRemixNotification = async (
      sender,
      receiver,
      messageNotification,
      photoURL
    ) => {
      const newNotification = {
        sender: currentUser.displayName,
        receiver: EditorStore.username,
        type:"remixedFlow",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userId:currentUser.uid,
        flowId:  FlowStore.flowId,
        isSeen:false,
        additionalData: {
          messageNotification: "messageNotification",
          photoURL: currentUser.photoURL,
        },

      };
  
      await firebase.firestore().collection("notifications").add(newNotification);
    };
 

    const PostFlowButton = ({ isNewRoom, handleClick, flowId }) => {
    
       if (!isNewRoom) return null;
    
      return (
        <button
          onClick={()=>{
            // handleClick()
            isRemix = false;
            reviewFlowInfo();
          }}
          disabled={isLoading}
          className="green-button" // Assuming 'green-button' is defined in your CSS
          style={{
            width: "100%",
            fontWeight: 700,
            fontSize: 15,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "11px 17px",
            height: 40,
            borderRadius: 10,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            color: "#4FD975",
            marginTop: "10px",
            marginBottom: "0px",
            background: "#1C1C1C",
            border: "1px solid",
            position: 'relative', // Added for spinner positioning
          }}
        >
          {isLoading ? (
            <>
              <span>Saving...</span>
              <div className="spinner" style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                border: '4px solid rgba(0, 0, 0, 0.2)',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                borderLeftColor: '#4FD975',
                animation: 'spin 1s ease infinite',
              }}></div>
            </>
          ) : (
            'Post Flow'
          )}
          <style>{`
             .spinner {
              border: 4px solid white;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border-left-color: #09f;
              animation: spin 1s ease infinite;
            }
    
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}</style>
        </button>
      );
    };



    const PostRemixButton = ({ isNewRoom, handleClick, flowId }) => {
    
      // if (isNewRoom) return null;
   
     return (
       <button
         onClick={()=>{
          isRemix = true;
          isUpdate = false;
          reviewFlowInfo();
        }}
         disabled={isLoading}
         style={{
          width: "100%",
          fontWeight: 700,
          fontSize: 15,
          boxSizing: "border-box",
          display: "flex",
          flexDirection:"row",
          justifyContent: "center",
          alignItems: "center",
          padding: "11px 17px",
          height: 35,
          borderRadius: 10,
          fontFamily: "Quicksand",
          fontStyle: "normal",
          color: "#4FD975",
          marginTop: "10px",
          marginBottom:"0px",
          background: "#1C1C1C",
          border: "1px solid",
           position: 'relative', // Added for spinner positioning
         }}
       >
         {isLoading ? (
           <>
             <span>Saving Remix...</span>
             <div className="spinner" style={{
               position: 'absolute',
               right: '10px',
               top: '7px',
               border: '4px solid rgba(0, 0, 0, 0.1)',
               width: '20px',
               height: '20px',
               borderRadius: '50%',
               borderLeftColor: '#4FD975',
               animation: 'spin 1s ease infinite',
             }}></div>
           </>
         ) : (
           'Post Remix'
         )}
         <style>{`
            .spinner {
             border: 4px solid white;
             width: 30px;
             height: 30px;
             border-radius: 50%;
             border-left-color: #09f;
             animation: spin 1s ease infinite;
           }
   
           @keyframes spin {
             0% {
               transform: rotate(0deg);
             }
             100% {
               transform: rotate(360deg);
             }
           }
         `}</style>
       </button>
     );
   };

    const location = useLocation(); // Gets the location object

    // Determine if the current path is 'room/new'
    const isNewRoom = location.pathname === '/room/new';

    function updateDocumentVisibility(docId, newVisibility) {
      // Assuming the collection name is 'flows', but change this to your specific collection name
      const collectionName = 'flows';
    
      firebase.firestore().collection(collectionName).doc(docId).update({
        visibility: newVisibility
      })
      .then(() => {
        console.log('Document successfully updated!');
        setIsLoading(false);
    
          const newUrl = `/room/${docId}`;
          history.replace(newUrl);
        
        
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
    }

  let createRemix = async (img, html, css, js, flowId) => {



    async function checkAndAddPromoRequestNotification(currentUser, additionalData = {}) {
      // Validate currentUser and necessary properties
      if (!currentUser || !currentUser.displayName) {
          console.error("Invalid currentUser object. Ensure the user is authenticated and has a displayName.");
          return;
      }
  
      // Retrieve flowId from EditorStore
      let flowIdForCollab = id === "new" ? flowId : EditorStore.flowId;
      if (!flowIdForCollab) {
          console.error("Invalid flowId. Ensure EditorStore.flowId is set.");
          return;
      }
  
      // Initialize Firestore
      const db = firebase.firestore();
      const notificationsRef = db.collection('notifications');
  
      try {
          // Query the notifications collection with the additional flowId condition
          const querySnapshot = await notificationsRef
              .where('type', '==', 'promoRequest')
              .where('receiver', '==', currentUser.displayName)
              .where('flowId', '==', flowIdForCollab)
              .where('isAccepted', '==', true)
              .limit(1) // Limit to 1 for efficiency
              .get();

          if (querySnapshot.empty) {
              // No existing document found, proceed to add a new one
              await notificationsRef.add({
                  additionalData: additionalData, // You can pass additional data as needed
                  photoURL: "/images/Flowroom_Default_Avatar.png",
                  collaboratorRole: "creator", // Adjust role as needed
                  flowId: flowIdForCollab, // Use the dynamic flowId from EditorStore
                  isAccepted: true,
                  isSeen: true,
                  receiver: currentUser.displayName,
                  sender: "flowroom",
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  type: "promoRequest",
                  yourRole: ""
              });
              console.log("PromoRequest notification added successfully.");
          } else {
              // Document exists, do nothing
              console.log("PromoRequest notification already exists for this flow. No action taken.");
          }
      } catch (error) {
          // Handle any errors that occur during the process
          alert("Error in checkAndAddPromoRequestNotification:", error);
      }
  }




    let parts = window.location.pathname.split("/");
    // let parts = window.location.pathname.split("/");
    let lastSegment = parts.pop() || parts.pop();
    // if(isRemix == true) {

    // }
      if(PublishMenuStore.description == "") {
        return;
      } 
    let roomDataForRemix = {
      date: new Date(),
      description: EditorStore.isRemixed || isRemix
      ? `${PublishMenuStore.description}`
      : PublishMenuStore.description,
          flowId: flowId,
      thumbnail: EditorStore.flowThumbnailImage,
      animation: [],
      title: PublishMenuStore.title,
      urlHTML: html,
      urlCSS: css,
      urlJS: js,
      urlPhoto: currentUser.photoURL,
      userId: currentUser.uid,
      username: currentUser.displayName,
      visibility:"public",
      modelId: FlowStore.modelId,
      libraries: PublishMenuStore.libraries,
      cssLibraries: PublishMenuStore.cssLibraries,
      isRemixable: PublishMenuStore.isRemixable,
      AITextPopulated: PublishMenuStore.AITextPopulated,
      Contributors: PublishMenuStore.Contributors,
      widgets: WidgetStore.widgets,
      imageIndexes: WidgetStore.imageIndexes,
      textInputIndexes: WidgetStore.textInputIndexes,
      colorSelectIndexes: WidgetStore.colorSelectIndexes,
      sliderIndexSelect: WidgetStore.sliderIndexes,
      switchIndexSelect: WidgetStore.switchIndexes,
      selectIndexSelect: WidgetStore.selectIndexes,
      checkboxIndexSelect: WidgetStore.checkboxIndexes,
      calendarIndexSelect: WidgetStore.calendarIndexes,
      imageUploaderCount: WidgetStore.imageUploaderCount,
      editorLocked: EditorStore.editorLocked,
      editorLockedPassword: EditorStore.editorLockedPassword,
      editorLockedSelected: EditorStore.editorLockedSelected,
      disableComments: EditorStore.disableComments,
      isAR: EditorStore.isAR,
      isVR: EditorStore.isVR,
      isLive: EditorStore.isLive,
      isAI: EditorStore.isAI,
      hashtags: [],
      hasRemixableAI: WidgetStore.hasRemixableAI,
      followers: myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
      flowRemixer: PublishMenuStore.flowRemixer,
      flowIdOP: EditorStore.isRemixed ? EditorStore.flowIdOP : lastSegment,
      collaborators: PublishMenuStore.collaborators,
      isAllDevice: EditorStore.isAllDevice,
      deviceMobile: EditorStore.deviceMobile,
      deviceTablet: EditorStore.deviceTablet,
      deviceDesktop: EditorStore.deviceDesktop,
      paymentOneTimeFee: EditorStore.paymentOneTimeFee,
      paymentSubscription: EditorStore.paymentSubscription,
      paymentFreeTrial: EditorStore.paymentFreeTrial,
      isFeatured: EditorStore.isFeatured,
      remixedBy: currentUser.displayName,
      isRemixed: true,
      isAReShare: false,
      isPinned: false,
      isPaidPromotion: false,
      isReplyInComments: false,
      isPinnedBy: firebase.auth().currentUser.displayName,
      remixedCount: 0,
      isNSFW: EditorStore.isNSFW,
      enableGifRecap: EditorStore.enableGifRecap,
      enableAIGifRecap: EditorStore.enableAIGifRecap,
      enableSpecialShare: EditorStore.enableSpecialShare,
      screencap: EditorStore.screencap,
      browserTags: EditorStore.browserTags,
      paymentPlan: EditorStore.paymentPlan,
      isSafari: EditorStore.isSafari,
      isChrome: EditorStore.isChrome,
      isFirefox: EditorStore.isFirefox,
      isOpera: EditorStore.isOpera,
      isVivaldi: EditorStore.isVivaldi,
      isBrave: EditorStore.isBrave,
      deductCreditFrom: {
        uid: firebase.auth().currentUser.uid,
        numberOfTimes:
          FlowStore.shareWithOwnCredits == true ? FlowStore.numberOfTimes : 0,
      },
      uid: firebase.auth().currentUser.uid,
      loadInFeed: EditorStore.loadInFeed,
      hasAudio: EditorStore.hasAudio,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      uid: firebase.auth().currentUser.uid,
      flowData: EditorStore.flowData,
      flowIsMonetized: EditorStore.flowIsMonetized,
      flowIsMonetizedInitial: EditorStore.flowIsMonetized,
      flowPrice: EditorStore.flowPrice,
      flowPriceInitial: EditorStore.flowPrice,
      flowIsApproved: EditorStore.flowIsApproved,
      flowIsApprovedInitial: EditorStore.flowIsApproved,
      flowFreeTrial: EditorStore.flowFreeTrial,
      flowFreeTrialInitial: EditorStore.flowFreeTrial,
      flowMonthly: EditorStore.flowMonthly,
      flowMonthlyInitial: EditorStore.flowMonthly,
      flowAnnually: EditorStore.flowAnnually,
      flowAnnuallyInitial: EditorStore.flowAnnually,
      paidRemix: EditorStore.paidRemix,
      paidRemixInitial: EditorStore.paidRemix,
      flowPreview: EditorStore.flowPreview,
      flowPreviewInitial: EditorStore.flowPreview,
      flowThumbnail: EditorStore.flowThumbnail,
      flowThumbnailInitial: EditorStore.flowThumbnail,
      flowPerMonth: EditorStore.flowPerMonth,
      flowPerMonthInitial: EditorStore.flowPerMonth,
      flowPerYear: EditorStore.flowPerYear,
      flowPerYearInitial: EditorStore.flowPerYear,
      monetized: EditorStore.monetized,
      monetizedInitial: EditorStore.monetizedl,
      flowDays: EditorStore.flowDays,
      flowDaysInitial: EditorStore.flowDays,
      flowNumberOfUses: EditorStore.flowNumberOfUses,
      flowNumberOfUsesInitial: EditorStore.flowNumberOfUses,
      flowPreviewId: EditorStore.flowPreviewId,
      flowPreviewIdInitial: EditorStore.flowPreviewId,
      loadThumbnail: EditorStore.loadThumbnail,
      loadThumbnailInitial: EditorStore.loadThumbnail,
      isRemixable: EditorStore.isRemixable,
      isRemixableInitial: EditorStore.isRemixable,
      loadThumbnailPaid: EditorStore.loadThumbnailPaid,
      loadThumbnailPaidInitial: EditorStore.loadThumbnailPaid,
      isRemixableByEveryone: EditorStore.isRemixableByEveryone,
      isRemixableByEveryoneInitial: EditorStore.isRemixableByEveryone,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      isRemixableByDevOnlyInitial: EditorStore.isRemixableByDevOnly,
      isRemixableByMeOnly: EditorStore.isRemixableByMeOnly,
      isRemixableByMeOnlyInitial: EditorStore.isRemixableByMeOnlyInitial,

      aiModifyRemix: EditorStore.aiModifyRemix,
      aiModifyRemixInitial: EditorStore.aiModifyRemix,
      editorRemix: EditorStore.editorRemix,
      editorRemixInitial: EditorStore.editorRemix,
      specificRemixing: EditorStore.specificRemixing,
      specificRemixingInitial: EditorStore.specificRemixing,
      remixPrice: EditorStore.remixPrice,
      remixPriceInitial: EditorStore.remixPrice,
      yourRole: EditorStore.yourRole,
      isRemixedFlow:true,
      isBookmarkedFlow:false,
      bookmarkedFlowId:flowId,
      hasSeenAcceptanceStatus:false,
      type:"flow"
    };
    let roomObject = JSON.parse(JSON.stringify(roomDataForRemix));
    //If you're not the owner, you're remixing so first save the post as a remix
    //then call createRoom
    PublishMenuStore.setFlowIdOP(lastSegment);
    firebase
      .firestore()
      .collection("remixes")
      .doc(flowId)
      .set(roomObject, { merge: true })
      .then(() => {
        firebase
          .firestore()
          .collection("flows")
          .doc(PublishMenuStore.flowIdOP)
          .set(
            {
              remixedCount: firebase.firestore.FieldValue.increment(1),
            },
            { merge: true }
          )
          .then(async () => {


              // Replace YOUR_CLOUD_FUNCTION_URL with the actual URL of your deployed Cloud Function
  const cloudFunctionUrl = "https://us-central1-flowroom-fd862.cloudfunctions.net/addToAlgolia";
  
  // Use fetch to make a POST request to the Cloud Function
  fetch(cloudFunctionUrl, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(roomObject),
  })
  .then((response) => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      console.log("Document added to Algolia index:", data);
  })
  .catch((error) => {
      console.error("Error adding document to Algolia:", error);
  });

  await addPostNotification(
    currentUser.displayName,
    null,
    "text",
    currentUser.photoURL
  );


  // if (!screenshotError) {
    // Only execute the following code if there was no error
    // createGifAnimation(flowId); // Uncomment if you want to execute this only when there's no error
    // setIsReviewing(false);
    await PublishMenuStore.startCreateRoom(roomDataForRemix, currentUser);

    FlowStore.setFlowId(flowId);
    FlowStore.setRoom(roomDataForRemix);
    FlowStore.setIsFlowOwner(true);
  
    updateDocumentVisibility(flowId, 'public');
    Publish_Menu_Store.setHasPublished(true);
    
    // addMessageNotification(
    //   null,
    //   currentUser.displayName,
    //   "text",
    //   currentUser.photoURL
    // );

    addRemixNotification();
    processMentions(PublishMenuStore.description);

    // Additional data you might want to include (optional)
    const additionalData = {
        // Add any key-value pairs as needed
    };
    
    // Call the function without awaiting it
    await checkAndAddPromoRequestNotification(currentUser, additionalData);
    
    // setIsLoading(false);
  // } else {
  //   // Code to handle what should happen if there's an error
  // }




        
          });
      });
  };

  // const visibilityQuestion = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://us-central1-flowroom-fd862.cloudfunctions.net/customChat`,
  //       {
  //         method: `POST`,
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           conversation: [
  //             {
  //               role: "system",
  //               content: "You are a bot that responds only with w words",
  //             },
  //             ...newConversation,
  //           ],
  //         }),
  //         mode: "no-cors",
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const responseData = await response.text();

  //     // Append the response to the conversation
  //     setConversation((prev) => [
  //       ...prev,
  //       { role: "system", content: responseData },
  //     ]);
  //   } catch (error) {
  //     console.error("Error sending message:", error);
  //   }
  // };

  // const postNewOrUpdate = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://us-central1-flowroom-fd862.cloudfunctions.net/customChat`,
  //       {
  //         method: `POST`,
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           conversation: [
  //             {
  //               role: "system",
  //               content: "You are a bot that responds only with w words",
  //             },
  //             ...newConversation,
  //           ],
  //         }),
  //         mode: "no-cors",
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const responseData = await response.text();

  //     // Append the response to the conversation
  //     setConversation((prev) => [
  //       ...prev,
  //       { role: "system", content: responseData },
  //     ]);
  //   } catch (error) {
  //     console.error("Error sending message:", error);
  //   }
  // };

  function checkForErrors() {
    const store = EditorStore; // Using shorthand for brevity

    // Check for remix options

    if (store.flowIsMonetized) {
      if (!store.flowPrice || store.flowPrice === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If you've selected to monetize, there should be a price greater than 0."
        );

        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "If you've selected to monetize, there should be a price greater than 0. You must go back and fix this.",
            },
          ]);
        }, [1000]);

        return true; // indicates an error was found
      }

      console.log(
        "flowMonthly value:",
        store.flowPerMonth,
        "Type:",
        typeof store.flowPerMonth
      );
      console.log(
        "flowPerYear value:",
        store.flowPerYear,
        "Type:",
        typeof store.flowPerYear
      );
      console.log(
        "flowFreeTrial value:",
        store.flowFreeTrial,
        "Type:",
        typeof store.flowFreeTrial
      );

      if (!store.flowPerMonth && !store.flowPerYear && !store.flowFreeTrial) {
        PublishMenuStore.setPostErrorMessage(
          "You must select free trial, monthly or yearly for your monetized flow."
        );

        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "You must select free trial, monthly or yearly for your monetized flow. You must go back and fix this.",
            },
          ]);
        }, [1000]);

        return true;
      }

      if (!store.flowThumbnail && !store.flowPreview) {
        PublishMenuStore.setPostErrorMessage(
          "If monetized, you must select either a thumbnail or preview."
        );
        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "If monetized, you must select either a thumbnail or preview. You must go back and fix this.",
            },
          ]);
        }, [1000]);
        return true;
      }

      if (store.flowPreview && !store.flowPreviewId) {
        PublishMenuStore.setPostErrorMessage(
          "If preview is selected, a flow ID must be provided."
        );
        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "If preview is selected, a flow ID must be provided. You must go back and fix this.",
            },
          ]);
        }, [1000]);
        return true;
      }
    }

    if (store.flowFreeTrial) {
      if (!store.flowDays || store.flowDays === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If free trial is selected, days cannot be empty or 0."
        );
        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "If free trial is selected, days cannot be empty or 0. You must go back and fix this.",
            },
          ]);
        }, [1000]);
        return true;
      }
      if (!store.flowNumberOfUses || store.flowNumberOfUses === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If free trial is selected, number of uses cannot be empty or 0."
        );
        setTimeout(() => {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              role: "assistant",
              content:
                "If free trial is selected, number of uses cannot be empty or 0. You must go back and fix this.",
            },
          ]);
        }, [1000]);
        return true;
      }
    }

    if (store.flowPerMonth && (!store.flowPrice || store.flowPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "If 'per month' is selected, a price should be added."
      );
      setTimeout(() => {
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            role: "assistant",
            content:
              "If 'per month' is selected, a price should be added. You must go back and fix this.",
          },
        ]);
      }, [1000]);
      return true;
    }

    if (store.paidRemix && (!store.flowPrice || store.flowPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "If user pays to remix this flow, a price should be added."
      );
      setTimeout(() => {
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            role: "assistant",
            content:
              "If user pays to remix this flow, a price should be added. You must go back and fix this.",
          },
        ]);
      }, [1000]);
      return true;
    }

    if (
      store.aiModifyRemix == false &&
      store.editorRemix == false &&
      store.specificRemixing == false
    ) {
      PublishMenuStore.setPostErrorMessage("You must select a remix option.");
      setTimeout(() => {
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            role: "assistant",
            content:
              "You must select a remix option. You must go back and fix this.",
          },
        ]);
      }, [1000]);
      return true;
    }

    // New condition for remixPrice when paidRemix is selected
    if (store.paidRemix && (!store.remixPrice || store.remixPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "You selected paid remix but did not provide a remix price."
      );
      setTimeout(() => {
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            role: "assistant",
            content:
              "You selected paid remix but did not provide a remix price. You must go back and fix this.",
          },
        ]);
      }, [1000]);
      return true;
    }

    // No errors found
    PublishMenuStore.setPostErrorMessage("");
    return false;
  }
  




useEffect(()=>{
        setConversation([]);
    if (EditorStore.showApprovalChat === true) {

  startReview();
    }
        return () => {
          
        }
},[EditorStore.showApprovalChat])



  
  // useEffect(() => {
  //   let timeoutId;
  
  //   if (EditorStore.showApprovalChat === true) {
  //     timeoutId = setTimeout(() => {
  //       setConversation((prevConversation) => [
  //         ...prevConversation,
  //         {
  //           role: "assistant",
  //           content: "Type Begin Review... to start the review process or ask a question if you need help.",
  //         },
  //       ]);
  //     }, 1000);
  //   } else {
  //     setConversation([]);
  //   }
  
  //   // Cleanup function to clear the timeout
  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [EditorStore.showApprovalChat]);
  






  const startReview = () => {
    setIsReviewing(true);
    setTimeout(() => {
      setConversation((prevConversation) => [
        ...prevConversation,
        {
          role: "assistant",
          content: "Reviewing please wait... ",
        },
      ]);
      handleClick();
    }, 1000);
  };









  async function vision(image, flowId, imageHQ) {
    try {
      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/visionAPI",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-4-vision-preview",
            messages: [
              {
                role: "user",
                content: [
                  {
                    type: "image_url",
                    image_url: {
                      url: `${image}`,
                    },
                  },
                ],
              },
            ],
            max_tokens: 3000,
          }),
        }
      );

      if (!response.ok) {
        EditorStore.setFlowIsApproved(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("vision", data);

      let message = data.choices[0].message.content;

      // Regular expression to match a JSON object possibly within triple backticks and optionally labeled as JSON
      const jsonPattern = /```json\s*\{[\s\S]*?\}```|\{[\s\S]*?\}/;

      // Find the JSON object
      const jsonMatch = message.match(jsonPattern);

      let json = null;
      let messageWithoutJSON = message;

      // If a JSON object is found
      if (jsonMatch) {
        let jsonString = jsonMatch[0];

        // Remove potential backticks and "json" label
        jsonString = jsonString.replace(/```json\s*|\```/g, "").trim();

        try {
          json = JSON.parse(jsonString); // Parse the JSON object
        } catch (e) {
          console.error("Error parsing JSON:", e);
        }

        messageWithoutJSON = message.replace(jsonPattern, "").trim(); // Remove the JSON from the message
      }

      // Printing the results
      console.log("JSON Object:", json);
      console.log("Message without JSON:", messageWithoutJSON);
      //if (!json.reject === true) {
      async function updateThumbnailUrl(flowId, newThumbnailUrl) {
        console.log("newThumbnailUrl", newThumbnailUrl);
        const flowsRef = firestore.collection("flows").doc(flowId);

        try {
          await flowsRef.update({ thumbnail: newThumbnailUrl });
          console.log(`Document with flowId ${flowId} updated successfully`);
        } catch (error) {
          console.error("Error updating document:", error);
        }
      }

      // Example usage
      const newThumbnailUrl = imageHQ; // Replace with your new thumbnail URL
      updateThumbnailUrl(flowId, newThumbnailUrl);
      // }
      console.log("json obj", json);
   
      // Handle the response data
    } catch (error) {
      console.error("Error:", error);
      EditorStore.setFlowIsApproved(true);
    }
  }


  async function saveCodeFiles(html, css, js) {
    console.log(html, css, js);
    const functionUrl = 'https://us-central1-flowroom-fd862.cloudfunctions.net/uploadCodeFilesHTTP';
  
    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ html, css, js }),
        mode: "cors"
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      const { htmlUrl, cssUrl, jsUrl } = result;
      console.log('Files saved:', htmlUrl, cssUrl, jsUrl);
      return { htmlUrl, cssUrl, jsUrl };
    } catch (error) {
      console.error('Error saving files:', error);
      throw error;
    }
  }
  




  const saveRoom = async () => {
    if (!currentUser) {
      AuthenticationStore.setShowModal(true);
      return;
    }



    const cleanStr = (str) => (str ? str.replace(/\s+/g, "") : "");

    // Retrieve HTML, CSS, JS from localStorage
    const currentHTML = localStorage.getItem("currentHTML") || "";
    const currentCSS = localStorage.getItem("currentCSS") || "";
    const currentJS = localStorage.getItem("currentJS") || "";

    // Simplified title and description checks
    const isTitleEmpty = !title?.trim();
    const isDescriptionEmpty = !description?.trim();
    if (isTitleEmpty || isDescriptionEmpty) {
      PublishMenuStore.setShowErrorMessage(true);
      PublishMenuStore.setShowErrorMessageTitle(isTitleEmpty);
      PublishMenuStore.setShowErrorMessageDescription(isDescriptionEmpty);
      PublishMenuStore.setPostErrorMessage(
        "You are missing a title and/or description"
      );
  
      
      setTimeout(async () => {
        const newConversation = [
          ...conversation,
          {
            role: "assistant",
            content: "You are missing a title and/or description",
          },
        ];
        setConversation(newConversation);
        // let thumbnail = await createScreenShot();
        // console.log("thumbnail", thumbnail);
      }, 100);
      setTimeout(()=>{
      setIsLoading(false);
      },500)

      return;
    }

    if (checkForErrors()) {
      return; // Assuming checkForErrors handles its own error messaging
    }

    const html = DHTML_Store.html || "";
    const css = DHTML_Store.css || "";
    const js = DHTML_Store.js || "";


    console.log('html save codes', html);
    console.log('css save codes', css);
    console.log('js save codes', js);


    try {
      let result = await saveCodeFiles(html, css, js);
      console.log('saveCodeFiles result:', result);

      const urlHTML = result.htmlUrl;
      const urlCSS = result.cssUrl;
      const urlJS = result.jsUrl;

      const flowId = determineFlowId(); // Extract to a function for readability
      PublishMenuStore.setCreateFlowId(flowId);
  
      const collabRef = firestore.collection("collaborators");
      const currentUserDisplayName = currentUser.displayName;
  
      const query = collabRef.where(
        "additionalData.collaboratorId",
        "==",
        currentUserDisplayName
      );
      const snapshot = await query.get();
  
      if (!snapshot.empty) {
        const docId = snapshot.docs[0].id;
        await collabRef.doc(docId).update({
          "additionalData.skill": EditorStore.yourRole || "not listed",
        });
      } else {
        await collabRef.add({
          // other fields...
          additionalData: {
            // other fields...
            skill: EditorStore.yourRole || "not listed",
          },
        });
      }
  
      if (isNewRoom == false) {
        if(!isRemix) {

        await createRoom(null, urlHTML, urlCSS, urlJS, flowId);
        } else {
          await createRemix(null, urlHTML, urlCSS, urlJS, flowId);
        }
      } else {
        if(!isRemix) {
        await createRoom(null, urlHTML, urlCSS, urlJS, flowId);
        } else {

          await createRemix(null, urlHTML, urlCSS, urlJS, flowId);
  
        }
      }
      updateUIAfterSave(); // Extract UI updates to a function for readability
      // setHideUpdateBtn(false);
      // setHideRemixBtn(false);
      PublishMenuStore.setTitle(revisedTitle);
      PublishMenuStore.setDescription(revisedDescription);
      // EditorStore.setTitle(revisedTitle);
      // EditorStore.setDescription(revisedDescription);

      // const parts = window.location.pathname.split("/");
      // const lastSegment = parts.pop() || parts.pop();
    
      // if (isNewRoom == false && isUpdate) {
      //   flowId = lastSegment;
      // } 
    
        let thumbnail = await createScreenShotAndCloudFlowReview(flowId);
        console.log("thumbnail", thumbnail);
    

    } catch (error) {
      console.error('Error in saveCodeFiles:', error);
    }


    // const fileNameHTML = getFileName("html");
    // const fileNameCSS = getFileName("css");
    // const fileNameJS = getFileName("js");

    // putObject("html", fileNameHTML, html);
    // putObject("css", fileNameCSS, css);
    // putObject("js", fileNameJS, js);

    // const urlHTML = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameHTML}`;
    // const urlCSS = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameCSS}`;
    // const urlJS = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameJS}`;

   
  };

  const createRoom = async (img, html, css, js, flowId) => {




    async function checkAndAddPromoRequestNotification(currentUser, additionalData = {}) {
      // Validate currentUser and necessary properties
      if (!currentUser || !currentUser.displayName) {
          console.error("Invalid currentUser object. Ensure the user is authenticated and has a displayName.");
          return;
      }
  
      // Retrieve flowId from EditorStore
      let flowIdForCollab = id === "new" ? flowId : EditorStore.flowId;
      if (!flowIdForCollab) {
          console.error("Invalid flowId. Ensure EditorStore.flowId is set.");
          return;
      }
  
      // Initialize Firestore
      const db = firebase.firestore();
      const notificationsRef = db.collection('notifications');
  
      try {
          // Query the notifications collection with the additional flowId condition
          const querySnapshot = await notificationsRef
              .where('type', '==', 'promoRequest')
              .where('receiver', '==', currentUser.displayName)
              .where('flowId', '==', flowIdForCollab)
              .where('isAccepted', '==', true)
              .limit(1) // Limit to 1 for efficiency
              .get();

          if (querySnapshot.empty) {
              // No existing document found, proceed to add a new one
              await notificationsRef.add({
                  additionalData: additionalData, // You can pass additional data as needed
                  photoURL: "/images/Flowroom_Default_Avatar.png",
                  collaboratorRole: "creator", // Adjust role as needed
                  flowId: flowIdForCollab, // Use the dynamic flowId from EditorStore
                  isAccepted: true,
                  isSeen: true,
                  receiver: currentUser.displayName,
                  sender: "flowroom",
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  type: "promoRequest",
                  yourRole: ""
              });
              console.log("PromoRequest notification added successfully.");
          } else {
              // Document exists, do nothing
              console.log("PromoRequest notification already exists for this flow. No action taken.");
          }
      } catch (error) {
          // Handle any errors that occur during the process
          alert("Error in checkAndAddPromoRequestNotification:", error);
      }
  }



    if(PublishMenuStore.description == "") {
      return;
    } 
     console.log('currentUser createRoom', currentUser)
    let userId = EditorStore.userId !== null && EditorStore.userId !== undefined && EditorStore.userId !== "" && !isNewRoom ? EditorStore.userId : currentUser.uid;
    let canvasSize = JSON.parse(localStorage.getItem("canvasSize"));
    let canvasWidth =
      canvasSize && canvasSize.canvasWidth ? canvasSize.canvasWidth : 640;
    let canvasHeight =
      canvasSize && canvasSize.canvasHeight ? canvasSize.canvasHeight : 640;
    let parts = window.location.pathname.split("/");
    // let parts = window.location.pathname.split("/");
    let lastSegment = parts.pop() || parts.pop();
    // if (isNewRoom == false) {
    //   EditorStore.setIsRemixed(true);
    // }
    let roomData = {
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      date: new Date(),
      description: EditorStore.isRemixed || isRemix == true
      ? `${PublishMenuStore.description}`
      : PublishMenuStore.description,
          flowId: flowId,     
      thumbnail:
        "https://" + Config.HOST_CLOUDFRONT + Config.URL_THUMBNAIL_PROCESSING,
      animation: [],
      title: PublishMenuStore.title,
      urlHTML: html,
      urlCSS: css,
      urlJS: js,
      urlPhoto: currentUser.photoURL,
      userId: userId,
      username: EditorStore.username !== null && EditorStore.username !== undefined && EditorStore.username !== "" && !isNewRoom ? EditorStore.username : currentUser.displayName,
      visibility: "approval",
      modelId: FlowStore.modelId,
      libraries: PublishMenuStore.libraries,
      cssLibraries: PublishMenuStore.cssLibraries,
      isRemixable: PublishMenuStore.isRemixable,
      AITextPopulated: PublishMenuStore.AITextPopulated,
      Contributors: PublishMenuStore.Contributors,
      widgets: WidgetStore.widgets,
      imageIndexes: WidgetStore.imageIndexes,
      textInputIndexes: WidgetStore.textInputIndexes,
      colorSelectIndexes: WidgetStore.colorSelectIndexes,
      sliderIndexSelect: WidgetStore.sliderIndexes,
      switchIndexSelect: WidgetStore.switchIndexes,
      selectIndexSelect: WidgetStore.selectIndexes,
      checkboxIndexSelect: WidgetStore.checkboxIndexes,
      calendarIndexSelect: WidgetStore.calendarIndexes,
      imageUploaderCount: WidgetStore.imageUploaderCount,
      editorLocked: EditorStore.editorLocked,
      editorLockedPassword: EditorStore.editorLockedPassword,
      editorLockedSelected: EditorStore.editorLockedSelected,
      disableComments: EditorStore.disableComments,
      isAR: EditorStore.isAR,
      isVR: EditorStore.isVR,
      isLive: EditorStore.isLive,
      isAI: EditorStore.isAI,
      hashtags: [],
      hasRemixableAI: WidgetStore.hasRemixableAI,
      followers: myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
      flowRemixer: PublishMenuStore.flowRemixer,
      flowIdOP: lastSegment, // PublishMenuStore.flowIdOP,
      collaborators: PublishMenuStore.collaborators,
      isAllDevice: EditorStore.isAllDevice,
      deviceMobile: EditorStore.deviceMobile,
      deviceTablet: EditorStore.deviceTablet,
      deviceDesktop: EditorStore.deviceDesktop,
      paymentOneTimeFee: EditorStore.paymentOneTimeFee,
      paymentSubscription: EditorStore.paymentSubscription,
      paymentFreeTrial: EditorStore.paymentFreeTrial,
      isFeatured: EditorStore.isFeatured,
      isPinnedBy: EditorStore.isPinnedBy,
      isRemixed: EditorStore.isRemixed == true ? true : false,
      isAReShare: false,
      isPinned: EditorStore.isPinned,
      isPaidPromotion: EditorStore.isPaidPromotion,
      isReplyInComments: EditorStore.isReplyInComments,
      remixedCount: 0,
      isNSFW: EditorStore.isNSFW,
      enableGifRecap: EditorStore.enableGifRecap,
      enableAIGifRecap: EditorStore.enableAIGifRecap,
      enableSpecialShare: EditorStore.enableSpecialShare,
      screencap: EditorStore.screencap,
      browserTags: EditorStore.browserTags,
      paymentPlan: EditorStore.paymentPlan,
      isSafari: EditorStore.isSafari,
      isChrome: EditorStore.isChrome,
      isFirefox: EditorStore.isFirefox,
      isOpera: EditorStore.isOpera,
      isVivaldi: EditorStore.isVivaldi,
      isBrave: EditorStore.isBrave,
      loadInFeed: EditorStore.loadInFeed,
      hasAudio: false,
      deductCreditFrom: {
        uid: EditorStore.username !== null && EditorStore.uid !== undefined && EditorStore.uid !== "" && !isNewRoom ? EditorStore.uid : currentUser.uid,
        numberOfTimes: FlowStore.shareWithOwnCredits == true ? 1 : 0,
      },
      uid: EditorStore.uid !== null && EditorStore.uid !== undefined && EditorStore.uid !== "" && !isNewRoom ? EditorStore.uid : currentUser.uid,
      flowData: EditorStore.flowData,
      flowIsMonetized: EditorStore.flowIsMonetized,
      flowIsMonetizedInitial: EditorStore.flowIsMonetized,
      flowPrice: EditorStore.flowPrice,
      flowPriceInitial: EditorStore.flowPrice,
      flowIsApproved: EditorStore.flowIsApproved,
      flowIsApprovedInitial: EditorStore.flowIsApproved,
      flowFreeTrial: EditorStore.flowFreeTrial,
      flowFreeTrialInitial: EditorStore.flowFreeTrial,
      flowMonthly: EditorStore.flowMonthly,
      flowMonthlyInitial: EditorStore.flowMonthly,
      flowAnnually: EditorStore.flowAnnually,
      flowAnnuallyInitial: EditorStore.flowAnnually,
      paidRemix: EditorStore.paidRemix,
      paidRemixInitial: EditorStore.paidRemix,
      flowPreview: EditorStore.flowPreview,
      flowPreviewInitial: EditorStore.flowPreview,
      flowThumbnail: EditorStore.flowThumbnail,
      flowThumbnailInitial: EditorStore.flowThumbnail,
      flowPerMonth: EditorStore.flowPerMonth,
      flowPerMonthInitial: EditorStore.flowPerMonth,
      flowPerYear: EditorStore.flowPerYear,
      flowPerYearInitial: EditorStore.flowPerYear,
      monetized: EditorStore.monetized,
      monetizedInitial: EditorStore.monetizedl,
      flowDays: EditorStore.flowDays,
      flowDaysInitial: EditorStore.flowDays,
      flowNumberOfUses: EditorStore.flowNumberOfUses,
      flowNumberOfUsesInitial: EditorStore.flowNumberOfUses,
      flowPreviewId: EditorStore.flowPreviewId,
      flowPreviewIdInitial: EditorStore.flowPreviewId,
      loadThumbnail: EditorStore.loadThumbnail,
      loadThumbnailInitial: EditorStore.loadThumbnail,
      isRemixable: EditorStore.isRemixable,
      isRemixableInitial: EditorStore.isRemixable,
      loadThumbnailPaid: EditorStore.loadThumbnailPaid,
      loadThumbnailPaidInitial: EditorStore.loadThumbnailPaid,
      isRemixableByEveryone: EditorStore.isRemixableByEveryone,
      isRemixableByEveryoneInitial: EditorStore.isRemixableByEveryone,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      isRemixableByDevOnlyInitial: EditorStore.isRemixableByDevOnly,
      isRemixableByMeOnly: EditorStore.isRemixableByMeOnly,
      isRemixableByMeOnlyInitial: EditorStore.isRemixableByMeOnlyInitial,

      aiModifyRemix: EditorStore.aiModifyRemix,
      aiModifyRemixInitial: EditorStore.aiModifyRemix,
      editorRemix: EditorStore.editorRemix,
      editorRemixInitial: EditorStore.editorRemix,
      specificRemixing: EditorStore.specificRemixing,
      specificRemixingInitial: EditorStore.specificRemixing,
      remixPrice: EditorStore.remixPrice,
      remixPriceInitial: EditorStore.remixPrice,
      yourRole: EditorStore.yourRole,
      isBookmarkedFlow:false,
      bookmarkedFlowId:flowId,
      hasSeenAcceptanceStatus:false,
      type:"flow"
    };

    try {
      await PublishMenuStore.startCreateRoom(roomData, currentUser);
      const additionalData = {
        // Add any key-value pairs as needed
      };
      await checkAndAddPromoRequestNotification(currentUser, additionalData);
      

    } catch (error) {
      console.error("Error while creating screenshot:", error);

      
      // Optionally, you can use an alert or any other way to inform the user
      // alert("Failed to create screenshot.");
    }


    
  // Replace YOUR_CLOUD_FUNCTION_URL with the actual URL of your deployed Cloud Function
  const cloudFunctionUrl = "https://us-central1-flowroom-fd862.cloudfunctions.net/addToAlgolia";
  
  // Use fetch to make a POST request to the Cloud Function
  fetch(cloudFunctionUrl, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(roomData),
  })
  .then((response) => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      console.log("Document added to Algolia index:", data);
  })
  .catch((error) => {
      console.error("Error adding document to Algolia:", error);
  });



    // createSearch();
   let screenshotError = false; // Flag to indicate if there was an error

try {
  // await createScreenShot(flowId);
} catch (error) {
  screenshotError = true; // Set the flag to true if there was an error
  setTimeout(() => {
    const newConversation = [
      ...conversation,
      {
        role: "assistant",
        content: "Error while creating screenshot.",
      },
    ];
    setConversation(newConversation);
    // Handle the error, e.g., by updating the UI or logging
  }, 100);
}

if (!screenshotError) {
  // Only execute the following code if there was no error
  // createGifAnimation(flowId); // Uncomment if you want to execute this only when there's no error
  // setIsReviewing(false);

  FlowStore.setFlowId(flowId);
  FlowStore.setRoom(roomData);
  FlowStore.setIsFlowOwner(true);

  updateDocumentVisibility(flowId, 'approval');
  Publish_Menu_Store.setHasPublished(true);
  processMentions(PublishMenuStore.description);
  await addPostNotification(
    null,
    currentUser.displayName,
    "text",
    currentUser.photoURL
  );

    // Additional data you might want to include (optional)
  
  
  // Call the function without awaiting it
  // setIsLoading(false);
} else {
  // Code to handle what should happen if there's an error
}


    // setDisableSend(false);
    // EditorStore.setIsSaving(false);
  };

  function determineFlowId() {
    let flowId;
    const parts = window.location.pathname.split("/");
    const lastSegment = parts.pop() || parts.pop();

    if (isNewRoom == false && isUpdate) {
      flowId = lastSegment;
    } else {
      const hashids = new Hashids(uuid(), 6);
      flowId = hashids.encode(1, 2, 3);
    }

    return flowId;
  }

  function updateUIAfterSave() {
    const updateElementVisibility = (id, displayStyle) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = displayStyle;
      }
    };

    updateElementVisibility("postbtn", "none");
    updateElementVisibility("savechanges", "flex");
    updateElementVisibility("deletebtn", "flex");
  }

  const handleClick = () => {
    if (currentUser !== null && currentUser.displayName !== null) {
      // console.log("remixedby", remixedByArr);
      let parts = window.location.pathname.split("/");
      // let parts = window.location.pathname.split("/");
      let lastSegment = parts.pop() || parts.pop();
      if (FlowStore.isFlowOwner === false && lastSegment !== "new") {
        PublishMenuStore.setFlowIdOP(lastSegment);
        saveRoom();
        PublishMenuStore.setShowShare(true);
        if (document.getElementById("postbtn")) {
          document.getElementById("postbtn").style.display = "none";
        }
        if (document.getElementById("savechanges")) {
          document.getElementById("savechanges").style.display = "flex";
        }
        if (document.getElementById("deletebtn")) {
          document.getElementById("deletebtn").style.display = "flex";
        }
      } else {
        saveRoom();
      }
    } else {
      AuthenticationStore.setShowModal(true);
      WidgetStore.setShowPostModal(false);
    }
  };

 

  let getMimeType = (type) => {
    let mimeType = "";
    switch (type) {
      case "html":
        mimeType = "text/html";
        break;
      case "css":
        mimeType = "text/css";
        break;
      case "js":
        mimeType = "text/javascript";
        break;
    }

    return mimeType;
  };
  let getFileName = (type) => {
    let extension = "";
    switch (type) {
      case "html":
        extension = ".html";
        break;
      case "css":
        extension = ".css";
        break;
      case "js":
        extension = ".js";
        break;
    }
    let hashids = new Hashids(uuid(), 6);
    let fileName = hashids.encode(1, 2, 3) + extension;
    return fileName;
  };

  const createScreenShotAndCloudFlowReview = async (flowId) => {
    try {
      // console.log("[Publish_Save] createScreenShot response data low:", data);

const baseUrl = 'https://flowroom.com/screenshot/';
const encodedUrl = `${baseUrl}?flow=${encodeURIComponent(flowId)}&username=${encodeURIComponent(currentUser.displayName)}`;
const urlHigh = `https://us-central1-flowroom-fd862.cloudfunctions.net/screenThumbnailWithVision/?url=${encodedUrl}&username=${encodeURIComponent(currentUser.displayName)}&privacy=${encodeURIComponent(PublishMenuStore.visibility)}&sender=${encodeURIComponent('flowroom')}&receiver=${encodeURIComponent(currentUser.displayName)}&commentContent=${encodeURIComponent(null)}&photoURL=${encodeURIComponent(null)}&flowId=${encodeURIComponent(flowId)}`;

// const urlHigh = `http://127.0.0.1:5002/flowroom-fd862/us-central1/screenThumbnailWithVision/?url=${encodeURIComponent(encodedUrl)}`;
// const encodedUrl = encodeURIComponent(`${baseUrl}?flow=${flowId}`);


      // const urlHigh = `http://127.0.0.1:5002/flowroom-fd862/us-central1/screenThumbnailWithVision/?url=http://staging.flowroom.com.s3-website-us-east-1.amazonaws.com/screenshot/?flow=${flowId}`;

      const responseHigh = await fetch(urlHigh, { mode: "cors" });
      if (!responseHigh.ok) {
        throw new Error(`HTTP error! status: ${responseHigh.status}`);
      }
      const dataHigh = await responseHigh.json();
      console.log(
        "[Publish_Save] createScreenShot response data high:",
        dataHigh
      );
      // await vision(data.thumbnailUrl, flowId, dataHigh.thumbnailUrl);
      //  }, []);
    } catch (error) {
      console.log("[Publish_Save] createScreenShot error:", error);

      EditorStore.setFlowIsApproved(false);
    }
  };

 

  let titleRef = React.useRef();
  let descriptionRef = React.useRef();
  let tagsRef = React.useRef();
  useEffect(() => {
    if (currentUser && currentUser.displayName) {
      console.log("Updating displayName:", currentUser.displayName);
      setDisplayName(currentUser.displayName);
      console.log("props room", props);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [currentUser]);

  useEffect(() => {
    if (PublishMenuStore.showErrorMessage == true) {
      setTitleErrorOutline(true);

      if (PublishMenuStore.description == "") {
        setDescriptionErrorOutline(true);
      }
      PublishMenuStore.setShowErrorMessage(false);
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [PublishMenuStore.showErrorMessage]);

  function capitalizeFirstLetter(string) {
    return string !== undefined
      ? string.charAt(0).toUpperCase() + string.slice(1)
      : "";
  }

  const handleCheckboxToggle = (checkboxName) => {
    console.log("Checkbox clicked:", checkboxName);
    setSelectedCheckbox(checkboxName);
    PublishMenuStore.setVisibility(checkboxName);
  };
  const onChangeTitle = (event) => {
    // console.log("[publish_menu] onChangeTitle:", titleRef.current.value);
    setTitle(event.target.value);
    PublishMenuStore.setTitle(event.target.value);
    PublishMenuStore.setPostErrorMessage("");
    PublishMenuStore.setShowErrorMessageTitle(false);
  };

  const onChangeDescription = (event) => {
    // console.log(
    //   "[publish_menu] onChangeDescription:",
    //   descriptionRef.current.value
    // );
    setDescription(event.target.value);
    PublishMenuStore.setDescription(event.target.value.trim());
    PublishMenuStore.setPostErrorMessage("");
    PublishMenuStore.setShowErrorMessageDescription(false);
  };


  // useEffect(() => {
  //   if (EditorStore.title) {
  //     setTitle(EditorStore.title);
  //     PublishMenuStore.setTitle(EditorStore.title);
  //   }
  //   if (EditorStore.description) {
  //     setDescription(EditorStore.description);
  //     PublishMenuStore.setDescription(EditorStore.description);
  //   }
  //   return () => {
  //     // Clean up or cancel any ongoing tasks or subscriptions here
  //   };
  // }, [EditorStore.title, EditorStore.description]);

  //'calc(100vh - 201px)'
  useEffect(() => {
    if (!FlowStore.isFlowOwner && !isNewRoom) {
      const unsubscribe = firebase.firestore().collection("notifications")
        .where("isAccepted", "==", true)
        .where("type", "==", "collabRequest")
        .where("flowId", "==", EditorStore.flowId)
        .where("receiver", "==", currentUser.displayName)
        .onSnapshot(snapshot => {
          if (!snapshot.empty) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        }, error => {
          console.log("Error fetching permissions: ", error);
          setIsAuthorized(false);
        });

      return () => unsubscribe(); // Cleanup subscription on unmount
    } else {
      setIsAuthorized(true); // Default to true if user is the owner or it's a new room
    }
  }, [EditorStore.flowId]);



  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // backgroundColor: "#222222",
        width: "100%",
      }}
    >
      {EditorStore.isSaving == false ? (
        <div>
          <div
            className="publish-wrap-wrap"
            style={{
              // height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "0px",
              justifyContent: "center",
              alignItems: "center",
              // position: "fixed",
            }}
          >
            <div
              style={{
                justifyContent: "space-between",
                width: "calc(100% - 0px)",
                backgroundColor: "#1C1C1C",
                padding: 0,
                alignItems: "center",
                display: "flex",
                position: "absolute",
                height: 55,
                borderBottom: "2px solid #3E3E3E",
                marginBottom: 20,
                top: 0,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  style={{
                    display: "block",
                    right: "10px",
                    top: "10px",
                    cursor: "pointer",
                    zIndex: 1000,
                    marginRight: 5,
                  }}
                  src="/images/save-flow-selected.svg"
                />
                <p
                  style={{
                    color: "white",
                    fontSize: 17,
                    fontWeight: "bold",
                    fontFamily: "quicksand",
                    margin: 0,
                  }}
                >
                  Save
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <img
                  style={{
                    display: "block",
                    right: "10px",
                    top: "10px",
                    cursor: "pointer",
                    zIndex: 1000,
                    marginRight: 18,
                    height:18
                  }}
                  src="/images/question-circle.svg"
                />
                <img
                  style={{
                    display: "block",
                    right: "5px",
                    top: "0px",
                    cursor: "pointer",
                    zIndex: 1000,
                    position:'relative',
                    height:18
                  }}
                  onClick={() => {
                    if( document.getElementById(
                      "vertical-tab-post-tab"
                    ) !== null){
                    document.getElementById(
                      "vertical-tab-post-tab"
                    ).style.background = "transparent";
                    }
                    TabStore.setIsPostSelected(false);
                    EditorStore.setShowPostModal(false);
                    TabStore.setClosePanel(true);

                    // document.getElementsByClassName(
                    //   "editor-menu"
                    // )[0].style.paddingLeft = "78px";
                    if (
                      document.getElementById("horizontal-tab-remix-tab") !==
                      null
                    ) {
                      document.getElementById(
                        "horizontal-tab-remix-tab"
                      ).style.background = "none";
                    }

                    // document.getElementsByClassName(
                    //   "remix-menu"
                    // )[0].style.display = "none";
                  }}
                  src="/images/close-circle.svg"
                />
              </div>
            </div>

            <div
              id="save-wrap"
              style={{
                display: "flex",
                overflowY: "hidden",
                top: "10px",
                width: "100%",
              }}
            >

              <div
                className="publish-wrap"
                style={{
                  display:
                    EditorStore.showApprovalChat == false ? "flex" : "none",
                }}
              > 
                   <SimpleBar
      id="publish-content"
      style={{
        borderBottom: "1px solid rgb(62, 62, 62)",
        width: "100%",
        height: "100%",
        maxHeight: 370,
        borderRadius: 10,
      }}
    >

                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        // height: "calc(100vh / 2.2)",
                        width: "calc(100% - 40px)",
                        borderRadius: 10,
                        paddingBottom: 0,
                        paddingTop: WidgetStore.showPostModal ? 30 : 25,
                        marginTop: WidgetStore.showPostModal ? 20 : 0,
                        margin: "auto",
                      }}
                    >
                      <div
                        className="publish-title"
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          // padding: "7px 10px",
                          borderRadius: 10,
                          paddingTop: 5,
                          paddingLeft: 0,
                        }}
                      >
                        <p style={{color:'#FFF', fontFamily:'quicksand', fontSize:11, marginBottom:10, marginTop:10}}>Title:</p>

                        <input
                          className="formInputOutline"
                          maxLength="100" // Add the maximum character limit here
                          style={{
                            fontSize: 14,
                            color: "white",
                            fontFamily: "quicksand",
                            height: 42,
                            width: "100%",
                            background: "#2C2C2C",
                            borderRadius: 10,
                            border: "none",
                            outline: "none",
                            paddingLeft: 10,
                            fontWeight: "normal",
                            outline:
                              PublishMenuStore.showErrorMessageTitle == true
                                ? "2px solid red"
                                : "none",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: 15,
                              overflow: "hidden",
                              paddingLeft: 15,
                            },
                          }}
                          error={TitleError}
                          helperText={titleHelperText}
                          id="title"
                          type="text"
                          variant="standard"
                          placeholder="Type in a new title"
                          inputRef={titleRef}
                          onChange={(e) => {
                            onChangeTitle(e);
                            setTitle(e.target.value);
                            EditorStore.setTitle(e.target.value);
                            if (e.target.value.length === e.target.maxLength) {
                              setTitleErrorMessage(
                                "Maximum character limit reached!"
                              );
                            } else {
                              setTitleErrorMessage(""); // Clear the error message
                            }
                          }}
                          onFocus={() => {
                            setTitleError(false);
                            setTitleHelperText("");
                          }}
                          value={title}
                        />
                        <p style={{ color: "red", fontFamily: "quicksand" }}>
                          {titleErrorMessage}
                        </p>
                        {showReviseTitleField ? (<div>
                          <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:11, marginBottom:10, marginTop:10}}>Suggested Title:</p>
                          <input
                          className="formInputOutline"
                          maxLength="100" // Add the maximum character limit here
                          style={{
                            fontSize: 14,
                            color: "white",
                            fontFamily: "quicksand",
                            height: 42,
                            width: "100%",
                            background: "#2C2C2C",
                            borderRadius: 10,
                            border: "none",
                            outline: "none",
                            paddingLeft: 10,
                            fontWeight: "normal"
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: 15,
                              overflow: "hidden",
                              paddingLeft: 15,
                            },
                          }}
                          type="text"
                          variant="standard" 
                          value={revisedTitle}
                        />
                        <div style={{display:'flex'}}>
                        <div onClick={()=>{

                          PublishMenuStore.setTitle(revisedTitle);
                          EditorStore.setTitle(revisedTitle);
                          setTitle(revisedTitle);
                          setShowReviseTitleField(false);
                        }} style={{color:'rgb(79, 217, 117)',border:'1px solid rgb(79, 217, 117)', height:30, width:130, borderRadius:7, display:'flex', justifyContent:'center', alignItems:'center', marginTop:10, cursor:'pointer', marginRight:10}}><p style={{color:'rgb(79, 217, 117)', fontSize:11, fontFamily:'quicksand'}}>Use suggested title</p></div>
                        <div onClick={()=>{
                          setShowReviseTitleField(false);
                          setUseMyOwnTitle(true);
                        }}  style={{color:'rgb(79, 217, 117)',border:'1px solid rgb(79, 217, 117)', height:30, width:130, borderRadius:7, display:'flex', justifyContent:'center', alignItems:'center', marginTop:10, cursor:'pointer'}}><p style={{color:'rgb(79, 217, 117)', fontSize:11, fontFamily:'quicksand'}}>Use my own</p></div>
                        </div>
                        </div>):""}
                        {FlowStore.isFlowOwner == false &&
                        isNewRoom == false ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              maxWidth: 650,
                              margin: "auto",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 15,
                                color: "white",
                                fontFamily: "quicksand",
                                /* margin-top: 8px; */
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: '10px'
                              }}
                            >
                              You can change this title or keep it the same for
                              you remix.
                            </p>
                            <div
                              onClick={() => {
                                setTitle("");
                              }}
                              style={{
                                height: 35,
                                width: 70,
                                margin: 10,
                                fontFamily: "Quicksand",
                                fontSize: 10,
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                width: 80,
                                borderRadius: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "lighter",
                              }}
                              className="green-button"
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                Clear
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: 251,
                          margin: "auto",
                          marginBottom: 17,
                        }}
                      >
                        <div
                          style={{
                            height: 120,
                            width: 115,
                            backgroundColor: "#222",
                            borderRadius: 15,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "none",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          <p
                            style={{
                              color: "rgb(79, 217, 117)",
                              fontFamily: "quicksand",
                              fontSize: 20,
                            }}
                          >
                            Add
                          </p>
                          <p
                            style={{
                              color: "rgb(79, 217, 117)",
                              fontFamily: "quicksand",
                              fontSize: 20,
                            }}
                          >
                            thumbnail
                          </p>
                        </div>
                        <div
                          style={{
                            height: 120,
                            width: 115,
                            backgroundColor: "#222",
                            borderRadius: 15,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "none",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          <p
                            style={{
                              color: "rgb(79, 217, 117)",
                              fontFamily: "quicksand",
                              fontSize: 20,
                            }}
                          >
                            Add GIF
                          </p>
                          <p
                            style={{
                              color: "rgb(79, 217, 117)",
                              fontFamily: "quicksand",
                              fontSize: 20,
                            }}
                          >
                            preview
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "auto",
                          maxWidth: 250,
                        }}
                      >
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 10,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                                                                          <p style={{color:'#FFF', fontFamily:'quicksand', fontSize:11, marginBottom:10, marginTop:10}}>Description:</p>
                                                                          <p style={{color:'#FFF', fontFamily:'quicksand', fontSize:11, marginBottom:10, marginTop:10}}>(Try to be detailed, include hashtags etc.)</p>


                        <textarea
                          className="formInputOutline"
                          maxLength="5000" // Example: set max character limit to 200
                          style={{
                            height: "auto",
                            width: "calc(100% - 0px)",
                            backgroundColor: "#2C2C2C",
                            borderRadius: 10,
                            overflow: "hidden",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: 80,
                            border: "none",
                            resize: "none",
                            padding: 10,
                            marginLeft: 0,
                            color: "white",
                            fontSize: 15,
                            color: "white",
                            fontFamily: "quicksand",
                            outline: "none",
                            fontWeight: "normal",
                            outline:
                              PublishMenuStore.showErrorMessageDescription ==
                              true
                                ? "2px solid red"
                                : "none",
                            overflowY: "scroll",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: 15,
                              overflow: "hidden",
                              paddingLeft: 20,
                            },
                          }}
                          error={descriptionError}
                          helperText={descriptionHelperText}
                          id="description"
                          type="text"
                          multiline
                          rows={3}
                          variant="standard"
                          placeholder="Description"
                          inputRef={descriptionRef}
                          onChange={(e) => {
                            if (
                              e.target.value.length ===
                              e.target.maxLength - 1
                            ) {
                              setDescriptionErrorMessage(""); // Clear the error just before hitting max length
                            } else if (
                              e.target.value.length === e.target.maxLength
                            ) {
                              setDescriptionErrorMessage(
                                "Maximum character limit reached!"
                              );
                            } else {
                              setDescriptionErrorMessage(""); // Clear the error message otherwise
                            }

                            onChangeDescription(e);
                            setDescription(e.target.value);
                            EditorStore.setDescription(e.target.value);

                            // setRevisedDescription(e.target.value);

                          }}
                          onFocus={() => {
                            setDescriptionError(false);
                            setDescriptionHelperText("");
                          }}
                          value={description}
                        />
                        <p style={{ color: "red", fontFamily: "quicksand" }}>
                          {DescriptionErrorMessage}
                        </p>
                        {showReviseDescriptionField ? ( 
                        <div>
                         <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:11, marginBottom:10, marginTop:10}}>Suggested Description:</p>
                        <textarea
                          className="formInputOutline"
                          maxLength="5000" // Example: set max character limit to 200
                          style={{
                            height: "auto",
                            width: "calc(100% - 0px)",
                            backgroundColor: "#2C2C2C",
                            borderRadius: 10,
                            overflow: "hidden",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: 100,
                            border: "none",
                            resize: "none",
                            padding: 10,
                            marginLeft: 0,
                            color: "white",
                            fontSize: 15,
                            color: "white",
                            fontFamily: "quicksand",
                            outline: "none",
                            fontWeight: "normal",
                            overflowY: "scroll",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: 15,
                              overflow: "hidden",
                              paddingLeft: 20,
                            },
                          }}
                          type="text"
                          multiline
                          rows={3}
                          variant="standard"
                          value={revisedDescription}
                        />
                        <div style={{display:'flex'}}>
                        <div onClick={()=>{
                            PublishMenuStore.setDescription(revisedDescription);
                            setDescription(revisedDescription);
                            setShowReviseDescriptionField(false);
                        }} style={{color:'rgb(79, 217, 117)',border:'1px solid rgb(79, 217, 117)', height:30, width:170, borderRadius:7, display:'flex', justifyContent:'center', alignItems:'center', marginTop:10, cursor:'pointer', marginRight:10}}><p style={{color:'rgb(79, 217, 117)', fontSize:11, fontFamily:'quicksand'}}>Use suggested description</p></div>
                        <div onClick={()=>{
                          setShowReviseDescriptionField(false);
                          setUseMyOwnDescription(true);

                        }} style={{color:'rgb(79, 217, 117)',border:'1px solid rgb(79, 217, 117)', height:30, width:130, borderRadius:7, display:'flex', justifyContent:'center', alignItems:'center', marginTop:10, cursor:'pointer'}}><p style={{color:'rgb(79, 217, 117)', fontSize:11, fontFamily:'quicksand'}}>Use my own</p></div>
                        </div>
                        </div>):""}
                        <p style={{color:'#F06263', fontSize:12, fontFamily:'quicksand', marginTop:15, marginBottom:15}}>{PublishMenuStore.postErrorMessage}</p>

                        {FlowStore.isFlowOwner == false &&
                          !isNewRoom ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              maxWidth: 650,
                              margin: "auto",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 15,
                                color: "white",
                                fontFamily: "quicksand",
                                /* margin-top: 8px; */
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight:'10px'
                              }}
                            >
                              You can change this description or keep it the
                              same for your remix.
                            </p>
                            <div
                              onClick={() => {
                                setDescription("");
                              }}
                              style={{
                                margin: 10,
                                fontFamily: "Quicksand",
                                fontSize: 10,
                                borderRadius: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 35,
                                width: 80,
                                margin:0
                              }}
                              className="green-button"
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontWeight: "lighter",
                                  fontSize: 14,
                                }}
                              >
                                {" "}
                                Clear
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
               
                      {FlowStore.isFlowOwner ? (
                        <CollaboratorList
                          flowId={FlowStore.flowId}
                          userId={displayName}
                        />
                      ) : (
                        <div>
                          {isNewRoom ? (
                            <div
                              onClick={() => {
                                PublishMenuStore.setPostErrorMessage(
                                  "You must save this flow first before adding a collaborator"
                                );
                                setTimeout(() => {
                                  PublishMenuStore.setPostErrorMessage("");
                                }, 5000);
                              }}
                              style={{
                                backgroundImage:
                                  "url(/images/add-collaborator.svg)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: 154,
                                height: "38px",
                                width: "155px",
                                cursor: "pointer",
                                marginLeft: 0,
                                marginTop: 15,
                              }}
                            ></div>
                          ) : (
                            ""
                          )}
                          {/* <p style={{color:'#F06263', fontSize:12, fontFamily:'quicksand', marginTop:15, marginBottom:15}}>{PublishMenuStore.postErrorMessage}</p> */}
                        </div>
                      )}
          
                      <div
                        style={{
                          // background: "#2D2A2B",
                          width: "257px",
                          margin: "auto",

                          paddingBottom: 10,
                        }}
                      >
                        {/* <Credits /> */}
                      </div>
                      {(() => {
                        let parts = window.location.pathname.split("/");
                        // let parts = window.location.pathname.split("/");
                        let lastSegment = parts.pop() || parts.pop();

                        if (
                          FlowStore.isFlowOwner === true ||
                          isNewRoom
                        ) {
                          return (
                            <div style={{ height: "100%", width: "100%" }}>
                              
                              {AuthenticationStore.isDevAccount == true ? (
                                <div
                                  style={{ marginTop: 20, marginBottom: 25 }}
                                >
                                  {/* <TechnicalInformation /> */}
                                </div>
                              ) : (
                                ""
                              )}
                              {AuthenticationStore.isDevAccount == true ? (
                                <div
                                  style={{ marginTop: 20, marginBottom: 25 }}
                                >
                                  {/* <Sharing /> */}
                                </div>
                              ) : (
                                ""
                              )}
          
                            </div>
                          );
                        }
                      })()}
                    </div>

                    <div
                      onClick={() => {
                        if (
                          document.getElementsByClassName(
                            "publish-visibility"
                          )[0].style.zIndex == 100
                        ) {
                          document.getElementsByClassName(
                            "publish-visibility"
                          )[0].style.zIndex = 100000;
                        }
                      }}
                      className="publish-visibility"
                      style={{
                        marginBottom: 0,

                        padding: "0px 20px",
                        width: "100%",
                        paddingBottom:20
                      }}
                    >

                      <div
                        style={{
                          marginTop: WidgetStore.setShowPostModal ? 0 : 0,
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 15,
                          }}
                        >
                          <SquareCheckbox
                            onToggle={() => handleCheckboxToggle("public")}
                            isChecked={selectedCheckbox === "public"}
                          />
                          <p
                            style={{ fontFamily: "quicksand", color: "white", fontSize:12 }}
                          >
                            Everyone (public, followers, community)
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 15,
                          }}
                        >
                          <SquareCheckbox
                            onToggle={() => handleCheckboxToggle("feed")}
                            isChecked={selectedCheckbox === "feed"}
                          />
                          <p
                            style={{ fontFamily: "quicksand", color: "white",  fontSize:12 }}
                          >
                            My Feed Only (myself & followers)
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 15,
                          }}
                        >
                          <SquareCheckbox
                            onToggle={() => handleCheckboxToggle("private")}
                            isChecked={selectedCheckbox === "private"}
                          />
                          <p
                            style={{ fontFamily: "quicksand", color: "white",  fontSize:12 }}
                          >
                            Private (only me)
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 15,
                          }}
                        >
                          <SquareCheckbox
                            onToggle={() => handleCheckboxToggle("unlisted")}
                            isChecked={selectedCheckbox === "unlisted"}
                          />
                          <p
                            style={{ fontFamily: "quicksand", color: "white",  fontSize:12 }}
                          >
                            Unlisted (myself and anyone I share with)
                          </p>
                        </div>
                      </div>

                
                    </div>
                  </div>
               </SimpleBar>

                <div
                  style={{
                    position: "relative",
                    bottom: 0,
                    isplay: "flex",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Publish_Save
                    height={50}
                    width={"100%"}
                    maxWidth={350}
                    deleteWidth={55}
                    isMobile={false}
                    props={props}
                  />
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display:
                    EditorStore.showApprovalChat == true ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                <button
                  onClick={() => {
                    handleClick();
                  }}
                  className="green-button"
                  style={{
                    width: 100,
                    borderRadius: 15,
                    marginLeft: 10,
                    marginTop: 20,
                    height: 30,
                  }}
                >
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "quicksand",
                      color: "black",
                    }}
                  >
                    back
                  </p>
                </button>
                <div
                  style={{
                    display: "flex",
                    height: "calc(100% - 177px)",
                    border: "0px solid red",
                    borderRadius: 10,
                    margin: "auto",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <ul
                      id="approval-chat-convo"
                      style={{
                        height: "calc(100vh - 548px)",
                        overflowY: "scroll",
                        marginBottom: 20,
                      }}
                    >
                      {conversation.map((msg, index) => {
                        if (msg.type !== "image") {
                          return (
                            <li
                              key={index}
                              className={msg.role}
                              style={{
                                color: "#000000",
                                fontFamily: "quicksand",
                                listStyle: "none",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: 5,
                                margin: 10,
                                fontSize: 14,
                              }}
                            >
                              {msg.content}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={index}
                              className={msg.role}
                              style={{
                                color: "white",
                                fontFamily: "quicksand",
                              }}
                            >
                              <img
                                src={`${msg.content}`}
                                style={{
                                  height: 385,
                                  width: 220,
                                  borderRadius: 10,
                                  margin: 10,
                                  objectFit: "contain",
                                }}
                              />
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: EditorStore.showApprovalChat == true ? "none" : "flex",
                height: 110,
                width: "calc(100% - 40px)",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                bottom:20,
                zIndex:1000
              }}
            >
              {isAuthorized &&
              !isNewRoom ? (
                <button
                  onClick={() => {
                    isRemix = false;
                    isUpdate = true;
                    reviewFlowInfo();

                  }}
                  className="green-button"
                  style={{
                    width: "100%",
                    fontWeight: 700,
                    fontSize: 15,
                    boxSizing: "border-box",

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 17px",
                    height: 35,
                    borderRadius: "10px",
                    fontFamily: "Quicksand",
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#000",
                    position:'relative'
                  }}
                  // variant={"contained"}
                >
                     {isLoadingUpdate ? (
           <>
             <span>Saving Update...</span>
             <div className="spinner" style={{
               position: 'absolute',
               right: '10px',
               top: '7px',
               border: '4px solid rgba(0, 0, 0, 0.1)',
               width: '20px',
               height: '20px',
               borderRadius: '50%',
               borderLeftColor: '#1c1c1c',
               animation: 'spin 1s ease infinite',
             }}></div>
           </>
         ) : (
           'Update'
         )}
         <style>{`
            .spinner {
             border: 4px solid white;
             width: 30px;
             height: 30px;
             border-radius: 50%;
             border-left-color: #09f;
             animation: spin 1s ease infinite;
           }
   
           @keyframes spin {
             0% {
               transform: rotate(0deg);
             }
             100% {
               transform: rotate(360deg);
             }
           }
         `}</style>
                </button>
              ) : (
                ""
              )}
              {isAuthorized &&
              !isNewRoom && hideRemixBtn == false ? (
                 <PostRemixButton isNewRoom={isNewRoom} handleClick={()=>{

                  setIsLoading(true);
                  isRemix = true;
                  reviewFlowInfo();
               }} />
              ) : (
                ""
              )}
              {!isAuthorized &&
              !isNewRoom ? (
           
                <PostRemixButton isNewRoom={isNewRoom} handleClick={()=>{

                  setIsLoading(true);
                  isRemix = true;
                  isUpdate = false;
                  handleClick();
               }} />
              ) : (
                ""
              )}
  
              <PostFlowButton isNewRoom={isNewRoom} handleClick={()=>{
                  setIsLoading(true);
                  isUpdate = false;
                  //  handleClick();
  
                  reviewFlowInfo();
              }} />

              
            </div>
          </div>

          <ReactModal
            isOpen={notEnough}
            ariaHideApp={false}
            closeTimeoutMS={500}
            style={{
              overlay: {
                padding: 0,
                zIndex: 1000000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.3)",
              },
              content: {
                position: "relative",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                border: "0px solid rgb(64, 255, 232)",
                backgroundColor: "#3F3F3E",
                borderRadius: "15px",
                outline: "none",
                padding: "0px",
                zIndex: 1000000,
                margin: "auto",
                width: "calc(100% / 1.1)",
                maxWidth: "560px",
                paddingBottom: 20,
                // maxHeight: "370px",
              },
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "20px 25px",
              }}
            >
              <img
                src="/images/block-icon.svg"
                style={{
                  height: 120,
                  width: 120,
                  margin: "auto",
                  marginBottom: 10,
                  marginTop: 35,
                }}
              />
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Not enough credits
              </p>
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  textAlign: "center",
                  color: "#FFFFFF",
                  opacity: 0.6,
                }}
              >
                You do not have enough credits to post this flow with your own
                credits.
              </p>
              <div
                onClick={() => {
                  setNotEnough(false);
                  FlowStore.setShareWithOwnCredits(false);
                }}
                style={{
                  backgroundColor: "transparent",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                  marginTop: 50,
                  cursor: "pointer",
                  maxWidth: 526,
                  height: 50,
                  border: "1px solid #4FD975",
                }}
              >
                <p
                  style={{
                    color: "rgb(79, 217, 117)",
                    fontFamily: "quicksand",
                    fontSize: 19,
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={hasEnoughCredit}
            ariaHideApp={false}
            closeTimeoutMS={500}
            style={{
              overlay: {
                padding: 0,
                zIndex: 1000000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.8)",
              },
              content: {
                position: "relative",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                border: "0px solid rgb(64, 255, 232)",
                backgroundColor: "#3F3F3E",
                overflow: "auto",
                borderRadius: "15px",
                outline: "none",
                padding: "0px",
                zIndex: 1000000,
                margin: "auto",
                width: "calc(100% / 1.1)",
                maxWidth: "560px",
                paddingBottom: 20,
                // maxHeight: "370px",
              },
            }}
          >
            <div>
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: 400,
                  marginBottom: 20,
                  paddingBottom: 20,
                  marginTop: 20,
                }}
              >
                IMPORTANT!
              </p>
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 14,
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: 400,
                  marginBottom: 20,
                  paddingBottom: 20,
                }}
              >
                You chose to post a flow with your own credits, do you want this
                post to show up in your feed? Warning: Sharing a post in your
                feed with your own credits means anyone who sees it in the feed
                can use it. Please make sure this is something you want to. This
                is NOT the same as Sharing a flow with your own credits. If you
                post this flow without selecting this option, you can “Share
                with your own credits” which allows you to share a posted flow
                with someone else without everyone in your feed or who comes
                across an embed. There are good reasons for posting a flow to
                your feed with your own credits.
              </p>
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 14,
                  textAlign: "center",
                  maxWidth: 400,
                  margin: "auto",
                  width: "100%",
                }}
              >
                If you wish to continue, specify the amount of times a user can
                have where your credits are used. If you do not specify any
                amount, the default will be 1
              </p>
              <input
                onChange={(e) => {
                  if (
                    e.target.value.trim() == "" ||
                    e.target.value.trim() <= 0
                  ) {
                    FlowStore.setNumberOfTimes("");
                    //Error can not be empty or 0 or lower
                  } else {
                    FlowStore.setNumberOfTimes(e.target.value.trim());
                  }
                }}
                type="text"
                placeholder=""
                style={{ marginTop: 20, marginBottom: 20 }}
                value={FlowStore.numberOfTimes}
              />
              <div
                onClick={() => {
                  if (FlowStore.numberOfTimes === "") {
                    FlowStore.setNumberOfTimes(1);
                    setHasEnoughCredit(false);
                  } else {
                    FlowStore.setShareWithOwnCredits(true);

                    setHasEnoughCredit(false);
                  }
                }}
                style={{
                  backgroundColor: "#6371F6",
                  height: 35,
                  width: "calc(100% - 50px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 15,
                  marginTop: 50,
                  cursor: "pointer",
                  margin: "auto",
                  marginBottom: 20,
                }}
              >
                <p
                  style={{
                    color: "#222222",
                    fontFamily: "quicksand",
                    fontSize: 19,
                  }}
                >
                  Continue
                </p>
              </div>
              <div
                onClick={() => {
                  setHasEnoughCredit(false);
                  FlowStore.setShareWithOwnCredits(false);
                }}
                style={{
                  backgroundColor: "rgb(240, 98, 99)",
                  height: 35,
                  width: "calc(100% - 50px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 15,
                  marginTop: 50,
                  cursor: "pointer",
                  margin: "auto",
                }}
              >
                <p
                  style={{
                    color: "#222222",
                    fontFamily: "quicksand",
                    fontSize: 19,
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={saveAsReply}
            ariaHideApp={false}
            closeTimeoutMS={500}
            style={{
              overlay: {
                padding: 0,
                zIndex: 1000000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.8)",
              },
              content: {
                position: "relative",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                border: "0px solid rgb(64, 255, 232)",
                backgroundColor: "#3F3F3E",
                overflow: "auto",
                borderRadius: "15px",
                outline: "none",
                padding: "0px",
                zIndex: 1000000,
                margin: "auto",
                width: "calc(100% / 1.1)",
                maxWidth: "560px",
                paddingBottom: 20,
                // maxHeight: "370px",
              },
            }}
          >
            <div>
              <p
                onClick={() => {
                  setSaveAsReply(false);
                }}
              >
                close
              </p>

              <p>save as reply</p>
            </div>
          </ReactModal>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 198px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {EditorStore.warning == false ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontFamily: "quicksand",
                  fontSize: 47,
                }}
              >
                Saving
              </p>
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div>Warning</div>
          )}
        </div>
      )}
    </div>
  );
});

export default React.memo(PublishMenu);
