import React, { useState, useContext, useRef } from "react";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "../../Components/Home/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import ReactModal from "react-modal";
import LoginM from "./LoginM";
import { observer } from "mobx-react-lite";
const PasswordReset = observer(() => {
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const validatePassword = () => {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    // Check for minimum length of 8 characters
    if (password.length < 8) {
      setMessage("Password must be at least 8 characters long.");
      return false;
    }
  
    // Check for regex pattern match
    if (!regex.test(password)) {
      setMessage("Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.");
      return false;
    }
  
    // Check if passwords match
    if (password !== reEnterPassword) {
      setMessage("Passwords do not match.");
      return false;
    }
  
    // // Optional: Check for unique characters - assuming you want to ensure the password contains no repeated characters
    // const uniqueChars = new Set(password);
    // if (uniqueChars.size < password.length) {
    //   setMessage("Password must contain unique characters without repetitions.");
    //   return false;
    // }
  
    return true;
  };
  

  const handleSubmit = () => {
    setMessage("");

    if (!validatePassword()) {
      return; // Stop the submission if validation fails
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const actionCode = urlParams.get("oobCode");

    firebase
      .auth()
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        setMessage("Your password has been reset successfully!");
        setPasswordReset(true);
      })
      .catch((error) => {
        // Customize error message for a bad action code or other errors
        const errorMessage = error.code === 'auth/invalid-action-code'
          ? "The link is invalid or expired. Please request a new password reset."
          : error.message;
        setMessage(errorMessage);
      });
  };

  return (
    <div className="" style={{ flex: "1", display: "flex", height: 'calc(100vh = 70px)', width: '100%', backgroundColor: '#323232' }}>
      {!passwordReset ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 64px)",
            width: "calc(100% - 50px)",
            margin: 'auto'
          }}
        >
          <div
            style={{
              backgroundColor: "#1C1C1C",
              width: "100%",
              maxWidth: "500px",
              borderRadius: 15,
              padding: 25,
            }}
          >
            <p
              style={{
                color: "#FEFFFE",
                fontFamily: "quicksand",
                fontSize: 20,
              }}
            >
              Password Reset
            </p>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              className="formInputOutline"
              style={{
                border: "0px solid #545454",
                overflow: "hidden",
                borderRadius: 20,
                backgroundColor: "#2D2A2B",
                color: "white",
                height: 37,
                width: "100%",
                paddingLeft: 10,
                fontFamily: "quicksand",
                marginBottom: 20,
                marginTop: 20,
              }}
              placeholder="New password"
            />
            <input
              onChange={(e) => setReEnterPassword(e.target.value)}
              type="password"
              id="reEnterPassword"
              className="formInputOutline"
              style={{
                border: "0px solid #545454",
                overflow: "hidden",
                borderRadius: 20,
                backgroundColor: "#2D2A2B",
                color: "white",
                height: 37,
                width: "100%",
                paddingLeft: 10,
                fontFamily: "quicksand",
                marginBottom: 20,
              }}
              placeholder="Re-enter new password"
            />
            <p
              style={{
                fontFamily: "quicksand",
                color: "rgb(240, 98, 99)",
                fontSize: 14,
              }}
            >
              {message}
            </p>
            <div
              style={{
                backgroundColor: "#F06263",
                width: "100%",
                maxWidth: 500,
                height: 33,
                borderRadius: 10,
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                fontFamily: "quicksand",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              <p
                style={{
                  color: "#222222",
                  fontSize: 18,
                  fontFamily: "quicksand",
                }}
              >
                Submit
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 70px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 50,
          }}
        >
          <div
            style={{
              height: 250,
              width: "100%",
              maxHeight: 300,
              maxWidth: 400,
              background: "#1C1C1C",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: 15,
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              color="#FEFFFE"
              style={{ display: "flex", marginBottom: 10, fontSize: 80 }}
            />
            <p
              style={{
                color: "#4FD975",
                fontFamily: "quicksand",
                fontSize: 20,
              }}
            >
              Password Reset Successful!
            </p>
          </div>
        </div>
      )}
        <ReactModal
              ariaHideApp={false}
              closeTimeoutMS={500}
              isOpen={AuthenticationStore.showModal}
              style={{
                overlay: {
                  padding: 0,
                  zIndex: 1000000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(0, 0, 0, 0.8)",
                },
                content: {
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  border: "0px solid rgb(64, 255, 232)",
                  backgroundColor: "#3F3F3E",
                  overflow: "hidden",
                  borderRadius: "15px",
                  outline: "none",
                  padding: "0px",
                  zIndex: 1000000,
                  margin: "auto",
                  width: "calc(100% / 1.1)",
                  maxWidth: 570,
                  // maxHeight: WidgetStore.currentModalHeight,
                },
              }}
            >
              <div
                onClick={() => {
                  AuthenticationStore.setShowModal(false);
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  margin: 10,
                  cursor: "pointer",
                  zIndex: 1,
                }}
              >
                {/* <FontAwesomeIcon
                  icon={faTimesCircle}
                  color="#F06263"
                  size={"4x"}
                  style={{
                    display: "block",
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                /> */}
                    <img src="../images/close-circle.svg"   style={{
                  display: "block",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}/>
              </div>

             <LoginM />
            </ReactModal>
    </div>
  );
});

export default React.memo(PasswordReset);
