import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import "react-tabs/style/react-tabs.css";
import "react-web-tabs/dist/react-web-tabs.css";
import { AuthenticationStoreContext } from "../../../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../../../store/EditorStore.js";
import { FlowStoreContext } from "../../../../store/FlowStore.js";
import { RemixMenuDesktopStoreContext } from "../../../../store/Remix_Menu_Desktop.js";
import { RouteStoreContext } from "../../../../store/RouteStore.js";
const TagsInput = React.lazy(() => import("../../TagsInput.js"));

let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let lastSegment = parts.pop() || parts.pop();

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);
let renderPreiviewContent = (htmlResponse, cssResponse, jsResponse) => {
  let base_tpl =
    "<!doctype html>\n" +
    "<html>\n\t" +
    "<head>\n\t\t" +
    '<meta charset="utf-8">\n\t\t' +
    "<title>Flowroom</title>\n\n\t\t\n\t" +
    "</head>\n\t" +
    "<body>\n\t\n\t" +
    "</body>\n" +
    "</html>";

  let remix = `
      .border {
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%);
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
        background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
        border-radius: 5px;
        padding: 10px;
        -webkit-animation: dash 5s linear infinite;
        animation: dash 5s linear infinite;
      }
      @-webkit-keyframes dash {
        to {
          background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
        }
      }
      @keyframes dash {
        to {
          background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
        }
      }
    `;

  let fontawesome =
    '<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous"></link> ';

  let prepareSource = () => {
    //console.log("[Editor] prepareSource");

    let html = htmlResponse;
    let css = cssResponse;
    let js = jsResponse;

    //console.log("[Editor] prepareSource html:", html, "css:", css, "js:", js);

    let dhtmlObj = { html: html, css: css, js: js };
    //localStorage.setItem("dhtml", JSON.stringify(dhtmlObj));

    let JSflowroom = '<script src="../js/flowroom.js"></script>';
    let freesound =
      '<script type="text/javascript" src="../js/freesound.js"></script>';
    let howlerjs = '<script src="../js/howler.min.js"></script>';
    let prefixfree =
      '<script src="https://cdnjs.cloudflare.com/ajax/libs/prefixfree/1.0.7/prefixfree.min.js"></script>';
    let normalize =
      '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"></link>';

    let src = base_tpl.replace("</body>", html + "</body>");
    let srcCSS = "<style>" + css + remix + "</style>";
    // src = src.replace(
    //   "</head>",
    //   isNormalizeFlow
    //     ? normalize
    //     : '<link rel="stylesheet" href=""></link>' +
    //         srcCSS +
    //         howlerjs +
    //         freesound +
    //         JSflowroom +
    //         prefixfree +
    //         fontawesome +
    //         "</head>"
    // );
    let babel =
      '<script src="https://unpkg.com/babel-standalone@6.15.0/babel.min.js"></script>';

    let script = "";
    // if (
    //   librariesFlow.length !== 0 &&
    //   librariesFlow !== undefined &&
    //   librariesFlow !== null
    // ) {
    //   for (let i = 0; i < librariesFlow.length; i++) {
    //     script = script + `<script src="${librariesFlow[i]}"></script>`;
    //   }
    // }
    // if (isBabel === true) {
    //   script =
    //     script +
    //     '<script src="https://unpkg.com/babel-standalone@6.15.0/babel.min.js"></script>';
    // }

    let scriptTag = '<script type="text/babel">';

    let srcJS = "<script>" + js + "</script>";
    //scriptTag +js + "</script>";
    // : "<script>" + js + "</script>";

    src = src.replace(
      "</body>",
      script +
        srcJS +
        '<script src="../js/isLoaded.js">' +
        "</script>" +
        "</body>"
    );
    return src;
  };

  try {
    let iframe = document.getElementById("flow-preview");

    if (iframe !== null) {
      ///FlowStore.setSource("");
      let source = prepareSource();
      let iframe_doc = iframe.contentDocument;

      iframe_doc.open();
      iframe_doc.write(source);
      iframe_doc.close();

      // iframe.src = "";
      //iframe.setAttribute("srcdoc", source);
    }
  } catch (e) {}
};
const SetupOptions = observer((props) => {
  const EditorStore = useContext(EditorStoreContext);
  const RouteStore = useContext(RouteStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const Remix_Menu_Desktop = useContext(RemixMenuDesktopStoreContext);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    // alert(isMobile);
    setOpenClose(props.openModalError);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [props.openModalError]);
  return (
    <ReactModal
      isOpen={AuthenticationStore.saveMessage}
      ariaHideApp={false}
      closeTimeoutMS={500}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          backgroundColor: "#3F3F3E",
          overflow: "auto",
          borderRadius: "15px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "90%",
          maxWidth: "560px",
          maxHeight: "750px",
        },
      }}
    >
      <div
        onClick={() => {
          AuthenticationStore.setShowModal(false);
          RouteStore.setCurrent("");
        }}
        style={{
          position: "absolute",
          right: 0,
          margin: 10,
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#F06263"
          size={"4x"}
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            top: 10,
          }}
        />
      </div>
      <div></div>
    </ReactModal>
  );
});

export default React.memo(SetupOptions);
