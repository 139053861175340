import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class ProfileStore {
  constructor() {
    makeAutoObservable(this);
  }

  isShowModal = false;
  flowId = "";
  room = {};
  followerSelected = false;
  followingSelected = false;
  likeSelected = false;
  flowSelected = false;
  profileItemSelected = "";
  reloadRooms = false;
  setIsShowModal(isShow) {
    this.isShowModal = isShow;
  }

  setFlowId(flowId) {
    this.flowId = flowId;
  }

  setRoom(room) {
    this.room = room;
  }

  setProfileItemSelected(param) {
    this.profileItemSelected = param;
  }
  setLoadRooms(bool) {
    this.reloadRooms = bool;
  }
}

export const ProfileStoreContext = createContext(new ProfileStore());
