import { default as React, useRef, useState } from "react";

const VoiceRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [textInput, setTextInput] = useState("");
  const [outputUrl, setOutputUrl] = useState("");
  const [mp3URL, setMP3URL] = useState(null);

  const mediaRecorder = useRef(null); // useRef to hold the MediaRecorder instance

  const convertAndPlayAudio = async (audioBlob) => {
    try {
      const audioFile = new File([audioBlob], "recording.webm", {
        type: "audio/webm",
      });
      const jobResponse = await fetch("https://api.cloudconvert.com/v2/jobs", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjM3M2FiZDJlNDA5YTQyM2FkYTJjZWQ4ZDY1NzM5MWY5YzlkY2JmMDRlOGI2NjY1ZDM3ODUyM2U0NDliOTY0YWY4YmQyOGE0ZjEzYTYwZTAiLCJpYXQiOjE3MDQzNDgwNjUuOTY1MDU2LCJuYmYiOjE3MDQzNDgwNjUuOTY1MDU4LCJleHAiOjQ4NjAwMjE2NjUuOTQxMzM1LCJzdWIiOiI2NjcwNDIyMSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.KjxYT6BuT6PYdA11GZRGkjr5pN_xXMr_4MkfdHJqiru3SttKRKndRR5coIAPsFVJxQMpXGryGD98VcPY84YO26-ntDUuFjnpQySBxWiiaK5jAaQkHK7NKdO5NETSLJaWedVlNudIhRqAleVFLJOzzXGg7MaXe3s5erAAB7FiVJO710i7ihXKUCMV7OSK3bLwfkRioKyaQc6dQ8drgnl7DiNp1vu6nVqTiWXmZfPRQhPuNbdzrD7QC6asR-esU19WN_tTDOZBXvpBW0nWUeCze2Of_rb5-_Dcwqy6zl4K2aH_qF8O-lO9smWzgjNXm4T20Qa-XWavvP_M3QR6M1DcUdc6s9Rqhl9GEWT5Wf3qMjWNgQcjjUemOAQSIqZnO8HOdX_eHyNG7idV9a_z5hqB__b5jEw6-h2WYMeWiolVHrEVrfXTKZVuVIv5FwxoPBMlfYBJw91uLQJmIVGUXOBzwzkWF9Y_4MvemoJ6Z9FRCb2nODfotHwWamIYJIiam7yq8dA7-tkfafuIHh5N3XjJTcukks3-rUPIJsYmJJk2_n9iOK7QvpPoyM4LWizKlIFdNGk6Yo3nDcvxohDa2zGlkZrv6xUfjHSSlVswb2xXC47tYQTRLDad1qQZIWcvl34emLGkctd4il03ChO-BYmu96RiQxrGAKANn8Jy7ZlIr5U",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tasks: {
            "upload-my-file": { operation: "import/upload" },
            "convert-my-file": {
              operation: "convert",
              input: "upload-my-file",
              input_format: "webm",
              output_format: "wav",
            },
            "export-my-file": {
              operation: "export/url",
              input: "convert-my-file",
            },
          },
        }),
      });
      if (!jobResponse.ok) throw new Error("Job creation failed");
      const jobData = await jobResponse.json();

      const uploadTask = jobData.data.tasks.find(
        (task) => task.name === "upload-my-file"
      );
      const uploadUrl = uploadTask.result.form.url;
      const formData = new FormData();
      Object.entries(uploadTask.result.form.parameters).forEach(
        ([key, value]) => {
          formData.append(key, value);
        }
      );
      formData.append("file", audioFile);
      await fetch(uploadUrl, { method: "POST", body: formData });

      let isJobCompleted = false;
      let exportTask;
      while (!isJobCompleted) {
        const statusResponse = await fetch(
          `https://api.cloudconvert.com/v2/jobs/${jobData.data.id}`,
          {
            headers: {
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjM3M2FiZDJlNDA5YTQyM2FkYTJjZWQ4ZDY1NzM5MWY5YzlkY2JmMDRlOGI2NjY1ZDM3ODUyM2U0NDliOTY0YWY4YmQyOGE0ZjEzYTYwZTAiLCJpYXQiOjE3MDQzNDgwNjUuOTY1MDU2LCJuYmYiOjE3MDQzNDgwNjUuOTY1MDU4LCJleHAiOjQ4NjAwMjE2NjUuOTQxMzM1LCJzdWIiOiI2NjcwNDIyMSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.KjxYT6BuT6PYdA11GZRGkjr5pN_xXMr_4MkfdHJqiru3SttKRKndRR5coIAPsFVJxQMpXGryGD98VcPY84YO26-ntDUuFjnpQySBxWiiaK5jAaQkHK7NKdO5NETSLJaWedVlNudIhRqAleVFLJOzzXGg7MaXe3s5erAAB7FiVJO710i7ihXKUCMV7OSK3bLwfkRioKyaQc6dQ8drgnl7DiNp1vu6nVqTiWXmZfPRQhPuNbdzrD7QC6asR-esU19WN_tTDOZBXvpBW0nWUeCze2Of_rb5-_Dcwqy6zl4K2aH_qF8O-lO9smWzgjNXm4T20Qa-XWavvP_M3QR6M1DcUdc6s9Rqhl9GEWT5Wf3qMjWNgQcjjUemOAQSIqZnO8HOdX_eHyNG7idV9a_z5hqB__b5jEw6-h2WYMeWiolVHrEVrfXTKZVuVIv5FwxoPBMlfYBJw91uLQJmIVGUXOBzwzkWF9Y_4MvemoJ6Z9FRCb2nODfotHwWamIYJIiam7yq8dA7-tkfafuIHh5N3XjJTcukks3-rUPIJsYmJJk2_n9iOK7QvpPoyM4LWizKlIFdNGk6Yo3nDcvxohDa2zGlkZrv6xUfjHSSlVswb2xXC47tYQTRLDad1qQZIWcvl34emLGkctd4il03ChO-BYmu96RiQxrGAKANn8Jy7ZlIr5U",
            },
          }
        );
        const statusData = await statusResponse.json();
        if (statusData.data.status === "error") throw new Error("Job failed");

        isJobCompleted = statusData.data.status === "finished";
        if (isJobCompleted) {
          exportTask = statusData.data.tasks.find(
            (task) => task.name === "export-my-file"
          );
          if (exportTask.status !== "finished") isJobCompleted = false;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      if (exportTask && exportTask.result && exportTask.result.files) {
        const mp3Url = exportTask.result.files[0].url;
        console.log("mp3url", mp3Url);
        const mp3Response = await fetch(mp3Url);
        const mp3Blob = await mp3Response.blob();
        const audioUrl = URL.createObjectURL(mp3Blob);
        const audio = new Audio(audioUrl);
        setMP3URL(mp3URL); // Set the state here
        audio.play();
      }
    } catch (error) {
      console.error("Conversion error:", error);
    }
  };
  const pollForCompletion = async (predictionId) => {
    const pollInterval = 5000; // Poll every 5 seconds
    let completedData;

    while (true) {
      const statusResponse = await fetch(
        `http://127.0.0.1:5002/flowroom-fd862/us-central1/voiceClone?predictionId=${predictionId}`
      );
      completedData = await statusResponse.json();

      if (
        completedData.status === "succeeded" ||
        completedData.status === "failed"
      ) {
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, pollInterval));
    }

    return completedData;
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => setAudioBlob(e.data);
      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = () => {
        setIsRecording(false);
        mediaRecorder.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        // Ensure convertAndPlayAudio is called after state update
        setAudioBlob((prevBlob) => {
          convertAndPlayAudio(prevBlob);
          return prevBlob;
        });
      };
    }
  };

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // First, upload the mp3Blob_ to a server or cloud storage
      // and get the URL of the uploaded file
      // This step depends on your backend setup or cloud storage service

      // Prepare the JSON payload
      const payload = {
        sample_file_url:
          "https://eu-central.storage.cloudconvert.com/tasks/6c795cf8-2431-4542-bae1-f60ea7b3fa3b/recording.wav?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=cloudconvert-production%2F20240104%2Ffra%2Fs3%2Faws4_request&X-Amz-Date=20240104T100437Z&X-Amz-Expires=86400&X-Amz-Signature=a15679c156dcc859eb6bfb4f03a0c6dc698b13ff3793419564168ab0daf586de&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22recording.wav", // URL of the uploaded audio file
        voice_name: "brandontest", // Replace with your desired voice name
      };

      // Send the request to the Firebase function (which calls Play.ht API)
      const response = await fetch(
        "http://127.0.0.1:5002/flowroom-fd862/us-central1/voiceClone",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Other headers as needed
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response Data", responseData);

      // Process the response data as needed
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };

  async function uploadAudioAndGetUrl(blob) {
    // Upload the blob to your server or cloud storage
    // Return the URL of the uploaded file
    // This function needs to be implemented based on your backend setup
  }

  return (
    <div>
      {isRecording ? (
        <button onClick={stopRecording}>Stop Recording</button>
      ) : (
        <button onClick={startRecording}>Start Recording</button>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={textInput}
          onChange={handleTextInputChange}
          placeholder="Enter text here"
        />
        <button type="submit">Submit</button>
      </form>
      {outputUrl && <audio controls src={outputUrl} />}
    </div>
  );
};

const VoiceClone = () => {
  const [showRecord, setShowRecord] = useState(false);
  return (
    <div
      className=""
      style={{
        flex: "1",
        display: "flex",
        height: 300,
        width: "100%",
        maxWidth: 400,
        backgroundColor: "#1C1C1C",
        position: "absolute",
        zIndex: 10000,
        top: 170,
        left: 444,
        borderRadius: 15,
        alignItems: "center",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <p
        style={{
          fontFamily: "quicksand",
          color: "white",
          margin: "0px",
          height: 20,
        }}
      >
        Voice Clone
      </p>
      <div
        onClick={() => {
          setShowRecord(true);
        }}
        style={{
          height: 20,
          width: 70,
          backgroundColor: "green",
          right: 20,
          position: "absolute",
          borderRadius: 5,
          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontFamily: "quicksand",
            color: "white",
            margin: "0px",
            height: 20,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Add +
        </p>
      </div>
      {showRecord == false ? (
        <ul
          style={{
            height: "100%",
            backgroundColor: "red",
            width: "100%",
            borderRadius: 5,
            marginTop: 10,
            listStyle: "none",
            padding: 10,
            overflowY: "scroll",
          }}
        >
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
          <li style={{ padding: 10, backgroundColor: "blue", marginTop: 5 }}>
            <p
              style={{ fontSize: 14, fontFamily: "quicksand", color: "white" }}
            >
              Something
            </p>
          </li>
        </ul>
      ) : (
        <div>
          <VoiceRecorder />
        </div>
      )}
    </div>
  );
};

export default React.memo(VoiceClone);
