import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

function useFlowroom() {
  let [remixImageList, setRemixImageList] = useState([]);
  let [remixTextList, setRemixTextList] = useState([]);

  useEffect(() => {
    // return console.log("[Flowroom.ts] useEffect remixImageList:", remixImageList);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [remixImageList]);

  return { remixImageList, setRemixImageList, remixTextList, setRemixTextList };
}

export let Flowroom = createContainer(useFlowroom);
