import { faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as React, useContext, useEffect, useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import Select, { components } from "react-select";
import { FlowStoreContext } from "../../store/FlowStore";
import { WidgetStoreContext } from "../../store/WidgetStore";
import { firebase } from "../firebase/firebase";
import DeleteRemixWidgetModal from "./DeleteRemixWidgetModal.js";
import LockRemixWidgetModal from "./LockRemixWidgetModal.js";
import UnlockRemixWidgetModal from "./UnlockRemixWidgetModal.js";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
const CaretDownIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faTriangle}
      color="#A962F1"
      style={{
        display: "block",
        position: "absolute",
        right: "10px",
        top: "10px",
        transform: "rotate(180deg)",
        fontSize: "15px",
      }}
    />
  );
};
const customStyles = {
  indicatorsContainer: (provided, state) => ({
    backgroundColor: "#2d2a2b",
    borderRadius: 4,
    color: "white",
  }),

  control: (_, { selectProps: { width, backgroundColor, height } }) => ({
    height: 35,
    backgroundColor: "#2d2a2b",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 15,
    padding: "0px",
    fontFamily: "quicksand",
    color: "white",
    minHeight: 3,
    //width: "calc(100% - 50px)",

    overflow: "hidden",
  }),

  dropdownIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      backgroundColor: "#2d2a2b",
      // height: 30,
      fontSize: 20,

      borderRadius: 4,
      padding: "0px",
      color: "white",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      fontFamily: "quicksand",
      padding: "0px 10px",
      // width: "calc(100% - 50px)",
    };
  },
  menu: (provided, state) => {
    return {
      // ...provided,
      fontFamily: "quicksand",
      backgroundColor: "#2d2a2b",
      overflow: "hidden",
      fontSize: 20,
      position: "absolute",
      top: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,

      // borderRadius: 15,
      // width: "calc(100% - 50px)",
      // color: state.selectProps.menuColor,
    };
  },
  placeholder: (provided, state) => {
    return {
      fontFamily: "quicksand",
      color: "white",
      alignItems: "auto",
      justifyContent: "space-between",
      display: "flex",
      fontSize: 20,
      // width: "calc(100% - 50px)",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      // marginTop: 5,
      fontSize: "10px",
      height: 90,
      // width: "calc(100% - 50px)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      // height: 30,
      fontSize: 20,

      padding: "0px",
      color: "white",
      fontFamily: "quicksand",
      // width: "calc(100% - 50px)",
    };
  },
  option: (base) => ({
    ...base,
    height: "100%",
    width: "calc(100vw - 50px)",
  }),
};
const SelectComponent = (props) => {

  return (
    <div
      style={{
        display:"flex",
        height:'100%',
        backgroundColor:'#1c1c1c'
      }}
    >
        <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10, marginTop: 70,
    width: '100%',
    overflowY: 'scroll' }}>
          <Select
            defaultValue={1}
            closeMenuOnSelect={true}
            components={{ Placeholder, DropdownIndicator }}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                // ...theme.colors,
                primary25: "#A962F1",
                primary: "#6371F6",
                // color: "white",
              },
            })}
            display={"flex"}
            // width={250}
            height={30}
            placeholder={props.placeholder}
            options={[...props.items]}
            onChange={(e) => {
              console.log('item selected', props.items)
              try {
                let iframe = document.getElementById("undefined_output_frame");
                if (iframe !== undefined && iframe !== null) {
                  let iframe = document.getElementById(
                    "undefined_output_frame"
                  );
                  if (iframe !== undefined && iframe !== null) {
                    iframe.contentWindow[props.functionName](e.value)

                }
              }
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </div>
   
    </div>
    
  );
};

export default React.memo(SelectComponent);
