import { makeAutoObservable } from "mobx";
import { createContext } from "react";
class RouteStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentRoute = "home";
  isMoving = false;
  lastPage = "";
  setCurrent(route) {
    this.currentRoute = route;
  }

  setIsMoving(bool) {
    this.isMoving = bool;
  }
  setLastPage(page) {
    this.lastPage = page;
  }
}

export const RouteStoreContext = createContext(new RouteStore());
