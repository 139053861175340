import React, { createContext, useState, useEffect, useContext } from 'react';
import { firebase } from "../firebase/firebase";
import { AuthContext, AuthProvider } from "../../Components/Home/AuthContext";


// Create the context
export const NotificationContext = createContext();

// Provider component
export const NotificationProvider = ({ children }) => {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const [isPromo, setIsPromo] = useState(true);
  const [promo, setPromo] = useState(true);

  const [isFollows, setIsFollows] = useState(true);
  const [isLikes, setIsLikes] = useState(true);
  const [isComments, setIsComments] = useState(true);
  const [isMessages, setIsMessages] = useState(true);
  const [isRemixes, setIsRemixes] = useState(true);
  const [isPromotes, setIsPromotes] = useState(true);
  const [isLikesComments, setIsLikesComments] = useState(true);
  const [isPinFlow, setIsPinFlow] = useState(true);
  const [isCollaborationRequest, setIsCollaborationRequest] = useState(true);
  const [isLikesReplyComments, setIsLikesReplyComments] = useState(true);

  const [isUpdatedFlow, setIsUpdatedFlow] = useState(true);
  const [isPostedFlow, setIsPostedFlow] = useState(true);
  const [isMentionsYouInCommentReply, setIsMentionsYouInCommentReply] = useState(true);
  const [isMentionsYouInDescription, setIsMentionsYouInDescription] = useState(true);
  const [isMentionsYouComments, setIsMentionsYouComments] = useState(true);
  const [isCommentsReply, setIsCommentsReply] = useState(true);
  const [isLikesFlow, setIsLikesFlow] = useState(true);
  const [isSiteAnnounced, setIsSiteAnnounced] = useState(true);
  useEffect(()=>{
    if(document.getElementsByTagName('html') !== null) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
    },[])

  // Function to load settings from Firestore

  // Function to load settings from Firestore
  const loadSettings = async () => {
    try {
      // Query the "users" collection where "username" matches the currentUser's displayName
      const usersRef = firebase.firestore().collection('users');
      const querySnapshot = await usersRef.where('username', '==', currentUser?.displayName).get();

      // Ensure there is at least one matching document
      if (!querySnapshot.empty) {
        // Get the first matched document (assuming unique usernames)
        const userDoc = querySnapshot.docs[0];
        const data = userDoc.data();

        setIsFollows(data?.isFollows ?? false);
        setIsLikes(data?.isLikes ?? false);
        setIsComments(data?.isComments ?? false);
        setIsMessages(data?.isMessages ?? false);
        setIsRemixes(data?.isRemixes ?? false);
        setIsPromotes(data?.isPromotes ?? false);
        setIsLikesComments(data?.isLikesComments ?? false);
        setIsPinFlow(data?.isPinFlow ?? false);
        setIsCollaborationRequest(data?.isCollaborationRequest ?? false);
        setIsLikesReplyComments(data?.isLikesReplyComments ?? false);
        
        // Load new states
        setIsUpdatedFlow(data?.isUpdatedFlow ?? false);
        setIsPostedFlow(data?.isPostedFlow ?? false);
        setIsMentionsYouInCommentReply(data?.isMentionsYouInCommentReply ?? false);
        setIsMentionsYouInDescription(data?.isMentionsYouInDescription ?? false);
        setIsMentionsYouComments(data?.isMentionsYouComments ?? false);
        setIsCommentsReply(data?.isCommentsReply ?? false);
        setIsLikesFlow(data?.isLikesFlow ?? false);
        setIsSiteAnnounced(data?.isSiteAnnounced ?? false);
      } else {
        console.log('No matching user document found.');
      }
    } catch (error) {
      console.error('Error loading user settings:', error);
    }
  };



    // Effect to load settings when the component mounts
    useEffect(() => {
      if (currentUser) {
        loadSettings();
      }
    }, [currentUser]);

  // useEffect(() => {
  //   if (currentUser) {
  //     const unsubscribe = firebase.firestore()
  //       .collection('notifications')
  //       .where('receiver', '==', currentUser.displayName)
  //       .where('isSeen', '==', false)
  //       .onSnapshot(snapshot => {
  //         setHasNewNotifications(!snapshot.empty);
  //       });

  //     return () => unsubscribe();
  //   }
  // }, [currentUser]);
  useEffect(() => {
    if (currentUser) {
      const fetchNotifications = async () => {
        const notificationsRef = firebase.firestore().collection('notifications');
        const followersRef = firebase.firestore().collection('followers');
  
        // Fetch personal notifications where the current user is the receiver and hasn't seen them yet
        const personalNotificationsQuery = notificationsRef
        .where("receiver", "in", [currentUser.displayName, "all_flowroo_musers_XYRGT-TEXTX"])
        .where('isSeen', '==', false);
  
        // Fetch followers of the current user
        const followersSnapshot = await followersRef
          .where('followerUserId', '==', currentUser.uid)
          .get();
  
        const followedUserIds = followersSnapshot.docs.map(doc => doc.data().userId);
  
        // Fetch notifications from followed users with a specific type
        const followedUserNotificationsPromises = followedUserIds.map(userId =>
          notificationsRef
            .where('userId', '==', userId)
            .where('type', '==', 'postedFlow')
            .where('isSeen', '==', false)
            .get()
        );
  
        // Wait for all promises to resolve
        const results = await Promise.all([personalNotificationsQuery.get(), ...followedUserNotificationsPromises]);
  
        // First result is the current user's notifications, rest are followed users' notifications
        const personalNotifications = results[0].docs.map(doc => ({ ...doc.data(), id: doc.id }));
        const followedUserNotifications = results.slice(1).flatMap(result => result.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  
        // Combine and deduplicate notifications
        const allNotifications = [...personalNotifications, ...followedUserNotifications];
        const uniqueNotifications = [...new Map(allNotifications.map(notification => [notification.id, notification])).values()];
  
        console.log('uniqueNotifications', uniqueNotifications)
  
        // Filtering logic based on user preferences stored in state and sender filtering
        const filteredNotifications = uniqueNotifications.filter(notification => {
          // Filter out notifications sent by the current user
          if (notification.sender === currentUser.displayName) {
            return false;
          }
  
          // Switch-case logic for filtering based on notification type and state
          switch (notification.type) {
            case "followed":
              return isFollows; // Keep if user wants to see follow notifications
            case "likedFlow":
              return isLikes && isLikesFlow; // Keep if both conditions are true
            case "commentLeft":
              return isComments;
            case "commentReplyLeft":
              return isCommentsReply;
            case "messageNotification":
              return isMessages;
            case "remixedFlow":
              return isRemixes;
            case "likeComment":
              return isLikesComments;
            case "pinnedFlow":
              return isPinFlow;
            case "collabRequest":
              return isCollaborationRequest;
            case "updatedFlow":
              return isUpdatedFlow;
            case "postedFlow":
              return isPostedFlow;
            case "commentMention":
              return isMentionsYouComments;
            case "commentMentionReply":
              return isCommentsReply;
            case "flowroom":
              return true;
            default:
              return true; // Keep other notification types by default
          }
        });
  
        console.log('filteredNotifications', filteredNotifications);
  
        // Update state based on whether there are unseen notifications
        setHasNewNotifications(filteredNotifications.length > 0);
      };
  
      fetchNotifications().catch(console.error);
  
      // Since this useEffect does not set up a real-time listener, there's no unsubscribe function to return
    }
  }, [
    currentUser, 
    isFollows, 
    isLikes, 
    isComments, 
    isCommentsReply, 
    isMessages, 
    isRemixes, 
    isLikesComments, 
    isPinFlow, 
    isCollaborationRequest, 
    isUpdatedFlow, 
    isPostedFlow, 
    isMentionsYouComments, 
    isMentionsYouInCommentReply, 
    isLikesFlow
  ]);
  
  

  return (
    <NotificationContext.Provider value={{ hasNewNotifications, setHasNewNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook to use the notification context
export const useNotifications = () => useContext(NotificationContext);
