import { default as React } from "react";

const CollabPlus = (props) => {
  return (
    <div
      className="credit-step"
      onClick={() => {
        if (
          document.getElementById(`collab-plus-${props.flowId}`).style
            .display !== "none"
        ) {
          document.getElementById(
            `flow-options-${props.flowId}-desktop`
          ).style.display = "none";
          // document.getElementById(`credit_${props.flowId}`).style.display =
          //   "flex";
          document.getElementById(`collab-plus-${props.flowId}`).style.display =
            "none";
          document.getElementById(
            `collab-minus-${props.flowId}`
          ).style.display = "block";
          if (
            document.getElementById(`credit_${props.flowId}-details`) !== null
          ) {
            document.getElementById(
              `credit_${props.flowId}-details`
            ).style.display = "block";
          }

          // document.getElementById(`flow-share-${props.flowId}`).style.display =
          //   "none";
          // document.getElementById(
          //   `flow-options-${props.flowId}`
          // ).style.display = "none";

          // document.getElementById(
          //   `flow-details-${props.flowId}`
          // ).style.display = "none";
          //   if (
          //     document.getElementById(`flow-remixes-${props.flowId}`) !== null
          //   ) {
          //     document.getElementById(
          //       `flow-remixes-${props.flowId}`
          //     ).style.display = "none";
          //   }
          //   document.getElementById(
          //     `flow-description-${props.flowId}`
          //   ).style.display = "none";
          // } else {
          //   document.getElementById(`credit_${props.flowId}`).style.display =
          //     "none";
          //   document.getElementById(`collab-plus-${props.flowId}`).style.display =
          //     "block";
          //   document.getElementById(
          //     `collab-minus-${props.flowId}`
          //   ).style.display = "none";
          // }
        } else {
          document.getElementById(`collab-plus-${props.flowId}`).style.display =
            "block";
          document.getElementById(
            `collab-minus-${props.flowId}`
          ).style.display = "none";
          // document.getElementById(
          //   `credit_${props.flowId}-details`
          // ).style.display = "none";
          if (
            document.getElementById(`credit_${props.flowId}-details`) !== null
          ) {
            document.getElementById(
              `credit_${props.flowId}-details`
            ).style.display = "none";
          }
        }
      }}
      style={{
        backgroundColor: "#4FD975",
        height: 20,
        width: 20,
        borderRadius: "50%",
        marginLeft: 7,
        marginBottom: 8,
        cursor: "pointer",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100000,
        marginTop: 14,
      }}
    >
      <p
        style={{
          color: "black",
          fontFamily: "quicksand",
          fontSize: 11.4,
          display: "flex",
        }}
      >
        <span id={`collab-plus-${props.flowId}`} style={{ display: "block" }}>
          {"+"}
        </span>
        <span id={`collab-minus-${props.flowId}`} style={{ display: "none" }}>
          {"-"}
        </span>
        <span>
          {props.collaborators !== undefined && props.collaborators !== null
            ? props.collaborators.length
            : 0}
        </span>
      </p>
    </div>
  );
};

export default React.memo(CollabPlus);
