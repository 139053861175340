// src/Components/Home/FlowManagement.js

import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const FlowManagement = () => {
  const db = firebase.firestore();
  const [flowId, setFlowId] = useState('');
  const [flowAction, setFlowAction] = useState('delete');
  const [flowMessage, setFlowMessage] = useState('');
  const [error, setError] = useState('');

  const handleFlowAction = async () => {
    setFlowMessage('');
    setError('');

    if (!flowId.trim() || !flowAction) {
      setFlowMessage('Please provide both Flow ID and select an action.');
      return;
    }

    try {
      // Fetch flow by flowId
      const flowSnapshot = await db.collection('flows')
        .where('flowId', '==', flowId.trim())
        .limit(1)
        .get();

      if (flowSnapshot.empty) {
        setFlowMessage('Flow not found.');
        return;
      }

      const flowDoc = flowSnapshot.docs[0];
      const flowDocId = flowDoc.id;

      if (flowAction === 'delete') {
        // Confirm deletion
        const confirmDelete = window.confirm('Are you sure you want to delete this flow?');
        if (!confirmDelete) {
          return;
        }

        await db.collection('flows').doc(flowDocId).delete();
        setFlowMessage(`Flow ID ${flowId.trim()} has been deleted.`);
      } else {
        // Update visibility based on action
        let newVisibility = '';
        switch (flowAction) {
          case 'public':
            newVisibility = 'public';
            break;
          case 'private':
            newVisibility = 'private';
            break;
          case 'featured':
            newVisibility = 'featured';
            break;
          case 'create':
            newVisibility = 'create';
            break;
          default:
            newVisibility = 'private';
        }

        await db.collection('flows').doc(flowDocId).update({
          visibility: newVisibility,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setFlowMessage(`Flow ID ${flowId.trim()} has been set to ${newVisibility}.`);
      }

      // Clear input
      setFlowId('');
      setFlowAction('delete');
    } catch (err) {
      console.error("Error managing flow:", err);
      setFlowMessage('Failed to perform the action. Please try again.');
    }
  };

  return (
    <section style={styles.section}>
      <h2>Flow Management</h2>
      <div style={styles.inputGroup}>
        <input
          type="text"
          placeholder="Flow ID"
          value={flowId}
          onChange={(e) => setFlowId(e.target.value)}
          style={styles.input}
        />
        <select
          value={flowAction}
          onChange={(e) => setFlowAction(e.target.value)}
          style={styles.select}
        >
          <option value="delete">Delete</option>
          <option value="public">Make Public</option>
          <option value="private">Make Private</option>
          <option value="featured">Add to Featured</option>
          <option value="create">Add to Create Menu</option>
        </select>
        <button onClick={handleFlowAction} style={styles.button}>
          Perform
        </button>
      </div>
      <button style={styles.readReportsButton}>
        Read Reports
      </button>
      {flowMessage && <p style={styles.message}>{flowMessage}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    flex: '1 1 200px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  select: {
    flex: '1 1 150px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    flex: '0 0 auto',
  },
  readReportsButton: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28A745',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default FlowManagement;
