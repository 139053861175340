import { makeAutoObservable } from "mobx";
import { createContext } from "react";

class DrawerStore {
  constructor() {
    makeAutoObservable(this);
  }

  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  setState(state) {
    this.state = state;
  }
}

export const DrawerStoreContext = createContext(new DrawerStore());
